/**
 *  Generated by Modeler
 *  timestamp: 2022-08-22 12:24:20.652766
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";

export default class LabelChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
    this.addChecker(
      item.getFidLabelRefs(),
      this.checkLabelRefs,
      ErrorType.CLOCK_DIAGRAM
    );
    //this.addChecker(item.getFidNodeId(), this.checkNodeId, ErrorType.NAME)
  }
  checkName() {
    // TBD
  }
  checkLabelRefs(item) {
    // for (const refId of item.getLabelRefs()) {
    //   const labelRef = item.getRefItem(refId);
    //   if (!labelRef) {
    //     this.newError(`${refId} does not exist in clock diagram`);
    //   }
    // }
  }
  checkNodeId() {
    // TBD
  }
}
