import { ClassicPreset } from "rete";
import { Repository } from "../store";
import * as ITDARenderer from "./renderers";

export default class ITDAControl extends ClassicPreset.Control {
  protected render: (elem: HTMLElement, data: { [key in string]: any }) => void;
  protected repo: Repository;
  constructor() {
    super();
    this.repo = Repository.getInstance();
    this.render = ITDARenderer.ITDAControl.render;
  }

  update(): Promise<void> {
    const area = this.repo.getAreaPlugin(this.repo.getCurrentID());
    return area?.update("control", this.id);
  }
}
