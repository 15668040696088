import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodePower from "./ITDANodePower";

export default class ITDAINTRSFR extends ITDANodePower {
  constructor(name = "") {
    super(name, NodeType.ITDAINTRSFR);
    this.render = ITDANodeRenderer.ITDAINTRSFR.render;
  }
}
