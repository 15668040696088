import { ClassicPreset } from "rete";
import { ITDASocket as ITDASocketRenderer } from "./renderers";
import { ITDASocketType } from "./types";
export default class ITDASocket extends ClassicPreset.Socket {
  private type: ITDASocketType;
  private size: { width: number; height: number }; // for socket size
  render: (elem: HTMLElement, color: string) => void;
  constructor(type: ITDASocketType) {
    super(type.getKey());
    this.type = type;
    this.render = ITDASocketRenderer.render;
    this.size = { width: 0, height: 0 };
  }

  getType(): ITDASocketType {
    return this.type;
  }

  isCompatibleWith(socket: ITDASocket) {
    return this.getType() === socket.getType();
  }

  setSize(width: number, height: number) {
    this.size = { width, height };
  }

  getSize() {
    return this.size;
  }
}
