import { ITDACanvasType, ITDAPlugin, ITDAPluginType } from "./plugins/types";
import { Repository } from "./store";

export default abstract class ITDACanvas implements ITDAPlugin {
  width = 15000;
  height = 15000;
  gridSpace = 10; // 그리드 크기로 width, height를 제어하는 API 생성
  gridStroke = 1;
  showGrid = true;

  protected repo: Repository;
  protected id: string;
  protected res!: ITDAPluginType;
  emit!: (context: any) => Promise<boolean>;
  protected type: ITDACanvasType;
  constructor(id: string, type: ITDACanvasType) {
    this.repo = Repository.getInstance();
    this.id = id;
    this.type = type;
    this.repo.addPlugin(this.id, type, this);
  }

  getRepository() {
    return this.repo;
  }

  getID() {
    return this.id;
  }

  getInstance(): ITDAPluginType {
    return this.res;
  }

  getGridSpace(): number {
    return this.gridSpace;
  }

  getGridStroke(): number {
    return this.gridStroke;
  }

  snapToGrid(value: number): number {
    return Math.round(value / this.getGridSpace()) * this.getGridSpace();
  }
}
