/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import DUTInstanceEvent from '@/model/src/events/DUTInstanceEvent'
import DUTInstanceChecker from '@/model/src/checkers/DUTInstanceChecker'
export default class DUTInstance extends Node {
    FID_DUTINSTANCE_NAME = 100
    FID_DUTINSTANCE_TYPE = 101
    FID_DUTINSTANCE_UPPER = 102
    FID_DUTINSTANCE_MODULE = 103
    FID_DUTINSTANCE_LAST = 103

    constructor(obj) {
        super(Object.assign(obj,{itemType:"DUTInstance"}))
        
        this.setEvent(new DUTInstanceEvent(this))
        this.setChecker(new DUTInstanceChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "Instance"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.type === undefined) {
        this.properties.type = "BLOCK"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "DUTInstance"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "type": {"type": "enum", "targets": ["BLOCK", "CMU", "PMC", "PMIF"], "readOnly": true, "hidden": true, "multiple": false, "default": "BLOCK", "category": "IP"}, "upper": {"type": "item", "label": "Block", "desc": "Block", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "module": {"type": "item", "label": "Module", "desc": "Module", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "type": {"type": "enum", "targets": ["BLOCK", "CMU", "PMC", "PMIF"], "readOnly": true, "hidden": true, "multiple": false, "default": "BLOCK", "category": "IP"}, "upper": {"type": "item", "label": "Block", "desc": "Block", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "module": {"type": "item", "label": "Module", "desc": "Module", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_DUTINSTANCE_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_DUTINSTANCE_NAME
    }
    

    setType(type, event=true) {
        if ((type !== null) && (type !== undefined)) {
            if (![String].includes(type.constructor)) {
                throw `type(${type}) must be [String] type`
            }
        }
        const newVal = (type === null || type === undefined) ? undefined : type
        const oldVal = this.properties.type
        this.properties.type = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_DUTINSTANCE_TYPE, newVal, oldVal)
        }
    }
    getType(pure = false) {
        return this.properties.type
        
    }
    getFidType() {
        return this.FID_DUTINSTANCE_TYPE
    }
    

    setUpper(upper, event=true) {
        if ((upper !== null) && (upper !== undefined)) {
            if (!(upper instanceof Item) && (upper.constructor !== String)) {
                throw `upper(${upper}) must be Item or String type`
            }
        }
        const newVal = (upper === null || upper === undefined) ? undefined : (upper.constructor === String) ? upper : upper.getId()
        const oldVal = this.properties.upper
        this.properties.upper = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_DUTINSTANCE_UPPER, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getUpper(pure = false) {
        return pure ? this.properties.upper : this.getRefItem(this.properties.upper)
        
    }
    getFidUpper() {
        return this.FID_DUTINSTANCE_UPPER
    }
    

    setModule(module, event=true) {
        if ((module !== null) && (module !== undefined)) {
            if (!(module instanceof Item) && (module.constructor !== String)) {
                throw `module(${module}) must be Item or String type`
            }
        }
        const newVal = (module === null || module === undefined) ? undefined : (module.constructor === String) ? module : module.getId()
        const oldVal = this.properties.module
        this.properties.module = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_DUTINSTANCE_MODULE, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getModule(pure = false) {
        return pure ? this.properties.module : this.getRefItem(this.properties.module)
        
    }
    getFidModule() {
        return this.FID_DUTINSTANCE_MODULE
    }
    

    getFidLast() {
        return this.FID_DUTINSTANCE_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_DUTINSTANCE_NAME:
                return "name"

            case this.FID_DUTINSTANCE_TYPE:
                return "type"

            case this.FID_DUTINSTANCE_UPPER:
                return "upper"

            case this.FID_DUTINSTANCE_MODULE:
                return "module"

            default:
                return ""
        }
    }


}