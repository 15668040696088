import Utils from "./utils";
import {
  Item,
  RefItemElement,
  RefItemElementType,
  VariantLevel,
} from "ITDAModelTypes";

export const getSequenceLabelRefsElements = (
  item: Item,
  propKey: string
): RefItemElement[] => {
  const res: RefItemElement[] = [];
  if (!item.getLabelRefs().length) {
    return [];
  }

  item.getLabelRefs().forEach((labelRef: Item) => {
    res.push({
      icon: "mdi-chevron-double-right",
      variant: VariantLevel.LEVEL0,
      disabled: false,
      type: RefItemElementType.CHIP,
      propKey: propKey,
      label: "",
      multiple: false,
      targets: [
        {
          key: `${labelRef.getParent().getTitle()}:${item.getName()}`,
          item: labelRef,
          closable: false,
        },
      ],
      event: () => eventLabelRefs(item, labelRef),
    });
  });
  return res;
};

const eventLabelRefs = async (label: Item, labelRef: Item) => {
  Utils.moveToTarget(label, labelRef);
};
