import ITDAControlEXT from "./ITDAControlEXT.vue";
import ITDAControlFrequency from "./ITDAControlFrequency.vue";
import ITDAControlSignal from "./ITDAControlSignal.vue";
import ITDAControlTitle from "./ITDAControlTitle.vue";
import ITDAControlSDC from "./ITDAControlSDC.vue";
import ITDAControlDesc from "./ITDAControlDesc.vue";

export default {
  ITDAControlEXT,
  ITDAControlFrequency,
  ITDAControlSignal,
  ITDAControlTitle,
  ITDAControlSDC,
  ITDAControlDesc,
};
