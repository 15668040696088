import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodeSequence from "./ITDANodeSequence";

export default class ITDASEQLABEL extends ITDANodeSequence {
  constructor(name = "") {
    super(name, NodeType.ITDASEQLABEL);
    this.render = ITDANodeRenderer.ITDASEQLABEL.render;
  }

  set width(data: number) {
    super.width = this.adjustNodeToGrid(data);
  }

  get width() {
    return (
      this.adjustNodeToGrid(super.width) -
      this.repo.getEditor(this.repo.getCurrentID()).getGridStroke() / 2
    );
  }

  set height(data: number) {
    super.height = this.adjustNodeToGrid(data);
  }

  get height() {
    return (
      this.adjustNodeToGrid(super.height) -
      this.repo.getEditor(this.repo.getCurrentID()).getGridStroke() / 2
    );
  }
}
