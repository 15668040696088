/**
 *  Generated by Modeler
 *  timestamp: 2024-08-27 10:28:21.894782
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class SDCPhantomGroupEvent extends ItemEvent {
    constructor(item) {
        super(item)
        // this.addPropertyListener(item.getFidPhantom(), this.lstn_phantom)
        // this.addPropertyListener(item.getFidOriginItemID(), this.lstn_originItemID)
    }
    // lstn_phantom(item, newVal, oldVal) {
    //     console.debug(item, newVal, oldVal)
    // }
    // lstn_originItemID(item, newVal, oldVal) {
    //     console.debug(item, newVal, oldVal)
    // }
    postProcess(item) {
        item.setNodeType("ITDAINSTANCESDCPHANTOMGROUP");
    }
}
