/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x0000",
    "size": "0x4",
    "alignSize": "0x4",
    "sfrs": [
        {
            "name": "CTRL",
            "alignOffset": "0x0",
            "description": "Power Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_PWR_REQ",
                    "index": 0,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD Control Signal for {{name}}\n0 = PWR_REQ is Low\n1 = PWR_REQ is High",
                    "initValue": "0x0",
                    "nc": "SFR_PWR_REQ"
                },
                {
                    "name": "SFR_PWR_STATUS",
                    "index": 4,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD Control Signal for {{name}}\n0 = Set PWR_STATUS to 0\n1 = Set PWR_STATUS to 1",
                    "initValue": "0x0",
                    "nc": "SFR_PWR_STATUS"
                },
                {
                    "name": "SFR_PWR_CMD_0",
                    "index": 8,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD_CMD_0 Signal Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PWR_CMD_0"
                },
                {
                    "name": "SFR_PWR_CMD_1",
                    "index": 9,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD_CMD_1 Signal Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PWR_CMD_1"
                },
                {
                    "name": "SFR_PWR_CMD_2",
                    "index": 10,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD_CMD_2 Signal Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PWR_CMD_2"
                },
                {
                    "name": "SFR_PWR_CMD_3",
                    "index": 11,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD_CMD_3 Signal Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PWR_CMD_3"
                }
            ]
        }
    ]
}