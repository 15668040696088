/**
 *  Generated by Modeler
 *  timestamp: 2024-08-20 10:35:45.435233
 */
import ItemEvent from '@/model/src/ItemEvent'

export default class DUTModuleFolderEvent extends ItemEvent {
    constructor(item) {
        super(item)
    }
}