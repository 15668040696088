import { DockPlugin, DockPresets } from "rete-dock-plugin";
import { AreaPlugin } from "rete-area-plugin";
import { ITDACommon, AreaExtra, ITDACanvasType } from "./types";
import ITDACanvas from "../ITDACanvas";
import { DiagramType } from "../types";
import { getFactoryByDiagram } from "./utils";

export default class ITDADockPlugin extends ITDACanvas {
  constructor(id: string) {
    super(id, ITDACanvasType.Dock);
    this.res = new DockPlugin<ITDACommon.Schemes>();
    const area = this.repo
      .getPlugin(this.id, ITDACanvasType.Area)
      ?.getInstance() as AreaPlugin<ITDACommon.Schemes, AreaExtra>;
    this.res.addPreset(DockPresets.classic.setup({ area, size: 0, scale: 1 }));
  }
  getInstance(): DockPlugin<ITDACommon.Schemes> {
    return this.res as DockPlugin<ITDACommon.Schemes>;
  }

  register(diagramType: DiagramType) {
    this.repo.updateDockState(true);
    const nodes = getFactoryByDiagram(diagramType);
    nodes.forEach((o) => this.getInstance().add(o[1]));
    this.repo.updateDockState(false);
  }

  // render() {
  //   const elem = document.querySelector("#dockBox");
  //   if (elem) {
  //     elem.innerHTML = "";
  //     this.getInstance().nodes.forEach((value) => {
  //       value.element.style.height = "auto";
  //       value.element.style.minHeight = "50px";
  //       value.element.style.margin = "0px";
  //       value.element.style.padding = "0.75rem 0.75rem 0.75rem 0.75rem";
  //       value.element.style.alignContent = "center";
  //       elem.appendChild(value.element);
  //     });
  //   }
  // }

  render() {
    const elem = document.querySelector("#conditionalDockBox");
    if (elem) {
      elem.innerHTML = "";
      this.getInstance().nodes.forEach((value) => {
        value.element.style.height = "auto";
        value.element.style.display = "flex";
        value.element.style.flexDirection = "column";
        elem.appendChild(value.element);
      });
    }
  }
}
