import { Repository } from "@/canvas/store";
import { Position, Size } from "rete-area-plugin/_types/types";
import { NodeView } from "rete-area-plugin";
import ITDANode from "../../ITDANode";
import { NodeType } from "@/canvas/types";

type ResizerDirection =
  | "top"
  | "right"
  | "bottom"
  | "left"
  | "nw"
  | "ne"
  | "sw"
  | "se";
type RefinedData = {
  size: Size;
  movement: Position;
};

const repo = Repository.getInstance();

export default class ITDANodeResizer {
  private currentResizer: HTMLElement | null;

  private reteArea = repo.getAreaPlugin(repo.getCurrentID());
  private node: ITDANode;
  private nodeView: NodeView | undefined;

  private prev: Position;
  private startPosition: Position;
  private totalMovements: Position;

  private moveHandler: (e: PointerEvent) => Promise<void>;
  private upHandler: (e: PointerEvent) => void;

  constructor(node: ITDANode) {
    this.node = node;
    this.nodeView = this.reteArea.getNodeView(this.node.id);

    this.totalMovements = { x: 0, y: 0 };
    this.prev = { x: 0, y: 0 };
    this.startPosition = {
      x: this.nodeView?.position.x || 0,
      y: this.nodeView?.position.y || 0,
    };
    this.currentResizer = null;

    this.moveHandler = this.handleMove.bind(this);
    this.upHandler = this.handleUp.bind(this);
  }

  handleClick(e: PointerEvent) {
    e.stopPropagation();

    this.prev = { x: e.pageX, y: e.pageY };
    this.totalMovements = { x: 0, y: 0 };
    this.startPosition = this.nodeView!.position;
    this.currentResizer = e.target as HTMLElement;

    window.addEventListener("pointermove", this.moveHandler);
    window.addEventListener("pointerup", this.upHandler);
  }

  handleMove = async (e: PointerEvent) => {
    e.stopPropagation();

    let size: Size = { width: this.node.width, height: this.node.height };
    const k = this.reteArea.getArea().transform.k;
    const delta = { x: e.pageX - this.prev.x, y: e.pageY - this.prev.y };

    if (this.currentResizer?.dataset) {
      const direction = this.currentResizer.dataset
        .direction as ResizerDirection;
      size = this.calcSize(direction, size, delta, k);

      const data: RefinedData = this.refineData(size, direction, delta, k);
      // this.translate(data.movement);
      this.resize(data.size);
      this.node.setResizeValue(
        data.size.width - this.node.getType().getWidth()
      );
    }

    this.prev = { x: e.pageX, y: e.pageY };
  };

  refineData(
    size: Size,
    direction: ResizerDirection,
    delta: Position,
    k: number
  ): RefinedData {
    const minSize = this.getMinSize();
    if (size.width <= minSize.width) {
      size.width = minSize.width;
    } else {
      this.calcMovements(direction, delta, k, "x");
    }

    if (size.height <= minSize.height) {
      size.height = minSize.height;
    } else {
      this.calcMovements(direction, delta, k, "y");
    }

    return {
      size: size,
      movement: this.totalMovements,
    };
  }

  getMinSize(): Size {
    switch (this.node.getType()) {
      case NodeType.ITDAINSTANCEBLOCK:
        return this.node.getBoundingBox();
      default:
        return {
          width: this.node.getType().getWidth(),
          height: this.node.getType().getHeight(),
        };
    }
  }

  handleUp() {
    window.removeEventListener("pointermove", this.moveHandler);
    window.removeEventListener("pointerup", this.upHandler);
  }

  resize = async (size: Size) => {
    await this.reteArea.resize(this.node.id, size.width, size.height);
  };

  translate = async (movement: Position) => {
    await this.nodeView?.translate(
      this.startPosition.x + movement.x,
      this.startPosition.y + movement.y
    );
  };

  calcMovements = (
    direction: ResizerDirection,
    delta: Position,
    k: number,
    type: "x" | "y"
  ) => {
    switch (direction) {
      case "left":
        if (type === "x")
          this.totalMovements.x = this.totalMovements.x + delta.x / k;
        break;

      case "top":
        if (type === "y")
          this.totalMovements.y = this.totalMovements.y + delta.y / k;
        break;

      case "nw":
        if (type === "x")
          this.totalMovements.x = this.totalMovements.x + delta.x / k;
        if (type === "y")
          this.totalMovements.y = this.totalMovements.y + delta.y / k;
        break;

      case "ne":
        if (type === "y")
          this.totalMovements.y = this.totalMovements.y + delta.y / k;
        break;

      case "sw":
        if (type === "x")
          this.totalMovements.x = this.totalMovements.x + delta.x / k;
        break;

      default:
        break;
    }
  };

  calcSize = (
    direction: ResizerDirection,
    size: Size,
    delta: Position,
    k: number
  ): Size => {
    switch (direction) {
      case "left":
        size.width = size.width + (delta.x / k) * -1;
        break;

      case "right":
        size.width = size.width + delta.x / k;
        break;

      case "top":
        size.height = size.height + (delta.y / k) * -1;
        break;

      case "bottom":
        size.height = size.height + delta.y / k;
        break;

      case "nw":
        size.width = size.width + (delta.x / k) * -1;
        size.height = size.height + (delta.y / k) * -1;
        break;

      case "ne":
        size.width = size.width + delta.x / k;
        size.height = size.height + (delta.y / k) * -1;
        break;

      case "sw":
        size.width = size.width + (delta.x / k) * -1;
        size.height = size.height + delta.y / k;
        break;

      case "se":
        size.width = size.width + delta.x / k;
        size.height = size.height + delta.y / k;
        break;

      default:
        break;
    }
    return size;
  };
}
