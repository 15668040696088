/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class PowerPMRINTREXTATOMEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), () => {});
    this.addPropertyListener(item.getFidLast() + 10, this.lstn_update_name);
  }

  lstn_update_name(item, newVal) {
    item
      .getParent()
      .getPowerPMRINTREXTATOMs()
      .forEach((obj) => {
        if (obj === item) {
          item.setName(newVal);
        }
      });
  }
}
