/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from '@/model/src/ItemEvent'

export default class ClockSIGNALEvent extends ItemEvent {
    constructor(item) {
        super(item)
        // this.addPropertyListener(item.getFidSignalName(), this.lstn_SignalName)
        // this.addPropertyListener(item.getFidSignalReset(), this.lstn_SignalReset)
        // this.addPropertyListener(item.getFidType(), this.lstn_type)
    }
    // lstn_SignalName(item, newVal, oldVal) {
    //     console.debug(item, newVal, oldVal)
    // }
    // lstn_SignalReset(item, newVal, oldVal) {
    //     console.debug(item, newVal, oldVal)
    // }
    // lstn_type(item, newVal, oldVal) {
    //     console.debug(item, newVal, oldVal)
    // }
}