import { ClassicPreset } from "rete";
import * as ITDAControl from "../controls";
import * as ITDANodeRenderer from "./renderers";

import { Repository } from "../store";
import { NodeCustomProps, NodeType, ImportNodeType } from "../types";
import {
  ITDAControlBuilder,
  ITDACtrlType,
  InputSpec,
  OutputSpec,
} from "./types";
import { NodeView } from "rete-area-plugin";
import { ITDAPortPosition, ITDASocketType } from "../common";

export default class ITDANode<
    Inputs extends { [key in string]?: ClassicPreset.Socket } = {
      [key in string]?: ClassicPreset.Socket;
    },
    Outputs extends { [key in string]?: ClassicPreset.Socket } = {
      [key in string]?: ClassicPreset.Socket;
    },
    Controls extends { [key in string]?: ITDACtrlType } = {
      [key in string]?: ITDACtrlType;
    }
  >
  extends ClassicPreset.Node<Inputs, Outputs, Controls>
  implements ITDAControlBuilder
{
  protected repo: Repository;
  protected render: (
    elem: HTMLElement,
    width: number,
    height: number,
    color: string,
    options: any
  ) => void;
  _width = 80;
  _height = 80;
  _nodeStroke = 1.5;
  resizable = false;
  resizeValue: number;
  parent?: string;
  private itemID!: string;
  private itemOptions: Record<string, any> = {};
  private type: NodeType;
  private dock = false;
  private lock = false;
  private inheritedClassName: string;

  constructor(name: string, type: NodeType) {
    super(name);
    this.type = type;
    this.repo = Repository.getInstance();
    this._width = this.getType().getWidth();
    this._height = this.getType().getHeight();
    this._nodeStroke = 1.5;
    this.render = ITDANodeRenderer.ITDANode.render;
    this.resizeValue = 0;

    this.inheritedClassName = this.getInheritedClassName();

    const repo = Repository.getInstance();
    if (repo.isDock()) {
      this.dock = true;
    } else {
      this.addControl(
        "port",
        new ITDAControl.ITDAControlPort(this) as Controls["port"]
      );
      this.addControl(
        "signal",
        new ITDAControl.ITDAControlDynamicSignal(this) as Controls["signal"]
      );
      this.addControl(
        "ext",
        new ITDAControl.ITDAControlEXT(this) as Controls["ext"]
      );
      this.addControl(
        "title",
        new ITDAControl.ITDAControlTitle(this) as Controls["title"]
      );
      this.addControl(
        "freq",
        new ITDAControl.ITDAControlFrequency(this) as Controls["freq"]
      );
      this.addControl(
        "sdc",
        new ITDAControl.ITDAControlSDC(this) as Controls["sdc"]
      );
      this.addControl(
        "desc",
        new ITDAControl.ITDAControlDesc(this) as Controls["desc"]
      );
      // this.build();
    }
  }

  build(inputs?: InputSpec[], outputs?: OutputSpec[]) {
    const ctrl = this.getCtrlPort();
    ctrl.remInputPorts();
    inputs?.forEach((input: InputSpec) => {
      const socket = ITDASocketType.getObjByKey(input.socket);
      let port = ctrl.getInputPort(input.key);
      if (socket && !port) {
        port = ctrl.newInputPort(
          input.key,
          socket,
          ITDAPortPosition[input.position],
          input.hidden,
          input.multiple
        );
        port.setItemID(input.itemID);
      }
    });
    ctrl.remOutputPorts();
    outputs?.forEach((output: OutputSpec) => {
      const socket = ITDASocketType.getObjByKey(output.socket);
      let port = ctrl.getOutputPort(output.key);
      if (socket && !port) {
        port = ctrl.newOutputPort(
          output.key,
          socket,
          ITDAPortPosition[output.position],
          output.multiple,
          output?.maxNum
        );
        port.setItemID(output.itemID);
      }
    });
    return this.update();
  }

  set width(data: number) {
    this._width = data;
  }

  get width() {
    return this._width;
  }

  set height(data: number) {
    this._height = data;
  }

  get height() {
    return this._height;
  }

  set nodeStroke(data: number) {
    this._nodeStroke = data;
  }

  get nodeStroke() {
    return this._nodeStroke;
  }

  updateProps({ width, height, resizable, parent, itemID }: NodeCustomProps) {
    if (width) {
      this._width = width;
    }
    if (height) {
      this._height = height;
    }
    if (resizable) {
      this.resizable = resizable;
    }
    if (parent) {
      this.parent = parent;
    }
    if (itemID) {
      this.itemID = itemID;
    }
  }

  async update(): Promise<void> {
    for (const id in this.controls) {
      await this.controls[id]?.update();
    }
    const area = this.repo.getAreaPlugin(this.repo.getCurrentID());
    await area.update("node", this.id);
  }

  async data(inputs: Record<string, any>): Promise<Record<string, any>> {
    console.debug(inputs);
    return {};
  }

  isDocked(): boolean {
    return this.dock;
  }

  setItemID(id: string) {
    this.itemID = id;
  }

  getItemID(): string {
    return this.itemID;
  }

  getType(): NodeType {
    return this.type;
  }

  getCtrlSDC(): ITDAControl.ITDAControlSDC {
    return this.controls.sdc as ITDAControl.ITDAControlSDC;
  }

  getCtrlPort(): ITDAControl.ITDAControlPort {
    return this.controls.port as ITDAControl.ITDAControlPort;
  }

  getCtrlTitle(): ITDAControl.ITDAControlTitle {
    return this.controls.title as ITDAControl.ITDAControlTitle;
  }

  getNodeView(): NodeView | undefined {
    const area = this.repo.getAreaPlugin(this.repo.getCurrentID());
    return area.getNodeView(this.id);
  }

  setResizeValue(value: number): void {
    this.resizeValue = value;
  }

  getResizeValue(): number {
    return this.resizeValue;
  }

  acquireLock(): void {
    this.lock = true;
  }

  isLocked(): boolean {
    return this.lock;
  }

  releaseLock(): void {
    this.lock = false;
  }

  import(data: ImportNodeType) {
    this.setItemID(data.itemID);
    this.updateItemOptions(data.itemOptions);
  }

  // export(position: Position = { x: 0, y: 0 }): ExportedNodeType {
  //   const view = this.getNodeView();
  //   return {
  //     // id: this.id,
  //     itemID: this.getItemID(),
  //     itemOptions: this.getItemOptions(),
  //     type: this.getType().getKey(),
  //     width: this.width,
  //     height: this.height,
  //     view: {
  //       position: view ? view.position : position,
  //     },
  //   };
  // }

  updateItemOptions(options: Record<string, any>) {
    Object.assign(this.itemOptions, options);
  }

  getItemOptions(): Record<string, any> {
    return this.itemOptions;
  }

  getBoundingBox(): {
    width: number;
    height: number;
  } {
    const editor = this.repo.getEditor(this.repo.getCurrentID());
    const nodes = editor.getNodes().filter((child) => child.parent === this.id);

    const boxes = nodes.map((node) => {
      const view = node.getNodeView();
      if (!view) throw new Error("view");

      return {
        position: view.position,
        width: node.width,
        height: node.height,
      };
    });

    const paddingSize = 100;
    const left = Math.min(...boxes.map((b) => b.position.x));
    const right = Math.max(...boxes.map((b) => b.position.x + b.width));
    const top = Math.min(...boxes.map((b) => b.position.y));
    const bottom = Math.max(...boxes.map((b) => b.position.y + b.height));
    const width = Math.max(
      right - left + paddingSize * 2,
      this.getType().getWidth()
    );
    const height = Math.max(
      bottom - top + paddingSize * 2,
      this.getType().getHeight()
    );

    return { width: width, height: height };
  }

  getInheritedClassName(): string {
    let proto = Object.getPrototypeOf(this);
    while (proto) {
      const className = proto.constructor.name;
      if (className !== "Object" && className !== this.constructor.name) {
        return className;
      }
      proto = Object.getPrototypeOf(proto);
    }
    return "unknown";
  }

  adjustNodeToGrid(size: number): number {
    const gridSpace = this.repo
      .getEditor(this.repo.getCurrentID())
      .getGridSpace();

    const nodeGrid = Math.round(size / gridSpace);

    if (nodeGrid === 0) {
      return size;
    }

    return nodeGrid * gridSpace;
  }
}
