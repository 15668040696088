import Repository from "./store";
import Utils from "./utils";
import Wrapper from "./wrapper";
import item from "./src/Item";
import events from "./src/events/utils";

export default {
  repo: Repository.getInstance(),
  item: item,
  ...Utils,
  ...Wrapper,
  ...events,
};
