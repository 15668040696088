import Mapper from "@/model/src/Mapper";

export default class MapperFactory {
  static createMapper(type, source, target) {
    var mapper = source.getMappers().find((obj) => obj.getTarget() === target);
    if (mapper && mapper.getType() === type) {
      return mapper;
    }
    if (!mapper) {
      mapper = new Mapper(type, source, target);
    }
    switch (type) {
      case "name":
        mapper.addPropertyRefListener(
          source.getFidLast() + 10,
          target.getFidName()
        );
        break;
      case "title":
        mapper.addPropertyRefListener(
          source.getFidLast() + 11,
          target.getFidTitle()
        );
        break;
      case "SFRIFToCMU":
      case "SFRToNode":
        // case "SFRFieldToPMUSub":
        mapper.addPropertyRefListener(
          source.getFidLast() + 1,
          target.getFidName()
        );
        break;
      case "SFRToNodeTitle":
        mapper.addPropertyRefListener(
          source.getFidLast() + 1,
          target.getFidTitle()
        );
        break;
      case "SFRFieldToNode":
        mapper.addPropertyRefListener(
          source.getFidLast() + 20,
          target.getFidTitle()
        );
        break;
      default:
        break;
    }
    return mapper;
  }

  static removeMapper(mapper) {
    if (mapper.getSource()) {
      this._refreshMappers(mapper.getSource(), mapper);
    }
    if (mapper.getTarget()) {
      this._refreshMappers(mapper.getTarget(), mapper);
    }
  }

  static _refreshMappers(item, mapper) {
    const prevMappers = item.getMappers().filter(() => true);
    item.clearMappers();
    for (const pMapper of prevMappers) {
      if (pMapper !== mapper) {
        item.addMapper(pMapper);
      }
    }
  }
}
