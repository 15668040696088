/**
 *  Generated by Modeler
 *  timestamp: 2023-11-16 11:47:29.773775
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class SDCModuleEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
  }
  lstn_name(item, newVal) {
    item.setTitle(newVal);
  }
}
