import * as d3 from "d3";

export default {
  render: (
    elem: HTMLElement,
    width: number,
    height: number,
    gridSpace: number,
    gridStroke: number
  ) => {
    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const defs = svg.append("defs");
    const pattern = defs
      .append("pattern")
      .attr("id", "grid")
      .attr("width", gridSpace)
      .attr("height", gridSpace)
      .attr("patternUnits", "userSpaceOnUse");

    // horizontal grid
    pattern
      .append("path")
      .attr("d", `M 0 0 L ${gridSpace} 0`)
      .attr("fill", "none")
      .attr("stroke", "gray")
      .attr("stroke-width", gridStroke)
      .attr("opacity", 0.5);

    // vertical grid
    pattern
      .append("path")
      .attr("d", `M 0 0 L 0 ${gridSpace}`)
      .attr("fill", "none")
      .attr("stroke", "gray")
      .attr("stroke-width", gridStroke)
      .attr("opacity", 0.5);

    // horizontal grid
    svg
      .append("rect")
      .attr("width", "100%")
      .attr("height", "100%")
      .attr("fill", "url(#grid)");
  },
};
