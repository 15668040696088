/**
 *  Generated by Modeler
 *  timestamp: 2023-08-07 18:59:42.969900
 */
//import ErrorType from '@/model/ErrorType'
import ItemChecker from "@/model/src/ItemChecker";

export default class SDCInstanceChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
    //this.addChecker(item.getFidModule(), this.checkModule, ErrorType.NAME)
    //this.addChecker(item.getFidPrimaryPowerSource(), this.checkPrimaryPowerSource, ErrorType.NAME)
    //this.addChecker(item.getFidNodeId(), this.checkNodeId, ErrorType.NAME)
  }
  checkName() {
    // TBD
  }
  checkModule() {
    // TBD
  }
  checkPrimaryPowerSource() {
    // TBD
  }
  checkNodeId() {
    // TBD
  }
}
