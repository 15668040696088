import * as ITDANodeRenderer from "./renderers";
import { NodeType } from "../types";
import ITDANodeClock from "./ITDANodeClock";

export default class ITDALABEL extends ITDANodeClock {
  constructor(name = "") {
    super(name, NodeType.ITDALABEL);
    this.render = ITDANodeRenderer.ITDALABEL.render;
  }

  async data(
    inputs: Record<`CLK_INPUT`, any>
  ): Promise<Record<`CLK_OUTPUT`, any>> {
    let frequency = this.getFrequencyInitValue();
    if (Object.keys(inputs).length !== 0) {
      frequency = inputs.CLK_INPUT[0];
    }
    return { CLK_OUTPUT: frequency };
  }
}
