/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import NodeMetaViewEvent from '@/model/src/events/NodeMetaViewEvent'
import NodeMetaViewChecker from '@/model/src/checkers/NodeMetaViewChecker'
export default class NodeMetaView extends Item {
    FID_NODEMETAVIEW_X = 0
    FID_NODEMETAVIEW_Y = 1
    FID_NODEMETAVIEW_LAST = 1

    constructor(obj) {
        super(Object.assign(obj,{itemType:"NodeMetaView"}))
        
        this.setEvent(new NodeMetaViewEvent(this))
        this.setChecker(new NodeMetaViewChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.x === undefined) {
        this.properties.x = 0
    }
    if (this.properties.y === undefined) {
        this.properties.y = 0
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "NodeMetaView"
    }

    getSchema() {
        return {"x": {"type": "integer", "default": 0, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "y": {"type": "integer", "default": 0, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"x": {"type": "integer", "default": 0, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "y": {"type": "integer", "default": 0, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setX(x, event=true) {
        if ((x !== null) && (x !== undefined)) {
            if (![Number].includes(x.constructor)) {
                throw `x(${x}) must be [Number] type`
            }
        }
        const newVal = (x === null || x === undefined) ? undefined : x
        const oldVal = this.properties.x
        this.properties.x = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_NODEMETAVIEW_X, newVal, oldVal)
        }
    }
    getX(pure = false) {
        return this.properties.x
        
    }
    getFidX() {
        return this.FID_NODEMETAVIEW_X
    }
    

    setY(y, event=true) {
        if ((y !== null) && (y !== undefined)) {
            if (![Number].includes(y.constructor)) {
                throw `y(${y}) must be [Number] type`
            }
        }
        const newVal = (y === null || y === undefined) ? undefined : y
        const oldVal = this.properties.y
        this.properties.y = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_NODEMETAVIEW_Y, newVal, oldVal)
        }
    }
    getY(pure = false) {
        return this.properties.y
        
    }
    getFidY() {
        return this.FID_NODEMETAVIEW_Y
    }
    

    getFidLast() {
        return this.FID_NODEMETAVIEW_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_NODEMETAVIEW_X:
                return "x"

            case this.FID_NODEMETAVIEW_Y:
                return "y"

            default:
                return ""
        }
    }


}