/**
 *  Generated by Modeler
 *  timestamp: 2024-11-01 07:46:04.883289
 */
//import ErrorType from '@/model/src/ErrorType'
import ItemChecker from '@/model/src/ItemChecker'

export default class ClockDFTOCCBufferChecker extends ItemChecker {
    constructor(item) {
        super(item)
    }
}