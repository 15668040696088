/**
 *  Generated by Modeler
 *  timestamp: 2023-04-10 14:54:51.198864
 */
//import ErrorType from '@/model/ErrorType'
import ItemChecker from "@/model/src/ItemChecker";

export default class UPFGroundChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
    //this.addChecker(item.getFidNodeId(), this.checkNodeId, ErrorType.NAME)
  }
  checkName() {
    // TBD
  }
  checkNodeId() {
    // TBD
  }
}
