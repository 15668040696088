import { ClassicPreset } from "rete";
import { Repository } from "@/canvas/store";
import Canvas from "@/canvas";
export default class ITDAConnection<
  Source extends ClassicPreset.Node,
  Target extends ClassicPreset.Node
> extends ClassicPreset.Connection<Source, Target> {
  private itemID!: string;

  constructor(
    source: Source,
    sourceOutput: keyof Source["outputs"],
    target: Target,
    targetInput: keyof Target["inputs"]
  ) {
    super(source, sourceOutput, target, targetInput);
  }

  // Creata path segment
  createPathSegments(path: string) {
    return path.split(/(?=M)/).map((segment, index) => ({
      pathId: this.id,
      id: Math.random().toString(36).substr(2, 9),
      d: segment.trim(),
      index,
    }));
  }

  //Handle pointer down event for path movement
  handlePointerDown(
    event: PointerEvent,
    svgElement: SVGSVGElement | null,
    dragging: { value: boolean },
    draggingSegmentId: { value: string | null }
  ) {
    const repo = Repository.getInstance();
    const reroutePlugin = repo
      .getConnectionReRoutePlugin(repo.getCurrentID())
      .getInstance();
    if (!reroutePlugin) {
      console.error("Reroute plugin is not available.");
      return;
    }
    const target = event.target as HTMLElement;
    const segmentId = target.getAttribute("data-segment-id");
    const segmentIndex = parseInt(
      target.getAttribute("data-segment-index") ?? "0",
      10
    );
    const connectionId = target.getAttribute("data-id");

    if (reroutePlugin.selectedPinID) {
      reroutePlugin.unselect(reroutePlugin.selectedPinID);
    }

    if (segmentId && connectionId) {
      reroutePlugin.select(segmentId);
      draggingSegmentId.value = segmentId;

      // Control the movement of the two pins to drag the connection
      const handlePointerMove = (e: PointerEvent) => {
        const ctm = svgElement?.getScreenCTM();
        // ctm: zoom scale
        if (!ctm) return;

        let dx = e.movementX / ctm.a;
        let dy = e.movementY / ctm.d;

        const pins = reroutePlugin.getPinsForSegment(
          connectionId,
          segmentIndex
        );
        if (!pins.startPin || !pins.endPin) {
          dx = e.movementX / ctm.a;
          dy = e.movementY / ctm.d;
        }
        if (pins.startPin && pins.endPin) {
          reroutePlugin.translate(pins.startPin.id, dx, dy);
          reroutePlugin.translate(pins.endPin.id, dx, dy);
        } else if (pins.startPin) {
          reroutePlugin.translate(pins.startPin.id, dx, dy);
        } else if (pins.endPin) {
          reroutePlugin.translate(pins.endPin.id, dx, dy);
        }
      };

      const handlePointerUp = () => {
        dragging.value = false;
        draggingSegmentId.value = null;
        document.removeEventListener("pointermove", handlePointerMove); // when mouse pointer
        document.removeEventListener("pointerup", handlePointerUp); // when mouse pointer up
      };
      dragging.value = true;
      document.addEventListener("pointerup", handlePointerUp);
      document.addEventListener("pointermove", handlePointerMove);
    }
  }

  // Reset to path-segment to path
  handleResetSegmentMode(isSegment: { value: boolean }) {
    if (!isSegment.value) return;
    if (isSegment.value) {
      isSegment.value = false;
      const paths = document.querySelectorAll(".connection-path-segment");
      paths.forEach((path) => {
        path.classList.remove("connection-path-segment");
        path.classList.add("connection-path");
      });
    }
  }

  getAngle(
    { x: x1, y: y1 }: { x: number; y: number },
    { x: x2, y: y2 }: { x: number; y: number }
  ): number {
    const dx = x1 - x2;
    const dy = y1 - y2;
    return (180 * Math.atan2(dy, dx)) / Math.PI;
  }

  getTransformAlong(
    path: SVGPathElement,
    offset: number,
    delta: number,
    needRotate: boolean
  ): string {
    if (!path || path.getTotalLength() === 0) {
      return "";
    }

    const length = path.getTotalLength() * delta;
    const p1 = path.getPointAtLength(length + offset);
    const p2 = path.getPointAtLength(length);

    const angle = 180 + (needRotate ? this.getAngle(p1, p2) : 0);

    return `translate(${p1.x}, ${p1.y + 3}) rotate(${angle}) scale(0.8)`;
  }

  handleClickEvent(
    event: KeyboardEvent,
    clicked: { value: boolean },
    clickedConnectionId: { value: string | null },
    isSegment: { value: boolean }
  ) {
    const editor = Canvas.getEditor(Canvas.getCurrentID()).getInstance();
    if (
      event.code === "Escape" &&
      typeof this.handleResetSegmentMode === "function" &&
      isSegment.value !== false
    ) {
      this.handleResetSegmentMode(isSegment);
    }

    if (event.code === "Escape") {
      clicked.value = false;
      clickedConnectionId.value = null;
    }

    if (event.code === "Delete" && clickedConnectionId.value) {
      editor.removeConnection(clickedConnectionId.value);
      clickedConnectionId.value = null;
    }
  }

  setItemID(id: string) {
    this.itemID = id;
  }

  getItemID(): string {
    return this.itemID;
  }
}
