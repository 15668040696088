import { ClassicPreset } from "rete";
import ITDAControl from "./ITDAControl";

export default class ITDAControlEXT extends ITDAControl {
  private node: ClassicPreset.Node;
  private values: string[];
  constructor(parent: ClassicPreset.Node) {
    super();
    this.values = [];
    this.node = parent;
  }

  setValues(data: string[]) {
    this.values = data;
  }

  getValues(): string[] {
    return this.values;
  }
}
