import * as d3 from "d3";

export default {
  render: (
    elem: HTMLElement,
    width: number,
    height: number,
    color: string,
    options: any
  ) => {
    const { title } = options;

    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    g.append("text")
      .attr("x", 2 + rectW / 2)
      .attr("y", 2 + rectH / 2)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .text(title ? title : "");
  },
};
