import { Item, ItemType } from "ITDAModelTypes";
import { watch } from "vue";
import { RouteLocationNamedRaw } from "vue-router";
import router from "@/router";
import store from "@/store";
import API from "@/api/internal";

const wait = () => {
  return new Promise<void>((resolve, reject) => {
    try {
      watch(
        () => store.getters["IS_ROUTED"],
        (newVal) => {
          try {
            if (newVal) {
              resolve();
            }
          } catch (err) {
            console.error(err);
            reject(err);
          }
        },
        { immediate: true }
      );
    } catch (err) {
      console.error(err);
      reject(err);
    }
  });
};

export default {
  changePage: async (item: Item) => {
    switch (item.getItemType()) {
      case ItemType.DUTModuleFolder:
        item = item.getDUTModule().getDUTModuleDiagram();
        break;
      case ItemType.SDCModule:
        item = item.getSDCDiagram();
        break;
      case ItemType.UPFModule:
        item = item.getUPFDiagram();
        break;
      default:
        break;
    }
    store.commit("item/SET_CURRENT_ITEM", item);
    const view = API.getItemViewSpec(item);
    const names = [];
    if (view?.diagram) {
      names.push("diagram");
    }
    if (view?.table) {
      names.push("table");
    }
    store.commit("ROUTING_START", names);
    const route: RouteLocationNamedRaw = {
      name: "Item",
      query: {
        id: item.getId(),
        diagram: view?.diagram.toString(),
        table: view?.table.toString(),
        refresh: new Date().getTime(),
      },
    };
    await router.push(route);
    await wait();
    store.commit("navigation/SET_SELECTED_KEYS", item.getId());
  },
};
