/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemHandler from "../ItemHandler";
import ItemType from "../ItemType";
import SFRHandler from "../SFRHandler";

export default class ClockRefCLKMultiplexerEXTEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidPowerDownMode(), this.refreshSFR);
    this.addPropertyListener(item.getFidCustomMode(), this.refreshSFR);
    this.addPropertyListener(item.getFidName(), () => {});
    this.addPropertyListener(item.getFidLast() + 10, this.lstn_mapper_name);
  }

  async refreshSFR(item) {
    const sfrBlock = item.getParent(ItemType.ClockCMU).getSFRBlock();
    const mapper = item
      .getMappers()
      .find((mapper) => mapper.getTarget() === item);
    const specType = "FULL";
    const sfr = mapper.getSource();
    for (const field of sfr.getSFRFields()) {
      await ItemHandler.removeItem(field);
    }

    const sfrSpec = item.getSFRSpec(specType);
    const fields = sfrSpec.sfrs[0].fields;
    let targets = [];
    if (item.getPowerDownMode()) {
      targets = [
        ...targets,
        ...fields.filter((field) => field.index === 16 || field.index === 24),
      ];
    }
    if (item.getCustomMode()) {
      targets = [...targets, ...fields.filter((field) => field.index === 27)];
    }

    for (const field of targets) {
      await SFRHandler.createSFRField(sfr, field);
    }
    sfr.hidden = sfr.getSFRFields().length ? false : true;

    sfr.getEvent().sortSFRFields(sfr);
    sfrBlock.setSorted(false);
    sfrBlock.setSorted(true);
  }

  lstn_mapper_name(item, newVal) {
    item.setName(newVal);
  }

  postProcess(item) {
    item.setName(item.getParent().getName(), false);
  }
}
