<template>
  <div ref="sdc" :style="getSdcStyle" class="sdc" data-testid="sdc"></div>
</template>

<script setup lang="ts">
import API from "@/api/internal";
import { ITDAPLLCTRL } from "@/canvas/nodes";
import { computed, defineProps, onMounted, onUpdated, ref } from "vue";

const props = defineProps<{
  data: any;
  seed: number;
}>();

const width = ref<number>(props.data.node.width);
const height = ref<number>(props.data.node.height);

const getSdcStyle = computed(() => {
  if (props.data.node instanceof ITDAPLLCTRL) {
    return {
      top: `${(height.value / 12) * 9 - 173}px`,
      left: `${(width.value / 12) * 8 - 43}px`,
    };
  } else {
    return {
      top: `${height.value / 2 - 170}px`,
      left: `${width.value - 17}px`,
    };
  }
});

const sdc = ref<HTMLElement | null>(null);
const rerenderSDC = () => {
  const bgColor = API.getItem(props.data.node.getItemID())
    ?.getClockGroup()
    ?.getColor();
  if (sdc.value) {
    if (sdc.value.firstChild) {
      sdc.value?.removeChild(sdc.value.firstChild);
    }
    props.data.render(sdc.value, {
      SDCText: props.data.sdcName,
      SDCBackgroundColor: bgColor,
    });
  }
};
onMounted(() => {
  rerenderSDC();
});

onUpdated(() => {
  width.value = props.data.node.width;
  height.value = props.data.node.height;
  rerenderSDC();
});
</script>

<style lang="sass" scoped>
.sdc
  width: fit-content
  position: absolute
  pointer-events: none
  z-index: 99

  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none
</style>
