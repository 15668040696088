import IF from "../src/ItemFactory";
import ItemType from "../src/ItemType";
import Repository from "../store";

export default {
  createItemInstance: (type: string, obj: object): any => {
    const repo = Repository.getInstance();
    const pllSpec = repo.getExternalPLLSpec();
    const hmSpec = repo.getExternalHMSpec();
    const itemType = ItemType.getObjByKey(type);
    const res = itemType
      ? IF.createItemInstance(itemType, obj, pllSpec, hmSpec)
      : undefined;
    return res;
  },
  getSFRItemTargets: (): any[] => {
    return IF.getSFRItemTargets();
  },
  getConstructor: (type: ItemType) => {
    return IF.getConstructor(type);
  },
};
