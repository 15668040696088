import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, data: { [key in string]: any }) => {
    const { SDCText, SDCBackgroundColor } = data;
    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", 250)
      .attr("height", 160)
      .attr("viewBox", "0 0 250 160")
      .attr("fill", "none");

    svg
      .append("line")
      .attr("x1", "95")
      .attr("y1", "140")
      .attr("x2", "5")
      .attr("y2", "140")
      .attr("stroke", "#FF0000")
      .attr("transform", "rotate(-37, 50, 100)");

    svg
      .append("line")
      .attr("x1", "30")
      .attr("y1", "147")
      .attr("x2", "5")
      .attr("y2", "140")
      .attr("stroke", "#FF0000")
      .attr("transform", "rotate(-37, 50, 100)");

    svg
      .append("line")
      .attr("x1", "30")
      .attr("y1", "133")
      .attr("x2", "5")
      .attr("y2", "140")
      .attr("stroke", "#FF0000")
      .attr("transform", "rotate(-37, 50, 100)");

    const text = svg
      .append("text")
      .attr("x", 100)
      .attr("y", 147)
      .attr("font-size", "20px")
      .attr("fill", "black")
      .attr("textLength", "150")
      .attr("lengthAdjust", "spacingAndGlyphs")
      .attr("fill", "red")
      .text(SDCText || "")
      .attr("transform", "rotate(-37, 50, 100)");

    const bbox = text.node()?.getBBox();

    if (bbox) {
      svg
        .insert("rect", "text")
        .attr("x", bbox.x - 5)
        .attr("y", bbox.y - 1)
        .attr("width", bbox.width + 10)
        .attr("height", bbox.height + 2)
        .attr("fill", SDCBackgroundColor)
        .attr("opacity", 0.5)
        .attr("transform", "rotate(-37, 50, 100)");
    }
  },
};
