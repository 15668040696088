<template>
  <div
    v-if="props.ports.length > 0"
    class="ports"
    :style="getPortsStyle()"
    :data-testid="`${props.position.toLocaleLowerCase()}-ports`">
    <div
      v-for="(port, idx) in props.ports"
      :key="`${props.position.toLocaleLowerCase()}-` + idx + props.seed"
      class="port"
      :style="getPortStyle()"
      :data-testid="`${props.position.toLocaleLowerCase()}-` + idx">
      <div
        v-if="port instanceof ITDAInput"
        class="socket-wrapper"
        data-testid="socket-wrapper">
        <Ref
          v-if="port.showControl"
          class="port-socket"
          :style="getSocketStyle()"
          :data="{
            type: 'socket',
            side: 'input',
            key: port.label,
            nodeId: props.nodeId,
            payload: port.socket,
          }"
          :emit="props.emit"
          :data-testid="`${props.position.toLocaleLowerCase()}-socket`" />
      </div>
      <div v-else class="socket-wrapper" data-testid="socket-wrapper">
        <Ref
          class="port-socket"
          :style="getSocketStyle()"
          :data="{
            type: 'socket',
            side: 'output',
            key: port.label,
            nodeId: props.nodeId,
            payload: port.socket,
          }"
          :emit="props.emit"
          :data-testid="`${props.position.toLocaleLowerCase()}-socket`" />
      </div>
      <div
        v-if="port.getItemID()"
        class="port-name"
        :style="getPortNameStyle()">
        {{ API.getItem(port.getItemID())?.getName() }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Ref } from "rete-vue-plugin";
import { defineProps, onUpdated, ref } from "vue";
import { ITDAPort, ITDAPortPosition } from "@/canvas/common";
import { ITDAInput } from "@/canvas/controls/ITDAControlPort";
import API from "@/api/internal";

const SOCKET_HEIGHT = 10;
const SOCKET_BORDER = 2;

const props = defineProps<{
  ports: ITDAPort[];
  position: ITDAPortPosition;
  emit: (event: string, ...args: any[]) => void;
  nodeId: string;
  seed: number;
  height: number;
  resizeValue?: number;
}>();

const resizeValue = ref<number>(0);

const getPortsStyle = () => {
  const res = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
  };
  switch (props.position) {
    case ITDAPortPosition.TOP:
      Object.assign(res, {
        justifyContent: "center",
        flexDirection: "row",
        bottom: `${SOCKET_BORDER + 0.5}px`,
      });
      break;
    case ITDAPortPosition.BOTTOM:
      Object.assign(res, {
        justifyContent: "center",
        height: `${SOCKET_HEIGHT}px`,
        flexDirection: "row",
        top: `${SOCKET_BORDER + 0.5}px`,
      });
      break;
    case ITDAPortPosition.LEFT:
      Object.assign(res, {
        height: `${props.height}px`,
        right: "1px",
      });
      break;
    case ITDAPortPosition.RIGHT:
      Object.assign(res, {
        height: `${props.height}px`,
        left: "1px",
      });
      break;
    default:
      break;
  }
  return res;
};

const getPortStyle = () => {
  const res = {
    textAlign: "left",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    flex: "1 1",
  };
  return res;
};

const getSocketStyle = () => {
  const res = {};
  switch (props.position) {
    case ITDAPortPosition.TOP:
    case ITDAPortPosition.BOTTOM:
      Object.assign(res, { transform: "rotate(90deg)" });
      break;
    default:
      break;
  }
  return res;
};

const getPortNameStyle = () => {
  const res = {};
  switch (props.position) {
    case ITDAPortPosition.TOP:
      Object.assign(res, {
        top: "20px",
        width: "80px",
        display: "flex",
        justifyContent: "center",
      });
      break;
    case ITDAPortPosition.RIGHT:
      Object.assign(res, { right: "20px", zIndex: 10 });
      break;
    case ITDAPortPosition.BOTTOM:
      Object.assign(res, {
        bottom: "20px",
        width: "80px",
        display: "flex",
        justifyContent: "center",
      });
      break;
    case ITDAPortPosition.LEFT:
      Object.assign(res, { left: "20px", zIndex: 10 });
      break;
    default:
      break;
  }
  return res;
};

onUpdated(() => {
  if (props.resizeValue) resizeValue.value = props.resizeValue;
});
</script>

<style lang="sass" scoped>
.socket-wrapper
  height: inherit
  flex-wrap: nowrap
  flex-direction: row
  align-items: center
  justify-content: center
.port-name
  position: absolute
  font-size: 0.7rem
</style>
