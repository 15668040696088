import API from "@/api/internal";
import { Context } from "../types";
import { Item, ItemType } from "ITDAModelTypes";

export const connectionpick = async (context: Context): Promise<boolean> => {
  const item = API.getItem(context.options.node.getItemID());
  if (item) {
    switch (item.getItemType()) {
      case ItemType.PowerSequenceWrite:
      case ItemType.PowerSequenceReadWait:
      case ItemType.PowerSequenceWait:
      case ItemType.PowerSequenceIf: {
        const seqOutput = item
          .getOutputs()
          .find((output: Item) => output.getKey() === "SEQ_OUTPUT");
        if (seqOutput && seqOutput.getConnections()?.length > 0) {
          for (const conn of seqOutput.getConnections()) {
            if (
              conn?.getTargetInput().getParent().getItemType() ===
              ItemType.PowerSequenceBack
            ) {
              return false;
            }
          }
        }
        return true;
      }
      case ItemType.PowerSequenceLabel:
        return false;
      default:
        return true;
    }
  }
  return true;
};
