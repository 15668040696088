/**
 *  Generated by Modeler
 *  timestamp: 2023-04-07 16:24:16.041753
 */
//import ErrorType from '@/model/ErrorType'
import ItemChecker from "@/model/src/ItemChecker";

export default class VoltageLevelFolderChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidSelectedConfiguration(), this.checkSelectedConfiguration, ErrorType.NAME)
  }
  checkSelectedConfiguration() {
    // TBD
  }
}
