/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import PowerAPMPWRDOWNEvent from '@/model/src/events/PowerAPMPWRDOWNEvent'
import PowerAPMPWRDOWNChecker from '@/model/src/checkers/PowerAPMPWRDOWNChecker'
export default class PowerAPMPWRDOWN extends Item {
    FID_POWERAPMPWRDOWN_NAME = 0
    FID_POWERAPMPWRDOWN_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerAPMPWRDOWN"}))
        
        this.setEvent(new PowerAPMPWRDOWNEvent(this))
        this.setChecker(new PowerAPMPWRDOWNChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerAPMPWRDOWN"
    }

    getSchema() {
        return {"name": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERAPMPWRDOWN_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERAPMPWRDOWN_NAME
    }
    

    getFidLast() {
        return this.FID_POWERAPMPWRDOWN_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERAPMPWRDOWN_NAME:
                return "name"

            default:
                return ""
        }
    }


}