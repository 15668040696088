import * as ITDAControl from "../controls";
import * as ITDANodeRenderer from "./renderers";
import { NodeType } from "../types";
import ITDANodeClock from "./ITDANodeClock";
import CanvasAPI from "../api";
import { DiagramType } from "../types";

export default class ITDAGATE extends ITDANodeClock {
  constructor(name = "") {
    super(name, NodeType.ITDAGATE);
    this.render = ITDANodeRenderer.ITDAGATE.render;
  }

  async data(
    inputs: Record<`CLK_INPUT`, any>
  ): Promise<
    | Record<`CLK_OUTPUT` | `QCH_OUTPUT`, any>
    | Record<`CLK_OUTPUT_${number}`, any>
  > {
    const freq = this.controls.freq as ITDAControl.ITDAControlFrequency;
    let frequency = this.getFrequencyInitValue();
    if (Object.keys(inputs).length !== 0) {
      const inputFreq = inputs.CLK_INPUT[0];
      frequency = inputFreq;
    }

    if (frequency) {
      freq.setFrequency(frequency);
      this.update();
    }
    const editor = CanvasAPI.getEditor();
    if (editor.getDiagramType() === DiagramType.IP) {
      return {
        CLK_OUTPUT: freq.getFrequency(),
        QCH_OUTPUT: freq.getFrequency(),
      };
    } else {
      return { CLK_OUTPUT: freq.getFrequency() };
    }
  }
}
