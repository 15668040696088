/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import InstanceHandler from "../InstanceHandler";
import ItemType from "../ItemType";
import ItemHandler from "../ItemHandler";

export default class ClockMultiplexerEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), () => {});
    this.addPropertyListener(item.getFidNumberOfParents(), this.lstn_parents);
  }

  async lstn_parents(item, newVal, oldVal) {
    oldVal = oldVal || 0;
    if (Number(newVal) > Number(oldVal)) {
      for (let i = Number(oldVal); i < Number(newVal); i++) {
        await InstanceHandler.createGFMUXPort(item, i);
      }
    } else {
      for (let i = Number(oldVal) - 1; i >= Number(newVal); i--) {
        InstanceHandler.removeGFMUXPort(item, i);
      }
    }
    ItemHandler.refreshSFRInfo(item);
  }

  postProcess(item) {
    super.postProcess(item);
    return this.setupPorts(item);
  }

  setupPorts(item) {
    let numPorts;
    if (item.getItemType() === ItemType.SDCPhantom) {
      const origin = item.getOriginItem();
      numPorts = origin ? Number(origin.getNumberOfParents()) : 0;
    } else {
      numPorts = Number(item.getNumberOfParents());
    }
    return [
      ...InstanceHandler.createGFMUXPorts(item, numPorts),
      item.newOutput({
        key: `CLK_${ItemType.Output.getKey().toUpperCase()}`,
        socket: "CLK",
      }).promise,
    ];
  }
}
