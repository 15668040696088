import { CanvasState } from "./types";

const state = (): CanvasState => ({
  canvasStyles: {},
});

const mutations = {
  SET_PATH_STYLE(
    state: CanvasState,
    payload: { canvasId: string; style: "line" | "curve" }
  ) {
    state.canvasStyles[payload.canvasId] = payload.style;
  },
};

const getters = {
  GET_PATH_STYLE: (state: CanvasState) => (canvasId: string) => {
    return state.canvasStyles[canvasId] || "line";
  },
};

const actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
