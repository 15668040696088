import { Item, ItemState } from "../types";
import API from "@/api/internal";

const state = (): ItemState => ({
  items: [],
  currentItem: undefined,
  currentNodeItem: undefined,
  topItem: undefined,
  errorMsgMap: undefined,
});

const mutations = {
  SET_TOP_ITEM(state: ItemState, item: Item | undefined) {
    state.topItem = item;
  },
  SET_CURRENT_ITEM(state: ItemState, item: Item) {
    if (!state.currentItem || state.currentItem !== item) {
      state.currentItem = item;
      API.setCurrentItem(item);
    }
  },
  SET_CURRENT_NODE_ITEM(state: ItemState, item: Item) {
    if (!state.currentNodeItem || state.currentNodeItem !== item) {
      state.currentNodeItem = item;
      API.setCurrentNodeItem(item);
    }
  },
};

const getters = {
  GET_ALL_ITEMS(state: ItemState) {
    return state.topItem ? [state.topItem] : [];
  },
  GET_CURRENT_ITEM(state: ItemState) {
    return state.currentItem;
  },
  GET_CURRENT_NODE_ITEM(state: ItemState) {
    return state.currentNodeItem;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
