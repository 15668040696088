import { AreaPlugin, Area2D } from "rete-area-plugin";
import { CommentPlugin } from "rete-comment-plugin";
import { ConnectionPlugin } from "rete-connection-plugin";
import { DockPlugin } from "rete-dock-plugin";
import { DataflowEngine, ControlFlowEngine } from "rete-engine";
import { HistoryPlugin } from "rete-history-plugin";
import { VueArea2D, VuePlugin } from "rete-vue-plugin";
import { ScopesPlugin } from "rete-scopes-plugin";
import * as ITDACommon from "../common";
import { CustomReroutePlugin } from "./utils/ITDACustomReRoute";
import ITDACustomEditor from "./utils/ITDACustomEditor";

export {
  ITDACustomEditor,
  AreaPlugin,
  Area2D,
  CommentPlugin,
  ConnectionPlugin,
  DockPlugin,
  ControlFlowEngine,
  HistoryPlugin,
  VuePlugin,
  ScopesPlugin,
};
export { ITDACommon };
export type AreaExtra = VueArea2D<ITDACommon.Schemes>;

export type ITDAPluginType =
  | ITDACustomEditor<ITDACommon.Schemes>
  | AreaPlugin<ITDACommon.Schemes, AreaExtra>
  | CommentPlugin<ITDACommon.Schemes, AreaExtra>
  | ConnectionPlugin<ITDACommon.Schemes, AreaExtra>
  | CustomReroutePlugin<ITDACommon.Schemes>
  | DockPlugin<ITDACommon.Schemes>
  | DataflowEngine<ITDACommon.Schemes>
  | HistoryPlugin<ITDACommon.Schemes>
  | VuePlugin<ITDACommon.Schemes, AreaExtra>
  | ScopesPlugin<ITDACommon.Schemes>;

interface ITDAPlugin {
  use?: () => void;
  event?: () => void;
  getInstance(): ITDAPluginType;
}
export { ITDAPlugin };

export class ITDACanvasType {
  private index: number;
  private key: string;

  static Editor = new ITDACanvasType(0, "NodeEditor");
  static Area = new ITDACanvasType(0, "Area");
  static Comment = new ITDACanvasType(0, "Comment");
  static Connection = new ITDACanvasType(0, "Connection");
  static ConnectionPath = new ITDACanvasType(0, "ConnectionPath");
  static ConnectionReRoute = new ITDACanvasType(0, "ConnectionReRoute");
  static Dock = new ITDACanvasType(0, "Dock");
  static DataFlow = new ITDACanvasType(0, "DataFlow");
  // static ControlFlow = new ITDACanvasType(0, "ControlFlow", "controlflow");
  static History = new ITDACanvasType(0, "History");
  static Render = new ITDACanvasType(0, "Vue");
  static Scopes = new ITDACanvasType(0, "Scopes");

  private constructor(index: number, key: string) {
    this.index = index;
    this.key = key;
  }

  static getKeys(): string[] {
    return Object.keys(this);
  }

  static getValues(): ITDACanvasType[] {
    return Object.values(this);
  }

  static getEntries(): [string, ITDACanvasType][] {
    return Object.entries(this);
  }

  static getObjByKey(
    key: keyof typeof ITDACanvasType
  ): ITDACanvasType | undefined {
    return this[key] as ITDACanvasType;
  }

  public getKey() {
    return this.key;
  }
}
