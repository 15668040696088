<template>
  <div class="property-title">Properties</div>
  <ItemProperty :key="seed" :item="item" :style="stylePropertyNode" />
  <ItemProperty
    v-if="needToChildProp()"
    :key="seed"
    :item="getChildItem()"
    :isExt="true"
    class="property-node-ext" />
</template>

<script setup lang="ts">
import { defineProps, computed } from "vue";
import { Item } from "ITDAModelTypes";
import ItemProperty from "@/components/item/ItemProperty.vue";
const props = defineProps<{
  item: Item;
  seed: string;
}>();

const stylePropertyNode = computed(() => {
  return {
    paddingBottom: needToChildProp() ? "0" : "",
  };
});

const needToChildProp = () => {
  return props.item
    ?.getChildren()
    .find(
      (cItem: Item) => cItem.getItemType().getCategory() === "ClockNodeSub"
    );
};

const getChildItem = () => {
  return props.item[`get${props.item.getItemType().getKey()}EXT`]();
};
</script>

<style lang="sass" scoped>
.property-title
    width: inherit
    margin-top: 16px
    padding-left: 16px
    font-weight: bolder
    font-size: medium
</style>
