import { ItemType } from "ITDAModelTypes";
import ItemHandler from "../../ItemHandler";

export const copyPowerSequenceDiagram = async (
  configuration,
  targetDiagramId
) => {
  const targetDiagram = configuration
    .getPowerSequenceDiagrams()
    .find((diagram) => {
      return diagram.getId() === targetDiagramId;
    });
  if (targetDiagram && targetDiagram.getCopied()) {
    await clearSequenceDiagram(targetDiagram);
    await createSequences(targetDiagram);
    targetDiagram.setCopied(null);
  }
};

const clearSequenceDiagram = async (item) => {
  for (const child of item.getChildren()) {
    const type =
      child.getItemType().getKey().charAt(0).toUpperCase() +
      child.getItemType().getKey().slice(1);
    await item[`rem${type}`](child);
  }
};

const createSequences = async (item) => {
  await createSequenceNodes(item);
  await updateProperties(item);
  await createSequenceConnections(item);
};

export const createSequenceNodes = async (item) => {
  const newElems = [];
  const promises = [];
  const copied = item.getCopied();
  const sequenceDiagramElements = copied.getChildren();
  for (const elem of sequenceDiagramElements) {
    if (elem.getItemType() !== ItemType.Connection) {
      const props = elem.exportProperties();
      const { item: newElem, promise } = ItemHandler.createItem(
        item,
        elem.getItemType(),
        props
      );

      elem.setPhantom(newElem);
      newElems.push({ copied: newElem, origin: elem });
      promises.push(promise);
    }
  }

  await Promise.all(promises);

  newElems.forEach((elem) => {
    const nodeMetaView = elem.copied?.getNodeMetaView();
    elem.copied.setNodeType(elem.origin.getNodeType());
    nodeMetaView.setX(elem.origin.getNodeMetaView().getX() || 0);
    nodeMetaView.setY(elem.origin.getNodeMetaView().getY() || 0);
  });
};

export const updateProperties = async (item) => {
  const copied = item.getCopied();
  const sequenceDiagramElements = copied.getChildren();

  sequenceDiagramElements.forEach((origin) => {
    if (origin.getItemType() === ItemType.PowerSequenceLabelRef) {
      const originLabel = origin.getItemLabel();
      if (originLabel) {
        origin.getPhantom().setItemLabel(originLabel.getPhantom());
      }
    }

    if (origin.getItemType() === ItemType.PowerSequenceLabel) {
      const originLabelRefs = origin.getLabelRefs();
      const phantomLabelRefs = originLabelRefs.map((labelRef) =>
        labelRef.getPhantom()
      );
      origin.getPhantom().setLabelRefs(phantomLabelRefs);
    }
  });
};

export const createSequenceConnections = async (item) => {
  const promises = [];
  const copied = item.getCopied();
  const sequenceDiagramElements = copied.getChildren();
  for (const elem of sequenceDiagramElements) {
    if (elem.getItemType() === ItemType.Connection) {
      const sourceOutput = elem
        .getSourceOutput()
        .getParent()
        .getPhantom()
        .getOutputs()
        .find((output) => {
          return output.getKey() === elem.getSourceOutput().getKey();
        });
      const targetInput = elem
        .getTargetInput()
        .getParent()
        .getPhantom()
        .getInputs()
        .find((input) => {
          return input.getKey() === elem.getTargetInput().getKey();
        });

      const { item: newConn, promise } = item.newConnection({
        sourceOutput: sourceOutput.getId(),
        targetInput: targetInput.getId(),
      });
      promises.push(promise);
    }
  }

  await Promise.all(promises);
};
