/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";

export default class ClockCMUChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
    this.addChecker(item.getFidRefCLK(), this.checkRefCLK, ErrorType.NAME);
    this.addChecker(item.getFidPCLK(), this.checkPCLK, ErrorType.NAME);
    //this.addChecker(item.getFidJtag(), this.checkJtag, ErrorType.NAME)
    //this.addChecker(item.getFidCustom(), this.checkCustom, ErrorType.NAME)
    //this.addChecker(item.getFidExternalAsyncType(), this.checkExternalAsyncType, ErrorType.NAME)
  }
  checkName() {
    // TBD
  }
  checkRefCLK(item) {
    if (!item.getRefCLK()) {
      this.newError(`Reference Clock must be selected`);
    }
  }
  checkPCLK(item) {
    if (!item.getPCLK()) {
      this.newError(`PCLK must be selected`);
    }
  }
  checkJtag() {
    // TBD
  }
  checkCustom() {
    // TBD
  }
  checkExternalAsyncType() {
    // TBD
  }
}
