// import CanvasUtils from "../canvas/CanvasUtils";
import ItemHandler from "./ItemHandler";
import ItemType from "./ItemType";
import LabelType from "./LabelType";
import Repository from "../store";

export default class SDCHandler {
  static genericSpecs = null;

  static setGenericSpec(spec) {
    SDCHandler.genericSpecs = spec;
  }

  static getGenericSpecs() {
    return SDCHandler.genericSpecs;
  }

  static isSDCTarget(node) {
    if (!node || !CanvasUtils.getCurrentCanvas()) {
      return false;
    }
    const diagram = CanvasUtils.getCurrentCanvas().getDiagram();
    if (diagram && diagram.getItemType() === ItemType.SDCDiagram) {
      return (
        node.name === ItemType.ClockExternalCLKSource.getKey() ||
        node.name === ItemType.ClockPLLCtrl.getKey() ||
        node.name === ItemType.ClockRefCLKMultiplexer.getKey() ||
        node.name === ItemType.ClockMultiplexer.getKey() ||
        node.name === ItemType.ClockDivider.getKey() ||
        node.name === ItemType.ClockNMDivider.getKey() ||
        node.name === ItemType.ClockGate.getKey() ||
        node.name === ItemType.ClockBuffer.getKey() ||
        (node.name === ItemType.LabelRef.getKey() &&
          (node.getItem()
            ? node.getItem().getType() === LabelType.EXTERNAL.getKey()
            : false))
      );
    } else {
      return false;
    }
  }

  static async createPhantomNodeItems(sdcInstance, cmu) {
    if (!cmu) {
      return;
    }
    const cmuDiagrams = [
      ...cmu.getClockDiagramFolder().getClockDiagrams(),
      cmu.getClockIPDiagram(),
    ];

    for (const diagram of cmuDiagrams) {
      const { item: SDCPhantomGroup, promise } = ItemHandler.createItem(
        sdcInstance,
        ItemType.SDCPhantomGroup,
        {
          originItem: diagram.getId(),
        }
      );
      await promise;

      for (const obj of diagram.getChildren()) {
        if (
          obj.getItemType() !== ItemType.Connection &&
          obj.getItemType() !== ItemType.ClockQChannelManager &&
          obj.getItemType() !== ItemType.ClockQChannelInfo
        ) {
          const { item: phantomNode, promise } = ItemHandler.createItem(
            SDCPhantomGroup,
            ItemType.SDCPhantom,
            {
              originItem: obj.getId(),
            }
          );
          await promise;
          obj.setPhantom(phantomNode.getId());

          if (obj.getItemType() === ItemType.ClockIP) {
            for (const clockInput of obj.getClockIPInputs()) {
              const { item: input, promise } = ItemHandler.createItem(
                phantomNode,
                ItemType.Input,
                {
                  name: clockInput.getName(),
                  key: clockInput.getKey(),
                  socket: clockInput.getSocket(),
                }
              );
              await promise;
            }
          }
        }
      }
    }
  }

  static async createPhantomConnections(sdcDiagram, cmu) {
    if (!cmu) {
      return;
    }
    const cmuDiagrams = [
      ...cmu.getClockDiagramFolder().getClockDiagrams(),
      cmu.getClockIPDiagram(),
    ];
    for (const diagram of cmuDiagrams) {
      for (const conn of diagram.getChildren()) {
        if (conn.getItemType() === ItemType.Connection) {
          const sourceOutput = conn.getSourceOutput();
          const targetInput = conn.getTargetInput();
          if (sourceOutput && targetInput) {
            const source = sourceOutput.getParent();
            const target = targetInput.getParent();
            const sourcePT = source.getPhantom();
            const targetPT = target.getPhantom();
            if (target.getItemType() !== ItemType.ClockQChannelManager) {
              const outputPT = sourcePT
                .getOutputs()
                .find((output) => output.getKey() === sourceOutput.getKey());
              const inputPT = targetPT
                .getInputs()
                .find((input) => input.getKey() === targetInput.getKey());
              const { item, promise } = ItemHandler.createItem(
                sdcDiagram,
                ItemType.Connection,
                {
                  sourceOutput: outputPT.getId(),
                  targetInput: inputPT.getId(),
                }
              );
              await promise;
            }
          }
        }
      }
    }
  }

  static setSDCPath(item, origin) {
    if (
      origin.getItemType() === ItemType.LabelRef ||
      origin.getItemType() === ItemType.ClockExternalCLKSource
    ) {
      item.setPath("");
      return;
    }
    if (origin.getSDCPath) {
      let sdcPath = origin.getSDCPath();
      const cmu = origin.getParent(ItemType.ClockCMU);
      sdcPath = sdcPath
        .replace(/{{CMU}}/g, cmu.getName())
        .replace(/{{NODE}}/g, origin.getName());
      const strs = sdcPath.split("$POINT$");
      if (strs.length > 1) {
        sdcPath = strs[0] + this.getSDCPoint(strs[1]);
      }
      item.setPath(sdcPath);
    }
  }

  static getSDCPoint(genericInfo) {
    const repo = Repository.getInstance();
    const genericSpec = repo.getExternalGenericSpec();
    const [genericName, genericPort] = genericInfo.split("$");
    const modules = [];
    for (const generic of genericSpec) {
      modules.push(...generic.modules);
    }
    const module = modules.find((s) => s.name === genericName);
    const port = module.syn.sdc.create_clock[genericPort];
    if (!module || !port) {
      return "";
    }
    return port;
  }
  // static createSDCVue(node, template, style, isArrow) {
  //   const meta = { ...style };
  //   const item = node.getItem();
  //   const canvas = CanvasUtils.getCurrentCanvas();
  //   if (canvas) {
  //     if (isArrow && item.getItemType() === ItemType.PLLCtrl) {
  //       meta.width *= 2;
  //       meta.height *= 2;
  //     }
  //     // meta.vue = this._createVue(canvas, template, node, "arrow", meta.width, meta.height)
  //     meta.vue = CanvasUtils.createVue(
  //       canvas,
  //       template,
  //       node,
  //       "arrow",
  //       meta.width,
  //       meta.height
  //     );
  //   }
  //   return meta;
  // }
  // static _createVue(canvas, template, node, type, width, height) {
  //     const parentElem = canvas.getVueComponent().$el.querySelector("div")
  //     return canvas.createVue(
  //         parentElem,
  //         template,
  //         {
  //             node: node,
  //             id: `${type}${node.getItem().getId()}`,
  //             width: width,
  //             height: height
  //         }
  //     ).$children[0]
  // }

  // static updateSDCStyle(node, el, arrow, text) {
  //   const item = node.getItem();
  //   if (!arrow.vue || !text.vue) {
  //     return;
  //   }
  //   if (this.getSDCValue(item, node)) {
  //     arrow.vue.isShow = true;
  //     text.vue.isShow = true;
  //   } else {
  //     arrow.vue.isShow = false;
  //     text.vue.isShow = false;
  //   }
  //   const transform = window
  //     .getComputedStyle(el.parentElement)
  //     .getPropertyValue("transform");
  //   var left = el.clientWidth;
  //   var bottom = el.clientHeight;
  //   if (item.getItemType() === ItemType.PLLCtrl) {
  //     this._updateVuePosition(
  //       arrow.vue,
  //       transform,
  //       null,
  //       left / 2 + 5,
  //       (-bottom * 3) / 4 + 10
  //     );
  //     this._updateVuePosition(
  //       text.vue,
  //       transform,
  //       -2,
  //       left / 2 + 5 + arrow.width,
  //       (-bottom * 3) / 4 + 10 + arrow.height
  //     );
  //     // arrow.vue.zIndex = null
  //     // arrow.vue.transform = `translate(${node.position[0]+el.clientWidth/2+5}px, ${node.position[1]+Math.abs(el.clientHeight)*3/4-40}px)`
  //     // text.vue.zIndex = -2
  //     // text.vue.transform = `translate(${node.position[0]+el.clientWidth+5+arrow.width}px, ${node.position[1]+Math.abs(el.clientHeight)*3/4-40-arrow.height}px)`
  //   } else {
  //     this._updateVuePosition(
  //       arrow.vue,
  //       transform,
  //       -2,
  //       left + 5,
  //       -bottom / 2 + 10
  //     );
  //     this._updateVuePosition(
  //       text.vue,
  //       transform,
  //       -2,
  //       left + 5 + arrow.width,
  //       -bottom / 2 + 10 + arrow.height
  //     );
  //     // arrow.vue.zIndex = -2
  //     // arrow.vue.transform = `translate(${node.position[0]+el.clientWidth+5}px, ${node.position[1]+Math.abs(el.clientHeight)/2-40}px)`
  //     // text.vue.zIndex = -2
  //     // text.vue.transform = `translate(${node.position[0]+el.clientWidth+5+arrow.width}px, ${node.position[1]+Math.abs(el.clientHeight)/2-40-arrow.height}px)`
  //   }
  // }
  // static _updateVuePosition(component, transform, zIndex, left, bottom) {
  //   component.zIndex = zIndex;
  //   component.transform = transform;
  //   component.left = `${left}px`;
  //   component.bottom = `${bottom}px`;
  // }

  // static getSDCValue(item, node) {
  //   const canvas = CanvasUtils.getCurrentCanvas();
  //   if (canvas && canvas.getDiagram().getItemType() === ItemType.SDCDiagram) {
  //     if (!node) {
  //       node = canvas.getEditor().selected.list[0];
  //     }
  //     const instance = canvas.getInstanceByNode(node);
  //     if (instance) {
  //       var sdc = instance
  //         .getSDCs()
  //         .find((sdc) => sdc.getItemRef() === item.getId());
  //       if (!sdc) {
  //         sdc = ItemHandler.createItem(instance, ItemType.SDC, {
  //           value: false,
  //           path: this._getSDCDefaultPath(item, item.getSDCPath()),
  //           itemRef: item.getId(),
  //         }).item;
  //       }
  //       return sdc.getValue();
  //     }
  //   }
  // }
  // static setSDCValue(item, value) {
  //   const canvas = CanvasUtils.getCurrentCanvas();
  //   const node = canvas.getEditor().selected.list[0];
  //   const instance = canvas.getInstanceByNode(node);
  //   if (instance) {
  //     const sdc = instance
  //       .getSDCs()
  //       .find((sdc) => sdc.getItemRef() === item.getId());
  //     sdc.setValue(value);
  //     node.vueContext.updateSDC(node);
  //   }
  // }

  // static getSDCExternal(item, node) {
  //   const canvas = CanvasUtils.getCurrentCanvas();
  //   if (!node) {
  //     node = canvas.getEditor().selected.list[0];
  //   }
  //   const instance = canvas.getInstanceByNode(node);
  //   if (instance) {
  //     var sdc = instance
  //       .getSDCs()
  //       .find((sdc) => sdc.getItemRef() === item.getId());
  //     if (!sdc) {
  //       sdc = ItemHandler.createItem(instance, ItemType.SDC, {
  //         value: false,
  //         path: this._getSDCDefaultPath(item, item.getSDCPath()),
  //         itemRef: item.getId(),
  //       }).item;
  //       console.log(sdc);
  //     }
  //     return sdc.getExternal();
  //   }
  // }
  // static setSDCExternal(item, value) {
  //   const canvas = CanvasUtils.getCurrentCanvas();
  //   const node = canvas.getEditor().selected.list[0];
  //   const instance = canvas.getInstanceByNode(node);
  //   const sdc = instance
  //     .getSDCs()
  //     .find((sdc) => sdc.getItemRef() === item.getId());
  //   sdc.setExternal(value);
  // }

  // static isExternalSDC(item) {
  //   if (item) {
  //     return (
  //       item.getItemType() === ItemType.ExternalCLKSource ||
  //       item.getItemType() === ItemType.PLLCtrl ||
  //       (item.getItemType() === ItemType.LabelRef &&
  //         item.getType() === LabelType.EXTERNAL.getKey()) ||
  //       (item.getECC && item.getECC())
  //     );
  //   }
  // }
  // static getSDCPath(item, node) {
  //   const canvas = CanvasUtils.getCurrentCanvas();
  //   if (!node) {
  //     node = canvas.getEditor().selected.list[0];
  //   }
  //   const instance = canvas.getInstanceByNode(node);
  //   if (instance) {
  //     var sdc = instance
  //       .getSDCs()
  //       .find((sdc) => sdc.getItemRef() === item.getId());
  //     if (!sdc) {
  //       sdc = ItemHandler.createItem(instance, ItemType.SDC, {
  //         value: false,
  //         path: this._getSDCDefaultPath(item, item.getSDCPath()),
  //         itemRef: item.getId(),
  //       }).item;
  //     }
  //     if (!SDCHandler.isExternalSDC(item)) {
  //       sdc.setPath(
  //         this._getSDCDefaultPath(item, item.getSchema().SDCPath.default)
  //       );
  //     }
  //     return this.getSDCDisplayPath(instance, item, sdc.getPath());
  //   }
  // }
  // static setSDCPath(item, value) {
  //   const canvas = CanvasUtils.getCurrentCanvas();
  //   const node = canvas.getEditor().selected.list[0];
  //   const instance = canvas.getInstanceByNode(node);
  //   const sdc = instance
  //     .getSDCs()
  //     .find((sdc) => sdc.getItemRef() === item.getId());
  //   sdc.setPath(value);
  // }

  // static _getSDCDefaultPath(item, path) {
  //   if (item.getECC && item.getECC()) {
  //     return "";
  //   }
  //   if (item.getItemType() === ItemType.Multiplexer) {
  //     return this._getRefinedNumberOfParents(Number(item.getNumberOfParents()))
  //       ? path.replace("OR2", "OR4")
  //       : path;
  //   }
  //   return path;
  // }
  // static _getRefinedNumberOfParents(num) {
  //   const res = num >> 2;
  //   return res > 1 ? this._getRefinedNumberOfParents(res) : res;
  // }
  // static getSDCDisplayPath(instance, item, path) {
  //   if (path) {
  //     const strs = path.split("$POINT$");
  //     if (strs.length > 1) {
  //       const generic = strs[1].split("$");
  //       path = `${strs[0]}${SDCHandler._getSDCPoint(generic[0], generic[1])}`;
  //     } else {
  //       path = strs[0];
  //     }
  //   }
  //   return path
  //     ? path
  //         .replace(
  //           /{{CMU}}/gi,
  //           `${instance.getName()}/${item
  //             .getParent(ItemType.CMU)
  //             .getName()}__CORE`
  //         )
  //         .replace(/{{NODE}}/gi, item.getTitle())
  //     : "";
  // }

  // static _getSDCPoint(name, port) {
  //   for (const group of SDCHandler.getGenericSpecs()) {
  //     let module = group.modules.find((module) => module.name === name);
  //     if (module) {
  //       return module.vendor.sdc.create_clock[port];
  //     }
  //   }
  //   return "??";
  // }

  // static getSDCInstanceName(instance) {
  //   const hierarchy = this._getPowerDomainHierarchyPath(
  //     instance.getParent(ItemType.PowerDomain)
  //   );
  //   return hierarchy
  //     ? `CLOCK_${hierarchy}_${instance.getName()}`
  //     : `CLOCK_${instance.getName()}`;
  // }

  // static _getPowerDomainHierarchyPath(pd) {
  //   if (!pd) {
  //     return "";
  //   }
  //   if (pd.getParent() === ItemHandler.getTopItem()) {
  //     return "";
  //   } else {
  //     const upper = this._getPowerDomainHierarchyPath(
  //       pd.getParent(ItemType.PowerDomain)
  //     );
  //     return upper ? `${upper}_${pd.getName()}` : pd.getName();
  //   }
  // }
}
