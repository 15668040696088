/**
 *  Generated by Modeler
 *  timestamp: 2023-07-25 16:58:29.538289
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class SDCDiagramEvent extends ItemEvent {
  constructor(item) {
    super(item);
    // this.addPropertyListener(item.getFidName(), this.lstn_name)
    // this.addPropertyListener(item.getFidType(), this.lstn_type)
    // this.addPropertyListener(item.getFidCanvasData(), this.lstn_canvasData)
  }
  // lstn_name(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
  // lstn_type(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
  // lstn_canvasData(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
}
