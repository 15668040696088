/**
 *  Generated by Modeler
 *  timestamp: 2022-12-21 20:22:35.185400
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class VoltageDomainEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
  }
  lstn_name(item, newVal) {
    item.setTitle(newVal);
  }
}
