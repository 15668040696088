<template>
  <v-container>
    <v-card hover class="mx-auto" min-width="328px" elevation="0">
      <v-card-title class="text-left">
        <h4>Create DB</h4>
      </v-card-title>
      <v-row class="ma-2">
        <v-col cols="12" md="6">
          Design Name
          <v-text-field
            v-model="createdDesignName"
            dense
            placeholder="Design Name"
            color="#1e41cd"
            variant="outlined"
            :hide-details="true"
            @update:modelValue="setCreatedDesignName"
            @keydown.enter="
              openConfirmDialog('design', createdDesignName)
            "></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          Config Name
          <v-text-field
            v-model="createdConfigName"
            dense
            placeholder="Config Name"
            color="#1e41cd"
            variant="outlined"
            :hide-details="true"
            @update:modelValue="setCreatedConfigName"
            @keydown.enter="
              openConfirmDialog('config', createdConfigName)
            "></v-text-field>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="isDialogOpen" max-width="500">
      <v-card class="confirm">
        <v-card-title class="headline">Create {{ dialogType }} DB</v-card-title>
        <v-card-text>
          {{ dialogType }} DB "{{ dialogName }}" will be created. Do you want to
          proceed?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="confirm-button" @click="createDB">Check</v-btn>
          <v-btn class="cancel-button" @click="closeDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup lang="ts">
import { ref, defineEmits } from "vue";
import API from "@/api";

const emit = defineEmits(["targetUpdated"]);

const createdDesignName = ref("");
const createdConfigName = ref("");
const isDialogOpen = ref(false);
const dialogType = ref<"design" | "config">("design");
const dialogName = ref("");

const setCreatedDesignName = (value: string) => {
  createdDesignName.value = value;
};
const setCreatedConfigName = (value: string) => {
  createdConfigName.value = value;
};

const openConfirmDialog = (type: "design" | "config", name: string) => {
  if (!name) return;
  dialogType.value = type;
  dialogName.value = name;
  isDialogOpen.value = true;
};

const closeDialog = () => {
  isDialogOpen.value = false;
};

const createDB = async () => {
  const db = API.exportEmptyDB(Date.now(), dialogType.value);
  await API.save(dialogName.value, dialogType.value, db);
  emit("targetUpdated");
  closeDialog();
};
</script>

<style lang="css" scoped>
.f-12 {
  font-size: 12px;
}
.text-left {
  margin-left: 3px;
}
.confirm {
  border-radius: 10px !important;
}
.confirm-button {
  background: #1e41cd;
  color: white;
}
.cancel-button {
  background: white;
  color: #1e41cd;
  border: 1px solid #1e41cd;
}
</style>
