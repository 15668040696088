import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    if (!color) {
      return;
    }
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const baseColor = color;
    // const hoverColor = d3.hsl(baseColor);
    // hoverColor.l = Math.min(hoverColor.l + 0.05, 1);

    const component = svg
      .append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("fill", baseColor)

      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    // component
    //   .on("mouseover", function () {
    //     d3.select(this).attr("fill", hoverColor.toString());
    //   })
    //   .on("mouseout", function () {
    //     d3.select(this).attr("fill", baseColor);
    //   });
  },
};
