/**
 *  Generated by Modeler
 *  timestamp: 2024-11-05 01:00:29.421806
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class SDCClockGroupEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), () => {});
    this.addPropertyListener(item.getFidColor(), () => {});
  }
  // lstn_name(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
  // lstn_color(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
}
