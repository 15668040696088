/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import ConfigurationFolderEvent from '@/model/src/events/ConfigurationFolderEvent'
import ConfigurationFolderChecker from '@/model/src/checkers/ConfigurationFolderChecker'
export default class ConfigurationFolder extends Item {
    FID_CONFIGURATIONFOLDER_SELECTEDCONFIGURATION = 0
    FID_CONFIGURATIONFOLDER_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ConfigurationFolder"}))
        
        this.setEvent(new ConfigurationFolderEvent(this))
        this.setChecker(new ConfigurationFolderChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "Configuration"
        }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ConfigurationFolder"
    }

    getSchema() {
        return {"selectedConfiguration": {"type": "item", "label": "Selected Configuration", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"selectedConfiguration": {"type": "item", "label": "Selected Configuration", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "Configuration", "auto": false, "searchable": false, "title": "Configuration List", "headers": [{"title": "Name", "align": "start", "filterable": true, "value": "name", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setSelectedConfiguration(selectedConfiguration, event=true) {
        if ((selectedConfiguration !== null) && (selectedConfiguration !== undefined)) {
            if (!(selectedConfiguration instanceof Item) && (selectedConfiguration.constructor !== String)) {
                throw `selectedConfiguration(${selectedConfiguration}) must be Item or String type`
            }
        }
        const newVal = (selectedConfiguration === null || selectedConfiguration === undefined) ? undefined : (selectedConfiguration.constructor === String) ? selectedConfiguration : selectedConfiguration.getId()
        const oldVal = this.properties.selectedConfiguration
        this.properties.selectedConfiguration = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CONFIGURATIONFOLDER_SELECTEDCONFIGURATION, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getSelectedConfiguration(pure = false) {
        return pure ? this.properties.selectedConfiguration : this.getRefItem(this.properties.selectedConfiguration)
        
    }
    getFidSelectedConfiguration() {
        return this.FID_CONFIGURATIONFOLDER_SELECTEDCONFIGURATION
    }
    

    getFidLast() {
        return this.FID_CONFIGURATIONFOLDER_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CONFIGURATIONFOLDER_SELECTEDCONFIGURATION:
                return "selectedConfiguration"

            default:
                return ""
        }
    }


    newConfiguration(properties={}, title = "", domain = "") {
        return this.createChild(this, "Configuration", properties, title, domain)
    }
    remConfiguration(obj) {
        return this.removeChild(obj)
    }
    getConfigurations() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "Configuration")
            return res
        })
    }
}