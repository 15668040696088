import * as Project from "./Project";
import * as CMU from "./CMU";
import * as Instance from "./Instance";
import * as Label from "./Label";
import * as LabelRef from "./LabelRef";
import * as Configuration from "./Configuration";
import * as UPF from "./upf";
import * as Sequence from "./Sequence";
import * as IPInput from "./IPInput";
import * as SequenceDiagram from "./SequenceDiagram";
import * as SequenceLabel from "./SequenceLabel";
import * as SequenceLabelRef from "./SequenceLabelRef";
import * as PMDInstance from "./PMDInstance";
import * as SDCPhantom from "./SDCPhantom";
export default {
  ...Project,
  ...CMU,
  ...Instance,
  ...Label,
  ...LabelRef,
  ...Configuration,
  ...UPF,
  ...Sequence,
  ...IPInput,
  ...SequenceDiagram,
  ...SequenceLabel,
  ...SequenceLabelRef,
  ...PMDInstance,
  ...SDCPhantom,
};
