import { TableRendererDFT } from "./renderer/types";
import { TableSchema, TableCellConfig } from "./schema/types";

export enum TableType {
  DFT = "DFT",
}

export enum TableCellType {
  string = "text",
  enum = "dropdown",
}

export { TableRendererDFT };
export type { TableSchema, TableCellConfig };
