import {
  Item,
  ItemSchema,
  ChildSpec,
  ItemViewSpec,
  Property,
} from "@/model/types";
import { ItemType } from "@/model/types";
import Repository from "../store";

const isPropertyEnum = (obj: any): boolean => {
  return obj.type === "enum" && Array.isArray(obj.targets);
};

const isPropertyItem = (obj: any): boolean => {
  return obj.type === "item";
};

const isPropertyWithoutTargets = (obj: any): boolean => {
  return (
    obj.type !== "enum" && obj.type !== "item" && obj.targets === undefined
  );
};

const isItemSchema = (obj: any): obj is ItemSchema => {
  if (typeof obj !== "object" || obj === null) {
    return false;
  }

  for (const key in obj) {
    if (
      !isPropertyEnum(obj[key]) &&
      !isPropertyItem(obj[key]) &&
      !isPropertyWithoutTargets(obj[key])
    ) {
      return false;
    }
  }
  return true;
};

const isValidSchema = (
  item: Item,
  prop: Property,
  propKey: string
): boolean => {
  let res = true;
  res = res && !prop.hidden;

  if (propKey === "ECC") {
    return false;
  }

  switch (item.getItemType()) {
    case ItemType.Project:
      return true;
    case ItemType.ClockDivider:
      if (propKey === "clkEnValue") {
        return res && item.getClkEn();
      } else {
        return res;
      }
    case ItemType.ClockFreqmon:
    case ItemType.ClockBuffer:
      if (propKey === "ECC") {
        return false;
      } else {
        return res;
      }
    case ItemType.ClockQChannelManagerEXT:
      return false;
    case ItemType.Label:
      if (propKey === "numChild") {
        return res && item.getType() === "external";
      } else {
        return res;
      }
    case ItemType.PowerSequenceWrite:
    case ItemType.PowerSequenceReadWait:
    case ItemType.PowerSequenceIf:
      if (
        propKey === "PMD" &&
        item.getParent().getItemRef()?.getItemType() ===
          ItemType.PowerPMDInstance
      ) {
        return false;
      } else {
        return res;
      }
    case ItemType.PowerSequenceWait:
      if (propKey === "PMD") return false;
      return res;
    case ItemType.SDCPhantom: {
      if (
        propKey === "ClockPoint" &&
        (item.getOriginItem().getItemType() === ItemType.ClockFreqmon ||
          item.getOriginItem().getItemType() === ItemType.Label ||
          item.getOriginItem().getItemType() === ItemType.ClockIP)
      ) {
        return false;
      }
      if (propKey !== "ClockPoint") {
        return res && item.getClockPoint();
      }
      return res;
    }
    case ItemType.ClockGate:
      if (propKey === "enableInitValue") {
        return false;
      }
      return res;
    case ItemType.ClockGateEXT:
      if (propKey === "ewakeupMode") {
        const repo = Repository.getInstance();
        if (repo && !repo.getAcgMode()) {
          return res && false;
        }
      }
      return res;
    default:
      return res;
  }
};

const isValidChildSpec = (spec: ChildSpec) => {
  return !spec.auto && !spec.hidden;
};

const getParentName = (item: Item, propKey: string): string | undefined => {
  switch (item.getItemType()) {
    case ItemType.ClockDivider:
      if (propKey === "clkEnValue") {
        return "clkEn";
      }
      return undefined;
    case ItemType.Label:
      if (propKey === "numChild") {
        return "type";
      }
      return undefined;
    case ItemType.SDCPhantom:
      if (propKey !== "ClockPoint") {
        return "ClockPoint";
      }
      return undefined;
    default:
      return undefined;
  }
};

const getPriority = (key: string): number => {
  return [
    "string",
    "integer",
    "enum",
    "boolean",
    "item",
    "textarea",
    "color",
  ].indexOf(key);
  // switch (key) {
  //   case "string":
  //     return 0;
  //   case "integer":
  //     return 1;
  //   case "enum":
  //     return 2;
  //   case "boolean":
  //     return 3;
  //   case "item":
  //     return 4;
  //   case "textarea":
  //     return 5;
  //   case "color":
  //     return 6;
  //   default:
  //     return 100;
  // }
};

const getSortedData = (item: Item): Array<[string, Property]> => {
  const schema: ItemSchema = item._schema;
  const data = Object.entries(schema);
  switch (item.getItemType()) {
    case ItemType.ClockCMU:
    case ItemType.LabelRef:
    case ItemType.PowerSequenceGoto:
    case ItemType.PowerSequenceIf:
    case ItemType.PowerSequenceLabel:
    case ItemType.PowerSequenceLabelRef:
    case ItemType.PowerSequenceReadWait:
    case ItemType.PowerSequenceStart:
    case ItemType.PowerSequenceWait:
    case ItemType.PowerSequenceWrite:
    case ItemType.SDCPhantom:
      return data;
    default:
      return data.sort((a: [string, Property], b: [string, Property]) => {
        if (getPriority(a[1].type) < getPriority(b[1].type)) return -1;
        else if (getPriority(a[1].type) > getPriority(b[1].type)) return 1;
        else return 1;
      });
  }
};

export default {
  getSchema: (item: Item): ItemSchema => {
    const res: ItemSchema = {};
    if (item && isItemSchema(item._schema)) {
      const data = getSortedData(item);
      data.forEach(([key, prop]) => {
        if (isValidSchema(item, prop, key)) {
          res[key] = prop;
        }
      });
      data.forEach(([key, prop]) => {
        if (isValidSchema(item, prop, key)) {
          const parentKey = getParentName(item, key);
          if (parentKey) {
            const newItem: ItemSchema = { [key]: prop };
            if (!res[parentKey]["children"]) {
              res[parentKey]["children"] = [];
            }
            if (
              !res[parentKey]["children"]?.find(
                (o) => Object.keys(o)[0] === key
              )
            ) {
              res[parentKey]["children"]?.push(newItem);
            }
            delete res[key];
          }
        }
      });
      return res;
    } else {
      //   throw new Error(`Please check the item schema type`);
      console.error(`Please check the item schema type`);
      return res;
    }
  },
  getViewSpec: (item: Item): ItemViewSpec => {
    return item.view ? item.view : { diagram: false, table: false };
  },
  getChildrenSpec: (item: Item) => {
    return item.getChildrenSpec().reduce((acc: ChildSpec[], cur: ChildSpec) => {
      if (isValidChildSpec(cur)) {
        acc.push(cur);
      }
      return acc;
    }, []);
  },
};
