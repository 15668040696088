/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import UPFMapperGroundEvent from '@/model/src/events/UPFMapperGroundEvent'
import UPFMapperGroundChecker from '@/model/src/checkers/UPFMapperGroundChecker'
export default class UPFMapperGround extends Item {
    FID_UPFMAPPERGROUND_POWERSOURCE = 0
    FID_UPFMAPPERGROUND_MAPPEDMODULE = 1
    FID_UPFMAPPERGROUND_MAPPEDPOWER = 2
    FID_UPFMAPPERGROUND_LAST = 2

    constructor(obj) {
        super(Object.assign(obj,{itemType:"UPFMapperGround"}))
        
        this.setEvent(new UPFMapperGroundEvent(this))
        this.setChecker(new UPFMapperGroundChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "UPFMapperGround"
    }

    getSchema() {
        return {"powerSource": {"type": "item", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "mappedModule": {"type": "item", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "mappedPower": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"powerSource": {"type": "item", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "mappedModule": {"type": "item", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "mappedPower": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setPowerSource(powerSource, event=true) {
        if ((powerSource !== null) && (powerSource !== undefined)) {
            if (!(powerSource instanceof Item) && (powerSource.constructor !== String)) {
                throw `powerSource(${powerSource}) must be Item or String type`
            }
        }
        const newVal = (powerSource === null || powerSource === undefined) ? undefined : (powerSource.constructor === String) ? powerSource : powerSource.getId()
        const oldVal = this.properties.powerSource
        this.properties.powerSource = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFMAPPERGROUND_POWERSOURCE, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getPowerSource(pure = false) {
        return pure ? this.properties.powerSource : this.getRefItem(this.properties.powerSource)
        
    }
    getFidPowerSource() {
        return this.FID_UPFMAPPERGROUND_POWERSOURCE
    }
    

    setMappedModule(mappedModule, event=true) {
        if ((mappedModule !== null) && (mappedModule !== undefined)) {
            if (!(mappedModule instanceof Item) && (mappedModule.constructor !== String)) {
                throw `mappedModule(${mappedModule}) must be Item or String type`
            }
        }
        const newVal = (mappedModule === null || mappedModule === undefined) ? undefined : (mappedModule.constructor === String) ? mappedModule : mappedModule.getId()
        const oldVal = this.properties.mappedModule
        this.properties.mappedModule = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFMAPPERGROUND_MAPPEDMODULE, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getMappedModule(pure = false) {
        return pure ? this.properties.mappedModule : this.getRefItem(this.properties.mappedModule)
        
    }
    getFidMappedModule() {
        return this.FID_UPFMAPPERGROUND_MAPPEDMODULE
    }
    

    setMappedPower(mappedPower, event=true) {
        if ((mappedPower !== null) && (mappedPower !== undefined)) {
            if (![String].includes(mappedPower.constructor)) {
                throw `mappedPower(${mappedPower}) must be [String] type`
            }
        }
        const newVal = (mappedPower === null || mappedPower === undefined) ? undefined : mappedPower
        const oldVal = this.properties.mappedPower
        this.properties.mappedPower = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFMAPPERGROUND_MAPPEDPOWER, newVal, oldVal)
        }
    }
    getMappedPower(pure = false) {
        return this.properties.mappedPower
        
    }
    getFidMappedPower() {
        return this.FID_UPFMAPPERGROUND_MAPPEDPOWER
    }
    

    getFidLast() {
        return this.FID_UPFMAPPERGROUND_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_UPFMAPPERGROUND_POWERSOURCE:
                return "powerSource"

            case this.FID_UPFMAPPERGROUND_MAPPEDMODULE:
                return "mappedModule"

            case this.FID_UPFMAPPERGROUND_MAPPEDPOWER:
                return "mappedPower"

            default:
                return ""
        }
    }


}