/**
 *  Generated by Modeler
 *  timestamp: 2023-11-16 11:47:29.773775
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class SDCModuleFolderEvent extends ItemEvent {
  constructor(item) {
    super(item);
  }
}
