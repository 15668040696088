/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemHandler from "../ItemHandler";
import ItemType from "../ItemType";

export default class ClockPLLCtrlEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), () => {});
    this.addPropertyListener(item.getFidPlltype(), this.lstn_plltype);
  }

  async lstn_plltype(item, newVal) {
    for (const pllItem of item.getClockPLLs()) {
      await ItemHandler.removeItem(pllItem);
    }
    if (newVal) {
      const { item: pll, promise } = item.newClockPLL({ name: item.getName() });
      await promise;

      const sfrBlock = item.getParent(ItemType.ClockCMU).getSFRBlock();
      sfrBlock.setSorted(false);
      sfrBlock.setSorted(true);
    }
  }

  postProcess(item) {
    super.postProcess(item);
    return this.setupPorts(item);
  }

  setupPorts(item) {
    return [
      item.newInput({
        key: `CLK_${ItemType.Input.getKey().toUpperCase()}`,
        socket: "CLK",
      }).promise,
      item.newInput({
        key: `CLK_${ItemType.Input.getKey().toUpperCase()}_DUMMY`,
        hidden: true,
        socket: "CLK",
      }).promise,
      item.newOutput({
        key: `CLK_${ItemType.Output.getKey().toUpperCase()}`,
        socket: "CLK",
      }).promise,
    ];
  }
}
