import * as d3 from "d3";

export default {
  render: (
    elem: HTMLElement,
    width: number,
    height: number,
    color: string,
    options: any
  ) => {
    if (!color) {
      return;
    }

    const { isDocked } = options;
    const OUTER_PADDING = 1.5;
    const rectW = width - OUTER_PADDING * 2;
    const rectH = height - OUTER_PADDING * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", isDocked ? width * 0.75 : width)
      .attr("height", isDocked ? height * 0.75 : height);

    const drawerGroup = svg
      .append("g")
      .attr("transform", isDocked ? `scale(0.75, 0.75)` : `scale(1)`);

    const baseColor = color;

    drawerGroup
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", width)
      .attr("height", height)
      .attr("fill", "black");

    drawerGroup
      .append("rect")
      .attr("x", OUTER_PADDING)
      .attr("y", OUTER_PADDING)
      .attr("rx", 3)
      .attr("ry", 3)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("fill", baseColor);
  },
};
