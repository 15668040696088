import { Context } from "../types";
import Canvas from "@/canvas";

export const connectionimported = async (
  context: Context
): Promise<boolean> => {
  const editor = context.options.editor;
  await Canvas.getDataFlowPlugin(editor.getID()).process();
  return true;
};
