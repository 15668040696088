import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import HomeView from "../views/HomeView.vue";
import ItemView from "../views/ItemView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/Item",
    name: "Item",
    component: ItemView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach((to, from, next) => {
  if (window.performance) {
    const navigationEntries = performance.getEntriesByType("navigation");
    if (navigationEntries.length > 0) {
      const navigationTiming =
        navigationEntries[0] as PerformanceNavigationTiming;
      if ((window as any).isAfterReload) {
        next();
      } else if (navigationTiming.type == "reload" && to.path !== "/") {
        (window as any).isAfterReload = true;
        next({ path: "/" });
      } else {
        next();
      }
    } else {
      next();
    }
  }
});
export default router;
