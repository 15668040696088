import { NodeType } from "../types";
import ITDANode from "./ITDANode";
import { ITDAControlPower } from "./types";
import * as ITDAControl from "../controls";

export default abstract class ITDANodePower
  extends ITDANode
  implements ITDAControlPower
{
  constructor(name: string, type: NodeType) {
    super(name, type);
  }

  getCtrlSignal(): ITDAControl.ITDAControlDynamicSignal {
    return this.controls.signal as ITDAControl.ITDAControlDynamicSignal;
  }

  set width(data: number) {
    super.width = this.adjustNodeToGrid(data);
  }

  get width() {
    const gridSpace = this.repo
      .getEditor(this.repo.getCurrentID())
      .getGridStroke();
    return this.adjustNodeToGrid(super.width) + gridSpace * 2;
  }

  set height(data: number) {
    super.height = this.adjustNodeToGrid(data);
  }

  get height() {
    const gridSpace = this.repo
      .getEditor(this.repo.getCurrentID())
      .getGridStroke();
    return this.adjustNodeToGrid(super.height) + gridSpace * 2;
  }
}
