import {
  Item,
  ItemType,
  VariantLevel,
  RefItemElement,
  RefItemElementType,
  RefItemElementTarget,
  Property,
} from "ITDAModelTypes";
import API from "@/api/internal";
import Canvas from "@/canvas";

type Event = (
  item: Item,
  elem: RefItemElement,
  value: string | string[] | number | number[]
) => void;

// export const getUPFGround = (
//   item: Item,
//   propKey: string,
//   prop: Property
// ): RefItemElement[] => {
//   const moduleItem = item.getModule();
//   const model = moduleItem ? moduleItem.getName() : undefined;
//   const targets: RefItemElementTarget[] = [];
//   API.getParentDiagram(item)
//     ?.getPowerDomain()
//     .getUPFGrounds()
//     .forEach((item: Item) => {
//       targets.push({
//         key: item.getName(),
//         item: item,
//         closable: true,
//       });
//     });
//   return [getElement(model, targets, propKey, eventUPFGround)];
// };

export const getUPFPrimaryPowerSource = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const moduleItem = item.getPrimaryPowerSource();
  const model = moduleItem ? moduleItem.getName() : undefined;
  const targets: RefItemElementTarget[] = [];
  API.getParentDiagram(item)
    ?.getUPFPowerDomain()
    .getUPFPowerSources()
    .forEach((item: Item) => {
      targets.push({
        key: item.getName(),
        item: item,
        closable: true,
      });
    });

  return [getElement(model, targets, propKey, eventPrimaryPowerSource)];
};

// export const getUPFPowerSource = (
//   item: Item,
//   propKey: string,
//   prop: Property
// ): RefItemElement[] => {
//   const moduleItem = item.getPowerSource();
//   const model = moduleItem ? moduleItem.getName() : undefined;
//   const targets: RefItemElementTarget[] = [];
//   const topItem = API.getTopItem();
//   const voltageDomains = topItem?.getVoltage().getVoltageDomains();
//   voltageDomains.forEach((item: Item) => {
//     targets.push({
//       key: item.getName(),
//       item: item,
//       closable: true,
//     });
//   });

//   return [getElement(model, targets, propKey, eventUPFPowerSource)];
// };

// export const getMappedPower = (
//   item: Item,
//   propKey: string
// ): RefItemElement[] => {
//   const acc: RefItemElement[] = [];
//   acc.push({
//     icon: "mdi-chevron-double-right",
//     variant: VariantLevel.LEVEL2,
//     disabled: false,
//     type: RefItemElementType.CHIP,
//     propKey: propKey,
//     label: "",
//     multiple: false,
//     targets: [
//       {
//         key: item.getMappedPower().getName(),
//         item: item.getMappedPower(),
//         closable: false,
//       },
//     ],
//     event: event,
//   });
//   return acc;
// };

export const getUPFLocation = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const moduleItem = item.getLocation();
  const model = moduleItem ? moduleItem.getName() : undefined;
  const targets: RefItemElementTarget[] = [];
  const powerDomain = API.getParentDiagram(item)?.getUPFPowerDomain();
  targets.push({
    key: powerDomain.getName(),
    item: powerDomain,
    closable: true,
  });

  powerDomain.getUPFHardMacros().forEach((item: Item) => {
    targets.push({
      key: item.getName(),
      item: item,
      closable: true,
    });
  });

  powerDomain.getUPFPowerDomainInstances().forEach((item: Item) => {
    targets.push({
      key: item.getName(),
      item: item,
      closable: true,
    });
  });

  return [getElement(model, targets, propKey, eventUPFLocation)];
};
const getElement = (
  model: string | undefined,
  targets: RefItemElementTarget[],
  propKey: string,
  event: Event
): RefItemElement => {
  return {
    variant: VariantLevel.LEVEL0,
    disabled: false,
    type: RefItemElementType.ENUM,
    propKey: propKey,
    label: "",
    multiple: false,
    model: model,
    targets: targets,
    event: event,
  };
};

// const eventUPFGround = (
//   item: Item,
//   elem: RefItemElement,
//   value: string | string[]
// ) => {
//   const target = elem.targets?.find((target) => target.key === value);
//   if (target) {
//     item.setGround(target.item);
//   }
// };

const eventPrimaryPowerSource = (
  item: Item,
  elem: RefItemElement,
  value: string | string[] | number | number[]
) => {
  const target = elem.targets?.find((target) => target.key === value);
  if (target) {
    item.setPrimaryPowerSource(target.item);
  }
};

// const eventUPFPowerSource = (
//   item: Item,
//   elem: RefItemElement,
//   value: string | string[]
// ) => {
//   const target = elem.targets?.find((target) => target.key === value);
//   if (target) {
//     item.setPowerSource(target.item);
//   }
// };

const eventUPFLocation = (
  item: Item,
  elem: RefItemElement,
  value: string | string[] | number | number[]
) => {
  const target = elem.targets?.find((target) => target.key === value);
  if (target) {
    item.setLocation(target.item);
    const node = Canvas.getEditor().getNode(item.getNodeID());
    node.parent = target.item.getNodeID();
  } else {
    item.setLocation(null);
    const node = Canvas.getEditor().getNode(item.getNodeID());
    node.parent = API.getParentDiagram(item)?.getUPFPowerDomain().getNodeID();
  }
};
