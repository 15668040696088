<template>
  <div>
    <div v-if="props.table">
      <v-checkbox
        v-model="model"
        color="primary"
        :disabled="getDisabled() ? true : false"
        :hide-details="!props.errorMessages"
        :error-messages="props.errorMessages"
        :class="getCheckboxStyle(props.propKey)"
        @update:modelValue="
          Utils.update(props.item, props.propKey, Boolean, $event)
        " />
    </div>
    <div v-else class="checkbox" :style="getCheckboxExpandedStyle">
      <div class="checkbox__icon">
        <v-checkbox
          v-model="model"
          color="#1e41cd"
          :disabled="getDisabled() ? true : false"
          :hide-details="!props.errorMessages"
          :error-messages="props.errorMessages"
          :class="getCheckboxStyle(props.propKey)"
          @update:modelValue="
            Utils.update(props.item, props.propKey, Boolean, $event)
          " />
      </div>
      <div class="checkbox__label">
        <div :class="getDisabled() ? 'checkbox__label-disabled' : undefined">
          {{
            props.table ? undefined : Utils.getLabel(props.propKey, props.prop)
          }}
        </div>
        <div
          v-if="props.children && Utils.showChildren(props.item)"
          class="checkbox__expanded">
          <v-divider class="checkbox__expanded-divider"></v-divider>
          <v-row
            v-for="(prop, key) in props.children"
            :key="key"
            class="property__row">
            <v-col class="property__col" overflow="hidden">
              <ItemPropertyTextField
                v-if="Utils.isStringType(Object.values(prop)[0])"
                v-bind="
                  getProps(Object.keys(prop)[0], Object.values(prop)[0])
                " />
              <ItemPropertyIntegerField
                v-if="Utils.isIntegerType(Object.values(prop)[0])"
                v-bind="getProps(Object.keys(prop)[0], Object.values(prop)[0])"
                :expanded="true" />
              <ItemPropertyCheckbox
                v-else-if="Utils.isBooleanType(Object.values(prop)[0])"
                v-bind="
                  getProps(Object.keys(prop)[0], Object.values(prop)[0])
                " />
              <ItemPropertyItemField
                v-else-if="Utils.isItemType(Object.values(prop)[0])"
                v-bind="
                  getProps(Object.keys(prop)[0], Object.values(prop)[0])
                " />
            </v-col>
          </v-row>
        </div>
        <div v-if="hasSpecs()" class="checkbox__expanded">
          <n-collapse v-if="model" :expanded-names="[0, 1, 2, 3, 4]">
            <v-divider class="checkbox__expanded-divider"></v-divider>
            <div v-for="(spec, idx) in getSpecs()" :key="`${idx}-${spec.name}`">
              <ItemPropertyTable
                :item="props.item"
                :spec="spec"
                :name="Number(idx)"
                :expanded="true" />
            </div>
          </n-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import API from "@/api/internal";
import Utils from "@/components/item/utils";
import { computed, defineProps, ref } from "vue";
import { ChildSpec, ItemPropsType, Property } from "ITDAModelTypes";
import ItemPropertyIntegerField from "./ItemPropertyIntegerField.vue";
import ItemPropertyTable from "./ItemPropertyTable.vue";
import ItemPropertyTextField from "./ItemPropertyTextField.vue";
import ItemPropertyItemField from "./ItemPropertyItemField.vue";
const props = defineProps<ItemPropsType>();
const specs = Utils.getSpec(props.item, props.propKey);

const hasSpecs = () => {
  return specs ? true : false;
};
const getSpecs = (): ChildSpec[] => {
  return specs as ChildSpec[];
};

const model = ref(Utils.getValue(props.item, props.propKey));
const getCheckboxStyle = (propKey: string) => {
  switch (propKey) {
    case "gated":
      return "gated-checkbox-style";
    default:
      return "";
  }
};
const getCheckboxExpandedStyle = computed(() => {
  if (props.children && props.children.length > 0) {
    return {
      height: `fit-content`,
    };
  } else {
    return {
      height: `inherit`,
    };
  }
});
const getDisabled = () => {
  return Utils.isDisabledCheckbox(props.item, props.propKey, props.prop);
};
const getProps = (key: string, prop: Property): ItemPropsType => {
  const res = {
    item: props.item,
    propKey: key.toString(),
    prop: prop,
    errorMessages: API.getErrorMessages(props.item, key),
  };
  if (prop.children) {
    Object.assign(res, { children: prop.children });
  }
  return res;
};
</script>

<style lang="sass" scoped>
.checkbox
  width: 100%
  height: 40px
  display: flex
  flex-direction: row
  .checkbox__icon
    padding: 10px
    background-color: #FAFAFA
    border: 1px solid rgb(118, 118, 118)
    border-right: none
    border-top-left-radius: 4px
    border-bottom-left-radius: 4px
    .v-input
      padding-top: 0
  .checkbox__label
    width: inherit
    height: inherit
    display: flex
    flex-direction: column
    align-items: flex-start
    border: 1px solid rgb(118, 118, 118)
    padding: 10px
    border-top-right-radius: 4px
    border-bottom-right-radius: 4px
    .checkbox__expanded
      width: 100%
      align-items: center
      .checkbox__expanded-divider
        margin: 0.5rem 0
  .checkbox__label-disabled
    color: gray
.gated-checkbox-style
  //margin-left: 1.5rem
  display: flex
  flex-direction: column
  align-items: center
</style>
