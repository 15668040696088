import axios from "axios";
import { host, protocol } from "./config";

export const copy = async (name: string, target: string, type: string) => {
  try {
    const data = await axios.post(
      `${protocol}://${host}/io/copy`,
      {},
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          name: name,
          target: target,
          type: type,
        },
      }
    );
    return { res: true, data: data };
  } catch (err) {
    console.error("Error copying DB", err);
  }
};
