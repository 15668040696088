/**
 *  Generated by Modeler
 *  timestamp: 2022-08-22 12:24:20.652766
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";
import { ItemType } from "ITDAModelTypes";
import NodeType from "../NodeType";

export default class InputChecker extends ItemChecker {
  constructor(item) {
    super(item);
    // this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME);
    // this.addChecker(item.getFidWidth(), this.checkWidth, ErrorType.CONFIG);
    this.addChecker(
      item.getFidConnections(),
      this.checkConnections,
      ErrorType.CONNECTION
    );
  }
  checkName() {}
  // checkWidth(item) {
  //   if (item.getWidth() < 1) {
  //     this.newError(`Width must be positive`);
  //   }
  // }
  checkConnections(item) {
    if (item.getParent().getItemType() === ItemType.DUTInstance) {
      return;
    }
    if (item.getParent().getParent().getItemType() === ItemType.ClockCMU) {
      return;
    }

    const idx = item
      .getParent()
      .getInputs()
      .findIndex((obj) => obj.getId() === item.getId());
    if (!item.getConnections().find((conn) => conn) && !item.getHidden()) {
      if (item.getParent().getItemType().getCategory() === NodeType.Sequence) {
        if (!item.getParent().getLabel()) {
          if (item.getParent().getInputs()[0].getConnections().length === 0) {
            this.newError(`Input(${idx}) must be connected`);
          }
        }
      } else {
        this.newError(`Input(${idx}) must be connected`);
      }
    }
  }
}
