import { ITDAConnection, Schemes } from "@/canvas/common";
import { ITDANode } from "@/canvas/nodes";
import { BaseSchemes, NodeEditor } from "rete";

export default class ITDACustomEditor<T extends Schemes> extends NodeEditor<T> {
  private itdanodes: Record<string, ITDANode> = {};
  private itdaconnections: Record<string, ITDAConnection<ITDANode, ITDANode>> =
    {};

  constructor() {
    super();
  }

  public getNode(id: string) {
    return this.itdanodes[id];
  }

  public getNodes() {
    return Object.values(this.itdanodes);
  }

  public getConnections() {
    return Object.values(this.itdaconnections);
  }

  public getConnection(id: string) {
    return this.itdaconnections[id];
  }

  async addNode(data: ITDANode) {
    if (this.getNode(data.id)) throw new Error("node has already been added");

    if (!(await this.emit({ type: "nodecreate", data }))) return false;

    this.itdanodes[data.id] = data;

    await this.emit({ type: "nodecreated", data });
    return true;
  }

  async addConnection(data: ITDAConnection<ITDANode, ITDANode>) {
    if (this.getConnection(data.id))
      throw new Error("connection has already been added");

    if (!(await this.emit({ type: "connectioncreate", data }))) return false;

    this.itdaconnections[data.id] = data;

    await this.emit({ type: "connectioncreated", data });
    return true;
  }

  async removeNode(id: string) {
    const node = this.itdanodes[id];

    if (!node) throw new Error("cannot find node");

    if (!(await this.emit({ type: "noderemove", data: node }))) return false;

    delete this.itdanodes[id];

    await this.emit({ type: "noderemoved", data: node });
    return true;
  }

  async removeConnection(id: string) {
    const connection = this.itdaconnections[id];

    if (!connection) throw new Error("cannot find connection");

    if (!(await this.emit({ type: "connectionremove", data: connection })))
      return false;

    delete this.itdaconnections[id];

    await this.emit({ type: "connectionremoved", data: connection });
    return true;
  }

  async clear() {
    if (!(await this.emit({ type: "clear" }))) {
      await this.emit({ type: "clearcancelled" });
      return false;
    }

    for (const id in this.itdaconnections) {
      await this.removeConnection(id);
    }

    for (const id in this.itdanodes) {
      await this.removeNode(id);
    }

    await this.emit({ type: "cleared" });
    return true;
  }
}
