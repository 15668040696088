/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerSequenceCallEvent from '@/model/src/events/PowerSequenceCallEvent'
import PowerSequenceCallChecker from '@/model/src/checkers/PowerSequenceCallChecker'
export default class PowerSequenceCall extends Node {
    FID_POWERSEQUENCECALL_LABEL = 100
    FID_POWERSEQUENCECALL_LAST = 100

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerSequenceCall"}))
        
        this.setEvent(new PowerSequenceCallEvent(this))
        this.setChecker(new PowerSequenceCallChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.label === undefined) {
        this.properties.label = false
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerSequenceCall"
    }

    getSchema() {
        return {"label": {"type": "boolean", "label": "Create LABEL", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"label": {"type": "boolean", "label": "Create LABEL", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setLabel(label, event=true) {
        if ((label !== null) && (label !== undefined)) {
            if (![Boolean].includes(label.constructor)) {
                throw `label(${label}) must be [Boolean] type`
            }
        }
        const newVal = (label === null || label === undefined) ? undefined : label
        const oldVal = this.properties.label
        this.properties.label = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCECALL_LABEL, newVal, oldVal)
        }
    }
    getLabel(pure = false) {
        return this.properties.label
        
    }
    getFidLabel() {
        return this.FID_POWERSEQUENCECALL_LABEL
    }
    

    getFidLast() {
        return this.FID_POWERSEQUENCECALL_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERSEQUENCECALL_LABEL:
                return "label"

            default:
                return ""
        }
    }


}