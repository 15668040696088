import { AreaExtensions } from "rete-area-plugin";
import { CommentPlugin, CommentExtensions } from "rete-comment-plugin";
import { VueArea2D } from "rete-vue-plugin";
import { ITDACanvasType, ITDACommon } from "./types";
import ITDACanvas from "../ITDACanvas";

type AreaExtra = VueArea2D<ITDACommon.Schemes>;

export default class ITDACommentPlugin extends ITDACanvas {
  constructor(id: string) {
    super(id, ITDACanvasType.Comment);
    this.res = new CommentPlugin<ITDACommon.Schemes, AreaExtra>();

    const selector = AreaExtensions.selector();
    const accumulating = AreaExtensions.accumulateOnCtrl();
    CommentExtensions.selectable(this.res, selector, accumulating);
  }
  getInstance(): CommentPlugin<ITDACommon.Schemes, AreaExtra> {
    return this.res as CommentPlugin<ITDACommon.Schemes, AreaExtra>;
  }
}
