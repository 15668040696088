import { TableCellType } from "@/table/types";
export default [
  {
    data: "Port",
    type: TableCellType.string,
    readOnly: true,
    className: "",
  },
  {
    data: "Direction",
    type: TableCellType.string,
    readOnly: true,
    className: "htCenter",
  },
  {
    data: "FUNC",
    type: TableCellType.enum,
    source: ["DIRECT", "TIE", "X", "MODE_DIRECT"],
    className: "htCenter",
  },
  {
    data: "DA",
    type: TableCellType.enum,
    source: ["DIRECT", "TIE", "X", "MODE_DIRECT"],
    className: "htCenter",
  },
  {
    data: "SCANINT",
    type: TableCellType.enum,
    strict: false,
    source: [
      "DIRECT",
      "TIE",
      "X",
      "MODE_DIRECT",
      "Scan Clock",
      "Scan Reset",
      "Scan Enable",
      "Scan Shift Enable",
      "Observe",
    ],
    className: "htCenter",
  },
  {
    data: "SCANEXT",
    type: TableCellType.enum,
    strict: false,
    source: [
      "DIRECT",
      "TIE",
      "X",
      "MODE_DIRECT",
      "Scan Clock",
      "Scan Reset",
      "Scan Enable",
      "Scan Shift Enable",
      "Observe",
    ],
    className: "htCenter",
  },
  {
    data: "HTOL",
    type: TableCellType.enum,
    strict: false,
    allowHtml: true,
    trimDropdown: true,
    source: ["DIRECT", "TIE", "X", "MODE_DIRECT"],

    className: "htCenter",
  },
  {
    data: "USERDEF",
    type: TableCellType.string,
    strict: false,
    allowHtml: true,
    trimDropdown: true,
    source: ["DIRECT", "TIE", "X", "MODE_DIRECT"],
    className: "htCenter",
  },
];
