import { ITDAConnection } from "@/canvas/common";
import { ITDANode } from "@/canvas/nodes";
import { ItemType } from "ITDAModelTypes";
import API from "@/api/internal";
import Canvas from "@/canvas";
import { ITDAInput, ITDAOutput } from "@/canvas/controls/ITDAControlPort";

export const addConnection = async (
  conn: ITDAConnection<ITDANode, ITDANode>
): Promise<boolean> => {
  const editor = Canvas.getEditor();
  const source = editor.getNode(conn.source);
  const target = editor.getNode(conn.target);
  const output = source.outputs[conn.sourceOutput] as ITDAOutput;
  const outputItem = API.getItem(output.getItemID());
  const input = target.inputs[conn.targetInput] as ITDAInput;
  const inputItem = API.getItem(input.getItemID());

  let connItem = API.getItem(conn.getItemID());
  const diagram = API.getItem(editor.getID());

  if (!connItem) {
    if (diagram) {
      if (outputItem && inputItem) {
        connItem = await API.createItem(diagram, ItemType.Connection.getKey(), {
          build: true,
          sourceOutput: outputItem.getId(),
          targetInput: inputItem.getId(),
        });
      } else {
        console.error(`output: ${output}, input: ${input}.`);
        return false;
      }
    } else {
      console.error(`diagram is null`);
      return false;
    }
  }
  conn.setItemID(connItem.getId());
  connItem.getSourceOutput();
  connItem.setConnectionID(conn.id);
  connItem.setBuild(true);
  return true;
};

export const removeConnection = async (
  conn: ITDAConnection<ITDANode, ITDANode>
): Promise<boolean> => {
  const editor = Canvas.getEditor();
  const connItem = API.getItem(conn.getItemID());
  if (connItem) {
    const diagram = API.getItem(editor.getID());
    if (diagram) {
      await API.removeItem(connItem.getId());
    } else {
      console.error(`diagram is null`);
      return false;
    }
  }
  return true;
};
