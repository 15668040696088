import Canvas from "@/canvas";
import API from "@/api/internal";
import { Context } from "../types";
import { ITDANode } from "@/canvas/nodes";
import { Item, ItemType } from "ITDAModelTypes";
import { Repository } from "@/canvas/store";

export const nodecreate = async (context: Context): Promise<boolean> => {
  const node = context.options.node;
  const item = API.getItem(node.getItemID());
  if (!item) {
    const currentDiagram = API.getItem(Canvas.getCurrentID());
    if (currentDiagram) {
      const diagrams = getDiagrams(currentDiagram);
      if (diagrams.length === 0) return true;

      const repo = Repository.getInstance();
      for (const diagram of diagrams) {
        const editor = repo.getEditor(diagram.getId());
        if (editor) {
          const nodeItems = diagram.getChildren().filter((i: Item) => {
            return i.getItemType() !== ItemType.Connection;
          });
          const nodes = nodeItems.map((nodeItem: Item) => {
            return editor.getNode(nodeItem.getNodeID());
          });
          const sameTypeNodes = nodes.filter((n: ITDANode) => {
            return n.getType() === node.getType();
          });
          if (sameTypeNodes.length > 0) {
            const nodeItem = API.getItem(sameTypeNodes[0].getItemID());
            if (
              nodeItem &&
              nodeItem.getMaxNum &&
              sameTypeNodes.length >= nodeItem.getMaxNum()
            ) {
              return false;
            }
          }
        }
      }
    }
  }
  return true;
};

const getDiagrams = (currentDiagram: Item): Item[] | [] => {
  if (currentDiagram.getParent(ItemType.ClockDiagramFolder)) {
    return currentDiagram
      .getParent(ItemType.ClockDiagramFolder)
      .getClockDiagrams();
  } else if (currentDiagram.getParent(ItemType.PowerDiagramFolder)) {
    return currentDiagram
      .getParent(ItemType.PowerDiagramFolder)
      .getPowerDiagrams();
  } else {
    return [];
  }
};
