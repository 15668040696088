import { Context } from "../types";
import API from "@/api/internal";
import store from "@/store";

export const nodepicked = async (context: Context): Promise<boolean> => {
  const node = context.options.node;
  if (node) {
    const item = API.getItem(node.getItemID());
    if (item) {
      store.commit("item/SET_CURRENT_NODE_ITEM", item);
    }
  } else {
    store.commit("item/SET_CURRENT_NODE_ITEM", null);
  }

  return true;
};
