import { ClassicPreset } from "rete";
import ITDAControl from "./ITDAControl";

export default class ITDAControlDesc extends ITDAControl {
  private node: ClassicPreset.Node;
  constructor(parent: ClassicPreset.Node) {
    super();
    this.node = parent;
  }
}
