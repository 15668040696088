import TopUtils from "@/utils";
import { Item } from "ITDAModelTypes";
import { Position } from "rete-area-plugin/_types/types";

export const setITDAModule = (item: Item) => {
  item.getNodeMetaView().setX((TopUtils.getNodeType(item).getWidth() / 2) * -1);
  item
    .getNodeMetaView()
    .setY((TopUtils.getNodeType(item).getHeight() / 2) * -1);
};

export const getUpPosition = (pItem: Item, item: Item): Position => {
  const maxPositionX = getChildPositionX(pItem, item);
  const nodeType = TopUtils.getNodeType(item);
  const positionX =
    maxPositionX === -Infinity
      ? pItem.getNodeMetaView().getX()
      : maxPositionX + nodeType.getWidth() + 20;
  const positionY = pItem.getNodeMetaView().getY() - nodeType.getHeight() - 50;
  return { x: positionX, y: positionY };
};

export const getDownPosition = (pItem: Item, item: Item): Position => {
  const maxPositionX = getChildPositionX(pItem, item);
  const pItemNodeType = TopUtils.getNodeType(pItem);
  const itemNodeType = TopUtils.getNodeType(item);
  const positionX =
    maxPositionX === -Infinity
      ? pItem.getNodeMetaView().getX()
      : maxPositionX + itemNodeType.getWidth() + 20;
  const positionY =
    pItem.getNodeMetaView().getY() + pItemNodeType.getHeight() + 50;
  return { x: positionX, y: positionY };
};

function getChildPositionX(pItem: Item, item: Item): number {
  const nodeType = TopUtils.getNodeType(item);
  const getItems = pItem
    .getChildren()
    .filter(
      (o: Item) => o.isNodeType() && o.getNodeType() === nodeType.getKey()
    );
  let maxPositionX = -Infinity;
  for (const getItem of getItems) {
    if (getItem.getNodeID() === item.getNodeID()) {
      continue;
    }
    maxPositionX = Math.max(
      maxPositionX,
      getItem.getNodeMetaView()?.getX() ?? -Infinity
    );
  }
  return maxPositionX;
}
