/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x1000",
    "size": "0x2000",
    "alignSize": "0x200",
    "sfrs": [
        {
            "name": "PWR_CTRL",
            "alignOffset": "0x30",
            "description": "",
            "fields": [
                {
                    "name": "SFR_CLK_ENABLE",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x1",
                    "nc": "SFR_CLK_ENABLE"
                },
                {
                    "name": "SFR_MEM_PGEN",
                    "index": 1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_MEM_PGEN"
                },
                {
                    "name": "SFR_MEM_RET",
                    "index": 2,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_MEM_RET"
                }
            ]
        }
    ]
}