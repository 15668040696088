<template>
  <n-collapse-item class="property-table">
    <template #header>
      <div class="sfr-header">
        {{ API.getItemValue(API.getCurrentItem(), "title") }} ({{
          Object.values(props.item.properties)[4]
        }})
      </div>
    </template>
    <div class="sfr-item-boundary">
      <div class="sfr-scale">
        <div v-for="i in 32" :key="i" class="sfr-scale-grid">
          {{ 32 - i }}
        </div>
      </div>
      <div class="sfr-bar-boundary">
        <div v-for="bar in combinedSFRBars" :key="bar.id" class="sfr-bar">
          <div class="sfr-bar-item" :style="getSFRBarStyle(bar)">
            <span v-if="isRSVD(bar)" class="sfr-bar-label">{{
              bar.title
            }}</span>
            <span v-else class="sfr-bar-label"
              >{{ bar.title.replace(/(\w+)\[(\d+:\d+)\]/, "$1 [$2]") }} /
              {{ bar.access }} / {{ bar.initValue }}</span
            >
          </div>
        </div>
      </div>
    </div>
  </n-collapse-item>
</template>

<script setup lang="ts">
import { reactive, computed, defineProps } from "vue";
import { Item, ChildSpec } from "ITDAModelTypes";
import API from "@/api/internal";

const props = defineProps<{
  item: Item;
  spec: ChildSpec;
  expanded?: boolean;
}>();

let items = reactive(API.getChildTableItems(props.item, props.spec));

const combinedSFRBars = computed(() => {
  const sortedItems = [...items].sort((a, b) => b.index - a.index);
  const bars = [];
  let maxBit = 31;

  for (const item of sortedItems) {
    const itemEnd = item.index + item.size - 1;

    if (itemEnd < maxBit) {
      bars.push({
        id: `rsvd-${maxBit}`,
        index: itemEnd + 1,
        size: maxBit - itemEnd,
        title: "RSVD",
        access: "",
        initValue: "",
      });
    }

    bars.push(item);
    maxBit = item.index - 1;
  }

  if (maxBit >= 0) {
    bars.push({
      id: `rsvd-final`,
      index: 0,
      size: maxBit + 1,
      title: "RSVD",
      access: "",
      initValue: "",
    });
  }

  return bars;
});

const getSFRBarStyle = (bar: any) => {
  const startPosition = ((31 - (bar.index + bar.size - 1)) / 32) * 100;
  const width = (bar.size / 32) * 100;

  return {
    left: `${startPosition}%`,
    width: `${width}%`,
    position: "absolute",
    height: "100%",
    backgroundColor: bar.title === "RSVD" ? "lightgray" : "#FBB47A",
    border: "0.3px solid white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: bar.size,
  };
};

const isRSVD = (bar: any) => bar.title === "RSVD";
</script>

<style lang="sass" scoped>
.sfr-header
  margin-bottom: 0.5rem

.sfr-item-boundary
  margin-bottom: 0.5rem

.sfr-scale
  display: flex
  width: 100%
  font-size: 0.8rem
  color: gray
  position: relative

.sfr-scale-grid
  flex: 1
  width: calc(100% / 32)
  text-align: center
  border: 0.5px solid lightgray

.sfr-bar-boundary
  position: relative
  height: 300px
  background-color: white
  display: flex
  align-items: center

.sfr-bar
  width: 100%
  height: 100%

.sfr-bar-item
  position: absolute
  text-align: center
  color: black
  font-size: 0.8rem
  border-radius: 3px

.sfr-bar-label
  transform: rotate(90deg)
  white-space: nowrap
  font-size: 0.7rem
  font-weight: 500
  color: black
</style>
