import ItemEvent from "@/model/src/ItemEvent";
import ItemType from "@/model/src/ItemType";

export default class SFREvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
    this.addPropertyListener(item.getFidLast() + 1, this.lstn_update_name);
  }

  sortSFRFields(item) {
    item.getChildren().sort(function (a, b) {
      if (parseInt(a.getIndex()) < parseInt(b.getIndex())) return -1;
      if (parseInt(a.getIndex()) > parseInt(b.getIndex())) return 1;
      return 0;
    });
  }

  lstn_name(item, newVal) {
    item.setTitle(newVal);
  }

  lstn_update_name(item, newVal) {
    item.setName(`${item.getType()}__${newVal}`);
  }
}
