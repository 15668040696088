import EventEmitter from "events";
// import store from '@/store/index'
import Repository from "../store";
import EventType from "./EventType";

export default class EventHandler {
  static emitter = new EventEmitter();
  static registered = new Set();

  static baseFidMap = {};
  static fidSpec = {};

  static OFFSET = null;
  static ALIGN_OFFSET = 1000;

  static eventQueue = [];
  static eventLocked = false;

  static getFidKey(id, fid) {
    const item = Repository.getInstance().getItemByID(id);
    return item.getFidKey(fid);
  }

  static getUniqueFid(id, fid) {
    return this.getBaseFid(id) + fid;
  }

  static getBaseFid(id) {
    if (!this.baseFidMap[id]) {
      this.OFFSET += this.ALIGN_OFFSET;
      this.baseFidMap[id] = this.OFFSET;
    }
    return this.baseFidMap[id];
  }

  static initFidSpec(itemId, fid, type, fidKey) {
    if (!this.fidSpec[fid]) {
      this.fidSpec[fid] = {
        itemId: itemId,
        // item: store.getters['item/GET_ID_TO_ITEM_MAP'][itemId],
        item: Repository.getInstance().getItemByID(itemId),
        fidKey: fidKey,
        type: type,
        refered: [],
      };
    }
  }

  static addPropertyListener(itemId, fid, listener) {
    const fidKey = this.getFidKey(itemId, fid);
    fid = this.getUniqueFid(itemId, fid);
    this.initFidSpec(itemId, fid, EventType.PROP, fidKey);
    this.addListener(fid, listener);
  }

  static addPropertyRefListener(itemId, fid, rItemId, rFid) {
    fid = this.getUniqueFid(itemId, fid);
    rFid = this.getUniqueFid(rItemId, rFid);
    if (this.fidSpec[rFid] && this.fidSpec[rFid].type === EventType.PROP) {
      if (
        this.fidSpec[rFid].refered.findIndex((obj) => obj.fid === fid) === -1
      ) {
        this.fidSpec[rFid].refered.push({
          itemId: itemId,
          fid: fid,
        });
      }
    }
  }

  static addArrayListener(itemId, fid, listener) {
    fid = this.getUniqueFid(itemId, fid);
    this.initFidSpec(itemId, fid + EventType.ADD.getIndex(), EventType.ADD);
    this.initFidSpec(itemId, fid + EventType.REM.getIndex(), EventType.REM);
    this.initFidSpec(itemId, fid + EventType.CLR.getIndex(), EventType.CLR);

    this.addListener(fid + EventType.ADD.getIndex(), listener.add);
    this.addListener(fid + EventType.REM.getIndex(), listener.remove);
    this.addListener(fid + EventType.CLR.getIndex(), listener.clear);
  }

  static addArrayRefListener(itemId, fid, rItemId, rFid, type) {
    fid = this.getUniqueFid(itemId, fid);
    rFid = this.getUniqueFid(rItemId, rFid) + type.getIndex();
    if (this.fidSpec[rFid] && this.fidSpec[rFid].type === type) {
      if (
        this.fidSpec[rFid].refered.findIndex((obj) => obj.fid === fid) === -1
      ) {
        this.fidSpec[rFid].refered.push({
          itemId: itemId,
          fid: fid,
        });
      }
    }
  }

  static addListener(fid, listener) {
    if (!this.registered.has(fid)) {
      this.emitter.on(fid, listener);
      this.registered.add(fid);
    }
  }

  static propertyChanged(id, fid, newVal, oldVal) {
    if (newVal !== oldVal) {
      return this.emitChanged(EventType.PROP, id, fid, newVal, oldVal);
    } else {
      return [new Promise((resolve) => resolve(true))];
    }
  }

  static arrayChangedAdd(id, fid, value = null) {
    return this.emitChanged(EventType.ADD, id, fid, value);
  }

  static arrayChangedRemove(id, fid, value = null) {
    return this.emitChanged(EventType.REM, id, fid, value);
  }

  static arrayChangedClear(id, fid, value = null) {
    return this.emitChanged(EventType.CLR, id, fid, value);
  }

  static isListening(type, id, fid) {
    fid = this.getUniqueFid(id, fid) + type.getIndex();
    return this.fidSpec[fid] ? true : false;
  }

  static emitChanged(type, id, fid, newVal = null, oldVal = null) {
    if (Repository.getInstance().isLoading()) {
      return
    };
    // if (store.getters['IS_LOADING']) {
    //     return
    // }
    fid = this.getUniqueFid(id, fid) + type.getIndex();
    if (this.fidSpec[fid] && this.fidSpec[fid].type === type) {
      this.enqueue(fid, type, newVal, oldVal);
      for (const rSpec of this.fidSpec[fid].refered) {
        this.enqueue(rSpec.fid, type, newVal, oldVal);
      }
    }

    return Promise.all(this.triggerEventSequentially());
  }

  static enqueue(fid, type, newVal, oldVal) {
    if (this.fidSpec[fid]) {
      if (this.getItemByFID(fid)) {
        this.eventQueue.push({
          fid: fid,
          type: type,
          newVal: newVal,
          oldVal: oldVal,
        });
      }
    }

    // const obj = {
    //     fid: fid,
    //     type: type,
    //     newVal: newVal,
    //     oldVal: oldVal
    // }
    // this.eventQueue.push(obj)
    // var item = this.getItemByFID(fid)
    // console.log(item, obj)
    // console.log("enqueue:",this.eventQueue.length)
  }

  static dequeue() {
    return this.eventQueue.shift();
  }

  static triggerEventSequentially() {
    let res = [];
    //if (this.acquireLock()) {
    var obj = this.dequeue();
    if (obj) {
      var lstn = this.waitForEvents(...Object.values(obj));
      res.push(lstn(this.emitter));
      //this.releaseLock()
      res = res.concat(this.triggerEventSequentially());
    } else {
      //this.releaseLock()
      res.push(new Promise((resolve) => resolve(true)));
    }
    //}
    return res;
  }

  /*static acquireLock() {
        if (this.eventLocked) {
            return false
        } else {
            this.eventLocked = true
            return true
        }
    }

    static releaseLock() {
        this.eventLocked = false
    }*/

  static waitForEvents(fid, type, newVal, oldVal) {
    var item = this.getItemByFID(fid);
    return function (emitter) {
      var res = [];
      var events = emitter._events[fid];
      if (events) {
        if (events.constructor === Array) {
          for (const func of events) {
            const obj = func(item, newVal, oldVal);
            if (obj instanceof Promise) {
              res.push(obj);
            }
          }
        } else {
          const obj = events(item, newVal, oldVal);
          if (obj instanceof Array) {
            obj.forEach((o) => {
              if (o instanceof Promise) {
                res.push(o);
              }
            });
          } else if (obj instanceof Promise) {
            res.push(obj);
          }
        }
      }
      return Promise.all(res).then((results) => {
        // console.debug("results::", results);
        if (type === EventType.PROP) {
          return item.update("itemupdated", {
            propKey: EventHandler.fidSpec[fid].fidKey,
            newVal: newVal,
            oldVal: oldVal,
          });
        } else if (type === EventType.ADD) {
          return item.update("itemadded", {
            data: newVal,
          });
        } else if (type === EventType.REM) {
          return item.update("itemremoved", {
            data: newVal,
          });
        } else {
          return new Promise((resolve) => {
            resolve(true);
          });
        }
      });
    };
  }

  static getItemByFID(fid) {
    return Repository.getInstance().getItemByID(this.fidSpec[fid].itemId);
  }
}

/*export default class EventHandler {
    static emitter = new EventEmitter()
    
    static baseFidMap = {}
    static fidSpec = {}

    static OFFSET = null
    static ALIGN_OFFSET = 1000

    static getUniqueFid(id, fid) {
        return this.getBaseFid(id) + fid
    }

    static getBaseFid(id) {
        if (!this.baseFidMap[id]) {
            this.OFFSET += this.ALIGN_OFFSET
            this.baseFidMap[id] = this.OFFSET
        }
        return this.baseFidMap[id]
    }

    static initFidSpec(item, fid, type) {
        if (!this.fidSpec[fid]) {
            this.fidSpec[fid] = {
                item: item,
                type: type,
                refered: []
            }
        }
    }

    static addPropertyListener(item, fid, listener) {
        fid = this.getUniqueFid(item.id, fid)
        this.initFidSpec(item, fid, EventType.PROP)
        this.addListener(fid, listener)
    }

    static addPropertyRefListener(item, fid, rItem, rFid) {
        fid = this.getUniqueFid(item.id, fid)
        rFid = this.getUniqueFid(rItem.id, rFid)
        if (this.fidSpec[rFid] && this.fidSpec[rFid].type === EventType.PROP) {
            if (this.fidSpec[rFid].refered.findIndex((obj) => obj.fid === fid) === -1) {
                this.fidSpec[rFid].refered.push({
                    item: item,
                    fid: fid
                })
            }
        }
    }

    static addArrayListener(item, fid, listener) {
        fid = this.getUniqueFid(item.id, fid)
        this.initFidSpec(item, fid+EventType.ADD.getIndex(), EventType.ADD)
        this.initFidSpec(item, fid+EventType.REM.getIndex(), EventType.REM)
        this.initFidSpec(item, fid+EventType.CLR.getIndex(), EventType.CLR)

        this.addListener(fid+EventType.ADD.getIndex(), listener.add)
        this.addListener(fid+EventType.REM.getIndex(), listener.remove)
        this.addListener(fid+EventType.CLR.getIndex(), listener.clear)
    }

    static addArrayRefListener(item, fid, rItem, rFid, type) {
        fid = this.getUniqueFid(item.id, fid)
        rFid = this.getUniqueFid(rItem.id, rFid) + type.getIndex()
        if (this.fidSpec[rFid] && this.fidSpec[rFid].type === type) {
            if (this.fidSpec[rFid].refered.findIndex((obj) => obj.fid === fid) === -1) {
                this.fidSpec[rFid].refered.push({
                    item: item,
                    fid: fid
                })
            }
        }
    }

    static addListener(fid, listener) {
        this.emitter.on(fid, listener)
    }

    static propertyChanged(id, fid, newVal, oldVal) {
        if (newVal != oldVal) {
            this.emitChanged(EventType.PROP, id, fid, newVal, oldVal)
        }
    }
    
    static arrayChangedAdd(id, fid, value=null) {
        this.emitChanged(EventType.ADD, id, fid, value)
    }

    static arrayChangedRemove(id, fid, value=null) {
        this.emitChanged(EventType.REM, id, fid, value)
    }

    static arrayChangedClear(id, fid, value=null) {
        this.emitChanged(EventType.CLR, id, fid, value)
    }

    static emitChanged(type, id, fid, newVal=null, oldVal=null) {
        fid = this.getUniqueFid(id, fid) + type.getIndex()
        if (this.fidSpec[fid] && this.fidSpec[fid].type === type) {
            this.emitter.emit(fid, this.fidSpec[fid].item, newVal, oldVal)
            for (const rSpec of this.fidSpec[fid].refered) {
                this.emitter.emit(rSpec.fid, rSpec.item, newVal, oldVal)
            }
        }
    }
}*/
