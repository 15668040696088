/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import ClockPLLCtrlEvent from '@/model/src/events/ClockPLLCtrlEvent'
import ClockPLLCtrlChecker from '@/model/src/checkers/ClockPLLCtrlChecker'
import ITDA_CMU_PLL_PT from '@/model/gen/sfrSpec/ITDA_CMU_PLL_PT'
export default class ClockPLLCtrl extends Node {
    FID_CLOCKPLLCTRL_NAME = 100
    FID_CLOCKPLLCTRL_MAXNUM = 101
    FID_CLOCKPLLCTRL_ECC = 102
    FID_CLOCKPLLCTRL_PLLTYPE = 103
    FID_CLOCKPLLCTRL_SDCPATH = 104
    FID_CLOCKPLLCTRL_LAST = 104

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockPLLCtrl"}))
        
        this.setEvent(new ClockPLLCtrlEvent(this))
        this.setChecker(new ClockPLLCtrlChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.maxNum === undefined) {
        this.properties.maxNum = 32
    }
    if (this.properties.ECC === undefined) {
        this.properties.ECC = false
    }
    if (this.properties.SDCPath === undefined) {
        this.properties.SDCPath = ""
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockPLLCtrl"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 32, "category": "IP", "multiple": false, "readOnly": false}, "ECC": {"type": "boolean", "label": "External Clock Path", "desc": "Control External Clock", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "plltype": {"type": "enum", "label": "PLL Type", "targets": [], "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "", "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 32, "category": "IP", "multiple": false, "readOnly": false}, "ECC": {"type": "boolean", "label": "External Clock Path", "desc": "Control External Clock", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "plltype": {"type": "enum", "label": "PLL Type", "targets": [], "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "", "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ClockPLL", "auto": false, "title": "", "searchable": false, "headers": [], "hidden": true, "category": "IP"}, {"name": "ClockPLLCtrlEXT", "auto": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_CMU_PLL_PT
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_CMU_PLL_PT
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKPLLCTRL_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKPLLCTRL_NAME
    }
    

    setMaxNum(maxNum, event=true) {
        if ((maxNum !== null) && (maxNum !== undefined)) {
            if (![Number].includes(maxNum.constructor)) {
                throw `maxNum(${maxNum}) must be [Number] type`
            }
        }
        const newVal = (maxNum === null || maxNum === undefined) ? undefined : maxNum
        const oldVal = this.properties.maxNum
        this.properties.maxNum = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKPLLCTRL_MAXNUM, newVal, oldVal)
        }
    }
    getMaxNum(pure = false) {
        return this.properties.maxNum
        
    }
    getFidMaxNum() {
        return this.FID_CLOCKPLLCTRL_MAXNUM
    }
    

    setECC(ECC, event=true) {
        if ((ECC !== null) && (ECC !== undefined)) {
            if (![Boolean].includes(ECC.constructor)) {
                throw `ECC(${ECC}) must be [Boolean] type`
            }
        }
        const newVal = (ECC === null || ECC === undefined) ? undefined : ECC
        const oldVal = this.properties.ECC
        this.properties.ECC = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKPLLCTRL_ECC, newVal, oldVal)
        }
    }
    getECC(pure = false) {
        return this.properties.ECC
        
    }
    getFidECC() {
        return this.FID_CLOCKPLLCTRL_ECC
    }
    

    setPlltype(plltype, event=true) {
        if ((plltype !== null) && (plltype !== undefined)) {
            if (![String].includes(plltype.constructor)) {
                throw `plltype(${plltype}) must be [String] type`
            }
        }
        const newVal = (plltype === null || plltype === undefined) ? undefined : plltype
        const oldVal = this.properties.plltype
        this.properties.plltype = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKPLLCTRL_PLLTYPE, newVal, oldVal)
        }
    }
    getPlltype(pure = false) {
        return this.properties.plltype
        
    }
    getFidPlltype() {
        return this.FID_CLOCKPLLCTRL_PLLTYPE
    }
    

    setSDCPath(SDCPath, event=true) {
        if ((SDCPath !== null) && (SDCPath !== undefined)) {
            if (![String].includes(SDCPath.constructor)) {
                throw `SDCPath(${SDCPath}) must be [String] type`
            }
        }
        const newVal = (SDCPath === null || SDCPath === undefined) ? undefined : SDCPath
        const oldVal = this.properties.SDCPath
        this.properties.SDCPath = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKPLLCTRL_SDCPATH, newVal, oldVal)
        }
    }
    getSDCPath(pure = false) {
        return this.properties.SDCPath
        
    }
    getFidSDCPath() {
        return this.FID_CLOCKPLLCTRL_SDCPATH
    }
    

    getFidLast() {
        return this.FID_CLOCKPLLCTRL_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKPLLCTRL_NAME:
                return "name"

            case this.FID_CLOCKPLLCTRL_MAXNUM:
                return "maxNum"

            case this.FID_CLOCKPLLCTRL_ECC:
                return "ECC"

            case this.FID_CLOCKPLLCTRL_PLLTYPE:
                return "plltype"

            case this.FID_CLOCKPLLCTRL_SDCPATH:
                return "SDCPath"

            default:
                return ""
        }
    }


    newClockPLL(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockPLL", properties, title, domain)
    }
    remClockPLL(obj) {
        return this.removeChild(obj)
    }
    getClockPLLs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockPLL")
            return res
        })
    }
    newClockPLLCtrlEXT(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockPLLCtrlEXT", properties, title, domain)
    }
    remClockPLLCtrlEXT(obj) {
        return this.removeChild(obj)
    }
    getClockPLLCtrlEXT() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "ClockPLLCtrlEXT")
            return res
        })
    }
}