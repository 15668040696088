import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodeSequence from "./ITDANodeSequence";

export default class ITDASEQSTART extends ITDANodeSequence {
  constructor(name = "") {
    super(name, NodeType.ITDASEQSTART);
    this.render = ITDANodeRenderer.ITDASEQSTART.render;
  }
}
