/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x1000",
    "size": "0x2000",
    "alignSize": "0x20",
    "sfrs": [
        {
            "name": "INT_IN",
            "alignOffset": "0x10",
            "description": "Interrupt Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_INT_IN",
                    "index": -1,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Source for {{name}}\n0 = Interrupt Source is Low\n1 = Interrupt Source is High",
                    "initValue": "0x0",
                    "nc": "SFR_INT_IN"
                }
            ]
        },
        {
            "name": "INT_EN",
            "alignOffset": "0x14",
            "description": "Interrupt Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_INT_EN",
                    "index": -1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Enable for {{name}}\n0 = Interrupt is Disabled\n1 = Interrupt is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_INT_EN"
                }
            ]
        },
        {
            "name": "INT_TYPE",
            "alignOffset": "0x18",
            "description": "Interrupt Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_INT_TYPE",
                    "index": -1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Type for {{name}}\n0 = Edge Triggered\n1 = Level Triggered",
                    "initValue": "0x0",
                    "nc": "SFR_INT_TYPE"
                }
            ]
        },
        {
            "name": "INT_DIR",
            "alignOffset": "0x1c",
            "description": "Interrupt Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_INT_DIR",
                    "index": -1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Direction for {{name}}\nIf INT_TYPE is 0, then \n0 = Fall Edge Triggered\n1 = Rise Edge Triggered\nIf INT_TYPE is 1, then \n0 = Level Low Triggered\n1 = Level High Triggered",
                    "initValue": "0x0",
                    "nc": "SFR_INT_DIR"
                }
            ]
        }
    ]
}