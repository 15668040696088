/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import ClockIPEvent from '@/model/src/events/ClockIPEvent'
import ClockIPChecker from '@/model/src/checkers/ClockIPChecker'
export default class ClockIP extends Node {
    FID_CLOCKIP_NAME = 100
    FID_CLOCKIP_LAST = 100

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockIP"}))
        
        this.setEvent(new ClockIPEvent(this))
        this.setChecker(new ClockIPChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "IP_NONAME"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockIP"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ClockIPInput", "auto": false, "searchable": false, "title": "Clock Inputs", "headers": [{"title": "Name", "value": "name", "width": "45%", "minWidth": "100px", "align": "start", "sortable": true}, {"title": "Gated", "value": "gated", "minWidth": "100px", "align": "center", "sortable": true}, {"title": "Shared", "value": "shared", "width": "45%", "minWidth": "100px", "align": "start", "sortable": true}], "category": "IP"}, {"name": "ClockQChannelInfo", "auto": false, "searchable": false, "title": "Q-Channel", "headers": [{"title": "Name", "value": "name", "align": "start", "sortable": true}, {"title": "Control Clocks", "value": "controlledClocks", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKIP_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKIP_NAME
    }
    

    getFidLast() {
        return this.FID_CLOCKIP_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKIP_NAME:
                return "name"

            default:
                return ""
        }
    }


    newClockIPInput(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockIPInput", properties, title, domain)
    }
    remClockIPInput(obj) {
        return this.removeChild(obj)
    }
    getClockIPInputs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockIPInput")
            return res
        })
    }
    newClockQChannelInfo(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockQChannelInfo", properties, title, domain)
    }
    remClockQChannelInfo(obj) {
        return this.removeChild(obj)
    }
    getClockQChannelInfos() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockQChannelInfo")
            return res
        })
    }
}