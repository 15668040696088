import { ITDAGrid as ITDAGridRenderer } from "./renderers";
export default class ITDAGrid {
  render: (
    gridCanvas: HTMLElement,
    width: number,
    height: number,
    gridSpace: number,
    gridStroke: number
  ) => void;
  constructor() {
    this.render = ITDAGridRenderer.render;
  }
}
