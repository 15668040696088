'use strict';

export default class NodeType {

    static Module           = new NodeType(2, "Module", "Module")
    static Instance         = new NodeType(2, "Instance", "Instance")
    static ModuleInstance   = new NodeType(2, "ModuleInstance", "ModuleInstance")

    static Clock            = new NodeType(2, "Clock", "Clock")
    static Power            = new NodeType(2, "Power", "Power")
    static Sequence         = new NodeType(2, "Sequence", "Sequence")
    static UPF              = new NodeType(2, "UPF", "UPF")
    static HM              = new NodeType(2, "UPF", "HM")
    
    constructor(index, key, label="") {
        this.index = index
        this.key = key
        this.label = label
    }

    static getKeys() {
        return Object.keys(this)
    }

    static getValues() {
        return Object.values(this)
    }

    static getEntries() {
        return Object.entries(this)
    }
    
    static getObjByKey(key) {
        return this[key]
    }

    getKey() {
        return this.key
    }

    getLabel() {
        return this.label
    }
}
