/**
 *  Generated by Modeler
 *  timestamp: 2022-08-22 12:24:20.652766
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";

export default class SFRFieldChecker extends ItemChecker {
  constructor(item) {
    super(item);
    this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME);
    this.addChecker(item.getFidIndex(), this.checkIndex, ErrorType.CONFIG);
    this.addChecker(item.getFidSize(), this.checkSize, ErrorType.CONFIG);
    //this.addChecker(item.getFidAccess(), this.checkAccess, ErrorType.NAME)
    //this.addChecker(item.getFidInitValue(), this.checkInitValue, ErrorType.NAME)
  }
  checkName(item) {
    for (const cItem of item.getParent().getChildren()) {
      if (cItem === item) {
        continue;
      }
      if (cItem.getName() === item.getName()) {
        this.newError(`${item.getName()} is duplicated`, cItem);
      }
    }
  }
  checkIndex(item) {
    if (item.getIndex() > 31) {
      this.newError(`Please check the index(${item.getIndex()})`, item);
    }
    for (const fieldItem of item.getParent().getSFRFields()) {
      if (fieldItem === item) {
        continue;
      }
      if (fieldItem.getIndex() === item.getIndex()) {
        this.newError(`Please check the index(${item.getIndex()})`, fieldItem);
      }
    }
  }
  checkSize(item) {
    const msb = item.getIndex() + item.getSize() - 1;
    const lsb = item.getIndex();
    if (msb > 31) {
      this.newError(`Please check the overflow or overlap`, item);
    }
    for (const fieldItem of item.getParent().getSFRFields()) {
      if (fieldItem === item) {
        continue;
      }
      const fmsb = fieldItem.getIndex() + fieldItem.getSize() - 1;
      const flsb = fieldItem.getIndex();
      if (flsb <= msb && fmsb >= lsb) {
        this.newError(`Please check the overflow or overlap`, fieldItem);
      }
    }
  }
  checkAccess() {
    // TBD
  }
  checkInitValue() {
    // TBD
  }
}
