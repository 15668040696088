/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import SequenceHandler from "../SequenceHandler";
import ItemType from "../ItemType";

export default class PowerSequenceIfEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidBack(), () => {});
  }

  postProcess(item) {
    return this.setupPorts(item);
  }

  setupPorts(item) {
    return [
      item.newInput({
        key: `SEQ_${ItemType.Input.getKey().toUpperCase()}`,
        socket: "SEQ",
        position: "TOP",
      }).promise,
      item.newOutput({
        key: `SEQREF_${ItemType.Output.getKey().toUpperCase()}`,
        socket: "SEQREF",
        position: "RIGHT",
      }).promise,
      item.newOutput({
        key: `SEQ_${ItemType.Output.getKey().toUpperCase()}`,
        socket: "SEQ",
        position: "BOTTOM",
        multiple: false,
      }).promise,
      item.newInput({
        key: `SEQREF_${ItemType.Input.getKey().toUpperCase()}`,
        socket: "SEQREF",
        position: "LEFT",
      }).promise,
    ];
  }
}
