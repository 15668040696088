<template>
  <h3 style="color: black">Before Save</h3>
  <input />
  <hot-table
    ref="hot"
    :data="rawData"
    :columns="columns"
    :rowHeaders="true"
    :colHeaders="colHeaders"
    :height="200"
    :cells="cells"
    :colWidths="120"></hot-table>
  <button
    style="
      color: black;
      border: 1px solid black;
      padding: 2px 5px;
      margin-top: 10px;
      margin-bottom: 30px;
    "
    @click="saveData()">
    저장
  </button>
  <h3 style="color: black">After Save</h3>
  <hot-table
    :data="newData"
    :columns="columns"
    :rowHeaders="true"
    :colHeaders="colHeaders"
    :height="200"
    :cells="cells"></hot-table>
</template>

<script setup lang="ts">
import { ref, reactive, computed, defineEmits, onMounted } from "vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import { TableType } from "./types";
import TableRenderer from "./renderer";
import TableSchema from "./schema";
import Handsontable from "handsontable";
import { RowObject } from "handsontable/common";

const hot = ref<{ hotInstance: Handsontable.Core } | null>(null);

let newData = reactive<RowObject[]>([]);

TableRenderer(TableType.DFT);
const schema = TableSchema(TableType.DFT);
const colHeaders = schema?.getHeaders();

const saveData = () => {
  newData.length = 0;
  const currentData = hot.value?.hotInstance.getSourceData() as RowObject[];
  newData.push(...currentData);
};

registerAllModules();

const rawData = [
  {
    id: 1,
    Port: "FIN",
    Direction: "I",
    FUNC: "DIRECT",
    DA: "DIRECT",
    SCANINT: "DIRECT",
    SCANEXT: "DIRECT",
    HTOL: "DIRECT",
    vector: { left: 4, right: 2 },
  },
  {
    id: 2,
    Port: "PDIV",
    Direction: "I",
    FUNC: "DIRECT",
    DA: "X",
    SCANINT: "DIRECT",
    SCANEXT: "DIRECT",
    HTOL: "X",
    vector: { left: 4, right: 2 },
  },
  {
    id: 3,
    Port: "MDIV",
    Direction: "I",
    FUNC: "TIE: 0x1",
    DA: "X",
    SCANINT: "DIRECT",
    SCANEXT: "DIRECT",
    HTOL: "X",
    vector: { left: 4, right: 2 },
  },
  {
    id: 4,
    Port: "SDIV",
    Direction: "I",
    FUNC: "DIRECT",
    DA: "X",
    SCANINT: "DIRECT",
    SCANEXT: "DIRECT",
    HTOL: "X",
    vector: { left: 4, right: 2 },
  },
  {
    id: 5,
    Port: "FOUT",
    Direction: "O",
    FUNC: "DIRECT",
    DA: "MODE_DIRECT",
    SCANINT: "DIRECT",
    SCANEXT: "DIRECT",
    HTOL: "X",
    vector: { left: 4, right: 2 },
  },
];

const columns = schema?.getColumns();
const cells = computed(() => {
  return hot.value?.hotInstance
    ? schema?.getCells.bind({ hot: hot.value?.hotInstance })
    : schema?.getCells;
});

const emit = defineEmits(["mounted"]);
onMounted(async () => {
  emit("mounted");
});
</script>

<style lang="sass" scoped>
.selectWrapper
  border: 1px solid black
  color: black
  margin-bottom: 10px
  padding: 2px 10px
</style>
