import { NavigationState } from "./types";

const state = (): NavigationState => ({
  show: true,
  selectedKeys: [],
});

const mutations = {
  TOGGLE_NAV(state: NavigationState) {
    state.show = !state.show;
  },
  SET_SELECTED_KEYS(state: NavigationState, selected: string | undefined) {
    if (selected) state.selectedKeys = [selected];
    else state.selectedKeys.length = 0;
  },
};

const getters = {
  IS_NAV_OPENED(state: NavigationState) {
    return state.show;
  },
  GET_SELECTED_KEYS(state: NavigationState) {
    return state.selectedKeys;
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
