<template>
  <div ref="wrapper" class="property-wrapper" :style="style">
    <v-icon
      icon="mdi-drag-horizontal-variant"
      class="property-drag"
      @pointerdown="onPointerDown"></v-icon>
    <div class="property-group">
      <div
        class="resizer left"
        :style="styleResizerLeft"
        @pointerdown="resizerPointerDown($event, 'left')"></div>
      <div
        class="resizer right"
        :style="styleResizerRight"
        @pointerdown="resizerPointerDown($event, 'right')"></div>
      <n-collapse-transition
        :show="props.nodeItem ? true : false"
        class="property-transition">
        <CanvasProperty
          v-if="props.nodeItem"
          :item="props.nodeItem"
          :seed="`${props.nodeItem?.getId()}-${
            store.getters['component/GET_NAV_RIGHT_PROPERTY']
          }`" />
        <v-divider v-if="utils.showConfiguration()" class="property-divider" />
        <CanvasPropertyConfig
          v-if="props.nodeItem && utils.showConfiguration()"
          :item="props.nodeItem"
          :seed="`${props.nodeItem?.getId()}-${
            store.getters['component/GET_NAV_RIGHT_PROPERTY']
          }`" />
      </n-collapse-transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import { Item } from "ITDAModelTypes";
import CanvasProperty from "./CanvasProperty.vue";
import CanvasPropertyConfig from "./CanvasPropertyConfig.vue";
import utils from "@/components/navigation/utils";

const store = useStore();
const props = defineProps<{
  nodeItem: Item;
}>();

const wrapper = ref<HTMLElement>();
const posX = ref(0);
const posY = ref(0);
const offset = {
  x: 0,
  y: 0,
};

const defaultWidth = 400;
const leftDelta = ref(0);
const width = ref(defaultWidth);

const style = computed(() => {
  return {
    display: props.nodeItem ? "block" : "none",
    left: `${posX.value + leftDelta.value}px`,
    top: `${posY.value}px`,
    width: `${width.value}px`,
  };
});

const styleResizerLeft = computed(() => {
  return {
    left: `1px`,
  };
});

const styleResizerRight = computed(() => {
  if (wrapper.value) {
    return {
      left: `${width.value - 10}px`,
    };
  } else {
    return {};
  }
});

const onPointerDown = (e: PointerEvent) => {
  offset.x = e.clientX - posX.value;
  offset.y = e.clientY - posY.value;

  window.addEventListener("pointermove", onPointerMove);
  window.addEventListener("pointerup", onPointerUp);
};

const onPointerMove = (e: PointerEvent) => {
  posX.value = e.clientX - offset.x;
  posY.value = e.clientY - offset.y;
};

const onPointerUp = (e: PointerEvent) => {
  window.removeEventListener("pointermove", onPointerMove);
  window.removeEventListener("pointerup", onPointerUp);
};

const direction = ref("left");
const resizerX = ref(0);
const resizerPointerDown = (e: PointerEvent, dir: "left" | "right") => {
  direction.value = dir;
  resizerX.value = e.clientX;

  window.addEventListener("pointermove", resizerPointerMove);
  window.addEventListener("pointerup", resizerPointerUp);
};

const resizerPointerMove = (e: PointerEvent) => {
  e.preventDefault();
  const delta =
    direction.value === "left"
      ? resizerX.value - e.clientX
      : e.clientX - resizerX.value;
  resizerX.value = e.clientX;
  if (width.value + delta >= defaultWidth) {
    if (direction.value === "left") {
      width.value += delta;
      leftDelta.value += -delta;
    } else {
      width.value += delta;
    }
  }
};

const resizerPointerUp = (e: PointerEvent) => {
  window.removeEventListener("pointermove", resizerPointerMove);
  window.removeEventListener("pointerup", resizerPointerUp);
};

onMounted(() => {
  if (wrapper.value) {
    const parentRect = wrapper.value?.parentElement?.getBoundingClientRect();
    const rect = wrapper.value?.getBoundingClientRect();
    if (parentRect && rect) {
      posX.value = parentRect.width - defaultWidth;
      posY.value = parentRect.height * 0.1;
    }
  }
});
</script>

<style lang="css" scoped>
.property-wrapper {
  position: absolute;
  background: white;
  border: 0.5px solid lightgray;
  border-radius: 11px;
  filter: drop-shadow(-1px 5px 3px rgba(0, 0, 0, 0.2));
}
.property-group {
  height: fit-content;
  max-height: 60vh;
  overflow-y: auto;
}
.resizer {
  position: absolute;
  border: 4px solid transparent;
  border-radius: 60%;
  height: -webkit-fill-available;
  z-index: 9999;
}
.resizer.left {
  cursor: e-resize;
}
.resizer.right {
  cursor: w-resize;
}

.property-transition {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.property-drag {
  height: 24px;
  width: inherit;
  cursor: move;
  color: gray;
  background: #fafafa;
  border-top-left-radius: 11px;
  border-top-right-radius: 11px;
}

.property-divider {
  width: inherit;
}
</style>
