/**
 *  Generated by Modeler
 *  timestamp: 2022-08-22 12:24:20.652766
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";
import LabelType from "../LabelType";

export default class LabelRefChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
    this.addChecker(
      item.getFidBoundaryName(),
      this.checkBoundaryName,
      ErrorType.NAME
    );
    // this.addChecker(
    //   item.getFidItemLabel(),
    //   this.checkItemLabel,
    //   ErrorType.CLOCK_DIAGRAM
    // );
    //this.addChecker(item.getFidNodeId(), this.checkNodeId, ErrorType.NAME)
  }
  checkName() {
    // TBD
  }
  checkBoundaryName(item) {
    if (
      item.getType() === LabelType.EXTERNAL.getKey() &&
      !item.getBoundaryName()
    ) {
      this.newError(`LabelRef must have boundary port name`);
    }
  }
  checkItemLabel() {}
  checkNodeId() {
    // TBD
  }
}
