/**
 *  Generated by Modeler
 *  timestamp: 2022-12-22 12:18:37.191826
 */
//import ErrorType from '@/model/ErrorType'
import ItemChecker from "@/model/src/ItemChecker";

export default class ConfigurationFolderChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidSelectedConfiguration(), this.checkSelectedConfiguration, ErrorType.NAME)
  }
  checkSelectedConfiguration() {
    // TBD
  }
}
