import * as ITDANodes from "../nodes";
import { NodeCustomProps, NodeType } from "../types";
export default {
  createNode: (
    nodeType: NodeType,
    props?: NodeCustomProps
  ): ITDANodes.ITDANode | undefined => {
    let res;
    switch (nodeType) {
      case NodeType.ITDABUFFER:
        res = new ITDANodes.ITDABUFFER();
        break;
      case NodeType.ITDADIVIDER:
        res = new ITDANodes.ITDADIVIDER();
        break;
      case NodeType.ITDAFREQMON:
        res = new ITDANodes.ITDAFREQMON();
        break;
      case NodeType.ITDAGATE:
        res = new ITDANodes.ITDAGATE();
        break;
      case NodeType.ITDALABEL:
        res = new ITDANodes.ITDALABEL();
        break;
      case NodeType.ITDALABELREF:
        res = new ITDANodes.ITDALABELREF();
        break;
      case NodeType.ITDAGFMUX:
        res = new ITDANodes.ITDAGFMUX();
        break;
      case NodeType.ITDANMDIVIDER:
        res = new ITDANodes.ITDANMDIVIDER();
        break;
      case NodeType.ITDAPLLCTRL:
        res = new ITDANodes.ITDAPLLCTRL();
        break;
      case NodeType.ITDAREFCLKMUX:
        res = new ITDANodes.ITDAREFCLKMUX();
        break;
      case NodeType.ITDASOURCE:
        res = new ITDANodes.ITDASOURCE();
        break;
      case NodeType.ITDADFTOCCBUFFER:
        res = new ITDANodes.ITDADFTOCCBUFFER();
        break;
      case NodeType.ITDAQCH:
        res = new ITDANodes.ITDAQCH();
        break;
      case NodeType.ITDAQCHINFO:
        res = new ITDANodes.ITDAQCHINFO();
        break;

      case NodeType.ITDAAPM:
        res = new ITDANodes.ITDAAPM();
        break;
      case NodeType.ITDAREFCLKEN:
        res = new ITDANodes.ITDAREFCLKEN();
        break;
      case NodeType.ITDAPSW:
        res = new ITDANodes.ITDAPSW();
        break;
      case NodeType.ITDAPWREN:
        res = new ITDANodes.ITDAPWREN();
        break;
      case NodeType.ITDAISOEN:
        res = new ITDANodes.ITDAISOEN();
        break;
      case NodeType.ITDAMEM:
        res = new ITDANodes.ITDAMEM();
        break;
      case NodeType.ITDARETENTION:
        res = new ITDANodes.ITDARETENTION();
        break;
      case NodeType.ITDAOTP:
        res = new ITDANodes.ITDAOTP();
        break;
      case NodeType.ITDAHANDSHAKE:
        res = new ITDANodes.ITDAHANDSHAKE();
        break;
      case NodeType.ITDACLINK:
        res = new ITDANodes.ITDACLINK();
        break;
      case NodeType.ITDAPCH:
        res = new ITDANodes.ITDAPCH();
        break;
      case NodeType.ITDARESET:
        res = new ITDANodes.ITDARESET();
        break;
      case NodeType.ITDAUSERDEFOUT:
        res = new ITDANodes.ITDAUSERDEFOUT();
        break;
      case NodeType.ITDAUSERDEFIN:
        res = new ITDANodes.ITDAUSERDEFIN();
        break;
      case NodeType.ITDAINFORM:
        res = new ITDANodes.ITDAINFORM();
        break;
      case NodeType.ITDATIMEOUT:
        res = new ITDANodes.ITDATIMEOUT();
        break;
      case NodeType.ITDAPMRCTRL:
        res = new ITDANodes.ITDAPMRCTRL();
        break;
      case NodeType.ITDALATCH:
        res = new ITDANodes.ITDALATCH();
        break;
      case NodeType.ITDAINTRSFR:
        res = new ITDANodes.ITDAINTRSFR();
        break;
      case NodeType.ITDAINTREXT:
        res = new ITDANodes.ITDAINTREXT();
        break;
      case NodeType.ITDATIMER:
        res = new ITDANodes.ITDATIMER();
        break;
      case NodeType.ITDAPMDGRP:
        res = new ITDANodes.ITDAPMDGRP();
        break;

      case NodeType.ITDASEQSTART:
        res = new ITDANodes.ITDASEQSTART();
        break;
      case NodeType.ITDASEQEND:
        res = new ITDANodes.ITDASEQEND();
        break;
      case NodeType.ITDASEQWRITE:
        res = new ITDANodes.ITDASEQWRITE();
        break;
      case NodeType.ITDASEQREADWAIT:
        res = new ITDANodes.ITDASEQREADWAIT();
        break;
      case NodeType.ITDASEQWAIT:
        res = new ITDANodes.ITDASEQWAIT();
        break;
      case NodeType.ITDASEQIF:
        res = new ITDANodes.ITDASEQIF();
        break;
      case NodeType.ITDASEQGOTO:
        res = new ITDANodes.ITDASEQGOTO();
        break;
      case NodeType.ITDASEQCALL:
        res = new ITDANodes.ITDASEQCALL();
        break;
      case NodeType.ITDASEQLABEL:
        res = new ITDANodes.ITDASEQLABEL();
        break;
      case NodeType.ITDASEQLABELREF:
        res = new ITDANodes.ITDASEQLABELREF();
        break;
      case NodeType.ITDASEQBACK:
        res = new ITDANodes.ITDASEQBACK();
        break;

      case NodeType.ITDAINSTANCEIP:
      case NodeType.ITDAINSTANCEPMD:
      case NodeType.ITDAINSTANCESDC:
      case NodeType.ITDAINSTANCESDCPHANTOMGROUP:
      case NodeType.ITDAINSTANCEBLOCK:
      case NodeType.ITDAINSTANCECMU:
      case NodeType.ITDAINSTANCEPMC:
      case NodeType.ITDAINSTANCEPMIF:
      case NodeType.ITDAINSTANCEPOWERDOMAIN:
      case NodeType.ITDAINSTANCEHARDMACRO:
        res = new ITDANodes.ITDAINSTANCE(nodeType);
        break;
      case NodeType.ITDAMODULEUPF:
        res = new ITDANodes.ITDAMODULE(nodeType);
        break;
      case NodeType.ITDAUPFPOWERSOURCE:
      case NodeType.ITDAUPFGROUND:
      case NodeType.ITDAUPFPHANTOMPOWERSOURCE:
      case NodeType.ITDAUPFPHANTOMGROUND:
        res = new ITDANodes.ITDAUPFSOURCE(nodeType);
        break;
      case NodeType.ITDAUPFLEVELSHIFTER:
        res = new ITDANodes.ITDAUPFLEVELSHIFTER();
        break;

      case NodeType.ITDASDCCLOCKGROUP:
        res = new ITDANodes.ITDASDCCLOCKGROUP();
        break;

      default:
        break;
    }
    if (res instanceof ITDANodes.ITDANode && props) {
      res.updateProps(props);
    }
    return res;
  },
};
