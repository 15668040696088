import API from "@/api/internal";
import { Repository } from "../store";
import { NodeType } from "../types";
import { ItemType } from "ITDAModelTypes";

const repo = Repository.getInstance();

export const getEXTData = (diagramID: string, nodeId: string) => {
  const editor = repo.getEditor(diagramID);
  const node = editor.getNode(nodeId);
  const item = API.getItem(node?.getItemID());
  if (!item) {
    return [];
  }

  if (item.getItemType() === ItemType.SDCPhantom) {
    return [];
  }

  switch (node.getType()) {
    case NodeType.ITDADIVIDER:
      return getDividerEXT(node.getItemID());
    case NodeType.ITDAGFMUX:
      return getMultiplexerEXT(node.getItemID());
    case NodeType.ITDAPLLCTRL:
      return getPllCtrlEXT(node.getItemID());
    case NodeType.ITDAGATE:
      return getGateEXT(node.getItemID());
    case NodeType.ITDAREFCLKMUX:
      return getRefCLKMultiplexerEXT(node.getItemID());
    case NodeType.ITDANMDIVIDER:
      return getNMDividerEXT(node.getItemID());
    case NodeType.ITDAFREQMON:
      return getFreqmonEXT(node.getItemID());
    case NodeType.ITDAQCH:
      return getQCHEXT(node.getItemID());
    default:
      return [];
  }
};

const getDividerEXT = (id: string): string[] => {
  const EXTS = ["PWRDOWN", "THROTTLE", "CUSTOM"];
  const res: string[] = [];

  const item = API.getItem(id);
  if (!item) return res;
  const extItem = item.getClockDividerEXT();
  if (!extItem) return res;
  EXTS.forEach((ext) => {
    if (extItem[getFunctionCase(ext)]()) {
      res.push(ext);
    }
  });
  return res;
};

const getMultiplexerEXT = (id: string): string[] => {
  const EXTS = ["THROTTLE", "CUSTOM"];
  const res: string[] = [];

  const item = API.getItem(id);
  if (!item) return res;
  const extItem = item.getClockMultiplexerEXT();
  if (!extItem) return res;
  EXTS.forEach((ext) => {
    if (extItem[getFunctionCase(ext)]()) {
      res.push(ext);
    }
  });
  return res;
};

const getPllCtrlEXT = (id: string): string[] => {
  const EXTS = ["PWRDOWN", "CUSTOM"];
  const res: string[] = [];

  const item = API.getItem(id);
  if (!item) return res;
  const extItem = item.getClockPLLCtrlEXT();
  if (!extItem) return res;
  EXTS.forEach((ext) => {
    if (extItem[getFunctionCase(ext)]()) {
      res.push(ext);
    }
  });
  return res;
};

const getGateEXT = (id: string): string[] => {
  const EXTS = ["SHORTSTOP", "CUSTOM", "EWAKEUP"];
  const res: string[] = [];

  const item = API.getItem(id);
  if (!item) return res;
  const extItem = item.getClockGateEXT();
  if (!extItem) return res;
  EXTS.forEach((ext) => {
    if (extItem[getFunctionCase(ext)]()) {
      res.push(ext);
    }
  });
  return res;
};
//
const getRefCLKMultiplexerEXT = (id: string): string[] => {
  const EXTS = ["PWRDOWN", "CUSTOM"];
  const res: string[] = [];

  const item = API.getItem(id);
  if (!item) return res;
  const extItem = item.getClockRefCLKMultiplexerEXT();
  if (!extItem) return res;
  EXTS.forEach((ext) => {
    if (extItem[getFunctionCase(ext)]()) {
      res.push(ext);
    }
  });
  return res;
};

const getNMDividerEXT = (id: string): string[] => {
  const EXTS = ["GRADUAL", "CUSTOM"];
  const res: string[] = [];

  const item = API.getItem(id);
  if (!item) return res;
  const extItem = item.getClockNMDividerEXT();
  if (!extItem) return res;
  EXTS.forEach((ext) => {
    if (extItem[getFunctionCase(ext)]()) {
      res.push(ext);
    }
  });
  return res;
};

const getFreqmonEXT = (id: string): string[] => {
  const EXTS = ["CUSTOM"];
  const res: string[] = [];

  const item = API.getItem(id);
  if (!item) return res;
  const extItem = item.getClockFreqmonEXT();
  if (!extItem) return res;
  EXTS.forEach((ext) => {
    if (extItem[getFunctionCase(ext)]()) {
      res.push(ext);
    }
  });
  return res;
};

const getQCHEXT = (id: string): string[] => {
  const res: string[] = [];
  const item = API.getItem(id);
  if (!item) return res;
  if (item.getPowerdownStep()) {
    res.push("PWRDOWN");
  }
  return res;
};

const getFunctionCase = (str: string) => {
  if (!str) return "";

  let name: string;
  switch (str) {
    case "PWRDOWN":
      name = "PowerDown";
      break;
    case "SHORTSTOP":
      name = "ShortStop";
      break;
    default:
      name = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
      break;
  }
  return `get${name}Mode`;
};
