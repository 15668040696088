import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodePower from "./ITDANodePower";

export default class ITDATIMER extends ITDANodePower {
  constructor(name = "") {
    super(name, NodeType.ITDATIMER);
    this.render = ITDANodeRenderer.ITDATIMER.render;
  }
}
