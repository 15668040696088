/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x1000",
    "size": "0x400",
    "alignSize": "0x8",
    "sfrs": [
        {
            "name": "CTRL_GFMUX_EXT",
            "alignOffset": "0x4",
            "description": "Clock Component Extension Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_SELECT_THROTTLE",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "MUX Selection at THROTTLE for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_SELECT_THROTTLE"
                },
                {
                    "name": "SFR_ENABLE_THROTTLE",
                    "index": 17,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "MUX Selection Automatic change Control for {{name}}\n0 = MUX Selection Automatic change to SFR_SELECT_THROTTLE at THROTTLE is Disabled\n1 = MUX Selection Automatic change to SFR_SELECT_THROTTLE at THROTTLE is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_THROTTLE"
                },
                {
                    "name": "SFR_OVERRIDED_THROTTLE",
                    "index": 25,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "MUX Selection Override Indication for {{name}}\n0 = MUX Selection is Controlled by SFR_SELECT\n1 = MUX Selection is Overrided by SFR_SELECT_THROTTLE",
                    "initValue": "0x0",
                    "nc": "SFR_OVERRIDED_THROTTLE"
                },
                {
                    "name": "SFR_OVERRIDED_CUSTOM",
                    "index": 27,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "MUX Selection Override Indication for {{name}}\n0 = MUX Selection is Controlled by SFR_SELECT\n1 = MUX Selection is Overrided by CUSTOM",
                    "initValue": "0x0",
                    "nc": "SFR_OVERRIDED_CUSTOM"
                }
            ]
        }
    ]
}