import { ItemType } from "ITDAModelTypes";

/**
 * @param { Item } pmdGrp
 * @param { string } [newValue]
 * @param { number } [targetOutputIdx]
 */
export const updateINTRChildrenTitle = (pmdGrp, newValue, targetOutputIdx) => {
  const intrGrps = pmdGrp.getPowerPMRINTRGRPs();
  if (newValue && targetOutputIdx !== undefined) {
    if (intrGrps.length > 0) {
      const children = [];
      intrGrps.forEach((intrGrp) => {
        children.push(
          ...intrGrp
            .getPowerPMRINTREvents()
            .slice(targetOutputIdx * 2, targetOutputIdx * 2 + 2)
        );
        children.push(
          ...intrGrp
            .getPowerPMRINTRGenBits()
            .slice(targetOutputIdx * 2, targetOutputIdx * 2 + 2)
        );
      });

      children.forEach((child) => {
        const type = child.getTitle().split("__")[1];
        const name = newValue
          ? `SFR_${newValue}__${type}`
          : `SFR_PMD${targetOutputIdx}__${type}`;
        child.setTitle(name);
      });
    }
  } else {
    if (intrGrps.length > 0) {
      const events = [];
      const genBits = [];
      intrGrps.forEach((intrGrp) => {
        events.push(...intrGrp.getPowerPMRINTREvents());
        genBits.push(...intrGrp.getPowerPMRINTRGenBits());
      });

      const pmdNames = [];
      const pmdOutputs = pmdGrp.getOutputs();
      pmdOutputs.forEach((pmdOutput) => {
        pmdNames.push(
          pmdOutput
            .getConnections()?.[0]
            ?.getTargetInput()
            .getParent()
            .getName()
        );
      });

      events.forEach((event, idx) => {
        const type = event.getTitle().split("__")[1];
        const name = pmdNames[Math.floor(idx / 2)]
          ? `SFR_${[pmdNames[Math.floor(idx / 2)]]}__${type}`
          : `SFR_PMD${Math.floor(idx / 2)}__${type}`;
        event.setTitle("", false);
        event.setTitle(name);
      });

      genBits.forEach((getBit, idx) => {
        const type = getBit.getTitle().split("__")[1];
        const name = pmdNames[Math.floor(idx / 2)]
          ? `SFR_${[pmdNames[Math.floor(idx / 2)]]}__${type}`
          : `SFR_PMD${Math.floor(idx / 2)}__${type}`;
        getBit.setTitle("", false);
        getBit.setTitle(name);
      });
    }
  }
};

/**
 * @param { Item } item
 */
export const updatePMR2PMDGRPSEL = (item) => {
  const pmdGroupMap = new Map();

  const pmc =
    item.getItemType() === ItemType.PowerPMC
      ? item
      : item.getParent(ItemType.PowerPMC);
  if (!pmc) return;

  const powerDiagrams = pmc.getPowerDiagramFolder().getPowerDiagrams();
  const pmdGrps = [];
  powerDiagrams.forEach((diagram) => {
    pmdGrps.push(...diagram.getPowerPMRPMDGRPs());
  });
  if (pmdGrps.length > 0) {
    pmdGrps.forEach((p) => {
      pmdGroupMap.set(p, p.getOutputs());
    });
  }

  const sfrs = pmc
    .getSFRBlock()
    .getSFRs()
    .filter((o) => o.getItemRef().getItemType() === ItemType.PowerPMRCorePCU);
  const sfr = sfrs.filter((sfr) => {
    return sfr.getType() === "PMR_PMDGRP_CTRL";
  });
  sfr.forEach((o) => {
    const sfrFields = o.getSFRFields();
    sfrFields.forEach((sfrField) => {
      sfrField.setDescription(
        pmdGroupMap.size > 0
          ? createDescription(pmdGroupMap, pmc.getName())
          : createDescription(undefined, pmc.getName())
      );
    });
  });
};

const createDescription = (data, pmcName) => {
  if (!pmcName) pmcName = "PMC_NONAME";
  const maxNumOfPmdOutputs = 8;
  let description = `PMR to PMDGRP Path Selection for ${pmcName}\n`;

  if (data) {
    let index = 0;
    data.forEach((outputs, pmd) => {
      const instancePMDs = outputs.map((o) => {
        return o.getConnections().length > 0
          ? o.getConnections()[0].getTargetInput().getParent()
          : undefined;
      });
      const pmds = fillArrayWithUndefined(instancePMDs);
      pmds.forEach((pmd, idx) => {
        const pmdName = pmd?.getName()
          ? `${index * maxNumOfPmdOutputs + idx} = Select ${pmd.getName()}\n`
          : `${index * maxNumOfPmdOutputs + idx} = Non-used\n`;
        description = description.concat(pmdName);
      });
      index++;
    });
  } else {
    new Array(maxNumOfPmdOutputs).forEach((elem, idx) => {
      description += `${idx} = Non-used\n`;
    });
  }

  return description;
};

const fillArrayWithUndefined = (arr) => {
  const returnArrayLength = 8;

  if (arr.length > returnArrayLength) return arr;

  const returnArray = new Array(returnArrayLength);
  for (let i = 0; i < returnArrayLength; i++) {
    returnArray[i] = arr[i];
  }

  return returnArray;
};
