import { Item, ItemType, Module } from "ITDAModelTypes";
import { Context } from "../types";
import Canvas from "@/canvas";
import utils from "../utils";
import API from "@/api/internal";

export const itemremoved = async (context: Context): Promise<boolean> => {
  const item = context.options.item;
  const cItem = context.options.data;

  const diagram = API.getParentDiagram(cItem);
  const editor = Canvas.getEditor(diagram?.getId());

  if (editor) {
    if (cItem.isNodeType()) {
      if (editor.getNode(cItem.getNodeID())) {
        await editor.removeNode(cItem.getNodeID());
      }
    }
    if (cItem.isConnectionType()) {
      if (editor.getConnection(cItem.getConnectionID())) {
        await editor.removeConnection(cItem.getConnectionID());
      }
    }

    if (API.isConfigurationTarget(cItem)) {
      const configFolder = API.getConfigurationFolder(cItem);
      const configurations = configFolder?.getConfigurations();
      if (configurations?.length) {
        const configElems = getConfigElements(configurations);
        removeRefItemElems(cItem, configElems);
      }

      if (configFolder?.getParent(ItemType.PowerPMC)) {
        const sequence = configFolder
          ?.getParent()
          .getPowerSequenceConfigurationFolder();
        const PMD = sequence
          ?.getPowerSequenceConfigurations()
          .find((config: Item) => config.getType() === "PMD");
        const sequenceDiagram = PMD?.getPowerSequenceDiagrams().find(
          (psd: Item) => {
            return psd.getItemRef(true) === cItem.getId();
          }
        );
        if (sequenceDiagram) PMD.remPowerSequenceDiagram(sequenceDiagram);
      }
    }

    switch (item.getItemType()) {
      case ItemType.SDCClockGroup:
        {
          const instance = item.getParent();
          instance.getSDCPhantomGroups().forEach((grp: Item) => {
            grp.getSDCPhantoms().forEach((pt: Item) => {
              const node = editor.getNode(pt.getNodeID());
              if (pt.getClockGroup() === item) {
                pt.setClockGroup(null);
                node?.getCtrlSDC().setBackgroundColor("transparent");
                node?.getCtrlSDC().update();
              }
            });
          });
        }
        break;
      default:
        break;
    }

    switch (cItem.getItemType()) {
      case ItemType.ClockIPInput:
      case ItemType.Input:
      case ItemType.Output:
        {
          const node = editor.getNode(item.getNodeID());
          if (node) {
            utils.createNodePorts(node, item);
            if (item.getItemType() === ItemType.DUTInstance) {
              utils.updateParentScope(item.getUpper());
            }
          }
        }
        break;
      case ItemType.Configuration:
        {
          const deletedConfigId = cItem.getId();
          const project = cItem.getParent(ItemType.Project);
          const pmcs = project.getPowerPMCFolder().getPowerPMCs();

          const configElems: Item[] = [];
          pmcs.forEach((pmc: Item) => {
            const psss = pmc.getConfigurationFolder().getConfigurations();
            psss.map((pss: Item) =>
              configElems.push(...pss.getConfigurationElements())
            );
          });

          configElems.forEach((elem: Item) => {
            if (elem.getValue() === deletedConfigId) elem.setValue("null");
          });
        }
        break;
      case ItemType.PowerPMD:
        {
          const pmcs = cItem
            .getParent(ItemType.Project)
            .getPowerPMCFolder()
            .getPowerPMCs();
          pmcs.forEach((pmc: Item) => {
            const PMD = pmc
              .getPowerSequenceConfigurationFolder()
              .getPowerSequenceConfigurations()
              .find((config: Item) => {
                return config.getType() === "PMD";
              });
            const removeArr: Item[] = [];
            if (PMD) {
              PMD.getPowerSequenceDiagrams().forEach((diagram: Item) => {
                if (diagram.getItemRef()?.getModule(true) === cItem.getId())
                  removeArr.push(diagram);
              });
            }
            removeArr.forEach((elem: Item) => {
              PMD.remPowerSequenceDiagram(elem);
            });
          });
        }
        break;
      case ItemType.PowerPMRPMDGRP:
        API.updatePMR2PMDGRPSEL(cItem);
        break;
      default:
        break;
    }
  }

  return true;
};

const getConfigElements = (configurations: Item[]): Item[] => {
  const configElems: Item[] = [];
  configurations.forEach((setting: Item) => {
    configElems.push(...setting.getConfigurationElements());
  });
  return configElems;
};

const removeRefItemElems = async (item: Item, configElems: Item[]) => {
  for (const elem of configElems) {
    if (elem.getItemRef(true) === item.getId()) {
      await API.removeItem(elem.getId());
    }
  }
};
