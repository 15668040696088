/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x280",
    "size": "0x34",
    "alignSize": "0x34",
    "sfrs": [
        {
            "name": "PMR_PCU_CTRL",
            "alignOffset": "0x0",
            "description": "Memory Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_PMR_MEM_SEL",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "PMR Memory Selection for {{name}}\nSoftware should NOT configure this Field to 1, before SFR_PMR_RAM_DOWNLOAD_DONE = 1\nThis Field is only reset at Cold Booting of {{name}}\n0 = Select PMR ROM \n1 = Select PMR RAM",
                    "initValue": "0x0",
                    "nc": "SFR_PMR_MEM_SEL"
                }
            ]
        },
        {
            "name": "PMR_SEQ_CTRL",
            "alignOffset": "0x4",
            "description": "Power Sequence Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_PMR_RAM_DOWNLOAD_DONE",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "PMR_RAM Download Done Flag for {{name}}\nSoftware should configure this Field to 1, when PMR_RAM Download is Done\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PMR_RAM_DOWNLOAD_DONE",
                    "floating": true
                },
                {
                    "name": "SFR_PMD_RAM_DOWNLOAD_DONE",
                    "index": 4,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "PMD_RAM Download Done Flag for {{name}}\nSoftware should configure this Field to 1, when PMD_RAM Download is Done\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PMD_RAM_DOWNLOAD_DONE",
                    "floating": true
                },
                {
                    "name": "SFR_RESET_SEQ_DONE",
                    "index": 8,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Reset Sequence Done Flag for {{name}}\nPMR should configure this Field to 1, after Booting sequence is Done",
                    "initValue": "0x0",
                    "nc": "SFR_RESET_SEQ_DONE",
                    "floating": true
                }
            ]
        },
        {
            "name": "PMR_PMDGRP_CTRL",
            "alignOffset": "0x8",
            "description": "PMR to PMDGRP Path Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_PMR2PMDGRP_SEL",
                    "index": 0,
                    "size": 7,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMR to PMDGRP Path Selection for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PMR2PMDGRP_SEL"
                }
            ]
        },
        {
            "name": "PMR_INFORM",
            "alignOffset": "0xC",
            "description": "Inform Register for {{name}}",
            "fields": [
                {
                    "name": "SFR_DATA0",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA0",
                    "floating": true
                },
                {
                    "name": "SFR_DATA1",
                    "index": 1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA1",
                    "floating": true
                },
                {
                    "name": "SFR_DATA2",
                    "index": 2,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA2",
                    "floating": true
                },
                {
                    "name": "SFR_DATA3",
                    "index": 3,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA3",
                    "floating": true
                },
                {
                    "name": "SFR_DATA4",
                    "index": 4,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA4",
                    "floating": true
                },
                {
                    "name": "SFR_DATA5",
                    "index": 5,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA5",
                    "floating": true
                },
                {
                    "name": "SFR_DATA6",
                    "index": 6,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA6",
                    "floating": true
                },
                {
                    "name": "SFR_DATA7",
                    "index": 7,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA7",
                    "floating": true
                },
                {
                    "name": "SFR_DATA8",
                    "index": 8,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA8",
                    "floating": true
                },
                {
                    "name": "SFR_DATA9",
                    "index": 9,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA9",
                    "floating": true
                },
                {
                    "name": "SFR_DATA10",
                    "index": 10,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA10",
                    "floating": true
                },
                {
                    "name": "SFR_DATA11",
                    "index": 11,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA11",
                    "floating": true
                },
                {
                    "name": "SFR_DATA12",
                    "index": 12,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA12",
                    "floating": true
                },
                {
                    "name": "SFR_DATA13",
                    "index": 13,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA13",
                    "floating": true
                },
                {
                    "name": "SFR_DATA14",
                    "index": 14,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA14",
                    "floating": true
                },
                {
                    "name": "SFR_DATA15",
                    "index": 15,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "Inform Register for {{name}}\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DATA15",
                    "floating": true
                }
            ]
        },
        {
            "name": "PCU_R0",
            "alignOffset": "0x10",
            "description": "PMR Internal Register R0 Value for {{name}}",
            "fields": [
                {
                    "name": "SFR_PCU_R0",
                    "index": 0,
                    "size": 32,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMR Internal Register R0 Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PCU_R0"
                }
            ]
        },
        {
            "name": "PCU_R1",
            "alignOffset": "0x14",
            "description": "PMR Internal Register R1 Value for {{name}}",
            "fields": [
                {
                    "name": "SFR_PCU_R1",
                    "index": 0,
                    "size": 32,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMR Internal Register R1 Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PCU_R1"
                }
            ]
        },
        {
            "name": "PCU_SP",
            "alignOffset": "0x20",
            "description": "PMR SP Value for {{name}}",
            "fields": [
                {
                    "name": "SFR_PCU_SP",
                    "index": 0,
                    "size": 16,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMR SP Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PCU_SP"
                }
            ]
        },
        {
            "name": "PCU_PC",
            "alignOffset": "0x24",
            "description": "PMR PC Value for {{name}}",
            "fields": [
                {
                    "name": "SFR_PCU_PC",
                    "index": 0,
                    "size": 16,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMR PC Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PCU_PC"
                }
            ]
        },
        {
            "name": "DEBUG_CTRL",
            "alignOffset": "0x30",
            "description": "Debugging Option for {{name}}",
            "fields": [
                {
                    "name": "SFR_DUMP_ENABLE",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Debugging Option for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DUMP_ENABLE",
                    "floating": true
                },
                {
                    "name": "SFR_DUMP_SEL",
                    "index": 1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Debugging Option for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_DUMP_SEL",
                    "floating": true
                }
            ]
        }
    ]
}