/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
//import ErrorType from '@/model/src/ErrorType'
import ItemChecker from '@/model/src/ItemChecker'

export default class ClockPLLCtrlEXTChecker extends ItemChecker {
    constructor(item) {
        super(item)
        //this.addChecker(item.getFidPowerDownMode(), this.checkPowerDownMode, ErrorType.NAME)
        //this.addChecker(item.getFidCustomMode(), this.checkCustomMode, ErrorType.NAME)
        //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
    }
    checkPowerDownMode() {
        // TBD
    }
    checkCustomMode() {
        // TBD
    }
    checkName() {
        // TBD
    }
}