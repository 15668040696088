import * as ITDAControl from "../controls";
import * as ITDANodeRenderer from "./renderers";
import { NodeType } from "../types";
import ITDANodeClock from "./ITDANodeClock";

export default class ITDABUFFER extends ITDANodeClock {
  constructor(name = "") {
    super(name, NodeType.ITDABUFFER);
    this.render = ITDANodeRenderer.ITDABUFFER.render;
  }

  async data(
    inputs: Record<`CLK_INPUT`, any>
  ): Promise<Record<`CLK_OUTPUT`, any>> {
    const freq = this.controls.freq as ITDAControl.ITDAControlFrequency;
    let frequency = this.getFrequencyInitValue();
    if (Object.keys(inputs).length !== 0) {
      const inputFreq = inputs.CLK_INPUT[0];
      frequency = inputFreq;
    }

    if (frequency) {
      freq.setFrequency(frequency);
      this.update();
    }
    return { CLK_OUTPUT: freq.getFrequency() };
  }
}
