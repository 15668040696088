import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodePower from "./ITDANodePower";

export default class ITDAINTREXT extends ITDANodePower {
  constructor(name = "") {
    super(name, NodeType.ITDAINTREXT);
    this.render = ITDANodeRenderer.ITDAINTREXT.render;
  }
}
