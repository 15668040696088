<template>
  <v-container
    :key="store.getters['component/GET_PROP_SCHEMA']"
    class="property">
    <div
      v-if="API.getChildrenSpec(props.item).length && getItemTypeKey === 'SFR'"
      class="property-table-sfr">
      <n-collapse :expanded-names="[0, 1, 2, 3, 4]">
        <div
          v-for="(spec, idx) in API.getChildrenSpec(props.item)"
          :key="`${idx}-${spec.name}`">
          <SFRPropertyTable
            v-if="API.showTable(props.item, spec)"
            class="property-table-boundary"
            :item="props.item"
            :spec="spec"
            :name="Number(idx)" />
        </div>
      </n-collapse>
    </div>
    <div v-if="hasSchema()">
      <v-row
        v-for="(prop, key) in getSchema()"
        :key="key"
        class="property__row text-center"
        :style="style(props.item, key as string)">
        <v-col class="property__col" overflow="hidden">
          <div :class="[firstCheckbox(prop)]"></div>
          <ItemPropertyCheckbox
            v-if="Utils.isBooleanType(prop)"
            v-bind="getProps(key, prop)" />
          <ItemPropertyAutocomplete
            v-else-if="Utils.isEnumType(prop)"
            v-bind="getProps(key, prop)" />
          <ItemPropertyTextarea
            v-else-if="Utils.isTextareaType(prop)"
            v-bind="getProps(key, prop)" />
          <ItemPropertyTextField
            v-else-if="Utils.isStringType(prop)"
            v-bind="getProps(key, prop)" />
          <ItemPropertyIntegerField
            v-else-if="Utils.isIntegerType(prop)"
            v-bind="getProps(key, prop)" />
          <ItemPropertyItemField
            v-else-if="Utils.isItemType(prop)"
            v-bind="getProps(key, prop)" />
          <ItemPropertyColorPicker
            v-else-if="Utils.isColorType(prop)"
            v-bind="getProps(key, prop)" />
          <!-- <div v-else>{{ key }}</div> -->
        </v-col>
      </v-row>
    </div>
    <div v-else-if="!hasSchema() && props.item.isNodeType()">
      <NoData type="properties" />
    </div>
    <div
      v-if="API.getChildrenSpec(props.item).length"
      class="property-table-wrapper">
      <n-collapse :expanded-names="[0, 1, 2, 3, 4]">
        <div
          v-for="(spec, idx) in API.getChildrenSpec(props.item)"
          :key="`${idx}-${spec.name}`"
          :seed="store.getters['component/GET_PROP_CHILDREN']">
          <ItemPropertyTable
            v-if="API.showTable(props.item, spec)"
            class="property-table-boundary"
            :item="props.item"
            :spec="spec"
            :name="Number(idx)" />
        </div>
      </n-collapse>
    </div>
  </v-container>
</template>
<script setup lang="ts">
import { ref, defineProps } from "vue";
import { useStore } from "vuex";
import Utils from "@/components/item/utils";
import ItemPropertyCheckbox from "./ItemPropertyCheckbox.vue";
import ItemPropertyAutocomplete from "./ItemPropertyAutocomplete.vue";
import ItemPropertyTextarea from "./ItemPropertyTextarea.vue";
import ItemPropertyTextField from "./ItemPropertyTextField.vue";
import ItemPropertyColorPicker from "./ItemPropertyColorPicker.vue";
import ItemPropertyTable from "./ItemPropertyTable.vue";
import ItemPropertyItemField from "./ItemPropertyItemField.vue";
import ItemPropertyIntegerField from "./ItemPropertyIntegerField.vue";
import SFRPropertyTable from "./SFRPropertyTable.vue";
import API from "@/api/internal";
import { Item, ItemPropsType } from "ITDAModelTypes";
import NoData from "../NoData.vue";
const props = defineProps<{
  item: Item;
  isExt?: boolean;
}>();
const getProps = (key, prop): ItemPropsType => {
  const res = {
    item: props.item,
    propKey: key.toString(),
    prop: prop,
    errorMessages: API.getErrorMessages(props.item, key),
  };
  if (prop.children) {
    Object.assign(res, { children: prop.children });
  }
  return res;
};
const store = useStore();
const getItemTypeKey = props.item.getItemType().getKey();
const style = (item: Item, propKey: string) => {
  return {
    display: Utils.showProps(item, propKey) ? "block" : "none",
  };
};

const getSchema = () => {
  const res = API.getSchema(props.item);
  const firstProp = Object.values(res).find((prop) => prop.type === "boolean");
  if (firstProp) {
    firstProp.__isFirst = true;
  }
  return res;
};
const hasSchema = () => {
  return Object.values(getSchema()).length > 0;
};

const firstCheckbox = (prop) => {
  return !props.isExt && prop.__isFirst ? "checkbox-border" : "";
};
</script>
<style lang="sass" scoped>
.property
  gap: 16px
  display: flex
  flex-direction: column
.property > div
  display: flex
  flex-direction: column
  gap: 16px
.property__row
  margin: 0
  color: black
.property__col
  padding: 0
.expansion-panels
  height: inherit
.checkbox-border
  height: 1rem

.property-table-boundary
  border: 1px solid rgb(155, 155, 155)
  border-radius: 4px
  //box-shadow: 0px 3px 1px -2px var(--v-shadow-key-umbra-opacity, rgba(0, 0, 0, 0.2)),
    //0px 2px 2px 0px var(--v-shadow-key-penumbra-opacity, rgba(0, 0, 0, 0.14)),
    //0px 1px 5px 0px var(--v-hadow-key-ambient-opacity, rgba(0, 0, 0, 0.12))
  padding: 0.5rem 1rem 0.5rem 1rem !important

.property-table-sfr
  min-height: 200px

.property-table-wrapper .n-collapse
  display: flex
  flex-direction: column
  gap: 0.5rem
</style>
