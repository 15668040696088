/**
 *  Generated by Modeler
 *  timestamp: 2024-08-26 09:44:21.387471
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemHandler from "../ItemHandler";

export default class PhantomEvent extends ItemEvent {
  constructor(item) {
    super(item);
    // this.addPropertyListener(item.getFidOriginItem(), this.lstn_originItem)
  }
  // lstn_originItem(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }

  postProcess(item) {
    const origin = item.getOriginItem();
    if (origin) {
      const nodeMetaView = item.getNodeMetaView();
      item.setNodeType(origin.getNodeType());
      nodeMetaView.setX(origin.getNodeMetaView().getX());
      nodeMetaView.setY(origin.getNodeMetaView().getY());
      return origin.getEvent().setupPorts
        ? origin.getEvent().setupPorts(item)
        : [];
    }
  }
}
