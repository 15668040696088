import { h, render } from "vue";
import {
  AreaPlugin,
  AreaExtensions,
  Zoom,
  NodeView,
  Area,
} from "rete-area-plugin";
import { ITDACommon, AreaExtra, ITDACanvasType } from "./types";
import ITDACanvas from "../ITDACanvas";
import { Position } from "rete-area-plugin/_types/types";
import ITDABackground from "../common/templates/ITDABackground.vue";
import { WheelDrag } from "./utils";
import { ITDANode } from "../nodes";
import { ConnectionView } from "rete-area-plugin/_types/connection-view";
import { ITDAGrid } from "../common";
import ITDAEditor from "../ITDAEditor";
import { DiagramType } from "../types";

export default class ITDAAreaPlugin extends ITDACanvas {
  private zoomHandler: Zoom | null = null;
  private dragHandler: WheelDrag | null = null;
  public zoomLevel = 1;
  isDragging = false;
  selected: ITDANode[] = [];
  constructor(id: string, container: HTMLElement, diagramType: DiagramType) {
    super(id, ITDACanvasType.Area);
    this.res = new AreaPlugin<ITDACommon.Schemes, AreaExtra>(container);
    this.res.area.setZoomHandler(this.zoomHandler);
    this.res.area.setDragHandler(null);

    this.dragHandler = new WheelDrag(0.3);
    this.dragHandler.initialize(
      container,
      {
        getCurrentPosition: () => this.getArea().transform,
        getZoom: () => 1,
      },
      {
        start: () => null,
        translate: this.onTranslate.bind(this),
        drag: () => null,
      }
    );

    window.addEventListener("keydown", this.enableZoomHandler.bind(this));
    window.addEventListener("keyup", this.disableZoomHandler.bind(this));

    AreaExtensions.selectableNodes(this.res, AreaExtensions.selector(), {
      // accumulating: AreaExtensions.accumulateOnCtrl(),
      accumulating: this.getCustomAccumulating(
        this.repo.getEditor(this.id),
        diagramType
      ),
    });
    AreaExtensions.restrictor(this.res, {
      scaling: { min: 0.25, max: 30 },
    });
  }

  private getCustomAccumulating(editor: ITDAEditor, diagramType: DiagramType) {
    let pressed = false;

    function keydown(e: KeyboardEvent) {
      if (e.key === "Control") pressed = true;
    }
    function keyup(e: KeyboardEvent) {
      if (e.key === "Control") pressed = false;
    }

    document.addEventListener("keydown", keydown);
    document.addEventListener("keyup", keyup);

    return {
      active() {
        let valid = true;
        if (diagramType === DiagramType.SEQ) {
          valid = editor.getNodes().find((node: ITDANode) => node.selected)
            ? false
            : true;
        }
        return pressed && valid;
      },
      destroy() {
        document.removeEventListener("keydown", keydown);
        document.removeEventListener("keyup", keyup);
      },
    };
  }

  private onTranslate(x: number, y: number) {
    if (this.zoomHandler && this.zoomHandler.isTranslating()) return; // lock translation while zoom on multitouch
    this.getArea().translate(x, y);
  }

  use() {
    this.getInstance().use(
      this.repo.getConnectionPlugin(this.id).getInstance()
    );
    this.getInstance().use(this.repo.getRenderPlugin(this.id).getInstance());
    this.getInstance().use(this.repo.getDockPlugin(this.id).getInstance());
    this.getInstance().use(this.repo.getHistoryPlugin(this.id).getInstance());
    this.getInstance().use(this.repo.getCommentPlugin(this.id).getInstance());
    this.getInstance().use(this.repo.getScopesPlugin(this.id).getInstance());
  }

  event() {
    this.getInstance().addPipe(async (context) => {
      const editor = this.repo
        .getEditor(this.repo.getCurrentID())
        .getInstance();
      if (context.type === "nodepicked") {
        await this.emit({
          type: context.type,
          options: {
            node: editor.getNode(context.data.id),
          },
        });
      } else if (context.type === "noderesized") {
        const node = editor.getNode(context.data.id);
        if (node) {
          node.width = context.data.size.width;
          node.height = context.data.size.height;
          node.update();
        }
      } else if (context.type === "pointerup") {
        const selectedItem = editor.getNodes().find((n) => n.selected);
        if (!selectedItem) {
          await this.emit({
            type: "nodepicked",
            options: {},
          });
        }
      } else if (context.type === "nodetranslated") {
        if (this.repo.getImported()) {
          const node = editor.getNode(context.data.id);
          if (!node.isLocked()) {
            node.acquireLock();
            await this.emit({
              type: context.type,
              options: context.data,
            });
            node.releaseLock();
          }
        }
      } else if (context.type === "nodedragged") {
        await this.emit({
          type: "nodedragged",
          options: {
            node: editor.getNode(context.data.id),
          },
        });
      } else if (context.type === "noderesize") {
        if (this.repo.getImported()) {
          await this.emit({
            type: context.type,
            options: context.data,
          });
        }
      } else if (context.type === "zoomed") {
        this.zoomLevel = context.data.zoom;
        this.emit({
          type: context.type,
          options: {
            node: context.data,
          },
        });
      }
      return context;
    });
  }

  adjustNodePosToGrid() {
    const allNode = document.querySelectorAll(".node-wrapper");

    allNode.forEach((node) => {
      if (node.firstChild && node.firstElementChild instanceof HTMLElement) {
        const firstChildClassName = (node.firstElementChild as HTMLElement)
          .className;

        const vueComponent = (node as any).__vueParentComponent;
        if (vueComponent) {
          const typeTitle = vueComponent.props.data.type.title;

          const nodeClass = Object.getPrototypeOf(vueComponent.props.data)
            .constructor.name;
          const inheritedClassName = vueComponent.props.data.inheritedClassName;

          if (node instanceof HTMLElement) {
            if (!firstChildClassName.includes("docked")) {
              node.style.position = "relative";

              if (inheritedClassName === "ITDANodeClock") {
                node.style.top = "-2.5px";
                node.style.left = "0.5px";
              } else if (inheritedClassName === "ITDANodePower") {
                node.style.top = "-4px";
                node.style.left = "-0.5px";
              } else if (inheritedClassName === "ITDANodeSequence") {
                if (nodeClass === "ITDASEQLABEL") {
                  node.style.top = "-3px";
                  node.style.left = "0.5px";
                } else if (nodeClass === "ITDASEQLABELREF") {
                  node.style.top = "-3px";
                  node.style.left = "0.5px";
                } else if (nodeClass === "ITDASEQIF") {
                  node.style.top = "-4px";
                  node.style.left = "0px";
                } else if (nodeClass === "ITDASEQBACK") {
                  node.style.top = "-1.5px";
                  node.style.left = "-0.5px";
                } else {
                  node.style.top = "-4px";
                  node.style.left = "-0.5px";
                }
              } else if (nodeClass === "ITDAINSTANCE") {
                if (typeTitle === "IP") {
                  node.style.top = "-2.5px";
                  node.style.left = "0.5px";
                } else {
                  node.style.top = "-4px";
                  node.style.left = "-0.5px";
                }
              } else if (nodeClass === "ITDAMODULE") {
                node.style.top = "-4px";
                node.style.left = "-0.5px";
              } else if (nodeClass === "ITDAUPFSOURCE") {
                node.style.top = "-4px";
                node.style.left = "-0.5px";
              } else if (nodeClass === "ITDAUPFLEVELSHIFTER") {
                node.style.top = "-5.5px";
                node.style.left = "-0.5px";
              } else if (typeTitle === "QCHINFO") {
                node.style.top = "-2.5px";
                node.style.left = "0px";
              }
            }
          }
        }
      }
    });
  }

  alignNodeToGrid(contextId: string) {
    const gridSpace = this.repo
      .getEditor(this.repo.getCurrentID())
      .getGridSpace();
    const nodeView = this.getInstance()?.nodeViews.get(contextId);
    if (!nodeView) return;

    const { x, y } = nodeView.position;
    nodeView.translate(
      Math.round(x / gridSpace) * gridSpace,
      Math.round(y / gridSpace) * gridSpace
    );
  }

  canAlignConnections(nodeId: string): boolean {
    const editor = this.repo.getEditor(this.repo.getCurrentID()).getInstance();
    const connections = editor.getConnections();

    const isConnected = connections.some((connection) => {
      return connection.source === nodeId || connection.target === nodeId;
    });

    return isConnected;
  }

  getInstance(): AreaPlugin<ITDACommon.Schemes, AreaExtra> {
    return this.res as AreaPlugin<ITDACommon.Schemes, AreaExtra>;
  }

  getNodeView(id: string): NodeView | undefined {
    return this.getInstance()?.nodeViews.get(id);
  }

  getConnectionView(id: string): ConnectionView | undefined {
    return this.getInstance()?.connectionViews.get(id);
  }

  getArea(): Area {
    return this.getInstance().area;
  }

  resize(
    id: string,
    width: number,
    height: number
  ): Promise<boolean | undefined> {
    return this.getInstance().resize(id, width, height);
  }

  translate(id: string, { x, y }: Position): Promise<boolean | undefined> {
    return this.getInstance().translate(id, { x, y });
  }

  update(
    type: "node" | "connection" | "socket" | "control",
    id: string
  ): Promise<void> {
    return this.getInstance().update(type, id);
  }

  private enableZoomHandler(e: KeyboardEvent) {
    if (e.code === "ControlLeft") {
      e.preventDefault();
      if (!this.zoomHandler) {
        this.zoomHandler = new Zoom(0.1);
        this.getInstance().area.setZoomHandler(this.zoomHandler);
      }
    }
  }

  private disableZoomHandler(e: KeyboardEvent) {
    if (this.zoomHandler) {
      this.zoomHandler = null;
      this.getInstance().area.setZoomHandler(this.zoomHandler);
    }
  }

  isPressedControlLeft() {
    return this.zoomHandler ? true : false;
  }

  simpleNodesOrder() {
    AreaExtensions.simpleNodesOrder(this.getInstance());
  }
  // async translate(nodes: any[]) {
  //   const idx = 0;
  //   for (const n of nodes) {
  //     await this.getInstance().translate(n.id, { x: idx * 200, y: idx * 100 });
  //     idx += 1;
  //   }
  // }
  addCustomBackground() {
    this.renderBackgroundComponent();
  }

  updateGrid(checked: boolean) {
    this.showGrid = checked;
    this.renderBackgroundComponent();
  }

  private renderBackgroundComponent() {
    const gridRenderer = new ITDAGrid();
    const vnode = h(ITDABackground, {
      width: this.width,
      height: this.height,
      gridSpace: this.gridSpace,
      gridStroke: this.gridStroke,
      showGrid: this.showGrid,
      renderGrid: gridRenderer.render,
      key: Math.random(),
    });
    render(vnode, this.getInstance().area.content.holder);
  }

  zoomAt(nodes: any[]) {
    AreaExtensions.zoomAt(this.getInstance(), nodes);
  }

  getZoom() {
    return this.zoomLevel;
  }
}
