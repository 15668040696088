import EventHandler from "./EventHandler";
import EventType from "./EventType";

export default class EventInterface {
  constructor(item) {
    this.itemId = item.getId();
  }

  addPropertyListener(fid, listener) {
    EventHandler.addPropertyListener(this.itemId, fid, listener);
  }

  addArrayListener(fid, listener) {
    EventHandler.addArrayListener(this.itemId, fid, listener);
  }

  propertyChanged(fid, newVal, oldVal) {
    if (EventHandler.isListening(EventType.PROP, this.itemId, fid)) {
      return EventHandler.propertyChanged(this.itemId, fid, newVal, oldVal);
    }
  }

  arrayChangedAdd(fid, value) {
    if (EventHandler.isListening(EventType.ADD, this.itemId, fid)) {
      return EventHandler.arrayChangedAdd(this.itemId, fid, value);
    }
  }

  arrayChangedRemove(fid, value) {
    if (EventHandler.isListening(EventType.REM, this.itemId, fid)) {
      return EventHandler.arrayChangedRemove(this.itemId, fid, value);
    }
  }

  arrayChangedClear(fid, value) {
    if (EventHandler.isListening(EventType.CLR, this.itemId, fid)) {
      return EventHandler.arrayChangedClear(this.itemId, fid, value);
    }
  }
}
