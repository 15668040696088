import {
  Item,
  RefItemElement,
  RefItemElementTarget,
  RefItemElementType,
  VariantLevel,
} from "ITDAModelTypes";

export const getSequenceDiagramElements = (
  sequenceDiagram: Item,
  propKey: string
): RefItemElement[] => {
  if (!sequenceDiagram.getItemRef()) {
    return [];
  }

  const childrenData: RefItemElementTarget[] = [];

  sequenceDiagram
    .getParent()
    .getPowerSequenceDiagrams()
    .forEach((psd: Item) => {
      if (
        psd.getId() !== sequenceDiagram.getId() &&
        psd.getItemRef()?.getModule() ===
          sequenceDiagram.getItemRef().getModule()
      ) {
        childrenData.push({
          key: psd.getTitle(),
          item: psd,
          closable: true,
        });
      }
    });
  childrenData.sort((a, b) => {
    return a.key.localeCompare(b.key, "ko");
  });

  const model = sequenceDiagram.getCopied()
    ? sequenceDiagram.getCopied()
    : undefined;

  return [
    {
      variant: VariantLevel.LEVEL1,
      disabled: false,
      type: RefItemElementType.ENUM,
      propKey,
      label: "Sequence Diagram",
      model,
      targets: childrenData,
      event: eventLabels,
    },
  ];
};

const eventLabels = (
  item: Item,
  elem: RefItemElement,
  value: string | string[] | number | number[]
) => {
  const target = elem.targets?.find((target) => target.key === value);
  if (target) {
    item.setCopied(target.item.getId());
  } else {
    item.setCopied(null);
  }
};
