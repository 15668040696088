'use strict';

export default class LabelType {

    static INTERNAL      = new LabelType(0, "internal")
    static EXTERNAL      = new LabelType(1, "external")
    
    constructor(index, key) {
        this.index = index
        this.key = key
    }

    static getKeys() {
        return Object.keys(this)
    }

    static getValues() {
        return Object.values(this)
    }

    static getEntries() {
        return Object.entries(this)
    }
    
    static getObjByKey(key) {
        return this[key]
    }

    getKey() {
        return this.key
    }
}
