import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodePower from "./ITDANodePower";

export default class ITDAPSW extends ITDANodePower {
  constructor(name = "") {
    super(name, NodeType.ITDAPSW);
    this.render = ITDANodeRenderer.ITDAPSW.render;
  }

  build() {
    const res = super.build();

    const ctrl = this.getCtrlSignal();
    ctrl.newSignal("PGEN", "out");
    ctrl.newSignal("PGEN_FEEDBACK", "in");

    return res;
  }
}
