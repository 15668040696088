import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodeSequence from "./ITDANodeSequence";

export default class ITDASEQCALL extends ITDANodeSequence {
  constructor(name = "") {
    super(name, NodeType.ITDASEQCALL);
    this.render = ITDANodeRenderer.ITDANodeSequence.render;
  }
}
