const findChildrenByClass = (parentNode: Element, className: string) => {
  // 자식 노드 배열 생성
  const children: Element[] = [];

  // 자식 노드 반복
  if (parentNode) {
    for (const child of parentNode.children) {
      // 클래스 이름이 일치하는 경우 자식 노드 배열에 추가
      if (child.classList.contains(className)) {
        children.push(child);
      }

      // 재귀 호출을 통하여 자식 노드의 자식들도 검색
      children.push(...findChildrenByClass(child, className));
    }
  }
  return children;
};
export default {
  findChildrenByClass: findChildrenByClass,
};
