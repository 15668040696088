import { ClassicFlow, ClassicParams, SocketData } from "rete-connection-plugin";
import { ITDACommon } from "../plugins/types";

export default class ITDAConnectionFlow<
  S extends ITDACommon.Schemes,
  K extends any[]
> extends ClassicFlow<S, K> {
  constructor(data: SocketData, params?: Partial<ClassicParams<S>>) {
    super(params);
    console.debug(data);
  }
}
