import { Item, Property, ItemType, RefItemElement } from "ITDAModelTypes";
import RefItemUtils from "./refItem";
import API from "@/api/internal";

export default {
  getRefItemTitle: (
    item: Item,
    propKey: string,
    prop: Property,
    config?: boolean
  ): string => {
    if (config) {
      if (
        item.getItemType() === ItemType.SDCInstance ||
        API.isConfigurationTarget(item)
      )
        return "";
    } else {
      switch (item.getItemType()) {
        case ItemType.UPFMapperPowerSource:
        case ItemType.ConfigurationElement:
          return "";
        case ItemType.Label:
          if (propKey === "labelRefs" && !item.getLabelRefs().length) {
            return "";
          }
          break;
        case ItemType.LabelRef:
          if (item.getType() === "external") {
            return "";
          } else {
            if (propKey === "itemLabel" && !item.getItemLabel()) {
              return "";
            }
          }
          break;
        default:
          break;
      }
    }
    return prop.label ? prop.label : "";
  },
  getRefItemElements: (
    item: Item,
    propKey: string,
    prop: Property,
    config?: boolean
  ): RefItemElement[] => {
    if (config && API.isConfigurationTarget(item)) {
      if (item.getItemType() === ItemType.SDCInstance) {
        return RefItemUtils.getSDCFrequencyConfiguration(item, propKey, prop);
      } else if (
        item.getItemType() === ItemType.LabelRef &&
        propKey === "itemRef"
      ) {
        return RefItemUtils.getExternalLabels(item, propKey, prop);
      }
      if (item.getItemType() === ItemType.PowerPMDInstance) {
        return RefItemUtils.getPowerStatusSettingsConfiguration(item, propKey);
      }
      return RefItemUtils.getConfigurationElement(item, propKey, prop);
    }
    switch (item.getItemType()) {
      case ItemType.Project:
        if (propKey === "migration") {
          return RefItemUtils.getMigration(item);
        } else {
          return [];
        }
      case ItemType.ClockCMU:
        if (propKey === "refCLK") {
          return RefItemUtils.getRefCLKElements(item, propKey, prop);
        } else if (propKey === "PCLK") {
          return RefItemUtils.getPCLKElements(item, propKey, prop);
        } else {
          return [];
        }
      case ItemType.Label:
        if (propKey === "labelRefs") {
          return RefItemUtils.getLabelRefsElements(item, propKey, prop);
        } else {
          return [];
        }
      case ItemType.LabelRef:
        if (propKey === "itemLabel") {
          return RefItemUtils.getItemLabelElements(item, propKey, prop);
        } else if (propKey === "labels") {
          if (item.getType() === "internal") {
            return RefItemUtils.getLabelsElements(item, propKey, prop);
          } else {
            return [];
          }
        } else {
          return [];
        }
      case ItemType.ConfigurationFolder:
      case ItemType.VoltageLevelFolder:
        if (propKey === "selectedConfiguration") {
          return RefItemUtils.getSelectedConfiguration(item);
        } else {
          return [];
        }
      case ItemType.ConfigurationElement:
        if (propKey === "itemRef") {
          return RefItemUtils.getItemRefName(item);
        } else {
          return [];
        }
      case ItemType.SDCInstance:
        if (propKey === "module") {
          return RefItemUtils.getModule(item, propKey, prop);
        } else if (propKey === "primaryPowerSource") {
          return RefItemUtils.getPowerSource(item);
        } else {
          return [];
        }
      case ItemType.SDCPhantom:
        if (propKey === "clockGroup") {
          return RefItemUtils.getSDCPhantomElements(item, propKey);
        } else {
          return [];
        }
      case ItemType.DUTInstance:
        if (propKey === "upper") {
          return RefItemUtils.getBlock(item, propKey, prop);
        } else if (propKey === "module") {
          return RefItemUtils.getModule(item, propKey, prop);
        } else {
          return [];
        }
      case ItemType.PowerPMDInstance:
      case ItemType.UPFPowerDomainInstance:
        return propKey === "module"
          ? RefItemUtils.getModule(item, propKey, prop)
          : [];
      case ItemType.UPFPowerDomain:
      case ItemType.UPFHardMacro:
        return propKey === "primaryPowerSource"
          ? RefItemUtils.getUPFPrimaryPowerSource(item, propKey, prop)
          : [];
      // case ItemType.UPFMapperPowerSource:
      //   if (propKey === "powerSource") {
      //     return RefItemUtils.getUPFPowerSource(item, propKey, prop);
      //   } else if (propKey === "mappedPower") {
      //     return RefItemUtils.getMappedPower(item, propKey);
      //   } else {
      //     return [];
      //   }
      case ItemType.UPFLevelShifter:
        return propKey === "location"
          ? RefItemUtils.getUPFLocation(item, propKey, prop)
          : [];
      case ItemType.PowerSequenceWrite:
      case ItemType.PowerSequenceReadWait:
      case ItemType.PowerSequenceWait:
      case ItemType.PowerSequenceIf:
        if (propKey === "PMD") {
          return RefItemUtils.getSequencePMDElements(item, propKey, prop) || [];
        } else if (propKey === "PWRCOMP") {
          return (
            RefItemUtils.getSequencePWRCOMPElements(item, propKey, prop) || []
          );
        } else if (propKey === "SFRField") {
          return (
            RefItemUtils.getSequenceSFRFieldElements(item, propKey, prop) || []
          );
        } else {
          return [];
        }
      // case IT.SequenceWait:
      //   console.log("wait");
      //   return RefItemUtils.getSequenceElements(item, propKey, prop) || [];
      case ItemType.ClockIPInput:
        if (propKey === "shared") {
          return RefItemUtils.getGateElements(item, propKey);
        } else {
          return [];
        }
      case ItemType.PowerSequenceDiagram:
        return RefItemUtils.getSequenceDiagramElements(item, propKey);
      case ItemType.PowerSequenceLabel:
        if (propKey === "labelRefs") {
          return RefItemUtils.getSequenceLabelRefsElements(item, propKey);
        } else {
          return [];
        }
      case ItemType.PowerSequenceLabelRef:
        if (propKey === "itemLabel") {
          return RefItemUtils.getSequenceItemLabelElements(item, propKey);
        } else if (propKey === "labels") {
          return RefItemUtils.getSequenceLabelsElements(item, propKey);
        } else {
          return [];
        }
      default:
        return [];
    }
  },
};
