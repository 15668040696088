import ItemHandler from "./ItemHandler";
import ItemType from "./ItemType";
import store from "@/store/index";
import LabelType from "./LabelType";
import NodeType from "./NodeType";
import Repository from "../store";
import API from "@/api/internal";

export default class ConfigurationHandler {
  static getCurrentConfigurationDB() {
    return store.getters["io/GET_CURRENT_CONFIG_DB"];
  }

  static getConfigurationTitle(type) {
    switch (type) {
      case ItemType.ClockCMU:
      case ItemType.ClockDiagram:
      case ItemType.ClockIPDiagram:
      case ItemType.SDCDiagram:
        return "Clock Frequency Setting";
      case ItemType.PowerPMD:
      case ItemType.PowerPMC:
      case ItemType.PowerDiagram:
        return "Power Status Setting";
      case ItemType.VoltageDomain:
        return "Voltage Level";
      case ItemType.UPFModule:
        return "PST";
      default:
        return;
    }
  }

  static getPowerDomainsByVoltage(vd) {
    return ItemHandler.getPowerDomainItems().filter((pd) => {
      let pps = pd.getPrimaryPowerSource();
      if (pps) {
        return this.getVoltageDomain(pps) === vd;
      }
    });
  }

  static getVoltageDomain(ps) {
    if (!ps) {
      return;
    }
    const res = ps.getUPFMapperPowerSources()[0].getPowerSource();
    return res && res.getItemType() === ItemType.VoltageDomain
      ? res
      : this.getVoltageDomain(res);
  }

  static clearCMUConfigurations() {
    ItemHandler.getTopItem()
      .getCMUFolder()
      .getCMUs()
      .forEach((cmu) =>
        cmu.getConfigurationFolder().setSelectedConfiguration()
      );
  }

  static clearVoltageDomainConfigurations() {
    ItemHandler.getTopItem()
      .getVoltage()
      .getVoltageDomains()
      .forEach((vd) => vd.getConfigurationFolder().setSelectedConfiguration());
  }

  static getConfigurationElementValue(id) {
    const item = ItemHandler.getRefItem(id);
    const refItem = item.getItemRef();
    if (refItem) {
      if (refItem.isNodeType()) {
        return item.getValue();
      } else {
        return ItemHandler.getRefItem(item.getValue());
      }
    }
  }

  // static isConfigurationSelected(item, checkSDCDiagram = false) {
  //   if (this.isConfigurationTarget(item, checkSDCDiagram)) {
  //     const module = this.getTargetModule(item);
  //     if (module) {
  //       return module.getConfigurationFolder().getSelectedConfiguration()
  //         ? true
  //         : false;
  //     }
  //   }
  // }

  // static isConfigurationTarget(item, checkSDCDiagram = false) {
  //   var res = false;
  //   if (!item) {
  //     return res;
  //   }
  //   const type = item.getItemType();
  //   if (type.getCategory() === NodeType.Clock) {
  //     res |=
  //       type === ItemType.ClockPLLCtrl ||
  //       type === ItemType.ClockRefCLKMultiplexer ||
  //       type === ItemType.ClockMultiplexer ||
  //       type === ItemType.ClockDivider ||
  //       type === ItemType.ClockNMDivider ||
  //       type === ItemType.ClockGate ||
  //       type === ItemType.ClockBuffer ||
  //       type === ItemType.ClockFreqmon;
  //     // if (CanvasUtils.getCurrentCanvas() && checkSDCDiagram) {
  //     //     res &= (CanvasUtils.getCurrentCanvas().getDiagram().getItemType() !== ItemType.SDCDiagram)
  //     // }
  //   } else if (type.getCategory() === NodeType.Instance) {
  //     res |=
  //       type === ItemType.ClockExternalCLKSource ||
  //       (type === ItemType.LabelRef &&
  //         item.getType() === LabelType.EXTERNAL.getKey());
  //     // if (CanvasUtils.getCurrentCanvas() && checkSDCDiagram) {
  //     //     res &= (CanvasUtils.getCurrentCanvas().getDiagram().getItemType() !== ItemType.SDCDiagram)
  //     // }
  //   } else if (type.getCategory() === NodeType.Power) {
  //     res |=
  //       type === ItemType.PowerPSW ||
  //       type === ItemType.PowerHANDSHAKE ||
  //       type === ItemType.PowerOTP ||
  //       type === ItemType.PowerPWREN ||
  //       type === ItemType.PowerISOEN ||
  //       type === ItemType.PowerRESET ||
  //       type === ItemType.PowerMEM ||
  //       type === ItemType.PowerRETENTION ||
  //       type === ItemType.PowerREFCLKEN ||
  //       type === ItemType.PowerUSERDEFOUT;
  //   } else if (type.getCategory() === NodeType.ModuleInstance) {
  //     res |=
  //       type === ItemType.PowerPMDInstance || type === ItemType.SDCInstance;
  //   }
  //   return res;
  // }
  static getSchema(item) {
    switch (item.getItemType()) {
      case ItemType.ClockExternalCLKSource:
        return [{ prop: "frequency", label: "Frequency", type: "integer" }];
      case ItemType.ClockPLLCtrl:
        var res = [{ prop: "selection", label: "SELECT", type: "integer" }];
        var pllSpec = Repository.getInstance()
          .getExternalPLLSpec()
          .find((spec) => spec.name === item.getPlltype());
        if (pllSpec) {
          for (const sfr of pllSpec.sfrs) {
            for (const field of sfr.fields) {
              if (field.config) {
                res.push({
                  prop: field.name.split("SFR_")[1],
                  label: field.name.split("SFR_")[1],
                  type: "string",
                });
              }
            }
          }
        }
        return res;
      case ItemType.ClockSIGNAL:
        return [
          {
            prop: "SignalName",
            label: "SHORTSTOP SIGNAL LIST",
            type: "string",
          },
        ];
      case ItemType.ClockRefCLKMultiplexer:
      case ItemType.ClockMultiplexer:
        return [{ prop: "selection", label: "SELECT", type: "integer" }];
      case ItemType.ClockDivider:
        return [{ prop: "divRatio", label: "DIVRATIO", type: "integer" }];
      case ItemType.ClockNMDivider:
        return [
          { prop: "numerator", label: "NUMERATOR", type: "integer" },
          {
            prop: "denominator",
            label: "SELECT_DENOM",
            type: "integer",
          },
        ];
      case ItemType.LabelRef:
        if (item.getType() === LabelType.EXTERNAL.getKey()) {
          return [
            { prop: "itemRef", label: "Source External Labels", type: "item" },
          ];
        }
        return [];
      case ItemType.PowerPSW:
        return [{ prop: "PGEN", label: "PGEN", type: "integer" }];
      case ItemType.PowerHANDSHAKE:
        return [{ prop: "REQ", label: "REQ", type: "integer" }];
      case ItemType.PowerOTP:
        return [{ prop: "OTP_REQ", label: "OTP_REQ", type: "integer" }];
      case ItemType.PowerPWREN:
        return [{ prop: "PWREN", label: "PWREN", type: "integer" }];
      case ItemType.PowerISOEN:
        return [{ prop: "ISOEN", label: "ISOEN", type: "integer" }];
      case ItemType.PowerRESET:
        return [{ prop: "RESETn", label: "RESETn", type: "integer" }];
      case ItemType.PowerMEM:
        return [
          { prop: "MEM_RET", label: "MEM_RET", type: "integer" },
          { prop: "MEM_PGEN", label: "MEM_PGEN", type: "integer" },
        ];
      case ItemType.PowerRETENTION:
        return [{ prop: "RETENTION", label: "RETENTION", type: "integer" }];
      case ItemType.PowerREFCLKEN:
        return [{ prop: "REFCLKEN", label: "REFCLKEN", type: "integer" }];
      case ItemType.PowerUSERDEFOUT:
        return [
          {
            prop: "USERDEFOUT",
            label: "USERDEFOUT",
            type: "integer",
          },
        ];
      case ItemType.PowerPMDInstance:
        return [{ prop: "PSS", label: "POWER STATUS", type: "item" }];
      case ItemType.ClockCMU:
        [{ prop: "name", label: "NAME", type: "string" }];
      case ItemType.Configuration:
        [{ prop: "voltage", label: "VOLTAGE (V)", type: "integer" }];
      case ItemType.SDCInstance:
        return [
          { prop: "FS", label: "Select Clock Frequency Setting", type: "item" },
        ];
      default:
        return [];
    }
  }

  static refreshConfigurationFolder(item) {
    if (item) {
      item
        .getConfigurations()
        .reduce((acc, cur) => {
          acc = acc.concat(cur.getConfigurationElements());
          return acc;
        }, [])
        .forEach((configElem) => {
          let refItem = configElem.getItemRef();
          if (refItem) {
            if (
              refItem.getItemType().getCategory() === NodeType.ModuleInstance
            ) {
              if (!refItem.getModule()) {
                configElem.setValue(null);
                ItemHandler.removeItem(configElem);
                console.log("remove config elem (module is null):", configElem);
              } else {
                if (refItem.getItemType() === ItemType.SDCInstance) {
                  if (!refItem.getPrimaryPowerSource()) {
                    configElem.setValue(null);
                    ItemHandler.removeItem(configElem);
                    console.log(
                      "remove config elem (primary power is null):",
                      configElem
                    );
                  }
                }
              }
            }
          } else {
            configElem.setValue(null);
            ItemHandler.removeItem(configElem);
            console.log("remove config elem (itemRef is null):", configElem);
          }
        });
    }
  }

  static getTargets(item, prop) {
    var res = [];
    switch (prop) {
      case "itemRef":
        if (item.getItemType() === ItemType.LabelRef) {
          res = this._getLabelRefTargets(item, prop);
        }
        break;
      case "PSS":
        if (this.getTargetModule(item)) {
          res = this._getPMSPMCTargets(item, prop);
        }
        break;
      case "FS":
        if (this.getTargetModule(item)) {
          res = this._getSDCTargets(item, prop);
        }
        break;
      default:
        break;
    }
    return res;
  }

  static _getLabelRefTargets(item, prop) {
    if (prop === "itemRef") {
      if (item.getType() === LabelType.EXTERNAL.getKey()) {
        const curCMU = item.getParent(ItemType.ClockCMU);
        return ItemHandler.getTopItem()
          .getCMUFolder()
          .getCMUs()
          .reduce((acc, cur) => {
            if (cur !== curCMU) {
              acc = acc.concat(cur.getClockDiagramFolder().getClockDiagrams());
            }
            return acc;
          }, [])
          .reduce((acc, cur) => {
            acc = acc.concat(
              cur
                .getLabels()
                .filter(
                  (label) => label.getType() === LabelType.EXTERNAL.getKey()
                )
            );
            return acc;
          }, []);
      }
    }
    return [];
  }

  static _getPMSPMCTargets(item, prop) {
    if (prop === "PSS") {
      const module = item.getModule();
      return module ? module.getConfigurationFolder().getConfigurations() : [];
    }
    return [];
  }

  static _getSDCTargets(item, prop) {
    if (prop === "FS") {
      const module = item.getModule();
      return module ? module.getConfigurationFolder().getConfigurations() : [];
    }
    return [];
  }

  static getValue(item, prop) {
    const module = this.getTargetModule(item);
    if (module) {
      const config = module.getConfigurationFolder()
        ? module.getConfigurationFolder().getSelectedConfiguration()
        : null;
      if (module.getItemType() === ItemType.ClockCMU) {
        return Number(this._getCMSValue(item, prop, config));
      } else if (module.getItemType() === ItemType.PowerPMC) {
        return this._getPMSPMCValue(item, prop, config);
      } else if (module.getItemType() === ItemType.VoltageDomain) {
        return this._getSDCConfigValue(item, prop, config);
      } else {
        return Number(this._getPMSValue(item, prop, config));
      }
    }
  }

  static _getCMSValue(item, prop, config) {
    if (config) {
      const configElem = item.getConfigurationElement(item, prop);
      if (item.getItemType() === ItemType.ClockNMDivider) {
        return configElem ? configElem.getValue() : -1;
      } else {
        return configElem ? configElem.getValue() : "";
      }
    }
  }

  static _getPMSPMCValue(item, prop, config) {
    if (config) {
      const configElem = config
        .getConfigurationElements()
        .find(
          (configElem) =>
            configElem.getItemRef() === item.getId() &&
            configElem.getProp() === prop
        );
      return configElem ? configElem.getValue() : null;
    }
  }

  static _getSDCConfigValue(item, prop, config) {
    if (config) {
      const configElem = config
        .getConfigurationElements()
        .find(
          (configElem) =>
            configElem.getItemRef() === item.getId() &&
            configElem.getProp() === prop
        );
      return configElem ? configElem.getValue() : null;
    }
  }

  static _getPMSValue(item, prop, config) {
    if (config) {
      var configElem = config
        .getConfigurationElements()
        .find(
          (configElem) =>
            configElem.getItemRef() === item.getId() &&
            configElem.getProp() === prop
        );
      return configElem ? configElem.getValue() : "";
    }
  }

  static changeValue(item, prop, value) {
    const module = this.getTargetModule(item);
    if (module) {
      const config = module.getConfigurationFolder()
        ? module.getConfigurationFolder().getSelectedConfiguration()
        : null;
      if (module.getItemType() === ItemType.ClockCMU) {
        this._changeCMSValue(item, prop, value, config, module);
      } else if (module.getItemType() === ItemType.VoltageDomain) {
        this._changeSDCConfigValue(item, prop, value, config);
      } else if (module.getItemType() === ItemType.PowerPMC) {
        this._changePMSValue(item, prop, value, config);
      } else {
        this._changePMSValue(item, prop, value, config);
      }
    }
  }

  static getTargetModule(item) {
    const cmu = item.getParent(ItemType.ClockCMU);
    if (cmu) {
      return cmu;
    }
    const pmd = item.getParent(ItemType.PowerPMD);
    if (pmd) {
      return pmd;
    }
    const pmc = item.getParent(ItemType.PowerPMC);
    if (pmc) {
      return pmc;
    }

    if (item.getParent(ItemType.SDCDiagram)) {
      return item.getPrimaryPowerSource();
    }
  }

  static _changeCMSValue(item, prop, value, config, cmu) {
    if (config) {
      var configElem = item.getConfigurationElement(item, prop);
      if (!configElem) {
        configElem = ItemHandler.createItem(
          config,
          ItemType.ConfigurationElement,
          {
            itemRef: item.getId(),
            prop: prop,
          }
        ).item;
        const configMap =
          store.getters["configuration/GET_CMU_TO_CONFIG"].get(cmu);
        if (!configMap.get(item)) {
          configMap.set(item, []);
        }
        configMap.get(item).push(configElem);
      }
      configElem.setValue(String(value), null);
    }
    const canvas = item.getCanvas();
    canvas.getEditor().trigger("process");
  }

  static _changeSDCConfigValue(item, prop, value, config) {
    if (config) {
      var configElem = config
        .getConfigurationElements()
        .find(
          (configElem) =>
            configElem.getItemRef() === item.getId() &&
            configElem.getProp() === prop
        );
      if (!configElem) {
        configElem = ItemHandler.createItem(
          config,
          ItemType.ConfigurationElement,
          {
            itemRef: item.getId(),
            prop: prop,
          }
        );
      }
      configElem.setValue(String(value));
    }
    const canvas = item.getCanvas();
    canvas.getEditor().trigger("process");
    canvas.vueContext.$parent.$forceUpdate();
  }

  static _changePMSValue(item, prop, value, config) {
    if (config) {
      var configElem = config
        .getConfigurationElements()
        .find(
          (configElem) =>
            configElem.getItemRef() === item.getId() &&
            configElem.getProp() === prop
        );
      if (!configElem) {
        configElem = ItemHandler.createItem(
          config,
          ItemType.ConfigurationElement,
          {
            itemRef: item.getId(),
            prop: prop,
          }
        );
      }
      configElem.setValue(String(value));
    }
    const canvas = item.getCanvas();
    canvas.getEditor().trigger("process");
  }

  static checkVerilogSyntax(value) {
    if (!/^[A-Za-z]/.test(value)) {
      return "The first character must be an English alphabet letter";
    }
    if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value)) {
      return "Only English Alphabet is supported";
    }
    if (/\d+\.\d+/.test(value)) {
      return `${value} must be an integer type`;
    }
    if (/ /.test(value) === true) {
      return "Cannot use space key";
    }
    if (/[^A-Za-z0-9_]/.test(value)) {
      return "Only _ is allowed as a special character";
    }
    return true;
  }

  static getRules(item, prop) {
    const res = [];
    const isValidInteger = (value) => {
      const strValue = String(value).trim();
      const num = Number(strValue);
      if (Number.isInteger(num) && !isNaN(num)) {
        return true;
      }
      if (/^0x[0-9a-fA-F]+$/.test(strValue) && !isNaN(num)) {
        return true;
      }

      return `${value} must be an integer or hexadecimal type`;
    };

    const rangeCheck = (value, minValue, maxValue) => {
      const num = Number(value);
      if (isNaN(num) || num < Number(minValue) || num > Number(maxValue)) {
        return `${value} must be in the range ${minValue} ~ ${maxValue}`;
      }
      return true;
    };

    const isValidNumber = (value) => {
      const strValue = String(value).trim();
      const num = Number(strValue);

      if (Number.isInteger(num) && !isNaN(num)) {
        return true;
      }

      if (/^0x[0-9a-fA-F]+$/.test(strValue) && !isNaN(num)) {
        return true;
      }
      if (!isNaN(num) && /^\d+(\.\d+)?$/.test(strValue)) {
        return true;
      }

      return `${value} must be an integer, hexadecimal, or floating-point number`;
    };

    const isValidVoltage = (value) => {
      const strValue = String(value)
        .trim()
        .replace(/^0+(\d)/, "$1");
      const num = Number(strValue);

      if (isNaN(num) || num < 0 || num > 100) {
        return `${value} must be in the range 0 and 100`;
      }

      const decimalPlaces = strValue.split(".")[1];
      if (decimalPlaces && decimalPlaces.length > 4) {
        return `${value} must be a number with up to 4 decimal places (e.g., 0.0000 - 99.9999 or 100)`;
      }

      return true;
    };

    switch (prop) {
      case "SignalName":
        res.push((value) => {
          const result = this.checkVerilogSyntax(value);
          if (result !== true) {
            return result;
          }
          return true;
        });
        break;
      case "frequency":
        let maxFreq = 10000;
        res.push((value) => {
          const validIntegerResult = isValidInteger(value);
          if (validIntegerResult !== true) {
            return validIntegerResult;
          }
          const rangeCheckResult = rangeCheck(value, 1, maxFreq);
          if (rangeCheckResult !== true) {
            return rangeCheckResult;
          }

          return true;
        });
        break;
      case "selection":
        var numOfParents = 2;
        if (item.getItemType() === ItemType.ClockMultiplexer) {
          numOfParents = item.getNumberOfParents();
        }
        res.push((value) => {
          const validIntegerResult = isValidInteger(value);
          if (validIntegerResult !== true) {
            return validIntegerResult;
          }
          const rangeCheckResult = rangeCheck(value, 0, numOfParents - 1);
          if (rangeCheckResult !== true) {
            return rangeCheckResult;
          }
          return true;
        });
        break;

      case "divRatio":
        res.push((value) => {
          const maxValue = Math.pow(2, item.getMaxDivRatioWidth());
          const validIntegerResult = isValidInteger(value);
          if (validIntegerResult !== true) {
            return validIntegerResult;
          }
          const rangeCheckResult = rangeCheck(value, 0, maxValue - 1);
          if (rangeCheckResult !== true) {
            return rangeCheckResult;
          }
          return true;
        });
        break;
      case "numerator":
        res.push((value) => {
          const configElements = API.getCMUConfigurationElements(item);
          let denominatorVal = 0;

          if (configElements) {
            const existConfigElem = configElements.find(
              (e) => e.getProp() === "denominator"
            );

            if (existConfigElem) {
              let denomVal = existConfigElem.getValue(value);

              if (denomVal !== undefined) {
                denominatorVal = Number(denomVal);
              }
            }
          }
          const validIntegerResult = isValidInteger(value);
          if (validIntegerResult !== true) {
            return validIntegerResult;
          }
          if (denominatorVal === -1 || denominatorVal === 0) {
            if (value === "" || Number(value) > 15 || Number(value) < 0) {
              return `${value} must be in the range 0 ~ 15`;
            }
          } else if (denominatorVal <= 4 && denominatorVal >= 0) {
            if (
              value === "" ||
              Number(value) > 2 ** (denominatorVal + 4) - 1 ||
              Number(value) < 0
            ) {
              return `${value} must be in the range 0 ~ ${
                2 ** (denominatorVal + 4) - 1
              }`;
            }
          } else {
            return "Please enter valid denominator";
          }
          return true;
        });
        break;
      case "denominator":
        res.push((value) => {
          const numeratorVal = this.getValue(item, "numerator");
          const validIntegerResult = isValidInteger(value);
          if (validIntegerResult !== true) {
            return validIntegerResult;
          }
          const rangeCheckResult = rangeCheck(value, 0, 4);
          if (rangeCheckResult !== true) {
            return rangeCheckResult;
          }
          if (numeratorVal > 2 ** (Number(value) + 4)) {
            return "cannot change denominator (less than numerator)";
          }
          return true;
        });
        break;
      case "PGEN":
      case "PWREN":
      case "ISOEN":
      case "RESETn":
      case "MEM_PGEN":
      case "MEM_RET":
      case "RETENTION":
      case "REFCLKEN":
      case "OTP_REQ":
      case "REQ":
        res.push((value) => {
          const validIntegerResult = isValidInteger(value);
          if (validIntegerResult !== true) {
            return validIntegerResult;
          }
          const rangeCheckResult = rangeCheck(value, 0, 1);
          if (rangeCheckResult !== true) {
            return rangeCheckResult;
          }

          return true;
        });
        break;
      case "USERDEFOUT":
        res.push((value) => {
          const validIntegerResult = isValidInteger(value);
          if (validIntegerResult !== true) {
            return validIntegerResult;
          }
          const maxValue = Math.pow(2, item.getMaxUserdefWidth());
          const rangeCheckResult = rangeCheck(value, 0, maxValue - 1);
          if (rangeCheckResult !== true) {
            return rangeCheckResult;
          }
          return true;
        });
        break;
      case "voltage":
        res.push((value) => {
          const validNumberResult = isValidNumber(value);
          if (validNumberResult !== true) {
            return validNumberResult;
          }
          const validVoltageResult = isValidVoltage(value);
          if (validVoltageResult !== true) {
            return validVoltageResult;
          }
          return true;
        });
        break;
      case "name":
        res.push((value) => {
          const result = this.checkVerilogSyntax(value);
          if (result !== true) {
            return result;
          }
          return true;
        });
        break;
      default:
        break;
    }
    return res;
  }
}
