/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerPMRPMDGRPEvent from '@/model/src/events/PowerPMRPMDGRPEvent'
import PowerPMRPMDGRPChecker from '@/model/src/checkers/PowerPMRPMDGRPChecker'
import ITDA_PMS_PMC_PMDGRP from '@/model/gen/sfrSpec/ITDA_PMS_PMC_PMDGRP'
export default class PowerPMRPMDGRP extends Node {
    FID_POWERPMRPMDGRP_NAME = 100
    FID_POWERPMRPMDGRP_NUMOFPMDS = 101
    FID_POWERPMRPMDGRP_NUMOFRAMSIZE = 102
    FID_POWERPMRPMDGRP_MAXNUM = 103
    FID_POWERPMRPMDGRP_LAST = 103

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerPMRPMDGRP"}))
        
        this.setEvent(new PowerPMRPMDGRPEvent(this))
        this.setChecker(new PowerPMRPMDGRPChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "PowerPMRPMDGRP"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "PMDGRP_NONAME"
    }
    if (this.properties.numOfPMDs === undefined) {
        this.properties.numOfPMDs = "4"
    }
    if (this.properties.numOfRAMSize === undefined) {
        this.properties.numOfRAMSize = "4KB"
    }
    if (this.properties.maxNum === undefined) {
        this.properties.maxNum = 16
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerPMRPMDGRP"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "PMDGRP_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "numOfPMDs": {"type": "enum", "label": "Number of PMD", "targets": ["1", "2", "3", "4", "5", "6", "7", "8"], "multiple": false, "default": "4", "category": "IP", "hidden": false, "readOnly": false}, "numOfRAMSize": {"type": "enum", "label": "RAM Size", "targets": ["1KB", "2KB", "4KB"], "multiple": false, "default": "4KB", "category": "IP", "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 16, "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "PMDGRP_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "numOfPMDs": {"type": "enum", "label": "Number of PMD", "targets": ["1", "2", "3", "4", "5", "6", "7", "8"], "multiple": false, "default": "4", "category": "IP", "hidden": false, "readOnly": false}, "numOfRAMSize": {"type": "enum", "label": "RAM Size", "targets": ["1KB", "2KB", "4KB"], "multiple": false, "default": "4KB", "category": "IP", "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 16, "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "PowerPMRINTRGRP", "auto": false, "searchable": false, "title": "", "headers": [], "hidden": true, "category": "IP"}, {"name": "PowerPMRPMDCTRL", "auto": false, "searchable": false, "title": "", "headers": [], "hidden": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_PMS_PMC_PMDGRP
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_PMS_PMC_PMDGRP
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRPMDGRP_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERPMRPMDGRP_NAME
    }
    

    setNumOfPMDs(numOfPMDs, event=true) {
        if ((numOfPMDs !== null) && (numOfPMDs !== undefined)) {
            if (![String].includes(numOfPMDs.constructor)) {
                throw `numOfPMDs(${numOfPMDs}) must be [String] type`
            }
        }
        const newVal = (numOfPMDs === null || numOfPMDs === undefined) ? undefined : numOfPMDs
        const oldVal = this.properties.numOfPMDs
        this.properties.numOfPMDs = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRPMDGRP_NUMOFPMDS, newVal, oldVal)
        }
    }
    getNumOfPMDs(pure = false) {
        return this.properties.numOfPMDs
        
    }
    getFidNumOfPMDs() {
        return this.FID_POWERPMRPMDGRP_NUMOFPMDS
    }
    

    setNumOfRAMSize(numOfRAMSize, event=true) {
        if ((numOfRAMSize !== null) && (numOfRAMSize !== undefined)) {
            if (![String].includes(numOfRAMSize.constructor)) {
                throw `numOfRAMSize(${numOfRAMSize}) must be [String] type`
            }
        }
        const newVal = (numOfRAMSize === null || numOfRAMSize === undefined) ? undefined : numOfRAMSize
        const oldVal = this.properties.numOfRAMSize
        this.properties.numOfRAMSize = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRPMDGRP_NUMOFRAMSIZE, newVal, oldVal)
        }
    }
    getNumOfRAMSize(pure = false) {
        return this.properties.numOfRAMSize
        
    }
    getFidNumOfRAMSize() {
        return this.FID_POWERPMRPMDGRP_NUMOFRAMSIZE
    }
    

    setMaxNum(maxNum, event=true) {
        if ((maxNum !== null) && (maxNum !== undefined)) {
            if (![Number].includes(maxNum.constructor)) {
                throw `maxNum(${maxNum}) must be [Number] type`
            }
        }
        const newVal = (maxNum === null || maxNum === undefined) ? undefined : maxNum
        const oldVal = this.properties.maxNum
        this.properties.maxNum = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRPMDGRP_MAXNUM, newVal, oldVal)
        }
    }
    getMaxNum(pure = false) {
        return this.properties.maxNum
        
    }
    getFidMaxNum() {
        return this.FID_POWERPMRPMDGRP_MAXNUM
    }
    

    getFidLast() {
        return this.FID_POWERPMRPMDGRP_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERPMRPMDGRP_NAME:
                return "name"

            case this.FID_POWERPMRPMDGRP_NUMOFPMDS:
                return "numOfPMDs"

            case this.FID_POWERPMRPMDGRP_NUMOFRAMSIZE:
                return "numOfRAMSize"

            case this.FID_POWERPMRPMDGRP_MAXNUM:
                return "maxNum"

            default:
                return ""
        }
    }


    newPowerPMRINTRGRP(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMRINTRGRP", properties, title, domain)
    }
    remPowerPMRINTRGRP(obj) {
        return this.removeChild(obj)
    }
    getPowerPMRINTRGRPs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerPMRINTRGRP")
            return res
        })
    }
    newPowerPMRPMDCTRL(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMRPMDCTRL", properties, title, domain)
    }
    remPowerPMRPMDCTRL(obj) {
        return this.removeChild(obj)
    }
    getPowerPMRPMDCTRLs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerPMRPMDCTRL")
            return res
        })
    }
}