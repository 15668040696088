/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import ClockBufferEvent from '@/model/src/events/ClockBufferEvent'
import ClockBufferChecker from '@/model/src/checkers/ClockBufferChecker'
import ITDA_CMU_BUFFER_PT from '@/model/gen/sfrSpec/ITDA_CMU_BUFFER_PT'
export default class ClockBuffer extends Node {
    FID_CLOCKBUFFER_NAME = 100
    FID_CLOCKBUFFER_MAXNUM = 101
    FID_CLOCKBUFFER_SDCPATH = 102
    FID_CLOCKBUFFER_LAST = 102

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockBuffer"}))
        
        this.setEvent(new ClockBufferEvent(this))
        this.setChecker(new ClockBufferChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.maxNum === undefined) {
        this.properties.maxNum = 128
    }
    if (this.properties.SDCPath === undefined) {
        this.properties.SDCPath = "/{{CMU}}/{{NODE}}/{{NODE}}__CLKCOMP_BUF/{{NODE}}__CLKCOMP_BUF__CLKBUF/$POINT$ITDA_GENERIC_CLKBUF$Y"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockBuffer"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 128, "category": "IP", "multiple": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "/{{CMU}}/{{NODE}}/{{NODE}}__CLKCOMP_BUF/{{NODE}}__CLKCOMP_BUF__CLKBUF/$POINT$ITDA_GENERIC_CLKBUF$Y", "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 128, "category": "IP", "multiple": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "/{{CMU}}/{{NODE}}/{{NODE}}__CLKCOMP_BUF/{{NODE}}__CLKCOMP_BUF__CLKBUF/$POINT$ITDA_GENERIC_CLKBUF$Y", "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_CMU_BUFFER_PT
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_CMU_BUFFER_PT
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKBUFFER_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKBUFFER_NAME
    }
    

    setMaxNum(maxNum, event=true) {
        if ((maxNum !== null) && (maxNum !== undefined)) {
            if (![Number].includes(maxNum.constructor)) {
                throw `maxNum(${maxNum}) must be [Number] type`
            }
        }
        const newVal = (maxNum === null || maxNum === undefined) ? undefined : maxNum
        const oldVal = this.properties.maxNum
        this.properties.maxNum = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKBUFFER_MAXNUM, newVal, oldVal)
        }
    }
    getMaxNum(pure = false) {
        return this.properties.maxNum
        
    }
    getFidMaxNum() {
        return this.FID_CLOCKBUFFER_MAXNUM
    }
    

    setSDCPath(SDCPath, event=true) {
        if ((SDCPath !== null) && (SDCPath !== undefined)) {
            if (![String].includes(SDCPath.constructor)) {
                throw `SDCPath(${SDCPath}) must be [String] type`
            }
        }
        const newVal = (SDCPath === null || SDCPath === undefined) ? undefined : SDCPath
        const oldVal = this.properties.SDCPath
        this.properties.SDCPath = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKBUFFER_SDCPATH, newVal, oldVal)
        }
    }
    getSDCPath(pure = false) {
        return this.properties.SDCPath
        
    }
    getFidSDCPath() {
        return this.FID_CLOCKBUFFER_SDCPATH
    }
    

    getFidLast() {
        return this.FID_CLOCKBUFFER_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKBUFFER_NAME:
                return "name"

            case this.FID_CLOCKBUFFER_MAXNUM:
                return "maxNum"

            case this.FID_CLOCKBUFFER_SDCPATH:
                return "SDCPath"

            default:
                return ""
        }
    }


}