import { Item } from "ITDAModelTypes";
import { ITDAConnection } from "../common";
import { Repository } from "../store";

const repo = Repository.getInstance();
export default {
  connect: (
    id: string,
    source: Item,
    sindex: number,
    target: Item,
    tindex: number
  ) => {
    const editor = repo.getEditor(id);
    return editor.addConnection(
      new ITDAConnection(
        editor.getNode(source.getNodeID()),
        `out${sindex}`,
        editor.getNode(target.getNodeID()),
        `in${tindex}`
      )
    );
  },
};
