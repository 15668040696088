import { Item, Property } from "ITDAModelTypes";
import { isDisabled } from "./ItemProperty";

export const isDisabledCheckbox = (
  item: Item,
  key: string,
  prop: Property
): boolean => {
  const disabled = isDisabled(item, key, prop);
  switch (item.getItemType()) {
    default:
      return false || disabled;
  }
};
