import { GetSchemes } from "rete";
import ITDAConnection from "./ITDAConnection";
import * as ITDANode from "../nodes";
import { ITDAInput, ITDAOutput } from "../controls/ITDAControlPort";

export type NodeProps = ITDANode.ITDANode;
export type ConnProps = ITDAConnection<ITDANode.ITDANode, ITDANode.ITDANode>;
export type Schemes = GetSchemes<NodeProps, ConnProps>;

export enum ITDASignalType {
  PWREN = "pwren",
}

export type ITDAPort = ITDAInput | ITDAOutput;

export enum ITDAPortPosition {
  TOP = "Top",
  RIGHT = "Right",
  BOTTOM = "Bottom",
  LEFT = "Left",
}

export class ITDASocketType {
  private index: number;
  private key: keyof typeof ITDASocketType;
  private color: string;
  private dashArray: number;

  static CLK = new ITDASocketType(0, "CLK", "gray");
  static QCH = new ITDASocketType(0, "QCH", "green", 8);

  static PWRCTRL = new ITDASocketType(0, "PWRCTRL", "gray", 8);
  static SEQ = new ITDASocketType(0, "SEQ", "black");
  static SEQREF = new ITDASocketType(0, "SEQREF", "gray", 4);

  static SFRIF = new ITDASocketType(0, "SFRIF", "black");
  static CLINK = new ITDASocketType(0, "CLINK", "blue");
  static PLINK = new ITDASocketType(0, "PLINK", "pink");

  private constructor(
    index: number,
    key: keyof typeof ITDASocketType,
    color: string,
    dashArray = 0
  ) {
    this.index = index;
    this.key = key;
    this.color = color;
    this.dashArray = dashArray;
  }

  static getKeys(): string[] {
    return Object.keys(this);
  }

  static getValues(): ITDASocketType[] {
    return Object.values(this);
  }

  static getEntries(): [string, ITDASocketType][] {
    return Object.entries(this);
  }

  static getObjByKey(
    key: keyof typeof ITDASocketType
  ): ITDASocketType | undefined {
    return this[key] as ITDASocketType;
  }

  getKey(): keyof typeof ITDASocketType {
    return this.key;
  }

  getColor(): string {
    return this.color;
  }

  getDashArray(): number {
    return this.dashArray;
  }
}
