'use strict';

export default class DiagramType {

    static Clock        = new DiagramType(0, "Clock")
    static Power        = new DiagramType(0, "Power")
    static Sequence     = new DiagramType(0, "Sequence")

    static IP           = new DiagramType(0, "IP")
    static Integration  = new DiagramType(0, "Integration")

    static SDC          = new DiagramType(1, "SDC")
    static UPF          = new DiagramType(1, "UPF")
    static HardMacro    = new DiagramType(1, "HardMacro")
    
    constructor(index, key) {
        this.index = index
        this.key = key
    }

    static getKeys() {
        return Object.keys(this)
    }

    static getValues() {
        return Object.values(this)
    }

    static getEntries() {
        return Object.entries(this)
    }
    
    static getObjByKey(key) {
        return this[key]
    }

    getKey() {
        return this.key
    }
}
