/**
 *  Generated by Modeler
 *  timestamp: 2024-10-31 12:18:28.066127
 */
import ItemEvent from '@/model/src/ItemEvent';
import ItemType from "../ItemType";

export default class PowerSequenceEndEvent extends ItemEvent {
    constructor(item) {
        super(item)
    }

    postProcess(item) {
        return this.setupPorts(item);
      }
    
    setupPorts(item) {
        return item.newInput({
            key: `SEQ_${ItemType.Input.getKey().toUpperCase()}`,
            socket: "SEQ",
            position: "TOP",
            multiple: false,
        }).promise;
    }
}
