/**
 *  Generated by Modeler
 *  timestamp: 2023-04-07 16:24:16.040754
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class VoltageLevelFolderEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(
      item.getFidSelectedConfiguration(),
      this.lstn_selectedConfiguration
    );
  }
  lstn_selectedConfiguration(item, newVal, oldVal) {
    if (oldVal) {
      oldVal.setSelected(false);
    }
    if (newVal) {
      newVal.setSelected(true);
    }
  }
}
