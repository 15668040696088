import { Repository } from "@/canvas/store";
import {
  Item,
  ItemType,
  RefItemElement,
  RefItemElementTarget,
  RefItemElementType,
  VariantLevel,
} from "ITDAModelTypes";

export const getSDCPhantomElements = (
  sdcPhantom: Item,
  propKey: string
): RefItemElement[] => {
  if (propKey === "clockGroup") {
    const sdcInstance = sdcPhantom.getParent(ItemType.SDCInstance);
    if (sdcInstance) {
      const childrenData: RefItemElementTarget[] = [];
      sdcInstance.getSDCClockGroups().forEach((clockGrp: Item) => {
        childrenData.push({
          key: clockGrp.getName(),
          item: clockGrp,
          closable: true,
        });
      });
      childrenData.sort((a, b) => {
        return a.key.localeCompare(b.key, "ko");
      });

      const model = sdcPhantom.getClockGroup()?.getName()
        ? sdcPhantom.getClockGroup().getName()
        : undefined;

      return [
        {
          variant: VariantLevel.LEVEL1,
          disabled: false,
          type: RefItemElementType.ENUM,
          propKey,
          label: "Clock Group",
          model,
          targets: childrenData,
          event: eventLabels,
        },
      ];
    }
  }
  return [];
};

const eventLabels = (
  item: Item,
  elem: RefItemElement,
  value: string | string[] | number | number[]
) => {
  const target = elem.targets?.find((target) => target.key === value);
  const repo = Repository.getInstance();
  const editor = repo.getEditor(repo.getCurrentID());
  const node = editor.getNode(item.getNodeID());
  const sdc = node.getCtrlSDC();

  if (target) {
    item.setClockGroup(target.item.getId());
    if (sdc) sdc.setBackgroundColor(target.item.getColor());
  } else {
    item.setClockGroup(null);
    if (sdc) sdc.setBackgroundColor("transparent");
  }

  node.update();
};
