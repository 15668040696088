/**
 *  Generated by Modeler
 *  timestamp: 2024-06-13 10:49:37.618522
 */
//import ErrorType from '@/model/src/ErrorType'
import ItemChecker from '@/model/src/ItemChecker'

export default class WrapperDFTFolderChecker extends ItemChecker {
    constructor(item) {
        super(item)
    }
}