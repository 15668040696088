/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerSequenceWriteEvent from '@/model/src/events/PowerSequenceWriteEvent'
import PowerSequenceWriteChecker from '@/model/src/checkers/PowerSequenceWriteChecker'
export default class PowerSequenceWrite extends Node {
    FID_POWERSEQUENCEWRITE_PMD = 100
    FID_POWERSEQUENCEWRITE_PWRCOMP = 101
    FID_POWERSEQUENCEWRITE_SFRFIELD = 102
    FID_POWERSEQUENCEWRITE_VALUE = 103
    FID_POWERSEQUENCEWRITE_LABEL = 104
    FID_POWERSEQUENCEWRITE_BACK = 105
    FID_POWERSEQUENCEWRITE_LAST = 105

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerSequenceWrite"}))
        
        this.setEvent(new PowerSequenceWriteEvent(this))
        this.setChecker(new PowerSequenceWriteChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.label === undefined) {
        this.properties.label = false
    }
    if (this.properties.back === undefined) {
        this.properties.back = false
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerSequenceWrite"
    }

    getSchema() {
        return {"PMD": {"type": "item", "label": "PMD", "desc": "PMD Reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "PWRCOMP": {"type": "item", "label": "Power Component", "desc": "PWRCOMP Reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SFRField": {"type": "item", "label": "SFR Field", "desc": "SFRField Reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "value": {"type": "integer", "label": "Value", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "label": {"type": "boolean", "label": "Create LABEL", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "back": {"type": "boolean", "label": "Is Back Point", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"PMD": {"type": "item", "label": "PMD", "desc": "PMD Reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "PWRCOMP": {"type": "item", "label": "Power Component", "desc": "PWRCOMP Reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SFRField": {"type": "item", "label": "SFR Field", "desc": "SFRField Reference", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "value": {"type": "integer", "label": "Value", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "label": {"type": "boolean", "label": "Create LABEL", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "back": {"type": "boolean", "label": "Is Back Point", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setPMD(PMD, event=true) {
        if ((PMD !== null) && (PMD !== undefined)) {
            if (!(PMD instanceof Item) && (PMD.constructor !== String)) {
                throw `PMD(${PMD}) must be Item or String type`
            }
        }
        const newVal = (PMD === null || PMD === undefined) ? undefined : (PMD.constructor === String) ? PMD : PMD.getId()
        const oldVal = this.properties.PMD
        this.properties.PMD = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCEWRITE_PMD, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getPMD(pure = false) {
        return pure ? this.properties.PMD : this.getRefItem(this.properties.PMD)
        
    }
    getFidPMD() {
        return this.FID_POWERSEQUENCEWRITE_PMD
    }
    

    setPWRCOMP(PWRCOMP, event=true) {
        if ((PWRCOMP !== null) && (PWRCOMP !== undefined)) {
            if (!(PWRCOMP instanceof Item) && (PWRCOMP.constructor !== String)) {
                throw `PWRCOMP(${PWRCOMP}) must be Item or String type`
            }
        }
        const newVal = (PWRCOMP === null || PWRCOMP === undefined) ? undefined : (PWRCOMP.constructor === String) ? PWRCOMP : PWRCOMP.getId()
        const oldVal = this.properties.PWRCOMP
        this.properties.PWRCOMP = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCEWRITE_PWRCOMP, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getPWRCOMP(pure = false) {
        return pure ? this.properties.PWRCOMP : this.getRefItem(this.properties.PWRCOMP)
        
    }
    getFidPWRCOMP() {
        return this.FID_POWERSEQUENCEWRITE_PWRCOMP
    }
    

    setSFRField(SFRField, event=true) {
        if ((SFRField !== null) && (SFRField !== undefined)) {
            if (!(SFRField instanceof Item) && (SFRField.constructor !== String)) {
                throw `SFRField(${SFRField}) must be Item or String type`
            }
        }
        const newVal = (SFRField === null || SFRField === undefined) ? undefined : (SFRField.constructor === String) ? SFRField : SFRField.getId()
        const oldVal = this.properties.SFRField
        this.properties.SFRField = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCEWRITE_SFRFIELD, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getSFRField(pure = false) {
        return pure ? this.properties.SFRField : this.getRefItem(this.properties.SFRField)
        
    }
    getFidSFRField() {
        return this.FID_POWERSEQUENCEWRITE_SFRFIELD
    }
    

    setValue(value, event=true) {
        if ((value !== null) && (value !== undefined)) {
            if (![Number].includes(value.constructor)) {
                throw `value(${value}) must be [Number] type`
            }
        }
        const newVal = (value === null || value === undefined) ? undefined : value
        const oldVal = this.properties.value
        this.properties.value = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCEWRITE_VALUE, newVal, oldVal)
        }
    }
    getValue(pure = false) {
        return this.properties.value
        
    }
    getFidValue() {
        return this.FID_POWERSEQUENCEWRITE_VALUE
    }
    

    setLabel(label, event=true) {
        if ((label !== null) && (label !== undefined)) {
            if (![Boolean].includes(label.constructor)) {
                throw `label(${label}) must be [Boolean] type`
            }
        }
        const newVal = (label === null || label === undefined) ? undefined : label
        const oldVal = this.properties.label
        this.properties.label = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCEWRITE_LABEL, newVal, oldVal)
        }
    }
    getLabel(pure = false) {
        return this.properties.label
        
    }
    getFidLabel() {
        return this.FID_POWERSEQUENCEWRITE_LABEL
    }
    

    setBack(back, event=true) {
        if ((back !== null) && (back !== undefined)) {
            if (![Boolean].includes(back.constructor)) {
                throw `back(${back}) must be [Boolean] type`
            }
        }
        const newVal = (back === null || back === undefined) ? undefined : back
        const oldVal = this.properties.back
        this.properties.back = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCEWRITE_BACK, newVal, oldVal)
        }
    }
    getBack(pure = false) {
        return this.properties.back
        
    }
    getFidBack() {
        return this.FID_POWERSEQUENCEWRITE_BACK
    }
    

    getFidLast() {
        return this.FID_POWERSEQUENCEWRITE_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERSEQUENCEWRITE_PMD:
                return "PMD"

            case this.FID_POWERSEQUENCEWRITE_PWRCOMP:
                return "PWRCOMP"

            case this.FID_POWERSEQUENCEWRITE_SFRFIELD:
                return "SFRField"

            case this.FID_POWERSEQUENCEWRITE_VALUE:
                return "value"

            case this.FID_POWERSEQUENCEWRITE_LABEL:
                return "label"

            case this.FID_POWERSEQUENCEWRITE_BACK:
                return "back"

            default:
                return ""
        }
    }


}