import { Property, PropertyValue } from "@/store/types";
import API from "@/api/internal";
import { ExternalSpecPLL, Item, ItemType } from "ITDAModelTypes";

export const isStringType = (prop: Property): boolean => {
  return prop.type === "string";
};

export const isIntegerType = (prop: Property): boolean => {
  return prop.type === "integer";
};

export const isBooleanType = (prop: Property): boolean => {
  return prop.type === "boolean";
};

export const isEnumType = (prop: Property): boolean => {
  return prop.type === "enum";
};

export const isItemType = (prop: Property): boolean => {
  return prop.type === "item";
};

export const isTextareaType = (prop: Property): boolean => {
  return prop.type === "textarea";
};

export const isColorType = (prop: Property): boolean => {
  return prop.type === "color";
};

export const isMultiple = (prop: Property): boolean => {
  return prop.multiple ? true : false;
};

export const getLabel = (key: string, prop: Property): string => {
  return prop.label?.constructor === String ? prop.label : key;
};

export const isDisabled = (
  item: Item,
  key: string,
  prop: Property
): boolean => {
  const readOnly = prop.readOnly ? prop.readOnly : false;
  switch (item.getItemType()) {
    case ItemType.SFRBlock:
    case ItemType.SFR:
    case ItemType.SFRField:
      return true;
    case ItemType.PowerSequenceWrite:
    case ItemType.PowerSequenceReadWait:
    case ItemType.PowerSequenceWait:
    case ItemType.PowerSequenceIf: {
      if (key === "back") {
        const seqOutput = item
          .getOutputs()
          .find((output: Item) => output.getKey() === "SEQ_OUTPUT");
        if (seqOutput && seqOutput.getConnections()?.length > 0) {
          for (const conn of seqOutput.getConnections()) {
            if (
              conn?.getTargetInput().getParent().getItemType() !==
              ItemType.PowerSequenceBack
            )
              return true;
          }
        }
      }
      return false;
    }
    case ItemType.ConfigurationElement:
      if (item.getParent().getItemType() === ItemType.Configuration) {
        return true;
      }
      return false;
    case ItemType.SDCPhantom:
      if (item.getOriginItem() && key === "ports") {
        return checkPortsIsAvailable(item) ? false : true;
      }
      return false;
    default:
      return false || readOnly;
  }
};

export const getDesc = (): string => {
  return "";
};

export const getTargets = (prop: Property): string[] => {
  return prop.targets ? prop.targets : [];
};

export const getValue = (
  item: Item,
  key: string
): PropertyValue | PropertyValue[] => {
  return API.getItemValue(item, key);
};

export const showProps = (item: Item, propKey: string): boolean => {
  if (item.getItemType() === ItemType.LabelRef) {
    if (item.getType() === "internal") {
      return propKey === "boundaryName" ? false : true;
    }
  }
  return true;
};

const checkPortsIsAvailable = (item: Item) => {
  switch (item.getOriginItem().getItemType()) {
    case ItemType.ClockPLLCtrl:
      return true;
    case ItemType.ClockExternalCLKSource:
      return true;
    case ItemType.LabelRef:
      if (item.getOriginItem().getType() === "external") {
        return true;
      } else {
        return false;
      }
    default:
      return false;
  }
};
