/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";

export default class UPFPowerDomainChecker extends ItemChecker {
  constructor(item) {
    super(item);
    this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME);
    this.addChecker(
      item.getFidPrimaryPowerSource(),
      this.checkPrimaryPowerSource,
      ErrorType.POWER_DIAGRAM
    );
    //this.addChecker(item.getFidIsolationCells(), this.checkIsolationCells, ErrorType.NAME)
  }
  checkName(item) {
    if (!item.getName()) {
      this.newError(`name is null.`, item);
    }
  }
  checkPrimaryPowerSource(item) {
    const ps = item.getPrimaryPowerSource();
    if (!ps) {
      this.newError(`Primary power must be selected.`, item);
    }
  }
  checkIsolationCells() {
    // TBD
  }
}
