import { InstanceType, NodeType } from "../types";
import ITDANode from "./ITDANode";
import * as ITDANodeRenderer from "./renderers";

export default class ITDAINSTANCE extends ITDANode {
  private instanceType: InstanceType;
  constructor(nodeType: NodeType) {
    super(nodeType.getTitle(), nodeType);
    this.instanceType = nodeType.getSubType();
    if (this.getType().getKey() === "ITDAINSTANCEIP") {
      this.render = ITDANodeRenderer.ITDAINSTANCEIP.render;
    } else {
      this.render = ITDANodeRenderer.ITDAINSTANCE.render;
    }
    // this.resizable = true;
  }

  set width(data: number) {
    super.width = data;
  }

  get width() {
    const nodeStroke = this._nodeStroke;
    const adjustWidth = this.adjustNodeToGrid(super.width);
    switch (this.getType()) {
      case NodeType.ITDAINSTANCESDC:
      case NodeType.ITDAINSTANCESDCPHANTOMGROUP:
      case NodeType.ITDAINSTANCEBLOCK:
        return adjustWidth + nodeStroke;
      case NodeType.ITDAINSTANCEIP:
        return adjustWidth - nodeStroke / 3;
      default: {
        const ctrl = this.getCtrlPort();
        return this.isDocked() ||
          (ctrl.getTopPorts().length === 0 &&
            ctrl.getBottomPorts().length === 0)
          ? adjustWidth + nodeStroke
          : Math.max(ctrl.getTopPorts().length, ctrl.getBottomPorts().length) *
              adjustWidth +
              nodeStroke;
      }
    }
  }

  set height(data: number) {
    super.height = data;
  }

  get height() {
    const nodeStroke = this._nodeStroke;
    const adjustHeight = this.adjustNodeToGrid(super.height);
    switch (this.getType()) {
      case NodeType.ITDAINSTANCESDC:
      case NodeType.ITDAINSTANCESDCPHANTOMGROUP:
      case NodeType.ITDAINSTANCEBLOCK:
        return adjustHeight + nodeStroke;
      case NodeType.ITDAINSTANCEIP: {
        const ctrl = this.getCtrlPort();
        return this.isDocked() ||
          (ctrl.getLeftPorts().length === 0 &&
            ctrl.getRightPorts().length === 0)
          ? adjustHeight - nodeStroke / 3
          : Math.max(ctrl.getLeftPorts().length, ctrl.getRightPorts().length) *
              adjustHeight;
      }
      default: {
        const ctrl = this.getCtrlPort();
        return this.isDocked() ||
          (ctrl.getLeftPorts().length === 0 &&
            ctrl.getRightPorts().length === 0)
          ? adjustHeight + nodeStroke
          : Math.max(ctrl.getLeftPorts().length, ctrl.getRightPorts().length) *
              adjustHeight +
              nodeStroke;
      }
    }
  }

  getInstanceType(): InstanceType {
    return this.instanceType;
  }
}
