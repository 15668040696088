"use strict";

import DiagramType from "./DiagramType";
import ModuleType from "./ModuleType";
import NodeEXTType from "./NodeEXTType";
import NodeType from "./NodeType";

export default class ItemType {
  // Common
  static Configuration = new ItemType(1, "Configuration");
  static ConfigurationElement = new ItemType(1, "ConfigurationElement");
  static ConfigurationFolder = new ItemType(1, "ConfigurationFolder");
  static Connection = new ItemType(3, "Connection");
  static Input = new ItemType(40, "Input");
  static Label = new ItemType(
    38,
    "Label",
    NodeType.Instance,
    "LABEL",
    "ISO OUTPUT"
  );
  static LabelRef = new ItemType(
    39,
    "LabelRef",
    NodeType.Instance,
    "LABEL_REF",
    "ISO INPUT"
  );
  static NodeMetaView = new ItemType(0, "NodeMetaView");
  static Output = new ItemType(40, "Output");
  static Project = new ItemType(0, "Project");
  static SFR = new ItemType(22, "SFR");
  static SFRBlock = new ItemType(21, "SFRBlock");
  static SFRField = new ItemType(23, "SFRField");

  // Clock
  static ClockBuffer = new ItemType(
    37,
    "ClockBuffer",
    NodeType.Clock,
    "BUFFER"
  );
  static ClockCMU = new ItemType(20, "ClockCMU", ModuleType.Clock, "CMU");
  static ClockCMUCtrl = new ItemType(20, "ClockCMUCtrl");
  static ClockCMUFolder = new ItemType(20, "ClockCMUFolder", "", "CMU");
  static ClockDiagram = new ItemType(25, "ClockDiagram", DiagramType.Clock);
  static ClockDiagramFolder = new ItemType(24, "ClockDiagramFolder");
  static ClockDivider = new ItemType(
    35,
    "ClockDivider",
    NodeType.Clock,
    "DIVIDER"
  );
  static ClockDividerEXT = new ItemType(
    32,
    "ClockDividerEXT",
    "ClockNodeSub",
    "",
    "",
    NodeEXTType.ClockCTRLPWRDOWN,
    NodeEXTType.ClockCTRLTHROTTLE,
    NodeEXTType.ClockCTRLCUSTOM
  );
  static ClockExternalCLKSource = new ItemType(
    31,
    "ClockExternalCLKSource",
    NodeType.Instance,
    "SOURCE"
  );
  static ClockFreqmon = new ItemType(
    42,
    "ClockFreqmon",
    NodeType.Clock,
    "FREQMON"
  );
  static ClockFreqmonEXT = new ItemType(
    42,
    "ClockFreqmonEXT",
    "ClockNodeSub",
    "",
    "",
    NodeEXTType.ClockCTRLCUSTOM,
    NodeEXTType.ClockCTRLFREQMON
  );
  static ClockGate = new ItemType(36, "ClockGate", NodeType.Clock, "GATE");
  static ClockGateEXT = new ItemType(
    32,
    "ClockGateEXT",
    "ClockNodeSub",
    "",
    "",
    NodeEXTType.ClockCTRLSHORTSTOP,
    NodeEXTType.ClockCTRLCUSTOM,
    NodeEXTType.ClockCTRLEWAKEUP
  );
  static ClockIP = new ItemType(7, "ClockIP", NodeType.Instance, "IP");
  static ClockIPDiagram = new ItemType(6, "ClockIPDiagram", DiagramType.IP);
  static ClockIPInput = new ItemType(8, "ClockIPInput");
  static ClockMultiplexer = new ItemType(
    34,
    "ClockMultiplexer",
    NodeType.Clock,
    "MUX"
  );
  static ClockMultiplexerEXT = new ItemType(
    32,
    "ClockMultiplexerEXT",
    "ClockNodeSub",
    "",
    "",
    NodeEXTType.ClockCTRLTHROTTLE,
    NodeEXTType.ClockCTRLCUSTOM
  );
  static ClockNMDivider = new ItemType(
    35,
    "ClockNMDivider",
    NodeType.Clock,
    "NMDIVIDER"
  );
  static ClockNMDividerEXT = new ItemType(
    32,
    "ClockNMDividerEXT",
    "ClockNodeSub",
    "",
    "",
    NodeEXTType.ClockCTRLGRADUAL,
    NodeEXTType.ClockCTRLCUSTOM
  );
  static ClockPLL = new ItemType(32, "ClockPLL", "HardMacro", "PLL");
  static ClockPLLCtrl = new ItemType(
    32,
    "ClockPLLCtrl",
    NodeType.Clock,
    "PLLCTRL"
  );
  static ClockPLLCtrlEXT = new ItemType(
    32,
    "ClockPLLCtrlEXT",
    "ClockNodeSub",
    "",
    "",
    NodeEXTType.ClockCTRLPWRDOWN,
    NodeEXTType.ClockCTRLCUSTOM
  );
  static ClockQChannelInfo = new ItemType(
    9,
    "ClockQChannelInfo",
    NodeType.Instance
  );
  static ClockQChannelManager = new ItemType(
    9,
    "ClockQChannelManager",
    NodeType.Clock,
    "QCH"
  );
  static ClockQChannelManagerEXT = new ItemType(
    9,
    "ClockQChannelManagerEXT",
    "ClockNodeSub",
    "",
    "",
    NodeEXTType.ClockCTRLPWRDOWN
  );
  static ClockRefCLKMultiplexer = new ItemType(
    33,
    "ClockRefCLKMultiplexer",
    NodeType.Clock,
    "REFCLKMUX"
  );
  static ClockRefCLKMultiplexerEXT = new ItemType(
    32,
    "ClockRefCLKMultiplexerEXT",
    "ClockNodeSub",
    "",
    "",
    NodeEXTType.ClockCTRLPWRDOWN,
    NodeEXTType.ClockCTRLCUSTOM
  );
  static ClockDFTOCCBuffer = new ItemType(
    10000,
    "ClockDFTOCCBuffer",
    NodeType.Clock,
    "OCC BUFFER"
  );
  static ClockSIGNAL = new ItemType(40, "ClockSIGNAL");

  // DUT
  // static DUTBlock = new ItemType(3, "DUTBlock", NodeType.Module);
  static DUTInstance = new ItemType(3, "DUTInstance", NodeType.ModuleInstance);
  static DUTModule = new ItemType(3, "DUTModule", "");
  static DUTModuleDiagram = new ItemType(
    25,
    "DUTModuleDiagram",
    DiagramType.Integration
  );
  static DUTModuleFolder = new ItemType(3, "DUTModuleFolder", "");
  // static DUTPhantom = new ItemType(0, "DUTPhantom");

  // Power
  static PowerAPM = new ItemType(20, "PowerAPM", NodeType.Power, "APM");
  static PowerAPMPWRDOWN = new ItemType(20, "PowerAPMPWRDOWN");
  static PowerAPMPWRUP = new ItemType(20, "PowerAPMPWRUP");
  static PowerCLINK = new ItemType(20, "PowerCLINK", NodeType.Power, "CLINK");
  static PowerDiagramFolder = new ItemType(20, "PowerDiagramFolder", "");
  static PowerDiagram = new ItemType(
    20,
    "PowerDiagram",
    DiagramType.Integration
  );
  static PowerHANDSHAKE = new ItemType(
    20,
    "PowerHANDSHAKE",
    NodeType.Power,
    "HANDSHAKE"
  );
  static PowerISOEN = new ItemType(20, "PowerISOEN", NodeType.Power, "ISOEN");
  static PowerMEM = new ItemType(20, "PowerMEM", NodeType.Power, "MEM");
  static PowerOTP = new ItemType(20, "PowerOTP", NodeType.Power, "OTP");
  static PowerPCH = new ItemType(20, "PowerPCH", NodeType.Power, "PCH");
  static PowerPMC = new ItemType(20, "PowerPMC", NodeType.Module, "PMC");
  static PowerPMCFolder = new ItemType(20, "PowerPMCFolder", "", "PMC");
  static PowerPMD = new ItemType(20, "PowerPMD", ModuleType.Power, "PMD");
  static PowerPMDCTRLSPT = new ItemType(20, "PowerPMDCTRLSPT");
  static PowerPMDFolder = new ItemType(20, "PowerPMDFolder", "", "PMD");
  static PowerPMDInstance = new ItemType(
    20,
    "PowerPMDInstance",
    NodeType.ModuleInstance,
    "PMD"
  );
  static PowerPMR = new ItemType(20, "PowerPMR");
  static PowerPMRCore = new ItemType(20, "PowerPMRCore");
  static PowerPMRCoreMode = new ItemType(20, "PowerPMRCoreMode");
  static PowerPMRCoreNMI = new ItemType(20, "PowerPMRCoreNMI");
  static PowerPMRCorePCU = new ItemType(20, "PowerPMRCorePCU");
  static PowerPMRCoreReset = new ItemType(20, "PowerPMRCoreReset");
  static PowerPMRCTRL = new ItemType(
    20,
    "PowerPMRCTRL",
    NodeType.Power,
    "PMRCTRL"
  );
  static PowerPMRINFORM = new ItemType(
    20,
    "PowerPMRINFORM",
    NodeType.Power,
    "INFORM"
  );
  static PowerPMRINTREvent = new ItemType(
    20,
    "PowerPMRINTREvent",
    "PowerNodeSub"
  );
  static PowerPMRINTREXT = new ItemType(
    20,
    "PowerPMRINTREXT",
    NodeType.Power,
    "INTREXT"
  );
  static PowerPMRINTREXTATOM = new ItemType(
    20,
    "PowerPMRINTREXTATOM",
    "PowerNodeSub"
  );
  static PowerPMRINTRGenBit = new ItemType(
    20,
    "PowerPMRINTRGenBit",
    "PowerNodeSub"
  );
  static PowerPMRINTRGRP = new ItemType(20, "PowerPMRINTRGRP", "PowerNodeSub");
  static PowerPMRINTRSFR = new ItemType(
    20,
    "PowerPMRINTRSFR",
    NodeType.Power,
    "INTRSFR"
  );
  static PowerPMRINTRSFRATOM = new ItemType(
    20,
    "PowerPMRINTRSFRATOM",
    "PowerNodeSub"
  );
  static PowerPMRLATCH = new ItemType(
    20,
    "PowerPMRLATCH",
    NodeType.Power,
    "LATCH"
  );
  static PowerPMRPMDCTRL = new ItemType(20, "PowerPMRPMDCTRL", "PowerNodeSub");
  static PowerPMRPMDGRP = new ItemType(
    20,
    "PowerPMRPMDGRP",
    NodeType.Power,
    "PMDGRP"
  );
  static PowerPMRTIMEOUT = new ItemType(
    20,
    "PowerPMRTIMEOUT",
    NodeType.Power,
    "TIMEOUT"
  );
  static PowerPMRTIMER = new ItemType(20, "PowerPMRTIMER", "PowerNodeSub");
  static PowerPMRTIMERGRP = new ItemType(
    20,
    "PowerPMRTIMERGRP",
    NodeType.Power,
    "TIMER"
  );
  static PowerPSW = new ItemType(20, "PowerPSW", NodeType.Power, "PSW");
  static PowerPWREN = new ItemType(20, "PowerPWREN", NodeType.Power, "PWREN");
  static PowerREFCLKEN = new ItemType(
    20,
    "PowerREFCLKEN",
    NodeType.Power,
    "REFCLKEN"
  );
  static PowerRESET = new ItemType(20, "PowerRESET", NodeType.Power, "RESET");
  static PowerRETENTION = new ItemType(
    20,
    "PowerRETENTION",
    NodeType.Power,
    "RETENTION"
  );
  static PowerSequenceBack = new ItemType(
    1,
    "PowerSequenceBack",
    NodeType.Sequence,
    "BACK"
  );
  static PowerSequenceCall = new ItemType(
    1,
    "PowerSequenceCall",
    NodeType.Sequence,
    "CALL"
  );
  static PowerSequenceConfiguration = new ItemType(
    1,
    "PowerSequenceConfiguration"
  );
  static PowerSequenceConfigurationFolder = new ItemType(
    1,
    "PowerSequenceConfigurationFolder"
  );
  static PowerSequenceDiagram = new ItemType(
    1,
    "PowerSequenceDiagram",
    DiagramType.Sequence
  );
  static PowerSequenceEnd = new ItemType(
    1,
    "PowerSequenceEnd",
    NodeType.Sequence,
    "END"
  );
  static PowerSequenceGoto = new ItemType(
    1,
    "PowerSequenceGoto",
    NodeType.Sequence,
    "GOTO"
  );
  static PowerSequenceIf = new ItemType(
    1,
    "PowerSequenceIf",
    NodeType.Sequence,
    "IF"
  );
  static PowerSequenceLabel = new ItemType(
    1,
    "PowerSequenceLabel",
    NodeType.Sequence
  );
  static PowerSequenceLabelRef = new ItemType(
    1,
    "PowerSequenceLabelRef",
    NodeType.Sequence
  );
  static PowerSequenceReadWait = new ItemType(
    1,
    "PowerSequenceReadWait",
    NodeType.Sequence,
    "READWAIT"
  );
  static PowerSequenceStart = new ItemType(
    1,
    "PowerSequenceStart",
    NodeType.Sequence,
    "START"
  );
  static PowerSequenceWait = new ItemType(
    1,
    "PowerSequenceWait",
    NodeType.Sequence,
    "WAIT"
  );
  static PowerSequenceWrite = new ItemType(
    1,
    "PowerSequenceWrite",
    NodeType.Sequence,
    "WRITE"
  );
  static PowerUSERDEFIN = new ItemType(
    20,
    "PowerUSERDEFIN",
    NodeType.Power,
    "USERDEFIN"
  );
  static PowerUSERDEFOUT = new ItemType(
    20,
    "PowerUSERDEFOUT",
    NodeType.Power,
    "USERDEFOUT"
  );

  // SDC
  static SDC = new ItemType(3, "SDC");
  static SDCDiagram = new ItemType(6, "SDCDiagram", DiagramType.SDC);
  static SDCInstance = new ItemType(
    3,
    "SDCInstance",
    NodeType.ModuleInstance,
    "CMU"
  );
  static SDCModule = new ItemType(3, "SDCModule");
  static SDCModuleFolder = new ItemType(3, "SDCModuleFolder");
  static SDCPhantom = new ItemType(0, "SDCPhantom");
  static SDCPhantomGroup = new ItemType(0, "SDCPhantomGroup");
  static SDCClockGroup = new ItemType(0, "SDCClockGroup");

  // UPF
  static UPFDiagram = new ItemType(6, "UPFDiagram", DiagramType.UPF);
  static UPFElement = new ItemType(6, "UPFElement", "", "UPFElement");
  static UPFEnableLevelShifter = new ItemType(
    6,
    "UPFEnableLevelShifter",
    NodeType.UPF,
    "ENABLE LEVEL SHIFTER"
  );
  static UPFGround = new ItemType(6, "UPFGround", NodeType.UPF, "GROUND");
  static UPFHardMacro = new ItemType(
    6,
    "UPFHardMacro",
    NodeType.UPF,
    "Hard Macro"
  );
  static UPFIsolationCell = new ItemType(
    6,
    "UPFIsolationCell",
    NodeType.UPF,
    "ISO CELL"
  );
  static UPFLevelShifter = new ItemType(
    6,
    "UPFLevelShifter",
    NodeType.UPF,
    "LEVEL SHIFTER"
  );
  static UPFMapperGround = new ItemType(
    6,
    "UPFMapperGround",
    NodeType.UPF,
    "UPFMapperGround"
  );
  static UPFMapperPowerSource = new ItemType(
    6,
    "UPFMapperPowerSource",
    NodeType.UPF,
    "UPFMapperPowerSource"
  );
  static UPFModule = new ItemType(3, "UPFModule");
  static UPFModuleFolder = new ItemType(3, "UPF");
  static UPFPhantomGround = new ItemType(0, "UPFPhantomGrounds");
  static UPFPhantomPowerSource = new ItemType(0, "UPFPhantomPowerSource");
  static UPFPowerCtrlUnit = new ItemType(
    6,
    "UPFPowerCtrlUnit",
    NodeType.ModuleInstance,
    "POWER CONTROLLER"
  );
  static UPFPowerDomain = new ItemType(
    6,
    "UPFPowerDomain",
    NodeType.UPF,
    "POWER DOMAIN"
  );
  static UPFPowerDomainInstance = new ItemType(
    6,
    "UPFPowerDomainInstance",
    NodeType.ModuleInstance,
    "POWER DOMAIN"
  );
  static UPFPowerSource = new ItemType(
    6,
    "UPFPowerSource",
    NodeType.UPF,
    "POWER SOURCE"
  );
  static UPFPowerSwitch = new ItemType(
    6,
    "UPFPowerSwitch",
    NodeType.UPF,
    "POWER SWITCH"
  );
  static UPFRetention = new ItemType(
    6,
    "UPFRetention",
    NodeType.UPF,
    "RETENTION"
  );
  static UPFSRAM = new ItemType(6, "UPFSRAM", NodeType.UPF, "SRAM");

  // Voltage
  static Voltage = new ItemType(2, "Voltage");
  static VoltageDomain = new ItemType(2, "VoltageDomain");
  static VoltageLevelFolder = new ItemType(1, "VoltageLevelFolder");

  // Wrapper
  static WrapperDFT = new ItemType(2, "WrapperDFT", "", "DFT");
  static WrapperDFTFolder = new ItemType(2, "WrapperDFTFolder", "", "DFT");

  constructor(index, key, category = "", label0 = "", label1 = "", ...ext) {
    this.index = index;
    this.key = key;
    this.category = category;
    this.label0 = label0;
    this.label1 = label1;
    this.extValues = ext;
  }

  static getKeys() {
    return Object.keys(this);
  }

  static getValues() {
    return Object.values(this);
  }

  static getEntries() {
    return Object.entries(this);
  }

  static getObjByKey(key) {
    return this[key];
  }

  getKey() {
    return this.key;
  }

  getCategory() {
    return this.category;
  }

  getLabel(index = 0) {
    return this[`label${index}`];
  }

  getEXTValues() {
    return this.extValues;
  }
}
