/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x1800",
    "size": "0x800",
    "alignSize": "0x8",
    "sfrs": [
        {
            "name": "CTRL_GATE",
            "alignOffset": "0x0",
            "description": "Clock Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_ENABLE",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Gate Enable for {{name}}\n(This Field is only valid when SFR_ENABLE_ACG = 0)\n0 = Clock output is Disabled\n1 = Clock output is Enabled",
                    "initValue": "0x1",
                    "nc": "SFR_ENABLE"
                },
                {
                    "name": "SFR_ENABLE_ACG",
                    "index": 12,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Auto Clock Gating Enable for {{name}}\n0 = Auto Clock Gating is Disabled\n1 = Auto Clock Gating is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_ACG"
                },
                {
                    "name": "SFR_ENABLE_ACTIVE_CTRL",
                    "index": 13,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Manual ACG_ACTIVE Control Enable for {{name}}\n(This Field is only valid when SFR_ENABLE_ACG = 1)\n0 = Manual ACG_ACTIVE Control is Disabled (ACG_ACTIVE is controlled by Child Components)\n1 = Manual ACG_ACTIVE Control is Enabled (ACG_ACTIVE is controlled by SFR_ACG_ACTIVE)",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_ACTIVE_CTRL"
                },
                {
                    "name": "SFR_ACG_ACTIVE",
                    "index": 14,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Manual ACG_ACTIVE Control for {{name}}\n(This Field is only valid when SFR_ENABLE_ACG = 1 and SFR_ENABLE_ACTIVE_CTRL = 1)\n0 = Set ACG_ACTIVE to 0\n1 = Set ACG_ACTIVE to 1",
                    "initValue": "0x1",
                    "nc": "SFR_ACG_ACTIVE"
                },
                {
                    "name": "SFR_BUSY",
                    "index": 20,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Gate Enable Change Indication for {{name}}\n0 = Gate Enable Change is Done\n1 = Gate Enable Change is in Progress",
                    "initValue": "0x0",
                    "nc": "SFR_BUSY"
                },
                {
                    "name": "SFR_DBG_INFO",
                    "index": 24,
                    "size": 8,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Debug Information",
                    "initValue": "0x0",
                    "nc": "SFR_DBG_INFO"
                }
            ]
        }
    ]
}