import Model from "ITDAModel";
import {
  DomainKeys,
  Item,
  DBSchema,
  ExternalSpecPLL,
  ExternalSpecHM,
  ExternalSpecGeneric,
} from "ITDAModelTypes";
import APIE from "../external";
import store from "@/store";

export const runChecker = Model.runChecker;
export const clearErrors = Model.clearErrors;
export const getErrorSummary = (item: Item): string => {
  let msg = "<< Please Check The Errors >><br/>";
  const msgMap: Record<string, string> = {};
  for (const error of item.getAllErrors()) {
    if (!msgMap[error.getType().getName()]) {
      msgMap[error.getType().getName()] = error.getType().getDescription();
    }
  }
  Object.values(msgMap).forEach((v, idx) => {
    msg += `${idx + 1}) ${v}<br/>`;
  });
  return msg;
};
export const getErrorMsg = (item: Item, html = true) => {
  let msg = `<< Please Check The Errors >>${html ? "<br/>" : ""}`;
  let idx = 1;
  for (const error of item.getAllErrors()) {
    msg += `${idx}) ${error.getDescription()}${html ? "<br/>" : ""}`;
    idx += 1;
  }
  return msg;
};
export const getErrorMessages = Model.getErrorMessages;

export const loadExternalSpec = async () => {
  const pll = await APIE.loadSpec("pll");
  const hm = await APIE.loadSpec("hm");
  const generic = await APIE.loadSpec("generic");
  Model.repo.setExternalSpec(pll, hm, generic);
};

export const getExternalPLLSpec = (): ExternalSpecPLL[] => {
  return Model.repo.getExternalPLLSpec();
};
export const getExternalHMSpec = (): ExternalSpecHM[] => {
  return Model.repo.getExternalHMSpec();
};
export const getExternalGenericSpec = (): ExternalSpecGeneric[] => {
  return Model.repo.getExternalGenericSpec();
};

export const setupTopDesign = Model.setupTopDesign;
export const clearDB = async (
  type: "design" | "config",
  domain?: DomainKeys
) => {
  try {
    store.commit("SET_IN_PROGRESS", "CLEAR DB");
    await Model.clearDB(type, domain);
  } catch (e) {
    console.error(e);
  } finally {
    store.commit("SET_DONE", "CLEAR DB");
  }
};
export const importDB = async (
  domain: DomainKeys,
  data: DBSchema,
  type: "design" | "config"
): Promise<void> => {
  try {
    store.commit("SET_IN_PROGRESS", "IMPORT DB");
    await Model.importDB(domain, data, type);
  } catch (e) {
    console.error(e);
  } finally {
    store.commit("SET_DONE", "IMPORT DB");
  }
};
export const exportDB = Model.exportDB;
export const exportEmptyDB = Model.exportEmptyDB;

export const changeDomain = (domain: string): void => {
  const top = Model.repo.getTopItem();
  if (top) {
    Model.repo.setCurrentDomain(domain as DomainKeys);
    Model.getChildren(top).forEach((o: Item) => {
      o.hidden =
        o.getDomain() && o.getDomain().getKey() === domain ? false : true;
    });
  }
};

export const getParentDiagram = (item: Item): Item | undefined => {
  return Model.getParentDiagram(item);
};

export const getTitle = (item: Item): string => {
  return Model.getTitle(item);
};

export const createSequenceLabelRef = (item: Item): Promise<void> => {
  return Model.createSequenceLabelRef(item);
};

export const copyPowerSequenceDiagram = async (
  configuration: Item,
  targetDiagramId: string
): Promise<void> => {
  await Model.copyPowerSequenceDiagram(configuration, targetDiagramId);
};

export const updateINTRChildrenTitle = (pmdGrp: Item): void => {
  Model.updateINTRChildrenTitle(pmdGrp);
};

export const updatePMR2PMDGRPSEL = (pmdGrp: Item): void => {
  Model.updatePMR2PMDGRPSEL(pmdGrp);
};

export const updateAPMChildren = (apm: Item): void => {
  Model.updateAPMChildren(apm);
};
