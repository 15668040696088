import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodePower from "./ITDANodePower";

export default class ITDAUSERDEFIN extends ITDANodePower {
  constructor(name = "") {
    super(name, NodeType.ITDAUSERDEFIN);
    this.render = ITDANodeRenderer.ITDAUSERDEFIN.render;
  }

  build() {
    const res = super.build();

    const ctrl = this.getCtrlSignal();
    ctrl.newSignal("USERDEFIN", "in");

    return res;
  }
}
