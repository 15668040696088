/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemType from "../ItemType";

export default class PowerSequenceLabelRefEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidItemLabel(), this.lstn_itemLabel);
  }

  lstn_itemLabel(item, newVal, oldVal) {
    var res = [];
    if (newVal) {
      const label = newVal;
      res = [...label.getLabelRefs()];
      if (label.getLabelRefs().findIndex((o) => o === item) < 0) {
        res.push(item);
        label.setLabelRefs(res);
      }
    }
    if (oldVal) {
      const label = oldVal;
      const res = label.getLabelRefs().filter((o) => o !== item);
      label.setLabelRefs(res);
    }
  }

  postProcess(item) {
    return this.setupPorts(item);
  }

  setupPorts(item) {
    return item.newInput({
      key: `SEQREF_${ItemType.Input.getKey().toUpperCase()}`,
      socket: "SEQREF",
      position: "LEFT",
    }).promise;
  }
}
