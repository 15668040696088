<template>
  <div class="no-data">
    <v-img
      min-width="80px"
      max-width="80px"
      min-height="40px"
      max-height="40px"
      src="@/assets/no-data.svg"
      lazy-src="@/assets/no-data.svg"></v-img>
    <span>No {{ props.type }}</span>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
const props = defineProps<{
  type: string;
}>();
</script>

<style lang="sass" scoped>
.no-data
  width: 100%
  display: grid
  place-items: center
  border-radius: 10px
  padding-top: 1.5rem
  padding-bottom: 1rem
  gap: 0.5rem
  color: #d9d9d9
</style>
