import { ItemType, Item } from "../types";
import { ITDASocketType } from "@/canvas/common";

// const repo = Repository.getInstance();

export const getTitle = (item: Item): string => {
  let originItem = item;
  if (
    item.getItemType() === ItemType.SDCPhantom ||
    item.getItemType() === ItemType.SDCPhantomGroup
  ) {
    originItem = item.getOriginItem();
    if (originItem?.getItemType() === ItemType.ClockIPDiagram) {
      return "IP Diagram";
    }
  }

  switch (originItem?.getItemType()) {
    case ItemType.Label: {
      const input = originItem
        .getInputs()
        .find(
          (input: Item) => input.getSocket() === ITDASocketType.CLK.getKey()
        );
      if (input && input.getConnections().length) {
        return getTitle(
          input.getConnections()[0].getSourceOutput().getParent()
        );
      } else {
        return "";
      }
    }
    case ItemType.LabelRef: {
      if (originItem.getType() === "internal") {
        const labelItem = originItem.getItemLabel();
        return labelItem ? getTitle(labelItem) : "";
      } else {
        return originItem.getBoundaryName();
      }
    }
    default:
      return originItem?.getName ? originItem.getName() : "";
  }
};
