import axios from "axios";
import Cookies from "js-cookie";
import { host, protocol } from "./config";

export async function isAuthenticated(): Promise<{ res: boolean; data: any }> {
  const access_token = Cookies.get("access-token");
  try {
    const data = await axios.post(
      `${protocol}://${host}/auth/is_authenticated`,
      null,
      {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        params: {},
        withCredentials: true,
      }
    );
    return { res: true, data: data };
  } catch (err) {
    console.error(err);
    return { res: false, data: err };
  }
}
