import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;
    const arcRadius = Math.min(width, height) / 4;
    const innerStroke = 3;

    const arcGenerator = d3
      .arc<d3.DefaultArcObject>()
      .innerRadius(arcRadius * 0.85)
      .outerRadius(arcRadius)
      .startAngle(Math.PI / 1.3)
      .endAngle(-Math.PI / 1.3);

    g.append("path")
      .attr(
        "d",
        arcGenerator({
          innerRadius: arcRadius * 0.8,
          outerRadius: arcRadius,
          startAngle: Math.PI / 1.3,
          endAngle: -Math.PI / 1.3,
        })
      )
      .attr("transform", `translate(${centerX}, ${centerY + 6}) scale(1,-1)`)
      .attr("fill", "black")
      .attr("stroke", "black")
      .attr("stroke-width", 1);

    g.append("line")
      .attr("x1", centerX)
      .attr("y1", (centerY / 4) * 2 + 1)
      .attr("x2", centerX)
      .attr("y2", (centerY / 4) * 4)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke + 1);

    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 1.5)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("PWREN");
  },
};
