import ItemEvent from "@/model/src/ItemEvent";

export default class ClockDiagramFolder extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
  }
  lstn_name(item, newVal, oldVal) {
    console.debug(item, newVal, oldVal);
  }
}
