'use strict';

export default class ConnectionType {

    static CLK      = new ConnectionType(0, "CLK", "slategray")
    static QCHCtrl  = new ConnectionType(0, "QCHCtrl", "green", 8, "QCH Ctrl")
    static SFRIF    = new ConnectionType(1, "SFRIF", "blue")
    static CLINK    = new ConnectionType(2, "CLINK", "red")
    static PLINK    = new ConnectionType(3, "PLINK", "blueviolet")

    static SEQ      = new ConnectionType(3, "SEQ", "black")
    static SEQLabel = new ConnectionType(3, "SEQLabel", "gray", 4)

    static PWRCTRL  = new ConnectionType(3, "PWRCTRL", "black", 8)
    
    constructor(index, key, color, dash=0, label="") {
        this.index  = index
        this.key    = key
        this.color  = color
        this.dash   = dash
        this.label  = label ? label : this.key
    }

    static getKeys() {
        return Object.keys(this)
    }

    static getValues() {
        return Object.values(this)
    }

    static getEntries() {
        return Object.entries(this)
    }
    
    static getObjByKey(key) {
        return this[key]
    }

    getIndex() {
        return this.index
    }

    getKey() {
        return this.key
    }
    
    getColor() {
        return this.color
    }

    getDash() {
        return this.dash
    }

    getLabel() {
        return this.label
    }
}
