/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import ClockPLLCtrlEXTEvent from '@/model/src/events/ClockPLLCtrlEXTEvent'
import ClockPLLCtrlEXTChecker from '@/model/src/checkers/ClockPLLCtrlEXTChecker'
import ITDA_CMU_PLL_EXT_PT from '@/model/gen/sfrSpec/ITDA_CMU_PLL_EXT_PT'
export default class ClockPLLCtrlEXT extends Item {
    FID_CLOCKPLLCTRLEXT_POWERDOWNMODE = 0
    FID_CLOCKPLLCTRLEXT_CUSTOMMODE = 1
    FID_CLOCKPLLCTRLEXT_NAME = 2
    FID_CLOCKPLLCTRLEXT_LAST = 2

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockPLLCtrlEXT"}))
        
        this.setEvent(new ClockPLLCtrlEXTEvent(this))
        this.setChecker(new ClockPLLCtrlEXTChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.powerDownMode === undefined) {
        this.properties.powerDownMode = false
    }
    if (this.properties.customMode === undefined) {
        this.properties.customMode = false
    }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockPLLCtrlEXT"
    }

    getSchema() {
        return {"powerDownMode": {"type": "boolean", "label": "Use PWRDOWN", "desc": "Use Power Down", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "customMode": {"type": "boolean", "label": "Use CUSTOM", "desc": "Use Custom Ctrl", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "name": {"type": "string", "hidden": true, "default": "NONAME", "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"powerDownMode": {"type": "boolean", "label": "Use PWRDOWN", "desc": "Use Power Down", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "customMode": {"type": "boolean", "label": "Use CUSTOM", "desc": "Use Custom Ctrl", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "name": {"type": "string", "hidden": true, "default": "NONAME", "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_CMU_PLL_EXT_PT
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_CMU_PLL_EXT_PT
        }
    }

    setPowerDownMode(powerDownMode, event=true) {
        if ((powerDownMode !== null) && (powerDownMode !== undefined)) {
            if (![Boolean].includes(powerDownMode.constructor)) {
                throw `powerDownMode(${powerDownMode}) must be [Boolean] type`
            }
        }
        const newVal = (powerDownMode === null || powerDownMode === undefined) ? undefined : powerDownMode
        const oldVal = this.properties.powerDownMode
        this.properties.powerDownMode = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKPLLCTRLEXT_POWERDOWNMODE, newVal, oldVal)
        }
    }
    getPowerDownMode(pure = false) {
        return this.properties.powerDownMode
        
    }
    getFidPowerDownMode() {
        return this.FID_CLOCKPLLCTRLEXT_POWERDOWNMODE
    }
    

    setCustomMode(customMode, event=true) {
        if ((customMode !== null) && (customMode !== undefined)) {
            if (![Boolean].includes(customMode.constructor)) {
                throw `customMode(${customMode}) must be [Boolean] type`
            }
        }
        const newVal = (customMode === null || customMode === undefined) ? undefined : customMode
        const oldVal = this.properties.customMode
        this.properties.customMode = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKPLLCTRLEXT_CUSTOMMODE, newVal, oldVal)
        }
    }
    getCustomMode(pure = false) {
        return this.properties.customMode
        
    }
    getFidCustomMode() {
        return this.FID_CLOCKPLLCTRLEXT_CUSTOMMODE
    }
    

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKPLLCTRLEXT_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKPLLCTRLEXT_NAME
    }
    

    getFidLast() {
        return this.FID_CLOCKPLLCTRLEXT_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKPLLCTRLEXT_POWERDOWNMODE:
                return "powerDownMode"

            case this.FID_CLOCKPLLCTRLEXT_CUSTOMMODE:
                return "customMode"

            case this.FID_CLOCKPLLCTRLEXT_NAME:
                return "name"

            default:
                return ""
        }
    }


}