'use strict';

export default class Domain {

    static Clock        = new Domain(0, "Clock", "Clock")
    static Power        = new Domain(1, "Power", "Power")
    static Logical      = new Domain(2, "Logical", "DUT")
    static Physical     = new Domain(3, "Physical", "Voltage")
    static SDC          = new Domain(4, "SDC", "SDC")
    static UPF          = new Domain(5, "UPF", "UPF")
    
    constructor(index, key, label="") {
        this.index = index
        this.key = key
        this.label = label
    }

    static getKeys() {
        return Object.keys(this)
    }

    static getValues() {
        return Object.values(this)
    }

    static getEntries() {
        return Object.entries(this)
    }
    
    static getObjByKey(key) {
        return this[key]
    }

    getKey() {
        return this.key
    }

    getLabel() {
        return this.label
    }
}
