import { Position } from "rete-connection-reroute-plugin/_types/types";

// Get mid point
function getMidPoint(a: Position, b: Position): Position {
  return {
    x: (a.x + b.x) / 2,
    y: (a.y + b.y) / 2,
  };
}

// Create custom ITDAConnectionPath
export function createITDAConnectionPath(
  points: Position[],
  curvature: number,
  pathStyle: "curve" | "line" = "line"
): { pathSegments: string[]; midPoints: Position[] } {
  if (points.length < 2) {
    throw new Error("At least two points are required to create a path.");
  }

  const pathSegments: string[] = [];
  const midPoints: Position[] = [];

  for (let i = 1; i < points.length; i++) {
    const a = points[i - 1];
    const b = points[i];
    let pathSegment = "";

    if (pathStyle === "line") {
      pathSegment = `M ${a.x} ${a.y} L ${b.x} ${b.y}`;
    } else if (pathStyle === "curve") {
      pathSegment = `M ${a.x} ${a.y} C ${a.x + (b.x - a.x) * curvature} ${
        a.y
      }, ${b.x - (b.x - a.x) * curvature} ${b.y}, ${b.x} ${b.y}`;
    }

    pathSegments.push(pathSegment);

    const midPoint = getMidPoint(a, b);
    midPoints.push(midPoint);
  }

  return { pathSegments, midPoints };
}
