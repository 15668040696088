import ItemEvent from "@/model/src/ItemEvent";

export default class Input extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidKey(), this.lstn_name);
  }

  lstn_name(item, newVal, oldVal) {
    console.debug(item, newVal, oldVal);
  }
}
