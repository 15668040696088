/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import UPFSRAMEvent from '@/model/src/events/UPFSRAMEvent'
import UPFSRAMChecker from '@/model/src/checkers/UPFSRAMChecker'
export default class UPFSRAM extends Node {
    FID_UPFSRAM_NAME = 100
    FID_UPFSRAM_LAST = 100

    constructor(obj) {
        super(Object.assign(obj,{itemType:"UPFSRAM"}))
        
        this.setEvent(new UPFSRAMEvent(this))
        this.setChecker(new UPFSRAMChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "SRAM_NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "UPFSRAM"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "SRAM_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "SRAM_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "UPFMapperPowerSource", "auto": false, "searchable": false, "title": "Power Supply Info", "headers": [{"title": "Power Supply", "value": "powerSource", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFSRAM_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_UPFSRAM_NAME
    }
    

    getFidLast() {
        return this.FID_UPFSRAM_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_UPFSRAM_NAME:
                return "name"

            default:
                return ""
        }
    }


    newUPFMapperPowerSource(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFMapperPowerSource", properties, title, domain)
    }
    remUPFMapperPowerSource(obj) {
        return this.removeChild(obj)
    }
    getUPFMapperPowerSources() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFMapperPowerSource")
            return res
        })
    }
}