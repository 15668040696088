/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import ClockPLLEvent from '@/model/src/events/ClockPLLEvent'
import ClockPLLChecker from '@/model/src/checkers/ClockPLLChecker'
import ITDA_HM_CMU_PLL from '@/model/gen/sfrSpec/ITDA_HM_CMU_PLL'
export default class ClockPLL extends Item {
    FID_CLOCKPLL_NAME = 0
    FID_CLOCKPLL_PLLTYPE = 1
    FID_CLOCKPLL_LAST = 1

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockPLL"}))
        
        this.setEvent(new ClockPLLEvent(this))
        this.setChecker(new ClockPLLChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = ""
    }
    if (this.properties.plltype === undefined) {
        this.properties.plltype = ""
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockPLL"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "plltype": {"type": "string", "label": "PLL Type", "default": "", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "plltype": {"type": "string", "label": "PLL Type", "default": "", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_HM_CMU_PLL
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_HM_CMU_PLL
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKPLL_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKPLL_NAME
    }
    

    setPlltype(plltype, event=true) {
        if ((plltype !== null) && (plltype !== undefined)) {
            if (![String].includes(plltype.constructor)) {
                throw `plltype(${plltype}) must be [String] type`
            }
        }
        const newVal = (plltype === null || plltype === undefined) ? undefined : plltype
        const oldVal = this.properties.plltype
        this.properties.plltype = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKPLL_PLLTYPE, newVal, oldVal)
        }
    }
    getPlltype(pure = false) {
        return this.properties.plltype
        
    }
    getFidPlltype() {
        return this.FID_CLOCKPLL_PLLTYPE
    }
    

    getFidLast() {
        return this.FID_CLOCKPLL_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKPLL_NAME:
                return "name"

            case this.FID_CLOCKPLL_PLLTYPE:
                return "plltype"

            default:
                return ""
        }
    }


}