import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodePower from "./ITDANodePower";

export default class ITDATIMEOUT extends ITDANodePower {
  constructor(name = "") {
    super(name, NodeType.ITDATIMEOUT);
    this.render = ITDANodeRenderer.ITDATIMEOUT.render;
  }
}
