<template>
  <label :for="props.props.propKey" class="label">{{
    isTitle ? getTitle() : label
  }}</label>
</template>

<script setup lang="ts">
import Utils from "@/components/item/utils";
import { ItemPropsType } from "ITDAModelTypes";
import { defineProps } from "vue";

const props = defineProps<{ props: ItemPropsType; isTitle: boolean }>();
const label = props.props.table
  ? undefined
  : Utils.getLabel(props.props.propKey, props.props.prop)
      .slice(0, 1)
      .toUpperCase() +
    Utils.getLabel(props.props.propKey, props.props.prop).slice(1);

const getTitle = () => {
  const originalTitle = Utils.getRefItemTitle(
    props.props.item,
    props.props.propKey,
    props.props.prop,
    props.props.config
  );
  return originalTitle
    .split(" ")
    .map((word) => word.slice(0, 1).toUpperCase() + word.slice(1))
    .join(" ");
};
</script>

<style lang="sass" scoped>
.label
  width: 100%
  display: block
  text-align: left
  font-size: 0.7rem
  color: gray
  padding-left: 2px
</style>
