import { Context } from "../types";
import API from "@/api/internal";

export const noderemoved = async (context: Context): Promise<boolean> => {
  const itemID = context.options.node.itemID;
  const item = API.getItem(itemID);
  if (item) {
    await API.removeItem(item.getId());
  }
  return true;
};
