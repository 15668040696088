/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x0000",
    "size": "0x800",
    "alignSize": "0x40",
    "sfrs": [
        {
            "name": "CTRL_PLLMUX",
            "alignOffset": "0x0",
            "description": "Clock Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_SELECT",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "MUX Selection for {{name}}\n0 = Select CLKIN0 which is Reference Clock\n1 = Select CLKIN1 which is Function Clock",
                    "initValue": "0x0",
                    "nc": "SFR_SELECT"
                },
                {
                    "name": "SFR_ENABLE_ACG",
                    "index": 12,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Auto Clock Gating Enable for {{name}}\n0 = Auto Clock Gating is Disabled\n1 = Auto Clock Gating is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_ACG"
                },
                {
                    "name": "SFR_BUSY",
                    "index": 20,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "MUX Selection Change Indication for {{name}}\n0 = MUX Selection Change is Done\n1 = MUX Selection Change is in Progess",
                    "initValue": "0x0",
                    "nc": "SFR_BUSY"
                },
                {
                    "name": "SFR_DBG_INFO",
                    "index": 24,
                    "size": 8,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Debug Information",
                    "initValue": "0x0",
                    "nc": "SFR_DBG_INFO"
                }
            ]
        }
    ]
}