import * as ITDAControl from "../controls";
import * as ITDANodeRenderer from "./renderers";
import { NodeType } from "../types";
import ITDANodeClock from "./ITDANodeClock";

export default class ITDANMDIVIDER extends ITDANodeClock {
  constructor(name = "") {
    super(name, NodeType.ITDANMDIVIDER);
    this.render = ITDANodeRenderer.ITDANMDIVIDER.render;
  }

  async data(
    inputs: Record<`CLK_INPUT`, any>
  ): Promise<Record<`CLK_OUTPUT`, any>> {
    const freq = this.controls.freq as ITDAControl.ITDAControlFrequency;
    let frequency = this.getFrequencyInitValue();
    if (Object.keys(inputs).length !== 0) {
      const inputFreq = inputs.CLK_INPUT[0];
      // const item = API.getItem(this.getItemID());
      // const configElements = API.getCMUConfigurationElements(item);
      // if (configElements.length) {
      //   const numeratorElement = configElements.filter(
      //     (c) => c.getProp() === "numerator"
      //   );
      //   let numerator = numeratorElement.length
      //     ? numeratorElement[0].getValue()
      //     : 0;
      //   numerator = Number(numerator) + 1;

      //   const denominatorElement = configElements.filter(
      //     (c) => c.getProp() === "denominator"
      //   );
      //   let denominator = denominatorElement.length
      //     ? denominatorElement[0].getValue()
      //     : 0;
      //   denominator = 2 ** (Number(denominator) + 4);
      // }
      frequency = inputFreq;
    }
    if (frequency) {
      freq.setFrequency(frequency);
      this.update();
    }
    return { CLK_OUTPUT: freq.getFrequency() };
  }
}
