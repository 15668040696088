/**
 *  Generated by Modeler
 *  timestamp: 2023-04-05 20:24:58.495167
 */
// import ErrorType from '@/model/ErrorType'
import ItemChecker from "@/model/src/ItemChecker";

export default class UPFElementChecker extends ItemChecker {
  constructor(item) {
    super(item);
    // this.addChecker(item.getFidName(), this.checkName, ErrorType.POWER_DIAGRAM)
  }
  // checkName(item) {
  //     if (!item.getName()) {
  //         this.newError(`Name is null.`, item)
  //     }
  // }
}
