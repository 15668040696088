// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";
import "@/styles/settings.scss";
// import colors from "vuetify/util/colors";

// Vuetify
import { createVuetify, type ThemeDefinition } from "vuetify";
import { VTreeview } from "vuetify/labs/VTreeview";

const customTheme: ThemeDefinition = {
  colors: {
    error: "#FF5252", //255 82 82
    // error: "#D50000",
  },
};

export default createVuetify({
  components: {
    VTreeview,
  },
  theme: {
    defaultTheme: "customTheme",
    themes: {
      customTheme,
    },
  },
  // theme: {
  //   themes: {
  //     light: {
  //       dark: false,
  //       colors: {
  //         primary: colors.red.darken1, // #E53935
  //         secondary: colors.red.lighten4, // #FFCDD2
  //       },
  //     },
  //   },
  // },
  // https://vuetifyjs.com/en/styles/colors/#material-colors
});
// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
