<template>
  <div ref="background" class="background">
    <div v-if="showGrid" ref="gridCanvas" class="grid-canvas"></div>
    <div ref="selection" class="selection"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, defineProps } from "vue";
import Utils from "./utils";

const props = defineProps<{
  width: number;
  height: number;
  gridSpace: number;
  gridStroke: number;
  showGrid: boolean;
  renderGrid: (
    gridCanvas: HTMLElement,
    width: number,
    height: number,
    gridSpace: number,
    gridStroke: number
  ) => void;
}>();

const background = ref<HTMLElement | null>(null);
const selection = ref<HTMLElement | null>(null);
const gridCanvas = ref<HTMLElement | null>(null);

onMounted(() => {
  if (background.value && selection.value) {
    new Utils.AreaSelection(
      background.value,
      selection.value,
      props.width,
      props.height
    );
  }

  if (gridCanvas.value && props.showGrid) {
    if (gridCanvas.value.firstChild) {
      gridCanvas.value?.removeChild(gridCanvas.value.firstChild);
    }
    props.renderGrid(
      gridCanvas.value,
      props.width,
      props.height,
      props.gridSpace,
      props.gridStroke
    );
  }
});
</script>

<style lang="css" scoped>
.background {
  opacity: 1;
  background-size: 62px 62px;
  background-position: 0 0, 31px 31px;
  z-index: -1;
  overflow: auto;
}

.grid-canvas {
  background-color: transparent;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.selection {
  position: absolute;
  border: 1px dashed #000;
  background-color: rgba(0, 0, 255, 0.2);
  display: none;
}
</style>
