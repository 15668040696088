import { ItemType } from "ITDAModelTypes";
import { Item, Property } from "ITDAModelTypes";
import { getValue, isDisabled } from "./ItemProperty";
import API from "@/api";

export const isDisabledTextarea = (
  item: Item,
  key: string,
  prop: Property
): boolean => {
  const disabled = isDisabled(item, key, prop);
  switch (item.getItemType()) {
    case ItemType.SFR:
      if (prop.type === "textarea") {
        return is_custom_sfr_type(item) ? false : disabled;
      } else {
        return true;
      }
    case ItemType.SFRField:
      if (prop.type === "textarea") {
        return is_custom_sfr_type(item.getParent()) ? false : disabled;
      } else {
        return true;
      }
    default:
      return false || disabled;
  }
};

const is_custom_sfr_type = (item: Item) => {
  switch (item.getType()) {
    case "INFORM":
    case "PMR_INFORM":
    case "CTRL_USERDEFIN":
    case "CTRL_USERDEFOUT":
      return true;
    default:
      return false;
  }
};

export const getDescription = (item: Item, propKey: string): string => {
  let res = getValue(item, propKey) as string;
  res = res ? res.replaceAll("{{name}}", getDisplayName(item)) : "";
  if (item.getItemType() === ItemType.SFRField) {
    switch (item.getParent().getItemRef().getItemType()) {
      case ItemType.ClockMultiplexer:
        if (item.getName() === "SFR_SELECT") {
          const num = Number(
            item.getParent().getItemRef().getNumberOfParents()
          );
          for (let i = 0; i < num; i++) {
            res = res.concat(`\n${i} = Select CLKIN${i}`);
          }
        }
        break;
      case ItemType.ClockMultiplexerEXT:
        if (item.getName() === "SFR_SELECT_THROTTLE") {
          const num = Number(
            item.getParent().getItemRef().getParent().getNumberOfParents()
          );
          for (let i = 0; i < num; i++) {
            res = res.concat(`\n${i} = Select CLKIN${i}`);
          }
        }
        break;
      default:
        break;
    }
    if (item.getNameReset()) {
      if (!res.includes(`This Field is only reset at ${item.getNameReset()}`)) {
        res = res.concat(
          `\nThis Field is only reset at ${item.getNameReset()}`
        );
      }
    }
  }
  return res;
};

const getDisplayName = (item: Item): string => {
  switch (item.getItemType()) {
    case ItemType.SFR: {
      const nodeItem = item.getItemRef();
      return API.getTitle(nodeItem);
    }
    case ItemType.SFRField: {
      const nodeItem = item.getParent().getItemRef();
      return API.getTitle(nodeItem);
    }
    default:
      return "";
  }
};
