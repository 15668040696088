import Canvas from "@/canvas";
import API from "@/api/internal";
import { Context } from "../types";
import { ITDANode } from "@/canvas/nodes";
import { Domain, Item } from "ITDAModelTypes";
import ITDAEditor from "@/canvas/ITDAEditor";

export const runDataFlow = async (context: Context) => {
  const domain = API.getCurrentDomain();
  if (domain === Domain.Clock.getKey() || domain === Domain.SDC.getKey()) {
    const conn = context.options.data;
    const editor = Canvas.getEditor();
    const source = editor.getNode(conn.source);
    const target = editor.getNode(conn.target);
    if (needToDataFlow(source, target, editor)) {
      Canvas.getDataFlowPlugin().process();
    }
  }
};

const needToDataFlow = (
  source: ITDANode,
  target: ITDANode,
  editor: ITDAEditor
) => {
  if (source.parent && target.parent) {
    const sdcPhantomGroupNode = editor?.getNode(source.parent);
    let isConfig = false;
    if (sdcPhantomGroupNode && sdcPhantomGroupNode.parent) {
      const sdcNode = editor?.getNode(sdcPhantomGroupNode.parent);
      if (sdcNode) {
        const sdcItemId = sdcNode.getItemID();
        const sdcItem = API.getItem(sdcItemId);
        const voltagetConfig = sdcItem
          ? API.getSelectedVoltageConfiguration(sdcItem)
          : undefined;
        if (voltagetConfig) {
          const sdcFreqConfig = voltagetConfig
            .getConfigurationElements()
            .find((e: Item) => e.getItemRef().getId() === sdcItemId);
          isConfig = sdcFreqConfig ? true : false;
        }
      }
    }
    return isConfig;
  }

  if (source) {
    const item = API.getItem(source.getItemID());
    return item && API.getSelectedCMUConfiguration(item) ? true : false;
  }
  if (target) {
    const item = API.getItem(target.getItemID());
    return item && API.getSelectedCMUConfiguration(item) ? true : false;
  }
};
