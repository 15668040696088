import { getTitle } from "../utils/title";
import ItemHandler from "./ItemHandler";
import ItemType from "./ItemType";
import LabelType from "./LabelType";

export default class InstanceHandler {
  static createGFMUXPorts(item, numOfParents) {
    if (!item) {
      return;
    }
    let res = [];
    for (let i = 0; i < numOfParents; i++) {
      res.push(InstanceHandler.createGFMUXPort(item, i));
    }
    return res;
  }

  static createGFMUXPort(item, index) {
    if (!item) {
      return;
    }
    return ItemHandler.createItem(item, ItemType.Input, {
      key: `CLK_${ItemType.Input.getKey().toUpperCase()}_${index}`,
      socket: "CLK",
    }).promise;
  }

  static removeGFMUXPort(item, index) {
    if (!item) {
      return;
    }
    const key = `CLK_${ItemType.Input.getKey().toUpperCase()}_${index}`;
    InstanceHandler.removeInput(item, key);
  }

  static createPMDGRPPorts(item) {
    if (!item) {
      return;
    }
    for (let i = 0; i < Number(item.getNumOfPMDs()); i++) {
      InstanceHandler.createPMDGRPPort(item, i);
    }
  }

  static createPMDGRPPort(item, index) {
    if (!item) {
      return;
    }
    return ItemHandler.createItem(
      item,
      ItemType.Output,
      {
        name: `PMD${index}`,
        key: `PWRCTRL_${ItemType.Output.getKey().toUpperCase()}_${index}`,
        socket: "PWRCTRL",
        position: "RIGHT",
        multiple: false,
      },
      "",
      item.domain
    );
  }

  static removePMDGRPPort(instance, index) {
    if (!instance) {
      return;
    }
    const key = `PWRCTRL_${ItemType.Output.getKey().toUpperCase()}_${index}`;
    return InstanceHandler.removeOutput(instance, key);
  }

  static createPMD2PMDGRP(instance, pmd) {
    if (!instance || !pmd) {
      return;
    }
    ItemHandler.createItem(instance, ItemType.Input, {
      key: `PWRCTRL_${ItemType.Input.getKey().toUpperCase()}`,
      socket: "PWRCTRL",
    });
  }

  static createSFRIFPorts(instance, cmu) {
    if (!instance || !cmu) {
      return;
    }
    if (cmu.getItemType() !== ItemType.ClockCMU) {
      return;
    }
    if (cmu.getExternalAsyncType() === "SFRIF TX") {
      for (let i = 0; i < 8; i++) {
        // CanvasUtils.createOutput(instance, `EXT_SFRIF${i}`, ConnectionType.SFRIF.getKey())
        ItemHandler.createItem(instance, ItemType.Output, {
          name: `EXT_SFRIF${i}`,
          key: `SFRIF_${ItemType.Output.getKey().toUpperCase()}_${i}`,
          socket: "SFRIF",
          position: "RIGHT",
          multiple: false,
        });
      }
    } else if (cmu.getExternalAsyncType() === "SFRIF RX") {
      // CanvasUtils.createInput(instance, "EXT_SFRIF", ConnectionType.SFRIF.getKey())
      ItemHandler.createItem(instance, ItemType.Input, {
        name: `EXT_SFRIF`,
        key: `SFRIF_${ItemType.Input.getKey().toUpperCase()}`,
        socket: "SFRIF",
        position: "LEFT",
      });
    }
  }

  static createCLKPorts(instance, cmu) {
    if (!instance || !cmu) {
      return;
    }
    if (cmu.getItemType() !== ItemType.ClockCMU) {
      return;
    }

    cmu
      .getClockDiagramFolder()
      .getClockDiagrams()
      .forEach((diagram) => {
        diagram
          .getLabels()
          .filter((obj) => obj.getType() === LabelType.EXTERNAL.getKey())
          .forEach((obj) => {
            const input = obj
              .getInputs()
              .find((input) => input.getSocket() === "CLK");
            if (input) {
              input.getConnections().forEach((conn) => {
                ItemHandler.createItem(instance, ItemType.Output, {
                  name: getTitle(obj),
                  key: `CLK_${ItemType.Output.getKey().toUpperCase()}_${obj.getId()}`,
                  socket: "CLK",
                  position: "RIGHT",
                  multiple: true,
                });
              });
            }
          });
        diagram
          .getLabelRefs()
          .filter((obj) => obj.getType() === LabelType.EXTERNAL.getKey())
          .forEach((obj) => {
            const output = obj
              .getOutputs()
              .find((output) => output.getSocket() === "CLK");
            if (output) {
              ItemHandler.createItem(instance, ItemType.Input, {
                name: getTitle(obj),
                key: `CLK_${ItemType.Input.getKey().toUpperCase()}_${obj.getId()}`,
                socket: "CLK",
                position: "LEFT",
              });
            }
          });
      });
  }

  static createCLINKPorts(instance, module) {
    if (!instance || !module) {
      return;
    }
    if (module.getItemType() === ItemType.ClockCMU) {
      // CanvasUtils.createInput(instance, "CLINK", ConnectionType.CLINK.getKey())
      ItemHandler.createItem(instance, ItemType.Input, {
        name: "CLINK",
        key: `CLINK_${ItemType.Input.getKey().toUpperCase()}`,
        socket: "CLINK",
        position: "LEFT",
      });
    }
  }

  static createPLINKPorts(instance, module) {
    if (!instance || !module) {
      return;
    }
    let index = 0;
    module
      .getPowerDiagramFolder()
      .getPowerDiagrams()
      .forEach((diagram) => {
        diagram.getPowerPMDInstances().forEach((pmdInstance) => {
          if (pmdInstance.getModule().getPMIFInPMC() === true) {
            pmdInstance
              .getModule()
              .getPowerDiagramFolder()
              .getPowerDiagrams()
              .reduce((acc, cur) => {
                acc = acc.concat(cur.getPowerCLINKs());
                return acc;
              }, [])
              .forEach((clink) => {
                ItemHandler.createItem(instance, ItemType.Output, {
                  name: `${pmdInstance.getName()}/${clink.getName()}`,
                  key: `CLINK_${ItemType.Output.getKey().toUpperCase()}_${index}`,
                  socket: "CLINK",
                  position: "RIGHT",
                  multiple: false,
                });
                index += 1;
              });
            return;
          }

          instance.newOutput({
            name: pmdInstance.getName(),
            key: `PLINK_${ItemType.Output.getKey().toUpperCase()}_${index}`,
            socket: "PLINK",
            position: "RIGHT",
            multiple: false,
          });
          index += 1;
        });
      });
  }

  // static createPLINKPorts(instance, pmc) {
  //   if (!instance) {
  //     return;
  //   }

  //   // if (pmc && pmc.getItemType() === ItemType.PowerPMC) {
  //   //     ConnectionHandler.getConnections(pmc, ConnectionType.SFRIF)
  //   //         .forEach(conn => {
  //   //             let master = ItemHandler.getRefItem(conn.getMaster())
  //   //             let slave = ItemHandler.getRefItem(conn.getSlave())
  //   //             if (master && slave) {
  //   //                 let pmdInst = slave.getParent()
  //   //                 CanvasUtils.createOutput(instance, `${pmdInst.getId()}`, ConnectionType.PLINK.getKey())
  //   //             }
  //   //         })
  //   // } else {
  //   //     CanvasUtils.createInput(instance, "PLINK", ConnectionType.PLINK.getKey())
  //   // }
  // }

  // static createPCUPorts(instance, module) {
  //   if (!instance || !module) {
  //     return;
  //   }
  //   // module.getSFRBlock().getSFRs()
  //   //     .reduce((acc, cur) => {
  //   //         let pwrcomp = ItemHandler.getRefItem(cur.getItemRef())
  //   //         if (this._is_pcu_port_target(pwrcomp)) {
  //   //             acc.push(pwrcomp)
  //   //         }
  //   //         return acc
  //   //     }, [])
  //   //     .forEach(pwrcomp => {
  //   //         NodeHandler.getOutputSignals(pwrcomp).forEach(obj => {
  //   //             CanvasUtils.createOutput(instance, `${pwrcomp.getId()}__${obj.name}`, ConnectionType.PWRCTRL.getKey())
  //   //         })
  //   //         NodeHandler.getInputSignals(pwrcomp).forEach(obj => {
  //   //             CanvasUtils.createOutput(instance, `${pwrcomp.getId()}__${obj.name}`, ConnectionType.PWRCTRL.getKey())
  //   //         })
  //   //     })
  // }

  // static _is_pcu_port_target(pwrcomp) {
  //   if (!pwrcomp) {
  //     return false;
  //   }

  //   if (pwrcomp.getItemType() === ItemType.PowerPSW) {
  //     return true;
  //   }
  //   if (pwrcomp.getItemType() === ItemType.PowerISOEN) {
  //     return true;
  //   }
  //   if (pwrcomp.getItemType() === ItemType.PowerRETENTION) {
  //     return true;
  //   }
  //   if (pwrcomp.getItemType() === ItemType.PowerMEM) {
  //     return true;
  //   }
  //   return false;
  // }

  static removeAllPorts(instance) {
    InstanceHandler.removeInputs(instance);
    InstanceHandler.removeOutputs(instance);
  }

  static removeInput(instance, key) {
    const input = instance.getInputs().find((input) => input.getKey() === key);
    if (input) {
      // input.getConnections().forEach((conn) => ItemHandler.removeItem(conn));
      return ItemHandler.removeItem(input);
    }
  }

  static removeInputs(instance) {
    const promises = [];
    instance.getInputs().forEach((input) => {
      // input.getConnections().forEach((conn) => ItemHandler.removeItem(conn));
      promises.push(ItemHandler.removeItem(input));
    });
    return Promise.all(promises);
  }

  static removeOutput(instance, key) {
    const output = instance
      .getOutputs()
      .find((output) => output.getKey() === key);
    if (output) {
      // output.getConnections().forEach((conn) => ItemHandler.removeItem(conn));
      return ItemHandler.removeItem(output);
    }
  }

  static removeOutputs(instance) {
    const promises = [];
    instance.getOutputs().forEach((output) => {
      // output.getConnections().forEach((conn) => ItemHandler.removeItem(conn));
      promises.push(ItemHandler.removeItem(output));
    });
    return Promise.all(promises);
  }

  static async createUPFPhantom(item, originItem, itemType) {
    const { item: cItem, promise } = ItemHandler.createItem(item, itemType, {
      originItem: originItem.getId(),
    });
    await promise;
    originItem.setPhantom(cItem.getId());
  }

  static async removeUPFPhantoms(item) {
    const promises = [];
    for (const phantom of item.getUPFPhantomPowerSources()) {
      promises.push(ItemHandler.removeItem(phantom));
    }
    for (const phantom of item.getUPFPhantomGrounds()) {
      promises.push(ItemHandler.removeItem(phantom));
    }
    await Promise.all(promises);
  }

  // static async createPhantom(item, originItem) {
  //   const { item: cItem, promise } = ItemHandler.createItem(
  //     item,
  //     ItemType.DUTPhantom,
  //     {
  //       originItem: originItem.getId(),
  //     }
  //   );
  //   await promise;
  //   originItem.setPhantom(cItem.getId());

  //   const promises = [];
  //   for (const port of originItem.getInputs()) {
  //     promises.push(cItem.newInput(port.properties).promise);
  //   }
  //   for (const port of originItem.getOutputs()) {
  //     promises.push(cItem.newOutput(port.properties).promise);
  //   }
  //   await Promise.all(promises);

  //   if (
  //     originItem.getItemType() === ItemType.DUTPhantom ||
  //     originItem.getItemType() === ItemType.DUTInstance
  //   ) {
  //     const phantoms = originItem.getDUTPhantoms();
  //     for (const phantom of phantoms) {
  //       await this.createPhantom(cItem, phantom);
  //     }
  //   }
  // }

  // static async removePhantoms(phantoms) {
  //   const promises = [];
  //   for (const phantom of phantoms) {
  //     promises.push(ItemHandler.removeItem(phantom));
  //   }
  //   await Promise.all(promises);
  // }

  // static async createPhantomConnection(item, diagram) {
  //   const conns = diagram.getConnections();
  //   for (const conn of conns) {
  //     const sourceOutput = conn.getSourceOutput();
  //     const targetInput = conn.getTargetInput();
  //     if (sourceOutput && targetInput) {
  //       const source = sourceOutput.getParent();
  //       const target = targetInput.getParent();
  //       const sourcePT = source.getPhantom();
  //       const targetPT = target.getPhantom();
  //       if (sourcePT && targetPT) {
  //         const outputPT = sourcePT
  //           .getOutputs()
  //           .find((output) => output.getKey() === sourceOutput.getKey());
  //         const inputPT = targetPT
  //           .getInputs()
  //           .find((input) => input.getKey() === targetInput.getKey());
  //         const { item: connectionItem, promise } = ItemHandler.createItem(
  //           item.getParent().getParent(),
  //           ItemType.Connection,
  //           {
  //             sourceOutput: outputPT.getId(),
  //             targetInput: inputPT.getId(),
  //           }
  //         );
  //         await promise;
  //       }
  //     }
  //   }
  // }

  static async createCLINKOutputs(pmif, pmc, outputName) {
    for (const diagram of pmc.getPowerDiagramFolder().getPowerDiagrams()) {
      const pmdInst = diagram
        .getPowerPMDInstances()
        .find((inst) => inst.getName() === outputName);
      if (pmdInst) {
        const pmd = pmdInst.getModule();
        for (const pmdDiagram of pmd
          .getPowerDiagramFolder()
          .getPowerDiagrams()) {
          let index = 0;
          for (const clink of pmdDiagram.getPowerCLINKs()) {
            if (
              !pmif
                .getOutputs()
                .find((output) => output.getName() === clink.getName())
            ) {
              await pmif.newOutput({
                name: clink.getName(),
                key: `CLINK_${ItemType.Output.getKey().toUpperCase()}_${index}`,
                socket: "CLINK",
                multiple: false,
              }).promise;
              index += 1;
            }
          }
        }
      }
    }
  }
}
