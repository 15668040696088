import ItemEvent from "@/model/src/ItemEvent";

export default class SFRFieldEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
    this.addPropertyListener(item.getFidIndex(), this.lstn_index);
    this.addPropertyListener(item.getFidSize(), this.lstn_size);

    this.addPropertyListener(item.getFidLast() + 20, this.lstn_title);
    // this.addPropertyListener(item.getFidAccess(), this.lstn_access)

    this.addPropertyListener(item.getFidLast() + 1, this.lstn_pmusub_name);
  }
  lstn_name(item) {
    item.getEvent().updateFieldLabel(item);
  }
  lstn_index(item) {
    item.getEvent().updateFieldLabel(item);
  }
  lstn_size(item) {
    item.getEvent().updateFieldLabel(item);
  }
  // lstn_access(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
  updateFieldLabel(item) {
    var range = `[${item.getSize() + item.getIndex() - 1}:${item.getIndex()}]`;
    item.setTitle(`${item.getName()}${range}`);
  }

  lstn_pmusub_name(item, newVal, oldVal) {
    const strs = item.getName().split(oldVal);
    item.setName(`${strs[0]}${newVal}${strs[1]}`);
  }

  async lstn_title(item, newVal, oldVal) {
    await item.setName(newVal);
  }
}
