/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemType from "../ItemType";
import Utils from "./utils";

export default class ClockIPInputEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
    this.addPropertyListener(item.getFidGated(), this.lstn_gated);
    this.addPropertyListener(item.getFidShared(), this.lstn_shared);
  }

  lstn_name(item, newVal) {
    const ipItem = item.getParent();
    item.getConnections().forEach((conn) => {
      const gate = conn.getSourceOutput().getParent();
      if (gate.getItemType() === ItemType.ClockGate) {
        gate.setName(`${ipItem.getName()}__${newVal}`);
      }
    });
  }

  lstn_gated(item, newVal) {
    if (newVal) {
      return Utils.insertGate(item);
    } else {
      return Utils.removeGate(item);
    }
  }

  lstn_shared(item, newVal, oldVal) {
    return Utils.updateShared(item, newVal, oldVal);
  }

  postProcess(item) {
    item.setKey(
      `CLK_${ItemType.ClockIPInput.getKey().toUpperCase()}_${item.getId()}`
    );
    item.setSocket("CLK");
  }
}
