import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    if (!color) {
      return;
    }
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const gap = 3;

    g.append("line")
      .attr("x1", gap)
      .attr("y1", gap)
      .attr("x2", width - gap)
      .attr("y2", height - gap)
      .attr("stroke", "black")
      .attr("stroke-width", 2);

    g.append("line")
      .attr("x1", gap)
      .attr("y1", height - gap)
      .attr("x2", width - gap)
      .attr("y2", gap)
      .attr("stroke", "black")
      .attr("stroke-width", 2);
  },
};
