import {
  ScopesPlugin,
  Presets as ScopesPresets,
  Props,
} from "rete-scopes-plugin";
import { Size } from "rete-scopes-plugin/_types/types";
import { ITDACanvasType, ITDACommon } from "./types";
import { NodeType } from "../types";
import ITDACanvas from "../ITDACanvas";
import { NodeId } from "rete";

export default class ITDAScopesPlugin extends ITDACanvas {
  private customProps: Props;
  constructor(id: string) {
    super(id, ITDACanvasType.Scopes);
    this.customProps = this.getProps.bind(this)();
    this.res = new ScopesPlugin<ITDACommon.Schemes>(this.customProps);
    // this.res.addPreset(ScopesPresets.classic.setup());
  }
  getInstance(): ScopesPlugin<ITDACommon.Schemes> {
    return this.res as ScopesPlugin<ITDACommon.Schemes>;
  }

  update(id: NodeId): Promise<void> {
    return this.getInstance().update(id);
  }

  private getProps(): Props {
    const editor = this.getRepository().getEditor(this.id);
    return {
      padding: () => ({
        top: 100,
        left: 100,
        right: 100,
        bottom: 100,
      }),
      exclude: (id: NodeId) => {
        const node = editor.getInstance().getNode(id);
        if (node.parent) {
          const parent = editor.getInstance().getNode(node.parent);
          switch (parent.getType()) {
            case NodeType.ITDAINSTANCECMU:
            case NodeType.ITDAINSTANCEPMC:
            case NodeType.ITDAINSTANCEPMIF:
            case NodeType.ITDAMODULEUPF:
            case NodeType.ITDAINSTANCEPOWERDOMAIN:
            case NodeType.ITDAINSTANCEHARDMACRO:
              return true;
            default:
              return false;
          }
        } else {
          return false;
        }
      },
      size: (id: NodeId, size: Size) => {
        const node = editor.getInstance().getNode(id);
        let width;
        let height;

        switch (node.getType()) {
          case NodeType.ITDAMODULEUPF:
            width = node.width;
            height = node.height;
            break;
          default:
            width = size.width;
            height = size.height;
            break;
        }

        return {
          width: Math.max(width, node.getType().getWidth()),
          height: Math.max(height, node.getType().getHeight()),
        };
      },
    };
  }
}
