import API from "@/api/internal";
import { ITDASocketType } from "@/canvas/common";
import { ItemType, Item } from "ITDAModelTypes";
import Canvas from "@/canvas";
import { NodeType } from "@/canvas/types";

export const createDefaultSequence = async (diagram: Item) => {
  let obj, nodeMetaView;
  obj = await createStartResetAndLabelRef(diagram, "START_RESET");
  nodeMetaView = obj.reset.getNodeMetaView();
  nodeMetaView.setX(-300);
  nodeMetaView.setY(-450);
  nodeMetaView = obj.labelRef.getNodeMetaView();
  nodeMetaView.setX(-300 + 120 + 60.5);
  nodeMetaView.setY(-450);

  const name =
    diagram.getItemRef()?.getItemType() === ItemType.PowerPMDInstance
      ? "START_IRQ"
      : "START_NMI";
  obj = await createStartResetAndLabelRef(diagram, name);
  nodeMetaView = obj.reset.getNodeMetaView();
  nodeMetaView.setX(300);
  nodeMetaView.setY(-450);
  nodeMetaView = obj.labelRef.getNodeMetaView();
  nodeMetaView.setX(300 + 120 + 60.5);
  nodeMetaView.setY(-450);
};

const createStartResetAndLabelRef = async (
  diagram: Item,
  name: string
): Promise<{ reset: Item; labelRef: Item }> => {
  const reset = await API.createItem(
    diagram,
    ItemType.PowerSequenceStart.getKey()
  );
  const labelRef = await API.createItem(
    diagram,
    ItemType.PowerSequenceLabelRef.getKey()
  );
  reset.setName(name);

  await API.createItem(diagram, ItemType.Connection.getKey(), {
    sourceOutput: getSEQREFOutput(reset).getId(),
    targetInput: getSEQREFInput(labelRef).getId(),
  });
  return {
    reset: reset,
    labelRef: labelRef,
  };
};

const getSEQREFOutput = (item: Item): Item => {
  return item
    .getOutputs()
    .find(
      (output: Item) =>
        output.getKey() ===
        `${ITDASocketType.SEQREF.getKey()}_${ItemType.Output.getKey().toUpperCase()}`
    );
};

const getSEQREFInput = (item: Item): Item => {
  return item
    .getInputs()
    .find(
      (input: Item) =>
        input.getKey() ===
        `${ITDASocketType.SEQREF.getKey()}_${ItemType.Input.getKey().toUpperCase()}`
    );
};

export const translateUpSequenceGroup = async (
  item: Item,
  x: number,
  y: number
) => {
  let res: Promise<boolean>[] = [];

  const input = item
    .getInputs()
    .find((input: Item) => input.getSocket() === ITDASocketType.SEQ.getKey());
  if (input) {
    input.getConnections().forEach((conn: Item) => {
      const seq = conn.getSourceOutput().getParent();
      const node = Canvas.getEditor().getNode(seq.getNodeID());

      if (node && !node.isLocked()) {
        res = [...res, ...translateUpSequence(seq, x, y - node.height - 38)];
      }
    });
  }

  await Promise.all(res);
};

const translateUpSequence = (
  item: Item,
  x: number,
  y: number
): Promise<boolean>[] => {
  let res: Promise<boolean>[] = [
    ...translateChildren(item),
    translateSequence(item, x, y),
  ];

  const input = item
    .getInputs()
    .find((input: Item) => input.getSocket() === ITDASocketType.SEQ.getKey());
  if (input) {
    input.getConnections().forEach((conn: Item) => {
      const seq = conn.getSourceOutput().getParent();
      const node = Canvas.getEditor().getNode(seq.getNodeID());
      if (node && !node.isLocked()) {
        res = [...res, ...translateUpSequence(seq, x, y - node.height - 38)];
      }
    });
  }

  return res;
};

export const translateDownSequenceGroup = async (
  item: Item,
  x: number,
  y: number
) => {
  let res: Promise<boolean>[] = [];
  const output = item
    .getOutputs()
    .find((output: Item) => output.getSocket() === ITDASocketType.SEQ.getKey());
  if (output) {
    output.getConnections().forEach((conn: Item) => {
      const seq = conn.getTargetInput().getParent();
      const node = Canvas.getEditor().getNode(seq.getNodeID());
      if (node && !node.isLocked()) {
        res = [...res, ...translateDownSequence(seq, x, y + node.height + 38)];
      }
    });
  }

  await Promise.all(res);
};

const translateDownSequence = (
  item: Item,
  x: number,
  y: number
): Promise<boolean>[] => {
  let res: Promise<boolean>[] = [
    ...translateChildren(item),
    translateSequence(item, x, y),
  ];
  const output = item
    .getOutputs()
    .find((output: Item) => output.getSocket() === ITDASocketType.SEQ.getKey());
  if (output) {
    output.getConnections().forEach((conn: Item) => {
      const seq = conn.getTargetInput().getParent();
      const node = Canvas.getEditor().getNode(seq.getNodeID());
      if (node && !node.isLocked()) {
        res = [...res, ...translateDownSequence(seq, x, y + node.height + 38)];
      }
    });
  }

  return res;
};

export const translateChildren = (item: Item): Promise<boolean>[] => {
  const node = Canvas.getEditor()?.getNode(item.getNodeID());
  const area = Canvas.getAreaPlugin();
  const metaView = item.getNodeMetaView();
  const res: Promise<boolean>[] = [];

  const input = item
    .getInputs()
    .find(
      (input: Item) => input.getSocket() === ITDASocketType.SEQREF.getKey()
    );
  if (input) {
    input.getConnections().forEach((conn: Item) => {
      const label = conn.getSourceOutput().getParent();
      const nodeView = area.getNodeView(label.getNodeID());
      const nodeLabel = Canvas.getEditor()?.getNode(label.getNodeID());
      if (nodeView) {
        res.push(
          nodeView.translate(
            metaView.getX() - nodeLabel.width - 60.5,
            metaView.getY()
          )
        );
      }
    });
  }

  let output = item
    .getOutputs()
    .find(
      (output: Item) => output.getSocket() === ITDASocketType.SEQREF.getKey()
    );
  if (output) {
    output.getConnections().forEach((conn: Item) => {
      const labelRef = conn.getTargetInput().getParent();
      const nodeView = area.getNodeView(labelRef.getNodeID());
      if (nodeView) {
        res.push(
          nodeView.translate(
            metaView.getX() + node.width + 60.5,
            metaView.getY()
          )
        );
      }
    });
  }

  output = item
    .getOutputs()
    .find((output: Item) => output.getSocket() === ITDASocketType.SEQ.getKey());
  if (output) {
    output.getConnections().forEach((conn: Item) => {
      const back = conn.getTargetInput().getParent();
      if (back.getItemType() === ItemType.PowerSequenceBack) {
        const nodeView = area.getNodeView(back.getNodeID());
        if (nodeView) {
          res.push(
            nodeView.translate(
              metaView.getX(),
              metaView.getY() + node.height + 38
            )
          );
        }
      }
    });
  }
  return res;
};

const translateSequence = (
  item: Item,
  x: number,
  y: number
): Promise<boolean> => {
  const node = Canvas.getEditor().getNode(item.getNodeID());
  const nodeView = node?.getNodeView();
  if (nodeView) {
    return nodeView?.translate(x, y);
  } else {
    return new Promise((resolve) => resolve(true));
  }
};
