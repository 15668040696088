import * as dft from "./dft";
import { TableType } from "../types";

export default (type: TableType) => {
  switch (type) {
    case TableType.DFT:
      return dft;
    default:
      return null;
  }
};
