import EventHandler from "@/model/src/EventHandler";
import Repository from "../store";

export default class Mapper {
  constructor(type, source, target) {
    this.type = type;
    this.sourceId = source.getId();
    this.targetId = target.getId();
    source.addMapper(this);
    target.addMapper(this);
  }

  static getSFRMapHeaders() {
    return [
      {
        text: "SFR",
        filterable: true,
        value: "name",
      },
      {
        text: "Field",
        filterable: true,
        value: "field",
      },
      {
        text: "Index",
        value: "index",
      },
      {
        text: "Size",
        value: "size",
      },
    ];
  }

  getType() {
    return this.type;
  }

  getSource() {
    return Repository.getInstance().getItemByID(this.sourceId);
    // return store.getters["item/GET_ID_TO_ITEM_MAP"][this.sourceId];
    //return this.source
  }
  getTarget() {
    return Repository.getInstance().getItemByID(this.targetId);
    // return store.getters["item/GET_ID_TO_ITEM_MAP"][this.targetId];
    //return this.target
  }

  //toTitleCase(str) {
  //    return str.replace(
  //        /\w\S*/g,
  //        function(txt) {
  //            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  //        }
  //    );
  //}
  toTitleCase(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  addPropertyRefListener(prop, refProp) {
    var fid =
      prop.constructor === Number
        ? prop
        : this.getSource()[`getFid${this.toTitleCase(prop)}`]();
    var rFid =
      refProp.constructor === Number
        ? refProp
        : this.getTarget()[`getFid${this.toTitleCase(refProp)}`]();
    EventHandler.addPropertyRefListener(
      this.sourceId,
      fid,
      this.targetId,
      rFid
    );
    //EventHandler.addPropertyRefListener(this.source, fid, this.target, rFid)
  }
  addArrayRefListener(prop, refProp, type) {
    var fid =
      prop.constructor === Number
        ? prop
        : this.getSource()[`getFid${this.toTitleCase(prop)}`]();
    var rFid =
      refProp.constructor === Number
        ? refProp
        : this.getTarget()[`getFid${this.toTitleCase(refProp)}`]();
    EventHandler.addArrayRefListener(
      this.sourceId,
      fid,
      this.targetId,
      rFid,
      type
    );
    //EventHandler.addArrayRefListener(this.source, fid, this.target, rFid, type)
  }
}
