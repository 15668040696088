import {
  HistoryExtensions,
  HistoryPlugin,
  Presets as HistoryPresets,
} from "rete-history-plugin";
import { ITDACanvasType, ITDACommon } from "./types";
import ITDACanvas from "../ITDACanvas";

export default class ITDAHistoryPlugin extends ITDACanvas {
  constructor(id: string) {
    super(id, ITDACanvasType.History);
    this.res = new HistoryPlugin<ITDACommon.Schemes>();
    HistoryExtensions.keyboard(this.res);
    this.res.addPreset(HistoryPresets.classic.setup());
  }
  getInstance(): HistoryPlugin<ITDACommon.Schemes> {
    return this.res as HistoryPlugin<ITDACommon.Schemes>;
  }
}
