/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PhantomEvent from '@/model/src/events/PhantomEvent'
import PhantomChecker from '@/model/src/checkers/PhantomChecker'
export default class Phantom extends Node {
    FID_PHANTOM_ORIGINITEM = 100
    FID_PHANTOM_LAST = 100

    constructor(obj) {
        super(Object.assign(obj,{itemType:"Phantom"}))
        
        this.setEvent(new PhantomEvent(this))
        this.setChecker(new PhantomChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "Phantom"
    }

    getSchema() {
        return {"originItem": {"type": "item", "multiple": false, "hidden": true, "category": "IP", "readOnly": false}}
    }

    static getSchema() {
        return {"originItem": {"type": "item", "multiple": false, "hidden": true, "category": "IP", "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setOriginItem(originItem, event=true) {
        if ((originItem !== null) && (originItem !== undefined)) {
            if (!(originItem instanceof Item) && (originItem.constructor !== String)) {
                throw `originItem(${originItem}) must be Item or String type`
            }
        }
        const newVal = (originItem === null || originItem === undefined) ? undefined : (originItem.constructor === String) ? originItem : originItem.getId()
        const oldVal = this.properties.originItem
        this.properties.originItem = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_PHANTOM_ORIGINITEM, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getOriginItem(pure = false) {
        return pure ? this.properties.originItem : this.getRefItem(this.properties.originItem)
        
    }
    getFidOriginItem() {
        return this.FID_PHANTOM_ORIGINITEM
    }
    

    getFidLast() {
        return this.FID_PHANTOM_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_PHANTOM_ORIGINITEM:
                return "originItem"

            default:
                return ""
        }
    }


}