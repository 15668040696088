import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;

    g.append("line")
      .attr("x1", (centerX / 4) * 1.5)
      .attr("y1", (centerY / 4) * 2.4)
      .attr("x2", (centerX / 4) * 6.5)
      .attr("y2", (centerY / 4) * 2.4)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("polygon")
      .attr(
        "points",
        `${(centerX / 4) * 1.1},${(centerY / 4) * 2.4} ${(centerX / 4) * 1.8},${
          (centerY / 4) * 2
        } ${(centerX / 4) * 1.8},${(centerY / 4) * 2.8}`
      )
      .attr("fill", "black");

    g.append("line")
      .attr("x1", (centerX / 4) * 1.5)
      .attr("y1", (centerY / 4) * 6.4)
      .attr("x2", (centerX / 4) * 6.5)
      .attr("y2", (centerY / 4) * 6.4)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("polygon")
      .attr(
        "points",
        `${(centerX / 4) * 6.9},${(centerY / 4) * 6.4} ${(centerX / 4) * 6.2},${
          (centerY / 4) * 6.8
        } ${(centerX / 4) * 6.2},${(centerY / 4) * 6}`
      )
      .attr("fill", "black");

    // Draw square in the center
    g.append("rect")
      .attr("x", (centerX / 4) * 2.5)
      .attr("y", (centerY / 4) * 2.9)
      .attr("width", (centerX / 4) * 3)
      .attr("height", (centerY / 4) * 3)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    // Draw inner arrow inside the square
    g.append("line")
      .attr("x1", (centerX / 4) * 3.3 - 1)
      .attr("y1", (centerY / 4) * 4.4 + 1.5)
      .attr("x2", (centerX / 4) * 4.8)
      .attr("y2", (centerY / 4) * 3.5)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("line")
      .attr("x1", (centerX / 4) * 3.3 - 1)
      .attr("y1", (centerY / 4) * 4.4 + 1)
      .attr("x2", (centerX / 4) * 4.8)
      .attr("y2", (centerY / 4) * 5.2)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    // PMRCTRL text
    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 1.5)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("PMRCTRL");
  },
};
