interface ComponentState {
  PROPERTY: {
    SCHEMA: number;
    CHILDREN: number;
  };
  HEADER_SYSTEM: {
    DOMAIN: number;
  };
  NAVIGATION_RIGHT: {
    COMPONENT: number;
    PROPERTY: number;
    CONFIGURATION: number;
    SFR: number;
  };
}

const state = (): ComponentState => ({
  PROPERTY: {
    SCHEMA: 0,
    CHILDREN: 0,
  },
  HEADER_SYSTEM: {
    DOMAIN: 0,
  },
  NAVIGATION_RIGHT: {
    COMPONENT: 0,
    PROPERTY: 0,
    CONFIGURATION: 0,
    SFR: 0,
  },
});

const mutations = {
  UPDATE_PROP_SCHEMA(state: ComponentState) {
    state.PROPERTY.SCHEMA += 1;
  },
  UPDATE_PROP_CHILDREN(state: ComponentState) {
    state.PROPERTY.CHILDREN += 1;
  },
  UPDATE_NAV_RIGHT_COMPONENT(state: ComponentState) {
    state.NAVIGATION_RIGHT.COMPONENT += 1;
  },
  UPDATE_NAV_RIGHT_PROPERTY(state: ComponentState) {
    state.NAVIGATION_RIGHT.PROPERTY += 1;
  },
  UPDATE_NAV_RIGHT_CONFIGURATION(state: ComponentState) {
    state.NAVIGATION_RIGHT.CONFIGURATION += 1;
  },
  UPDATE_NAV_RIGHT_SFR(state: ComponentState) {
    state.NAVIGATION_RIGHT.SFR += 1;
  },
  ALTER_HEADER_SYSTEM_DOMAIN_KEY(state: ComponentState) {
    state.HEADER_SYSTEM.DOMAIN += 1;
  },
  RESET_HEADER_SYSTEM_DOMAIN_KEY(state: ComponentState) {
    state.HEADER_SYSTEM.DOMAIN = 0;
  },
};
const getters = {
  GET_PROP_SCHEMA(state: ComponentState) {
    return state.PROPERTY.SCHEMA;
  },
  GET_PROP_CHILDREN(state: ComponentState) {
    return state.PROPERTY.CHILDREN;
  },
  GET_NAV_RIGHT_COMPONENT(state: ComponentState) {
    return state.NAVIGATION_RIGHT.COMPONENT;
  },
  GET_NAV_RIGHT_PROPERTY(state: ComponentState) {
    return state.NAVIGATION_RIGHT.PROPERTY;
  },
  GET_NAV_RIGHT_CONFIGURATION(state: ComponentState) {
    return state.NAVIGATION_RIGHT.CONFIGURATION;
  },
  GET_NAV_RIGHT_SFR(state: ComponentState) {
    return state.NAVIGATION_RIGHT.SFR;
  },
  GET_HEADER_SYSTEM_DOMAIN_KEY(state: ComponentState) {
    return state.HEADER_SYSTEM.DOMAIN;
  },
};
const actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
