/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";
import Repository from "../../store";

export default class ClockPLLCtrlChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
    //this.addChecker(item.getFidMaxNum(), this.checkMaxNum, ErrorType.NAME)
    //this.addChecker(item.getFidECC(), this.checkECC, ErrorType.NAME)
    this.addChecker(item.getFidPlltype(), this.checkPlltype, ErrorType.PLL);
    //this.addChecker(item.getFidSDCPath(), this.checkSDCPath, ErrorType.NAME)
  }
  checkName() {
    // TBD
  }
  checkMaxNum() {
    // TBD
  }
  checkECC() {
    // TBD
  }
  checkPlltype(item) {
    if (item.getPlltype()) {
      const pllSpec = Repository.getInstance().getExternalPLLSpec();
      if (!pllSpec || !pllSpec.find((spec) => spec.name === item.getPlltype())) {
        this.newError(`${item.getPlltype()} PLL type must be changed`);
      }
    } else {
      this.newError(`PLL type must be selected`);
    }
  }
  checkSDCPath() {
    // TBD
  }
}
