import Model from "ITDAModel";
import {
  ChildSpec,
  ItemSchema,
  Item,
  ItemViewSpec,
  ItemConfigSchema,
} from "ITDAModelTypes";

export default {
  getSchema: (item: Item): ItemSchema => {
    return Model.getSchema(item);
  },
  getItemViewSpec: (item: Item): ItemViewSpec => {
    return Model.getViewSpec(item);
  },
  getChildrenSpec: (item: Item): ChildSpec[] => {
    return Model.getChildrenSpec(item);
  },
  getConfigSchema: (item: Item): ItemConfigSchema[] => {
    return Model.getConfigSchema(item);
  },
};
