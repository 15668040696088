/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemType from "../ItemType";

export default class PowerSequenceBackEvent extends ItemEvent {
  constructor(item) {
    super(item);
  }

  postProcess(item) {
    return this.setupPorts(item);
  }

  setupPorts(item) {
    return item.newInput({
      key: `SEQ_${ItemType.Input.getKey().toUpperCase()}`,
      socket: "SEQ",
      position: "TOP",
    }).promise;
  }
}
