import * as ITDAControl from "../controls";
import * as ITDANodeRenderer from "./renderers";
import { NodeType } from "../types";
import ITDANodeClock from "./ITDANodeClock";

export default class ITDAFREQMON extends ITDANodeClock {
  constructor(name = "") {
    super(name, NodeType.ITDAFREQMON);
    this.render = ITDANodeRenderer.ITDAFREQMON.render;
  }

  async data(
    inputs: Record<`CLK_INPUT`, any>
  ): Promise<Record<`CLK_OUTPUT`, undefined>> {
    const freq = this.controls.freq as ITDAControl.ITDAControlFrequency;
    const frequency = inputs.CLK_INPUT
      ? inputs.CLK_INPUT[0]
      : this.getFrequencyInitValue();
    if (frequency) {
      freq.setFrequency(frequency);
      this.update();
    }
    return { CLK_OUTPUT: undefined };
  }
}
