<template>
  <div ref="wrapper" class="dock-wrapper" :style="style">
    <v-icon
      icon="mdi-drag-vertical-variant"
      class="dock-drag"
      @pointerdown="onPointerDown"></v-icon>
    <v-container id="conditionalDockBox" class="dock"></v-container>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUpdated, computed } from "vue";
import { useStore } from "vuex";
import Canvas from "@/canvas";

const store = useStore();

const style = computed(() => {
  return {
    left: `${posX.value}px`,
    top: `${posY.value}px`,
  };
});

const wrapper = ref<HTMLElement>();
const posX = ref(0);
const posY = ref(0);
const offset = {
  x: 0,
  y: 0,
};
const onPointerDown = (e: PointerEvent) => {
  offset.x = e.clientX - posX.value;
  offset.y = e.clientY - posY.value;

  window.addEventListener("pointermove", onPointerMove);
  window.addEventListener("pointerup", onPointerUp);
};

const onPointerMove = (e: PointerEvent) => {
  posX.value = e.clientX - offset.x;
  posY.value = e.clientY - offset.y;
};

const onPointerUp = (e: PointerEvent) => {
  window.removeEventListener("pointermove", onPointerMove);
  window.removeEventListener("pointerup", onPointerUp);
};

onMounted(() => {
  render();
  if (wrapper.value) {
    setTimeout(() => {
      const parentRect = wrapper.value?.parentElement?.getBoundingClientRect();
      const rect = wrapper.value?.getBoundingClientRect();
      if (parentRect && rect) {
        posX.value = parentRect.width * 0.5 - rect.width * 0.5;
        posY.value = parentRect.height - rect.height - rect.height * 0.25;
      }
    }, 100);
  }
});
onUpdated(() => render());

const render = () => {
  const item = store.getters["item/GET_CURRENT_ITEM"];
  if (item) {
    const dock = Canvas.getDockPlugin(item.getId());
    if (dock) {
      dock.render();
    }
  }
};
</script>

<style lang="css" scoped>
.dock-wrapper {
  position: absolute;
  display: flex;
  flex-direction: row;
  background: white;
  border: 0.5px solid lightgray;
  border-radius: 11px;
  filter: drop-shadow(-1px 5px 3px rgba(0, 0, 0, 0.2));
}
.dock-drag {
  width: 24px;
  height: inherit;
  min-height: 60px;
  cursor: move;
  color: gray;
  /* zoom: 1.2; */
  background: #fafafa;
  border-top-left-radius: 11px;
  border-bottom-left-radius: 11px;
}
.dock {
  //position: absolute;
  bottom: 4%;
  //left: 27.5%;
  //width: 45%;
  width: 100%;
  height: inherit;
  min-height: 100%;

  background: white;
  /* border: 1px solid lightgray; */
  border-top-right-radius: 11px;
  border-bottom-right-radius: 11px;
  //filter: drop-shadow(-1px 5px 3px rgba(0, 0, 0, 0.2));
  z-index: 9999;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1.5rem;

  padding: 0 1rem 0 1rem;
  opacity: 0.9;

  overflow-x: auto;
}
</style>
