/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import PowerSequenceDiagramEvent from '@/model/src/events/PowerSequenceDiagramEvent'
import PowerSequenceDiagramChecker from '@/model/src/checkers/PowerSequenceDiagramChecker'
export default class PowerSequenceDiagram extends Item {
    FID_POWERSEQUENCEDIAGRAM_NAME = 0
    FID_POWERSEQUENCEDIAGRAM_TYPE = 1
    FID_POWERSEQUENCEDIAGRAM_ITEMREF = 2
    FID_POWERSEQUENCEDIAGRAM_COPIED = 3
    FID_POWERSEQUENCEDIAGRAM_LAST = 3

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerSequenceDiagram"}))
        
        this.setEvent(new PowerSequenceDiagramEvent(this))
        this.setChecker(new PowerSequenceDiagramChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    (this.view.diagram = true), (this.view.table = false)
    if (this.properties.name === undefined) {
        this.properties.name = "SEQ_NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerSequenceDiagram"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "SEQ_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "type": {"type": "enum", "targets": ["ROM", "RAM"], "readOnly": true, "multiple": false, "category": "IP", "hidden": false}, "itemRef": {"type": "item", "label": "referenced instance", "desc": "referenced instance", "hidden": true, "category": "IP", "multiple": false, "readOnly": false}, "copied": {"type": "item", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "SEQ_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "type": {"type": "enum", "targets": ["ROM", "RAM"], "readOnly": true, "multiple": false, "category": "IP", "hidden": false}, "itemRef": {"type": "item", "label": "referenced instance", "desc": "referenced instance", "hidden": true, "category": "IP", "multiple": false, "readOnly": false}, "copied": {"type": "item", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "Connection", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "PowerSequenceStart", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "PowerSequenceWrite", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "PowerSequenceReadWait", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "PowerSequenceWait", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "PowerSequenceIf", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "PowerSequenceGoto", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "PowerSequenceCall", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "PowerSequenceEnd", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "PowerSequenceBack", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "PowerSequenceLabel", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "PowerSequenceLabelRef", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCEDIAGRAM_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERSEQUENCEDIAGRAM_NAME
    }
    

    setType(type, event=true) {
        if ((type !== null) && (type !== undefined)) {
            if (![String].includes(type.constructor)) {
                throw `type(${type}) must be [String] type`
            }
        }
        const newVal = (type === null || type === undefined) ? undefined : type
        const oldVal = this.properties.type
        this.properties.type = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCEDIAGRAM_TYPE, newVal, oldVal)
        }
    }
    getType(pure = false) {
        return this.properties.type
        
    }
    getFidType() {
        return this.FID_POWERSEQUENCEDIAGRAM_TYPE
    }
    

    setItemRef(itemRef, event=true) {
        if ((itemRef !== null) && (itemRef !== undefined)) {
            if (!(itemRef instanceof Item) && (itemRef.constructor !== String)) {
                throw `itemRef(${itemRef}) must be Item or String type`
            }
        }
        const newVal = (itemRef === null || itemRef === undefined) ? undefined : (itemRef.constructor === String) ? itemRef : itemRef.getId()
        const oldVal = this.properties.itemRef
        this.properties.itemRef = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCEDIAGRAM_ITEMREF, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getItemRef(pure = false) {
        return pure ? this.properties.itemRef : this.getRefItem(this.properties.itemRef)
        
    }
    getFidItemRef() {
        return this.FID_POWERSEQUENCEDIAGRAM_ITEMREF
    }
    

    setCopied(copied, event=true) {
        if ((copied !== null) && (copied !== undefined)) {
            if (!(copied instanceof Item) && (copied.constructor !== String)) {
                throw `copied(${copied}) must be Item or String type`
            }
        }
        const newVal = (copied === null || copied === undefined) ? undefined : (copied.constructor === String) ? copied : copied.getId()
        const oldVal = this.properties.copied
        this.properties.copied = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCEDIAGRAM_COPIED, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getCopied(pure = false) {
        return pure ? this.properties.copied : this.getRefItem(this.properties.copied)
        
    }
    getFidCopied() {
        return this.FID_POWERSEQUENCEDIAGRAM_COPIED
    }
    

    getFidLast() {
        return this.FID_POWERSEQUENCEDIAGRAM_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERSEQUENCEDIAGRAM_NAME:
                return "name"

            case this.FID_POWERSEQUENCEDIAGRAM_TYPE:
                return "type"

            case this.FID_POWERSEQUENCEDIAGRAM_ITEMREF:
                return "itemRef"

            case this.FID_POWERSEQUENCEDIAGRAM_COPIED:
                return "copied"

            default:
                return ""
        }
    }


    newConnection(properties={}, title = "", domain = "") {
        return this.createChild(this, "Connection", properties, title, domain)
    }
    remConnection(obj) {
        return this.removeChild(obj)
    }
    getConnections() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "Connection")
            return res
        })
    }
    newPowerSequenceStart(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceStart", properties, title, domain)
    }
    remPowerSequenceStart(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceStarts() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerSequenceStart")
            return res
        })
    }
    newPowerSequenceWrite(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceWrite", properties, title, domain)
    }
    remPowerSequenceWrite(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceWrites() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerSequenceWrite")
            return res
        })
    }
    newPowerSequenceReadWait(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceReadWait", properties, title, domain)
    }
    remPowerSequenceReadWait(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceReadWaits() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerSequenceReadWait")
            return res
        })
    }
    newPowerSequenceWait(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceWait", properties, title, domain)
    }
    remPowerSequenceWait(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceWaits() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerSequenceWait")
            return res
        })
    }
    newPowerSequenceIf(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceIf", properties, title, domain)
    }
    remPowerSequenceIf(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceIfs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerSequenceIf")
            return res
        })
    }
    newPowerSequenceGoto(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceGoto", properties, title, domain)
    }
    remPowerSequenceGoto(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceGotos() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerSequenceGoto")
            return res
        })
    }
    newPowerSequenceCall(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceCall", properties, title, domain)
    }
    remPowerSequenceCall(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceCalls() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerSequenceCall")
            return res
        })
    }
    newPowerSequenceEnd(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceEnd", properties, title, domain)
    }
    remPowerSequenceEnd(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceEnds() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerSequenceEnd")
            return res
        })
    }
    newPowerSequenceBack(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceBack", properties, title, domain)
    }
    remPowerSequenceBack(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceBacks() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerSequenceBack")
            return res
        })
    }
    newPowerSequenceLabel(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceLabel", properties, title, domain)
    }
    remPowerSequenceLabel(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceLabels() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerSequenceLabel")
            return res
        })
    }
    newPowerSequenceLabelRef(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceLabelRef", properties, title, domain)
    }
    remPowerSequenceLabelRef(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceLabelRefs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerSequenceLabelRef")
            return res
        })
    }
}