import axios from "axios";
import { host, protocol } from "./config";

export const getLicenses = async (): Promise<object> => {
  try {
    const res = await axios.get(`${protocol}://${host}/auth/get_license`);
    if (!res || res.status !== 200) {
      return {};
    }
    return res && res.status === 200 ? res.data : undefined;
  } catch (err) {
    console.log(err);
    return {};
  }
};
