/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class PowerSequenceConfigurationEvent extends ItemEvent {
  constructor(item) {
    super(item);
  }

  postProcess(item) {
    item.setTitle(item.getType(), false);
  }
}
