export default class ITDAControlSignal {
  private name: string;
  private direction: string;
  constructor(name: string, direction: string) {
    this.name = name;
    this.direction = direction;
  }

  setName(name: string) {
    this.name = name;
  }

  getName(): string {
    return this.name;
  }

  setDirection(direction: string) {
    this.direction = direction;
  }

  getDirection(): string {
    return this.direction;
  }
}
