import { toRaw } from "vue";
import ItemHandler from "./ItemHandler";
import ItemType from "./ItemType";
import Repository from "../store";
// import { NIcon } from "naive-ui";
// import { Folder } from "@vicons/ionicons5";

export default class Item {
  static base = new Date().getTime();
  static jitter = 0;
  static emit = ({ type, options }) => new Promise((resolve) => resolve(true));

  FID_TITLE = 900;
  FID_CHILDREN = 905;
  FID_DISABLED = 910;
  FID_SORTED = 915;
  FID_HIDDENCHILDREN = 920;

  event = null;
  checker = null;

  constructor({
    pid,
    id,
    itemType,
    title,
    view = { diagram: false, table: false },
    properties,
    domain = "",
    disabled,
    sorted,
    hidden,
    hiddenChildren,
    category,
  }) {
    this.pid = pid;
    this.id = id ? id : (new Date().getTime() + Item.jitter).toString();
    Item.jitter += 1;
    this.itemType = itemType;
    this.title = title;
    this.view = view;
    this.properties = Object.assign({}, properties);
    this.domain = this.getDomain() ? this.getDomain().getKey() : domain;
    this._configuration = {};
    this.disabled = disabled;
    this.sorted = sorted;
    this.hidden = hidden;
    this.hiddenChildren = hiddenChildren;
    this.category = category;
    this._preItem = null;
    this._nextItem = null;
    this._firstChild = null;
    this._lastChild = null;
    this._children = {};
    this._mirroredChildren = [];
    this._mappers = [];

    this._referencedChildren = {};

    this._errorMap = {};

    Repository.getInstance().addItem(this);
  }

  // prefix() {
  //   return h(NIcon, null, {
  //     default: () => h(Folder),
  //   });
  // }

  toRaw(item) {
    return toRaw(item);
  }

  static getClassName() {
    return "Item";
  }

  getChildrenSpec() {
    return [];
  }

  getFidTitle() {
    return this.FID_TITLE;
  }

  getFidChildren() {
    return this.FID_CHILDREN;
  }

  getFidDisabled() {
    return this.FID_DISABLED;
  }

  getFidSorted() {
    return this.FID_SORTED;
  }

  getFidHiddenChildren() {
    return this.FID_HIDDENCHILDREN;
  }

  setEvent(event) {
    this.event = event;
  }

  getEvent() {
    return this.event;
  }

  setChecker(checker) {
    this.checker = checker;
  }

  getChecker() {
    return this.checker;
  }

  addMapper(mapper) {
    this._mappers.push(mapper);
  }

  getMappers() {
    return this._mappers;
  }

  clearMappers() {
    this._mappers = [];
  }

  getPid() {
    return this.pid;
  }

  // getRefItems(type) {
  //   // return Object.values(store.getters['item/GET_ID_TO_ITEM_MAP']).filter(item => item.getItemType() === type)
  //   return Object.values(repo.getIdToItemMap()).filter(
  //     (item) => item.getItemType() === type
  //   );
  // }

  getRefItem(id) {
    return Repository.getInstance().getItemByID(id);
  }

  getTopItem() {
    return Repository.getInstance().getTopItem();
  }

  getModuleItems() {
    return [];
  }

  getConfigItems() {
    return [];
  }

  getSFRBlock(item) {
    if (item) {
      if (
        item.getItemType() === ItemType.ClockCMU ||
        item.getItemType() === ItemType.PowerPMC ||
        item.getItemType() === ItemType.PowerPMD
      ) {
        return item.getSFRBlock();
      } else if (item.getItemType() === ItemType.SFRBlock) {
        return item;
      } else {
        return item.getParent() ? this.getSFRBlock(item.getParent()) : null;
      }
    }
  }

  createChild(item, type, properties = {}, title = "", domain = "") {
    return ItemHandler.createItem(
      item,
      ItemType.getObjByKey(type),
      properties,
      title,
      domain
    );
  }

  removeChild(item) {
    return ItemHandler.removeItem(item);
  }

  // remSyncChild(item) {
  //   return ItemHandler.removeSyncItem(item);
  // }

  // remChild(item) {
  //   return ItemHandler.removeItem(item);
  // }

  getConfigurationElement(item, prop) {
    // const cmu = item.getParent(ItemType.ClockCMU)
    // const configMap = store.getters['configuration/GET_CMU_TO_CONFIG'].get(cmu)
    // if (configMap) {
    //     const configElems = configMap.get(item)
    //     return (configElems) ? configElems.find(x => x.getProp() === prop) : null
    // }
  }

  getParent(itemType = null, category = null) {
    // const parent = store.getters['item/GET_ID_TO_ITEM_MAP'][this.pid]
    const parent = Repository.getInstance().getItemByID(this.pid);
    if (parent && itemType) {
      if (parent.getItemType() === itemType) {
        return parent;
      } else {
        return parent.getParent(itemType);
      }
    }
    if (parent && category) {
      if (parent.getItemType().getCategory() === category) {
        return parent;
      } else {
        return parent.getParent(null, category);
      }
    }
    return parent;
  }

  // isNodeType() {
  //   if (!this.getParent()) {
  //     return false;
  //   }
  //   if (this.isDiagramType(this.getParent()) || this.getParent().isNodeType()) {
  //     const category = this.getItemType().getCategory();
  //     return category && category.constructor === NodeType ? true : false;
  //   }
  // }

  isDiagramType(item) {
    return item ? item.isDiagramType() : this.view.diagram;
  }

  isTableType(item) {
    return item ? item.isTableType() : this.view.table;
  }

  isNodeType() {
    return false;
  }

  isConnectionType() {
    return false;
  }

  update(type = "itemupdated", props = {}) {
    const options = Object.assign({ item: this }, props);
    return Item.emit({
      type: type,
      options: options,
    });
  }

  getId() {
    return this.id;
  }

  getItemType() {
    return ItemType.getObjByKey(this.itemType);
  }

  setTitle(value, triggerEvent = true) {
    var oldVal = this.title;
    this.title = value;
    if (this.getEvent() && triggerEvent) {
      this.getEvent().propertyChanged(this.getFidTitle(), this.title, oldVal);
    }
  }

  getTitle() {
    return this.title;
  }

  get key() {
    return this.id;
  }

  get label() {
    return this.title;
  }

  addSyncChild(child) {
    if (child && child.id) {
      // var idx = this._children.findIndex((obj) => obj.id === child.id)
      // if (idx < 0) {
      //     this._children.push(child)
      // }
      if (!(child.id in this._children)) {
        this._children[child.id] = child;
        if (!this._firstChild) {
          this._firstChild = child;
        }
        if (this._lastChild) {
          this._lastChild._nextItem = child;
          child._preItem = this._lastChild;
        }
        this._lastChild = child;
        this._mirroredChildren = this.getMirroredChildren(this._firstChild);
      }
    }
  }

  addChild(child, triggerEvent = true) {
    if (child && child.id) {
      if (!(child.id in this._children)) {
        this._children[child.id] = child;
        if (!this._firstChild) {
          this._firstChild = child;
        }
        if (this._lastChild) {
          this._lastChild._nextItem = child;
          child._preItem = this._lastChild;
        }
        this._lastChild = child;
        this._mirroredChildren = this.getMirroredChildren(this._firstChild);
      }
      child.pid = this.id;

      if (this.getEvent() && triggerEvent) {
        return this.getEvent().arrayChangedAdd(this.getFidChildren(), child);
      }
    }
  }

  addRefChild(child) {
    if (!child || !child.id) {
      return;
    }
    if (!(child.id in this._referencedChildren)) {
      this._referencedChildren[child.id] = child;
      this._mirroredChildren = this.getMirroredChildren(this._firstChild);
    }
  }

  getRefChildren() {
    return Object.values(this._referencedChildren);
  }

  remSyncChild(child) {
    if (child && child.id) {
      if (this._firstChild === child) {
        this._firstChild = child._nextItem;
      }
      if (this._lastChild === child) {
        this._lastChild = child._preItem;
      }

      if (child._preItem) {
        child._preItem._nextItem = child._nextItem;
      }
      if (child._nextItem) {
        child._nextItem._preItem = child._preItem;
      }
      delete this._children[child.id];
      this._mirroredChildren = this.getMirroredChildren(this._firstChild);
    }
  }

  remChild(child, triggerEvent = true) {
    if (child && child.id) {
      if (this._firstChild === child) {
        this._firstChild = child._nextItem;
      }
      if (this._lastChild === child) {
        this._lastChild = child._preItem;
      }

      if (child._preItem) {
        child._preItem._nextItem = child._nextItem;
      }
      if (child._nextItem) {
        child._nextItem._preItem = child._preItem;
      }

      delete this._children[child.id];
      this._mirroredChildren = this.getMirroredChildren(this._firstChild);
      if (this.getEvent() && triggerEvent) {
        return this.getEvent().arrayChangedRemove(this.getFidChildren(), child);
      }
    }
  }

  remRefChild(child) {
    if (!child || !child.id) {
      return;
    }
    if (child.id in this._referencedChildren) {
      delete this._referencedChildren[child.id];
      this._mirroredChildren = this.getMirroredChildren(this._firstChild);
    }
  }

  clearChildren() {
    this._firstChild = null;
    this._lastChild = null;
    this._children = {};
    this._mirroredChildren = [];
    this._referencedChildren = {};
  }

  upSequence(item, targetItems) {
    if (!this._isValidSequenceTargets(item, targetItems)) {
      return;
    }

    const target = targetItems[0];

    if (this._firstChild === item) {
      this._firstChild = target;
    }
    if (this._lastChild === target) {
      this._lastChild = target._preItem;
    }

    const nextItem = target._nextItem;

    if (target._preItem) {
      target._preItem._nextItem = target._nextItem;
    }
    if (target._nextItem) {
      target._nextItem._preItem = target._preItem;
    }
    target._preItem = item._preItem;
    target._nextItem = item;

    if (item._preItem) {
      item._preItem._nextItem = target;
    }
    item._preItem = target;
    if (item._nextItem === target) {
      item._nextItem = nextItem;
    }

    this._mirroredChildren = this.getMirroredChildren(this._firstChild);
  }

  downSequence(item, targetItems) {
    if (!this._isValidSequenceTargets(item, targetItems)) {
      return;
    }

    const target = targetItems[0];
    if (this._firstChild === target) {
      this._firstChild = target._nextItem;
    }

    if (this._lastChild === item) {
      this._lastChild = target;
    }

    const preItem = target._preItem;

    if (target._preItem) {
      target._preItem._nextItem = target._nextItem;
    }
    if (target._nextItem) {
      target._nextItem._preItem = target._preItem;
    }
    target._preItem = item;
    target._nextItem = item._nextItem;

    if (item._nextItem) {
      item._nextItem._preItem = target;
    }
    item._nextItem = target;
    if (item._preItem === target) {
      item._preItem = preItem;
    }

    this._mirroredChildren = this.getMirroredChildren(this._firstChild);
  }

  _isValidSequenceTargets(item, targetItems) {
    if (!targetItems.length) {
      return false;
    }
    if (targetItems.find((o) => o === item)) {
      return false;
    }
    if (!this.isSequentialItems(targetItems[0], 0, targetItems)) {
      return false;
    }
    return true;
  }

  isSequentialItems(firstItem, index, targetItems) {
    if (firstItem === targetItems[index]) {
      if (index >= targetItems.length - 1) {
        return true;
      } else {
        const nextItem = this._getNextItem(firstItem, firstItem.getItemType());
        return this.isSequentialItems(nextItem, index + 1, targetItems);
      }
    } else {
      return false;
    }
  }

  _getNextItem(item, type) {
    if (item._nextItem) {
      return item._nextItem.getItemType() === type
        ? item._nextItem
        : this._getNextItem(item._nextItem, type);
    }
  }

  getChildWithProp(prop, value) {
    return this._mirroredChildren.find((obj) => obj.properties[prop] === value);
  }

  getChildren() {
    return this._mirroredChildren;
  }

  getSortedChildren(child) {
    return child ? [child, ...this.getSortedChildren(child._nextItem)] : [];
  }

  getMirroredChildren(child) {
    return this.getSortedChildren(child).concat(
      Object.values(this._referencedChildren)
    );
  }

  get children() {
    if (this.getHiddenChildren()) {
      return [];
    } else {
      return this._mirroredChildren.filter((item) => !item.hidden);
    }
  }

  setDisabled(value, triggerEvent = true) {
    var oldVal = this.disabled;
    this.disabled = value;
    if (this.getEvent() && triggerEvent) {
      this.getEvent().propertyChanged(
        this.getFidDisabled(),
        this.disabled,
        oldVal
      );
    }
  }

  getDisabled() {
    return this.disabled;
  }

  setSorted(value, triggerEvent = true) {
    var oldVal = this.sorted;
    this.sorted = value;
    if (this.getEvent() && triggerEvent) {
      this.getEvent().propertyChanged(this.getFidSorted(), this.sorted, oldVal);
    }
  }

  getSorted() {
    return this.sorted;
  }

  setHidden(value) {
    this.hidden = value;
  }

  getHidden() {
    return this.hidden;
  }

  setHiddenChildren(value, triggerEvent = true) {
    var oldVal = this.sorted;
    this.hiddenChildren = value;
    if (this.getEvent() && triggerEvent) {
      this.getEvent().propertyChanged(
        this.getFidHiddenChildren(),
        this.hiddenChildren,
        oldVal
      );
    }
  }

  getHiddenChildren() {
    return this.hiddenChildren;
  }

  setCategory(category) {
    this.category = category;
  }

  getCategory() {
    return this.category;
  }

  addError(type, fid, error) {
    if (!this._errorMap[type]) {
      this._errorMap[type] = {};
    }
    this._errorMap[type][fid] = error;
  }

  getErrorMap() {
    return this._errorMap;
  }

  getErrors(type) {
    var res = [];
    if (type) {
      if (this._errorMap[type]) {
        res = Object.values(this._errorMap[type]);
      }
    } else {
      for (const type in this._errorMap) {
        res = res.concat(Object.values(this._errorMap[type]));
      }
    }
    return res;
  }

  remError(type) {
    delete this._errorMap[type];
  }

  clearErrors() {
    this._errorMap = {};
  }

  getAllErrors() {
    var res = [...this.getErrors()];
    for (const cItem of this.getChildren()) {
      res = res.concat(cItem.getAllErrors());
    }
    return res;
  }

  isCurrentItem() {
    return this === Repository.getInstance().getCurrentItem();
  }

  /*replacer(key, val) {
        if (key != "emitter") {
            return val
        }
    }

    serialize() {
        return JSON.parse(JSON.stringify(this, this.replacer))
    }*/

  // replacer(key, val) {
  //   switch (key) {
  //     case "_children":
  //     case "_mirroredChildren":
  //     case "_preItem":
  //     case "_nextItem":
  //     case "_firstChild":
  //     case "_lastChild":
  //       return;
  //     default:
  //       return val;
  //   }
  // }

  importProperties(properties) {
    this.properties = Object.assign(
      this.properties ? this.properties : {},
      properties
    );
  }

  exportProperties() {
    return this.properties;
  }

  toJSON() {
    const res = {
      title: this.title,
      disabled: this.disabled,
      hidden: this.hidden,
      hiddenChildren: this.hiddenChildren,
      pid: this.pid,
      id: this.id,
      itemType: this.itemType,
      domain: this.domain,
      properties: this.properties,
      _type: this._type,
      _mappers: this._mappers,
      _children: this._mirroredChildren.map((cItem) => cItem.id),
    };
    return res;
  }

  exportProperties() {
    return this.properties;
  }

  replacer(key, val) {
    if (key === "_children") {
      return val.reduce((acc, id) => {
        const item = ItemHandler.getRefItem(id);
        if (
          item &&
          item.validate(this._type) &&
          item.getParent().getItemType() !== ItemType.Project
        ) {
          acc.push(id);
        }
        return acc;
      }, []);
    }
    return val;
  }

  serialize(type = "design") {
    this._type = type;
    return JSON.parse(JSON.stringify(this, this.replacer));
  }

  validate(type = "design") {
    if (
      this.getParent(ItemType.ConfigurationFolder) ||
      this.getParent(ItemType.PowerSequenceConfigurationFolder) ||
      this.getParent(ItemType.VoltageLevelFolder)
    ) {
      return type === "config";
    } else {
      return type === "design";
    }
  }
}
