/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import { Domain } from '@/model/types'
import DUTModuleFolderEvent from '@/model/src/events/DUTModuleFolderEvent'
import DUTModuleFolderChecker from '@/model/src/checkers/DUTModuleFolderChecker'
export default class DUTModuleFolder extends Item {
    FID_DUTMODULEFOLDER_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"DUTModuleFolder"}))
        
        this.setEvent(new DUTModuleFolderEvent(this))
        this.setChecker(new DUTModuleFolderChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "DUT"
        }
    }

    getDomain() {
        return Domain.DUT
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "DUTModuleFolder"
    }

    getSchema() {
        return {}
    }

    static getSchema() {
        return {}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "DUTModule", "title": "TOP", "auto": true, "hidden": false, "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    getFidLast() {
        return this.FID_DUTMODULEFOLDER_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            default:
                return ""
        }
    }


    newDUTModule(properties={}, title = "", domain = "") {
        return this.createChild(this, "DUTModule", properties, title, domain)
    }
    remDUTModule(obj) {
        return this.removeChild(obj)
    }
    getDUTModule() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "DUTModule")
            return res
        })
    }
}