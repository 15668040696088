<template>
  <div class="item-field">
    <ItemPropertyLabel :props="props" :isTitle="true" />
    <v-divider
      v-if="
        Utils.getRefItemTitle(
          props.item,
          props.propKey,
          props.prop,
          props.config
        ) && getItems().length > 1
      "
      class="mb-0" />
    <v-list>
      <v-list-item
        v-for="(element, i) in getItems()"
        :key="i"
        style="padding: 0 !important">
        <div v-if="element.type === 'chip'">
          <ItemPropertyChip v-bind="getData(element)" />
        </div>
        <ItemPropertyAutocomplete
          v-if="element.type === 'enum'"
          v-bind="getData(element)" />
        <ItemPropertyTextField
          v-else-if="element.type === 'string'"
          v-bind="getData(element)" />
        <ItemPropertyIntegerField
          v-else-if="element.type === 'integer'"
          v-bind="getData(element)" />
      </v-list-item>
    </v-list>
  </div>
</template>
<script setup lang="ts">
import { defineProps } from "vue";
import Utils from "@/components/item/utils";
import { ItemPropsType } from "ITDAModelTypes";
import ItemPropertyAutocomplete from "@/components/item/ItemPropertyAutocomplete.vue";
import ItemPropertyChip from "@/components/item/ItemPropertyChip.vue";
import ItemPropertyTextField from "@/components/item/ItemPropertyTextField.vue";
import ItemPropertyIntegerField from "@/components/item/ItemPropertyIntegerField.vue";
import ItemPropertyLabel from "./ItemPropertyLabel.vue";

const getData = (element) => {
  let res: ItemPropsType = {
    item: props.item,
    propKey: element.propKey,
    prop: {
      type: element.type,
      label: element.label,
      multiple: element.multiple,
      targets: element.targets
        ? element.targets.map((target) => target.key)
        : [],
    },
    itemRef: element,
  };
  if (props.errorMessages) {
    res["errorMessages"] = props.errorMessages;
  } else if (props.table) {
    res["table"] = props.table;
  }
  return res;
};
const props = defineProps<ItemPropsType>();

const getItems = () => {
  return Utils.getRefItemElements(
    props.item,
    props.propKey,
    props.prop,
    props.config
  );
};
</script>
<style lang="sass" scoped>
.item-field
  .item-field__title
    width: 100%
    display: block
    text-align: left
    font-size: 0.9rem
    font-weight: 500
    padding-left: 2px

  .list-items
    padding: 8px 0px !important

  .list-item
    padding: 0 !important

  .v-list--density-default
    padding: 0

  .v-list-item--density-default
    min-height: 0
</style>
