import { ItemType } from "ITDAModelTypes";
import ItemHandler from "../../ItemHandler";

export const insertGate = async (ipInput) => {
  const diagram = ipInput.getParent(ItemType.ClockIPDiagram);
  for (const connection of ipInput.getConnections()) {
    const labelRefOutput = connection.getSourceOutput();
    const labelRef = connection.getSourceOutput()?.getParent();
    if (labelRef.getItemType() === ItemType.LabelRef) {
      const { item: gate, promise } = ItemHandler.createItem(
        diagram,
        ItemType.ClockGate,
        {
          name: `${ipInput.getParent().getName()}__${ipInput.getName()}`,
        }
      );
      await promise;

      const gateInput = gate
        .getInputs()
        .find(
          (o) => o.getKey() === `CLK_${ItemType.Input.getKey().toUpperCase()}`
        );
      const gateOutput = gate
        .getOutputs()
        .find(
          (o) => o.getKey() === `CLK_${ItemType.Output.getKey().toUpperCase()}`
        );
      if (gateInput && gateOutput) {
        await createConnection(diagram, labelRefOutput, gateInput);
        await createConnection(diagram, gateOutput, ipInput);
      }
    }
    await ItemHandler.removeItem(connection);
  }
};

export const removeGate = async (ipInput) => {
  const diagram = ipInput.getParent(ItemType.ClockIPDiagram);

  let conn;
  conn = ipInput.getConnections()[0];
  if (!conn) return;

  const gateOutput = conn.getSourceOutput();
  const gate = gateOutput.getParent();
  const gateInput = gate
    .getInputs()
    .find((o) => o.getKey() === `CLK_${ItemType.Input.getKey().toUpperCase()}`);

  conn = gateInput.getConnections()[0];
  if (!conn) return;

  const labelRefOutput = conn.getSourceOutput();

  if (ipInput.getShared()) {
    await ipInput.setShared();
  } else {
    await restoreConnection(diagram, labelRefOutput, gateOutput);
    await ItemHandler.removeItem(gate);
  }
};

export const updateShared = async (ipInput, newVal, oldVal) => {
  const diagram = ipInput.getParent(ItemType.ClockIPDiagram);

  const connRight = ipInput.getConnections()[0];
  if (!connRight) return;

  const gateOutput = connRight.getSourceOutput();
  const gate = gateOutput.getParent();
  const gateInput = gate
    .getInputs()
    .find((o) => o.getKey() === `CLK_${ItemType.Input.getKey().toUpperCase()}`);
  const connLeft = gateInput.getConnections()[0];
  if (!connLeft) return;

  const labelRefOutput = connLeft.getSourceOutput();

  const sharedGate = newVal;
  if (sharedGate) {
    const sharedGateOutput = sharedGate
      .getOutputs()
      .find(
        (o) => o.getKey() === `CLK_${ItemType.Output.getKey().toUpperCase()}`
      );
    await createConnection(diagram, sharedGateOutput, ipInput);

    if (oldVal) {
      await ItemHandler.removeItem(connRight);
    } else {
      await restoreConnection(diagram, labelRefOutput, gateOutput, connRight);
      await ItemHandler.removeItem(gate);
    }
  } else {
    await ItemHandler.removeItem(connRight);
    await createConnection(diagram, labelRefOutput, ipInput);
  }
};

const restoreConnection = async (
  diagram,
  labelRefOutput,
  gateOutput,
  ignoreConn
) => {
  for (const conn of gateOutput.getConnections()) {
    if (conn === ignoreConn) {
      continue;
    }
    const ipInput = conn.getTargetInput();
    await createConnection(diagram, labelRefOutput, ipInput);
  }
};

const createConnection = async (parent, source, target) => {
  const { item, promise } = ItemHandler.createItem(
    parent,
    ItemType.Connection,
    {
      sourceOutput: source.getId(),
      targetInput: target.getId(),
    }
  );
  await promise;
  return item;
};
