'use strict';

export default class ModuleType {

    static Clock        = new ModuleType(0, "Clock")
    static Power        = new ModuleType(0, "Power")
    static Module       = new ModuleType(0, "Power")
    
    constructor(index, key) {
        this.index = index
        this.key = key
    }

    static getKeys() {
        return Object.keys(this)
    }

    static getValues() {
        return Object.values(this)
    }

    static getEntries() {
        return Object.entries(this)
    }
    
    static getObjByKey(key) {
        return this[key]
    }

    getKey() {
        return this.key
    }
}
