/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import ClockDiagramEvent from '@/model/src/events/ClockDiagramEvent'
import ClockDiagramChecker from '@/model/src/checkers/ClockDiagramChecker'
export default class ClockDiagram extends Item {
    FID_CLOCKDIAGRAM_NAME = 0
    FID_CLOCKDIAGRAM_TYPE = 1
    FID_CLOCKDIAGRAM_CANVASDATA = 2
    FID_CLOCKDIAGRAM_LAST = 2

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockDiagram"}))
        
        this.setEvent(new ClockDiagramEvent(this))
        this.setChecker(new ClockDiagramChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    (this.view.diagram = true), (this.view.table = false)
    if (this.properties.name === undefined) {
        this.properties.name = "CD_NONAME"
    }
    if (this.properties.type === undefined) {
        this.properties.type = "Clock"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockDiagram"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "CD_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "type": {"type": "enum", "targets": ["Clock"], "multiple": false, "default": "Clock", "category": "IP", "hidden": false, "readOnly": false}, "canvasData": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "CD_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "type": {"type": "enum", "targets": ["Clock"], "multiple": false, "default": "Clock", "category": "IP", "hidden": false, "readOnly": false}, "canvasData": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "Connection", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "ClockExternalCLKSource", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "ClockPLLCtrl", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "ClockRefCLKMultiplexer", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "ClockMultiplexer", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "ClockDivider", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "ClockNMDivider", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "ClockGate", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "ClockBuffer", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "Label", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "LabelRef", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "ClockFreqmon", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIAGRAM_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKDIAGRAM_NAME
    }
    

    setType(type, event=true) {
        if ((type !== null) && (type !== undefined)) {
            if (![String].includes(type.constructor)) {
                throw `type(${type}) must be [String] type`
            }
        }
        const newVal = (type === null || type === undefined) ? undefined : type
        const oldVal = this.properties.type
        this.properties.type = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIAGRAM_TYPE, newVal, oldVal)
        }
    }
    getType(pure = false) {
        return this.properties.type
        
    }
    getFidType() {
        return this.FID_CLOCKDIAGRAM_TYPE
    }
    

    setCanvasData(canvasData, event=true) {
        if ((canvasData !== null) && (canvasData !== undefined)) {
            if (![String].includes(canvasData.constructor)) {
                throw `canvasData(${canvasData}) must be [String] type`
            }
        }
        const newVal = (canvasData === null || canvasData === undefined) ? undefined : canvasData
        const oldVal = this.properties.canvasData
        this.properties.canvasData = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIAGRAM_CANVASDATA, newVal, oldVal)
        }
    }
    getCanvasData(pure = false) {
        return this.properties.canvasData
        
    }
    getFidCanvasData() {
        return this.FID_CLOCKDIAGRAM_CANVASDATA
    }
    

    getFidLast() {
        return this.FID_CLOCKDIAGRAM_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKDIAGRAM_NAME:
                return "name"

            case this.FID_CLOCKDIAGRAM_TYPE:
                return "type"

            case this.FID_CLOCKDIAGRAM_CANVASDATA:
                return "canvasData"

            default:
                return ""
        }
    }


    newConnection(properties={}, title = "", domain = "") {
        return this.createChild(this, "Connection", properties, title, domain)
    }
    remConnection(obj) {
        return this.removeChild(obj)
    }
    getConnections() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "Connection")
            return res
        })
    }
    newClockExternalCLKSource(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockExternalCLKSource", properties, title, domain)
    }
    remClockExternalCLKSource(obj) {
        return this.removeChild(obj)
    }
    getClockExternalCLKSources() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockExternalCLKSource")
            return res
        })
    }
    newClockPLLCtrl(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockPLLCtrl", properties, title, domain)
    }
    remClockPLLCtrl(obj) {
        return this.removeChild(obj)
    }
    getClockPLLCtrls() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockPLLCtrl")
            return res
        })
    }
    newClockRefCLKMultiplexer(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockRefCLKMultiplexer", properties, title, domain)
    }
    remClockRefCLKMultiplexer(obj) {
        return this.removeChild(obj)
    }
    getClockRefCLKMultiplexers() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockRefCLKMultiplexer")
            return res
        })
    }
    newClockMultiplexer(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockMultiplexer", properties, title, domain)
    }
    remClockMultiplexer(obj) {
        return this.removeChild(obj)
    }
    getClockMultiplexers() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockMultiplexer")
            return res
        })
    }
    newClockDivider(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockDivider", properties, title, domain)
    }
    remClockDivider(obj) {
        return this.removeChild(obj)
    }
    getClockDividers() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockDivider")
            return res
        })
    }
    newClockNMDivider(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockNMDivider", properties, title, domain)
    }
    remClockNMDivider(obj) {
        return this.removeChild(obj)
    }
    getClockNMDividers() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockNMDivider")
            return res
        })
    }
    newClockGate(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockGate", properties, title, domain)
    }
    remClockGate(obj) {
        return this.removeChild(obj)
    }
    getClockGates() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockGate")
            return res
        })
    }
    newClockBuffer(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockBuffer", properties, title, domain)
    }
    remClockBuffer(obj) {
        return this.removeChild(obj)
    }
    getClockBuffers() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockBuffer")
            return res
        })
    }
    newLabel(properties={}, title = "", domain = "") {
        return this.createChild(this, "Label", properties, title, domain)
    }
    remLabel(obj) {
        return this.removeChild(obj)
    }
    getLabels() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "Label")
            return res
        })
    }
    newLabelRef(properties={}, title = "", domain = "") {
        return this.createChild(this, "LabelRef", properties, title, domain)
    }
    remLabelRef(obj) {
        return this.removeChild(obj)
    }
    getLabelRefs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "LabelRef")
            return res
        })
    }
    newClockFreqmon(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockFreqmon", properties, title, domain)
    }
    remClockFreqmon(obj) {
        return this.removeChild(obj)
    }
    getClockFreqmons() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockFreqmon")
            return res
        })
    }
}