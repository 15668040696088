/**
 *  Generated by Modeler
 *  timestamp: 2024-08-21 02:24:06.858657
 */
import ItemEvent from '@/model/src/ItemEvent'

export default class NodeMetaViewEvent extends ItemEvent {
    constructor(item) {
        super(item)
        // this.addPropertyListener(item.getFidX(), this.lstn_x)
        // this.addPropertyListener(item.getFidY(), this.lstn_y)
    }
    // lstn_x(item, newVal, oldVal) {
    //     console.debug(item, newVal, oldVal)
    // }
    // lstn_y(item, newVal, oldVal) {
    //     console.debug(item, newVal, oldVal)
    // }
}