import * as ITDAControl from "../controls";
import * as ITDANodeRenderer from "./renderers";
import { NodeType } from "../types";
import ITDANodeClock from "./ITDANodeClock";

export default class ITDADIVIDER extends ITDANodeClock {
  constructor(name = "") {
    super(name, NodeType.ITDADIVIDER);
    this.render = ITDANodeRenderer.ITDADIVIDER.render;
  }

  async data(
    inputs: Record<`CLK_INPUT`, any>
  ): Promise<Record<`CLK_OUTPUT`, any>> {
    const freq = this.controls.freq as ITDAControl.ITDAControlFrequency;
    let frequency = this.getFrequencyInitValue();
    if (Object.keys(inputs).length !== 0) {
      const inputFreq = Number(inputs.CLK_INPUT[0]);
      const configElements = this.getConfigurationElements();
      const divRatio = configElements.length
        ? Number(configElements[0].getValue()) + 1
        : 1;
      frequency = (inputFreq / divRatio).toFixed(2);
    }

    if (frequency) {
      freq.setFrequency(frequency);
      this.update();
    }
    return { CLK_OUTPUT: freq.getFrequency() };
  }
}
