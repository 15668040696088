import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;
    const innerStroke = 2;
    const radius = width / 16;

    g.append("line")
      .attr("x1", centerX / 4)
      .attr("y1", centerY)
      .attr("x2", centerX / 2)
      .attr("y2", centerY)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 3)
      .attr("y1", centerY)
      .attr("x2", (centerX / 4) * 6)
      .attr("y2", (centerY / 8) * 5)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 6)
      .attr("y1", centerY)
      .attr("x2", (centerX / 4) * 7)
      .attr("y2", centerY)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("circle")
      .attr("cx", centerX / 2 + radius)
      .attr("cy", centerY)
      .attr("r", radius)
      .attr("fill", "none")
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("circle")
      .attr("cx", (centerX / 4) * 6 - radius)
      .attr("cy", centerY)
      .attr("r", radius)
      .attr("fill", "none")
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 1.5)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("REFCLKEN");
  },
};
