import axios from "axios";
import { host, protocol } from "./config";

export const loadSpec = async (
  name: "pll" | "hm" | "generic"
): Promise<any> => {
  try {
    const res = await axios.post(`${protocol}://${host}/io/load_${name}_spec`);
    return res && res.status === 200 ? res.data : undefined;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};
