<template>
  <div ref="socket" class="socket" :title="props.data.name"></div>
</template>

<script setup lang="ts">
import { defineProps, onMounted, onUpdated, ref, watch } from "vue";

const props = defineProps<{
  data: any;
}>();

const socket = ref<HTMLElement | null>(null);
//console.log("socket size", props);
onMounted(() => {
  if (socket.value) {
    props.data.render(socket.value, props.data.getType().getColor());
    const rect = socket.value.getBoundingClientRect();
    props.data.setSize(rect.width, rect.height); // set socket x,y position
    props.data.getSize();
  }
});
</script>

<style lang="sass" scoped>
.socket
  display: flex
  height: inherit
  align-items: center
</style>
