/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import ClockQChannelManagerEvent from '@/model/src/events/ClockQChannelManagerEvent'
import ClockQChannelManagerChecker from '@/model/src/checkers/ClockQChannelManagerChecker'
import ITDA_CMU_QCH_MANAGER_PT from '@/model/gen/sfrSpec/ITDA_CMU_QCH_MANAGER_PT'
export default class ClockQChannelManager extends Node {
    FID_CLOCKQCHANNELMANAGER_NAME = 100
    FID_CLOCKQCHANNELMANAGER_MAXNUM = 101
    FID_CLOCKQCHANNELMANAGER_POWERDOWNSTEP = 102
    FID_CLOCKQCHANNELMANAGER_LAST = 102

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockQChannelManager"}))
        
        this.setEvent(new ClockQChannelManagerEvent(this))
        this.setChecker(new ClockQChannelManagerChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.maxNum === undefined) {
        this.properties.maxNum = 128
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockQChannelManager"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 128, "category": "IP", "multiple": false, "readOnly": false}, "powerdownStep": {"type": "enum", "label": "PWRDOWN Step", "multiple": false, "targets": ["STEP0", "STEP1", "STEP2", "STEP3", "STEP4", "STEP5", "STEP6", "STEP7"], "category": "IP", "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 128, "category": "IP", "multiple": false, "readOnly": false}, "powerdownStep": {"type": "enum", "label": "PWRDOWN Step", "multiple": false, "targets": ["STEP0", "STEP1", "STEP2", "STEP3", "STEP4", "STEP5", "STEP6", "STEP7"], "category": "IP", "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ClockQChannelManagerEXT", "auto": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_CMU_QCH_MANAGER_PT
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_CMU_QCH_MANAGER_PT
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKQCHANNELMANAGER_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKQCHANNELMANAGER_NAME
    }
    

    setMaxNum(maxNum, event=true) {
        if ((maxNum !== null) && (maxNum !== undefined)) {
            if (![Number].includes(maxNum.constructor)) {
                throw `maxNum(${maxNum}) must be [Number] type`
            }
        }
        const newVal = (maxNum === null || maxNum === undefined) ? undefined : maxNum
        const oldVal = this.properties.maxNum
        this.properties.maxNum = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKQCHANNELMANAGER_MAXNUM, newVal, oldVal)
        }
    }
    getMaxNum(pure = false) {
        return this.properties.maxNum
        
    }
    getFidMaxNum() {
        return this.FID_CLOCKQCHANNELMANAGER_MAXNUM
    }
    

    setPowerdownStep(powerdownStep, event=true) {
        if ((powerdownStep !== null) && (powerdownStep !== undefined)) {
            if (![String].includes(powerdownStep.constructor)) {
                throw `powerdownStep(${powerdownStep}) must be [String] type`
            }
        }
        const newVal = (powerdownStep === null || powerdownStep === undefined) ? undefined : powerdownStep
        const oldVal = this.properties.powerdownStep
        this.properties.powerdownStep = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKQCHANNELMANAGER_POWERDOWNSTEP, newVal, oldVal)
        }
    }
    getPowerdownStep(pure = false) {
        return this.properties.powerdownStep
        
    }
    getFidPowerdownStep() {
        return this.FID_CLOCKQCHANNELMANAGER_POWERDOWNSTEP
    }
    

    getFidLast() {
        return this.FID_CLOCKQCHANNELMANAGER_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKQCHANNELMANAGER_NAME:
                return "name"

            case this.FID_CLOCKQCHANNELMANAGER_MAXNUM:
                return "maxNum"

            case this.FID_CLOCKQCHANNELMANAGER_POWERDOWNSTEP:
                return "powerdownStep"

            default:
                return ""
        }
    }


    newClockQChannelManagerEXT(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockQChannelManagerEXT", properties, title, domain)
    }
    remClockQChannelManagerEXT(obj) {
        return this.removeChild(obj)
    }
    getClockQChannelManagerEXT() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "ClockQChannelManagerEXT")
            return res
        })
    }
}