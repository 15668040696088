/**
 *  Generated by Modeler
 *  timestamp: 2024-08-26 09:44:21.388542
 */
//import ErrorType from '@/model/src/ErrorType'
import ItemChecker from "@/model/src/ItemChecker";

export default class PhantomChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidOriginItem(), this.checkOriginItem, ErrorType.NAME)
  }
  checkOriginItem() {
    // TBD
  }
}
