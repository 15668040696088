<template>
  <div :class="props.expanded ? undefined : undefined">
    <ItemPropertyLabel :props="props" :isTitle="false" />
    <v-text-field
      ref="input"
      v-model="model"
      color="primary"
      autocomplete="nope"
      :disabled="
        Utils.isDisabledIntegerField(props.item, props.propKey, props.prop)
      "
      variant="outlined"
      :rules="combinedRules"
      :error-messages="props.errorMessages"
      :hide-details="!inputError && !checkError"
      @input="(e) => limitDecimalPlaces(e)"
      @change="
        inputError
          ? undefined
          : props.itemRef
          ? props.itemRef.event(props.item, props.itemRef, $event.target.value)
          : Utils.change(props.item, props.propKey, Number, $event)
      " />
  </div>
</template>

<script setup lang="ts">
import API from "@/api/internal";
import { computed, defineProps, nextTick, onUpdated, ref, watch } from "vue";
import Utils from "@/components/item/utils";
import { ItemPropsType } from "ITDAModelTypes";
import ItemPropertyLabel from "./ItemPropertyLabel.vue";
const props = defineProps<
  ItemPropsType & {
    expanded?: boolean;
  }
>();
const value = props.itemRef
  ? props.itemRef.model
  : Utils.getValue(props.item, props.propKey);

const model = ref(value);
const input = ref<any>(null);
const inputError = ref(false);
const checkError = ref(false);

const rules = API.getConfigurationRules(props.item, props.propKey);
const itemRules = API.getItemRules(props.item, props.propKey);

const combinedRules = computed(() => {
  return [...rules, ...itemRules];
});

const limitDecimalPlaces = (e: any) => {
  let inputValue = e.target.value;
  const decimalIndex = inputValue.indexOf(".");

  if (decimalIndex !== -1 && inputValue.length - decimalIndex - 1 > 4) {
    inputValue = inputValue.substring(0, decimalIndex + 5);
    model.value = inputValue;
    e.target.value = inputValue;
  }
};

watch(model, async () => {
  await nextTick();
  const err = await input.value.validate();
  inputError.value = err.length > 0;
});

onUpdated(() => {
  checkError.value = !!props.errorMessages;
});
</script>

<style lang="sass" scoped>
.v-text-field
  padding-top: 0

.v-text-field *
  --v-input-control-height: 38px
  --v-field-input-padding-top: 7px
  --v-field-input-padding-bottom: 7px
  --v-field-padding-start: 10px
  --v-field-padding-end: 10px

.field__expanded
  display: grid
  grid-template-columns: 1fr 1fr
  place-items: center
  .v-text-field
    width: 100%
</style>
