/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import SDCClockGroupEvent from '@/model/src/events/SDCClockGroupEvent'
import SDCClockGroupChecker from '@/model/src/checkers/SDCClockGroupChecker'
export default class SDCClockGroup extends Node {
    FID_SDCCLOCKGROUP_NAME = 100
    FID_SDCCLOCKGROUP_COLOR = 101
    FID_SDCCLOCKGROUP_LAST = 101

    constructor(obj) {
        super(Object.assign(obj,{itemType:"SDCClockGroup"}))
        
        this.setEvent(new SDCClockGroupEvent(this))
        this.setChecker(new SDCClockGroupChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "SDCClockGroup"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "SDCClockGroup"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "color": {"type": "color", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "color": {"type": "color", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SDCCLOCKGROUP_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_SDCCLOCKGROUP_NAME
    }
    

    setColor(color, event=true) {
        if ((color !== null) && (color !== undefined)) {
            if (![String].includes(color.constructor)) {
                throw `color(${color}) must be [String] type`
            }
        }
        const newVal = (color === null || color === undefined) ? undefined : color
        const oldVal = this.properties.color
        this.properties.color = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SDCCLOCKGROUP_COLOR, newVal, oldVal)
        }
    }
    getColor(pure = false) {
        return this.properties.color
        
    }
    getFidColor() {
        return this.FID_SDCCLOCKGROUP_COLOR
    }
    

    getFidLast() {
        return this.FID_SDCCLOCKGROUP_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_SDCCLOCKGROUP_NAME:
                return "name"

            case this.FID_SDCCLOCKGROUP_COLOR:
                return "color"

            default:
                return ""
        }
    }


}