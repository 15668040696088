import * as ITDANodes from "../../nodes";
import { DiagramType, NodeType } from "../../types";
import { Repository } from "@/canvas/store";

export { WheelDrag } from "./WheelDrag";

export const getDestructor = (node: ITDANodes.ITDANode): [string, any][] => {
  return [
    [
      "Delete",
      () => {
        const repo = Repository.getInstance();
        const editor = repo.getEditor(repo.getCurrentID());
        return editor.removeNode(node.id);
      },
    ],
  ];
};

export const getFactoryByDiagram = (
  diagramType: DiagramType
): [string, any][] => {
  switch (diagramType) {
    case DiagramType.CMU:
      return [
        [
          NodeType.ITDASOURCE.getTitle(),
          () => new ITDANodes.ITDASOURCE(NodeType.ITDASOURCE.getTitle()),
        ],
        [
          NodeType.ITDAPLLCTRL.getTitle(),
          () => new ITDANodes.ITDAPLLCTRL(NodeType.ITDAPLLCTRL.getTitle()),
        ],
        [
          NodeType.ITDAREFCLKMUX.getTitle(),
          () => new ITDANodes.ITDAREFCLKMUX(NodeType.ITDAREFCLKMUX.getTitle()),
        ],
        [
          NodeType.ITDAGFMUX.getTitle(),
          () => new ITDANodes.ITDAGFMUX(NodeType.ITDAGFMUX.getTitle()),
        ],
        [
          NodeType.ITDADIVIDER.getTitle(),
          () => new ITDANodes.ITDADIVIDER(NodeType.ITDADIVIDER.getTitle()),
        ],
        [
          NodeType.ITDAGATE.getTitle(),
          () => new ITDANodes.ITDAGATE(NodeType.ITDAGATE.getTitle()),
        ],
        [
          NodeType.ITDABUFFER.getTitle(),
          () => new ITDANodes.ITDABUFFER(NodeType.ITDABUFFER.getTitle()),
        ],
        [
          NodeType.ITDANMDIVIDER.getTitle(),
          () => new ITDANodes.ITDANMDIVIDER(NodeType.ITDANMDIVIDER.getTitle()),
        ],
        [
          NodeType.ITDAFREQMON.getTitle(),
          () => new ITDANodes.ITDAFREQMON(NodeType.ITDAFREQMON.getTitle()),
        ],
        [
          NodeType.ITDALABEL.getTitle(),
          () => new ITDANodes.ITDALABEL(NodeType.ITDALABEL.getTitle()),
        ],
        [
          NodeType.ITDALABELREF.getTitle(),
          () => new ITDANodes.ITDALABELREF(NodeType.ITDALABELREF.getTitle()),
        ],
        [
          NodeType.ITDADFTOCCBUFFER.getTitle(),
          () =>
            new ITDANodes.ITDADFTOCCBUFFER(
              NodeType.ITDADFTOCCBUFFER.getTitle()
            ),
        ],
      ];
    case DiagramType.IP:
      return [
        [
          NodeType.ITDALABELREF.getTitle(),
          () => new ITDANodes.ITDALABELREF(NodeType.ITDALABELREF.getTitle()),
        ],
        [
          NodeType.ITDAQCH.getTitle(),
          () => new ITDANodes.ITDAQCH(NodeType.ITDAQCH.getTitle()),
        ],
        [
          NodeType.ITDAINSTANCEIP.getTitle(),
          () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEIP),
        ],
      ];
    case DiagramType.PMC:
      return [
        [
          NodeType.ITDAINSTANCEPMD.getTitle(),
          () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEPMD),
        ],
        [
          NodeType.ITDAPWREN.getTitle(),
          () => new ITDANodes.ITDAPWREN(NodeType.ITDAPWREN.getTitle()),
        ],
        [
          NodeType.ITDAISOEN.getTitle(),
          () => new ITDANodes.ITDAISOEN(NodeType.ITDAISOEN.getTitle()),
        ],
        [
          NodeType.ITDAHANDSHAKE.getTitle(),
          () => new ITDANodes.ITDAHANDSHAKE(NodeType.ITDAHANDSHAKE.getTitle()),
        ],
        [
          NodeType.ITDAPCH.getTitle(),
          () => new ITDANodes.ITDAPCH(NodeType.ITDAPCH.getTitle()),
        ],
        [
          NodeType.ITDARESET.getTitle(),
          () => new ITDANodes.ITDARESET(NodeType.ITDARESET.getTitle()),
        ],
        [
          NodeType.ITDAUSERDEFOUT.getTitle(),
          () =>
            new ITDANodes.ITDAUSERDEFOUT(NodeType.ITDAUSERDEFOUT.getTitle()),
        ],
        [
          NodeType.ITDAUSERDEFIN.getTitle(),
          () => new ITDANodes.ITDAUSERDEFIN(NodeType.ITDAUSERDEFIN.getTitle()),
        ],
        [
          NodeType.ITDAINFORM.getTitle(),
          () => new ITDANodes.ITDAINFORM(NodeType.ITDAINFORM.getTitle()),
        ],
        [
          NodeType.ITDATIMEOUT.getTitle(),
          () => new ITDANodes.ITDATIMEOUT(NodeType.ITDATIMEOUT.getTitle()),
        ],
        [
          NodeType.ITDAPMRCTRL.getTitle(),
          () => new ITDANodes.ITDAPMRCTRL(NodeType.ITDAPMRCTRL.getTitle()),
        ],
        [
          NodeType.ITDALATCH.getTitle(),
          () => new ITDANodes.ITDALATCH(NodeType.ITDALATCH.getTitle()),
        ],
        [
          NodeType.ITDAINTRSFR.getTitle(),
          () => new ITDANodes.ITDAINTRSFR(NodeType.ITDAINTRSFR.getTitle()),
        ],
        [
          NodeType.ITDAINTREXT.getTitle(),
          () => new ITDANodes.ITDAINTREXT(NodeType.ITDAINTREXT.getTitle()),
        ],
        [
          NodeType.ITDATIMER.getTitle(),
          () => new ITDANodes.ITDATIMER(NodeType.ITDATIMER.getTitle()),
        ],
        [
          NodeType.ITDAPMDGRP.getTitle(),
          () => new ITDANodes.ITDAPMDGRP(NodeType.ITDAPMDGRP.getTitle()),
        ],
      ];
    case DiagramType.PMD:
      return [
        [
          NodeType.ITDAAPM.getTitle(),
          () => new ITDANodes.ITDAAPM(NodeType.ITDAAPM.getTitle()),
        ],
        [
          NodeType.ITDAREFCLKEN.getTitle(),
          () => new ITDANodes.ITDAREFCLKEN(NodeType.ITDAREFCLKEN.getTitle()),
        ],
        [
          NodeType.ITDAPSW.getTitle(),
          () => new ITDANodes.ITDAPSW(NodeType.ITDAPSW.getTitle()),
        ],
        [
          NodeType.ITDAPWREN.getTitle(),
          () => new ITDANodes.ITDAPWREN(NodeType.ITDAPWREN.getTitle()),
        ],
        [
          NodeType.ITDAISOEN.getTitle(),
          () => new ITDANodes.ITDAISOEN(NodeType.ITDAISOEN.getTitle()),
        ],
        [
          NodeType.ITDAMEM.getTitle(),
          () => new ITDANodes.ITDAMEM(NodeType.ITDAMEM.getTitle()),
        ],
        [
          NodeType.ITDARETENTION.getTitle(),
          () => new ITDANodes.ITDARETENTION(NodeType.ITDARETENTION.getTitle()),
        ],
        [
          NodeType.ITDAOTP.getTitle(),
          () => new ITDANodes.ITDAOTP(NodeType.ITDAOTP.getTitle()),
        ],
        [
          NodeType.ITDAHANDSHAKE.getTitle(),
          () => new ITDANodes.ITDAHANDSHAKE(NodeType.ITDAHANDSHAKE.getTitle()),
        ],
        [
          NodeType.ITDACLINK.getTitle(),
          () => new ITDANodes.ITDACLINK(NodeType.ITDACLINK.getTitle()),
        ],
        [
          NodeType.ITDAPCH.getTitle(),
          () => new ITDANodes.ITDAPCH(NodeType.ITDAPCH.getTitle()),
        ],
        [
          NodeType.ITDARESET.getTitle(),
          () => new ITDANodes.ITDARESET(NodeType.ITDARESET.getTitle()),
        ],
        [
          NodeType.ITDAUSERDEFOUT.getTitle(),
          () =>
            new ITDANodes.ITDAUSERDEFOUT(NodeType.ITDAUSERDEFOUT.getTitle()),
        ],
        [
          NodeType.ITDAUSERDEFIN.getTitle(),
          () => new ITDANodes.ITDAUSERDEFIN(NodeType.ITDAUSERDEFIN.getTitle()),
        ],
      ];
    case DiagramType.SEQ:
      return [
        [
          NodeType.ITDASEQWRITE.getTitle(),
          () => new ITDANodes.ITDASEQWRITE(NodeType.ITDASEQWRITE.getTitle()),
        ],
        [
          NodeType.ITDASEQREADWAIT.getTitle(),
          () =>
            new ITDANodes.ITDASEQREADWAIT(NodeType.ITDASEQREADWAIT.getTitle()),
        ],
        [
          NodeType.ITDASEQWAIT.getTitle(),
          () => new ITDANodes.ITDASEQWAIT(NodeType.ITDASEQWAIT.getTitle()),
        ],
        [
          NodeType.ITDASEQIF.getTitle(),
          () => new ITDANodes.ITDASEQIF(NodeType.ITDASEQIF.getTitle()),
        ],
        [
          NodeType.ITDASEQGOTO.getTitle(),
          () => new ITDANodes.ITDASEQGOTO(NodeType.ITDASEQGOTO.getTitle()),
        ],
        [
          NodeType.ITDASEQCALL.getTitle(),
          () => new ITDANodes.ITDASEQCALL(NodeType.ITDASEQCALL.getTitle()),
        ],
        [
          NodeType.ITDASEQEND.getTitle(),
          () => new ITDANodes.ITDASEQEND(NodeType.ITDASEQEND.getTitle()),
        ],
      ];
    case DiagramType.SDC:
      return [
        [
          NodeType.ITDAINSTANCESDC.getTitle(),
          () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCESDC),
        ],
      ];
    case DiagramType.DUT:
      return [
        [
          NodeType.ITDAINSTANCEBLOCK.getTitle(),
          () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEBLOCK),
        ],
        [
          NodeType.ITDAINSTANCECMU.getTitle(),
          () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCECMU),
        ],
        [
          NodeType.ITDAINSTANCEPMC.getTitle(),
          () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEPMC),
        ],
        [
          NodeType.ITDAINSTANCEPMIF.getTitle(),
          () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEPMIF),
        ],
      ];
    case DiagramType.UPF:
      return [
        [
          NodeType.ITDAUPFLEVELSHIFTER.getTitle(),
          () =>
            new ITDANodes.ITDAUPFLEVELSHIFTER(
              NodeType.ITDAUPFLEVELSHIFTER.getTitle()
            ),
        ],
        [
          NodeType.ITDAINSTANCEHARDMACRO.getTitle(),
          () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEHARDMACRO),
        ],
        [
          NodeType.ITDAINSTANCEPOWERDOMAIN.getTitle(),
          () => new ITDANodes.ITDAINSTANCE(NodeType.ITDAINSTANCEPOWERDOMAIN),
        ],
      ];
    default:
      return [];
  }
};
