/**
 *  Generated by Modeler
 *  timestamp: 2022-12-22 10:29:56.254900
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";
import ItemHandler from "../ItemHandler";
import ItemType from "../ItemType";

export default class ConfigurationElementChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
    this.addChecker(item.getFidValue(), this.checkValue, ErrorType.CONFIG);
  }
  checkName() {
    // TBD
  }
  checkValue(item) {
    const refItem = item.getItemRef();
    if (refItem) {
      if (item.getParent(ItemType.UPFModule)) {
        if (!ItemHandler.getRefItem(item.getValue())) {
          this.newError(`Configuration must be selected.`, item);
        }
      }
    } else {
      this.newError(`Reference itemRef does not exist.`, item);
    }
  }
}
