/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import PowerSequenceConfigurationFolderEvent from '@/model/src/events/PowerSequenceConfigurationFolderEvent'
import PowerSequenceConfigurationFolderChecker from '@/model/src/checkers/PowerSequenceConfigurationFolderChecker'
export default class PowerSequenceConfigurationFolder extends Item {
    FID_POWERSEQUENCECONFIGURATIONFOLDER_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerSequenceConfigurationFolder"}))
        
        this.setEvent(new PowerSequenceConfigurationFolderEvent(this))
        this.setChecker(new PowerSequenceConfigurationFolderChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "Power Control Sequence"
        }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerSequenceConfigurationFolder"
    }

    getSchema() {
        return {}
    }

    static getSchema() {
        return {}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "PowerSequenceConfiguration", "auto": false, "searchable": false, "title": "Power Control Sequence List", "headers": [{"title": "Name", "align": "start", "filterable": true, "value": "type", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    getFidLast() {
        return this.FID_POWERSEQUENCECONFIGURATIONFOLDER_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            default:
                return ""
        }
    }


    newPowerSequenceConfiguration(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceConfiguration", properties, title, domain)
    }
    remPowerSequenceConfiguration(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceConfigurations() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerSequenceConfiguration")
            return res
        })
    }
}