import { ClassicPreset } from "rete";
import { VuePlugin, Presets } from "rete-vue-plugin";
import { ITDACommon, AreaExtra, ITDACanvasType } from "./types";
import * as ITDANodes from "../nodes";
import * as ITDAControl from "../controls";
import ITDACanvas from "../ITDACanvas";
import { getDOMSocketPosition } from "rete-render-utils";
import { Context, Instance } from "rete-vue-plugin/_types/vuecompat/types";
import { createApp } from "vue";
import vuetify from "@/plugins/vuetify";

type SocketContext = {
  element: HTMLElement;
  filled?: boolean | undefined;
  type: "socket";
} & {
  payload: ClassicPreset.Socket;
  nodeId: string;
  side: "input" | "output";
  key: string;
};

type ConnectionContext = {
  element: HTMLElement;
  filled?: boolean | undefined;
  type: "connection";
} & {
  payload: ITDACommon.ConnProps;
  start?: { x: number; y: number } | undefined;
  end?: { x: number; y: number } | undefined;
};

type ControlContext = {
  element: HTMLElement;
  filled?: boolean | undefined;
  type: "control";
} & {
  payload: ClassicPreset.Control;
};

type NodeContext = {
  element: HTMLElement;
  filled?: boolean | undefined;
  type: "node";
} & {
  payload: ITDACommon.NodeProps;
};

const socket = (context: SocketContext) => {
  if (context.payload instanceof ITDACommon.ITDASocket) {
    return ITDACommon.templates.ITDASocket;
  }
  return Presets.classic.Socket;
};

const connection = (context: ConnectionContext) => {
  if (context.payload instanceof ITDACommon.ITDAConnection) {
    return ITDACommon.templates.ITDAConnection;
  }
  // return Presets.classic.Connection;
  return ITDACommon.templates.ITDAConnection;
};

const control = (context: ControlContext) => {
  if (context.payload instanceof ITDAControl.ITDAControlEXT) {
    return ITDAControl.templates.ITDAControlEXT;
  } else if (context.payload instanceof ITDAControl.ITDAControlFrequency) {
    return ITDAControl.templates.ITDAControlFrequency;
  } else if (context.payload instanceof ITDAControl.ITDAControlTitle) {
    return ITDAControl.templates.ITDAControlTitle;
  }
  // else if (context.payload instanceof ITDAControl.ITDAControlPort) {
  //   return ITDAControl.templates.ITDAControlInput;
  // } else if (context.payload instanceof ITDAControl.ITDAControlInput) {
  //   return ITDAControl.templates.ITDAControlInput;
  // } else if (context.payload instanceof ITDAControl.ITDAControlOutput) {
  //   return ITDAControl.templates.ITDAControlOutput;
  // }
  else if (context.payload instanceof ITDAControl.ITDAControlDynamicSignal) {
    return ITDAControl.templates.ITDAControlSignal;
  } else if (context.payload instanceof ITDAControl.ITDAControlSDC) {
    return ITDAControl.templates.ITDAControlSDC;
  } else if (context.payload instanceof ITDAControl.ITDAControlDesc) {
    return ITDAControl.templates.ITDAControlDesc;
  }
  return Presets.classic.Control;
};

const node = (context: NodeContext) => {
  if (context.payload instanceof ITDANodes.ITDANode) {
    return ITDANodes.templates.ITDANodeWrapper;
  }
  return Presets.classic.Node;
};

export default class ITDARenderPlugin extends ITDACanvas {
  constructor(id: string) {
    super(id, ITDACanvasType.Render);
    this.res = new VuePlugin<ITDACommon.Schemes, AreaExtra>({
      setup: (context: Context): Instance => {
        return createApp(context).use(vuetify);
      },
    });
    this.res.addPreset(
      Presets.classic.setup({
        customize: {
          socket,
          connection,
          control,
          node,
        },
        socketPositionWatcher: getDOMSocketPosition({
          offset(
            { x, y },
            nodeId: string,
            side: "input" | "output",
            key: string
          ) {
            if (key.includes("QCH_INPUT")) {
              return {
                x: x - 13,
                y: y,
              };
            }
            if (key.includes("QCH_OUTPUT")) {
              return {
                x: x - 0.3,
                y: y - 13,
              };
            }
            return {
              x: x - 0.7,
              y: y,
            };
          },
        }),
      })
    );

    const reroute = this.repo.getConnectionReRoutePlugin(this.id).getInstance();
    this.res.addPreset(
      /* eslint-disable */
      // @ts-ignore
      Presets.reroute.setup({
        pointerdown(id) {
          reroute.unselect(id);
          reroute.select(id);
        },
        contextMenu(id) {
          reroute.remove(id);
        },
        translate(id, dx, dy) {
          reroute.translate(id, dx, dy);
        },
      })
    );
  }
  getInstance(): VuePlugin<ITDACommon.Schemes, AreaExtra> {
    return this.res as VuePlugin<ITDACommon.Schemes, AreaExtra>;
  }
  use() {
    // @ts-ignore
    this.res.use(this.repo.getConnectionReRoutePlugin(this.id).getInstance());
  }
}
