/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";
import ItemType from "../ItemType";

export default class DUTInstanceChecker extends ItemChecker {
  constructor(item) {
    super(item);
    this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME);
    this.addChecker(item.getFidModule(), this.checkModule, ErrorType.INSTANCE);
  }
  checkName(item) {
    if (!item.getName()) {
      this.newError(`name is null`, item);
    }
    const instance = item
      .getParent(ItemType.DUTModuleDiagram)
      .getDUTInstances()
      .find((cItem) => {
        return cItem !== item && cItem.getName() === item.getName();
      });
    if (instance) {
      this.newError(
        `instance name (${instance.getName()}) is duplicated`,
        instance
      );
    }
  }
  checkModule(item) {
    const module = item.getModule();
    if (!module && (item.getType() === "CMU" || item.getType() === "PMC")) {
      this.newError(`module must be selected`);
    }
  }
}
