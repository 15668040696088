<template>
  <ItemPropertyLabel :props="props" :isTitle="false" />
  <v-textarea
    v-model="model"
    variant="outlined"
    rows="7"
    :readonly="disabled"
    auto-grow
    density="compact"
    :error-messages="props.errorMessages"
    :hide-details="!props.errorMessages"
    :class="disabled ? 'textarea__disabled' : undefined"
    @change="Utils.change(props.item, props.propKey, String, $event)">
  </v-textarea>
</template>

<script setup lang="ts">
import { defineProps, ref } from "vue";
import Utils from "@/components/item/utils";
import { ItemPropsType } from "ITDAModelTypes";
import ItemPropertyLabel from "./ItemPropertyLabel.vue";
const props = defineProps<ItemPropsType>();
const model = ref(Utils.getDescription(props.item, props.propKey));
const disabled = Utils.isDisabledTextarea(
  props.item,
  props.propKey,
  props.prop
);
</script>

<style lang="sass" scoped>
.textarea__disabled
  color: darkgray
</style>
