import ItemEvent from "@/model/src/ItemEvent";

export default class ClockDiagram extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
  }
  lstn_name(item, newVal) {
    item.setTitle(newVal);
  }
}
