/**
 *  Generated by Modeler
 *  timestamp: 2023-11-13 15:18:10.047078
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";
import ItemHandler from "../ItemHandler";

export default class UPFMapperGroundChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidGround(), this.checkGround, ErrorType.NAME)
    this.addChecker(
      item.getFidPowerSource(),
      this.checkPowerSource,
      ErrorType.POWER_DIAGRAM
    );
    //this.addChecker(item.getFidMappedModuleGround(), this.checkMappedModuleGround, ErrorType.NAME)
    //this.addChecker(item.getFidNodeId(), this.checkNodeId, ErrorType.NAME)
  }
  checkGround() {
    // TBD
  }
  checkPowerSource(item) {
    const ps = item.getPowerSource();
    if (!ps) {
      this.newError(`Ground must be selected.`, item);
    }
  }
  checkMappedModuleGround() {
    // TBD
  }
  checkNodeId() {
    // TBD
  }
}
