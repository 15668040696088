import node from "./node";
import comment from "./comment";
import connection from "./connection";
import plugin from "./plugin";
import utils from "../utils";
import { Repository } from "../store";
import ITDAEditor from "../ITDAEditor";
import { DiagramType } from "../types";

export default {
  ...node,
  ...comment,
  ...connection,
  ...plugin,
  ...utils,
  createEditor: (id: string, elem: HTMLElement, diagramType: DiagramType) => {
    return new ITDAEditor(id, elem, diagramType);
  },
  setImported: (imported: boolean) => {
    Repository.getInstance().setImported(imported);
  },
  getImported: (): boolean => {
    return Repository.getInstance().getImported();
  },
  setModuleImported: (imported: boolean) => {
    Repository.getInstance().setModuleImported(imported);
  },
  getModuleImported: (): boolean => {
    return Repository.getInstance().getModuleImported();
  },
};
