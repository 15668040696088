import ITDANode from "./ITDANode";
import * as ITDANodeRenderer from "./renderers";
import { SourceType, NodeType } from "../types";

export default class ITDAUPFSOURCE extends ITDANode {
  private sourceType: SourceType;
  constructor(nodeType: NodeType) {
    super(nodeType.getTitle(), nodeType);
    this.render = ITDANodeRenderer.ITDAUPFSOURCE.render;
    this.sourceType = nodeType.getSubType();
  }

  set width(data: number) {
    super.width = this.adjustNodeToGrid(data);
  }

  get width() {
    const nodeStroke = this._nodeStroke;
    return this.adjustNodeToGrid(super.width) + nodeStroke / 2;
  }

  set height(data: number) {
    super.height = this.adjustNodeToGrid(data);
  }

  get height() {
    const nodeStroke = this._nodeStroke;
    return this.adjustNodeToGrid(super.height) + nodeStroke;
  }

  getSourceType(): SourceType {
    return this.sourceType;
  }
}
