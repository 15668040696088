/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemType from "../ItemType";

export default class ClockFreqmonEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), () => {});
  }

  postProcess(item) {
    super.postProcess(item);
    return this.setupPorts(item);
  }

  setupPorts(item) {
    return item.newInput({
      key: `CLK_${ItemType.Input.getKey().toUpperCase()}`,
      socket: "CLK",
    }).promise;
  }
}
