<template>
  <div
    class="node"
    :class="[selected(), ...docked()]"
    :style="style()"
    data-testid="node">
    <ITDANodeResizer
      v-if="props.data.selected && props.data.resizable"
      :key="key"
      :data="props.data"
      :selected="props.data.selected" />
    <div ref="shape" class="shadow" :style="shadowStyle()"></div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, onMounted, onUpdated, watch } from "vue";
import API from "@/api/internal";
import Utils from "./utils";
import { NMDividerConfigurationReturnType } from "../types";
import ITDANodeResizer from "./ITDANodeResizer.vue";
import ITDAINSTANCE from "../ITDAINSTANCE";
import ITDAMODULE from "../ITDAMODULE";
import ITDAUPFSOURCE from "../ITDAUPFSOURCE";
import ITDANodeSequence from "../ITDANodeSequence";
import ITDASEQSTART from "../ITDASEQSTART";
import ITDASEQLABEL from "../ITDASEQLABEL";
import ITDASEQLABELREF from "../ITDASEQLABELREF";
import ITDALABELREF from "../ITDALABELREF";
import ITDALABEL from "../ITDALABEL";
import { ItemType } from "ITDAModelTypes";
import ITDAGFMUX from "../ITDAGFMUX";
import ITDASDCCLOCKGROUP from "../ITDASDCCLOCKGROUP";

const props = defineProps<{
  data: any;
  emit: (event: string, ...args: any[]) => void;
  seed: any;
}>();
const shape = ref<HTMLElement | null>(null);
let key = 0;

const selected = () => {
  return props.data.selected ? "selected" : "";
};

const style = () => {
  const res = {};
  const domain = API.getCurrentDomain();
  if (props.data.selected) {
    if (domain === "Clock") {
      Object.assign(res, {
        left: "-0.5px",
      });
    } else if (domain === "Power") {
      Object.assign(res, {
        left: "-1px",
      });
    }
    return res;
  }
};
// props.data.dock이면  v-tooltip 보여주도록
const shadowStyle = () => {
  return {
    display: "flex",
  };
};

const docked = () => {
  const res = [];
  if (props.data.dock) {
    res.push("docked");

    if (props.data.label === "GFMUX" || props.data.label === "REFCLKMUX") {
      res.push("docked6");
    } else {
      res.push(props.data.height >= 80 ? "docked5" : "docked7");
    }
    if (props.data.height <= 40) {
      res.push("docked8");
    }

    if (props.data.height === 80 && props.data.width === 80)
      res.push("docked3");
  }
  return res;
};

const rerenderShape = (width: number, height: number) => {
  if (shape.value) {
    if (shape.value.firstChild) {
      shape.value?.removeChild(shape.value.firstChild);
    }
    props.data.render(shape.value, width, height, getColor(), getOptions());
  }
};

const getColor = (): string | undefined => {
  if (props.data instanceof ITDAINSTANCE) {
    return props.data.getInstanceType().getColor();
  } else if (props.data instanceof ITDAMODULE) {
    return props.data.getModuleType().getColor();
  } else if (props.data instanceof ITDAUPFSOURCE) {
    return props.data.getSourceType().getColor();
  } else if (props.data instanceof ITDASEQLABEL) {
    const item = API.getItem(props.data.getItemID());
    return item && item.getColor()
      ? item.getColor()
      : props.data.getType().getColor();
  } else if (props.data instanceof ITDASEQLABELREF) {
    const item = API.getItem(props.data.getItemID());
    if (item?.getItemLabel()) {
      return item.getItemLabel().getColor();
    } else return props.data.getType().getColor();
  } else if (props.data instanceof ITDASDCCLOCKGROUP) {
    const item = API.getItem(props.data.getItemID());
    if (item?.getColor) {
      return item.getColor();
    } else {
      return "transparent";
    }
  } else {
    return props.data.getType().getColor();
  }
};

const getOptions = () => {
  const res = {
    title: getTitle(),
    type: getType(),
    configValue: getConfiguration(),
    isDocked: props.data.isDocked(),
  };
  if (!props.data.isDocked() && props.data instanceof ITDAGFMUX) {
    return Object.assign(res, {
      numOfPorts: props.data.getCtrlPort().getLeftPorts().length,
    });
  }
  if (
    props.data instanceof ITDASEQLABEL ||
    props.data instanceof ITDASEQLABELREF
  ) {
    return Object.assign(res, {
      ports: props.data.getCtrlPort(),
      nodeId: props.data.id,
    });
  }
  if (props.data instanceof ITDASDCCLOCKGROUP) {
    const item = API.getItem(props.data.getItemID());
    if (item) {
      return {
        name: item.getName(),
      };
    }
  }
  return res;
};
const getTitle = (): string | undefined => {
  if (props.data.isDocked() && !(props.data instanceof ITDANodeSequence)) {
    return undefined;
  }

  if (props.data instanceof ITDANodeSequence) {
    if (
      props.data instanceof ITDASEQSTART ||
      props.data instanceof ITDASEQLABEL
    ) {
      const item = API.getItem(props.data.getItemID());
      return item?.getName();
    } else if (props.data instanceof ITDASEQLABELREF) {
      const item = API.getItem(props.data.getItemID());
      if (item) {
        return item.getItemLabel()?.getName();
      }
    } else {
      return props.data.getType().getTitle();
    }
  }
};

const getType = (): string | undefined => {
  if (props.data.isDocked()) {
    return undefined;
  }

  if (props.data instanceof ITDALABEL || props.data instanceof ITDALABELREF) {
    const item = API.getItem(props.data.getItemID());
    return item
      ? item.getItemType() === ItemType.SDCPhantom
        ? item.getOriginItem()?.getType()
        : item.getType()
      : undefined;
  }
};

const getConfiguration = ():
  | number
  | undefined
  | NMDividerConfigurationReturnType => {
  if (props.data.isDocked()) {
    return undefined;
  }

  return Utils.getConfiguration(props.data);
};

watch(
  () => [props.data.width, props.data.height] as [number, number],
  ([newWidth, newHeight], [oldWidth, oldHeight]) => {
    if (newWidth !== oldWidth || newHeight !== oldHeight) {
      rerenderShape(newWidth, newHeight);
    }
  }
);

onUpdated(() => {
  rerenderShape(props.data.width, props.data.height);
  if (props.data.selected) {
    key = key + 1;
  }
});

onMounted(() => {
  rerenderShape(props.data.width, props.data.height);
});
</script>

<style lang="sass">
@use "sass:math"
$node-color: rgba(110,136,255,0.8)
.node
  position: absolute
  top: 0
  left: 0
  display: flex
  flex-direction: column
  align-items: center
  z-index: 5
  user-select: none
  &.docked
    display: flex
    position: relative

  &.docked
    width: 50%
    justify-items: center
    align-content: center
    justify-content: center
    &.docked3
      zoom: 0.3
    &.docked5
      zoom: 0.53
    &.docked6
      zoom: 0.6
    &.docked7
      zoom: 0.7
    &.docked8
      zoom: 0.9

  &.selected
    border: 1px dashed #363636
    top: -1px
  .shadow
    filter: drop-shadow(-1px 5px 3px rgba(0, 0, 0, 0.2))
</style>
