import API from "@/api/internal";

interface CurrentDomainState {
  name: string;
}

const state = (): CurrentDomainState => ({
  name: "",
});

const mutations = {
  SET_CURRENT_DOMAIN(state: CurrentDomainState, name: string) {
    state.name = name;
    API.changeDomain(name);
  },
};
const getters = {
  GET_CURRENT_DOMAIN(state: CurrentDomainState) {
    return state.name;
  },
};
const actions = {};
export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
