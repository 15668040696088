import ItemEvent from "@/model/src/ItemEvent";
import LabelType from "../LabelType";
import ItemType from "../ItemType";
import ItemHandler from "../ItemHandler";

export default class LabelRef extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidType(), this.lstn_type);
    // this.addPropertyListener(item.getFidLabel(), this.lstn_label)
    // this.addPropertyListener(item.getFidNodeId(), this.lstn_nodeId)

    this.addPropertyListener(item.getFidItemLabel(), this.lstn_item_label);
  }
  lstn_type(item, newVal) {
    if (newVal === LabelType.EXTERNAL.getKey()) {
      item.setItemLabel(null);
    }
  }
  // lstn_label(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
  // lstn_nodeId(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }

  lstn_item_label(item, newVal, oldVal) {
    if (newVal) {
      const label = newVal;
      const res = [...label.getLabelRefs()];
      if (res.findIndex((o) => o === item) < 0) {
        res.push(item);
        label.setLabelRefs(res);
      }
    }
    if (oldVal) {
      const label = oldVal;
      const res = label.getLabelRefs().filter((o) => o !== item);
      label.setLabelRefs(res);
    }

    // var res = [];
    // if (newVal) {
    //   const labelItem = item.getRefItem(newVal);
    //   res = [...labelItem.getLabelRefs()];
    //   if (res.indexOf(item.getId()) < 0) {
    //     res.push(item.getId());
    //     labelItem.setLabelRefs(res);
    //   }
    // }
    // if (oldVal) {
    //   const labelItem = item.getRefItem(oldVal);
    //   if (labelItem) {
    //     res = [...labelItem.getLabelRefs()].filter((id) => {
    //       return id !== item.getId();
    //     });
    //     labelItem.setLabelRefs(res);
    //   }
    // }
  }

  postProcess(item) {
    return this.setupPorts(item);
  }

  setupPorts(item) {
    return ItemHandler.createItem(item, ItemType.Output, {
      key: `CLK_${ItemType.Output.getKey().toUpperCase()}`,
      socket: "CLK",
    }).promise;
  }
}
