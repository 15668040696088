import InstanceHandler from "../../InstanceHandler";
import ItemHandler from "../../ItemHandler";
import ItemType from "../../ItemType";

export const lstn_connection_added = (item, value) => {
  if (value.getItemType() !== ItemType.Connection) {
    return [];
  }

  const promises = [];
  const sourceOutput = value.getSourceOutput();
  const targetInput = value.getTargetInput();
  promises.push(
    sourceOutput?.setConnections([...sourceOutput.getConnections(), value])
  );
  promises.push(
    targetInput?.setConnections([...targetInput.getConnections(), value])
  );

  const source = sourceOutput?.getParent();
  const target = targetInput?.getParent();
  if (target.getItemType() === ItemType.DUTInstance) {
    if (target?.getType() === "PMIF") {
      promises.push(
        InstanceHandler.createCLINKOutputs(
          target,
          source.getModule(),
          sourceOutput.getName()
        )
      );
    }
  }
  return promises;
};

export const lstn_connection_removed = (item, value) => {
  if (value.getItemType() !== ItemType.Connection) {
    return [];
  }

  const promises = [];
  const sourceOutput = value.getSourceOutput();
  const targetInput = value.getTargetInput();
  promises.push(
    sourceOutput?.setConnections([
      ...sourceOutput.getConnections().filter((o) => o && o !== value),
    ])
  );
  promises.push(
    targetInput?.setConnections([
      ...targetInput.getConnections().filter((o) => o && o !== value),
    ])
  );

  const source = sourceOutput?.getParent();
  const target = targetInput?.getParent();
  if (target?.getItemType() === ItemType.DUTInstance) {
    if (target?.getType() === "PMIF") {
      promises.push(InstanceHandler.removeOutputs(target));
    }
  }

  if (source?.getItemType() === ItemType.PowerSequenceLabel) {
    promises.push(ItemHandler.removeItem(source));
  }
  if (
    target?.getItemType() === ItemType.PowerSequenceLabelRef ||
    target?.getItemType() === ItemType.PowerSequenceBack
  ) {
    promises.push(ItemHandler.removeItem(target));
  }

  return promises;
};
