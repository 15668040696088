/**
 *  Generated by Modeler
 *  timestamp: 2024-08-21 02:24:06.859731
 */
//import ErrorType from '@/model/src/ErrorType'
import ItemChecker from '@/model/src/ItemChecker'

export default class NodeMetaViewChecker extends ItemChecker {
    constructor(item) {
        super(item)
        //this.addChecker(item.getFidX(), this.checkX, ErrorType.NAME)
        //this.addChecker(item.getFidY(), this.checkY, ErrorType.NAME)
    }
    checkX() {
        // TBD
    }
    checkY() {
        // TBD
    }
}