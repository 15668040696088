import {
  Item,
  VariantLevel,
  RefItemElement,
  RefItemElementType,
  RefItemElementTarget,
  Property,
  RefItemEvent,
} from "ITDAModelTypes";
import API from "@/api/internal";
import utils from "@/utils";

export const getRefCLKElements = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const refclk = item.getRefCLK();
  const model = refclk ? getModel(refclk) : undefined;
  const targets = getTargets(item);
  return [getElement(model, targets, propKey, eventRefCLK)];
};

export const getPCLKElements = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const pclk = item.getPCLK();
  const model = pclk ? getModel(pclk) : undefined;
  const targets = getTargets(item);
  return [getElement(model, targets, propKey, eventPCLK)];
};

const getModel = (refItem: Item): string | undefined => {
  const diagram = API.getParentDiagram(refItem);
  if (diagram) {
    return API.getTitle(refItem);
  }
};

const getTargets = (item: Item): RefItemElementTarget[] => {
  const targets: RefItemElementTarget[] = [];
  item
    .getClockDiagramFolder()
    .getClockDiagrams()
    .forEach((diagram: Item) => {
      diagram.getLabels().forEach((labelItem: Item) => {
        const key = API.getTitle(labelItem);
        if (key) {
          targets.push({
            key: key,
            item: labelItem,
            closable: true,
          });
        }
      });
    });
  return targets;
};

const getElement = (
  model: string | undefined,
  targets: RefItemElementTarget[],
  propKey: string,
  event: RefItemEvent
): RefItemElement => {
  return {
    variant: VariantLevel.LEVEL0,
    disabled: false,
    type: RefItemElementType.ENUM,
    propKey: propKey,
    label: "",
    multiple: false,
    model: model,
    targets: targets,
    event: event,
  };
};

const eventRefCLK = (
  item: Item,
  elem: RefItemElement,
  value: string | string[] | number | number[]
) => {
  const target = elem.targets?.find((target) => target.key === value);
  if (target) {
    item.setRefCLK(target.item.getId());
  } else {
    item.setRefCLK(null);
  }
};

const eventPCLK = (
  item: Item,
  elem: RefItemElement,
  value: string | string[] | number | number[]
) => {
  const target = elem.targets?.find((target) => target.key === value);
  if (target) {
    item.setPCLK(target.item.getId());
  } else {
    item.setPCLK(null);
  }
};
