import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);
    const centerX = width / 2;
    const centerY = height / 2;
    const innerStroke = 2;
    const radius = width / 8;

    g.append("rect")
      .attr("x", (centerX / 4) * 2)
      .attr("y", (centerY / 4) * 2.5)
      .attr("width", (centerX / 4) * 4)
      .attr("height", (centerY / 4) * 4.6)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke * 1.4)
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("fill", color);

    g.append("line")
      .attr("x1", (centerX / 4) * 2.5)
      .attr("y1", (centerY / 4) * 3.3)
      .attr("x2", (centerX / 4) * 5.5)
      .attr("y2", (centerY / 4) * 3.3)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke * 1.4);

    g.append("line")
      .attr("x1", (centerX / 4) * 2.5)
      .attr("y1", (centerY / 4) * 4.3)
      .attr("x2", (centerX / 4) * 5.5)
      .attr("y2", (centerY / 4) * 4.3)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke * 1.4);

    g.append("line")
      .attr("x1", (centerX / 4) * 2.5)
      .attr("y1", (centerY / 4) * 5.3)
      .attr("x2", (centerX / 4) * 5.5)
      .attr("y2", (centerY / 4) * 5.3)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke * 1.4);

    g.append("line")
      .attr("x1", (centerX / 4) * 2.5)
      .attr("y1", (centerY / 4) * 6.3)
      .attr("x2", (centerX / 4) * 4.5)
      .attr("y2", (centerY / 4) * 6.3)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke * 1.4);

    g.append("circle")
      .attr("cx", (centerX / 4) * 5.6)
      .attr("cy", (centerY / 4) * 6.3)
      .attr("r", radius * 1.2)
      .attr("fill", "black")
      .attr("stroke", "white")
      .attr("stroke-width", 2.4);

    g.append("path")
      .attr(
        "d",
        "M8.9472 1.01547L8.948 1.01467C9.13425 0.828415 9.33241 0.702066 9.54309 0.625453C9.77705 0.54038 10.0045 0.5 10.2278 0.5C10.4746 0.5 10.7084 0.545933 10.9329 0.637774C11.1524 0.727556 11.3567 0.863801 11.5464 1.05356L12.9853 2.49244C13.1716 2.67869 13.2979 2.87685 13.3745 3.08754C13.4596 3.32149 13.5 3.54896 13.5 3.77222C13.5 3.98788 13.4561 4.21785 13.3594 4.46502C13.2683 4.69789 13.1327 4.90615 12.9486 5.09222C12.9485 5.09237 12.9483 5.09252 12.9482 5.09267L4.57562 13.5H0.5V9.42444L8.9472 1.01547Z"
      )
      .attr(
        "transform",
        `translate(${(centerX / 4) * 5}, ${(centerY / 4) * 5.6})`
      )
      .attr("fill", "white")
      .attr("stroke", "black");

    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 1.5)
      .attr("text-anchor", "middle")
      .attr("font-size", "10px")
      .attr("fill", "black")
      .text("LATCH");
  },
};
