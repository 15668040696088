/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemType from "../ItemType";

export default class PowerSequenceStartEvent extends ItemEvent {
  constructor(item) {
    super(item);
  }

  postProcess(item) {
    return this.setupPorts(item);
  }

  setupPorts(item) {
    return item.newOutput({
      key: `SEQREF_${ItemType.Output.getKey().toUpperCase()}`,
      socket: "SEQREF",
      multiple: false,
    }).promise;
  }
}
