import * as ITDANodes from "../nodes";
import { Repository } from "../store";
import { NodeType } from "../types";

const repo = Repository.getInstance();
export default {
  getNodeType: (diagramID: string, nodeID: string): NodeType | undefined => {
    const editor = repo.getEditor(diagramID);
    if (editor) {
      const node: ITDANodes.ITDANode = editor.getNode(nodeID);
      return node ? node.getType() : undefined;
    } else {
      return;
    }
  },
};
