import * as d3 from "d3";

export default {
  render: (
    elem: HTMLElement,
    width: number,
    height: number,
    color: string,
    options: { [key: string]: string }
  ) => {
    const { name } = options;
    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const baseColor = color;
    const hoverColor = d3.hsl(baseColor);
    hoverColor.l = Math.min(hoverColor.l + 0.05, 1);

    const group = svg.append("g");

    const component = group
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .attr("fill", baseColor)
      .attr("stroke", "black")
      .attr("stroke-width", 3);

    group
      .append("text")
      .attr("x", width / 2)
      .attr("y", height / 2)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "central")
      .attr("font-weight", "bold")
      .attr("fill", setContrast(color) || "black")
      .text(name);

    component
      .on("mouseover", function () {
        d3.select(this).attr("fill", hoverColor.toString());
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", baseColor);
      });
  },
};

const setContrast = (baseColor: string) => {
  if (baseColor) {
    const rgb = hexToRgb(baseColor);
    if (rgb) {
      const brightness = Math.round(
        (parseInt(rgb.r.toString()) * 299 +
          parseInt(rgb.g.toString()) * 587 +
          parseInt(rgb.b.toString()) * 114) /
          1000
      );
      return brightness > 125 ? "black" : "white";
    }
  }
};

const hexToRgb = (hex: string) => {
  const bigint = parseInt(hex.slice(1), 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return {
    r,
    g,
    b,
  };
};
