/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import ClockRefCLKMultiplexerEvent from '@/model/src/events/ClockRefCLKMultiplexerEvent'
import ClockRefCLKMultiplexerChecker from '@/model/src/checkers/ClockRefCLKMultiplexerChecker'
import ITDA_CMU_REFCLKMUX_PT from '@/model/gen/sfrSpec/ITDA_CMU_REFCLKMUX_PT'
export default class ClockRefCLKMultiplexer extends Node {
    FID_CLOCKREFCLKMULTIPLEXER_NAME = 100
    FID_CLOCKREFCLKMULTIPLEXER_MAXNUM = 101
    FID_CLOCKREFCLKMULTIPLEXER_ECC = 102
    FID_CLOCKREFCLKMULTIPLEXER_SDCPATH = 103
    FID_CLOCKREFCLKMULTIPLEXER_LAST = 103

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockRefCLKMultiplexer"}))
        
        this.setEvent(new ClockRefCLKMultiplexerEvent(this))
        this.setChecker(new ClockRefCLKMultiplexerChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.maxNum === undefined) {
        this.properties.maxNum = 128
    }
    if (this.properties.ECC === undefined) {
        this.properties.ECC = false
    }
    if (this.properties.SDCPath === undefined) {
        this.properties.SDCPath = "/{{CMU}}/{{NODE}}/{{NODE}}__CLKCOMP_GFMUX/{{NODE}}__CLKCOMP_GFMUX__CLKOR/$POINT$ITDA_GENERIC_CLKOR2$Y"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockRefCLKMultiplexer"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 128, "category": "IP", "multiple": false, "readOnly": false}, "ECC": {"type": "boolean", "label": "External Clock Path", "desc": "Control External Clock", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "/{{CMU}}/{{NODE}}/{{NODE}}__CLKCOMP_GFMUX/{{NODE}}__CLKCOMP_GFMUX__CLKOR/$POINT$ITDA_GENERIC_CLKOR2$Y", "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 128, "category": "IP", "multiple": false, "readOnly": false}, "ECC": {"type": "boolean", "label": "External Clock Path", "desc": "Control External Clock", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "/{{CMU}}/{{NODE}}/{{NODE}}__CLKCOMP_GFMUX/{{NODE}}__CLKCOMP_GFMUX__CLKOR/$POINT$ITDA_GENERIC_CLKOR2$Y", "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ClockRefCLKMultiplexerEXT", "auto": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_CMU_REFCLKMUX_PT
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_CMU_REFCLKMUX_PT
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKREFCLKMULTIPLEXER_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKREFCLKMULTIPLEXER_NAME
    }
    

    setMaxNum(maxNum, event=true) {
        if ((maxNum !== null) && (maxNum !== undefined)) {
            if (![Number].includes(maxNum.constructor)) {
                throw `maxNum(${maxNum}) must be [Number] type`
            }
        }
        const newVal = (maxNum === null || maxNum === undefined) ? undefined : maxNum
        const oldVal = this.properties.maxNum
        this.properties.maxNum = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKREFCLKMULTIPLEXER_MAXNUM, newVal, oldVal)
        }
    }
    getMaxNum(pure = false) {
        return this.properties.maxNum
        
    }
    getFidMaxNum() {
        return this.FID_CLOCKREFCLKMULTIPLEXER_MAXNUM
    }
    

    setECC(ECC, event=true) {
        if ((ECC !== null) && (ECC !== undefined)) {
            if (![Boolean].includes(ECC.constructor)) {
                throw `ECC(${ECC}) must be [Boolean] type`
            }
        }
        const newVal = (ECC === null || ECC === undefined) ? undefined : ECC
        const oldVal = this.properties.ECC
        this.properties.ECC = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKREFCLKMULTIPLEXER_ECC, newVal, oldVal)
        }
    }
    getECC(pure = false) {
        return this.properties.ECC
        
    }
    getFidECC() {
        return this.FID_CLOCKREFCLKMULTIPLEXER_ECC
    }
    

    setSDCPath(SDCPath, event=true) {
        if ((SDCPath !== null) && (SDCPath !== undefined)) {
            if (![String].includes(SDCPath.constructor)) {
                throw `SDCPath(${SDCPath}) must be [String] type`
            }
        }
        const newVal = (SDCPath === null || SDCPath === undefined) ? undefined : SDCPath
        const oldVal = this.properties.SDCPath
        this.properties.SDCPath = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKREFCLKMULTIPLEXER_SDCPATH, newVal, oldVal)
        }
    }
    getSDCPath(pure = false) {
        return this.properties.SDCPath
        
    }
    getFidSDCPath() {
        return this.FID_CLOCKREFCLKMULTIPLEXER_SDCPATH
    }
    

    getFidLast() {
        return this.FID_CLOCKREFCLKMULTIPLEXER_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKREFCLKMULTIPLEXER_NAME:
                return "name"

            case this.FID_CLOCKREFCLKMULTIPLEXER_MAXNUM:
                return "maxNum"

            case this.FID_CLOCKREFCLKMULTIPLEXER_ECC:
                return "ECC"

            case this.FID_CLOCKREFCLKMULTIPLEXER_SDCPATH:
                return "SDCPath"

            default:
                return ""
        }
    }


    newClockRefCLKMultiplexerEXT(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockRefCLKMultiplexerEXT", properties, title, domain)
    }
    remClockRefCLKMultiplexerEXT(obj) {
        return this.removeChild(obj)
    }
    getClockRefCLKMultiplexerEXT() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "ClockRefCLKMultiplexerEXT")
            return res
        })
    }
}