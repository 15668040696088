import ItemEvent from "@/model/src/ItemEvent";
import Repository from "../../store";
import ItemFactory from "../ItemFactory";
import SFRHandler from "../SFRHandler";
export default class SFRBlockEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidSorted(), this.lstn_sorted);

    this.addPropertyListener(item.getFidName(), () => {});
    this.addPropertyListener(item.getFidLast() + 10, this.lstn_update_name);
  }
  lstn_sorted(item, newVal) {
    if (newVal) {
      item.getChildren().sort(function (a, b) {
        if (parseInt(a.getOffset()) < parseInt(b.getOffset())) return -1;
        if (parseInt(a.getOffset()) > parseInt(b.getOffset())) return 1;
        return 0;
      });
    }
  }

  lstn_update_name(item, newVal) {
    item.setName(`${newVal}__SFR`);
  }

  postProcess(item) {
    item.setTitle("SFR", false);
    item.getEvent().lstn_update_name(item, item.getParent().getName());
  }
}
