
export default class Error {
    constructor(item, type, desc) {
        this.itemId = item.getId()
        this.type = type
        this.desc = desc
        this.references = []
    }

    getItemId() {
        return this.itemId
    }

    getType() {
        return this.type
    }

    getDescription() {
        return this.desc
    }

    addReference(id) {
        if (this.references.indexOf(id) < 0) {
            this.references.push(id)
        }
    }

    getReferences() {
        return this.references
    }
}