import { ItemType } from "ITDAModelTypes";
import API from "@/api";

export const showConfiguration = () => {
  const currentItem = API.getCurrentItem();
  const currentNode = API.getCurrentNodeItem();
  if (!currentItem || !currentNode) return false;
  if (currentItem.getParent(ItemType.ClockCMU)) {
    return API.getSelectedCMUConfiguration(currentItem) ? true : false;
  } else if (currentItem.getParent(ItemType.PowerPMD)) {
    return API.getSelectedPMDConfiguration(currentItem) ? true : false;
  } else if (currentItem.getParent(ItemType.PowerPMC)) {
    return API.getSelectedPMCConfiguration(currentItem) ? true : false;
  } else if (currentNode.getItemType() === ItemType.SDCInstance) {
    return API.getSelectedVoltageConfiguration(currentNode) ? true : false;
  }
  return false;
};
