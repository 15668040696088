import * as d3 from "d3";

export default {
  render: (
    elem: HTMLElement,
    width: number,
    height: number,
    color: string,
    options: any
  ) => {
    const { isDocked, configValue } = options;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", isDocked ? width * 0.55 : width)
      .attr("height", isDocked ? height * 0.75 : height);

    const drawerGroup = svg
      .append("g")
      .attr("transform", isDocked ? `scale(0.55, 0.75)` : `scale(1)`);

    const baseColor = color;
    const hoverColor = d3.hsl(baseColor);
    hoverColor.l = Math.min(hoverColor.l + 0.05, 1);

    const component = drawerGroup
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .attr("fill", baseColor)
      .attr("rx", 10)
      .attr("ry", 10);

    component
      .on("mouseover", function () {
        d3.select(this).attr("fill", hoverColor.toString());
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", baseColor);
      });

    const grid = 12;
    const gridHeight = height / grid;
    const gridWidth = width / grid;

    drawerGroup
      .append("line")
      .attr("x1", gridWidth * 2)
      .attr("y1", gridHeight * 9)
      .attr("x2", gridWidth * 10)
      .attr("y2", gridHeight * 2)
      .attr("stroke", "black")
      .attr("stroke-width", 2);

    if (!isDocked) {
      drawerGroup
        .append("text")
        .attr("x", gridWidth * 9)
        .attr("y", gridHeight * 8)
        .attr("text-anchor", "middle")
        .attr("dominant-baseline", "middle")
        .attr("font-size", "15px")
        .attr("fill", "red")
        .text(configValue === undefined ? "N" : configValue.toString());
    }
  },
};
