import * as ITDANodeRenderer from "./renderers";
import { NodeType } from "../types";
import ITDANode from "./ITDANode";

export default class ITDAQCHINFO extends ITDANode {
  constructor(name = "") {
    super(name, NodeType.ITDAQCHINFO);
    this.render = ITDANodeRenderer.ITDAQCHINFO.render;
  }

  set width(data: number) {
    super.width = this.adjustNodeToGrid(data);
  }

  get width() {
    return this.adjustNodeToGrid(super.width);
    // -
    // this.repo.getEditor(this.repo.getCurrentID()).getGridStroke() / 2
  }

  set height(data: number) {
    super.height = this.adjustNodeToGrid(data);
  }

  get height() {
    return this.adjustNodeToGrid(super.height);
    // -
    // this.repo.getEditor(this.repo.getCurrentID()).getGridStroke() / 2
  }
}
