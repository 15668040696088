<template>
  <div
    v-if="Number(props.data.getFrequency()) >= 0"
    ref="freqDesc"
    class="freq-desc"
    :style="style"
    @pointerdown.stop="">
    ({{ props.data.getFrequency() }} MHz)
  </div>
  <div v-else></div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";
import Canvas from "@/canvas";

const props = defineProps<{
  data: any;
}>();

const gridSpace = Canvas.getEditor(Canvas.getCurrentID()).getGridSpace();

const style = computed(() => {
  const baseGridSpace = 10;
  const baseFontSize = 0.625;

  const computedFontSize = (gridSpace / baseGridSpace) * baseFontSize;

  return {
    // width: `${props.data.node.width}px`,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    paddingTop: "2px",
    paddingBottom: "2px",
    fontSize: `${computedFontSize}rem`,
  };
});
</script>

<style lang="sass" scoped>
.freq-desc
  width: 100%
  color: #002cda
</style>
