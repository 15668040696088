/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemHandler from "../ItemHandler";
import ItemType from "../ItemType";

export default class UPFHardMacroEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
  }

  lstn_name(item, newVal) {
    item.setTitle(newVal);
  }

  postProcess(item) {
    ItemHandler.createItem(item, ItemType.UPFElement);
  }
}
