/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";

export default class PowerAPMChecker extends ItemChecker {
  constructor(item) {
    super(item);
    this.addChecker(item.getFidChildren(), this.checkChildren, ErrorType.EMPTY);
    //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
    //this.addChecker(item.getFidMaxNum(), this.checkMaxNum, ErrorType.NAME)
  }
  checkChildren(item) {
    const children = [
      ...item.getPowerAPMPWRDOWNs(),
      ...item.getPowerAPMPWRUPs(),
    ];

    if (children.length === 0) {
      this.newError(
        `APM must create at least one PWRDOWN or PWRUP signal`,
        item
      );
    }
  }
  checkName() {
    // TBD
  }
  checkMaxNum() {
    // TBD
  }
}
