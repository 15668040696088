/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import SDCModuleEvent from '@/model/src/events/SDCModuleEvent'
import SDCModuleChecker from '@/model/src/checkers/SDCModuleChecker'
export default class SDCModule extends Item {
    FID_SDCMODULE_NAME = 0
    FID_SDCMODULE_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"SDCModule"}))
        
        this.setEvent(new SDCModuleEvent(this))
        this.setChecker(new SDCModuleChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "BLK_NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "SDCModule"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "BLK_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "BLK_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "SDCDiagram", "auto": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SDCMODULE_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_SDCMODULE_NAME
    }
    

    getFidLast() {
        return this.FID_SDCMODULE_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_SDCMODULE_NAME:
                return "name"

            default:
                return ""
        }
    }


    newSDCDiagram(properties={}, title = "", domain = "") {
        return this.createChild(this, "SDCDiagram", properties, title, domain)
    }
    remSDCDiagram(obj) {
        return this.removeChild(obj)
    }
    getSDCDiagram() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "SDCDiagram")
            return res
        })
    }
}