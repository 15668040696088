import { ItemType } from "ITDAModelTypes";
import { Context } from "../types";
import API from "@/api";
import utils from "../utils";

export const nodeimported = async (context: Context): Promise<boolean> => {
  const editor = context.options.editor;
  const diagram = API.getItem(editor.getID());
  if (diagram?.getItemType() === ItemType.DUTModuleDiagram) {
    await utils.updateParentScopes(diagram);
  }
  return true;
};
