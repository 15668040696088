/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ConfigurationHandler from "../ConfigurationHandler";
import InstanceHandler from "../InstanceHandler";
import ItemHandler from "../ItemHandler";
import ItemType from "../ItemType";
import Utils from "./utils";

export default class PowerPMDInstanceEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
    this.addPropertyListener(item.getFidModule(), this.lstn_module);
  }

  lstn_name(item, newVal) {
    item.setTitle(newVal);

    const hasConnections = item.getInputs()[0]?.getConnections().length > 0;
    if (hasConnections) {
      const pmdGrpOutput = item
        .getInputs()[0]
        .getConnections()[0]
        .getSourceOutput();
      if (pmdGrpOutput) {
        const pmdGrpName = pmdGrpOutput.getParent().getName();
        const pmdGrpOutputIdx = Number(pmdGrpOutput.getKey().slice(-1));
        pmdGrpOutput
          .getParent()
          .getPowerPMRPMDCTRLs()
          [pmdGrpOutputIdx]?.setName(`${pmdGrpName}__${newVal}`);

        Utils.updateINTRChildrenTitle(
          pmdGrpOutput.getParent(),
          newVal,
          pmdGrpOutputIdx
        );
        Utils.updatePMR2PMDGRPSEL(pmdGrpOutput.getParent());
      }
    }
  }

  async lstn_module(item, newVal) {
    const PMC = item.getParent(ItemType.PowerPMC);
    const sequence = PMC.getPowerSequenceConfigurationFolder();
    const PMD = sequence?.getPowerSequenceConfigurations().find((config) => {
      return config.getType() === "PMD";
    });

    if (PMD && newVal) {
      const prevPMDs = PMD.getPowerSequenceDiagrams().filter((diagram) => {
        return diagram.getItemRef().getId() === item.getId();
      });
      prevPMDs.forEach((prevPMD) => {
        PMD.remPowerSequenceDiagram(prevPMD);
      });
      await PMD.newPowerSequenceDiagram({
        name: item.getName(),
        type: "RAM",
        itemRef: item.getId(),
      }).promise;
    } else {
      const sd = PMD?.getPowerSequenceDiagrams().find((psd) => {
        return psd.getItemRef() === item;
      });
      if (sd) PMD?.remPowerSequenceDiagram(sd);
    }

    InstanceHandler.removeAllPorts(item);
    InstanceHandler.createPMD2PMDGRP(item, newVal);
  }
}
