/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";

export default class UPFPowerSourceChecker extends ItemChecker {
  constructor(item) {
    super(item);
    this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME);
    //this.addChecker(item.getFidColor(), this.checkColor, ErrorType.NAME)
    this.addChecker(item.getFidGround(), this.checkGround, ErrorType.NAME);
    //this.addChecker(item.getFidIsNeedElements(), this.checkIsNeedElements, ErrorType.NAME)
  }
  checkName(item) {
    if (!item.getName()) {
      this.newError(`name is null`, item);
    }
  }

  checkColor() {
    // TBD
  }
  checkGround(item) {
    if (!item.getGround()) {
      this.newError(`ground must be selected.`, item);
    }
  }
  checkIsNeedElements() {
    // TBD
  }
}
