import * as connection from "./connection";
import * as port from "./port";
import * as sdc from "./sdc";
import * as dataflow from "./dataflow";
import * as sequence from "./sequence";
import * as upf from "./upf";
import * as scope from "./scope";

export default {
  ...connection,
  ...port,
  ...sdc,
  ...dataflow,
  ...sequence,
  ...upf,
  ...scope,
};
