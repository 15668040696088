import ErrorHandler from "../src/ErrorHandler";
import Repository from "../store";
import { Item } from "../types";

export const runChecker = (type?: string) => {
  ErrorHandler.runChecker(type);
};

export const clearErrors = () => {
  Object.values(Repository.getInstance().getAllItemMap()).forEach(
    (item: Item) => {
      item.clearErrors();
    }
  );
};
