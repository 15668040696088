/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import ConnectionEvent from '@/model/src/events/ConnectionEvent'
import ConnectionChecker from '@/model/src/checkers/ConnectionChecker'
export default class Connection extends Item {
    FID_CONNECTION_CONNECTIONID = 0
    FID_CONNECTION_BUILD = 1
    FID_CONNECTION_SOURCEOUTPUT = 2
    FID_CONNECTION_TARGETINPUT = 3
    FID_CONNECTION_LAST = 3

    constructor(obj) {
        super(Object.assign(obj,{itemType:"Connection"}))
        
        this.setEvent(new ConnectionEvent(this))
        this.setChecker(new ConnectionChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "Connection"
        }
    if (this.properties.build === undefined) {
        this.properties.build = false
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || true
    }

    static getClassName() {
        return "Connection"
    }

    getSchema() {
        return {"connectionID": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "build": {"type": "boolean", "hidden": true, "default": false, "category": "IP", "multiple": false, "readOnly": false}, "sourceOutput": {"type": "item", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "targetInput": {"type": "item", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"connectionID": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "build": {"type": "boolean", "hidden": true, "default": false, "category": "IP", "multiple": false, "readOnly": false}, "sourceOutput": {"type": "item", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "targetInput": {"type": "item", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setConnectionID(connectionID, event=true) {
        if ((connectionID !== null) && (connectionID !== undefined)) {
            if (![String].includes(connectionID.constructor)) {
                throw `connectionID(${connectionID}) must be [String] type`
            }
        }
        const newVal = (connectionID === null || connectionID === undefined) ? undefined : connectionID
        const oldVal = this.properties.connectionID
        this.properties.connectionID = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CONNECTION_CONNECTIONID, newVal, oldVal)
        }
    }
    getConnectionID(pure = false) {
        return this.properties.connectionID
        
    }
    getFidConnectionID() {
        return this.FID_CONNECTION_CONNECTIONID
    }
    

    setBuild(build, event=true) {
        if ((build !== null) && (build !== undefined)) {
            if (![Boolean].includes(build.constructor)) {
                throw `build(${build}) must be [Boolean] type`
            }
        }
        const newVal = (build === null || build === undefined) ? undefined : build
        const oldVal = this.properties.build
        this.properties.build = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CONNECTION_BUILD, newVal, oldVal)
        }
    }
    getBuild(pure = false) {
        return this.properties.build
        
    }
    getFidBuild() {
        return this.FID_CONNECTION_BUILD
    }
    

    setSourceOutput(sourceOutput, event=true) {
        if ((sourceOutput !== null) && (sourceOutput !== undefined)) {
            if (!(sourceOutput instanceof Item) && (sourceOutput.constructor !== String)) {
                throw `sourceOutput(${sourceOutput}) must be Item or String type`
            }
        }
        const newVal = (sourceOutput === null || sourceOutput === undefined) ? undefined : (sourceOutput.constructor === String) ? sourceOutput : sourceOutput.getId()
        const oldVal = this.properties.sourceOutput
        this.properties.sourceOutput = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CONNECTION_SOURCEOUTPUT, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getSourceOutput(pure = false) {
        return pure ? this.properties.sourceOutput : this.getRefItem(this.properties.sourceOutput)
        
    }
    getFidSourceOutput() {
        return this.FID_CONNECTION_SOURCEOUTPUT
    }
    

    setTargetInput(targetInput, event=true) {
        if ((targetInput !== null) && (targetInput !== undefined)) {
            if (!(targetInput instanceof Item) && (targetInput.constructor !== String)) {
                throw `targetInput(${targetInput}) must be Item or String type`
            }
        }
        const newVal = (targetInput === null || targetInput === undefined) ? undefined : (targetInput.constructor === String) ? targetInput : targetInput.getId()
        const oldVal = this.properties.targetInput
        this.properties.targetInput = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CONNECTION_TARGETINPUT, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getTargetInput(pure = false) {
        return pure ? this.properties.targetInput : this.getRefItem(this.properties.targetInput)
        
    }
    getFidTargetInput() {
        return this.FID_CONNECTION_TARGETINPUT
    }
    

    getFidLast() {
        return this.FID_CONNECTION_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CONNECTION_CONNECTIONID:
                return "connectionID"

            case this.FID_CONNECTION_BUILD:
                return "build"

            case this.FID_CONNECTION_SOURCEOUTPUT:
                return "sourceOutput"

            case this.FID_CONNECTION_TARGETINPUT:
                return "targetInput"

            default:
                return ""
        }
    }


}