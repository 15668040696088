/**
 *  Generated by Modeler
 *  timestamp: 2022-12-21 20:22:35.185400
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemType from "../ItemType";

import store from "@/store/index";
import ItemHandler from "../ItemHandler";
import Utils from "../../utils";

export default class ConfigurationEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
    this.addPropertyListener(item.getFidSelected(), this.lstn_selected);
  }
  postProcess(item) {
    const upfModule = item.getParent(ItemType.UPFModule);
    if (upfModule) {
      const pd = upfModule.getUPFDiagram().getUPFPowerDomain();
      const targets = [...pd.getUPFPowerSources(), ...pd.getUPFGrounds()];
      targets.forEach((o) => {
        ItemHandler.createItem(item, ItemType.ConfigurationElement, {
          itemRef: o.getId(),
          prop: "VL",
        });
      });
    }
  }

  lstn_name(item, newVal) {
    item.setTitle(newVal);
  }

  lstn_selected(item, newVal) {
    var cmu = item.getParent(ItemType.ClockCMU);
    if (item.getParent(ItemType.VoltageLevelFolder)) {
      item.getConfigurationElements().forEach((configElem) => {
        let config = ItemHandler.getRefItem(configElem.getValue());
        config.getParent().setSelectedConfiguration(config.getId());
      });
    }
  }
}
