import { ReroutePlugin, RerouteExtra } from "rete-connection-reroute-plugin";
import { BaseArea, BaseAreaPlugin } from "rete-area-plugin";
import { Scope, BaseSchemes, ConnectionId } from "rete";
import { createITDAConnectionPath } from "./ITDAPathUtils";
import { Position } from "rete-connection-reroute-plugin/_types/types";
import store from "@/store";
import Canvas from "@/canvas";
type Requires<Schemes extends BaseSchemes> = {
  type: "connectionpath";
  data: { payload: Schemes["Connection"]; path?: string; points: Position[] };
};

export class CustomReroutePlugin<
  Schemes extends BaseSchemes
> extends ReroutePlugin<Schemes> {
  selectedPinID!: string;
  private handleDocumentClickBound: (event: MouseEvent) => void;
  public connectionPoints: Map<
    ConnectionId,
    { start: Position; end: Position }
  > = new Map();
  public midPinContainers: Map<ConnectionId, HTMLElement> = new Map();
  public isSegment = false;
  public pathData: Map<
    ConnectionId,
    { pathSegments: string[]; midPoints: Position[] }
  > = new Map();

  constructor() {
    super();
    this.handleDocumentClickBound = this.handleDocumentClick.bind(this);
  }

  setParent(
    scope: Scope<Requires<Schemes>, [BaseArea<Schemes> | RerouteExtra]>
  ): void {
    this.parent = scope;
    type Base = BaseAreaPlugin<Schemes, BaseArea<Schemes> | RerouteExtra>;

    scope.addPipe((context) => {
      if (!context || typeof context !== "object" || !("type" in context))
        return context;

      // Connection rendered & created
      if (context.type === "rendered" && context.data.type === "connection") {
        // Connection 부분에서 line, curve 조절?
        const area = scope.parentScope<Base>(BaseAreaPlugin);
        const {
          element,
          payload: { id },
        } = context.data;

        if (!this.pinParents.has(element)) {
          const pinContainer = document.createElement("div");
          pinContainer.dataset["type"] = "pin-container";
          pinContainer.className = "pin-container";
          this.pinContainers.set(id, { element: pinContainer });
          this.pinParents.set(element, { id, pinContainer });
          area.area.content.add(pinContainer);
          area.area.content.reorder(pinContainer, element.nextElementSibling);
        }
        const container = this.pinContainers.get(id); // render 시 항상 존재

        if (container) {
          // Add a mid-pin when ITDAConnection is created
          this.adjustMidPinsInConnection(id, container, this.isSegment);
        }
        const containerElement = this.pinContainers.get(id)?.element;

        if (containerElement) {
          const pinsContainer = containerElement.querySelector(".pins");
          const pins = pinsContainer?.querySelectorAll(".pin");
          if (!pins) return;

          pins.forEach((pin, index) => {
            const pinId = `pin-${id}-${index}`;
            pin.setAttribute("id", pinId);

            const elem = document.getElementById(pinId);
            if (elem) {
              // get style code
              this.applyPinStyles(id, elem);
            }
          });
        }
      }

      // Pin unmount
      if (context.type === "unmount") {
        const area = scope.parentScope<Base>(BaseAreaPlugin);
        const { element } = context.data;
        const record = this.pinParents.get(element);

        if (record) {
          this.pinParents.delete(element);
          this.pinContainers.delete(record.id);
          area.emit({
            type: "unmount",
            data: { element: record.pinContainer },
          });
          area.area.content.remove(record.pinContainer);
        }
      }

      // path 및 요소들 재정렬?
      if (context.type === "reordered") {
        const area = scope.parentScope<Base>(BaseAreaPlugin);
        const { element } = context.data;
        const record = this.pinParents.get(element);
        if (record) {
          area.area.content.reorder(
            record.pinContainer,
            element.nextElementSibling
          );
        }
      }

      if (context.type === "connectionpath") {
        const area = scope.parentScope<Base>(BaseAreaPlugin);
        const {
          payload: { id },
        } = context.data;
        const container = this.pinContainers.get(id);
        const start = context.data.points[0];
        const end = context.data.points[context.data.points.length - 1];

        const pins = this.pins.getPins(id);

        this.connectionPoints.set(id, { start, end });
        if (container) {
          this.adjustMidPinsInConnection(id, container, this.isSegment);
        }
        if (container) {
          area.emit({
            type: "render",
            data: {
              type: "reroute-pins",
              element: container.element,
              data: { id, pins },
            },
          });
        }

        const reroutePoints = pins.map((item) => item.position);
        const points = [start, ...reroutePoints, end];
        const { pathSegments, midPoints } = createITDAConnectionPath(
          points,
          0.5,
          store.getters["canvas/GET_PATH_STYLE"](Canvas.getCurrentID())
        );

        this.pathData.set(id, { pathSegments, midPoints });
        context.data.path = pathSegments.join("");

        return {
          ...context,
          data: {
            ...context.data,
            points,
          },
        };
      }

      return context;
    });
    document.addEventListener("click", this.handleDocumentClickBound);
  }

  add(connectionId: ConnectionId, position: Position, index: number) {
    const pinId = `${connectionId}-${Math.random()}`;
    const pin = { id: pinId, position, selected: false, connectionId };
    this.pins.add(connectionId, pin, index);
    const container = this.pinContainers.get(connectionId)?.element; // pin-container
    if (container) {
      const area =
        this.parentScope().parentScope<
          BaseAreaPlugin<BaseSchemes, BaseArea<BaseSchemes>>
        >(BaseAreaPlugin);
      area.update("connection", connectionId);
    }
  }

  adjustMidPinsInConnection(
    id: ConnectionId,
    container: { element: HTMLElement },
    isSegment: boolean
  ) {
    const pathData = this.pathData.get(id);
    if (pathData) {
      this.clearMidPins(container.element);
      this.createMidPins(container.element, id, pathData.midPoints, isSegment);
    }
  }

  private clearMidPins(element: HTMLElement) {
    const midPins = element.querySelectorAll(".mid-pin-wrapper");
    midPins.forEach((pin) => pin.remove());
  }

  private createMidPins(
    element: HTMLElement | undefined,
    connectionId: ConnectionId,
    midPoints: Position[],
    isSegment: boolean
  ) {
    if (!element || !isSegment) return;

    midPoints.forEach((midPoint, index) => {
      const existingPinWrapper = element.querySelector(
        `.mid-pin-wrapper[data-segment-id="${connectionId}-${index}"]`
      );
      if (existingPinWrapper) return;
      const wrapper = document.createElement("div");
      wrapper.className = "mid-pin-wrapper";
      wrapper.style.position = "absolute";
      wrapper.style.left = `${midPoint.x - 3}px`;
      wrapper.style.top = `${midPoint.y - 3}px`;
      wrapper.style.zIndex = "5";
      wrapper.style.visibility = "hidden"; // default hidden
      wrapper.setAttribute("data-segment-id", `${connectionId}-${index}`);

      const pinElement = document.createElement("div");
      pinElement.className = `mid-pin-${connectionId}`;
      pinElement.style.width = "6px";
      pinElement.style.height = "6px";
      pinElement.style.border = "1px solid black";
      pinElement.style.background = "white";
      pinElement.style.zIndex = "5";
      pinElement.style.left = "0";
      pinElement.style.top = "0";
      pinElement.style.borderRadius = "20px";

      wrapper.appendChild(pinElement);
      element.appendChild(wrapper);

      this.midPinContainers.set(connectionId, wrapper);

      wrapper.addEventListener("pointerdown", (e) => {
        if (
          e.target &&
          (e.target as HTMLElement).classList.contains(
            `mid-pin-${connectionId}`
          )
        ) {
          this.add(connectionId, midPoint, index);
        }
      });
    });
  }

  // Get pins after path segmentation
  getPinsForSegment(connectionId: ConnectionId, segmentIndex: number) {
    const pins = this.pins.getPins(connectionId);
    const totalSegments = pins.length + 1;

    if (segmentIndex >= totalSegments) {
      return { startPin: null, endPin: null };
    }
    return {
      startPin: segmentIndex === 0 ? null : pins[segmentIndex - 1],
      endPin: segmentIndex === totalSegments - 1 ? null : pins[segmentIndex],
    };
  }

  private handleDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    if (!target.classList.contains("pin") && !target.closest(".pin")) {
      this.unselect(this.selectedPinID);
    }
  }

  // Apply custom pin style
  private applyPinStyles(connectionId: ConnectionId, pinElement: HTMLElement) {
    if (pinElement.id.startsWith(`pin-${connectionId}`)) {
      pinElement.style.transform = "scale(0.3)";
      pinElement.style.border = "2px solid black";
      pinElement.style.transformOrigin = "center";
      if (pinElement.classList.contains("selected")) {
        // selected pin background
        pinElement.style.backgroundColor = "red";
      } else {
        pinElement.style.backgroundColor = "";
      }
    } else {
      return;
    }
  }

  handlePinVisible(connectionId: ConnectionId, isClicked: boolean) {
    this.isSegment = isClicked;
    const container = this.pinContainers.get(connectionId);
    if (container && container.element) {
      const pins = container.element.querySelectorAll(".pin");
      pins.forEach((pin) => {
        (pin as HTMLElement).style.display = isClicked ? "block" : "none";
      });
    }
  }

  handleConnectionMouseEnter(connectionId: ConnectionId, index: number) {
    const midPinWrapper = document.querySelector(
      `.mid-pin-wrapper[data-segment-id="${connectionId}-${index}"]`
    );
    if (midPinWrapper) {
      (midPinWrapper as HTMLElement).style.visibility = "visible";
      (midPinWrapper as HTMLElement).style.display = "block";
    }
  }

  handleConnectionMouseLeave(connectionId: ConnectionId, index: number) {
    const midPinWrapper = document.querySelector(
      `.mid-pin-wrapper[data-segment-id="${connectionId}-${index}"]`
    );
    if (midPinWrapper) {
      (midPinWrapper as HTMLElement).style.visibility = "hidden";
    }
  }

  updatePinPosition(pinId: string, newX: number, newY: number) {
    const pin = this.pins.getPin(pinId);

    if (!pin) {
      console.error(`Pin with id ${pinId} not found`);
      return;
    }

    pin.position = { x: newX, y: newY };

    const connectionId = pin.connectionId;
    const container = this.pinContainers.get(connectionId)?.element;

    if (container) {
      this.adjustMidPinsInConnection(
        connectionId,
        { element: container },
        this.isSegment
      );
    }

    const area =
      this.parentScope().parentScope<
        BaseAreaPlugin<BaseSchemes, BaseArea<BaseSchemes>>
      >(BaseAreaPlugin);
    area.update("connection", connectionId);
  }

  destroy() {
    document.removeEventListener("click", this.handleDocumentClickBound);
  }
}
