import * as d3 from "d3";

export default {
  render: (
    elem: HTMLElement,
    width: number,
    height: number,
    color: string,
    options: any
  ) => {
    const { isDocked } = options;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", isDocked ? width * 0.7 : width)
      .attr("height", isDocked ? height * 0.8 : height);

    const drawerGroup = svg
      .append("g")
      .attr("transform", isDocked ? `scale(0.7, 0.8)` : `scale(1)`);
    const baseColor = color;
    const hoverColor = d3.hsl(baseColor);
    hoverColor.l = Math.min(hoverColor.l + 0.05, 1);

    const component = drawerGroup
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .attr("fill", baseColor)
      .attr("rx", 10)
      .attr("ry", 10);

    component
      .on("mouseover", function () {
        d3.select(this).attr("fill", hoverColor.toString());
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", baseColor);
      });

    const grid = 12;
    const gridHeight = height / grid;
    const gridWidth = width / grid;

    const cx = gridWidth * 4;
    const cy = gridHeight * 5;

    drawerGroup
      .append("rect")
      .attr("x", cx - 2)
      .attr("y", cy)
      .attr("width", 4)
      .attr("height", 20)
      .attr("fill", "black")
      .attr("transform", `rotate(-65, ${cx}, ${cy})`);

    drawerGroup
      .append("rect")
      .attr("x", cx - 4)
      .attr("y", cy * 2)
      .attr("width", 8)
      .attr("height", 21.5)
      .attr("fill", "black")
      .attr("transform", `rotate(-65, ${cx}, ${cy})`);

    drawerGroup
      .append("circle")
      .attr("cx", cx)
      .attr("cy", cy)
      .attr("r", height / 4)
      .attr("stroke", "black")
      .attr("stroke-width", "3")
      .attr("fill", "white");
  },
};
