/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";

export default class ClockIPInputChecker extends ItemChecker {
  constructor(item) {
    super(item);
    this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME);
    //this.addChecker(item.getFidKey(), this.checkKey, ErrorType.NAME)
    //this.addChecker(item.getFidGated(), this.checkGated, ErrorType.NAME)
    // this.addChecker(item.getFidShared(), this.checkShared, ErrorType.NAME);
    //this.addChecker(item.getFidPosition(), this.checkPosition, ErrorType.NAME)
    //this.addChecker(item.getFidSocket(), this.checkSocket, ErrorType.NAME)
    //this.addChecker(item.getFidMultiple(), this.checkMultiple, ErrorType.NAME)
    this.addChecker(
      item.getFidConnections(),
      this.checkConnections,
      ErrorType.CLOCK_DIAGRAM
    );
  }
  checkName(item) {
    const IPItem = item.getParent();
    const idx = IPItem.getClockIPInputs().findIndex(
      (obj) => obj.getId() === item.getId()
    );
    IPItem.getClockIPInputs().forEach((ipInput) => {
      if (ipInput !== item) {
        if (ipInput.getName() === item.getName()) {
          this.newError(
            `Input(${idx}) name(${item.getName()}) is duplicated`,
            ipInput
          );
        }
      }
    });
  }
  checkKey() {
    // TBD
  }
  checkGated() {
    // TBD
  }
  checkShared() {
    // TBD
  }
  checkPosition() {
    // TBD
  }
  checkSocket() {
    // TBD
  }
  checkMultiple() {
    // TBD
  }
  checkConnections(item) {
    const IPItem = item.getParent();
    const idx = IPItem.getClockIPInputs().findIndex(
      (obj) => obj.getId() === item.getId()
    );
    if (!item.getConnections().find((conn) => conn)) {
      this.newError(`Input(${idx}) must be connected`);
    }
  }
}
