import ItemType from "../src/ItemType";
import {
  ItemConfigSchema,
  Item,
  ConfigurationMapType,
  ConfigurationBase,
  ConfigurationFreqeuncy,
} from "../types";
import Repository from "../store";
import ConfigurationHandler from "../src/ConfigurationHandler";

// const repo = Repository.getInstance();
export const getConfigurationTitle = (item: Item): string | undefined => {
  return ConfigurationHandler.getConfigurationTitle(item.getItemType());
};

export const getConfigSchema = (item: Item): ItemConfigSchema[] => {
  return ConfigurationHandler.getSchema(item);
};

// export const isConfigurationTarget = (item: Item): boolean => {
//   return ConfigurationHandler.isConfigurationTarget(item);
// };

export const getConfigurationFolder = (item: Item): Item | undefined => {
  let type;
  switch (item.getItemType()) {
    case ItemType.ClockPLLCtrl:
    case ItemType.ClockRefCLKMultiplexer:
    case ItemType.ClockMultiplexer:
    case ItemType.ClockDivider:
    case ItemType.ClockNMDivider:
    case ItemType.ClockExternalCLKSource:
      type = ItemType.ClockCMU;
      break;
    case ItemType.PowerPMDInstance:
      type = ItemType.PowerPMC;
      break;
    case ItemType.PowerPSW:
    case ItemType.PowerHANDSHAKE:
    case ItemType.PowerOTP:
    case ItemType.PowerPWREN:
    case ItemType.PowerISOEN:
    case ItemType.PowerRESET:
    case ItemType.PowerMEM:
    case ItemType.PowerRETENTION:
    case ItemType.PowerREFCLKEN:
    case ItemType.PowerUSERDEFOUT:
      type = ItemType.PowerPMD;
      break;
    default:
      return undefined;
  }
  if (type) {
    const itemType = ItemType.getObjByKey(type.getKey());
    const module = item.getParent(itemType);
    return module?.getConfigurationFolder();
  }
};

export const isConfigurationTarget = (item: Item): boolean => {
  switch (item.getItemType()) {
    case ItemType.ClockPLLCtrl:
    case ItemType.ClockRefCLKMultiplexer:
    case ItemType.ClockMultiplexer:
    case ItemType.ClockDivider:
    case ItemType.ClockNMDivider:
    case ItemType.ClockExternalCLKSource:
    case ItemType.PowerPMDInstance:
    case ItemType.PowerPSW:
    case ItemType.PowerHANDSHAKE:
    case ItemType.PowerOTP:
    case ItemType.PowerPWREN:
    case ItemType.PowerISOEN:
    case ItemType.PowerRESET:
    case ItemType.PowerMEM:
    case ItemType.PowerRETENTION:
    case ItemType.PowerREFCLKEN:
    case ItemType.PowerUSERDEFOUT:
    case ItemType.SDCInstance:
      return true;
    case ItemType.LabelRef:
      return item.getType() === "external";
    default:
      return false;
  }
};

export const getSelectedCMUConfiguration = (item: Item): Item | undefined => {
  const cmuModule = item.getParent(ItemType.ClockCMU);
  return cmuModule
    ? cmuModule.getConfigurationFolder()?.getSelectedConfiguration()
    : undefined;
};

export const getCMUConfigurationElements = (item: Item): Item[] => {
  const selectedConfigElems = [];
  const selectedConfiguration = getSelectedCMUConfiguration(item);
  if (selectedConfiguration) {
    for (const configElem of selectedConfiguration.getConfigurationElements()) {
      if (configElem.getItemRef() === item) {
        selectedConfigElems.push(configElem);
      }
    }
  }
  return selectedConfigElems;
};

export const getSelectedVoltageConfiguration = (
  item: Item
): Item | undefined => {
  const powerSource = item.getPrimaryPowerSource();
  if (powerSource) {
    const voltageLevelFolder = powerSource.getConfigurationFolder();
    return voltageLevelFolder
      .getConfigurations()
      .find((config: Item) => config.getSelected());
  }
  return item.getPrimaryPowerSource();
};

export const getCurrentConfigurationElements = (
  item: Item,
  parentItem?: Item
): Item[] => {
  if (parentItem && parentItem.getItemType() === ItemType.SDCInstance) {
    const selectedConfigElems: Item[] = [];
    const selectedVoltageConfiuration =
      getSelectedVoltageConfiguration(parentItem);
    const freqConfigId = selectedVoltageConfiuration
      ?.getConfigurationElements()
      .find((e: Item) => e.getItemRef() === parentItem)
      ?.getValue();
    const freqConfig = Repository.getInstance().getItemByID(freqConfigId);
    if (!freqConfig) {
      return selectedConfigElems;
    }
    for (const configElem of freqConfig.getConfigurationElements()) {
      let itemOrigin = item;
      if (item.getItemType() === ItemType.SDCPhantom) {
        itemOrigin = item.getOriginItem();
      }
      if (configElem.getItemRef() === itemOrigin) {
        selectedConfigElems.push(configElem);
      }
    }
    return selectedConfigElems.length ? selectedConfigElems : [];
  } else {
    return getCMUConfigurationElements(item);
  }
};

export const getSelectedPMUConfiguration = (
  item: Item,
  type: ItemType
): Item | undefined => {
  const pmuModule = item.getParent(type);
  return pmuModule
    ? pmuModule.getConfigurationFolder().getSelectedConfiguration()
    : undefined;
};

export const getPMUConfigurationElements = (
  item: Item,
  type: ItemType
): Item[] => {
  const selectedConfigElems = [];
  const selectedConfiguration = getSelectedPMUConfiguration(item, type);
  if (selectedConfiguration) {
    for (const configElem of selectedConfiguration.getConfigurationElements()) {
      if (configElem.getItemRef() === item) {
        selectedConfigElems.push(configElem);
      }
    }
  }
  return selectedConfigElems;
};

export const getConfigurationMap = (): ConfigurationMapType => {
  return Repository.getInstance().getConfiguration().getConfigurationMap();
};

export const setConfigurationMap = (baseObj: ConfigurationBase): void => {
  Repository.getInstance().getConfiguration().setConfigurationBase(baseObj);
};

export const setConfigurationFrequency = (
  nodeID: string,
  freqObj: ConfigurationFreqeuncy
): void => {
  Repository.getInstance()
    .getConfiguration()
    .setConfigurationFrequency(nodeID, freqObj);
};
