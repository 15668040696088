import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, color: string) => {
    const div = d3
      .select(elem)
      .append("div")
      .style("width", "13px")
      .style("height", "10px")
      .style("display", "inline-block")
      .style("cursor", "pointer")
      .style("border", "2px solid lightgray")
      .style("border-radius", "8px")
      .style("vertical-align", "middle")
      .style("background", color)
      .style("position", "relative")
      .style("z-index", 2);

    div.on("mouseenter", function () {
      d3.select(this).style("border", "2px solid #777");
    });

    div.on("mouseleave", function () {
      d3.select(this).style("border", "2px solid lightgray");
    });
  },
};
