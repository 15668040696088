import { Item } from "ITDAModelTypes";
import Canvas from "@/canvas";

export const updateParentID = (item: Item, upper: Item | undefined) => {
  const editor = Canvas.getEditor(item.getParent().getId());
  const node = editor.getNode(item.getNodeID());
  node.parent = upper ? upper.getNodeID() : undefined;
};

export const updateParentScopes = async (diagram: Item) => {
  const editor = Canvas.getEditor(diagram.getId());
  const promises: Promise<void>[] = [];
  diagram.getDUTInstances().forEach((item: Item) => {
    const node = editor.getNode(item.getNodeID());
    if (item.getUpper()) {
      updateParentID(item, item.getUpper());
      promises.push(updateParentScope(item.getUpper()));
    }
  });
  await Promise.all(promises);
};

export const updateParentScope = async (
  item: Item | undefined
): Promise<void> => {
  if (item) {
    const editor = Canvas.getEditor(item.getParent().getId());
    const upperNode = item ? editor.getNode(item.getNodeID()) : undefined;
    if (upperNode) {
      await Canvas.getScopesPlugin().update(upperNode.id);
    }
  }
};
