import ErrorHandler from "./ErrorHandler";

export default class ErrorInterface {
  constructor(item) {
    this.item = item;
    this.topItem = this.item.getTopItem();
  }

  addChecker(fid, checker, type) {
    if (fid) {
      ErrorHandler.addChecker(this.item, fid, checker, type);
    }
  }

  /*newError(desc, ref) {
        return ErrorHandler.newError(this.item, this.type, this.fid, desc, ref)
    }

    remError(type) {
        ErrorHandler.remError(this.item, type)
    }

    clearErrors() {
        ErrorHandler.clearErrors(this.item)
    }

    getReferences(type) {
        return ErrorHandler.getReferences(this.item, type)
    }*/
}
