"use strict";

export default class NodeEXTType {
  static ClockCTRLCUSTOM = new NodeEXTType(
    2,
    "ClockCTRLCUSTOM",
    "getCustomMode",
    "CUSTOM",
    "blue"
  );
  static ClockCTRLEWAKEUP = new NodeEXTType(
    2,
    "ClockCTRLEWAKEUP",
    "getEwakeupMode",
    "EWAKEUP",
    "blue"
  );
  static ClockCTRLPWRDOWN = new NodeEXTType(
    2,
    "ClockCTRLPWRDOWN",
    "getPowerDownMode",
    "PWRDOWN",
    "blue"
  );
  static ClockCTRLSHORTSTOP = new NodeEXTType(
    2,
    "ClockCTRLSHORTSTOP",
    "getShortStopMode",
    "SHORTSTOP",
    "blue"
  );
  static ClockCTRLTHROTTLE = new NodeEXTType(
    2,
    "ClockCTRLTHROTTLE",
    "getThrottleMode",
    "THROTTLE",
    "blue"
  );
  static ClockCTRLFREQMON = new NodeEXTType(
    2,
    "ClockCTRLFREQMON",
    "getFreqmonMode",
    "FREQMON",
    "blue"
  );
  static ClockCTRLGRADUAL = new NodeEXTType(
    2,
    "ClockCTRLGRADUAL",
    "getGradualMode",
    "GRADUAL",
    "blue"
  );

  constructor(index, key, value, label = "", color = "black") {
    this.index = index;
    this.key = key;
    this.value = value;
    this.label = label;
    this.color = color;
  }

  static getKeys() {
    return Object.keys(this);
  }

  static getValues() {
    return Object.values(this);
  }

  static getEntries() {
    return Object.entries(this);
  }

  static getObjByKey(key) {
    return this[key];
  }

  getKey() {
    return this.key;
  }

  getValue() {
    return this.value;
  }

  getLabel() {
    return this.label;
  }

  getColor() {
    return this.color;
  }
}
