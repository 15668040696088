import * as copy from "./copy";
import * as generator from "./generator";
import * as license from "./license";
import * as load from "./load";
import * as loadSpec from "./loadSpec";
import * as loadTargets from "./loadTargets";
import * as logout from "./logout";
import * as save from "./save";
import * as sequence from "./sequence";

export default {
  ...copy,
  ...generator,
  ...license,
  ...load,
  ...loadSpec,
  ...loadTargets,
  ...logout,
  ...save,
  ...sequence,
};
