/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import ClockIPDiagramEvent from '@/model/src/events/ClockIPDiagramEvent'
import ClockIPDiagramChecker from '@/model/src/checkers/ClockIPDiagramChecker'
export default class ClockIPDiagram extends Item {
    FID_CLOCKIPDIAGRAM_CANVASDATA = 0
    FID_CLOCKIPDIAGRAM_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockIPDiagram"}))
        
        this.setEvent(new ClockIPDiagramEvent(this))
        this.setChecker(new ClockIPDiagramChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "IP Diagram"
        }
    (this.view.diagram = true), (this.view.table = false)
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockIPDiagram"
    }

    getSchema() {
        return {"canvasData": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"canvasData": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "Connection", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "LabelRef", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "ClockIP", "auto": false, "searchable": false, "title": "IPs", "headers": [], "category": "IP"}, {"name": "ClockGate", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "ClockQChannelManager", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setCanvasData(canvasData, event=true) {
        if ((canvasData !== null) && (canvasData !== undefined)) {
            if (![String].includes(canvasData.constructor)) {
                throw `canvasData(${canvasData}) must be [String] type`
            }
        }
        const newVal = (canvasData === null || canvasData === undefined) ? undefined : canvasData
        const oldVal = this.properties.canvasData
        this.properties.canvasData = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKIPDIAGRAM_CANVASDATA, newVal, oldVal)
        }
    }
    getCanvasData(pure = false) {
        return this.properties.canvasData
        
    }
    getFidCanvasData() {
        return this.FID_CLOCKIPDIAGRAM_CANVASDATA
    }
    

    getFidLast() {
        return this.FID_CLOCKIPDIAGRAM_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKIPDIAGRAM_CANVASDATA:
                return "canvasData"

            default:
                return ""
        }
    }


    newConnection(properties={}, title = "", domain = "") {
        return this.createChild(this, "Connection", properties, title, domain)
    }
    remConnection(obj) {
        return this.removeChild(obj)
    }
    getConnections() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "Connection")
            return res
        })
    }
    newLabelRef(properties={}, title = "", domain = "") {
        return this.createChild(this, "LabelRef", properties, title, domain)
    }
    remLabelRef(obj) {
        return this.removeChild(obj)
    }
    getLabelRefs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "LabelRef")
            return res
        })
    }
    newClockIP(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockIP", properties, title, domain)
    }
    remClockIP(obj) {
        return this.removeChild(obj)
    }
    getClockIPs() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockIP")
            return res
        })
    }
    newClockGate(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockGate", properties, title, domain)
    }
    remClockGate(obj) {
        return this.removeChild(obj)
    }
    getClockGates() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockGate")
            return res
        })
    }
    newClockQChannelManager(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockQChannelManager", properties, title, domain)
    }
    remClockQChannelManager(obj) {
        return this.removeChild(obj)
    }
    getClockQChannelManagers() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockQChannelManager")
            return res
        })
    }
}