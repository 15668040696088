import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;

    // Left rectangle with triangle
    g.append("rect")
      .attr("x", (centerX / 4) * 1)
      .attr("y", (centerY / 4) * 4)
      .attr("width", (centerX / 4) * 2)
      .attr("height", (centerY / 4) * 2)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    g.append("line")
      .attr("x1", (centerX / 4) * 1)
      .attr("y1", (centerY / 4) * 4)
      .attr("x2", (centerX / 4) * 3)
      .attr("y2", (centerY / 4) * 6)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("line")
      .attr("x1", (centerX / 4) * 1)
      .attr("y1", (centerY / 4) * 6)
      .attr("x2", (centerX / 4) * 3)
      .attr("y2", (centerY / 4) * 4)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("polygon")
      .attr(
        "points",
        `${(centerX / 4) * 3.5},${(centerY / 4) * 4.8} ${(centerX / 4) * 3.8},${
          (centerY / 4) * 5
        } ${(centerX / 4) * 3.5},${(centerY / 4) * 5.2}`
      )
      .attr("fill", "black")
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("line")
      .attr("x1", (centerX / 4) * 3)
      .attr("y1", (centerY / 4) * 5)
      .attr("x2", (centerX / 4) * 3.5)
      .attr("y2", (centerY / 4) * 5)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    //Hexagon
    g.append("polygon")
      .attr(
        "points",
        `${(centerX / 4) * (6 + 0.6)},${centerY + centerY / 4}
${(centerX / 4) * (5.5 + 0.6)},${(centerY / 4) * 3 + centerY / 4}
${(centerX / 4) * (4.5 + 0.6)},${(centerY / 4) * 3 + centerY / 4}
${centerX + (centerX / 4) * 0.6},${centerY + centerY / 4}
${(centerX / 4) * (4.5 + 0.6)},${(centerY / 4) * 5 + centerY / 4}
${(centerX / 4) * (5.5 + 0.6)},${(centerY / 4) * 5 + centerY / 4}`
      )
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    g.append("polygon")
      .attr(
        "points",
        `${(centerX / 4) * 7},${(centerY / 4) * 4.8} ${(centerX / 4) * 7.3},${
          (centerY / 4) * 5
        } ${(centerX / 4) * 7},${(centerY / 4) * 5.2}`
      )
      .attr("fill", "black")
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("line")
      .attr("x1", (centerX / 4) * 5.6)
      .attr("y1", (centerY / 4) * 5)
      .attr("x2", (centerX / 4) * 7)
      .attr("y2", (centerY / 4) * 5)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("line")
      .attr("x1", (centerX / 4) * 5.6)
      .attr("y1", (centerY / 4) * 3.3)
      .attr("x2", (centerX / 4) * 5.6)
      .attr("y2", (centerY / 4) * 3.7)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("line")
      .attr("x1", (centerX / 4) * 6.6)
      .attr("y1", (centerY / 4) * 4.3)
      .attr("x2", (centerX / 4) * 7)
      .attr("y2", (centerY / 4) * 4.1)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("line")
      .attr("x1", (centerX / 4) * 5.6)
      .attr("y1", (centerY / 4) * 6.3)
      .attr("x2", (centerX / 4) * 5.6)
      .attr("y2", (centerY / 4) * 6.7)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("line")
      .attr("x1", (centerX / 4) * 6.6)
      .attr("y1", (centerY / 4) * 5.6)
      .attr("x2", (centerX / 4) * 7)
      .attr("y2", (centerY / 4) * 5.8)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("line")
      .attr("x1", (centerX / 4) * 4.2)
      .attr("y1", (centerY / 4) * 5.8)
      .attr("x2", (centerX / 4) * 4.6)
      .attr("y2", (centerY / 4) * 5.6)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("line")
      .attr("x1", (centerX / 4) * 4.2)
      .attr("y1", (centerY / 4) * 4.1)
      .attr("x2", (centerX / 4) * 4.6)
      .attr("y2", (centerY / 4) * 4.3)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 1.5)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("INTREXT");
  },
};
