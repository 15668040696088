<template>
  <v-navigation-drawer class="drawer-left" app permanent :style="style">
    <navigation-title />
    <v-divider></v-divider>
    <n-tree
      block-line
      selectable
      key-field="id"
      label-field="title"
      children-field="children"
      :data="store.getters['item/GET_ALL_ITEMS']"
      :expand-on-click="false"
      :show-line="false"
      :default-expanded-keys="expandedKeys"
      :selected-keys="store.getters['navigation/GET_SELECTED_KEYS']"
      :render-label="renderLabel"
      :render-switcher-icon="renderSwitcherIconWithExpaned"
      :on-update:expanded-keys="updatePrefixWithExpaned"
      :on-update:selected-keys="updateSelectedKeys"
      :nodeProps="nodeProps">
    </n-tree>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
import { computed, onMounted, h, ref, watch } from "vue";
import { useStore } from "vuex";
import NavigationTitle from "./NavigationTitle.vue";
import { Domain, DomainKeys, Item, ItemType } from "ITDAModelTypes";
import Utils from "./utils";
import { NIcon, NTooltip } from "naive-ui";
import { CaretForward } from "@vicons/ionicons5";
import API from "@/api";

interface Props {
  checked?: boolean;
  option: Item;
  selected?: boolean;
}

const store = useStore();
const expandedKeys = ref<string[]>([]);
const expandedKeysMap = new Map<DomainKeys, Record<string, string>>();
const selectedItemMap = new Map<DomainKeys, Item>();
const style = computed(() => {
  return {
    width: "inherit",
    height: "inherit",
    minWidth: `3%`,
  };
});

const renderLabel = (props: Props) => {
  return error(props.option)
    ? h(
        NTooltip,
        {
          trigger: "hover",
          placement: "right-end",
        },
        {
          trigger: () => createLabelElement(props.option, error(props.option)),
          default: () =>
            h("div", { innerHTML: API.getErrorSummary(props.option) }),
        }
      )
    : createLabelElement(props.option);
};

const error = (item: Item) => {
  return item.getAllErrors().length ? "text-error" : "";
};

const createLabelElement = (item: Item, error?: string) => {
  return h(
    "span",
    { class: error ? [error, selected(item)] : [selected(item)] },
    `${item.title} ${getConfigurationValue(item)}`
  );
};

const selected = (item: Item) => {
  return store.getters["item/GET_CURRENT_ITEM"] === item ? "selected-item" : "";
};

const getConfigurationValue = (item: Item) => {
  if (
    item.getItemType() === ItemType.ConfigurationFolder ||
    item.getItemType() === ItemType.VoltageLevelFolder
  ) {
    const configuration = item.getSelectedConfiguration();
    const configName = configuration ? `(${configuration.getName()})` : "";
    return configName;
  }
  return "";
};

const renderSwitcherIconWithExpaned = (props: Props) => {
  return h(NIcon, null, {
    default: () =>
      props.option.children?.length ? h(CaretForward) : undefined,
    // default: () => h(CaretForward),
  });
};

const updatePrefixWithExpaned = (
  keys: Array<string | number>,
  option: Array<Item | null>,
  meta: {
    node: Item | null;
    action: "expand" | "collapse" | "filter";
  }
) => {
  if (!meta.node) return;

  const domain = API.getCurrentDomain();
  if (domain) {
    const idMap = expandedKeysMap.get(domain);
    if (idMap) {
      const id = meta.node.getId();
      switch (meta.action) {
        case "expand":
          if (idMap) {
            idMap[id] = id;
          }
          // meta.node.prefix = () =>
          //   h(NIcon, null, {
          //     default: () => h(FolderOpenOutline),
          //   });
          break;
        case "collapse":
          if (idMap) {
            delete idMap[id];
          }
          // meta.node.prefix = () =>
          //   h(NIcon, null, {
          //     default: () => h(Folder),
          //   });
          break;
        default:
          break;
      }
      expandedKeys.value = Object.values(idMap);
    }
  }
};

const updateSelectedKeys = (
  keys: Array<string | number>,
  option: Array<Item | null>,
  meta: {
    node: Item | null;
    action: "select" | "unselect";
  }
) => {
  if (meta.node) {
    const domain = API.getCurrentDomain();
    if (domain) {
      selectedItemMap.set(domain, meta.node);
      store.commit("navigation/SET_SELECTED_KEYS", meta.node.getId());
    }
  }
};

const nodeProps = (props: Props) => {
  return {
    async onClick() {
      await Utils.changePage(props.option);
    },
  };
};

watch(
  () => store.getters["domain/GET_CURRENT_DOMAIN"],
  async (newVal: DomainKeys) => {
    if (newVal) {
      const idMap = expandedKeysMap.get(newVal);
      if (idMap) {
        expandedKeys.value = Object.values(idMap);
      }
      const item = selectedItemMap.get(newVal);
      if (item) {
        store.commit("navigation/SET_SELECTED_KEYS", item.getId());
        await Utils.changePage(item);
      }
    } else {
      expandedKeysMap.clear();
      expandedKeys.value = [];
      selectedItemMap.clear();
      store.commit("navigation/SET_SELECTED_KEYS", undefined);
    }
  }
);

onMounted(() => {
  Domain.getKeys().forEach((key: DomainKeys) => {
    expandedKeysMap.set(key, {});
  });
  const top = API.getTopItem();
  if (top) {
    expandedKeys.value = [top.getId()];
  }
});
</script>
<style lang="sass" scoped>
.drawer-left
  position: inherit !important
  width: 100% !important
  height: 100% !important
  user-select: none
  font-size: 16px
  font-weight: 400
  overflow: hidden

  .n-tree
    height: calc(100vh - 37px - 44px - 37px)
    padding: 1rem
    overflow: auto
</style>
