import * as d3 from "d3";
export default {
  render: (elem: HTMLElement, data: { [key in string]: any }) => {
    const d = {
      in: "M10,11.5 L2,15 L10,18.5 Z",
      out: "M150,11.5 L158,15 L150,18.5 Z",
    };

    const lineD = {
      in: "M2,15 L160,15",
      out: "M0,15 L158,15",
    };

    const pathD = data.signal.direction === "in" ? d.in : d.out;
    const lindeD = data.signal.direction === "in" ? lineD.in : lineD.out;

    const div = d3
      .select(elem)
      .append("div")
      .attr("width", "160px")
      .attr("height", "20px")
      .style("display", "flex")
      .style("flex-direction", "row");

    const svg = div
      .append("svg")
      .attr("width", 160)
      .attr("height", 20)
      .attr("transform", "translate(-1,0.5)");

    const g = svg.append("g");
    g.append("path")
      .attr("d", pathD)
      .attr("fill", "black")
      .attr("stroke", "black")
      .attr("stroke-width", 2);
    g.append("path")
      .attr("d", lindeD)
      .attr("fill", "black")
      .attr("stroke", "black")
      .attr("stroke-width", 2)
      .attr("id", data.index);

    const text = g
      .append("text")
      .attr("dx", 12)
      .attr("dy", -5)
      .attr("font-size", 10)
      .attr("lengthAdjust", "spacingAndGlyphs")
      .attr("fill", "black");
    text
      .append("textPath")
      .attr("href", "#" + data.index)
      .text(data.signal.name);
  },
};
