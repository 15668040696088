import ItemEvent from "@/model/src/ItemEvent";
import ItemType from "../ItemType";
import LabelType from "../LabelType";
import ItemHandler from "../ItemHandler";

export default class Label extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidType(), this.lstn_type);
    this.addPropertyListener(item.getFidLabelRefs(), this.lstn_label_refs);
  }
  lstn_type(item, newVal) {
    if (newVal === LabelType.EXTERNAL.getKey()) {
      item.setLabelRefs([]);
    }
    if (!newVal) {
      item.setType(LabelType.INTERNAL.getKey(), false);
    }
    // const parents = item.getParentNodeItems();
    // if (parents.length) {
    //   const output = parents[0].getNode().outputs.values().next().value;
    //   for (const conn of output.connections) {
    //     let dstItem = conn.input.node.getItem();
    //     if (dstItem !== item) {
    //       if (
    //         dstItem.getItemType() === ItemType.Label &&
    //         dstItem.getType() === item.getType()
    //       ) {
    //         item.getCanvas().getEditor().removeConnection(conn);
    //         break;
    //       }
    //     }
    //   }
    // }
  }
  lstn_label_refs(item, newVal, oldVal) {
    oldVal
      .filter((labelRef) => {
        return newVal.findIndex((o) => o.getId() === labelRef.getId()) < 0;
      })
      .map((o) => {
        if (o.getItemLabel() === item) {
          o.setItemLabel();
        }
      });
    // oldVal
    //   .filter((id) => {
    //     return newVal.indexOf(id) < 0;
    //   })
    //   .map(async (id) => {
    //     var labelRef = item.getRefItem(id);
    //     if (labelRef.getItemLabel() === item.getId()) {
    //       labelRef.setItemLabel(null);
    //     }
    //   });
  }

  postProcess(item) {
    return this.setupPorts(item);
  }

  setupPorts(item) {
    return ItemHandler.createItem(item, ItemType.Input, {
      key: `CLK_${ItemType.Input.getKey().toUpperCase()}`,
      socket: "CLK",
    }).promise;
  }
}
