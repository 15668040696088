import ErrorInterface from "./ErrorInterface";
import ErrorType from "./ErrorType";
import ItemType from "./ItemType";
import NodeType from "./NodeType";
import LabelType from "./LabelType";
import ModuleType from "./ModuleType";
import DiagramType from "./DiagramType";
import ItemHandler from "./ItemHandler";

export default class ItemChecker extends ErrorInterface {
  constructor(item) {
    super(item);
    switch (item.getItemType().getCategory()) {
      case NodeType.Clock:
      case NodeType.Power:
        this.addChecker(item.getFidName(), this.checkNodeName, ErrorType.NAME);
        this.checkInitValue(item);
        break;
      case NodeType.UPF:
        if (item.getFidCells) {
          this.addChecker(
            item.getFidCells(),
            this.checkUPFLibCell,
            ErrorType.NAME
          );
        }
        break;
      case NodeType.Module:
      case NodeType.ModuleInstance:
        this.addChecker(
          item.getFidName(),
          this.checkIntegrationNodeName,
          ErrorType.NAME
        );
        break;
      case NodeType.Instance:
        if (
          item.getItemType() === ItemType.LabelRef &&
          item.getType() === LabelType.EXTERNAL
        ) {
          this.addChecker(
            item.getFidName(),
            this.checkExternalLabelRefName,
            ErrorType.NAME
          );
        }
        if (item.getItemType() === ItemType.ClockExternalCLKSource) {
          this.addChecker(
            item.getFidName(),
            this.checkExternalSourceName,
            ErrorType.NAME
          );
        }
        if (item.getItemType() === ItemType.ClockIP) {
          this.addChecker(
            item.getFidName(),
            this.checkExternalIPName,
            ErrorType.NAME
          );
        }
        break;
      case NodeType.Sequence:
        var excludeTargets = [
          ItemType.PowerSequenceStart,
          ItemType.PowerSequenceGoto,
          ItemType.PowerSequenceCall,
          ItemType.PowerSequenceBack,
          ItemType.PowerSequenceEnd,
          ItemType.PowerSequenceLabel,
          ItemType.PowerSequenceLabelRef,
        ];
        if (!excludeTargets.find((t) => t === item.getItemType())) {
          this.addChecker(
            item.getFidSFRField(),
            this.checkSequenceSFRField,
            ErrorType.SEQUENCE
          );
          this.addChecker(
            item.getFidValue(),
            this.checkSequenceValue,
            ErrorType.SEQUENCE
          );
        }
        break;
      default:
        break;
    }
  }

  checkNodeName(item) {
    const diagrams = [];
    const project = item.getParent(ItemType.Project);
    project
      .getClockCMUFolder()
      .getClockCMUs()
      .forEach((cmu) => {
        diagrams.push(...cmu.getClockDiagramFolder()?.getClockDiagrams());
        diagrams.push(cmu.getClockIPDiagram());
      });
    project
      .getPowerPMDFolder()
      .getPowerPMDs()
      .forEach((pmd) => {
        diagrams.push(...pmd.getPowerDiagramFolder()?.getPowerDiagrams());
      });
    project
      .getPowerPMCFolder()
      .getPowerPMCs()
      .forEach((pmc) => {
        diagrams.push(...pmc.getPowerDiagramFolder()?.getPowerDiagrams());
      });

    const duplicateNodes = diagrams
      .reduce((acc, cur) => {
        acc = acc.concat(cur.getChildren());
        return acc;
      }, [])
      .filter((nodeItem) => {
        if (
          nodeItem.getItemType() !== ItemType.Connection &&
          nodeItem.getItemType() !== ItemType.Label &&
          nodeItem.getItemType() !== ItemType.LabelRef
        ) {
          return nodeItem;
        }
      })
      .filter((nodeItem) => {
        if (nodeItem !== item && nodeItem.getName() === item.getName()) {
          return nodeItem;
        }
      });
    if (duplicateNodes.length > 0) {
      this.newError(`Node Name (${item.getName()}) is duplicated`, item);
    }
  }

  checkInitValue(item) {
    switch (item.getItemType()) {
      case ItemType.ClockDivider:
        this.addChecker(
          item.getFidDivRatioInitValue(),
          this._checkInitValue,
          ErrorType.VALUE
        );
        break;
      case ItemType.ClockGate:
        this.addChecker(
          item.getFidEnableInitValue(),
          this._checkInitValue,
          ErrorType.VALUE
        );
        break;
      case ItemType.PowerPWREN:
        this.addChecker(
          item.getFidPwrenInitValue(),
          this._checkInitValue,
          ErrorType.VALUE
        );
        break;
      case ItemType.PowerHANDSHAKE:
        this.addChecker(
          item.getFidReqInitValue(),
          this._checkInitValue,
          ErrorType.VALUE
        );
        break;
      case ItemType.PowerMEM:
        this.addChecker(
          item.getFidMemRetInitValue(),
          this._checkInitValue,
          ErrorType.VALUE
        );
        break;
      case ItemType.PowerPMRTIMEOUT:
        this.addChecker(
          item.getFidDataInitValue(),
          this._checkInitValue,
          ErrorType.VALUE
        );
        break;
      case ItemType.PowerRETENTION:
        this.addChecker(
          item.getFidRetentionInitValue(),
          this._checkInitValue,
          ErrorType.VALUE
        );
        break;
      case ItemType.PowerUSERDEFOUT:
        this.addChecker(
          item.getFidUserdefInitValue(),
          this._checkInitValue,
          ErrorType.VALUE
        );
        break;
      default:
        break;
    }
  }

  _checkInitValue(item) {
    var value = "";
    var maxValue = "";
    switch (item.getItemType()) {
      case ItemType.ClockDivider:
        value = item.getDivRatioInitValue();
        maxValue = Math.pow(2, item.getMaxDivRatioWidth());
        break;
      case ItemType.ClockGate:
        value = item.getEnableInitValue();
        maxValue = Math.pow(2, 1);
        break;
      case ItemType.PowerPWREN:
        value = item.getPwrenInitValue();
        maxValue = Math.pow(2, 1);
        break;
      case ItemType.PowerHANDSHAKE:
        value = item.getReqInitValue();
        maxValue = Math.pow(2, 1);
        break;
      case ItemType.PowerMEM:
        value = item.getMemRetInitValue();
        maxValue = Math.pow(2, 1);
        break;
      case ItemType.PowerPMRTIMEOUT:
        value = item.getDataInitValue();
        maxValue = Math.pow(2, 32);
        console.log(value, maxValue);
        break;
      case ItemType.PowerRETENTION:
        value = item.getRetentionInitValue();
        maxValue = Math.pow(2, 1);
        break;
      case ItemType.PowerUSERDEFOUT:
        value = item.getUserdefInitValue();
        maxValue = Math.pow(2, item.getMaxUserdefWidth());
        break;
      default:
        break;
    }

    if (
      value === "" ||
      Number(value) >= Number(maxValue) ||
      Number(value) < 0
    ) {
      this.newError(`${value} must be in the range 0 ~ ${maxValue - 1}`);
    }
  }

  checkUPFLibCell(item) {
    if (!item.getCells()) {
      this.newError(`Please check the lib_cells.`, item);
    }
  }

  checkIntegrationNodeName(item) {
    const diagram = item.getParent(null, DiagramType.Integration);
    if (!diagram) {
      return;
    }
    diagram
      .getChildren()
      .filter((nodeItem) => {
        let category = nodeItem.getItemType().getCategory();
        if (
          category === NodeType.Module ||
          category === NodeType.ModuleInstance
        ) {
          if (nodeItem !== item && nodeItem.getName() === item.getName()) {
            return nodeItem;
          }
        }
      })
      .forEach((nodeItem) => {
        this.newError(
          `Node Name (${nodeItem.getName()}) is duplicated`,
          nodeItem
        );
      });
  }

  checkExternalLabelRefName(item) {
    const cmu = item.getParent(null, ModuleType.Clock);
    cmu
      .getClockDiagramFolder()
      .getClockDiagrams()
      .reduce((acc, cur) => {
        acc = acc.concat(cur.getChildren());
        return acc;
      }, [])
      .filter((nodeItem) => {
        if (
          nodeItem.getItemType() === ItemType.LabelRef &&
          nodeItem.getType() === LabelType.EXTERNAL
        ) {
          if (
            nodeItem !== item &&
            nodeItem.getBoundaryName() === item.getBoundaryName()
          ) {
            return nodeItem;
          }
        }
      })
      .forEach((nodeItem) => {
        this.newError(
          `LabelRef Name (${nodeItem.getBoundaryName()}) is duplicated`,
          nodeItem
        );
      });
  }

  checkExternalSourceName(item) {
    const cmu = item.getParent(null, ModuleType.Clock);
    cmu
      .getClockDiagramFolder()
      .getClockDiagrams()
      .reduce((acc, cur) => {
        acc = acc.concat(cur.getChildren());
        return acc;
      }, [])
      .filter((nodeItem) => {
        if (nodeItem.getItemType() === ItemType.ClockExternalCLKSource) {
          if (nodeItem !== item && nodeItem.getName() === item.getName()) {
            return nodeItem;
          }
        }
      })
      .forEach((nodeItem) => {
        this.newError(
          `ExternalCLKSource Name (${nodeItem.getName()}) is duplicated`,
          nodeItem
        );
      });
  }

  checkExternalIPName(item) {
    item
      .getParent(null, DiagramType.IP)
      .getChildren()
      .filter((nodeItem) => {
        if (nodeItem.getItemType() === ItemType.ClockIP) {
          if (nodeItem !== item && nodeItem.getName() === item.getName()) {
            return nodeItem;
          }
        }
      })
      .forEach((nodeItem) => {
        this.newError(
          `IP Name (${nodeItem.getName()}) is duplicated`,
          nodeItem
        );
      });
  }

  checkSequenceSFRField(item) {
    const sfrField = item.getSFRField();
    if (!sfrField) {
      if (item.getItemType() !== ItemType.PowerSequenceWait) {
        this.newError(`SFR field must be selected`, item);
      }
    }
  }

  checkSequenceValue(item) {
    if (item.getItemType() === ItemType.PowerSequenceWait) {
      const sfrField = item.getSFRField();
      console.log(item, sfrField, item.getValue());
      if (!sfrField) {
        if (
          item.getValue() === "" ||
          item.getValue() === null ||
          item.getValue() === undefined
        ) {
          this.newError(`Value is null`, item);
        }
      }
    } else {
      if (
        item.getValue() === "" ||
        item.getValue() === null ||
        item.getValue() === undefined
      ) {
        this.newError(`Value is null`, item);
      }
    }
  }
}
