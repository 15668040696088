import {
  Item,
  ConfigurationFreqeuncy,
  ConfigurationMapType,
  ItemType,
} from "ITDAModelTypes";
import { ITDANode } from "@/canvas/nodes";
import API from "@/api";
import Canvas from "@/canvas";
import * as ITDAControl from "@/canvas/controls";
import { NodeType } from "@/canvas/types";
import CLI from "@/cli";

export const createConfigMap = async (): Promise<ConfigurationMapType> => {
  const topItem = API.getTopItem();

  const sdcModules = topItem?.getSDCModuleFolder().getSDCModules();
  for (const sdc of sdcModules) {
    const diagram = sdc.getSDCDiagram();
    const editor = Canvas.getEditor(diagram.getId());
    const nodes = editor.getNodes();
    const sdcNodes = nodes.filter(
      (n: ITDANode) => n.getType() === NodeType.ITDAINSTANCESDC
    );
    for (const sdcNode of sdcNodes) {
      const sdcItem = API.getItem(sdcNode.getItemID()) as Item;
      const childNodes: ITDANode[] = [];
      for (const sdcGroup of sdcItem.getSDCPhantomGroups()) {
        for (const sdcPhantom of sdcGroup.getSDCPhantoms()) {
          childNodes.push(editor.getNode(sdcPhantom.getNodeID()));
        }
      }
      setConfigurationBase(childNodes, sdcNode);
      const voltageDomain = sdcItem.getPrimaryPowerSource();
      if (voltageDomain) {
        const voltageLevelFolder = voltageDomain.getConfigurationFolder();
        const voltageLevels = voltageLevelFolder.getConfigurations();
        // save
        let selectOrigin;
        for (const voltageLv of voltageLevels) {
          if (voltageLv.getSelected()) {
            selectOrigin = voltageLv;
          }
          await voltageLv.setSelected(false);
        }

        for (const voltageLv of voltageLevels) {
          const voltageLvElem = voltageLv
            .getConfigurationElements()
            .find((e: Item) => e.getItemRef() === sdcItem);
          if (voltageLvElem && voltageLvElem.getValue() !== null) {
            if (!(await voltageLv.setSelected(true))) {
              throw new Error("config selected error.");
            }
            const freqConfig = API.getItem(voltageLvElem.getValue());
            if (freqConfig) {
              addConfigurationFrequency(childNodes, sdcItem, freqConfig);
            }
            await voltageLv.setSelected(false);
          }
        }
        // restore
        if (selectOrigin) {
          await selectOrigin.setSelected(true);
        }
      }
    }
  }
  return API.getConfigurationMap();
};

const setConfigurationBase = (childNodes: ITDANode[], sdcNode: ITDANode) => {
  childNodes.forEach((node: ITDANode) => {
    API.setConfigurationMap({
      itemID: node.getItemID(),
      nodeType: node.getType().getKey(),
      sdcID: sdcNode.getItemID(),
    });
  });
};

const addConfigurationFrequency = (
  childNodes: ITDANode[],
  sdcItem: Item,
  freqConfig: Item
) => {
  childNodes.forEach((node: ITDANode) => {
    const nodeItem = API.getItem(node.getItemID()) as Item;
    const freqCtrl = node.controls.freq as ITDAControl.ITDAControlFrequency;
    const freqObj: ConfigurationFreqeuncy = {
      id: freqConfig.getId(),
      name: freqConfig.getName(),
      freq: Number(freqCtrl.getFrequency()),
      props: {},
    };
    const nodeConfigElems = API.getCurrentConfigurationElements(
      nodeItem,
      sdcItem
    );
    nodeConfigElems.forEach((elem: Item) => {
      freqObj.props[elem.getProp()] = elem.getValue();
      if (
        node.getType() === NodeType.ITDALABELREF &&
        nodeItem.getOriginItem().getType() === "external"
      ) {
        const labelItem = API.getItem(elem.getValue());
        const cmu = labelItem?.getParent(ItemType.ClockCMU);
        const pInstances = sdcItem
          .getParent(ItemType.SDCModule)
          .getSDCDiagram()
          .getInstances()
          .filter((inst: Item) => inst.getModule() === cmu);
        const pInst = pInstances.find(
          (inst: Item) =>
            sdcItem.getPrimaryPowerSource() === inst.getPrimaryPowerSource()
        );
        if (pInst && labelItem) {
          let phantom;
          for (const group of pInst.getSDCPhantomGroups()) {
            if (
              group
                .getSDCPhantoms()
                .find((inst: Item) => inst.getOriginItem() === labelItem)
            ) {
              phantom = group
                .getSDCPhantoms()
                .find((inst: Item) => inst.getOriginItem() === labelItem);
              break;
            }
          }
          freqObj.extSource = {
            itemID: phantom.getId(),
            sdcID: pInst.getId(),
            config: cmu
              .getConfigurationFolder()
              .getSelectedConfiguration()
              .getId(),
          };
        }
      }
    });
    API.setConfigurationFrequency(nodeItem.getId(), freqObj);
  });
};
