/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x40",
    "size": "0x20",
    "alignSize": "0x4",
    "sfrs": [
        {
            "name": "CTRL_PWREN",
            "alignOffset": "0x0",
            "description": "Power Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_PWREN",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "VDD Control Signal for {{name}}\n0 = Set PWREN to 0\n1 = Set PWREN to 1",
                    "initValue": "0x0",
                    "nc": "SFR_PWREN"
                }
            ]
        }
    ]
}