import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodePower from "./ITDANodePower";

export default class ITDAPWREN extends ITDANodePower {
  constructor(name = "") {
    super(name, NodeType.ITDAPWREN);
    this.render = ITDANodeRenderer.ITDAPWREN.render;
  }

  build() {
    const res = super.build();

    const ctrl = this.getCtrlSignal();
    ctrl.newSignal("PWREN", "out");

    return res;
  }
}
