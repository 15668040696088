import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;

    // Inner rectangle (representing the chip)
    const innerRectSize = (centerX / 4) * 7;
    g.append("rect")
      .attr("x", centerX - innerRectSize / 4)
      .attr("y", centerY - innerRectSize / 6)
      .attr("width", innerRectSize / 2)
      .attr("height", innerRectSize / 2)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    // Adding the pins around the inner rectangle
    const pinLength = 5;
    const pinSpacing = 6;

    // Top pins
    for (let i = -2; i <= 2; i++) {
      g.append("line")
        .attr("x1", centerX - pinSpacing * i)
        .attr("y1", centerY - innerRectSize / 6 - pinLength)
        .attr("x2", centerX - pinSpacing * i)
        .attr("y2", centerY - innerRectSize / 6)
        .attr("stroke", "black")
        .attr("stroke-width", STROKE_WIDTH);
    }

    // Bottom pins
    for (let i = -2; i <= 2; i++) {
      g.append("line")
        .attr("x1", centerX - pinSpacing * i)
        .attr("y1", centerY + innerRectSize / 4 + pinLength + 1.5)
        .attr("x2", centerX - pinSpacing * i)
        .attr("y2", centerY + innerRectSize / 4 + pinLength * 2 + 1)
        .attr("stroke", "black")
        .attr("stroke-width", STROKE_WIDTH);
    }

    // Left pins
    for (let i = -2; i <= 2; i++) {
      g.append("line")
        .attr("x1", centerX - innerRectSize / 4)
        .attr("y1", centerY - pinSpacing * i + pinLength)
        .attr("x2", centerX - innerRectSize / 4 - pinLength)
        .attr("y2", centerY - pinSpacing * i + pinLength)
        .attr("stroke", "black")
        .attr("stroke-width", STROKE_WIDTH);
    }

    // Right pins
    for (let i = -2; i <= 2; i++) {
      g.append("line")
        .attr("x1", centerX + innerRectSize / 4)
        .attr("y1", centerY - pinSpacing * i + pinLength)
        .attr("x2", centerX + innerRectSize / 4 + pinLength)
        .attr("y2", centerY - pinSpacing * i + pinLength)
        .attr("stroke", "black")
        .attr("stroke-width", STROKE_WIDTH);
    }

    // MEM text at the center
    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 4.8)
      .attr("text-anchor", "middle")
      .attr("font-size", `8px`)
      .attr("fill", "black")
      .attr("font-weight", "bold")
      .text("MEM");

    // MEM text at the top
    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerX / 4) * 1.5)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("MEM");
  },
};
