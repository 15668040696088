/**
 *  Generated by Modeler
 *  timestamp: 2022-12-21 20:22:35.186401
 */
//import ErrorType from '@/model/ErrorType'
import ItemChecker from "@/model/src/ItemChecker";

export default class VoltageDomainChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
  }
  checkName() {
    // TBD
  }
}
