import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;

    // Circle (Main Timer)
    g.append("circle")
      .attr("cx", centerX)
      .attr("cy", (centerY / 4) * 4.8)
      .attr("r", (centerX / 4) * 2)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    // Inner Line (Inside Timer)
    g.append("line")
      .attr("x1", centerX)
      .attr("y1", (centerY / 4) * 3.5)
      .attr("x2", centerX)
      .attr("y2", (centerY / 4) * 4.8)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH * 1.5);

    g.append("rect")
      .attr("x", (centerX / 4) * 3.5)
      .attr("y", (centerY / 4) * 2.5)
      .attr("width", centerX / 4)
      .attr("height", STROKE_WIDTH * 1.1)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", "black");

    g.append("line")
      .attr("x1", (centerX / 4) * 5.6)
      .attr("y1", (centerY / 4) * 3.5)
      .attr("x2", (centerX / 4) * 5.75)
      .attr("y2", (centerY / 4) * 3.4)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH * 1.5);

    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 1.5)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("TIMER");
  },
};
