import { ClassicPreset } from "rete";
import ITDAControl from "./ITDAControl";

export default class ITDAControlFrequency extends ITDAControl {
  private node: ClassicPreset.Node;
  private freq = "-1";
  constructor(parent: ClassicPreset.Node) {
    super();
    this.node = parent;
  }
  setFrequency(freq: string) {
    this.freq = freq;
    this.update();
  }
  getFrequency(): string {
    return this.freq;
  }
}
