import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodePower from "./ITDANodePower";

export default class ITDALATCH extends ITDANodePower {
  constructor(name = "") {
    super(name, NodeType.ITDALATCH);
    this.render = ITDANodeRenderer.ITDALATCH.render;
  }
}
