/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
//import ErrorType from '@/model/src/ErrorType'
import ItemChecker from '@/model/src/ItemChecker'

export default class ClockBufferChecker extends ItemChecker {
    constructor(item) {
        super(item)
        //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
        //this.addChecker(item.getFidMaxNum(), this.checkMaxNum, ErrorType.NAME)
        //this.addChecker(item.getFidSDCPath(), this.checkSDCPath, ErrorType.NAME)
    }
    checkName() {
        // TBD
    }
    checkMaxNum() {
        // TBD
    }
    checkSDCPath() {
        // TBD
    }
}