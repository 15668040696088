import { RerouteExtensions } from "rete-connection-reroute-plugin";
import { AreaExtensions } from "rete-area-plugin";
import { ITDACommon, ITDACanvasType } from "./types";
import ITDACanvas from "../ITDACanvas";
import { CustomReroutePlugin } from "./utils/ITDACustomReRoute";
import { ITDAConnection } from "../common";
import { ITDANode } from "../nodes";
import { Repository } from "../store";
import store from "@/store";
export default class ITDAConnectionReRoutePlugin extends ITDACanvas {
  res: CustomReroutePlugin<ITDACommon.Schemes>;
  isDragging = false;

  constructor(id: string) {
    super(id, ITDACanvasType.ConnectionReRoute);
    this.res = new CustomReroutePlugin<ITDACommon.Schemes>();

    const selector = AreaExtensions.selector();
    const selectorAccumulating = AreaExtensions.accumulateOnCtrl();
    RerouteExtensions.selectablePins(this.res, selector, selectorAccumulating);
  }

  getInstance(): CustomReroutePlugin<ITDACommon.Schemes> {
    return this.res as CustomReroutePlugin<ITDACommon.Schemes>;
  }

  event() {
    this.res.addPipe((context) => {
      if (!("type" in context)) return context;

      if (context.type === "pinselected") {
        this.res.selectedPinID = context.data.id;
        this.isDragging = true;
      }

      if (context.type === "pointerup") {
        if (this.isDragging) {
          const pin = this.res.pins.getPin(this.res.selectedPinID);
          if (!pin) return;
          const gridSpace = this.repo
            .getEditor(this.repo.getCurrentID())
            .getGridSpace();
          const { x, y } = pin.position;

          const newX = Math.round(x / gridSpace) * gridSpace;
          const newY = Math.round(y / gridSpace) * gridSpace;
          this.res.updatePinPosition(pin.id, newX + 0.3, newY - 2.3);

          this.isDragging = false;
        }
      }
      return context;
    });
  }

  // Update connection path style
  updateConnectionPathStyle(newStyle: "line" | "curve") {
    const itemId = this.repo.getCurrentID();
    // store.commit("canvas/SET_PATH_STYLE", { itemId, style: newStyle });
    store.commit("canvas/SET_PATH_STYLE", newStyle);

    const editor = this.repo.getEditor(this.repo.getCurrentID()).getInstance();
    const area = this.repo.getAreaPlugin(this.repo.getCurrentID());

    editor
      .getConnections()
      .forEach((connection: ITDAConnection<ITDANode, ITDANode>) => {
        area.getInstance().update("connection", connection.id);
      });
  }

  filterPins(
    connections: ITDACommon.ITDAConnection<ITDANode, ITDANode>[],
    selectedTypes: string[]
  ) {
    const repo = Repository.getInstance();

    document
      .querySelectorAll('div[data-type="pin-container"]')
      .forEach((container) => {
        const connection = connections.find((conn) =>
          container.innerHTML.includes(conn.id)
        );

        if (connection) {
          const sourceNode = repo
            .getEditor(repo.getCurrentID())
            .getNode(connection.source);
          const outputSocket = sourceNode.outputs[connection.sourceOutput]
            ?.socket as ITDACommon.ITDASocket;

          container.setAttribute("data-connection-id", connection.id);
          container.setAttribute("data-socket-type", outputSocket.name);

          const isVisible = selectedTypes.includes(
            outputSocket?.getType().getKey() || ""
          );
          const pins = container.querySelectorAll(".pin[data-testid='pin']");

          pins.forEach((pin, index) => {
            pin.className = `pin division-${outputSocket.name}-${connection.id}-${index}`;
            (pin as HTMLElement).style.visibility = isVisible
              ? "visible"
              : "hidden";
          });
        }
      });
  }
}
