/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
//import ErrorType from '@/model/src/ErrorType'
import ItemChecker from '@/model/src/ItemChecker'

export default class UPFLevelShifterChecker extends ItemChecker {
    constructor(item) {
        super(item)
        //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
        //this.addChecker(item.getFidCells(), this.checkCells, ErrorType.NAME)
        //this.addChecker(item.getFidDirection(), this.checkDirection, ErrorType.NAME)
        //this.addChecker(item.getFidRule(), this.checkRule, ErrorType.NAME)
        //this.addChecker(item.getFidLocation(), this.checkLocation, ErrorType.NAME)
        //this.addChecker(item.getFidType(), this.checkType, ErrorType.NAME)
        //this.addChecker(item.getFidIsNeedElements(), this.checkIsNeedElements, ErrorType.NAME)
    }
    checkName() {
        // TBD
    }
    checkCells() {
        // TBD
    }
    checkDirection() {
        // TBD
    }
    checkRule() {
        // TBD
    }
    checkLocation() {
        // TBD
    }
    checkType() {
        // TBD
    }
    checkIsNeedElements() {
        // TBD
    }
}