<template>
  <n-collapse-item
    class="property-table"
    :class="items.length === 0 ? 'property-table__nodata' : undefined">
    <template #header>
      <div :class="props.expanded ? 'expanded-table-title' : undefined">
        {{ API.getChildTableTitle(props.item, props.spec) }}
      </div>
    </template>
    <template #header-extra>
      <v-toolbar flat height="30">
        <v-spacer />
        <v-btn
          color="#1e41cd"
          icon
          small
          :disabled="getDisabled('CREATE')"
          @click="eventCreateItem">
          <v-icon>{{ "mdi-plus-box-outline" }}</v-icon>
        </v-btn>
        <v-divider vertical />
        <v-btn
          color="#1e41cd"
          icon
          small
          :disabled="getDisabled('DELETE')"
          @click="eventRemoveItem">
          <v-icon>{{ "mdi-minus-box-outline" }}</v-icon>
        </v-btn>
      </v-toolbar>
    </template>
    <template #arrow>
      <div></div>
    </template>
    <v-data-table
      :key="key"
      :items="items"
      :headers="headers"
      :hide-default-footer="props.expanded ? true : false">
      <template v-slot:body="{ items, headers }">
        <template v-if="items?.length > 0">
          <tr
            v-for="(item, idx) in items"
            :key="`tr-${idx}`"
            class="table-tr"
            :class="{ selected: idx === selected }"
            @click="onRowClick(item, idx)">
            <td v-for="(header, idx) in headers[0]" :key="`td-${idx}`">
              <ItemPropertyCheckbox
                v-if="Utils.isBooleanType(header.valueType)"
                v-bind="getProps(item, header)" />
              <ItemPropertyAutocomplete
                v-else-if="Utils.isEnumType(header.valueType)"
                v-bind="getProps(item, header)" />
              <ItemPropertyTextarea
                v-else-if="Utils.isTextareaType(header.valueType)"
                v-bind="getProps(item, header)" />
              <ItemPropertyTextField
                v-else-if="Utils.isStringType(header.valueType)"
                v-bind="getProps(item, header)" />
              <ItemPropertyIntegerField
                v-else-if="Utils.isIntegerType(header.valueType)"
                v-bind="getProps(item, header)" />
              <ItemPropertyItemField
                v-else-if="Utils.isItemType(header.valueType)"
                v-bind="getProps(item, header)" />
              <ItemPropertyColorPicker
                v-else-if="Utils.isColorType(header.valueType)"
                v-bind="getProps(item, header)" />
              <div v-else>{{ item }}</div>
            </td>
            <td v-if="isPowerConfiguration" class="copy">
              <v-btn
                v-if="item.name !== 'PMR'"
                color="#1e41cd"
                size="small"
                @click="() => eventCopyItem(item)">
                COPY
              </v-btn>
            </td>
          </tr>
        </template>
        <template v-else>
          <tr class="table-tr">
            <td :colspan="headers[0]?.length">
              <NoData type="data" />
            </td>
          </tr>
        </template>
      </template>
    </v-data-table>
  </n-collapse-item>
</template>

<script setup lang="ts">
import Utils from "./utils";

import ItemPropertyCheckbox from "./ItemPropertyCheckbox.vue";
import ItemPropertyAutocomplete from "./ItemPropertyAutocomplete.vue";
import ItemPropertyTextField from "./ItemPropertyTextField.vue";
import ItemPropertyTextarea from "./ItemPropertyTextarea.vue";
import ItemPropertyIntegerField from "./ItemPropertyIntegerField.vue";
import ItemPropertyItemField from "./ItemPropertyItemField.vue";
import ItemPropertyColorPicker from "./ItemPropertyColorPicker.vue";
import { ref, reactive, defineProps, onMounted } from "vue";
import { useStore } from "vuex";
import {
  Item,
  ChildSpec,
  ItemPropsType,
  ChildTableHeader,
  Property,
  ItemType,
} from "ITDAModelTypes";
import API from "@/api/internal";
import NoData from "../NoData.vue";

const props = defineProps<{
  item: Item;
  spec: ChildSpec;
  expanded?: boolean;
}>();

const store = useStore();

let isPowerConfiguration = ref<boolean>(false);
let items = reactive(API.getChildTableItems(props.item, props.spec));
let headers = ref<ChildTableHeader[]>([]);
const key = ref<boolean>(false);

const getProps = (item: Item, header: ChildTableHeader): ItemPropsType => {
  let itemObj = API.getItem(item.id);
  if (itemObj) {
    return {
      item: itemObj,
      propKey: header.value.toString(),
      prop: header.valueType as Property,
      table: true,
    };
  } else {
    throw new Error(`Please check the item id ${item.id}`);
  }
};

const eventCreateItem = async () => {
  try {
    store.commit("SET_IN_PROGRESS", "CREATE_ITEM");
    const item = await API.createItem(
      props.item,
      props.spec.name,
      props.spec.properties ? props.spec.properties : {}
    );
    if (item) {
      items = API.getChildTableItems(props.item, props.spec);
      selected.value = items.length - 1;
    }
    refreshTable();
  } catch (e) {
    console.error(e);
  } finally {
    store.commit("SET_DONE", "CREATE_ITEM");
  }
};

const eventRemoveItem = async () => {
  try {
    store.commit("SET_IN_PROGRESS", "REMOVE_ITEM");
    items = API.getChildTableItems(props.item, props.spec);
    if (selected.value >= 0) {
      await API.removeItem(items[selected.value]?.id);
      if (items[selected.value])
        items = API.getChildTableItems(props.item, props.spec);
      if (!items[selected.value]) {
        selected.value = selected.value - 1;
      }
    } else {
      selected.value = items.length - 1;
    }
    refreshTable();
  } catch (e) {
    console.error(e);
  } finally {
    store.commit("SET_DONE", "REMOVE_ITEM");
  }
};

const eventCopyItem = async (item: Item) => {
  try {
    store.commit("SET_IN_PROGRESS", "COPY_ITEM");
    await API.copyPowerSequenceDiagram(props.item, item.id);
    refreshTable();
  } catch (e) {
    console.error(e);
  } finally {
    store.commit("SET_DONE", "COPY_ITEM");
  }
};

const refreshTable = () => {
  key.value = key.value === false ? true : false;
};

const onRowClick = (item: Item, idx: number) => {
  if (idx === selected.value) {
    selected.value = -1;
  } else {
    selected.value = idx;
  }
};

const selected = ref<number>(-1);

const getDisabled = (btnType: string) => {
  return API.disableTableButton(
    props.item,
    API.disableProp(props.item) || API.disablePropTable(props.item, props.spec),
    { specName: props.spec.name, btnType }
  );
};

onMounted(() => {
  if (props.item.getItemType() === ItemType.PowerSequenceConfiguration) {
    if (props.item.getTitle() === "PMD") {
      isPowerConfiguration.value = true;
      headers.value = API.getChildTableHeaders(props.spec);
    } else if (props.item.getTitle() === "PMR") {
      isPowerConfiguration.value = false;
      headers.value = API.getChildTableHeaders(props.spec).filter((header) => {
        return header.title?.toLowerCase() !== "copy";
      });
    }
  } else {
    headers.value = API.getChildTableHeaders(props.spec);
  }
});
</script>

<style lang="sass" scoped>
.expanded-table-title
  font-family: Roboto
  font-size: 0.8rem
  font-weight: 500
  .table-tr
    gap: 0.2rem
.expanded-table
  width: 100%
  padding: 0
.v-toolbar
  background: none !important
td
  padding: 0 !important
div.v-container.v-locale--is-ltr
  padding: 0px !important
.selected
  background-color: gainsboro
.copy
  text-align: center
  width: 80px
  min-width: 80px
  max-width: 80px
</style>
