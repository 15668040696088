/**
 *  Generated by Modeler
 *  timestamp: 2023-11-16 11:47:29.775775
 */
//import ErrorType from '@/model/ErrorType'
import ItemChecker from "@/model/src/ItemChecker";

export default class SDCModuleFolderChecker extends ItemChecker {
  constructor(item) {
    super(item);
  }
}
