import {
  Item,
  VariantLevel,
  RefItemElement,
  RefItemElementType,
  RefItemElementTarget,
  RefItemEvent,
  Property,
  ItemType,
} from "ITDAModelTypes";
import API from "@/api/internal";
import COMP from "@/components/index";

export const getSelectedConfiguration = (item: Item): RefItemElement[] => {
  const targets = getTargets(item);
  const configuration = item.getSelectedConfiguration();
  const model = configuration ? getModel(configuration) : undefined;
  return getEnumRefItem(model, targets, getConfigurationEvent);
};

const getConfigurationEvent = (
  item: Item,
  elem: RefItemElement,
  value: string | string[] | number | number[]
) => {
  const target = elem.targets?.find((target) => target.key === value);
  if (target) {
    item.setSelectedConfiguration(target.item.getId());
  } else {
    item.setSelectedConfiguration(null);
  }
};

const getModel = (refItem: Item): string => {
  return refItem.getName();
};

const getTargets = (item: Item): RefItemElementTarget[] => {
  const targets: RefItemElementTarget[] = [];
  item
    .getConfigurations()
    .forEach((config: Item) =>
      targets.push({ key: config.getName(), item: config, closable: true })
    );
  return targets;
};

export const getConfigurationElement = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const model = getConfigElementModel(item, propKey);
  return [
    {
      variant: undefined,
      disabled: false,
      type:
        prop.type === "integer"
          ? RefItemElementType.INTEGER
          : RefItemElementType.STRING,
      propKey: propKey || "",
      label: prop.label ? prop.label : "",
      multiple: false,
      model: model,
      event: async (
        item: Item,
        elem: RefItemElement,
        value: string | string[] | number | number[]
      ) => {
        value = value as string | number;
        value = prop.type === "integer" ? Number(value) : value;
        if (item.getParent(ItemType.ClockCMU)) {
          const configElements = API.getCMUConfigurationElements(item);
          const existConfigElem = configElements.find(
            (e) => e.getProp() === propKey
          );
          if (existConfigElem) {
            existConfigElem.setValue(value);
            if (existConfigElem.getProp() === "denominator") {
              const numeratorElem = configElements.find(
                (e) => e.getProp() === "numerator"
              );
              if (numeratorElem) {
                numeratorElem.setValue(0);
              }
              COMP.updateComponent("NAV_RIGHT_CONFIGURATION");
            }
          } else {
            const cmuConfiguration = API.getSelectedCMUConfiguration(item);
            const newConfigElem =
              cmuConfiguration &&
              (await API.createItem(
                cmuConfiguration,
                ItemType.ConfigurationElement.getKey()
              ));
            if (newConfigElem) {
              newConfigElem.setValue(value);
              newConfigElem.setProp(propKey);
              newConfigElem.setItemRef(item.getId());
            }
          }
        } else if (item.getParent(ItemType.PowerPMD)) {
          const configElements = API.getPMDConfigurationElements(item);
          const existConfigElem = configElements.find(
            (e) => e.getProp() === propKey
          );
          if (existConfigElem) {
            existConfigElem.setValue(value);
          } else {
            const pmdConfiguration = API.getSelectedPMDConfiguration(item);
            const newConfigElem =
              pmdConfiguration &&
              (await API.createItem(
                pmdConfiguration,
                ItemType.ConfigurationElement.getKey()
              ));
            if (newConfigElem) {
              newConfigElem.setValue(value);
              newConfigElem.setProp(propKey);
              newConfigElem.setItemRef(item.getId());
            }
          }
        } else if (item.getParent(ItemType.PowerPMC)) {
          const configElements = API.getPMCConfigurationElements(item);
          const existConfigElem = configElements.find(
            (e) => e.getProp() === propKey
          );
          if (existConfigElem) {
            existConfigElem.setValue(value);
          } else {
            const pmcConfiguration = API.getSelectedPMCConfiguration(item);
            const newConfigElem =
              pmcConfiguration &&
              (await API.createItem(
                pmcConfiguration,
                ItemType.ConfigurationElement.getKey()
              ));
            if (newConfigElem) {
              newConfigElem.setValue(value);
              newConfigElem.setProp(propKey);
              newConfigElem.setItemRef(item.getId());
            }
          }
        }
      },
    },
  ];
};

const getConfigElementModel = (
  item: Item,
  propKey: string
): string | undefined => {
  if (item.getParent(ItemType.ClockCMU)) {
    const configElements = API.getCMUConfigurationElements(item);
    const existConfigElem = configElements.find((e) => e.getProp() === propKey);

    if (existConfigElem) {
      if (item.getItemType() === ItemType.LabelRef) {
        const extLabel = API.getItem(existConfigElem.getValue());
        return extLabel ? API.getTitle(extLabel) : undefined;
      } else {
        return existConfigElem.getValue();
      }
    } else if (item.getItemType() === ItemType.LabelRef) {
      return undefined;
    }
  } else if (item.getParent(ItemType.PowerPMD)) {
    const configElements = API.getPMDConfigurationElements(item);
    const existConfigElem = configElements.find((e) => e.getProp() === propKey);

    if (existConfigElem) {
      return existConfigElem.getValue();
    }
  } else if (item.getParent(ItemType.PowerPMC)) {
    const configElements = API.getPMCConfigurationElements(item);
    const existConfigElem = configElements.find((e) => e.getProp() === propKey);

    if (existConfigElem) {
      return existConfigElem.getValue();
    }
  }
  return "0";
};

export const getExternalLabels = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const model = getConfigElementModel(item, propKey);
  return [
    {
      variant: VariantLevel.LEVEL0,
      disabled: false,
      type: RefItemElementType.ENUM,
      propKey: propKey,
      label: prop.label ? prop.label : "",
      multiple: false,
      model: model,
      targets: getExternalLabelTargets(item),
      event: async (
        item: Item,
        elem: RefItemElement,
        value: string | string[] | number | number[]
      ) => {
        if (item.getParent(ItemType.ClockCMU)) {
          const target = elem.targets?.find((target) => target.key === value);
          const configElements = API.getCMUConfigurationElements(item);
          const existConfigElem = configElements.find(
            (e) => e.getProp() === propKey
          );
          if (target) {
            if (existConfigElem) {
              existConfigElem.setValue(target.item.getId());
            } else {
              const cmuConfiguration = API.getSelectedCMUConfiguration(item);
              const newConfigElem =
                cmuConfiguration &&
                (await API.createItem(
                  cmuConfiguration,
                  ItemType.ConfigurationElement.getKey()
                ));
              if (newConfigElem) {
                newConfigElem.setValue(target.item.getId());
                newConfigElem.setProp(propKey);
                newConfigElem.setItemRef(item.getId());
              }
            }
          } else if (!target && existConfigElem) {
            existConfigElem.setValue(null);
          }
        }
      },
    },
  ];
};

const getExternalLabelTargets = (item: Item): RefItemElementTarget[] => {
  const cmuFolder = item.getParent(ItemType.ClockCMUFolder);
  let targets: RefItemElementTarget[] = [];
  for (const cmu of cmuFolder.getClockCMUs()) {
    targets = targets.concat(
      cmu
        .getClockDiagramFolder()
        .getClockDiagrams()
        .reduce((acc: RefItemElementTarget[], cur: Item) => {
          cur
            .getLabels()
            .filter((label: Item) => label.getType() === "external")
            .forEach((label: Item) => {
              const name = API.getTitle(label);
              if (name) {
                acc.push({
                  key: name,
                  item: label,
                  closable: true,
                });
              }
            });
          return acc;
        }, [])
    );
  }
  return targets;
};

export const getSDCFrequencyConfiguration = (
  item: Item,
  propKey: string,
  prop: Property
) => {
  const selectedCMUModule = item.getModule();
  if (selectedCMUModule) {
    const moduleItem = selectedCMUModule;
    if (!moduleItem) {
      return getEnumRefItem(undefined, [], getSDCFrequencyEvent, prop.label);
    }
    const configFolder = moduleItem.getConfigurationFolder();
    const targets = getTargets(configFolder);
    const voltageDomain = item.getPrimaryPowerSource();
    if (!voltageDomain) {
      return getEnumRefItem(undefined, [], getSDCFrequencyEvent, prop.label);
    }
    const voltageConfig = voltageDomain
      .getConfigurationFolder()
      .getSelectedConfiguration();
    if (!voltageConfig) {
      return getEnumRefItem(undefined, [], getSDCFrequencyEvent, prop.label);
    }
    const voltageConfigElem = voltageConfig
      .getConfigurationElements()
      ?.find((e: Item) => e.getItemRef() === item);
    const configuration = voltageConfigElem
      ? API.getItem(voltageConfigElem.getValue())
      : undefined;
    const model = configuration ? configuration.getName() : undefined;
    return getEnumRefItem(
      model,
      targets,
      getSDCFrequencyEvent,
      prop.label,
      propKey
    );
  }

  return getEnumRefItem(undefined, [], getSDCFrequencyEvent, prop.label);
};

const getSDCFrequencyEvent = async (
  item: Item,
  elem: RefItemElement,
  value: string | string[] | number | number[]
) => {
  const targetConfig = elem.targets?.find((target) => target.key === value);

  const voltageDomain = item.getPrimaryPowerSource();
  if (!voltageDomain) {
    return;
  }
  const voltageConfig = voltageDomain
    .getConfigurationFolder()
    .getSelectedConfiguration();
  if (!voltageConfig) {
    return;
  }

  const voltageConfigElem = voltageConfig
    .getConfigurationElements()
    ?.find((e: Item) => e.getItemRef() === item);
  if (voltageConfigElem) {
    if (targetConfig) {
      voltageConfigElem.setValue(targetConfig?.item.getId());
    } else {
      voltageConfigElem.setValue(null);
    }
  } else {
    const newConfigElem = await API.createItem(
      voltageConfig,
      ItemType.ConfigurationElement.getKey()
    );
    if (newConfigElem) {
      newConfigElem.setProp(elem.propKey);
      newConfigElem.setItemRef(item.getId());
      newConfigElem.setValue(targetConfig?.item.getId());
    }
  }
};

const getEnumRefItem = (
  model: string | undefined,
  targets: RefItemElementTarget[],
  event: RefItemEvent,
  label?: string,
  propKey?: string
): RefItemElement[] => {
  return [
    {
      variant: VariantLevel.LEVEL0,
      disabled: false,
      type: RefItemElementType.ENUM,
      propKey: propKey ? propKey : "",
      label: label ? label : "",
      multiple: false,
      model: model,
      targets: targets,
      event: event,
    },
  ];
};

export const getItemRefName = (item: Item): RefItemElement[] => {
  const itemRef = item.getItemRef();
  return [
    {
      variant: undefined,
      disabled: true,
      type: RefItemElementType.STRING,
      propKey: "",
      label: "",
      multiple: false,
      model: API.getTitle(itemRef),
      event: () => {
        return;
      },
    },
  ];
};
