import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./index.css";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import PrimeVue from "primevue/config";
// import "primevue/resources/themes/aura-light-green/theme.css";
import "primevue/resources/primevue.min.css";
import naive from "naive-ui";

loadFonts();

const app = createApp(App);
app.use(router).use(store);

app.use(vuetify).use(naive).use(PrimeVue);

app.mount("#app");
