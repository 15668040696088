/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemType from "../ItemType";

export default class ClockIPEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
  }

  lstn_name(item) {
    item.getClockIPInputs().forEach((input) => {
      input.getConnections().forEach((conn) => {
        const gate = conn.getSourceOutput().getParent();
        if (gate.getItemType() === ItemType.ClockGate) {
          gate.setName(`${item.getName()}__${input.getName()}`);
        }
      });
    });
  }
}
