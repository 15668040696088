<template>
  <div class="btn-container">
    <v-btn @click="check()">Check</v-btn>
    <v-btn @click="unCheck()">Uncheck</v-btn>
    <v-btn id="saveBtn" ref="saveBtn" @click="save()">Save</v-btn>
    <div v-if="isPrdMode" class="v-btn">
      <v-btn @click="generatePRDRTL()">Gen RTL</v-btn>
      <v-btn @click="generateDocument()">Gen DOC</v-btn>
    </div>
    <div v-else class="v-btn">
      <div class="v-not-prd">
        <v-btn @click="generateDEVRTL()">Gen DEV RTL</v-btn>
        <v-btn @click="generateUSRRTL()">Gen USER RTL</v-btn>
        <v-btn @click="generatePRDRTL()">Gen PRD RTL</v-btn>
        <v-btn @click="generateDocument()">Gen DOC</v-btn>
      </div>
    </div>
    <v-menu>
      <template v-slot:activator="{ props }">
        <v-img
          class="account"
          v-bind="props"
          src="@/assets/account.svg"
          @click="clickAccount" />
      </template>
      <v-list class="menu-list">
        <v-list-item class="menu-item" @click="clickItem">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script setup lang="ts">
import task from "@/events/task";
import API from "@/api";
import { useStore } from "vuex";
import { ref, onMounted } from "vue";
import { ConfigurationMapType } from "ITDAModelTypes";

const store = useStore();
const check = async () => {
  store.commit("SET_IN_PROGRESS", "CHECK");
  API.runChecker();
  store.commit("SET_DONE", "CHECK");
};
const unCheck = async () => {
  store.commit("SET_IN_PROGRESS", "UNCHECK");
  API.clearErrors();
  store.commit("SET_DONE", "UNCHECK");
};
const save = async () => {
  store.commit("SET_IN_PROGRESS", "SAVE");

  const date = Date.now();
  API.runChecker();

  const design = API.exportDB(date, "design");
  await API.save(store.getters["GET_DESIGN"], "design", design);

  const config = API.exportDB(date, "config");
  await API.save(store.getters["GET_CONFIG"], "config", config);

  API.clearErrors();
  store.commit("SET_DONE", "SAVE");
};

const generateDEVRTL = async () => {
  await generateRTL("dev");
};
const generateUSRRTL = async () => {
  await generateRTL("user");
};
const generatePRDRTL = async () => {
  await generateRTL("prd");
};
const generateDocument = async () => {
  await generateRTL("doc");
};
const generateRTL = async (type: "dev" | "user" | "prd" | "doc") => {
  store.commit("SET_IN_PROGRESS", "generateRTL");
  const configMap: ConfigurationMapType = await task.createConfigMap();
  if (store.getters["GET_CONFIG"]) {
    await API.generateRTL(
      API.getTopItem()?.getTitle(),
      store.getters["GET_DESIGN"],
      store.getters["GET_CONFIG"],
      configMap,
      type
    );
  } else {
    alert("Please select a config DB.");
  }
  store.commit("SET_DONE", "generateRTL");
};
const isPrdMode = ref(true);
const menuShow = ref(false);
let menuPosX = 0;
let menuPosY = 0;
const clickAccount = (e: PointerEvent) => {
  menuPosX = e.clientX + 10;
  menuPosY = e.clientY + 24;
  menuShow.value = true;
};
const clickItem = async () => {
  await API.logout();
};
onMounted(async () => {
  const license = await API.getLicenses();
  let prdMode = false;
  if ("ITDA_IS_PRD_MODE" in license) {
    prdMode = license["ITDA_IS_PRD_MODE"] as boolean;
  }
  isPrdMode.value = prdMode;
});
</script>
<style lang="sass" scoped>
.v-btn .v-btn--elevated
  display: block !important
.v-not-prd
  display: flex
</style>
<style lang="css" scoped>
.v-btn.v-btn--elevated {
  border: 1.5px solid lightgray;
  box-shadow: none;
}

.btn-container {
  display: flex;
  justify-content: flex-end;
  margin-right: 0.5rem;
  gap: 0.3rem;
}
.btn-container div {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}
.btn-container button {
  font-weight: 500;
  border: 1px solid #9b9fa1;
  border-radius: 11px;
  height: 24px;
  padding: 2px 12px 2px 12px;
  letter-spacing: initial;
}
.account {
  min-width: 24px;
  max-width: 24px;
  min-height: 24px;
  max-height: 24px;
  cursor: pointer;
}
.menu-list {
  border: 1px solid lightgray;
  border-radius: 10px;
  padding: 0.25rem;
  filter: drop-shadow(-1px 6px 3px rgba(0, 0, 0, 0.15));
}
.menu-item:hover {
  border-radius: 5px !important;
  cursor: pointer;
  background-color: #d8eaf8;
}
</style>
