import IH from "../src/ItemHandler";
import { ItemProperties, Item, ValidationRule } from "../types";
import ItemType from "../src/ItemType";

export const createItem = async (
  parent: Item | null,
  type: string,
  properties: ItemProperties = {},
  title = "",
  category = ""
): Promise<Item> => {
  const { item, promise } = IH.createItem(
    parent,
    ItemType.getObjByKey(type),
    properties,
    title,
    category
  ) as {
    item: Item;
    promise: Promise<any>;
  };
  await promise;

  if (title) {
    item?.setTitle(title);
  }
  return item;
};

export const removeItem = (id: string): Promise<void> => {
  return IH.removeItem(id);
};

export const getItemRules = (item: Item, prop: string): ValidationRule[] => {
  return IH.getRules(item, prop);
};
