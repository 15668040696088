/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import UPFHardMacroEvent from '@/model/src/events/UPFHardMacroEvent'
import UPFHardMacroChecker from '@/model/src/checkers/UPFHardMacroChecker'
export default class UPFHardMacro extends Node {
    FID_UPFHARDMACRO_NAME = 100
    FID_UPFHARDMACRO_TYPE = 101
    FID_UPFHARDMACRO_PRIMARYPOWERSOURCE = 102
    FID_UPFHARDMACRO_LAST = 102

    constructor(obj) {
        super(Object.assign(obj,{itemType:"UPFHardMacro"}))
        
        this.setEvent(new UPFHardMacroEvent(this))
        this.setChecker(new UPFHardMacroChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "HM_NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "UPFHardMacro"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "HM_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "type": {"type": "enum", "targets": [], "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "primaryPowerSource": {"type": "item", "label": "Primary Power", "desc": "Primary Power", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "HM_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "type": {"type": "enum", "targets": [], "multiple": false, "category": "IP", "hidden": false, "readOnly": false}, "primaryPowerSource": {"type": "item", "label": "Primary Power", "desc": "Primary Power", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "UPFPhantomPowerSource", "auto": false, "searchable": false, "title": "Power Source Mapper", "headers": [{"title": "Power Supply", "value": "powerSource", "align": "start", "sortable": true}, {"title": "Mapped Power Source", "value": "mappedPower", "align": "start", "sortable": true}], "category": "IP"}, {"name": "UPFPhantomGround", "auto": false, "searchable": false, "title": "Ground Mapper", "headers": [{"title": "Power Supply", "value": "powerSource", "align": "start", "sortable": true}, {"title": "Mapped Ground", "value": "mappedPower", "align": "start", "sortable": true}], "category": "IP"}, {"name": "UPFElement", "auto": false, "searchable": false, "title": "Scope Info", "headers": [{"title": "Scope (Instance Name)", "value": "name", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFHARDMACRO_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_UPFHARDMACRO_NAME
    }
    

    setType(type, event=true) {
        if ((type !== null) && (type !== undefined)) {
            if (![String].includes(type.constructor)) {
                throw `type(${type}) must be [String] type`
            }
        }
        const newVal = (type === null || type === undefined) ? undefined : type
        const oldVal = this.properties.type
        this.properties.type = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFHARDMACRO_TYPE, newVal, oldVal)
        }
    }
    getType(pure = false) {
        return this.properties.type
        
    }
    getFidType() {
        return this.FID_UPFHARDMACRO_TYPE
    }
    

    setPrimaryPowerSource(primaryPowerSource, event=true) {
        if ((primaryPowerSource !== null) && (primaryPowerSource !== undefined)) {
            if (!(primaryPowerSource instanceof Item) && (primaryPowerSource.constructor !== String)) {
                throw `primaryPowerSource(${primaryPowerSource}) must be Item or String type`
            }
        }
        const newVal = (primaryPowerSource === null || primaryPowerSource === undefined) ? undefined : (primaryPowerSource.constructor === String) ? primaryPowerSource : primaryPowerSource.getId()
        const oldVal = this.properties.primaryPowerSource
        this.properties.primaryPowerSource = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_UPFHARDMACRO_PRIMARYPOWERSOURCE, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getPrimaryPowerSource(pure = false) {
        return pure ? this.properties.primaryPowerSource : this.getRefItem(this.properties.primaryPowerSource)
        
    }
    getFidPrimaryPowerSource() {
        return this.FID_UPFHARDMACRO_PRIMARYPOWERSOURCE
    }
    

    getFidLast() {
        return this.FID_UPFHARDMACRO_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_UPFHARDMACRO_NAME:
                return "name"

            case this.FID_UPFHARDMACRO_TYPE:
                return "type"

            case this.FID_UPFHARDMACRO_PRIMARYPOWERSOURCE:
                return "primaryPowerSource"

            default:
                return ""
        }
    }


    newUPFPhantomPowerSource(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFPhantomPowerSource", properties, title, domain)
    }
    remUPFPhantomPowerSource(obj) {
        return this.removeChild(obj)
    }
    getUPFPhantomPowerSources() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFPhantomPowerSource")
            return res
        })
    }
    newUPFPhantomGround(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFPhantomGround", properties, title, domain)
    }
    remUPFPhantomGround(obj) {
        return this.removeChild(obj)
    }
    getUPFPhantomGrounds() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFPhantomGround")
            return res
        })
    }
    newUPFElement(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFElement", properties, title, domain)
    }
    remUPFElement(obj) {
        return this.removeChild(obj)
    }
    getUPFElements() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFElement")
            return res
        })
    }
}