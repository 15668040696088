/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import { Domain } from '@/model/types'
import SDCModuleFolderEvent from '@/model/src/events/SDCModuleFolderEvent'
import SDCModuleFolderChecker from '@/model/src/checkers/SDCModuleFolderChecker'
export default class SDCModuleFolder extends Item {
    FID_SDCMODULEFOLDER_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"SDCModuleFolder"}))
        
        this.setEvent(new SDCModuleFolderEvent(this))
        this.setChecker(new SDCModuleFolderChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "SDC"
        }
    }

    getDomain() {
        return Domain.SDC
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "SDCModuleFolder"
    }

    getSchema() {
        return {}
    }

    static getSchema() {
        return {}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "SDCModule", "auto": false, "searchable": false, "title": "SDC List", "headers": [{"title": "Name", "align": "start", "filterable": true, "value": "name", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    getFidLast() {
        return this.FID_SDCMODULEFOLDER_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            default:
                return ""
        }
    }


    newSDCModule(properties={}, title = "", domain = "") {
        return this.createChild(this, "SDCModule", properties, title, domain)
    }
    remSDCModule(obj) {
        return this.removeChild(obj)
    }
    getSDCModules() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "SDCModule")
            return res
        })
    }
}