import * as ITDAControl from "../controls";
import * as ITDANodeRenderer from "./renderers";
import { NodeType } from "../types";
import ITDANodeClock from "./ITDANodeClock";
import { InputSpec, OutputSpec } from "./types";
export default class ITDASOURCE extends ITDANodeClock {
  constructor(name = "") {
    super(name, NodeType.ITDASOURCE);
    this.render = ITDANodeRenderer.ITDASOURCE.render;
  }

  build(inputs?: InputSpec[], outputs?: OutputSpec[]) {
    const res = super.build(inputs, outputs);

    this.updateFrequency(this.getCtrlFrequency());

    return res;
  }

  async data(
    inputs: Record<`CLK_INPUT`, any>
  ): Promise<Record<`CLK_OUTPUT`, any>> {
    const freq = this.getCtrlFrequency();
    this.updateFrequency(freq);
    return { CLK_OUTPUT: freq.getFrequency() };
  }

  private updateFrequency(freq: ITDAControl.ITDAControlFrequency) {
    const configElements = this.getConfigurationElements();
    const frequency = configElements.length
      ? Number(configElements[0].getValue()).toFixed(2)
      : this.getFrequencyInitValue();
    if (frequency) {
      freq.setFrequency(frequency);
      this.update();
    }
  }
}
