/**
 *  Generated by Modeler
 *  timestamp: 2023-08-07 18:59:42.967900
 */
import ItemEvent from "@/model/src/ItemEvent";
// import CanvasUtils from "../../canvas/CanvasUtils";
import ConfigurationHandler from "../ConfigurationHandler";
import ItemHandler from "../ItemHandler";
import ItemType from "../ItemType";
import SDCHandler from "../SDCHandler";
import Utils from "../../utils";

export default class SDCInstanceEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
    this.addPropertyListener(item.getFidModule(), this.lstn_module);
    this.addPropertyListener(
      item.getFidPrimaryPowerSource(),
      this.lstn_primary_power_source
    );
    // this.addPropertyListener(item.getFidNodeId(), this.lstn_nodeId)
  }
  lstn_name() {
    // const canvas = CanvasUtils.getCurrentCanvas();
    // canvas.vueContext.$parent.$forceUpdate();
  }
  async lstn_module(item, newVal) {
    const cmu = newVal;
    await Promise.all(
      item.getSDCPhantomGroups().map((cItem) => ItemHandler.removeItem(cItem))
    );
    await SDCHandler.createPhantomNodeItems(item, cmu);
    await SDCHandler.createPhantomConnections(item.getParent(), cmu);
  }
  lstn_primary_power_source(item) {
    if (Utils.isConfigurationTarget(item)) {
      ItemHandler.getTopItem()
        .getVoltage()
        .getVoltageDomains()
        .forEach((vd) =>
          ConfigurationHandler.refreshConfigurationFolder(
            vd.getConfigurationFolder()
          )
        );
    }
  }
  // lstn_nodeId(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
}
