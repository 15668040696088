<template>
  <div class="configuration-title">
    {{ getTitle() || "Configuration Panel" }}
  </div>
  <v-container v-if="hasElements()">
    <v-row
      v-for="(configElement, idx) in nodeConfigElements(item)"
      :key="`${seed}-${idx}`"
      class="propery text-center">
      <v-col cols="12" overflow="hidden">
        <ItemPropertyItemField v-bind="configElement" />
      </v-col>
    </v-row>
  </v-container>
  <v-container v-else>
    <NoData type="data" />
  </v-container>
</template>

<script setup lang="ts">
import { defineProps } from "vue";
import { Item, ItemConfigSchema } from "ITDAModelTypes";
import API from "@/api/internal";
import ItemPropertyItemField from "@/components/item/ItemPropertyItemField.vue";
import NoData from "@/components/NoData.vue";

const props = defineProps<{
  item: Item;
  seed: string;
}>();
const nodeConfigElements = (item: Item) => {
  const configSchemas: ItemConfigSchema[] = API.getConfigSchema(item);
  const nodeConfigElements = [];
  for (const configSchema of configSchemas) {
    const prop: any = {};
    prop.type = configSchema.type;
    prop.label = configSchema.label;

    nodeConfigElements.push({
      item: item,
      propKey: configSchema.prop,
      prop: prop,
      config: true,
    });
  }
  return nodeConfigElements;
};

const getTitle = (): string | undefined => {
  const item = API.getCurrentItem();
  return item ? API.getConfigurationTitle(item) : undefined;
};

const hasElements = () => {
  return nodeConfigElements(props.item).length > 0 ? true : false;
};
</script>

<style lang="sass" scoped>
.configuration-title
    width: inherit
    margin-top: 16px
    padding-left: 16px
    font-weight: bolder
    font-size: medium
</style>
