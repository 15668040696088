'use strict';

export default class Category {

    static IP       = new Category(0, "IP", "IP")
    static SOC      = new Category(1, "SOC", "SOC")
    static ETC      = new Category(2, "ETC", "")
    
    constructor(index, key, label="") {
        this.index = index
        this.key = key
        this.label = label
    }

    static getKeys() {
        return Object.keys(this)
    }

    static getValues() {
        return Object.values(this)
    }

    static getEntries() {
        return Object.entries(this)
    }
    
    static getObjByKey(key) {
        return this[key]
    }

    getKey() {
        return this.key
    }

    getLabel() {
        return this.label
    }
}
