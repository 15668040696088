import API from "@/api/internal";
import {
  Item,
  ItemType,
  RefItemElement,
  RefItemElementTarget,
  RefItemElementType,
} from "ITDAModelTypes";

export const getGateElements = (ipInput: Item, propKey: string) => {
  const childrenData: Item[] = [];

  const ip = ipInput.getParent();
  if (ip) {
    let conns = ipInput.getConnections();
    if (conns.length) {
      const gateOutput = conns[0].getSourceOutput();
      const gate = gateOutput?.getParent();
      const gateInputs = gate?.getInputs();

      if (gateInputs?.length) {
        conns = gateInputs[0].getConnections();
        if (conns.length) {
          const labelRefOutput = conns[0].getSourceOutput();
          const labelRef = labelRefOutput.getParent();

          const labelRefOutputs = labelRef.getOutputs();
          if (labelRefOutputs.length) {
            labelRefOutputs[0].getConnections().forEach((conn: Item) => {
              const targetInput = conn.getTargetInput();
              const cItem = targetInput.getParent();
              if (
                cItem.getItemType() === ItemType.ClockGate &&
                targetInput !== gateInputs[0]
              ) {
                childrenData.push(cItem);
              }
            });
          }
        }
      }
    }
  }

  const model = ipInput.getShared()
    ? ipInput.getShared()?.getName()
    : undefined;

  return [
    {
      disabled: false,
      type: RefItemElementType.ENUM,
      propKey,
      label: "",
      model,
      targets: getGateLabels(childrenData),
      event: eventLabels,
    },
  ];
};

const getGateLabels = (gates: Item[]): RefItemElementTarget[] => {
  const res: RefItemElementTarget[] = [];
  gates.forEach((gateItem) => {
    if (gateItem)
      res.push({
        key: gateItem.getName(),
        item: gateItem,
        closable: true,
      });
  });
  res.sort((a, b) => {
    return a.key.localeCompare(b.key, "ko");
  });
  return res;
};

const eventLabels = (
  item: Item,
  elem: RefItemElement,
  value: string | string[] | number | number[]
) => {
  const target = elem.targets?.find((target) => target.key === value);
  if (target) {
    item.setShared(target.item.getId());
  } else {
    item.setShared(null);
  }
};
