import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodeSequence from "./ITDANodeSequence";

export default class ITDASEQBACK extends ITDANodeSequence {
  constructor(name = "") {
    super(name, NodeType.ITDASEQBACK);
    this.render = ITDANodeRenderer.ITDASEQBACK.render;
  }

  set width(data: number) {
    super.width = this.adjustNodeToGrid(data);
  }

  get width() {
    const gridSpace = this.repo
      .getEditor(this.repo.getCurrentID())
      .getGridStroke();
    return this.adjustNodeToGrid(super.width) + gridSpace * 2;
  }

  set height(data: number) {
    super.height = this.adjustNodeToGrid(data);
  }

  get height() {
    return this.adjustNodeToGrid(super.height);
  }
}
