import { ITDAAreaPlugin } from "@/canvas/plugins";
import { Repository } from "@/canvas/store";
const repo = Repository.getInstance();

type Rect = {
  left: number;
  top: number;
  right: number;
  bottom: number;
  deltaX: number;
  deltaY: number;
};

export default class AreaSelection {
  left = 0;
  top = 0;
  pointerStart = { x: 0, y: 0 };
  pointerCurrent = { x: 0, y: 0 };
  rect!: DOMRect;

  isDragging = false;
  width = 15000;
  height = 15000;
  isSpacePressed = false;

  private elem: HTMLElement;
  private selection: HTMLElement;
  private area: ITDAAreaPlugin = repo.getAreaPlugin(repo.getCurrentID());
  private pointerStartPos: { x: number; y: number } | null = null;

  constructor(
    background: HTMLElement,
    selection: HTMLElement,
    width: number,
    height: number
  ) {
    this.elem = background;
    this.selection = selection;
    this.elem.addEventListener(
      "pointerdown",
      this.handlePointerDown.bind(this)
    );
    this.elem.addEventListener(
      "pointermove",
      this.handlePointerMove.bind(this)
    );
    this.elem.addEventListener("pointerup", this.handlePointerUp.bind(this));

    window.addEventListener("keydown", this.handleKeyDown.bind(this));
    window.addEventListener("keyup", this.handleKeyUp.bind(this));

    this.width = width;
    this.height = height;

    this.elem.style.display = "table";
    this.elem.style.zIndex = "-1";
    this.elem.style.position = "absolute";
    this.elem.style.left = `-${this.width / 2}px`;
    this.elem.style.top = `-${this.height / 2}px`;
    this.elem.style.width = `${this.width}px`;
    this.elem.style.height = `${this.height}px`;
  }

  private handleKeyDown(e: KeyboardEvent) {
    if (e.code === "Space") {
      this.isSpacePressed = true;
      this.isDragging = false;
      document.body.style.cursor = "grab";
    }
  }

  private handleKeyUp(e: KeyboardEvent) {
    if (e.code === "Space") {
      this.isSpacePressed = false;
      document.body.style.cursor = "default";
    }
  }

  handlePointerDown(e: PointerEvent) {
    if (this.isSpacePressed) {
      this.pointerStartPos = { x: e.pageX, y: e.pageY };
      document.body.style.cursor = "grabbing";
    } else if (this.area.isPressedControlLeft()) {
      if (this.elem) {
        this.rect = this.elem.getBoundingClientRect();
      }

      this.pointerStart.x = e.pageX;
      this.pointerStart.y = e.pageY;
      this.pointerCurrent.x = e.pageX;
      this.pointerCurrent.y = e.pageY;
      this.isDragging = true;

      const editor = repo.getEditor(repo.getCurrentID());
      editor.getNodes().forEach((node) => {
        if (node.selected) {
          node.selected = false;
          node.update();
        }
      });

      this.update();

      this.area.isDragging = true;
      this.area.selected = [];
    }
  }

  handlePointerMove(e: PointerEvent) {
    if (this.isSpacePressed && this.pointerStartPos) {
      const dx = e.pageX - this.pointerStartPos.x;
      const dy = e.pageY - this.pointerStartPos.y;

      const transform = this.area.getArea().transform;
      this.area.getArea().translate(transform.x + dx, transform.y + dy);

      this.pointerStartPos = { x: e.pageX, y: e.pageY };
    } else if (this.isDragging && !this.isSpacePressed) {
      this.pointerCurrent.x = e.pageX;
      this.pointerCurrent.y = e.pageY;
      this.update();
    }
  }

  async handlePointerUp(e: PointerEvent) {
    if (this.isSpacePressed) {
      document.body.style.cursor = this.isSpacePressed ? "grab" : "default";
      this.pointerStartPos = null;
      return;
    }
    if (this.isDragging) {
      this.isDragging = false;
      const editor = repo.getEditor(repo.getCurrentID());
      for (const node of editor.getNodes()) {
        const nodeView = this.area.getNodeView(node.id);
        if (nodeView) {
          if (
            this.containRect(this.getRect(), {
              left: nodeView.position.x,
              top: nodeView.position.y,
              right: nodeView.position.x + node.width,
              bottom: nodeView.position.y + node.height,
              deltaX: node.width,
              deltaY: node.height,
            })
          ) {
            await this.area
              .getInstance()
              .emit({ type: "nodepicked", data: { id: node.id } });
            this.area.selected = [node];
          }
        }
      }
      this.update();
    }
    this.area.isDragging = false;
  }

  getRect() {
    return {
      left: Math.min(this.pointerStart.x, this.pointerCurrent.x),
      top: Math.min(this.pointerStart.y, this.pointerCurrent.y),
      right: Math.max(this.pointerStart.x, this.pointerCurrent.x),
      bottom: Math.max(this.pointerStart.y, this.pointerCurrent.y),
      deltaX: this.pointerCurrent.x - this.pointerStart.x,
      deltaY: this.pointerCurrent.y - this.pointerStart.y,
    };
  }

  containRect(r1: Rect, r2: Rect) {
    const area = this.area.getArea();
    r1.left = (r1.left - this.rect.x) / area.transform.k - this.width / 2;
    r1.top = (r1.top - this.rect.y) / area.transform.k - this.height / 2;
    r1.right = (r1.right - this.rect.x) / area.transform.k - this.width / 2;
    r1.bottom = (r1.bottom - this.rect.y) / area.transform.k - this.height / 2;
    return (
      r2.left > r1.left &&
      r2.right < r1.right &&
      r2.top > r1.top &&
      r2.bottom < r1.bottom
    );
  }

  update() {
    if (this.selection) {
      const area = this.area.getArea();
      const rect = this.getRect();

      const left =
        rect.deltaX >= 0
          ? Math.abs(this.rect.left - this.pointerStart.x)
          : Math.abs(this.rect.left - this.pointerStart.x - rect.deltaX);
      const top =
        rect.deltaY >= 0
          ? Math.abs(this.rect.top - this.pointerStart.y)
          : Math.abs(this.rect.top - this.pointerStart.y - rect.deltaY);
      this.selection.style.left = `${left / area.transform.k}px`;
      this.selection.style.top = `${top / area.transform.k}px`;
      this.selection.style.width = `${
        Math.abs(rect.deltaX) / area.transform.k
      }px`;
      this.selection.style.height = `${
        Math.abs(rect.deltaY) / area.transform.k
      }px`;
      this.selection.style.display = this.isDragging ? "block" : "none";
    }
  }

  destroy() {
    this.elem.removeEventListener("pointerdown", this.handlePointerDown);
    this.elem.removeEventListener("pointermove", this.handlePointerMove);
    this.elem.removeEventListener("pointerup", this.handlePointerUp);
  }
}
