import * as ItemEvent from "./ItemEvent";
import * as IPInput from "./IPInputEvent";
import * as PowerSequenceDiagramEvent from "./PowerSequenceDiagramEvent";
import * as PowerPMDGRPEvent from "./PowerPMDGRPEvent";
import * as PowerPMDAPMEvent from "./PowerPMDAPMEvent";

export default {
  ...ItemEvent,
  ...IPInput,
  ...PowerSequenceDiagramEvent,
  ...PowerPMDGRPEvent,
  ...PowerPMDAPMEvent,
};
