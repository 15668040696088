/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import DUTModuleDiagramEvent from '@/model/src/events/DUTModuleDiagramEvent'
import DUTModuleDiagramChecker from '@/model/src/checkers/DUTModuleDiagramChecker'
export default class DUTModuleDiagram extends Item {
    FID_DUTMODULEDIAGRAM_NAME = 0
    FID_DUTMODULEDIAGRAM_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"DUTModuleDiagram"}))
        
        this.setEvent(new DUTModuleDiagramEvent(this))
        this.setChecker(new DUTModuleDiagramChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "DUT Diagram"
        }
    (this.view.diagram = true), (this.view.table = false)
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "DUTModuleDiagram"
    }

    getSchema() {
        return {"name": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "Connection", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "DUTInstance", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_DUTMODULEDIAGRAM_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_DUTMODULEDIAGRAM_NAME
    }
    

    getFidLast() {
        return this.FID_DUTMODULEDIAGRAM_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_DUTMODULEDIAGRAM_NAME:
                return "name"

            default:
                return ""
        }
    }


    newConnection(properties={}, title = "", domain = "") {
        return this.createChild(this, "Connection", properties, title, domain)
    }
    remConnection(obj) {
        return this.removeChild(obj)
    }
    getConnections() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "Connection")
            return res
        })
    }
    newDUTInstance(properties={}, title = "", domain = "") {
        return this.createChild(this, "DUTInstance", properties, title, domain)
    }
    remDUTInstance(obj) {
        return this.removeChild(obj)
    }
    getDUTInstances() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "DUTInstance")
            return res
        })
    }
}