/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
//import ErrorType from '@/model/src/ErrorType'
import ItemChecker from '@/model/src/ItemChecker'

export default class PowerSequenceWriteChecker extends ItemChecker {
    constructor(item) {
        super(item)
        //this.addChecker(item.getFidArg0(), this.checkArg0, ErrorType.NAME)
        //this.addChecker(item.getFidArg1(), this.checkArg1, ErrorType.NAME)
        //this.addChecker(item.getFidArg2(), this.checkArg2, ErrorType.NAME)
        //this.addChecker(item.getFidLabel(), this.checkLabel, ErrorType.NAME)
        //this.addChecker(item.getFidBack(), this.checkBack, ErrorType.NAME)
    }
    checkArg0() {
        // TBD
    }
    checkArg1() {
        // TBD
    }
    checkArg2() {
        // TBD
    }
    checkLabel() {
        // TBD
    }
    checkBack() {
        // TBD
    }
}