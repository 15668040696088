/**
 *  Generated by Modeler
 *  timestamp: 2024-09-03 13:51:33.400997
 */
import ItemEvent from '@/model/src/ItemEvent'

export default class NodeEvent extends ItemEvent {
    constructor(item) {
        super(item)
        // this.addPropertyListener(item.getFidPhantom(), this.lstn_phantom)
        // this.addPropertyListener(item.getFidBuild(), this.lstn_build)
    }
    // lstn_phantom(item, newVal, oldVal) {
    //     console.debug(item, newVal, oldVal)
    // }
    // lstn_build(item, newVal, oldVal) {
    //     console.debug(item, newVal, oldVal)
    // }
}