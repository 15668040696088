import ITDANode from "./ITDANode";
import * as ITDANodeRenderer from "./renderers";
import { ModuleType, NodeType } from "../types";

export default class ITDAMODULE extends ITDANode {
  private moduleType: ModuleType;
  constructor(nodeType: NodeType) {
    super(nodeType.getTitle(), nodeType);
    this.render = ITDANodeRenderer.ITDAMODULE.render;
    this.moduleType = nodeType.getSubType();
    this.resizable = true;
  }

  set width(data: number) {
    super.width = this.adjustNodeToGrid(data);
  }

  get width() {
    const nodeStroke = this._nodeStroke;
    return this.adjustNodeToGrid(super.width) + nodeStroke / 2;
  }

  set height(data: number) {
    super.height = this.adjustNodeToGrid(data);
  }

  get height() {
    const nodeStroke = this._nodeStroke;
    return this.adjustNodeToGrid(super.height) + nodeStroke;
  }

  getModuleType(): ModuleType {
    return this.moduleType;
  }
}
