import {
  Item,
  VariantLevel,
  RefItemElement,
  RefItemElementType,
  Property,
  ItemType,
} from "ITDAModelTypes";
import Utils from "./utils";

export const getLabelRefsElements = (
  item: Item,
  propKey: string,
  prop: Property
): RefItemElement[] => {
  const res: RefItemElement[] = [];
  if (!item.getLabelRefs().length) return [];

  item.getLabelRefs().forEach((labelRef: Item) => {
    res.push({
      icon: "mdi-chevron-double-right",
      variant: VariantLevel.LEVEL0,
      disabled: false,
      type: RefItemElementType.CHIP,
      propKey: propKey,
      label: "",
      multiple: false,
      targets: [
        {
          key: `${item.getParent(ItemType.ClockCMU).getTitle()}:${labelRef
            .getParent()
            .getTitle()}`,
          item: labelRef,
          closable: false,
        },
      ],
      event: () => eventLabelRefs(item, labelRef),
    });
  });
  return res;
};

const eventLabelRefs = async (label: Item, labelRef: Item) => {
  Utils.moveToTarget(label, labelRef);
};
