/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemHandler from "../ItemHandler";
import ItemType from "../ItemType";
import SFRHandler from "../SFRHandler";

export default class PowerPMRINTRSFREvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), () => {});
  }

  setup(item) {
    const promises = [];
    promises.push(item.getEvent().refreshINTRGRP(item, 16));
    promises.push(item.getEvent().refreshINTRSFRATOM(item, 1));

    const pmu = item.getParent(ItemType.PowerPMC);
    pmu.getSFRBlock().setSorted(false);
    pmu.getSFRBlock().setSorted(true);

    return Promise.all(promises);
  }

  async refreshINTRGRP(item, num) {
    let promises = [];
    for (const intrGrp of item.getPowerPMRINTRGRPs()) {
      promises.push(ItemHandler.removeItem(intrGrp));
    }
    await Promise.all(promises);

    const { item: intrGrp, promise } = ItemHandler.createItem(
      item,
      ItemType.PowerPMRINTRGRP,
      {
        name: `${item.getName()}`,
      }, "", item.domain
    );
    await promise;
    const sfrBlock = item.getParent(ItemType.PowerPMC).getSFRBlock();

    promises = [];
    let sfrs = null;
    for (var i = 0; i < num; i++) {
      let { item: intrEventR, promise: promiseR } = ItemHandler.createItem(
        intrGrp,
        ItemType.PowerPMRINTREvent,
        { name: intrGrp.getName(), index: i * 2 },
        `SFR_DATA${i}__RISE`, item.domain
      );
      promises.push(promiseR);

      let { item: intrEventF, promise: promiseF } = ItemHandler.createItem(
        intrGrp,
        ItemType.PowerPMRINTREvent,
        { name: intrGrp.getName(), index: i * 2 + 1 },
        `SFR_DATA${i}__FALL`, item.domain
      );
      promises.push(promiseF);

      if (!sfrs) {
        sfrs = SFRHandler.createSFR(sfrBlock, intrEventR, "EVENT");
      }
      this._createPMUINTREVENTSFRField(
        sfrs,
        intrEventR,
        "EVENT_R",
        intrEventR.getTitle()
      ).forEach((sfrField) => {
        sfrField.setItemRef(intrEventR.getId(), false);
        // ItemHandler.createMapper(sfrField.getParent(), sfrField);
      });
      this._createPMUINTREVENTSFRField(
        sfrs,
        intrEventF,
        "EVENT_F",
        intrEventF.getTitle()
      ).forEach((sfrField) => {
        sfrField.setItemRef(intrEventF.getId(), false);
        // ItemHandler.createMapper(sfrField.getParent(), sfrField);
      });
    }
    await Promise.all(promises);

    promises = [];
    sfrs = null;
    for (const intrEvent of intrGrp.getPowerPMRINTREvents()) {
      let { item: intrGenBit, promise } = ItemHandler.createItem(
        intrGrp,
        ItemType.PowerPMRINTRGenBit,
        { name: intrGrp.getName(), index: intrEvent.getIndex() },
        intrEvent.getTitle(), item.domain
      );
      promises.push(promise);

      if (!sfrs) {
        sfrs = SFRHandler.createSFR(sfrBlock, intrGenBit, "GEN_BIT");
      }
      this._createPMUINTREVENTSFRField(
        sfrs,
        intrGenBit,
        "GEN_BIT",
        intrGenBit.getTitle()
      );
    }
    await Promise.all(promises);
  }

  _createPMUINTREVENTSFRField(sfrs, intrEvent, specType = "", postfix = "") {
    const res = [];
    const spec = intrEvent.getSFRSpec(specType);
    for (let i = 0; i < sfrs.length; i++) {
      const sfr = sfrs[i];
      const sfrSpec = spec.sfrs[i];
      const index = sfr.getSFRFields().length;
      for (let j = 0; j < sfrSpec.fields.length; j++) {
        const sfrField = SFRHandler.createSFRField(sfr, sfrSpec.fields[j]);
        if (postfix) {
          sfrField.setName(postfix, false);
        }
        sfrField.setIndex(index + j, false);
        sfrField.getEvent().updateFieldLabel(sfrField);
        res.push(sfrField);
      }
      sfr.getEvent().sortSFRFields(sfr);
    }
    return res;
  }

  async refreshINTRSFRATOM(item, num) {
    let promises = [];
    item
      .getPowerPMRINTRSFRATOMs()
      .forEach((atom) => promises.push(ItemHandler.removeItem(atom)));
    await Promise.all(promises);

    promises = [];
    for (var i = 0; i < num; i++) {
      const promise = ItemHandler.createItem(
        item,
        ItemType.PowerPMRINTRSFRATOM,
        {
          name: item.getName(),
        }, "", item.domain
      ).promise;
      promises.push(promise);
    }
    await Promise.all(promises);
  }
}
