/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import SDCInstanceEvent from '@/model/src/events/SDCInstanceEvent'
import SDCInstanceChecker from '@/model/src/checkers/SDCInstanceChecker'
export default class SDCInstance extends Node {
    FID_SDCINSTANCE_NAME = 100
    FID_SDCINSTANCE_MODULE = 101
    FID_SDCINSTANCE_PRIMARYPOWERSOURCE = 102
    FID_SDCINSTANCE_LAST = 102

    constructor(obj) {
        super(Object.assign(obj,{itemType:"SDCInstance"}))
        
        this.setEvent(new SDCInstanceEvent(this))
        this.setChecker(new SDCInstanceChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "SDCInstance"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "SDCInstance"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "module": {"type": "item", "label": "Module", "desc": "Module", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "primaryPowerSource": {"type": "item", "label": "Voltage Domain", "desc": "Voltage Domain", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "module": {"type": "item", "label": "Module", "desc": "Module", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "primaryPowerSource": {"type": "item", "label": "Voltage Domain", "desc": "Voltage Domain", "multiple": false, "category": "IP", "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "SDCPhantomGroup", "auto": false, "searchable": false, "title": "", "headers": [], "category": "IP"}, {"name": "SDCClockGroup", "auto": false, "searchable": false, "title": "Clock Group List", "headers": [{"title": "Name", "align": "start", "filterable": true, "value": "name", "sortable": true}, {"title": "Color", "align": "start", "filterable": true, "value": "color", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SDCINSTANCE_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_SDCINSTANCE_NAME
    }
    

    setModule(module, event=true) {
        if ((module !== null) && (module !== undefined)) {
            if (!(module instanceof Item) && (module.constructor !== String)) {
                throw `module(${module}) must be Item or String type`
            }
        }
        const newVal = (module === null || module === undefined) ? undefined : (module.constructor === String) ? module : module.getId()
        const oldVal = this.properties.module
        this.properties.module = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SDCINSTANCE_MODULE, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getModule(pure = false) {
        return pure ? this.properties.module : this.getRefItem(this.properties.module)
        
    }
    getFidModule() {
        return this.FID_SDCINSTANCE_MODULE
    }
    

    setPrimaryPowerSource(primaryPowerSource, event=true) {
        if ((primaryPowerSource !== null) && (primaryPowerSource !== undefined)) {
            if (!(primaryPowerSource instanceof Item) && (primaryPowerSource.constructor !== String)) {
                throw `primaryPowerSource(${primaryPowerSource}) must be Item or String type`
            }
        }
        const newVal = (primaryPowerSource === null || primaryPowerSource === undefined) ? undefined : (primaryPowerSource.constructor === String) ? primaryPowerSource : primaryPowerSource.getId()
        const oldVal = this.properties.primaryPowerSource
        this.properties.primaryPowerSource = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SDCINSTANCE_PRIMARYPOWERSOURCE, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getPrimaryPowerSource(pure = false) {
        return pure ? this.properties.primaryPowerSource : this.getRefItem(this.properties.primaryPowerSource)
        
    }
    getFidPrimaryPowerSource() {
        return this.FID_SDCINSTANCE_PRIMARYPOWERSOURCE
    }
    

    getFidLast() {
        return this.FID_SDCINSTANCE_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_SDCINSTANCE_NAME:
                return "name"

            case this.FID_SDCINSTANCE_MODULE:
                return "module"

            case this.FID_SDCINSTANCE_PRIMARYPOWERSOURCE:
                return "primaryPowerSource"

            default:
                return ""
        }
    }


    newSDCPhantomGroup(properties={}, title = "", domain = "") {
        return this.createChild(this, "SDCPhantomGroup", properties, title, domain)
    }
    remSDCPhantomGroup(obj) {
        return this.removeChild(obj)
    }
    getSDCPhantomGroups() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "SDCPhantomGroup")
            return res
        })
    }
    newSDCClockGroup(properties={}, title = "", domain = "") {
        return this.createChild(this, "SDCClockGroup", properties, title, domain)
    }
    remSDCClockGroup(obj) {
        return this.removeChild(obj)
    }
    getSDCClockGroups() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "SDCClockGroup")
            return res
        })
    }
}