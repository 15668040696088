import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodePower from "./ITDANodePower";

export default class ITDAPMDGRP extends ITDANodePower {
  constructor(name = "") {
    super(name, NodeType.ITDAPMDGRP);
    this.render = ITDANodeRenderer.ITDAPMDGRP.render;
  }

  set height(data: number) {
    super.height = data;
  }

  get height() {
    const nodeStroke = this._nodeStroke;
    const adjustHeight = this.adjustNodeToGrid(super.height);
    const ctrl = this.getCtrlPort();

    // ctrl이 undefined일 경우 기본값을 사용하거나 에러 처리
    if (!ctrl || !ctrl.getInputPorts || !ctrl.getOutputPorts) {
      return adjustHeight * 2 + nodeStroke; // 기본값 설정 또는 다른 적절한 처리
    }

    const maxPorts = Math.max(
      ctrl.getInputPorts().length,
      ctrl.getOutputPorts().length
    );
    const adjustedMaxPorts =
      maxPorts === 1 ? 80 : maxPorts * this.adjustNodeToGrid(super.height);

    return this.isDocked()
      ? adjustHeight * 2 + nodeStroke
      : adjustedMaxPorts + nodeStroke;
  }
}
