import ItemEvent from "@/model/src/ItemEvent";

export default class Output extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
    this.addPropertyListener(item.getFidKey(), () => {});
  }
  lstn_name(item, newVal, oldVal) {
    console.debug(item, newVal, oldVal);
  }
}
