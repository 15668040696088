<template>
  <div class="resizer-wrapper" :style="getResizerWrapperStyle">
    <!-- <div v-if="props.selected" class="resizer left" data-direction="left" /> -->
    <div v-if="props.selected" class="resizer right" data-direction="right" />
    <!-- <div v-if="props.selected" class="resizer top" data-direction="top" /> -->
    <div v-if="props.selected" class="resizer bottom" data-direction="bottom" />
    <!-- <div v-if="props.selected" class="resizer nw" data-direction="nw" />
    <div v-if="props.selected" class="resizer ne" data-direction="ne" />
    <div v-if="props.selected" class="resizer sw" data-direction="sw" /> -->
    <div v-if="props.selected" class="resizer se" data-direction="se" />
  </div>
</template>
<script setup lang="ts">
import { computed, defineProps, onMounted } from "vue";

import ITDANodeResizer from "./utils/ITDANodeResizer";

const props = defineProps<{
  data: any;
  selected: boolean | undefined;
}>();

const getResizerWrapperStyle = computed(() => {
  return {
    width: props.data.width + "px",
    height: props.data.height + "px",
  };
});

onMounted(() => {
  if (props.selected) {
    const nodeResizer = new ITDANodeResizer(props.data);

    const resizers = document.querySelectorAll(".resizer");
    for (let i = 0; i < resizers.length; i++) {
      const resizer = resizers[i];
      resizer.addEventListener("pointerdown", (e) => {
        nodeResizer.handleClick(e as PointerEvent);
      });
    }
  }
});
</script>
<style lang="sass" scoped>
.resizer-wrapper
    position: absolute

    .resizer
        position: absolute
        z-index: 99
        &.left
            width: 3px
            height: 100%
            cursor: ew-resize
            left: -1px
        &.right
            width: 3px
            height: 100%
            right: -1px
            cursor: ew-resize
        &.top
            width: 100%
            height: 3px
            top: -1px
            cursor: ns-resize
        &.bottom
            width: 100%
            bottom: -1px
            height: 3px
            cursor: ns-resize
        &.nw
            left: -3px
            top: -3px
            width: 6px
            height: 6px
            cursor: nw-resize
            background: white
            border: 1px solid black
        &.ne
            right: -3px
            top: -3px
            // right: -4%
            // top: -6%
            // width: 10%
            // height: 18%
            width: 6px
            height: 6px
            cursor: ne-resize
            background: white
            border: 1px solid black
            // transition: all 2s
        &.sw
            left: -3px
            bottom: -3px
            width: 6px
            height: 6px
            cursor: sw-resize
            background: white
            border: 1px solid black
        &.se
            right: -3px
            bottom: -3px
            width: 6px
            height: 6px
            cursor: se-resize
            background: white
            border: 1px solid black
</style>
