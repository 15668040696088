import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const grid = 12;
    const gridHeight = height / grid;
    const gridWidth = width / grid;

    const path = `M ${STROKE_WIDTH}, ${gridHeight * 3}
    C ${STROKE_WIDTH},${gridHeight * -0.1}, ${gridWidth * 1.5}, ${
      gridHeight * -0.1
    } ${gridWidth * 4}, ${gridHeight * 1.1}
    L ${gridWidth * 10}, ${gridHeight * 4}
    C ${gridWidth * 12.2},${gridHeight * 5}, ${gridWidth * 12.2}, ${
      gridHeight * 7
    } ${gridWidth * 10}, ${gridHeight * 8}
    L ${gridWidth * 4}, ${gridHeight * 10.9}
    C ${gridWidth * 1.5}, ${gridHeight * 12.1} ${STROKE_WIDTH},${
      gridHeight * 12.1
    } 
     ${STROKE_WIDTH}, ${gridHeight * 9}
    Z
    `;
    svg
      .append("path")
      .attr("d", path)
      .attr("fill", color)
      .attr("stroke", "black")
      .attr("stroke-width", 2);
  },
};
