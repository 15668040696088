import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);
    const centerX = width / 2;
    const centerY = height / 2;
    const innerStroke = 2;
    const radius = width / 8;

    // Draw the document icon
    g.append("rect")
      .attr("x", (centerX / 4) * 2)
      .attr("y", (centerY / 4) * 2.5)
      .attr("width", (centerX / 4) * 4)
      .attr("height", (centerY / 4) * 4.6)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke * 1.4)
      .attr("rx", 2)
      .attr("ry", 2)
      .attr("fill", color);

    g.append("line")
      .attr("x1", (centerX / 4) * 2.5)
      .attr("y1", (centerY / 4) * 3.3)
      .attr("x2", (centerX / 4) * 5.5)
      .attr("y2", (centerY / 4) * 3.3)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke * 1.4);

    g.append("line")
      .attr("x1", (centerX / 4) * 2.5)
      .attr("y1", (centerY / 4) * 4.3)
      .attr("x2", (centerX / 4) * 5.5)
      .attr("y2", (centerY / 4) * 4.3)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke * 1.4);

    g.append("line")
      .attr("x1", (centerX / 4) * 2.5)
      .attr("y1", (centerY / 4) * 5.3)
      .attr("x2", (centerX / 4) * 5.5)
      .attr("y2", (centerY / 4) * 5.3)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke * 1.4);

    g.append("line")
      .attr("x1", (centerX / 4) * 2.5)
      .attr("y1", (centerY / 4) * 6.3)
      .attr("x2", (centerX / 4) * 4.5)
      .attr("y2", (centerY / 4) * 6.3)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke * 1.4);
    // Draw 'i' icon inside the circle
    g.append("circle")
      .attr("cx", (centerX / 4) * 5.6)
      .attr("cy", (centerY / 4) * 6.3)
      .attr("r", radius * 1.2)
      .attr("fill", "black")
      .attr("stroke", "white")
      .attr("stroke-width", 2.4);

    g.append("text")
      .attr("x", (centerX / 4) * 5.6)
      .attr("y", (centerY / 4) * 6.75)
      .attr("text-anchor", "middle")
      .attr("font-size", "14px")
      .attr("font-weight", "bold")
      .attr("fill", "white")
      .text("i");

    // Draw the INFORM text on the top
    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 1.5)
      .attr("text-anchor", "middle")
      .attr("font-size", "10px")
      .attr("fill", "black")
      .text("INFORM");
  },
};
