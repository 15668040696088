/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x2000",
    "size": "0x400",
    "alignSize": "0x8",
    "sfrs": [
        {
            "name": "CTRL_QCH",
            "alignOffset": "0x0",
            "description": "Clock Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_ENABLE_QCH",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Q-Channel Handshake Enable for {{name}}\n0 = Q-Channel Handshake is Disabled\n1 = Q-Channel Handshake is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_QCH"
                },
                {
                    "name": "SFR_EXPIRE_VALUE",
                    "index": 8,
                    "size": 8,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "QACTIVE Hysteresis counter Expire value for {{name}}\nQACTIVE Hysteresis counter runs during (SFR_EXPIRE_VALUE) cycles of CMU Reference clock\nQ-channel Handshake is initiated after QACTIVE keeps 0 until Hysteresis counter Expire",
                    "initValue": "0x3F",
                    "nc": "SFR_EXPIRE_VALUE"
                },
                {
                    "name": "SFR_DBG_INFO",
                    "index": 24,
                    "size": 8,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Debug Information",
                    "initValue": "0x0",
                    "nc": "SFR_DBG_INFO"
                }
            ]
        }
    ]
}