import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;

    g.append("path")
      .attr(
        "d",
        "M16 20.48C18.4 20.48 20.4333 19.48 22.1 17.48C23.7667 15.48 24.6 13.04 24.6 10.16V2.6H7.4V10.16C7.4 13.04 8.23333 15.48 9.9 17.48C11.5667 19.48 13.6 20.48 16 20.48ZM0 46V43.4H4.4V35.78C4.4 32.94 5.06667 30.36 6.4 28.04C7.73333 25.72 9.5 24.04 11.7 23C9.5 21.92 7.73333 20.22 6.4 17.9C5.06667 15.58 4.4 13 4.4 10.16V2.6H0V0H32V2.6H27.6V10.16C27.6 13 26.9333 15.58 25.6 17.9C24.2667 20.22 22.5 21.92 20.3 23C22.5 24.04 24.2667 25.72 25.6 28.04C26.9333 30.36 27.6 32.94 27.6 35.78V43.4H32V46H0Z"
      )
      .attr("fill", "black")
      .attr(
        "transform",
        `translate(${(centerX / 4) * 2.4},${(centerY / 4) * 2.5}) `
      );

    g.append("path")
      .attr("d", "M19 26C20 26.5 20 26.4 22 30C24 33.6 24 35.5 24 36")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("stroke", "white")
      .attr(
        "transform",
        `translate(${(centerX / 4) * 2.4},${(centerY / 4) * 2.5})`
      );

    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 1.5)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("TIMEOUT");
  },
};
