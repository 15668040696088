import * as configuration from "./configuration";
import * as garbage from "./garbage";
import * as io from "./io";
import MM from "./mm";
import ITEM from "./item";
import * as title from "./title";

export default {
  ...configuration,
  ...garbage,
  ...io,
  ...MM,
  ...ITEM,
  ...title,
};
