<template>
  <div class="controls" :style="getControlStyle">
    <!-- TOP -->
    <div class="control-top" :style="getTopStyle">
      <Ref
        v-if="props.data.ext"
        class="control"
        :emit="props.emit"
        :data="{ type: 'control', payload: props.data.ext }"
        :data-testid="'control-ext'" />
      <ITDANodeControlPort
        :ports="props.data.port.getTopPorts()"
        :position="ITDAPortPosition.TOP"
        :emit="props.emit"
        :nodeId="props.nodeId"
        :seed="props.seed"
        :height="props.height" />
    </div>

    <!-- RIGHT -->
    <div class="control-right" :style="getRightStyle">
      <ITDANodeControlPort
        :ports="props.data.port.getRightPorts()"
        :position="ITDAPortPosition.RIGHT"
        :emit="props.emit"
        :nodeId="props.nodeId"
        :seed="props.seed"
        :height="props.height"
        :resizeValue="resizeValue" />
    </div>

    <!-- BOTTOM -->
    <div class="control-bottom" :style="getBottomStyle">
      <ITDANodeControlPort
        :ports="props.data.port.getBottomPorts()"
        :position="ITDAPortPosition.BOTTOM"
        :emit="props.emit"
        :nodeId="props.nodeId"
        :seed="props.seed"
        :height="props.height" />
      <Ref
        v-if="props.data.title"
        class="control"
        :emit="props.emit"
        :data="{ type: 'control', payload: props.data.title }"
        :data-testid="'control-title'" />
      <Ref
        v-if="props.data.freq"
        class="control"
        :style="getFreqStyle"
        :emit="props.emit"
        :data="{ type: 'control', payload: props.data.freq }"
        :data-testid="'control-frequency'" />
    </div>

    <!-- LEFT -->
    <div class="control-left" :style="getLeftStyle">
      <ITDANodeControlPort
        :ports="props.data.port.getLeftPorts()"
        :position="ITDAPortPosition.LEFT"
        :emit="props.emit"
        :nodeId="props.nodeId"
        :seed="props.seed"
        :height="props.height" />
    </div>

    <!-- SIGNAL -->
    <Ref
      v-if="props.data.signal"
      class="control-signal"
      :emit="props.emit"
      :data="{ type: 'control', payload: props.data.signal }"
      :data-testid="'control-signal'" />

    <!-- Description -->
    <Ref
      v-if="props.data.desc"
      class="control-desc"
      :data="{ type: 'control', payload: props.data.desc }"
      :width="props.width"
      :emit="props.emit"
      :nodeId="props.nodeId"
      :data-testid="'control-desc'" />

    <!-- SDC -->
    <Ref
      v-if="props.data?.sdc?.showSDC"
      class="control"
      :emit="props.emit"
      :data="{ type: 'control', payload: getSDC() }"
      :data-testid="'control-sdc'" />
  </div>
</template>

<script setup lang="ts">
import { Ref } from "rete-vue-plugin";
import { computed, defineProps, onUpdated, ref } from "vue";
import { ITDAPortPosition } from "@/canvas/common";
import ITDANodeControlPort from "./ITDANodeControlPort.vue";
const props = defineProps<{
  data: any;
  emit: (event: string, ...args: any[]) => void;
  nodeId: string;
  seed: number;
  width: number;
  height: number;
  resizeValue: number;
}>();

const topPortsLength = ref(props.data.port.getTopPorts().length);
const btmPortsLength = ref(props.data.port.getBottomPorts().length);
const resizeValue = ref<number>(props.resizeValue);
const getControlStyle = computed(() => {
  return {
    width: `${props.width}px`,
    height: `${props.height}px`,
  };
});

const getTopStyle = computed(() => {
  const res = {
    position: "absolute",
    width: `${props.width}px`,
    bottom: `${props.height}px`,
  };
  return res;
});

const getRightStyle = computed(() => {
  return {
    position: "absolute",
    height: `${props.height}px`,
    left: `${props.width}px`,
  };
});

const getBottomStyle = computed(() => {
  const res = {
    position: "absolute",
    width: `${props.width}px`,
    top: `${props.height}px`,
  };
  if (btmPortsLength.value === 0) {
    // 추후 수정
    // if (props.data.title.node.inheritedClassName === "ITDANodeClock") {
    //   Object.assign(res, { top: `${props.height - 4.5}px` });
    // } else if (props.data.title.node.inheritedClassName === "ITDANodePower") {
    //   Object.assign(res, { top: `${props.height - 5.5}px` });
    // } else if (props.data.title.node.type.key === "ITDAUPFLEVELSHIFTER") {
    //   Object.assign(res, { top: `${props.height - 7}px` });
    // } else {
    //   Object.assign(res, { top: `${props.height - 5.5}px` });
    // }
  }
  return res;
});

const getFreqStyle = computed(() => {
  const res = {
    position: "absolute",
    left: `${-props.width * 1.5}px`,
    width: `${props.width * 4}px`,
  };
  return res;
});

const getLeftStyle = computed(() => {
  const res = {
    position: "absolute",
    height: `${props.height}px`,
    right: `${props.width}px`,
  };
  if (props.data.title.node.type.key === "ITDAINSTANCEIP") {
    Object.assign(res, { top: "-0.3px" });
  }
  return res;
});

const getSDC = () => {
  const SDC = props.data.sdc;
  SDC.emit = props.emit;
  return SDC;
};

onUpdated(() => {
  resizeValue.value = props.resizeValue;
  topPortsLength.value = props.data.port.getTopPorts().length;
  btmPortsLength.value = props.data.port.getBottomPorts().length;
});
</script>

<style lang="sass" scoped>
@use "sass:math"
$socket-size: 16px
.controls
  position: absolute
  top: 0
  left: 0
  -webkit-user-select: none
  -moz-user-select: none
  -ms-user-select: none
  user-select: none

  .control-right
  .control-left
    height: 100%
</style>
