/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemHandler from "../ItemHandler";

export default class PowerRESETEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), () => {});
    this.addPropertyListener(
      item.getFidIsResetnCLDBoot(),
      this.lstn_is_resetn_cldboot
    );
    // this.addPropertyListener(item.getFidMaxNum(), this.lstn_maxNum)
  }

  lstn_is_resetn_cldboot(item) {
    ItemHandler.refreshSFRInfo(item);
  }
}
