import { Item } from "../types";
import SFRHandler from "../src/SFRHandler";
import { DBSchemaSFR, DBSchemaSFRMetadata } from "./types";

type SFRHandlerMap = Map<string, SFRHandler>;

export default class SFRRepository {
  private static instance: SFRRepository;
  private map: WeakMap<Item, SFRHandlerMap>;

  private constructor() {
    this.map = new WeakMap<Item, SFRHandlerMap>();
  }

  public static getInstance(): SFRRepository {
    if (!SFRRepository.instance) {
      SFRRepository.instance = new SFRRepository();
    }
    return SFRRepository.instance;
  }

  public clear(sfrBlocks?: Item[]): void {
    if (sfrBlocks) {
      sfrBlocks.forEach((sfrBlock: Item) => this.map.delete(sfrBlock));
    } else {
      this.map = new WeakMap<Item, SFRHandlerMap>();
    }
  }

  public addSFRHandlerMap(sfrBlock: Item, sfrHandlerMap: SFRHandlerMap): void {
    this.map.set(sfrBlock, sfrHandlerMap);
  }

  // public addPLLSFR(spec): void {
  //   this.pllSpec[spec.name] = spec;
  //   for (const sfr of spec.sfrs) {
  //     for (const field of sfr.fields) {
  //       field.name = `SFR_${field.name}`;
  //     }
  //   }
  // }
  public getSFRHandlerMap(sfrBlock: Item): SFRHandlerMap | undefined {
    return this.map.get(sfrBlock);
  }

  public setup(
    sfrHandlerMap: SFRHandlerMap,
    obj: Record<string, DBSchemaSFRMetadata>
  ) {
    for (const [name, meta] of Object.entries(obj)) {
      if (!meta.offsetToItemID) {
        continue;
      }
      for (const [offset, id] of Object.entries(meta.offsetToItemID)) {
        sfrHandlerMap.get(name)?.setOffsetMap(Number(offset), id);
      }
    }
  }

  public serialize(sfrBlocks: Item[]): DBSchemaSFR {
    const res: DBSchemaSFR = {};
    sfrBlocks.forEach((sfrBlock: Item) => {
      const sfrHandlerMap = this.map.get(sfrBlock);
      if (sfrHandlerMap) {
        const obj: { [key in string]: DBSchemaSFRMetadata } = {};
        for (const [key, sfrHandler] of sfrHandlerMap) {
          obj[key] = {
            startOffset: sfrHandler.startOffset,
            alignSize: sfrHandler.alignSize,
            size: sfrHandler.size,
            offsetToItemID: sfrHandler.offsetToItemID,
          };
        }
        res[sfrBlock.getId()] = obj;
      }
    });
    return res;
  }
}
