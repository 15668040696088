import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;

    g.append("rect")
      .attr("x", (centerX / 4) * 2)
      .attr("y", (centerY / 4) * 3.5)
      .attr("width", (centerX / 4) * 4)
      .attr("height", (centerY / 4) * 3.5)
      .attr("rx", 3)
      .attr("ry", 3)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH * 1.5)
      .attr("fill", color);

    g.append("path")
      .attr(
        "d",
        `M${(centerX / 4) * 3} ${(centerY / 4) * 3} 
             A${centerX / 4},${(centerY / 4) * 1} 0 0,1 ${(centerX / 4) * 5} ${
          (centerY / 4) * 3
        }`
      )
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH * 1.5)
      .attr("fill", color);

    g.append("line")
      .attr("x1", (centerX / 4) * 3)
      .attr("y1", (centerY / 4) * 3)
      .attr("x2", (centerX / 4) * 3)
      .attr("y2", (centerY / 4) * 3.5)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH * 1.5);

    g.append("line")
      .attr("x1", (centerX / 4) * 5)
      .attr("y1", (centerY / 4) * 3)
      .attr("x2", (centerX / 4) * 5)
      .attr("y2", (centerY / 4) * 3.5)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH * 1.5);

    // Keyhole
    g.append("circle")
      .attr("cx", centerX)
      .attr("cy", centerY + centerY / 4)
      .attr("r", centerX / 12)
      .attr("fill", "black");

    g.append("rect")
      .attr("x", centerX - 2)
      .attr("y", centerY + centerY / 4)
      .attr("width", 4)
      .attr("height", centerY / 6)
      .attr("fill", "black");

    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 1.5)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("OTP");
  },
};
