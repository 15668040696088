import * as d3 from "d3";

export default {
  render: (
    elem: HTMLElement,
    width: number,
    height: number,
    color: string,
    options: any
  ) => {
    const { title, ports, nodeId } = options;
    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .style("overflow", "visible");
    const grid = 12;
    const gridHeight = height / grid;
    const gridWidth = width / grid;

    const points = [
      { x: 0, y: 0 },
      { x: gridWidth * 2, y: gridHeight * 6 },
      { x: 0, y: gridHeight * 12 },
      { x: gridWidth * 10, y: gridHeight * 12 },
      { x: gridWidth * 12, y: gridHeight * 6 },
      { x: gridWidth * 10, y: 0 },
    ];

    const baseColor = color;
    const hoverColor = d3.hsl(baseColor);
    hoverColor.l = Math.min(hoverColor.l + 0.05, 1);

    const component = svg
      .append("polygon")
      .attr("points", points.map((p) => `${p.x},${p.y}`).join(" "))
      .attr("fill", baseColor)
      .attr("transform", "translate(0,-0.5)");

    let xPosition = width / 2;

    const textElement = svg
      .append("text")
      .attr("x", xPosition)
      .attr("y", height / 2)
      .attr("class", `text-${nodeId}`)
      .attr("dominant-baseline", "middle");

    textElement.text(title ? title : "");
    const textNode = textElement.node();
    let textLength = 0;

    if (textNode) {
      textLength = textNode.getComputedTextLength();
    }

    if (ports.node.inputs && ports.node.inputs.SEQREF_INPUT) {
      xPosition = width * (1 / 4) + textLength / 2;
    }

    if (ports.node.outputs && ports.node.outputs.SEQREF_OUTPUT) {
      xPosition = width * (3 / 4) - textLength / 2;
    }

    textElement.attr("x", xPosition).attr("text-anchor", "middle");

    component
      .on("mouseover", function () {
        d3.select(this).attr("fill", hoverColor.toString());
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", baseColor);
      });
  },
};
