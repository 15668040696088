<template>
  <svg
    ref="svgElement"
    class="itda-connection"
    data-testid="connection"
    @pointerdown.stop="
      props.data.handlePointerDown(
        $event,
        svgElement,
        { value: dragging },
        { value: draggingSegmentId }
      )
    "
    @click="handleRemoveConn"
    @dblclick="handlePathClick">
    <path
      :d="props.path"
      :data-id="props.data.id"
      :style="{
        ...getConnectionPathStyle,
        stroke: clicked ? 'rgb(255, 0, 0)' : connPathStyle().stroke,
      }"
      class="connection-path"></path>
    <path
      v-for="segment in getSegments()"
      :key="segment.id"
      :d="segment.d"
      :data-id="props.data.id"
      :data-segment-id="segment.id"
      :data-segment-index="segment.index"
      :style="getConnectionSegmentPathStyle"
      :class="[
        'connection-path-segment',
        { dragging: dragging && draggingSegmentId === segment.id },
      ]"
      @mouseenter="
        reroutePlugin.handleConnectionMouseEnter(props.data.id, segment.index)
      "
      @mouseleave="
        reroutePlugin.handleConnectionMouseLeave(props.data.id, segment.index)
      "></path>
    <path
      v-if="
        props.data instanceof ITDACommon.ITDAConnection &&
        drawArrowPath().socketType === 'SEQ'
      "
      :data-id="props.data.id"
      :d="drawArrowPath().arrowPath"
      :style="drawArrowPath().arrowPathStyle"
      :transform="getArrowTransform()"
      class="connection-arrow"></path>
  </svg>
</template>
<script setup lang="ts">
import {
  defineProps,
  onMounted,
  ref,
  watch,
  onUpdated,
  computed,
  onUnmounted,
} from "vue";
import { ITDACommon } from "@/canvas/plugins/types";
import Canvas from "@/canvas";

const props = defineProps<{
  data: ITDACommon.Schemes["Connection"];
  start: { x: number; y: number };
  end: { x: number; y: number };
  path: string;
}>();

const getSegments = () => {
  return props.data instanceof ITDACommon.ITDAConnection
    ? props.data.createPathSegments(props.path)
    : [];
};
const svgElement = ref<SVGSVGElement | null>(null);
const dragging = ref<boolean>(false);
const draggingSegmentId = ref<string | null>(null);
const isSegment = ref<boolean>(false);
const reroutePlugin = Canvas.getConnectionReRoutePlugin().getInstance();
const editor = Canvas.getEditor(Canvas.getCurrentID()).getInstance();
const clicked = ref<boolean>(false);
const clickedConnectionId = ref<string | null>(null);
const singleClickTimeout = ref<number | null>(null);

const drawArrowPath = () => {
  let socketType = null;
  let socketColor: string | null = null;
  let arrowPath = "";

  const source = editor.getNode(props.data.source);
  if (source && source.outputs && source.outputs[props.data.sourceOutput]) {
    const outputSocket = source.outputs[props.data.sourceOutput]
      ?.socket as ITDACommon.ITDASocket;
    if (outputSocket) {
      socketType = outputSocket.getType().getKey();
      socketColor = outputSocket.getType().getColor();
    }
  }

  if (socketType === "SEQ") {
    arrowPath = "M0,-7 10,0 0,7 0,-5";
  }
  return {
    socketType,
    arrowPath,
    arrowPathStyle: {
      fill: socketColor || "black",
      strokeWidth: 0,
    },
  };
};

const getArrowTransform = (): string => {
  const path = svgElement.value?.querySelector(
    ".connection-path"
  ) as SVGPathElement | null;
  if (!path) return "";
  if (props.data instanceof ITDACommon.ITDAConnection) {
    return props.data.getTransformAlong(path, -16, 1, true);
  } else {
    return "";
  }
};

const updateArrowPosition = () => {
  const path = svgElement.value?.querySelector(
    ".connection-path"
  ) as SVGPathElement | null;
  if (!path || !(props.data instanceof ITDACommon.ITDAConnection)) return;

  const transform = props.data.getTransformAlong(path, -16, 1, true);

  const arrowElement = svgElement.value?.querySelector(
    ".connection-arrow"
  ) as SVGElement | null;
  if (arrowElement) {
    arrowElement.setAttribute("transform", transform);
  }
};

const getConnectionPathStyle = computed(() => {
  return Object.assign(
    {
      display: isSegment.value ? "none" : "block",
    },
    connPathStyle()
  );
});

const getConnectionSegmentPathStyle = computed(() => {
  return Object.assign(
    {
      display: isSegment.value ? "block" : "none",
    },
    connPathStyle()
  );
});

const connPathStyle = () => {
  let socket;
  const source = editor.getNode(props.data.source);
  const target = editor.getNode(props.data.target);
  if (source) {
    const output = source.outputs[props.data.sourceOutput];
    socket = output?.socket as ITDACommon.ITDASocket;
  }
  if (target) {
    const input = target.inputs[props.data.targetInput];
    socket = input?.socket as ITDACommon.ITDASocket;
  }
  return {
    stroke: socket ? socket.getType().getColor() : "gray",
    strokeDasharray: socket ? socket.getType().getDashArray() : 0,
  };
};

const handleRemoveConn = () => {
  if (!singleClickTimeout.value) {
    singleClickTimeout.value = window.setTimeout(() => {
      clicked.value = !clicked.value;
      clickedConnectionId.value = clicked.value ? props.data.id : null;
      singleClickTimeout.value = null;
    }, 170);
  }
};

const handlePathClick = () => {
  if (singleClickTimeout.value) {
    clearTimeout(singleClickTimeout.value);
    singleClickTimeout.value = null;
  }
  isSegment.value = !isSegment.value;
};

const handleClickEvent = (event: KeyboardEvent) => {
  props.data.handleClickEvent(event, clicked, clickedConnectionId, isSegment);
};

onMounted(() => {
  updateArrowPosition();
  window.addEventListener("keydown", handleClickEvent);
});

onUnmounted(() => {
  window.removeEventListener("keydown", handleClickEvent);
});

watch(
  () => isSegment.value,
  (newValue) => {
    reroutePlugin.handlePinVisible(props.data.id, newValue);
  }
);

onUpdated(() => {
  updateArrowPosition();
  if (isSegment.value) {
    const containerElement = document.querySelector(".pin-container");
    if (containerElement) {
      reroutePlugin.adjustMidPinsInConnection(
        props.data.id,
        { element: containerElement as HTMLElement },
        isSegment.value
      );
    }
  }
});
</script>
<style lang="sass" scoped>
svg
  overflow: visible !important
  position: absolute
  pointer-events: none
  width: 9999px
  height: 9999px

path
  fill: none
  stroke-width: 3px
  stroke-linecap: round
  stroke-linejoin: round
  stroke: gray
  pointer-events: auto
  justify-content: fix-end
  z-index: -1

.connection-path-segment
  &:hover
    stroke: rgb(255, 217, 44) !important
  &.dragging
    stroke: rgb(255, 217, 44) !important
</style>
