<template>
  <v-container>
    <v-card hover class="mx-auto" min-width="328px" elevation="0">
      <v-card-title class="text-left">
        <h4>Copy DB</h4>
      </v-card-title>
      <v-row class="ma-2">
        <v-col cols="12" md="6">
          Design Name
          <v-autocomplete
            v-model="targetDesignDB"
            placeholder="Design Name"
            variant="outlined"
            chips
            closable-chips
            color="#1e41cd"
            :hide-details="true"
            :items="props.designTargets"
            @update:modelValue="openCopyDialog('design')">
          </v-autocomplete>
        </v-col>
        <v-col cols="12" md="6">
          Config Name
          <v-autocomplete
            v-model="targetConfigDB"
            placeholder="Config Name"
            variant="outlined"
            chips
            closable-chips
            color="#1e41cd"
            :hide-details="true"
            :items="props.configTargets"
            @update:modelValue="openCopyDialog('config')"></v-autocomplete>
        </v-col>
      </v-row>
    </v-card>

    <v-dialog v-model="isCopyDialogOpen" max-width="600">
      <v-card class="confirm">
        <v-card-title class="headline">
          New {{ copyDialogType === "design" ? "Design" : "Config" }} Name
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="copyName"
            :placeholder="
              copyDialogType === 'design' ? 'Design Name' : 'Config Name'
            "
            dense
            outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="confirm-button" @click="copyDB()">Copy</v-btn>
          <v-btn class="cancel-button" @click="closeCopyDialog">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup lang="ts">
import { ref, defineProps, defineEmits } from "vue";
import API from "@/api";

const emit = defineEmits(["targetUpdated"]);
const props = defineProps<{
  designTargets: string[];
  configTargets: string[];
}>();

const targetDesignDB = ref<string | undefined>(undefined);
const targetConfigDB = ref<string | undefined>(undefined);
const copyName = ref("");
const isCopyDialogOpen = ref(false);
const copyDialogType = ref<"design" | "config">("design");

const openCopyDialog = (type: "design" | "config") => {
  if (type === "design" && !targetDesignDB.value) return;
  if (type === "config" && !targetConfigDB.value) return;

  copyDialogType.value = type;
  copyName.value = "";
  isCopyDialogOpen.value = true;
};

const closeCopyDialog = () => {
  isCopyDialogOpen.value = false;
};

const copyDB = async () => {
  const target =
    copyDialogType.value === "design"
      ? targetDesignDB.value
      : targetConfigDB.value;
  if (!copyName.value || !target) return;

  try {
    await API.copy(copyName.value, target, copyDialogType.value);
    emit("targetUpdated");
    closeCopyDialog();
  } catch (err) {
    console.error("Error copying DB", err);
  }
};
</script>

<style lang="css" scoped>
.f-12 {
  font-size: 12px;
}
.text-left {
  margin-left: 3px;
}
.confirm {
  border-radius: 10px !important;
}
.confirm-button {
  background: #1e41cd;
  color: white;
}
.cancel-button {
  background: white;
  color: #1e41cd;
  border: 1px solid #1e41cd;
}
</style>
