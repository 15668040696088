//
//  Generated by Modeler
//  timestamp: 2024-12-03 07:51:18.582084
//
import ItemType from "@/model/src/ItemType";
import Phantom from "@/model/gen/model/Phantom";
import SFRBlock from "@/model/gen/model/SFRBlock";
import LabelRef from "@/model/gen/model/LabelRef";
import SFR from "@/model/gen/model/SFR";
import ConfigurationFolder from "@/model/gen/model/ConfigurationFolder";
import Connection from "@/model/gen/model/Connection";
import Project from "@/model/gen/model/Project";
import SFRField from "@/model/gen/model/SFRField";
import Configuration from "@/model/gen/model/Configuration";
import Node from "@/model/gen/model/Node";
import NodeMetaView from "@/model/gen/model/NodeMetaView";
import ConfigurationElement from "@/model/gen/model/ConfigurationElement";
import Input from "@/model/gen/model/Input";
import Label from "@/model/gen/model/Label";
import Output from "@/model/gen/model/Output";
import ClockQChannelManagerEXT from "@/model/gen/model/ClockQChannelManagerEXT";
import ClockMultiplexer from "@/model/gen/model/ClockMultiplexer";
import ClockGateEXT from "@/model/gen/model/ClockGateEXT";
import ClockPLLCtrl from "@/model/gen/model/ClockPLLCtrl";
import ClockExternalCLKSource from "@/model/gen/model/ClockExternalCLKSource";
import ClockQChannelInfo from "@/model/gen/model/ClockQChannelInfo";
import ClockNMDividerEXT from "@/model/gen/model/ClockNMDividerEXT";
import ClockSIGNAL from "@/model/gen/model/ClockSIGNAL";
import ClockFreqmonEXT from "@/model/gen/model/ClockFreqmonEXT";
import ClockMultiplexerEXT from "@/model/gen/model/ClockMultiplexerEXT";
import ClockCMUCtrl from "@/model/gen/model/ClockCMUCtrl";
import ClockDiagramFolder from "@/model/gen/model/ClockDiagramFolder";
import ClockIPInput from "@/model/gen/model/ClockIPInput";
import ClockIPDiagram from "@/model/gen/model/ClockIPDiagram";
import ClockPLL from "@/model/gen/model/ClockPLL";
import ClockDFTOCCBuffer from "@/model/gen/model/ClockDFTOCCBuffer";
import ClockDiagram from "@/model/gen/model/ClockDiagram";
import ClockFreqmon from "@/model/gen/model/ClockFreqmon";
import ClockIP from "@/model/gen/model/ClockIP";
import ClockDividerEXT from "@/model/gen/model/ClockDividerEXT";
import ClockRefCLKMultiplexerEXT from "@/model/gen/model/ClockRefCLKMultiplexerEXT";
import ClockCMU from "@/model/gen/model/ClockCMU";
import ClockDivider from "@/model/gen/model/ClockDivider";
import ClockQChannelManager from "@/model/gen/model/ClockQChannelManager";
import ClockPLLCtrlEXT from "@/model/gen/model/ClockPLLCtrlEXT";
import ClockGate from "@/model/gen/model/ClockGate";
import ClockNMDivider from "@/model/gen/model/ClockNMDivider";
import ClockRefCLKMultiplexer from "@/model/gen/model/ClockRefCLKMultiplexer";
import ClockCMUFolder from "@/model/gen/model/ClockCMUFolder";
import ClockBuffer from "@/model/gen/model/ClockBuffer";
import DUTModuleDiagram from "@/model/gen/model/DUTModuleDiagram";
import DUTModuleFolder from "@/model/gen/model/DUTModuleFolder";
import DUTInstance from "@/model/gen/model/DUTInstance";
import DUTModule from "@/model/gen/model/DUTModule";
import PowerDiagram from "@/model/gen/model/PowerDiagram";
import PowerPMRINTRSFRATOM from "@/model/gen/model/PowerPMRINTRSFRATOM";
import PowerPMRCoreMode from "@/model/gen/model/PowerPMRCoreMode";
import PowerPMRINTRSFR from "@/model/gen/model/PowerPMRINTRSFR";
import PowerPMRTIMERGRP from "@/model/gen/model/PowerPMRTIMERGRP";
import PowerISOEN from "@/model/gen/model/PowerISOEN";
import PowerSequenceStart from "@/model/gen/model/PowerSequenceStart";
import PowerPMRINTREvent from "@/model/gen/model/PowerPMRINTREvent";
import PowerDiagramFolder from "@/model/gen/model/PowerDiagramFolder";
import PowerUSERDEFIN from "@/model/gen/model/PowerUSERDEFIN";
import PowerPMRINTRGenBit from "@/model/gen/model/PowerPMRINTRGenBit";
import PowerPCH from "@/model/gen/model/PowerPCH";
import PowerHANDSHAKE from "@/model/gen/model/PowerHANDSHAKE";
import PowerPWREN from "@/model/gen/model/PowerPWREN";
import PowerPSW from "@/model/gen/model/PowerPSW";
import PowerPMRPMDCTRL from "@/model/gen/model/PowerPMRPMDCTRL";
import PowerSequenceGoto from "@/model/gen/model/PowerSequenceGoto";
import PowerSequenceReadWait from "@/model/gen/model/PowerSequenceReadWait";
import PowerREFCLKEN from "@/model/gen/model/PowerREFCLKEN";
import PowerPMRCorePCU from "@/model/gen/model/PowerPMRCorePCU";
import PowerPMRTIMEOUT from "@/model/gen/model/PowerPMRTIMEOUT";
import PowerPMD from "@/model/gen/model/PowerPMD";
import PowerPMCFolder from "@/model/gen/model/PowerPMCFolder";
import PowerPMDFolder from "@/model/gen/model/PowerPMDFolder";
import PowerPMRINTREXTATOM from "@/model/gen/model/PowerPMRINTREXTATOM";
import PowerPMRCTRL from "@/model/gen/model/PowerPMRCTRL";
import PowerAPM from "@/model/gen/model/PowerAPM";
import PowerSequenceDiagram from "@/model/gen/model/PowerSequenceDiagram";
import PowerPMRCoreNMI from "@/model/gen/model/PowerPMRCoreNMI";
import PowerUSERDEFOUT from "@/model/gen/model/PowerUSERDEFOUT";
import PowerPMRINTRGRP from "@/model/gen/model/PowerPMRINTRGRP";
import PowerPMRLATCH from "@/model/gen/model/PowerPMRLATCH";
import PowerRESET from "@/model/gen/model/PowerRESET";
import PowerCLINK from "@/model/gen/model/PowerCLINK";
import PowerSequenceIf from "@/model/gen/model/PowerSequenceIf";
import PowerOTP from "@/model/gen/model/PowerOTP";
import PowerPMR from "@/model/gen/model/PowerPMR";
import PowerPMDInstance from "@/model/gen/model/PowerPMDInstance";
import PowerPMRINFORM from "@/model/gen/model/PowerPMRINFORM";
import PowerAPMPWRUP from "@/model/gen/model/PowerAPMPWRUP";
import PowerPMRTIMER from "@/model/gen/model/PowerPMRTIMER";
import PowerPMRINTREXT from "@/model/gen/model/PowerPMRINTREXT";
import PowerSequenceCall from "@/model/gen/model/PowerSequenceCall";
import PowerPMDCTRLSPT from "@/model/gen/model/PowerPMDCTRLSPT";
import PowerPMC from "@/model/gen/model/PowerPMC";
import PowerSequenceConfigurationFolder from "@/model/gen/model/PowerSequenceConfigurationFolder";
import PowerPMRCore from "@/model/gen/model/PowerPMRCore";
import PowerAPMPWRDOWN from "@/model/gen/model/PowerAPMPWRDOWN";
import PowerRETENTION from "@/model/gen/model/PowerRETENTION";
import PowerPMRCoreReset from "@/model/gen/model/PowerPMRCoreReset";
import PowerSequenceLabel from "@/model/gen/model/PowerSequenceLabel";
import PowerSequenceConfiguration from "@/model/gen/model/PowerSequenceConfiguration";
import PowerSequenceWait from "@/model/gen/model/PowerSequenceWait";
import PowerSequenceWrite from "@/model/gen/model/PowerSequenceWrite";
import PowerSequenceBack from "@/model/gen/model/PowerSequenceBack";
import PowerSequenceLabelRef from "@/model/gen/model/PowerSequenceLabelRef";
import PowerSequenceEnd from "@/model/gen/model/PowerSequenceEnd";
import PowerMEM from "@/model/gen/model/PowerMEM";
import PowerPMRPMDGRP from "@/model/gen/model/PowerPMRPMDGRP";
import SDCModuleFolder from "@/model/gen/model/SDCModuleFolder";
import SDCInstance from "@/model/gen/model/SDCInstance";
import SDCModule from "@/model/gen/model/SDCModule";
import SDCDiagram from "@/model/gen/model/SDCDiagram";
import SDCPhantomGroup from "@/model/gen/model/SDCPhantomGroup";
import SDCClockGroup from "@/model/gen/model/SDCClockGroup";
import SDCPhantom from "@/model/gen/model/SDCPhantom";
import UPFMapperGround from "@/model/gen/model/UPFMapperGround";
import UPFEnableLevelShifter from "@/model/gen/model/UPFEnableLevelShifter";
import UPFSRAM from "@/model/gen/model/UPFSRAM";
import UPFPhantomGround from "@/model/gen/model/UPFPhantomGround";
import UPFPowerSwitch from "@/model/gen/model/UPFPowerSwitch";
import UPFHardMacro from "@/model/gen/model/UPFHardMacro";
import UPFElement from "@/model/gen/model/UPFElement";
import UPFPowerCtrlUnit from "@/model/gen/model/UPFPowerCtrlUnit";
import UPFIsolationCell from "@/model/gen/model/UPFIsolationCell";
import UPFRetention from "@/model/gen/model/UPFRetention";
import UPFModule from "@/model/gen/model/UPFModule";
import UPFPowerDomainInstance from "@/model/gen/model/UPFPowerDomainInstance";
import UPFLevelShifter from "@/model/gen/model/UPFLevelShifter";
import UPFPowerDomain from "@/model/gen/model/UPFPowerDomain";
import UPFGround from "@/model/gen/model/UPFGround";
import UPFPhantomPowerSource from "@/model/gen/model/UPFPhantomPowerSource";
import UPFMapperPowerSource from "@/model/gen/model/UPFMapperPowerSource";
import UPFModuleFolder from "@/model/gen/model/UPFModuleFolder";
import UPFPowerSource from "@/model/gen/model/UPFPowerSource";
import UPFDiagram from "@/model/gen/model/UPFDiagram";
import Voltage from "@/model/gen/model/Voltage";
import VoltageDomain from "@/model/gen/model/VoltageDomain";
import VoltageLevelFolder from "@/model/gen/model/VoltageLevelFolder";
import WrapperDFT from "@/model/gen/model/WrapperDFT";
import WrapperDFTFolder from "@/model/gen/model/WrapperDFTFolder";
export default {
    getConstructor(type) {
        switch (type) {
            case ItemType.Phantom:
                return Phantom;
            case ItemType.SFRBlock:
                return SFRBlock;
            case ItemType.LabelRef:
                return LabelRef;
            case ItemType.SFR:
                return SFR;
            case ItemType.ConfigurationFolder:
                return ConfigurationFolder;
            case ItemType.Connection:
                return Connection;
            case ItemType.Project:
                return Project;
            case ItemType.SFRField:
                return SFRField;
            case ItemType.Configuration:
                return Configuration;
            case ItemType.Node:
                return Node;
            case ItemType.NodeMetaView:
                return NodeMetaView;
            case ItemType.ConfigurationElement:
                return ConfigurationElement;
            case ItemType.Input:
                return Input;
            case ItemType.Label:
                return Label;
            case ItemType.Output:
                return Output;
            case ItemType.ClockQChannelManagerEXT:
                return ClockQChannelManagerEXT;
            case ItemType.ClockMultiplexer:
                return ClockMultiplexer;
            case ItemType.ClockGateEXT:
                return ClockGateEXT;
            case ItemType.ClockPLLCtrl:
                return ClockPLLCtrl;
            case ItemType.ClockExternalCLKSource:
                return ClockExternalCLKSource;
            case ItemType.ClockQChannelInfo:
                return ClockQChannelInfo;
            case ItemType.ClockNMDividerEXT:
                return ClockNMDividerEXT;
            case ItemType.ClockSIGNAL:
                return ClockSIGNAL;
            case ItemType.ClockFreqmonEXT:
                return ClockFreqmonEXT;
            case ItemType.ClockMultiplexerEXT:
                return ClockMultiplexerEXT;
            case ItemType.ClockCMUCtrl:
                return ClockCMUCtrl;
            case ItemType.ClockDiagramFolder:
                return ClockDiagramFolder;
            case ItemType.ClockIPInput:
                return ClockIPInput;
            case ItemType.ClockIPDiagram:
                return ClockIPDiagram;
            case ItemType.ClockPLL:
                return ClockPLL;
            case ItemType.ClockDFTOCCBuffer:
                return ClockDFTOCCBuffer;
            case ItemType.ClockDiagram:
                return ClockDiagram;
            case ItemType.ClockFreqmon:
                return ClockFreqmon;
            case ItemType.ClockIP:
                return ClockIP;
            case ItemType.ClockDividerEXT:
                return ClockDividerEXT;
            case ItemType.ClockRefCLKMultiplexerEXT:
                return ClockRefCLKMultiplexerEXT;
            case ItemType.ClockCMU:
                return ClockCMU;
            case ItemType.ClockDivider:
                return ClockDivider;
            case ItemType.ClockQChannelManager:
                return ClockQChannelManager;
            case ItemType.ClockPLLCtrlEXT:
                return ClockPLLCtrlEXT;
            case ItemType.ClockGate:
                return ClockGate;
            case ItemType.ClockNMDivider:
                return ClockNMDivider;
            case ItemType.ClockRefCLKMultiplexer:
                return ClockRefCLKMultiplexer;
            case ItemType.ClockCMUFolder:
                return ClockCMUFolder;
            case ItemType.ClockBuffer:
                return ClockBuffer;
            case ItemType.DUTModuleDiagram:
                return DUTModuleDiagram;
            case ItemType.DUTModuleFolder:
                return DUTModuleFolder;
            case ItemType.DUTInstance:
                return DUTInstance;
            case ItemType.DUTModule:
                return DUTModule;
            case ItemType.PowerDiagram:
                return PowerDiagram;
            case ItemType.PowerPMRINTRSFRATOM:
                return PowerPMRINTRSFRATOM;
            case ItemType.PowerPMRCoreMode:
                return PowerPMRCoreMode;
            case ItemType.PowerPMRINTRSFR:
                return PowerPMRINTRSFR;
            case ItemType.PowerPMRTIMERGRP:
                return PowerPMRTIMERGRP;
            case ItemType.PowerISOEN:
                return PowerISOEN;
            case ItemType.PowerSequenceStart:
                return PowerSequenceStart;
            case ItemType.PowerPMRINTREvent:
                return PowerPMRINTREvent;
            case ItemType.PowerDiagramFolder:
                return PowerDiagramFolder;
            case ItemType.PowerUSERDEFIN:
                return PowerUSERDEFIN;
            case ItemType.PowerPMRINTRGenBit:
                return PowerPMRINTRGenBit;
            case ItemType.PowerPCH:
                return PowerPCH;
            case ItemType.PowerHANDSHAKE:
                return PowerHANDSHAKE;
            case ItemType.PowerPWREN:
                return PowerPWREN;
            case ItemType.PowerPSW:
                return PowerPSW;
            case ItemType.PowerPMRPMDCTRL:
                return PowerPMRPMDCTRL;
            case ItemType.PowerSequenceGoto:
                return PowerSequenceGoto;
            case ItemType.PowerSequenceReadWait:
                return PowerSequenceReadWait;
            case ItemType.PowerREFCLKEN:
                return PowerREFCLKEN;
            case ItemType.PowerPMRCorePCU:
                return PowerPMRCorePCU;
            case ItemType.PowerPMRTIMEOUT:
                return PowerPMRTIMEOUT;
            case ItemType.PowerPMD:
                return PowerPMD;
            case ItemType.PowerPMCFolder:
                return PowerPMCFolder;
            case ItemType.PowerPMDFolder:
                return PowerPMDFolder;
            case ItemType.PowerPMRINTREXTATOM:
                return PowerPMRINTREXTATOM;
            case ItemType.PowerPMRCTRL:
                return PowerPMRCTRL;
            case ItemType.PowerAPM:
                return PowerAPM;
            case ItemType.PowerSequenceDiagram:
                return PowerSequenceDiagram;
            case ItemType.PowerPMRCoreNMI:
                return PowerPMRCoreNMI;
            case ItemType.PowerUSERDEFOUT:
                return PowerUSERDEFOUT;
            case ItemType.PowerPMRINTRGRP:
                return PowerPMRINTRGRP;
            case ItemType.PowerPMRLATCH:
                return PowerPMRLATCH;
            case ItemType.PowerRESET:
                return PowerRESET;
            case ItemType.PowerCLINK:
                return PowerCLINK;
            case ItemType.PowerSequenceIf:
                return PowerSequenceIf;
            case ItemType.PowerOTP:
                return PowerOTP;
            case ItemType.PowerPMR:
                return PowerPMR;
            case ItemType.PowerPMDInstance:
                return PowerPMDInstance;
            case ItemType.PowerPMRINFORM:
                return PowerPMRINFORM;
            case ItemType.PowerAPMPWRUP:
                return PowerAPMPWRUP;
            case ItemType.PowerPMRTIMER:
                return PowerPMRTIMER;
            case ItemType.PowerPMRINTREXT:
                return PowerPMRINTREXT;
            case ItemType.PowerSequenceCall:
                return PowerSequenceCall;
            case ItemType.PowerPMDCTRLSPT:
                return PowerPMDCTRLSPT;
            case ItemType.PowerPMC:
                return PowerPMC;
            case ItemType.PowerSequenceConfigurationFolder:
                return PowerSequenceConfigurationFolder;
            case ItemType.PowerPMRCore:
                return PowerPMRCore;
            case ItemType.PowerAPMPWRDOWN:
                return PowerAPMPWRDOWN;
            case ItemType.PowerRETENTION:
                return PowerRETENTION;
            case ItemType.PowerPMRCoreReset:
                return PowerPMRCoreReset;
            case ItemType.PowerSequenceLabel:
                return PowerSequenceLabel;
            case ItemType.PowerSequenceConfiguration:
                return PowerSequenceConfiguration;
            case ItemType.PowerSequenceWait:
                return PowerSequenceWait;
            case ItemType.PowerSequenceWrite:
                return PowerSequenceWrite;
            case ItemType.PowerSequenceBack:
                return PowerSequenceBack;
            case ItemType.PowerSequenceLabelRef:
                return PowerSequenceLabelRef;
            case ItemType.PowerSequenceEnd:
                return PowerSequenceEnd;
            case ItemType.PowerMEM:
                return PowerMEM;
            case ItemType.PowerPMRPMDGRP:
                return PowerPMRPMDGRP;
            case ItemType.SDCModuleFolder:
                return SDCModuleFolder;
            case ItemType.SDCInstance:
                return SDCInstance;
            case ItemType.SDCModule:
                return SDCModule;
            case ItemType.SDCDiagram:
                return SDCDiagram;
            case ItemType.SDCPhantomGroup:
                return SDCPhantomGroup;
            case ItemType.SDCClockGroup:
                return SDCClockGroup;
            case ItemType.SDCPhantom:
                return SDCPhantom;
            case ItemType.UPFMapperGround:
                return UPFMapperGround;
            case ItemType.UPFEnableLevelShifter:
                return UPFEnableLevelShifter;
            case ItemType.UPFSRAM:
                return UPFSRAM;
            case ItemType.UPFPhantomGround:
                return UPFPhantomGround;
            case ItemType.UPFPowerSwitch:
                return UPFPowerSwitch;
            case ItemType.UPFHardMacro:
                return UPFHardMacro;
            case ItemType.UPFElement:
                return UPFElement;
            case ItemType.UPFPowerCtrlUnit:
                return UPFPowerCtrlUnit;
            case ItemType.UPFIsolationCell:
                return UPFIsolationCell;
            case ItemType.UPFRetention:
                return UPFRetention;
            case ItemType.UPFModule:
                return UPFModule;
            case ItemType.UPFPowerDomainInstance:
                return UPFPowerDomainInstance;
            case ItemType.UPFLevelShifter:
                return UPFLevelShifter;
            case ItemType.UPFPowerDomain:
                return UPFPowerDomain;
            case ItemType.UPFGround:
                return UPFGround;
            case ItemType.UPFPhantomPowerSource:
                return UPFPhantomPowerSource;
            case ItemType.UPFMapperPowerSource:
                return UPFMapperPowerSource;
            case ItemType.UPFModuleFolder:
                return UPFModuleFolder;
            case ItemType.UPFPowerSource:
                return UPFPowerSource;
            case ItemType.UPFDiagram:
                return UPFDiagram;
            case ItemType.Voltage:
                return Voltage;
            case ItemType.VoltageDomain:
                return VoltageDomain;
            case ItemType.VoltageLevelFolder:
                return VoltageLevelFolder;
            case ItemType.WrapperDFT:
                return WrapperDFT;
            case ItemType.WrapperDFTFolder:
                return WrapperDFTFolder;
            default:
                return;
        }
    },

    getSFRItemTarget(type) {
        switch (type) {
            case ItemType.ClockQChannelManagerEXT:
                return ClockQChannelManagerEXT;
            case ItemType.ClockMultiplexer:
                return ClockMultiplexer;
            case ItemType.ClockGateEXT:
                return ClockGateEXT;
            case ItemType.ClockPLLCtrl:
                return ClockPLLCtrl;
            case ItemType.ClockNMDividerEXT:
                return ClockNMDividerEXT;
            case ItemType.ClockFreqmonEXT:
                return ClockFreqmonEXT;
            case ItemType.ClockMultiplexerEXT:
                return ClockMultiplexerEXT;
            case ItemType.ClockCMUCtrl:
                return ClockCMUCtrl;
            case ItemType.ClockPLL:
                return ClockPLL;
            case ItemType.ClockFreqmon:
                return ClockFreqmon;
            case ItemType.ClockDividerEXT:
                return ClockDividerEXT;
            case ItemType.ClockRefCLKMultiplexerEXT:
                return ClockRefCLKMultiplexerEXT;
            case ItemType.ClockDivider:
                return ClockDivider;
            case ItemType.ClockQChannelManager:
                return ClockQChannelManager;
            case ItemType.ClockPLLCtrlEXT:
                return ClockPLLCtrlEXT;
            case ItemType.ClockGate:
                return ClockGate;
            case ItemType.ClockNMDivider:
                return ClockNMDivider;
            case ItemType.ClockRefCLKMultiplexer:
                return ClockRefCLKMultiplexer;
            case ItemType.ClockBuffer:
                return ClockBuffer;
            case ItemType.PowerPMRINTRSFRATOM:
                return PowerPMRINTRSFRATOM;
            case ItemType.PowerPMRCoreMode:
                return PowerPMRCoreMode;
            case ItemType.PowerPMRINTRSFR:
                return PowerPMRINTRSFR;
            case ItemType.PowerPMRTIMERGRP:
                return PowerPMRTIMERGRP;
            case ItemType.PowerISOEN:
                return PowerISOEN;
            case ItemType.PowerPMRINTREvent:
                return PowerPMRINTREvent;
            case ItemType.PowerUSERDEFIN:
                return PowerUSERDEFIN;
            case ItemType.PowerPMRINTRGenBit:
                return PowerPMRINTRGenBit;
            case ItemType.PowerPCH:
                return PowerPCH;
            case ItemType.PowerHANDSHAKE:
                return PowerHANDSHAKE;
            case ItemType.PowerPWREN:
                return PowerPWREN;
            case ItemType.PowerPSW:
                return PowerPSW;
            case ItemType.PowerPMRPMDCTRL:
                return PowerPMRPMDCTRL;
            case ItemType.PowerREFCLKEN:
                return PowerREFCLKEN;
            case ItemType.PowerPMRCorePCU:
                return PowerPMRCorePCU;
            case ItemType.PowerPMRTIMEOUT:
                return PowerPMRTIMEOUT;
            case ItemType.PowerPMRINTREXTATOM:
                return PowerPMRINTREXTATOM;
            case ItemType.PowerPMRCTRL:
                return PowerPMRCTRL;
            case ItemType.PowerAPM:
                return PowerAPM;
            case ItemType.PowerPMRCoreNMI:
                return PowerPMRCoreNMI;
            case ItemType.PowerUSERDEFOUT:
                return PowerUSERDEFOUT;
            case ItemType.PowerPMRLATCH:
                return PowerPMRLATCH;
            case ItemType.PowerRESET:
                return PowerRESET;
            case ItemType.PowerCLINK:
                return PowerCLINK;
            case ItemType.PowerOTP:
                return PowerOTP;
            case ItemType.PowerPMRINFORM:
                return PowerPMRINFORM;
            case ItemType.PowerPMRTIMER:
                return PowerPMRTIMER;
            case ItemType.PowerPMRINTREXT:
                return PowerPMRINTREXT;
            case ItemType.PowerPMDCTRLSPT:
                return PowerPMDCTRLSPT;
            case ItemType.PowerRETENTION:
                return PowerRETENTION;
            case ItemType.PowerPMRCoreReset:
                return PowerPMRCoreReset;
            case ItemType.PowerMEM:
                return PowerMEM;
            case ItemType.PowerPMRPMDGRP:
                return PowerPMRPMDGRP;
            default:
                return;
        }
    },

    getSFRItemTargets() {
        return [
            ClockQChannelManagerEXT,
            ClockMultiplexer,
            ClockGateEXT,
            ClockPLLCtrl,
            ClockNMDividerEXT,
            ClockFreqmonEXT,
            ClockMultiplexerEXT,
            ClockCMUCtrl,
            ClockPLL,
            ClockFreqmon,
            ClockDividerEXT,
            ClockRefCLKMultiplexerEXT,
            ClockDivider,
            ClockQChannelManager,
            ClockPLLCtrlEXT,
            ClockGate,
            ClockNMDivider,
            ClockRefCLKMultiplexer,
            ClockBuffer,
            PowerPMRINTRSFRATOM,
            PowerPMRCoreMode,
            PowerPMRINTRSFR,
            PowerPMRTIMERGRP,
            PowerISOEN,
            PowerPMRINTREvent,
            PowerUSERDEFIN,
            PowerPMRINTRGenBit,
            PowerPCH,
            PowerHANDSHAKE,
            PowerPWREN,
            PowerPSW,
            PowerPMRPMDCTRL,
            PowerREFCLKEN,
            PowerPMRCorePCU,
            PowerPMRTIMEOUT,
            PowerPMRINTREXTATOM,
            PowerPMRCTRL,
            PowerAPM,
            PowerPMRCoreNMI,
            PowerUSERDEFOUT,
            PowerPMRLATCH,
            PowerRESET,
            PowerCLINK,
            PowerOTP,
            PowerPMRINFORM,
            PowerPMRTIMER,
            PowerPMRINTREXT,
            PowerPMDCTRLSPT,
            PowerRETENTION,
            PowerPMRCoreReset,
            PowerMEM,
            PowerPMRPMDGRP,
        ]
    },

    createItemInstance(type, obj) {
        var item = null;
        switch (type) {
            case ItemType.Phantom:
                item = new Phantom(obj);
                break;
            case ItemType.SFRBlock:
                item = new SFRBlock(obj);
                break;
            case ItemType.LabelRef:
                item = new LabelRef(obj);
                break;
            case ItemType.SFR:
                item = new SFR(obj);
                break;
            case ItemType.ConfigurationFolder:
                item = new ConfigurationFolder(obj);
                break;
            case ItemType.Connection:
                item = new Connection(obj);
                break;
            case ItemType.Project:
                item = new Project(obj);
                break;
            case ItemType.SFRField:
                item = new SFRField(obj);
                break;
            case ItemType.Configuration:
                item = new Configuration(obj);
                break;
            case ItemType.Node:
                item = new Node(obj);
                break;
            case ItemType.NodeMetaView:
                item = new NodeMetaView(obj);
                break;
            case ItemType.ConfigurationElement:
                item = new ConfigurationElement(obj);
                break;
            case ItemType.Input:
                item = new Input(obj);
                break;
            case ItemType.Label:
                item = new Label(obj);
                break;
            case ItemType.Output:
                item = new Output(obj);
                break;
            case ItemType.ClockQChannelManagerEXT:
                item = new ClockQChannelManagerEXT(obj);
                break;
            case ItemType.ClockMultiplexer:
                item = new ClockMultiplexer(obj);
                break;
            case ItemType.ClockGateEXT:
                item = new ClockGateEXT(obj);
                break;
            case ItemType.ClockPLLCtrl:
                item = new ClockPLLCtrl(obj);
                break;
            case ItemType.ClockExternalCLKSource:
                item = new ClockExternalCLKSource(obj);
                break;
            case ItemType.ClockQChannelInfo:
                item = new ClockQChannelInfo(obj);
                break;
            case ItemType.ClockNMDividerEXT:
                item = new ClockNMDividerEXT(obj);
                break;
            case ItemType.ClockSIGNAL:
                item = new ClockSIGNAL(obj);
                break;
            case ItemType.ClockFreqmonEXT:
                item = new ClockFreqmonEXT(obj);
                break;
            case ItemType.ClockMultiplexerEXT:
                item = new ClockMultiplexerEXT(obj);
                break;
            case ItemType.ClockCMUCtrl:
                item = new ClockCMUCtrl(obj);
                break;
            case ItemType.ClockDiagramFolder:
                item = new ClockDiagramFolder(obj);
                break;
            case ItemType.ClockIPInput:
                item = new ClockIPInput(obj);
                break;
            case ItemType.ClockIPDiagram:
                item = new ClockIPDiagram(obj);
                break;
            case ItemType.ClockPLL:
                item = new ClockPLL(obj);
                break;
            case ItemType.ClockDFTOCCBuffer:
                item = new ClockDFTOCCBuffer(obj);
                break;
            case ItemType.ClockDiagram:
                item = new ClockDiagram(obj);
                break;
            case ItemType.ClockFreqmon:
                item = new ClockFreqmon(obj);
                break;
            case ItemType.ClockIP:
                item = new ClockIP(obj);
                break;
            case ItemType.ClockDividerEXT:
                item = new ClockDividerEXT(obj);
                break;
            case ItemType.ClockRefCLKMultiplexerEXT:
                item = new ClockRefCLKMultiplexerEXT(obj);
                break;
            case ItemType.ClockCMU:
                item = new ClockCMU(obj);
                break;
            case ItemType.ClockDivider:
                item = new ClockDivider(obj);
                break;
            case ItemType.ClockQChannelManager:
                item = new ClockQChannelManager(obj);
                break;
            case ItemType.ClockPLLCtrlEXT:
                item = new ClockPLLCtrlEXT(obj);
                break;
            case ItemType.ClockGate:
                item = new ClockGate(obj);
                break;
            case ItemType.ClockNMDivider:
                item = new ClockNMDivider(obj);
                break;
            case ItemType.ClockRefCLKMultiplexer:
                item = new ClockRefCLKMultiplexer(obj);
                break;
            case ItemType.ClockCMUFolder:
                item = new ClockCMUFolder(obj);
                break;
            case ItemType.ClockBuffer:
                item = new ClockBuffer(obj);
                break;
            case ItemType.DUTModuleDiagram:
                item = new DUTModuleDiagram(obj);
                break;
            case ItemType.DUTModuleFolder:
                item = new DUTModuleFolder(obj);
                break;
            case ItemType.DUTInstance:
                item = new DUTInstance(obj);
                break;
            case ItemType.DUTModule:
                item = new DUTModule(obj);
                break;
            case ItemType.PowerDiagram:
                item = new PowerDiagram(obj);
                break;
            case ItemType.PowerPMRINTRSFRATOM:
                item = new PowerPMRINTRSFRATOM(obj);
                break;
            case ItemType.PowerPMRCoreMode:
                item = new PowerPMRCoreMode(obj);
                break;
            case ItemType.PowerPMRINTRSFR:
                item = new PowerPMRINTRSFR(obj);
                break;
            case ItemType.PowerPMRTIMERGRP:
                item = new PowerPMRTIMERGRP(obj);
                break;
            case ItemType.PowerISOEN:
                item = new PowerISOEN(obj);
                break;
            case ItemType.PowerSequenceStart:
                item = new PowerSequenceStart(obj);
                break;
            case ItemType.PowerPMRINTREvent:
                item = new PowerPMRINTREvent(obj);
                break;
            case ItemType.PowerDiagramFolder:
                item = new PowerDiagramFolder(obj);
                break;
            case ItemType.PowerUSERDEFIN:
                item = new PowerUSERDEFIN(obj);
                break;
            case ItemType.PowerPMRINTRGenBit:
                item = new PowerPMRINTRGenBit(obj);
                break;
            case ItemType.PowerPCH:
                item = new PowerPCH(obj);
                break;
            case ItemType.PowerHANDSHAKE:
                item = new PowerHANDSHAKE(obj);
                break;
            case ItemType.PowerPWREN:
                item = new PowerPWREN(obj);
                break;
            case ItemType.PowerPSW:
                item = new PowerPSW(obj);
                break;
            case ItemType.PowerPMRPMDCTRL:
                item = new PowerPMRPMDCTRL(obj);
                break;
            case ItemType.PowerSequenceGoto:
                item = new PowerSequenceGoto(obj);
                break;
            case ItemType.PowerSequenceReadWait:
                item = new PowerSequenceReadWait(obj);
                break;
            case ItemType.PowerREFCLKEN:
                item = new PowerREFCLKEN(obj);
                break;
            case ItemType.PowerPMRCorePCU:
                item = new PowerPMRCorePCU(obj);
                break;
            case ItemType.PowerPMRTIMEOUT:
                item = new PowerPMRTIMEOUT(obj);
                break;
            case ItemType.PowerPMD:
                item = new PowerPMD(obj);
                break;
            case ItemType.PowerPMCFolder:
                item = new PowerPMCFolder(obj);
                break;
            case ItemType.PowerPMDFolder:
                item = new PowerPMDFolder(obj);
                break;
            case ItemType.PowerPMRINTREXTATOM:
                item = new PowerPMRINTREXTATOM(obj);
                break;
            case ItemType.PowerPMRCTRL:
                item = new PowerPMRCTRL(obj);
                break;
            case ItemType.PowerAPM:
                item = new PowerAPM(obj);
                break;
            case ItemType.PowerSequenceDiagram:
                item = new PowerSequenceDiagram(obj);
                break;
            case ItemType.PowerPMRCoreNMI:
                item = new PowerPMRCoreNMI(obj);
                break;
            case ItemType.PowerUSERDEFOUT:
                item = new PowerUSERDEFOUT(obj);
                break;
            case ItemType.PowerPMRINTRGRP:
                item = new PowerPMRINTRGRP(obj);
                break;
            case ItemType.PowerPMRLATCH:
                item = new PowerPMRLATCH(obj);
                break;
            case ItemType.PowerRESET:
                item = new PowerRESET(obj);
                break;
            case ItemType.PowerCLINK:
                item = new PowerCLINK(obj);
                break;
            case ItemType.PowerSequenceIf:
                item = new PowerSequenceIf(obj);
                break;
            case ItemType.PowerOTP:
                item = new PowerOTP(obj);
                break;
            case ItemType.PowerPMR:
                item = new PowerPMR(obj);
                break;
            case ItemType.PowerPMDInstance:
                item = new PowerPMDInstance(obj);
                break;
            case ItemType.PowerPMRINFORM:
                item = new PowerPMRINFORM(obj);
                break;
            case ItemType.PowerAPMPWRUP:
                item = new PowerAPMPWRUP(obj);
                break;
            case ItemType.PowerPMRTIMER:
                item = new PowerPMRTIMER(obj);
                break;
            case ItemType.PowerPMRINTREXT:
                item = new PowerPMRINTREXT(obj);
                break;
            case ItemType.PowerSequenceCall:
                item = new PowerSequenceCall(obj);
                break;
            case ItemType.PowerPMDCTRLSPT:
                item = new PowerPMDCTRLSPT(obj);
                break;
            case ItemType.PowerPMC:
                item = new PowerPMC(obj);
                break;
            case ItemType.PowerSequenceConfigurationFolder:
                item = new PowerSequenceConfigurationFolder(obj);
                break;
            case ItemType.PowerPMRCore:
                item = new PowerPMRCore(obj);
                break;
            case ItemType.PowerAPMPWRDOWN:
                item = new PowerAPMPWRDOWN(obj);
                break;
            case ItemType.PowerRETENTION:
                item = new PowerRETENTION(obj);
                break;
            case ItemType.PowerPMRCoreReset:
                item = new PowerPMRCoreReset(obj);
                break;
            case ItemType.PowerSequenceLabel:
                item = new PowerSequenceLabel(obj);
                break;
            case ItemType.PowerSequenceConfiguration:
                item = new PowerSequenceConfiguration(obj);
                break;
            case ItemType.PowerSequenceWait:
                item = new PowerSequenceWait(obj);
                break;
            case ItemType.PowerSequenceWrite:
                item = new PowerSequenceWrite(obj);
                break;
            case ItemType.PowerSequenceBack:
                item = new PowerSequenceBack(obj);
                break;
            case ItemType.PowerSequenceLabelRef:
                item = new PowerSequenceLabelRef(obj);
                break;
            case ItemType.PowerSequenceEnd:
                item = new PowerSequenceEnd(obj);
                break;
            case ItemType.PowerMEM:
                item = new PowerMEM(obj);
                break;
            case ItemType.PowerPMRPMDGRP:
                item = new PowerPMRPMDGRP(obj);
                break;
            case ItemType.SDCModuleFolder:
                item = new SDCModuleFolder(obj);
                break;
            case ItemType.SDCInstance:
                item = new SDCInstance(obj);
                break;
            case ItemType.SDCModule:
                item = new SDCModule(obj);
                break;
            case ItemType.SDCDiagram:
                item = new SDCDiagram(obj);
                break;
            case ItemType.SDCPhantomGroup:
                item = new SDCPhantomGroup(obj);
                break;
            case ItemType.SDCClockGroup:
                item = new SDCClockGroup(obj);
                break;
            case ItemType.SDCPhantom:
                item = new SDCPhantom(obj);
                break;
            case ItemType.UPFMapperGround:
                item = new UPFMapperGround(obj);
                break;
            case ItemType.UPFEnableLevelShifter:
                item = new UPFEnableLevelShifter(obj);
                break;
            case ItemType.UPFSRAM:
                item = new UPFSRAM(obj);
                break;
            case ItemType.UPFPhantomGround:
                item = new UPFPhantomGround(obj);
                break;
            case ItemType.UPFPowerSwitch:
                item = new UPFPowerSwitch(obj);
                break;
            case ItemType.UPFHardMacro:
                item = new UPFHardMacro(obj);
                break;
            case ItemType.UPFElement:
                item = new UPFElement(obj);
                break;
            case ItemType.UPFPowerCtrlUnit:
                item = new UPFPowerCtrlUnit(obj);
                break;
            case ItemType.UPFIsolationCell:
                item = new UPFIsolationCell(obj);
                break;
            case ItemType.UPFRetention:
                item = new UPFRetention(obj);
                break;
            case ItemType.UPFModule:
                item = new UPFModule(obj);
                break;
            case ItemType.UPFPowerDomainInstance:
                item = new UPFPowerDomainInstance(obj);
                break;
            case ItemType.UPFLevelShifter:
                item = new UPFLevelShifter(obj);
                break;
            case ItemType.UPFPowerDomain:
                item = new UPFPowerDomain(obj);
                break;
            case ItemType.UPFGround:
                item = new UPFGround(obj);
                break;
            case ItemType.UPFPhantomPowerSource:
                item = new UPFPhantomPowerSource(obj);
                break;
            case ItemType.UPFMapperPowerSource:
                item = new UPFMapperPowerSource(obj);
                break;
            case ItemType.UPFModuleFolder:
                item = new UPFModuleFolder(obj);
                break;
            case ItemType.UPFPowerSource:
                item = new UPFPowerSource(obj);
                break;
            case ItemType.UPFDiagram:
                item = new UPFDiagram(obj);
                break;
            case ItemType.Voltage:
                item = new Voltage(obj);
                break;
            case ItemType.VoltageDomain:
                item = new VoltageDomain(obj);
                break;
            case ItemType.VoltageLevelFolder:
                item = new VoltageLevelFolder(obj);
                break;
            case ItemType.WrapperDFT:
                item = new WrapperDFT(obj);
                break;
            case ItemType.WrapperDFTFolder:
                item = new WrapperDFTFolder(obj);
                break;
            default:
                console.error(type);
                throw new Error(`Please Check the ItemType:: ${type}`);
        }
        return item;
    }
}