/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemHandler from "../ItemHandler";
import { ItemType } from "ITDAModelTypes";
import Utils from "./utils";

export default class PowerSequenceDiagramEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
    this.addPropertyListener(item.getFidLast() + 10, this.lstn_update_name);
  }

  lstn_name(item, newVal) {
    item.setTitle(newVal);
  }

  lstn_update_name(item, newVal) {
    item.setName(newVal);
  }

  postProcess(item) {
    item.setTitle(item.getName(), false);
  }
}
