import Canvas from "@/canvas";
import API from "@/api/internal";
import Utils from "../utils";

import { Context } from "../types";
import { NodeType } from "@/canvas/types";
import { Item, ItemType } from "ITDAModelTypes";

export const connectionremoved = async (context: Context): Promise<boolean> => {
  const editor = Canvas.getEditor();

  const conn = context.options.data;
  const source = editor.getNode(conn.source);
  const target = editor.getNode(conn.target);
  if (!source || !target) return false;

  await Utils.removeConnection(conn);
  if (Canvas.getImported() && Canvas.getModuleImported()) {
    await Utils.runDataFlow(context);
  }

  if (
    source.getType() === NodeType.ITDAPMDGRP &&
    target.getType() === NodeType.ITDAINSTANCEPMD
  ) {
    const pmdgrpItem = API.getItem(source.getItemID());
    const pmdInstItem = API.getItem(target.getItemID());
    if (!pmdgrpItem || !pmdInstItem) {
      return false;
    }
    const outputPortNum = conn.sourceOutput.slice(-1);
    const targetPmrPmdCtrl = pmdgrpItem.getChildren().filter((child: Item) => {
      return child.getItemType() === ItemType.PowerPMRPMDCTRL;
    })[outputPortNum];
    targetPmrPmdCtrl?.setName(`${pmdgrpItem.getName()}__PMD${outputPortNum}`);

    API.updateINTRChildrenTitle(pmdgrpItem);
    API.updatePMR2PMDGRPSEL(pmdgrpItem);
  }

  return true;
};
