/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x1000",
    "size": "0x2000",
    "alignSize": "0x20",
    "sfrs": [
        {
            "name": "PMD_CTRL",
            "alignOffset": "0x100",
            "description": "PMD Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_PWR_REQ",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD Control Signal for {{name}}\n0 = Set PWR_REQ to 0\n1 = Set PWR_REQ to 1",
                    "initValue": "0x0",
                    "nc": "SFR_PWR_REQ"
                },
                {
                    "name": "SFR_PWR_STATUS",
                    "index": 4,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD Control Signal for {{name}}\n0 = PWR_STATUS is Low\n1 = PWR_STATUS is High",
                    "initValue": "0x0",
                    "nc": "SFR_PWR_STATUS"
                },
                {
                    "name": "SFR_PWR_CMD_0",
                    "index": 8,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Set PMD_CMD_0 Signal for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PWR_CMD_0"
                },
                {
                    "name": "SFR_PWR_CMD_1",
                    "index": 9,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Set PMD_CMD_1 Signal for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PWR_CMD_1"
                },
                {
                    "name": "SFR_PWR_CMD_2",
                    "index": 10,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Set PMD_CMD_2 Signal for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PWR_CMD_2"
                },
                {
                    "name": "SFR_PWR_CMD_3",
                    "index": 11,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Set PMD_CMD_3 Signal for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PWR_CMD_3"
                },
                {
                    "name": "SFR_IRQ",
                    "index": 12,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "IRQ Siganl",
                    "initValue": "0x0",
                    "nc": "SFR_IRQ"
                }
            ]
        },
        {
            "name": "PMD_OUT",
            "alignOffset": "0x104",
            "description": "PMD Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_RESETn_PMD",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Reset Control Signal for {{name}}\n0 = Set RESETn to 0 (Reset is Asserted)\n1 = Set RESETn to 1 (Reset is Released)",
                    "initValue": "0x0",
                    "nc": "SFR_RESETn_PMD"
                },
                {
                    "name": "SFR_RESETn_PMD_PCU",
                    "index": 1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Reset Control Signal of PCU for {{name}}\n0 = Set RESETn to 0 (Reset is Asserted)\n1 = Set RESETn to 1 (Reset is Released)",
                    "initValue": "0x0",
                    "nc": "SFR_RESETn_PMD_PCU"
                },
                {
                    "name": "SFR_PMD_FUNCCLK_ENABLE",
                    "index": 2,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD Function Clock Enable for {{name}}\n0 = PMD Function Clock is Disabled\n1 = PMD Function Clock is Enabled",
                    "initValue": "0x1",
                    "nc": "SFR_PMD_FUNCCLK_ENABLE"
                },
                {
                    "name": "SFR_PMD_AIM_ENABLE",
                    "index": 3,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD NMI Control for {{name}}\nWhen this Field is set to 1, PMD branches to START_NMI",
                    "initValue": "0x0",
                    "nc": "SFR_PMD_AIM_ENABLE"
                },
                {
                    "name": "SFR_PMD_APM_ENABLE",
                    "index": 4,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD Auto Power Management Enable for {{name}}\n0 = PMD Auto Power Management is Disabled, PWR_REQ from SFR_PWR_REQ is Valid\n1 = PMD Auto Power Management is Enabled, PWR_REQ from APM is Valid",
                    "initValue": "0x0",
                    "nc": "SFR_PMD_APM_ENABLE"
                },
                {
                    "name": "SFR_PMD_APM_EXPIRE_VALUE",
                    "index": 5,
                    "size": 4,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD APM Hysteresis Filter Expire Value for {{name}}\nPWR_REQ becomes 0, after PWRDOWN signals are Valid until Hysteresis counter Expire",
                    "initValue": "0x0",
                    "nc": "SFR_PMD_APM_EXPIRE_VALUE"
                }
            ]
        },
        {
            "name": "PCU_R0",
            "alignOffset": "0x110",
            "description": "PMD Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_PCU_R0",
                    "index": 0,
                    "size": 32,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD Internal Register R0 Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PCU_R0"
                }
            ]
        },
        {
            "name": "PCU_R1",
            "alignOffset": "0x114",
            "description": "PMD Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_PCU_R1",
                    "index": 0,
                    "size": 32,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD Internal Register R1 Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PCU_R1"
                }
            ]
        },
        {
            "name": "PCU_SP",
            "alignOffset": "0x118",
            "description": "PMD Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_PCU_SP",
                    "index": 0,
                    "size": 16,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD SP Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PCU_SP"
                }
            ]
        },
        {
            "name": "PCU_PC",
            "alignOffset": "0x11C",
            "description": "PMD Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_PCU_PC",
                    "index": 0,
                    "size": 16,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PMD PC Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_PCU_PC"
                }
            ]
        }
    ]
}