/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import LabelRefEvent from '@/model/src/events/LabelRefEvent'
import LabelRefChecker from '@/model/src/checkers/LabelRefChecker'
export default class LabelRef extends Node {
    FID_LABELREF_TYPE = 100
    FID_LABELREF_BOUNDARYNAME = 101
    FID_LABELREF_ITEMLABEL = 102
    FID_LABELREF_LABELS = 103
    FID_LABELREF_SDCPATH = 104
    FID_LABELREF_LAST = 104

    constructor(obj) {
        super(Object.assign(obj,{itemType:"LabelRef"}))
        
        this.setEvent(new LabelRefEvent(this))
        this.setChecker(new LabelRefChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.type === undefined) {
        this.properties.type = "internal"
    }
    if (this.properties.boundaryName === undefined) {
        this.properties.boundaryName = ""
    }
    if (this.properties.labels === undefined) {
        this.properties.labels = []
    }
    if (this.properties.SDCPath === undefined) {
        this.properties.SDCPath = ""
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "LabelRef"
    }

    getSchema() {
        return {"type": {"type": "enum", "targets": ["internal", "external"], "multiple": false, "default": "internal", "category": "IP", "hidden": false, "readOnly": false}, "boundaryName": {"type": "string", "label": "Boundary Port Name", "default": "", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "itemLabel": {"type": "item", "label": "connected label", "desc": "connected label", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "labels": {"type": "item", "label": "Source Labels", "desc": "Source Labels", "multiple": true, "default": [], "category": "IP", "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "", "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"type": {"type": "enum", "targets": ["internal", "external"], "multiple": false, "default": "internal", "category": "IP", "hidden": false, "readOnly": false}, "boundaryName": {"type": "string", "label": "Boundary Port Name", "default": "", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "itemLabel": {"type": "item", "label": "connected label", "desc": "connected label", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "labels": {"type": "item", "label": "Source Labels", "desc": "Source Labels", "multiple": true, "default": [], "category": "IP", "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "", "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setType(type, event=true) {
        if ((type !== null) && (type !== undefined)) {
            if (![String].includes(type.constructor)) {
                throw `type(${type}) must be [String] type`
            }
        }
        const newVal = (type === null || type === undefined) ? undefined : type
        const oldVal = this.properties.type
        this.properties.type = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_LABELREF_TYPE, newVal, oldVal)
        }
    }
    getType(pure = false) {
        return this.properties.type
        
    }
    getFidType() {
        return this.FID_LABELREF_TYPE
    }
    

    setBoundaryName(boundaryName, event=true) {
        if ((boundaryName !== null) && (boundaryName !== undefined)) {
            if (![String].includes(boundaryName.constructor)) {
                throw `boundaryName(${boundaryName}) must be [String] type`
            }
        }
        const newVal = (boundaryName === null || boundaryName === undefined) ? undefined : boundaryName
        const oldVal = this.properties.boundaryName
        this.properties.boundaryName = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_LABELREF_BOUNDARYNAME, newVal, oldVal)
        }
    }
    getBoundaryName(pure = false) {
        return this.properties.boundaryName
        
    }
    getFidBoundaryName() {
        return this.FID_LABELREF_BOUNDARYNAME
    }
    

    setItemLabel(itemLabel, event=true) {
        if ((itemLabel !== null) && (itemLabel !== undefined)) {
            if (!(itemLabel instanceof Item) && (itemLabel.constructor !== String)) {
                throw `itemLabel(${itemLabel}) must be Item or String type`
            }
        }
        const newVal = (itemLabel === null || itemLabel === undefined) ? undefined : (itemLabel.constructor === String) ? itemLabel : itemLabel.getId()
        const oldVal = this.properties.itemLabel
        this.properties.itemLabel = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_LABELREF_ITEMLABEL, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getItemLabel(pure = false) {
        return pure ? this.properties.itemLabel : this.getRefItem(this.properties.itemLabel)
        
    }
    getFidItemLabel() {
        return this.FID_LABELREF_ITEMLABEL
    }
    

    setLabels(labels, event=true) {
        if ((labels !== null) && (labels !== undefined)) {
            if (![Array].includes(labels.constructor)) {
                throw `labels(${labels}) must be [Array] type`
            }
        }
        const newVal = (labels === null || labels === undefined) ? [] : labels.map((o) => {
            if (!o || o.constructor === String) {
                return o
            } else if (this.toRaw(o) instanceof Item) {
                return o.getId()
            }
        })
        const oldVal = this.properties.labels
        this.properties.labels = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_LABELREF_LABELS, newVal.map((id) => this.getRefItem(id)), oldVal.map((id) => this.getRefItem(id)))
        }
    }
    getLabels(pure = false) {
        return pure ? this.properties.labels : this.properties.labels.map((id) => this.getRefItem(id))
        
    }
    getFidLabels() {
        return this.FID_LABELREF_LABELS
    }
    

    setSDCPath(SDCPath, event=true) {
        if ((SDCPath !== null) && (SDCPath !== undefined)) {
            if (![String].includes(SDCPath.constructor)) {
                throw `SDCPath(${SDCPath}) must be [String] type`
            }
        }
        const newVal = (SDCPath === null || SDCPath === undefined) ? undefined : SDCPath
        const oldVal = this.properties.SDCPath
        this.properties.SDCPath = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_LABELREF_SDCPATH, newVal, oldVal)
        }
    }
    getSDCPath(pure = false) {
        return this.properties.SDCPath
        
    }
    getFidSDCPath() {
        return this.FID_LABELREF_SDCPATH
    }
    

    getFidLast() {
        return this.FID_LABELREF_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_LABELREF_TYPE:
                return "type"

            case this.FID_LABELREF_BOUNDARYNAME:
                return "boundaryName"

            case this.FID_LABELREF_ITEMLABEL:
                return "itemLabel"

            case this.FID_LABELREF_LABELS:
                return "labels"

            case this.FID_LABELREF_SDCPATH:
                return "SDCPath"

            default:
                return ""
        }
    }


}