import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;
    const innerStroke = 2;

    g.append("line")
      .attr("x1", (centerX / 4) * 4)
      .attr("y1", (centerY / 4) * 2.5)
      .attr("x2", (centerX / 4) * 4)
      .attr("y2", (centerY / 4) * 6.5)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 4 - 1)
      .attr("y1", (centerY / 4) * 2.5)
      .attr("x2", (centerX / 4) * 5)
      .attr("y2", (centerY / 4) * 2.5)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);
    g.append("line")
      .attr("x1", (centerX / 4) * 4 - 1)
      .attr("y1", (centerY / 4) * 6.5)
      .attr("x2", (centerX / 4) * 5)
      .attr("y2", (centerY / 4) * 6.5)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("path")
      .attr(
        "d",
        `M${(centerX / 4) * 5},${(centerY / 4) * 2.5} A15,15 0 0,1 ${
          (centerX / 4) * 5
        },${(centerY / 4) * 6.5}`
      )
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke)
      .attr("fill", color);

    // Draw input lines
    g.append("line")
      .attr("x1", (centerX / 4) * 2.5)
      .attr("y1", (centerY / 4) * 3.5)
      .attr("x2", (centerX / 4) * 4)
      .attr("y2", (centerY / 4) * 3.5)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 2.5)
      .attr("y1", (centerY / 4) * 5.5)
      .attr("x2", (centerX / 4) * 4)
      .attr("y2", (centerY / 4) * 5.5)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    // Draw texts (ISOEN, D)
    g.append("text")
      .attr("x", (centerX / 4) * 2.05)
      .attr("y", (centerY / 4) * 3.65)
      .attr("text-anchor", "middle")
      .attr("font-size", `6px`)
      .attr("fill", "black")
      .text("D");

    g.append("text")
      .attr("x", (centerX / 4) * 1.4)
      .attr("y", (centerY / 4) * 5.65)
      .attr("text-anchor", "middle")
      .attr("font-size", `6px`)
      .attr("fill", "black")
      .text("ISOEN");

    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 1.5)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("ISOEN");
  },
};
