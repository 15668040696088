// import CanvasUtils from "../canvas/CanvasUtils"
import ItemType from "./ItemType";
import NodeType from "./NodeType";
// import ItemHandler from "./ItemHandler"

export default class UPFHandler {
  static hmSpecs = null;

  static setHMSpec(spec) {
    UPFHandler.hmSpecs = spec;
  }

  static getHMSpecs() {
    return UPFHandler.hmSpecs;
  }

  static getHMSpec(type) {
    return UPFHandler.hmSpecs
      ? UPFHandler.hmSpecs.find((obj) => obj.name === type)
      : null;
  }

  static isUPFTarget(node) {
    const item = node.getItem();
    if (item && item.getItemType().getCategory() === NodeType.UPF) {
      switch (item.getItemType()) {
        case ItemType.UPFHardMacro:
        case ItemType.UPFPowerDomainInstance:
          return false;
        default:
          return true;
      }
    }
    // return item ? item.getUPFMapperPowerSources ? true : false : false
  }

  static createPowerSourceTextVue(node, template, style) {
    const meta = { ...style };
    // const item = node.getItem()
    // const canvas = item.getCanvas().getCurrentCanvas()
    // const canvas = CanvasUtils.getCurrentCanvas()
    // if (canvas) {
    //     meta.vue = CanvasUtils.createVue(canvas, template, node, "PowerSourceText", meta.width, meta.height)
    // }
    return meta;
  }

  static updateUPFStyle(node, text) {
    if (text.vue) {
      const position = [...node.position];
      position[0] +=
        (node.vueContext.$el.clientWidth - text.vue.$el.clientWidth) / 2;
      const height = text.vue.$el.clientHeight;
      text.vue.transform = `translate(${position[0]}px, ${
        position[1] - height
      }px)`;
      text.vue.textAlign = "center";
      text.vue.zIndex = -2;
    }
  }

  static resetMappedPowerSource(powerDiagram, powerSource) {
    powerDiagram.getChildren().forEach((item) => {
      if (item.getUPFMapperPowerSources) {
        item.getUPFMapperPowerSources().forEach((powerSourceMapper) => {
          if (powerSourceMapper.getPowerSource() === powerSource.getId()) {
            powerSourceMapper.setPowerSource(null);
          }
        });
      }
    });
  }
}
