import Handsontable from "handsontable";
import { TableRendererDFT } from "../types";
import Core from "handsontable/core";
// import { CellChange, CellValue } from "handsontable/common";
// import { DropdownMenu } from "handsontable/plugins";

// let editBoolean = false;
let inputValue = "";

const makeInnerHTML = () => {
  return `<div style='display: flex; justify-content: center;'>
    <input id="iExp1" value='${inputValue}' style='border: none; width:100%; outline: none; text-align: center;' type='text' placeholder=0 autocomplete=off
    />
  </div>`;
};

const handleKeyDown = (e: any, instance: Core, row: number, column: number) => {
  const inputElem = document.getElementById("iExp1");
  console.log(instance.getDataAtCell(row, column));

  if (e.keyCode === 13) {
    e.stopPropagation();

    if (!(document.activeElement === inputElem)) {
      console.log(` >>> activeElement !== inputElem`);
      console.log(
        `current renderer is : ${JSON.stringify(
          instance.getCellMeta(row, column)
        )}`
      );
      inputElem?.focus();
    } else {
      console.log(` >>> activeElement === inputElem`);
      inputElem?.blur();
      inputValue = e.target.value;
      instance.selectCell(row + 1, column);
    }
  } else if (e.keyCode >= 37 && e.keyCode <= 40) {
    console.log(e);
  } else if (e.keyCode === 9) {
    console.log(`tab`);
    e.stopPropagation();
    instance.setDataAtCell([[row, column, "DIRECT"]]);
    instance.setCellMeta(row, column, "renderer", "writeCellRenderer");
  } else {
    console.log(e);
    e.stopPropagation();
  }
};

export default () => {
  Handsontable.renderers.registerRenderer(
    TableRendererDFT.tieRenderer,
    (
      instance: Handsontable.Core,
      TD: HTMLTableCellElement,
      row: number,
      column: number,
      prop: string | number,
      value: any,
      cellProperties: Handsontable.CellProperties
    ): void => {
      Handsontable.renderers.TextRenderer(
        instance,
        TD,
        row,
        column,
        prop,
        value,
        cellProperties
      );
      TD.style.color = "black";
      TD.innerText = value;
      TD.innerHTML = makeInnerHTML();

      const input = document.getElementById("iExp1");
      addFormatter(input, createRegexFormatter(/^TIE:/i, "TIE: "));

      TD.onkeydown = (e) => handleKeyDown(e, instance, row, column);

      instance.addHook("afterChange", (changes) => {
        if (changes) {
          const newVal = changes[0][3];

          if (newVal === "TIE") {
            instance.selectCell(row, column);
            input?.focus();
          }
        }
      });
    }
  );
};

function addFormatter(input: any, formatFn: any, callback?: any) {
  let oldValue = input.value;

  const handleInput = (event?: any) => {
    const result = formatFn(input.value, oldValue, event);
    if (typeof result === "string") {
      input.value = result;
    }

    oldValue = input.value;

    if (callback !== undefined) {
      callback(input, result);
    }
  };

  handleInput();
  input.addEventListener("input", handleInput);
}

function createRegexFormatter(regex: any, defaultValue: any) {
  return (newValue: any, oldValue: any) =>
    regex.test(newValue) ? newValue : newValue ? oldValue : defaultValue;
}
