import API from "@/api/internal";
import Canvas from "@/canvas";
import { Item, ItemType } from "ITDAModelTypes";

export const getNodeTitle = (diagramID: string, nodeID: string): string => {
  const editor = Canvas.getEditor(diagramID);
  const node = editor.getNode(nodeID);
  const item = API.getItem(node?.getItemID());
  return item ? API.getTitle(item) : "";
};
