import { Item, DomainKeys } from "@/model/types";

export type DomainToItemMap = {
  [K in DomainKeys]?: IDToItemMap;
};

export type GarbageToItemMap = {
  [K in GarbageKeys]?: IDToItemMap;
};

export type IDToItemMap = {
  [key in string]: Item;
};

export type DB = {
  top: DBSchemaItem;
  version: string;
  date: number;
  error: boolean;
  domain: {
    [K in DomainKeys]?: DBSchema;
  };
};

export type DBSchema = {
  sfrs?: DBSchemaSFR;
  tops: {
    [key in string]: DBSchemaItem;
  };
  items: {
    [key in string]: DBSchemaItem;
  };
};

export type DBSchemaSFR = {
  [key in string]?: {
    [key in string]: DBSchemaSFRMetadata;
  };
};

export type DBSchemaSFRMetadata = {
  startOffset: string;
  alignSize: string;
  size: string;
  offsetToItemID?: {
    [key in string]: string;
  };
};

export interface DBSchemaItem extends DBSchemaItemMetadata {
  pid?: string;
  id: string;
  itemType: string;
  domain: string;
  properties: Record<string, any>;
  _mappers: {
    type: string;
    sourceId: string;
    targetId: string;
  }[];
  _children: string[];
}

export interface DBSchemaItemMetadata {
  title: string;
  disabled: boolean;
  hidden: boolean;
  hiddenChildren: boolean;
}

export interface ExternalSpec {
  pll?: ExternalSpecPLL[];
  hm?: ExternalSpecHM[];
  generic?: ExternalSpecGeneric[];
}

export interface ExternalSpecPLL {
  name: string;
  formula: string;
  sfrs: {
    name: string;
    alignOffset: string;
    fields: {
      name: string;
      index: number;
      size: number;
      access: "RW" | "RO";
      initValue?: string;
      config?: boolean;
    }[];
  }[];
}

export interface ExternalSpecHM {
  name: string;
  modules: {
    name: string;
    powers: {
      name: string;
      voltage: number;
    }[];
  }[];
}

export interface ExternalSpecGeneric {
  name: string;
  modules: {
    name: string;
    syn: {
      sdc: {
        create_clock: {
          [key in string]: string;
        };
        exception: {
          [key in string]: string;
        };
      };
    };
  }[];
}

export enum GarbageLevel {
  FATAL = "FATAL",
  WARN = "WARN",
  INFO = "INFO",
}
export type GarbageKeys = keyof typeof GarbageType;
export class GarbageType {
  private index: number;
  private key: GarbageKeys;
  private title: string;
  private level: GarbageLevel;

  static PARENT = new GarbageType(
    0,
    "PARENT",
    "Parent is null",
    GarbageLevel.FATAL
  );
  static SFR = new GarbageType(
    1,
    "SFR",
    "SFR itemRef is null",
    GarbageLevel.FATAL
  );
  static SDC = new GarbageType(
    2,
    "SDC",
    "SDC itemRef is null",
    GarbageLevel.FATAL
  );
  static CONN = new GarbageType(
    2,
    "CONN",
    "CONN source or target is null",
    GarbageLevel.FATAL
  );
  static PORT = new GarbageType(
    2,
    "PORT",
    "PORT connection is null",
    GarbageLevel.WARN
  );
  static SEQ = new GarbageType(3, "SEQ", "SEQ Prop is null", GarbageLevel.WARN);
  static LABEL = new GarbageType(
    3,
    "LABEL",
    "Label/LabelRef itemRef is null",
    GarbageLevel.WARN
  );
  static NODE = new GarbageType(4, "NODE", "NodeID is null", GarbageLevel.INFO);
  static CONFIG = new GarbageType(
    4,
    "CONFIG",
    "Config reference is null",
    GarbageLevel.FATAL
  );

  private constructor(
    index: number,
    key: GarbageKeys,
    title: string,
    level: GarbageLevel
  ) {
    this.index = index;
    this.key = key;
    this.title = title;
    this.level = level;
  }

  static getKeys(): GarbageKeys[] {
    return Object.keys(this) as GarbageKeys[];
  }

  static getValues(): GarbageType[] {
    return Object.values(this);
  }

  static getEntries(): [string, GarbageType][] {
    return Object.entries(this);
  }

  static getObjByKey(key: GarbageKeys): GarbageType | undefined {
    return this[key] as GarbageType;
  }

  getKey() {
    return this.key;
  }

  getTitle() {
    return this.title;
  }

  getLevel() {
    return this.level;
  }
}
