import { NodeType } from "@/canvas/types";
import { Context } from "../types";
import API from "@/api/internal";
import Utils from "../utils";
import TopUtils from "@/utils";
import Canvas from "@/canvas";
import { Domain, ItemType } from "ITDAModelTypes";

export const nodecreated = async (context: Context): Promise<boolean> => {
  const area = Canvas.getAreaPlugin();
  const node = context.options.node;
  let item = API.getItem(node.getItemID());
  area.adjustNodePosToGrid();

  if (!item) {
    const itemType = TopUtils.getItemType(node);
    const diagram = API.getCurrentItem();
    let parent = diagram;
    if (API.getCurrentDomain() === Domain.UPF.getKey()) {
      parent = diagram?.getUPFPowerDomain();
    }
    if (itemType && parent) {
      if (API.getCurrentDomain() === Domain.DUT.getKey()) {
        item = await API.createItem(parent, itemType.getKey(), {
          build: true,
          type: node.getType().getSubType().getKey(),
        });
      } else {
        item = await API.createItem(parent, itemType.getKey(), {
          build: true,
        });
      }
    }
  }

  if (item) {
    const pItem = item.getParent();
    if (
      !pItem.isDiagramType() &&
      pItem.isNodeType() &&
      item.getItemType() != ItemType.SDCClockGroup
    ) {
      node.parent = pItem.getNodeID();
    }
    node.setItemID(item.getId());
    item.setBuild(true);
    item.setNodeID(node.id);
    item.setNodeType(node.getType().getKey());

    Utils.createNodePorts(node, item);
    switch (node.getType()) {
      case NodeType.ITDASEQSTART:
        {
          let name = node.getItemOptions().name;
          if (
            pItem.getItemRef()?.getItemType() === ItemType.PowerPMDInstance &&
            name === "START_NMI"
          ) {
            name = "START_IRQ";
          }
          item.setName(name);
        }
        break;
      case NodeType.ITDASEQIF:
      case NodeType.ITDASEQGOTO:
      case NodeType.ITDASEQCALL:
        await API.createSequenceLabelRef(item);
        break;
      default:
        break;
    }

    if (item.getItemType() === ItemType.SDCPhantom) {
      Utils.setSDCValue(item);
    }
  }
  return true;
};
