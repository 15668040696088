/**
 *  Generated by Modeler
 *  timestamp: 2024-09-04 14:00:26.406806
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class UPFPhantomPowerSourceEvent extends ItemEvent {
  constructor(item) {
    super(item);
  }

  postProcess(item) {
    const origin = item.getOriginItem();
    if (origin) {
      return origin.getEvent().setupPorts
        ? origin.getEvent().setupPorts(item)
        : [];
    }
  }
}
