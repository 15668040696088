import { DB, Item, ItemType } from "ITDAModelTypes";
import axios from "axios";
import { host, protocol } from "./config";

export const getSequenceLine = async (db: any): Promise<boolean> => {
  try {
    // const res = await axios.post(`${protocol}://${host}/io/save`, db, {
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    // });
    // return res && res.status === 200 ? true : false;
    let totalSize = 0;
    let currentSize = 0;
    const toDecMap = { "8KB": 8192, "4KB": 4096, "2KB": 2048, "1KB": 1024 };
    const pmcItem = db.getParent(ItemType.PowerPMC);
    // totalSize
    if (!db.getItemRef()) {
      if (db.getName() === "ROM") {
        const romSize = pmcItem.getNumOfROMSize() as keyof typeof toDecMap;
        totalSize = toDecMap[romSize];
      } else {
        const ramSize = pmcItem.getNumOfRAMSize() as keyof typeof toDecMap;
        totalSize = toDecMap[ramSize];
      }
    } else {
      for (const diagram of pmcItem
        .getPowerDiagramFolder()
        .getPowerDiagrams()) {
        const pmdInst = diagram
          .getPowerPMDInstances()
          .find((pmd: Item) => pmd.getName() === db.getName());
        if (pmdInst) {
          const pmdInput = pmdInst.getInputs();
          const ramSize = pmcItem.getNumOfRAMSize() as keyof typeof toDecMap;
          totalSize = toDecMap[ramSize];
          if (pmdInput.length) {
            const pmdGrp = pmdInput[0]
              .getConnections()[0]
              .getSourceOutput()
              .getParent();
            if (pmdGrp.getNumOfPMDs() > 1) {
              const div = (pmdGrp.getNumOfPMDs() - 1).toString(2).length;
              totalSize = (totalSize / Math.pow(2, div)) as number;
            }
          }
          break;
        }
      }
    }
    //currentSize
    currentSize += 8;
    for (const node of db.getChildren()) {
      if (
        node.getItemType() !== ItemType.PowerSequenceStart &&
        node.getItemType() !== ItemType.Connection
      ) {
        console.log(node, currentSize);
        if (needLoadStore(node)) {
          currentSize += 8;
        }
        switch (node.getItemType()) {
          case ItemType.PowerSequenceWrite:
            if (node.getSFRField()?.getSize() > 1) {
              currentSize += 8;
            } else {
              currentSize += 4;
            }
            break;
          case ItemType.PowerSequenceReadWait:
            if (node.getSFRField()?.getSize() > 1) {
              currentSize += 4 * node.getSFRField()?.getSize();
            } else {
              currentSize += 4;
            }
            break;
          case ItemType.PowerSequenceGoto:
            currentSize += 4;
            break;
          case ItemType.PowerSequenceEnd:
            currentSize += 4;
            break;
          case ItemType.PowerSequenceWait:
            if (!node.getValue()) {
              currentSize += 8;
            } else {
              currentSize += 4;
            }
            break;
          case ItemType.PowerSequenceIf:
            currentSize += 8;
            break;
          case ItemType.PowerSequenceCall:
            currentSize += 4;
            break;
          case ItemType.PowerSequenceBack:
            currentSize += 4;
            break;
          default:
            break;
        }
      }
    }
    console.log(totalSize, currentSize);
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
};

const needLoadStore = (node: Item) => {
  let need = false;
  if (node.getPMD && node.getPMD()) {
    const parentNode = getParentNode(node);
    if (!parentNode) {
      need = true;
    } else {
      if (node.getLabel()) {
        need = true;
      } else {
        if (parentNode.getPMD && parentNode.getPMD() !== node.getPMD()) {
          need = true;
        }
      }
    }
  }
  return need;
};

const getParentNode = (node: Item) => {
  const conn = node.getInputs()[0].getConnections();
  if (conn.length) {
    return conn[0].getSourceOutput().getParent();
  }
};
