/**
 *  Generated by Modeler
 *  timestamp: 2022-12-21 20:22:35.186401
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";
import ItemType from "../ItemType";

export default class ConfigurationChecker extends ItemChecker {
  constructor(item) {
    super(item);
    // this.addChecker(item.getFidName(), this.checkName, ErrorType.CONFIG)
    this.addChecker(item.getFidVoltage(), this.checkVoltage, ErrorType.CONFIG);
  }
  checkName() {
    // TBD
  }
  checkVoltage(item) {
    const vd = item.getParent(ItemType.VoltageDomain);
    if (vd) {
      if (item.getVoltage() === null || item.getVoltage() === undefined) {
        this.newError(
          `Please check the voltage value (${item.getVoltage()})`,
          item
        );
      }
    }
  }
}
