<template>
  <v-card ref="editor" class="editor" @contextmenu="showContextMenu($event)">
    <navigation-title :style="styleTitle" />
    <CanvasHeader :itemId="item?.getId()" :width="width" :height="height" />
    <div id="rete" ref="rete" class="rete" :style="style('rete')"></div>
    <CanvasEditorDock :key="key" />
    <CanvasPropertyBox :nodeItem="nodeItem" />
    <v-menu v-model="menuShow" :target="[menuPosX, menuPosY]">
      <v-list class="menu-list">
        <v-list-item
          v-for="([title, factory], idx) in menuList"
          :key="idx"
          class="menu-item"
          @click="createNode(title, factory)">
          <v-list-item-title>{{ title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </v-card>
</template>

<script setup lang="ts">
import { ref, defineEmits, computed, onMounted, onUpdated, watch } from "vue";
import { useStore } from "vuex";
import Canvas from "@/canvas";
import events from "@/events";
import { DiagramMapper } from "@/types";
import Utils from "@/utils";
import CanvasHeader from "./CanvasHeader.vue";
import NavigationTitle from "../navigation/NavigationTitle.vue";
import CanvasPropertyBox from "./CanvasPropertyBox.vue";
import { Item, ItemType } from "ITDAModelTypes";
import API from "@/api/internal";
import CanvasEditorDock from "./CanvasEditorDock.vue";
import { getDestructor, getFactoryByDiagram } from "@/canvas/plugins/utils";
import { Repository } from "@/canvas/store";
import { DiagramType } from "@/canvas/types";
import { ITDANode } from "@/canvas/nodes";

const rete = ref<HTMLElement | null>(null);
const key = ref(0);
const style = (className: string) => {
  switch (className) {
    case "rete":
      //   return { flexBasis: `100%` };
      return {};
    case "wave-form":
      return { flexBasis: `15%` };
    default:
      return;
  }
};

const styleTitle = computed(() => {
  const isNavOpened = store.getters["navigation/IS_NAV_OPENED"];
  return {
    display: isNavOpened ? "none" : "block",
  };
});

// updated values ref width, height
const width = ref<number>(0);
const height = ref<number>(0);
const store = useStore();
const item = API.getCurrentItem();

const navShow = ref(store.getters["navigation/IS_NAV_OPENED"]);
const nodeItem = ref(store.getters["item/GET_CURRENT_NODE_ITEM"]);

watch(
  () => store.getters["navigation/IS_NAV_OPENED"],
  (newVal: boolean) => {
    navShow.value = newVal;
    key.value += 1;
  }
);

watch(
  () => store.getters["item/GET_CURRENT_NODE_ITEM"],
  (item: Item) => {
    nodeItem.value = item;
  }
);

const createCanvas = async () => {
  if (rete.value) {
    try {
      store.commit("SET_IN_PROGRESS", "create canvas");
      Canvas.setImported(false);
      if (item) {
        let diagramType = getDiagramType();
        if (diagramType) {
          const editor = Canvas.createEditor(
            item.getId(),
            rete.value,
            diagramType
          );
          width.value = editor.width;
          height.value = editor.height;
          events.register(item.getId());
          await editor.importNodes(Utils.getEditorNodes(item), true);
          await editor.importConnections(
            Utils.getEditorConnections(item),
            true
          );
        }
      }

      Canvas.setImported(true);
    } catch (e) {
      console.error(e);
    } finally {
      store.commit("SET_DONE", "create canvas");
    }
  }
};

const repo = Repository.getInstance();
const menuShow = ref(false);
const menuList = ref<[string, any][]>([]);
let menuPosX = 0;
let menuPosY = 0;
let menuPosition = [0, 0];
const showContextMenu = (e: PointerEvent) => {
  e.preventDefault();
  menuPosX = e.clientX;
  menuPosY = e.clientY;
  menuShow.value = true;
  const area = repo.getAreaPlugin(repo.getCurrentID());
  const pointer = area.getArea().pointer;
  menuPosition = [pointer.x, pointer.y];
  getNodeCreators();
};

const getNodeCreators = () => {
  const targetNode = getNode(menuPosition[0], menuPosition[1]);
  if (targetNode) {
    menuList.value = getDestructor(targetNode);
  } else {
    let diagramType = getDiagramType();
    menuList.value = diagramType ? getFactoryByDiagram(diagramType) : [];
  }
};

const getNode = (x: number, y: number) => {
  const editor = repo.getEditor(repo.getCurrentID());
  return editor.getNodes().find((node: ITDANode) => {
    const view = node.getNodeView();
    if (view) {
      const left = view.position.x;
      const right = view.position.x + node.width;
      const top = view.position.y;
      const bottom = view.position.y + node.height;
      return left < x && x < right && top < y && y < bottom;
    }
  });
};

const getDiagramType = (): DiagramType | undefined => {
  if (item?.getItemType() === ItemType.PowerDiagram) {
    if (item?.getParent(ItemType.PowerPMDFolder)) {
      return DiagramMapper.getObjByModuleFolderItemType(
        item?.getParent(ItemType.PowerPMDFolder).getItemType()
      )?.getDiagramType();
    } else if (item?.getParent(ItemType.PowerPMCFolder)) {
      return DiagramMapper.getObjByModuleFolderItemType(
        item?.getParent(ItemType.PowerPMCFolder).getItemType()
      )?.getDiagramType();
    }
  } else {
    return DiagramMapper.getObjByDiagramItemType(
      item?.getItemType()
    )?.getDiagramType();
  }
};

const createNode = async (title: string, factory: any) => {
  if (typeof factory === "function") {
    if (title === "Delete") {
      await factory();
    } else {
      const editor = repo.getEditor(repo.getCurrentID());
      const node = await factory();
      await editor.addNode(node);
      const nodeView = node.getNodeView();
      if (nodeView) {
        await nodeView.translate(menuPosition[0], menuPosition[1]);
      }
    }
  }
};

onUpdated(() => (key.value += 1));

const emit = defineEmits(["mounted"]);
onMounted(async () => {
  await createCanvas();
  emit("mounted");
  key.value += 1;
});
</script>

<style lang="sass" scoped>
.editor
  top: 0%
  height: 100%
  .rete
    height: 100%
    width: 100%

.menu-list
  border: 1px solid lightgray
  border-radius: 10px
  padding: 0.25rem

  box-shadow: none !important
  filter: drop-shadow(-1px 6px 3px rgba(0, 0, 0, 0.15))
  .menu-item:hover
    border-radius: 5px !important
    cursor: pointer
    background-color: #d8eaf8
</style>
