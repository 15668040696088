/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerPMDInstanceEvent from '@/model/src/events/PowerPMDInstanceEvent'
import PowerPMDInstanceChecker from '@/model/src/checkers/PowerPMDInstanceChecker'
export default class PowerPMDInstance extends Node {
    FID_POWERPMDINSTANCE_NAME = 100
    FID_POWERPMDINSTANCE_MODULE = 101
    FID_POWERPMDINSTANCE_LAST = 101

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerPMDInstance"}))
        
        this.setEvent(new PowerPMDInstanceEvent(this))
        this.setChecker(new PowerPMDInstanceChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "PMD_NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerPMDInstance"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "PMD_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "module": {"type": "item", "label": "Module", "desc": "Module", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "PMD_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "module": {"type": "item", "label": "Module", "desc": "Module", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMDINSTANCE_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERPMDINSTANCE_NAME
    }
    

    setModule(module, event=true) {
        if ((module !== null) && (module !== undefined)) {
            if (!(module instanceof Item) && (module.constructor !== String)) {
                throw `module(${module}) must be Item or String type`
            }
        }
        const newVal = (module === null || module === undefined) ? undefined : (module.constructor === String) ? module : module.getId()
        const oldVal = this.properties.module
        this.properties.module = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMDINSTANCE_MODULE, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getModule(pure = false) {
        return pure ? this.properties.module : this.getRefItem(this.properties.module)
        
    }
    getFidModule() {
        return this.FID_POWERPMDINSTANCE_MODULE
    }
    

    getFidLast() {
        return this.FID_POWERPMDINSTANCE_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERPMDINSTANCE_NAME:
                return "name"

            case this.FID_POWERPMDINSTANCE_MODULE:
                return "module"

            default:
                return ""
        }
    }


}