import { DB } from "ITDAModelTypes";
import axios from "axios";
import { host, protocol } from "./config";

export const save = async (
  name: string,
  type: "design" | "config",
  db: DB
): Promise<boolean> => {
  try {
    const res = await axios.post(`${protocol}://${host}/io/save`, db, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        name: name,
        type: type,
      },
    });
    return res && res.status === 200 ? true : false;
  } catch (err) {
    console.log(err);
    return false;
  }
};
