<template>
  <div class="navigation-title-wrapper" :style="styleWrapper">
    <div class="navigation-title-border" :style="styleBorder">
      <div class="navigation-title-link">
        <v-img
          min-width="80px"
          max-width="80px"
          min-height="25px"
          max-height="25px"
          src="@/assets/itda_blue.svg"></v-img>
        <div
          v-if="store.getters['navigation/IS_NAV_OPENED']"
          class="navigation-title-link-router"
          @click="goHome">
          <v-sheet class="navigation-title">
            {{ title }}
          </v-sheet>
        </div>
        <div v-else>
          <v-sheet class="navigation-title navigation-closed">
            {{ title }}
          </v-sheet>
        </div>
      </div>
      <div class="navigation-title-button">
        <v-btn
          class="nav-toggle-icon"
          icon
          :style="styleIcon"
          @click="toggleNav">
          <v-img
            min-width="40px"
            max-width="40px"
            min-height="20px"
            max-height="20px"
            src="@/assets/navdock.svg"></v-img>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { Item } from "ITDAModelTypes";
import router from "@/router";

const store = useStore();
const title = ref(store.getters["item/GET_CURRENT_ITEM"]?.getTitle());
const isBtnEnabled = ref(
  store.getters["item/GET_CURRENT_ITEM"]?.isDiagramType()
);

watch(
  () => store.getters["navigation/IS_NAV_OPENED"],
  (newVal: boolean) => {
    if (newVal) {
      title.value = `${store.getters["domain/GET_CURRENT_DOMAIN"]} Canvas`;
    } else {
      const nodeItem = store.getters["item/GET_CURRENT_ITEM"];
      title.value = nodeItem ? nodeItem.getTitle() : "";
    }
  }
);

watch(
  () => store.getters["domain/GET_CURRENT_DOMAIN"],
  (newVal: string) => {
    if (store.getters["navigation/IS_NAV_OPENED"]) {
      title.value = `${newVal} Canvas`;
    }
  }
);

watch(
  () => store.getters["item/GET_CURRENT_ITEM"],
  (nodeItem: Item) => {
    if (!store.getters["navigation/IS_NAV_OPENED"]) {
      title.value = nodeItem ? nodeItem.getTitle() : "";
    }
    if (nodeItem) {
      isBtnEnabled.value = nodeItem.isDiagramType();
    } else {
      isBtnEnabled.value = false;
    }
  }
);

const styleWrapper = computed(() => {
  const isNavOpened = store.getters["navigation/IS_NAV_OPENED"];
  return {
    position: isNavOpened ? "relative" : "absolute",
    width: isNavOpened ? "100%" : "",
    height: isNavOpened ? "44px" : "50px",
    top: isNavOpened ? "0" : "10px",
    left: isNavOpened ? "0" : "10px",
    zIndex: isNavOpened ? "0" : "1",
  };
});

const styleBorder = computed(() => {
  const isNavOpened = store.getters["navigation/IS_NAV_OPENED"];
  return {
    height: "inherit",
    border: isNavOpened ? "" : "1.5px solid #CCCCCC",
    borderRadius: isNavOpened ? "" : "5px",
    backgroundColor: "white",
    filter: isNavOpened ? "" : "drop-shadow(-1px 6px 3px rgba(0, 0, 0, 0.15))",
  };
});

const styleIcon = computed(() => {
  return {
    opacity: isBtnEnabled.value ? 1 : 0.5,
    cursor: isBtnEnabled.value ? "" : "not-allowed",
  };
});

const toggleNav = () => {
  if (isBtnEnabled.value) {
    store.commit("navigation/TOGGLE_NAV");
  }
};

const goHome = async () => {
  store.commit("item/SET_CURRENT_ITEM", undefined);
  await router.push({
    path: "/",
  });
  store.commit("navigation/SET_SELECTED_KEYS", undefined);
};
</script>

<style lang="sass" scoped>
.navigation-title-wrapper
  //width: 100%
  position: relative
  display: flex
  align-items: center

  .navigation-title-border
    display: flex
    width: inherit
    background: white
    justify-content: space-between
    //border-radius: 8px
    align-items: center

    .navigation-title-link
      padding-left: 0.8rem
      display: flex
      align-items: center
      //width: 80%

      .navigation-title-link-router
        text-decoration: none
        cursor: pointer

      .navigation-title
        font-family: Roboto, sans-serif
        font-weight: 450
        font-size: 1rem
        padding-left: 1rem

        &.navigation-closed
          font-size: 16px
          font-weight: normal


    .navigation-title-button
      border-radius: 8px
      .nav-toggle-icon
        width: 40px
        height: 40px
        margin: 5px
</style>

<style lang="css" scoped>
.v-btn.v-btn--elevated {
  border: 0px;
  box-shadow: none;
}
</style>
