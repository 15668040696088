import { ItemType } from "ITDAModelTypes";
import SFRHandler from "../../SFRHandler";
import ItemHandler from "../../ItemHandler";

export const updateAPMChildren = async (parent) => {
  const res = [];
  const sfrs = parent
    .getMappers()
    .filter((mapper) => {
      return (
        mapper.getTarget() === parent &&
        mapper.getSource().getItemType() === ItemType.SFR
      );
    })
    .map((mapper) => {
      return mapper.getSource();
    });
  const spec = parent.getSFRSpec("FULL");
  for (let i = 0; i < sfrs.length; i++) {
    const sfr = sfrs[i];

    let promises = [];
    sfr
      .getSFRFields()
      .forEach((sfrField) =>
        promises.push(ItemHandler.removeItem(sfrField).promise)
      );
    await Promise.all(promises);

    const sfrSpec = spec.sfrs[i];
    for (let j = 0; j < sfrSpec.fields.length; j++) {
      parent.getPowerAPMPWRDOWNs().forEach((pwrDown, idx) => {
        const sfrField = SFRHandler.createSFRField(sfr, sfrSpec.fields[j]);
        const name =
          sfrSpec.fields[j].name +
          `_${pwrDown.getName() ? pwrDown.getName() : idx}`;
        sfrField.setName(name);
        sfrField.setIndex(idx);
      });

      parent.getPowerAPMPWRUPs().forEach((pwrUp, idx) => {
        const sfrField = SFRHandler.createSFRField(sfr, sfrSpec.fields[j]);
        const name =
          sfrSpec.fields[j].name +
          `_${
            pwrUp.getName()
              ? pwrUp.getName()
              : parent.getMaxNumOfPWRDOWN() + idx
          }`;
        sfrField.setName(name);
        sfrField.setIndex(parent.getMaxNumOfPWRDOWN() + idx);
      });
    }
  }
  return res;
};
