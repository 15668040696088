import ITDANode from "./ITDANode";
import * as ITDANodeRenderer from "./renderers";
import { NodeType } from "../types";

export default class ITDAUPFLEVELSHIFTER extends ITDANode {
  constructor(name = "") {
    super(name, NodeType.ITDAUPFLEVELSHIFTER);
    this.render = ITDANodeRenderer.ITDAUPFLEVELSHIFTER.render;
  }

  set width(data: number) {
    super.width = this.adjustNodeToGrid(data);
  }

  get width() {
    const nodeStroke = this._nodeStroke;
    return this.adjustNodeToGrid(super.width) + nodeStroke / 2 + nodeStroke * 2;
  }

  set height(data: number) {
    super.height = this.adjustNodeToGrid(data);
  }

  get height() {
    const nodeStroke = this._nodeStroke;
    return this.adjustNodeToGrid(super.height) + nodeStroke * 3;
  }
}
