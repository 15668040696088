import Model from "ITDAModel";
import {
  ItemType,
  Item,
  ConfigurationBase,
  ConfigurationFreqeuncy,
  ConfigurationMapType,
} from "ITDAModelTypes";
export default {
  getConfigurationTitle: (item: Item): string | undefined => {
    return Model.getConfigurationTitle(item);
  },
  isConfigurationTarget: (item: Item): boolean => {
    return Model.isConfigurationTarget(item);
  },
  getConfigurationFolder: (item: Item): Item | undefined => {
    return Model.getConfigurationFolder(item);
  },
  getSelectedCMUConfiguration: (item: Item): Item | undefined => {
    return Model.getSelectedCMUConfiguration(item);
  },
  getCMUConfigurationElements: (item: Item) => {
    return Model.getCMUConfigurationElements(item);
  },
  getSelectedPMDConfiguration: (item: Item): Item | undefined => {
    return Model.getSelectedPMUConfiguration(item, ItemType.PowerPMD);
  },
  getSelectedPMCConfiguration: (item: Item): Item | undefined => {
    return Model.getSelectedPMUConfiguration(item, ItemType.PowerPMC);
  },
  getPMDConfigurationElements: (item: Item) => {
    return Model.getPMUConfigurationElements(item, ItemType.PowerPMD);
  },
  getPMCConfigurationElements: (item: Item) => {
    return Model.getPMUConfigurationElements(item, ItemType.PowerPMC);
  },
  getSelectedVoltageConfiguration: (item: Item): Item | undefined => {
    return Model.getSelectedVoltageConfiguration(item);
  },
  getCurrentConfigurationElements: (item: Item, parentItem: Item): Item[] => {
    return Model.getCurrentConfigurationElements(item, parentItem);
  },

  setConfigurationMap: (baseObj: ConfigurationBase): void => {
    Model.repo.getConfiguration().setConfigurationBase(baseObj);
  },

  setConfigurationFrequency: (
    itemID: string,
    freqObj: ConfigurationFreqeuncy
  ): void => {
    Model.repo.getConfiguration().setConfigurationFrequency(itemID, freqObj);
  },

  getConfigurationMap: (): ConfigurationMapType => {
    return Model.repo.getConfiguration().getConfigurationMap();
  },

  getConfigurationRules: (item: Item, prop: string) => {
    return Model.getConfigurationRules(item, prop);
  },
};
