/**
 *  Generated by Modeler
 *  timestamp: 2023-04-10 14:54:51.192863
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class UPFGroundEvent extends ItemEvent {
  constructor(item) {
    super(item);
  }
}
