import { DiagramType, NodeType } from "./canvas/types";
import { Module, ItemType } from "ITDAModelTypes";

export class NodeMapper {
  private index: number;
  private nodeType: NodeType;
  private ItemType: ItemType;

  // Clock Comp
  static ITDABUFFER = new NodeMapper(
    0,
    NodeType.ITDABUFFER,
    ItemType.ClockBuffer
  );
  static ITDADIVIDER = new NodeMapper(
    0,
    NodeType.ITDADIVIDER,
    ItemType.ClockDivider
  );
  static ITDAFREQMON = new NodeMapper(
    0,
    NodeType.ITDAFREQMON,
    ItemType.ClockFreqmon
  );
  static ITDAGATE = new NodeMapper(0, NodeType.ITDAGATE, ItemType.ClockGate);
  static ITDALABEL = new NodeMapper(0, NodeType.ITDALABEL, ItemType.Label);
  static ITDALABELREF = new NodeMapper(
    0,
    NodeType.ITDALABELREF,
    ItemType.LabelRef
  );
  static ITDAGFMUX = new NodeMapper(
    0,
    NodeType.ITDAGFMUX,
    ItemType.ClockMultiplexer
  );
  static ITDANMDIVIDER = new NodeMapper(
    0,
    NodeType.ITDANMDIVIDER,
    ItemType.ClockNMDivider
  );
  static ITDAPLLCTRL = new NodeMapper(
    0,
    NodeType.ITDAPLLCTRL,
    ItemType.ClockPLLCtrl
  );
  static ITDAREFCLKMUX = new NodeMapper(
    0,
    NodeType.ITDAREFCLKMUX,
    ItemType.ClockRefCLKMultiplexer
  );
  static ITDASOURCE = new NodeMapper(
    0,
    NodeType.ITDASOURCE,
    ItemType.ClockExternalCLKSource
  );

  static ITDAQCH = new NodeMapper(
    0,
    NodeType.ITDAQCH,
    ItemType.ClockQChannelManager
  );
  static ITDAQCHINFO = new NodeMapper(
    0,
    NodeType.ITDAQCHINFO,
    ItemType.ClockQChannelInfo
  );

  static ITDADFTOCCBUFFER = new NodeMapper(
    0,
    NodeType.ITDADFTOCCBUFFER,
    ItemType.ClockDFTOCCBuffer
  );

  // Power Comp
  static ITDAAPM = new NodeMapper(0, NodeType.ITDAAPM, ItemType.PowerAPM);
  static ITDAREFCLKEN = new NodeMapper(
    0,
    NodeType.ITDAREFCLKEN,
    ItemType.PowerREFCLKEN
  );
  static ITDAPSW = new NodeMapper(0, NodeType.ITDAPSW, ItemType.PowerPSW);
  static ITDAPWREN = new NodeMapper(0, NodeType.ITDAPWREN, ItemType.PowerPWREN);
  static ITDAISOEN = new NodeMapper(0, NodeType.ITDAISOEN, ItemType.PowerISOEN);
  static ITDAMEM = new NodeMapper(0, NodeType.ITDAMEM, ItemType.PowerMEM);
  static ITDARETENTION = new NodeMapper(
    0,
    NodeType.ITDARETENTION,
    ItemType.PowerRETENTION
  );
  static ITDAOTP = new NodeMapper(0, NodeType.ITDAOTP, ItemType.PowerOTP);
  static ITDAHANDSHAKE = new NodeMapper(
    0,
    NodeType.ITDAHANDSHAKE,
    ItemType.PowerHANDSHAKE
  );
  static ITDACLINK = new NodeMapper(0, NodeType.ITDACLINK, ItemType.PowerCLINK);
  static ITDAPCH = new NodeMapper(0, NodeType.ITDAPCH, ItemType.PowerPCH);
  static ITDARESET = new NodeMapper(0, NodeType.ITDARESET, ItemType.PowerRESET);
  static ITDAUSERDEFOUT = new NodeMapper(
    0,
    NodeType.ITDAUSERDEFOUT,
    ItemType.PowerUSERDEFOUT
  );
  static ITDAUSERDEFIN = new NodeMapper(
    0,
    NodeType.ITDAUSERDEFIN,
    ItemType.PowerUSERDEFIN
  );
  static ITDAINFORM = new NodeMapper(
    0,
    NodeType.ITDAINFORM,
    ItemType.PowerPMRINFORM
  );
  static ITDATIMEOUT = new NodeMapper(
    0,
    NodeType.ITDATIMEOUT,
    ItemType.PowerPMRTIMEOUT
  );
  static ITDAPMRCTRL = new NodeMapper(
    0,
    NodeType.ITDAPMRCTRL,
    ItemType.PowerPMRCTRL
  );
  static ITDALATCH = new NodeMapper(
    0,
    NodeType.ITDALATCH,
    ItemType.PowerPMRLATCH
  );
  static ITDAINTRSFR = new NodeMapper(
    0,
    NodeType.ITDAINTRSFR,
    ItemType.PowerPMRINTRSFR
  );
  static ITDAINTREXT = new NodeMapper(
    0,
    NodeType.ITDAINTREXT,
    ItemType.PowerPMRINTREXT
  );
  static ITDATIMER = new NodeMapper(
    0,
    NodeType.ITDATIMER,
    ItemType.PowerPMRTIMERGRP
  );
  static ITDAPMDGRP = new NodeMapper(
    0,
    NodeType.ITDAPMDGRP,
    ItemType.PowerPMRPMDGRP
  );

  // Sequence
  static ITDASEQSTART = new NodeMapper(
    0,
    NodeType.ITDASEQSTART,
    ItemType.PowerSequenceStart
  );
  static ITDASEQWRITE = new NodeMapper(
    0,
    NodeType.ITDASEQWRITE,
    ItemType.PowerSequenceWrite
  );
  static ITDASEQREADWAIT = new NodeMapper(
    0,
    NodeType.ITDASEQREADWAIT,
    ItemType.PowerSequenceReadWait
  );
  static ITDASEQWAIT = new NodeMapper(
    0,
    NodeType.ITDASEQWAIT,
    ItemType.PowerSequenceWait
  );
  static ITDASEQIF = new NodeMapper(
    0,
    NodeType.ITDASEQIF,
    ItemType.PowerSequenceIf
  );
  static ITDASEQGOTO = new NodeMapper(
    0,
    NodeType.ITDASEQGOTO,
    ItemType.PowerSequenceGoto
  );
  static ITDASEQCALL = new NodeMapper(
    0,
    NodeType.ITDASEQCALL,
    ItemType.PowerSequenceCall
  );
  static ITDASEQEND = new NodeMapper(
    0,
    NodeType.ITDASEQEND,
    ItemType.PowerSequenceEnd
  );
  static ITDASEQLABEL = new NodeMapper(
    0,
    NodeType.ITDASEQLABEL,
    ItemType.PowerSequenceLabel
  );
  static ITDASEQLABELREF = new NodeMapper(
    0,
    NodeType.ITDASEQLABELREF,
    ItemType.PowerSequenceLabelRef
  );
  static ITDASEQBACK = new NodeMapper(
    0,
    NodeType.ITDASEQBACK,
    ItemType.PowerSequenceBack
  );

  // Instance
  static ITDAINSTANCEIP = new NodeMapper(
    0,
    NodeType.ITDAINSTANCEIP,
    ItemType.ClockIP
  );
  static ITDAINSTANCEPMD = new NodeMapper(
    0,
    NodeType.ITDAINSTANCEPMD,
    ItemType.PowerPMDInstance
  );
  static ITDAINSTANCESDC = new NodeMapper(
    0,
    NodeType.ITDAINSTANCESDC,
    ItemType.SDCInstance
  );
  static ITDAINSTANCESDCPHANTOMGROUP = new NodeMapper(
    0,
    NodeType.ITDAINSTANCESDCPHANTOMGROUP,
    ItemType.SDCPhantomGroup
  );
  static ITDAMODULEUPF = new NodeMapper(
    0,
    NodeType.ITDAMODULEUPF,
    ItemType.UPFPowerDomain
  );
  static ITDAINSTANCEBLOCK = new NodeMapper(
    0,
    NodeType.ITDAINSTANCEBLOCK,
    ItemType.DUTInstance
  );
  static ITDAINSTANCECMU = new NodeMapper(
    0,
    NodeType.ITDAINSTANCECMU,
    ItemType.DUTInstance
  );
  static ITDAINSTANCEPMC = new NodeMapper(
    0,
    NodeType.ITDAINSTANCEPMC,
    ItemType.DUTInstance
  );
  static ITDAINSTANCEPMIF = new NodeMapper(
    0,
    NodeType.ITDAINSTANCEPMIF,
    ItemType.DUTInstance
  );
  static ITDAINSTANCEPOWERDOMAIN = new NodeMapper(
    0,
    NodeType.ITDAINSTANCEPOWERDOMAIN,
    ItemType.UPFPowerDomainInstance
  );
  static ITDAUPFPOWERSOURCE = new NodeMapper(
    0,
    NodeType.ITDAUPFPOWERSOURCE,
    ItemType.UPFPowerSource
  );

  static ITDAUPFGROUND = new NodeMapper(
    0,
    NodeType.ITDAUPFGROUND,
    ItemType.UPFGround
  );

  static ITDAINSTANCEHARDMACRO = new NodeMapper(
    0,
    NodeType.ITDAINSTANCEHARDMACRO,
    ItemType.UPFHardMacro
  );
  static ITDAUPFLEVELSHIFTER = new NodeMapper(
    0,
    NodeType.ITDAUPFLEVELSHIFTER,
    ItemType.UPFLevelShifter
  );

  static ITDAUPFPHANTOMPOWERSOURCE = new NodeMapper(
    0,
    NodeType.ITDAUPFPHANTOMPOWERSOURCE,
    ItemType.UPFPhantomPowerSource
  );

  static ITDAUPFPHANTOMGROUND = new NodeMapper(
    0,
    NodeType.ITDAUPFPHANTOMGROUND,
    ItemType.UPFPhantomGround
  );

  static ITDASDCCLOCKGROUP = new NodeMapper(
    0,
    NodeType.ITDASDCCLOCKGROUP,
    ItemType.SDCClockGroup
  );

  private constructor(index: number, nodeType: NodeType, ItemType: ItemType) {
    this.index = index;
    this.nodeType = nodeType;
    this.ItemType = ItemType;
  }

  static getKeys(): string[] {
    return Object.keys(this);
  }

  static getValues(): NodeMapper[] {
    return Object.values(this);
  }

  static getEntries(): [string, NodeMapper][] {
    return Object.entries(this);
  }

  static getObjByKey(key: keyof typeof NodeMapper): NodeMapper | undefined {
    return this[key] as NodeMapper;
  }

  static getObjByItemType(type: ItemType): NodeMapper | undefined {
    return this.getValues().find((o) => o.getItemType() === type);
  }

  getNodeType() {
    return this.nodeType;
  }

  getItemType() {
    return this.ItemType;
  }
}

export class DiagramMapper {
  private index: number;
  private dType: DiagramType;
  private moduleFolder: ItemType;
  private diagramFolder: ItemType;
  private diagram: ItemType;

  static CMU = new DiagramMapper(
    0,
    DiagramType.CMU,
    ItemType.ClockCMUFolder,
    ItemType.ClockDiagramFolder,
    ItemType.ClockDiagram
  );
  static IP = new DiagramMapper(
    1,
    DiagramType.IP,
    ItemType.ClockCMUFolder,
    ItemType.ClockCMU,
    ItemType.ClockIPDiagram
  );

  static PMD = new DiagramMapper(
    0,
    DiagramType.PMD,
    ItemType.PowerPMDFolder,
    ItemType.PowerDiagramFolder,
    ItemType.PowerDiagram
  );
  static PMC = new DiagramMapper(
    0,
    DiagramType.PMC,
    ItemType.PowerPMCFolder,
    ItemType.PowerDiagramFolder,
    ItemType.PowerDiagram
  );
  static SEQ = new DiagramMapper(
    1,
    DiagramType.SEQ,
    ItemType.PowerPMCFolder,
    ItemType.PowerSequenceConfiguration,
    ItemType.PowerSequenceDiagram
  );

  static DUT = new DiagramMapper(
    0,
    DiagramType.DUT,
    ItemType.DUTModuleFolder,
    ItemType.DUTModule,
    ItemType.DUTModuleDiagram
  );

  static SDC = new DiagramMapper(
    0,
    DiagramType.SDC,
    ItemType.SDCModuleFolder,
    ItemType.SDCModule,
    ItemType.SDCDiagram
  );
  static UPF = new DiagramMapper(
    0,
    DiagramType.UPF,
    ItemType.UPFModuleFolder,
    ItemType.UPFModule,
    ItemType.UPFDiagram
  );

  private constructor(
    index: number,
    dType: DiagramType,
    moduleFolder: ItemType,
    diagramFolder: ItemType,
    diagram: ItemType
  ) {
    this.index = index;
    this.dType = dType;
    this.moduleFolder = moduleFolder;
    this.diagramFolder = diagramFolder;
    this.diagram = diagram;
  }

  static getKeys(): string[] {
    return Object.keys(this);
  }

  static getValues(): DiagramMapper[] {
    return Object.values(this);
  }

  static getEntries(): [string, DiagramMapper][] {
    return Object.entries(this);
  }

  static getObjByDiagramType(dType: DiagramType): DiagramMapper | undefined {
    return DiagramMapper.getValues().find(
      (o) => o.getDiagramType() === dType
    ) as DiagramMapper;
  }

  static getObjByModuleFolderItemType(
    moduleFolder: ItemType
  ): DiagramMapper | undefined {
    const res = DiagramMapper.getValues().filter(
      (o) => o.getModuleFolderItemType() === moduleFolder
    );
    if (res && res.length) {
      return res.reduce((acc, cur) => {
        return cur.index < acc.index ? cur : acc;
      }, res[0]);
    }
  }

  static getObjByDiagramFolderItemType(
    diagramFolder: ItemType
  ): DiagramMapper | undefined {
    return DiagramMapper.getValues().find(
      (o) => o.getDiagramFolderItemType() === diagramFolder
    ) as DiagramMapper;
  }

  static getObjByDiagramItemType(diagram: ItemType): DiagramMapper | undefined {
    return DiagramMapper.getValues().find(
      (o) => o.getDiagramItemType() === diagram
    ) as DiagramMapper;
  }

  getDiagramType() {
    return this.dType;
  }

  getModuleFolderItemType() {
    return this.moduleFolder;
  }

  getDiagramFolderItemType() {
    return this.diagramFolder;
  }

  getDiagramItemType() {
    return this.diagram;
  }
}

export class ModuleMapper {
  private index: number;
  private key: string;
  private mType: Module;
  private type: ItemType;

  static CMU = new ModuleMapper(0, "CMU", Module.CMU, ItemType.ClockCMU);
  static PMD = new ModuleMapper(0, "PMD", Module.PMD, ItemType.PowerPMD);
  static PMC = new ModuleMapper(0, "PMC", Module.PMC, ItemType.PowerPMC);
  static WrapperDFT = new ModuleMapper(
    0,
    "WrapperDFT",
    Module.WrapperDFT,
    ItemType.WrapperDFT
  );
  static DUT = new ModuleMapper(0, "DUT", Module.DUT, ItemType.DUTModule);
  static Voltage = new ModuleMapper(
    0,
    "Voltage",
    Module.Voltage,
    ItemType.VoltageDomain
  );
  static SDC = new ModuleMapper(0, "SDC", Module.SDC, ItemType.SDCModule);
  static UPF = new ModuleMapper(0, "UPF", Module.UPF, ItemType.UPFModule);

  private constructor(
    index: number,
    key: string,
    mType: Module,
    type: ItemType
  ) {
    this.index = index;
    this.key = key;
    this.mType = mType;
    this.type = type;
  }

  static getKeys(): string[] {
    return Object.keys(this);
  }

  static getValues(): ModuleMapper[] {
    return Object.values(this);
  }

  static getEntries(): [string, ModuleMapper][] {
    return Object.entries(this);
  }

  static getObjByKey(key: keyof typeof ModuleMapper): ModuleMapper | undefined {
    return this[key] as ModuleMapper;
  }

  getModuleType() {
    return this.mType;
  }

  getItemType() {
    return this.type;
  }
}
