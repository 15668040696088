import * as d3 from "d3";

export default {
  render: (
    elem: HTMLElement,
    width: number,
    height: number,
    color: string,
    options: any
  ) => {
    const { isDocked, configValue } = options;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", isDocked ? width * 0.55 : width)
      .attr("height", isDocked ? height * 0.7 : height)
      .style("-webkit-user-select", "none")
      .style("-moz-user-select", "none")
      .style("-ms-user-select", "none")
      .style("user-select", "none")
      .style("pointer-events", "none");

    const drawerGroup = svg
      .append("g")
      .attr("transform", isDocked ? `scale(0.55, 0.7)` : `scale(1)`);

    const baseColor = color;
    const hoverColor = d3.hsl(baseColor);
    hoverColor.l = Math.min(hoverColor.l + 0.05, 1);

    const component = drawerGroup
      .append("rect")
      .attr("width", width)
      .attr("height", height)
      .attr("fill", baseColor);

    component
      .on("mouseover", function () {
        d3.select(this).attr("fill", hoverColor.toString());
      })
      .on("mouseout", function () {
        d3.select(this).attr("fill", baseColor);
      });

    const grid = 12;
    const margin = 2;
    const gridHeight = height / grid;
    const gridWidth = width / grid;

    const points = [
      { x: gridWidth * 9, y: gridHeight * 1 },
      { x: gridWidth * 11, y: gridHeight * 3 },
      {
        x: gridWidth * 11,
        y: gridHeight * 9,
      },
      { x: gridWidth * 9, y: gridHeight * 11 },
    ];

    drawerGroup
      .append("polygon")
      .attr("points", points.map((p) => `${p.x},${p.y}`).join(" "))
      .attr("fill", "none")
      .attr("stroke", "black");

    drawerGroup
      .append("line")
      .attr("x1", gridWidth * 11)
      .attr("y1", gridHeight * 6)
      .attr("x2", gridWidth * 12)
      .attr("y2", gridHeight * 6)
      .attr("stroke", "black");

    drawerGroup
      .append("rect")
      .attr("x", 0)
      .attr("y", gridHeight * 6)
      .attr("width", gridWidth * 8)
      .attr("height", gridHeight * 6)
      .attr("fill", "white");
    drawerGroup
      .append("line")
      .attr("x1", 0)
      .attr("y1", gridHeight * 3)
      .attr("x2", gridWidth * 9)
      .attr("y2", gridHeight * 3)
      .attr("stroke", "black");

    drawerGroup
      .append("line")
      .attr("x1", gridWidth * 1)
      .attr("y1", gridHeight * 3)
      .attr("x2", gridWidth * 1)
      .attr("y2", gridHeight * 9)
      .attr("stroke", "black");

    drawerGroup
      .append("line")
      .attr("x1", gridWidth * 1)
      .attr("y1", gridHeight * 9)
      .attr("x2", gridWidth * 9)
      .attr("y2", gridHeight * 9)
      .attr("stroke", "black");

    drawerGroup
      .append("rect")
      .attr("x", gridWidth * 3)
      .attr("y", gridHeight * 8)
      .attr("width", gridWidth * 4)
      .attr("height", gridHeight * 2)
      .attr("fill", "white")
      .attr("stroke", "black");

    drawerGroup
      .append("text")
      .attr("x", gridWidth * 5)
      .attr("y", gridHeight * 9)
      .attr("text-anchor", "middle")
      .attr("dominant-baseline", "middle")
      .attr("font-size", "12px")
      .attr("fill", "black")
      .text("PLL");

    drawerGroup
      .append("text")
      .attr("x", gridWidth * 2)
      .attr("y", gridHeight * 9 - margin)
      .attr("text-anchor", "middle")
      .attr("font-size", "9px")
      .attr("fill", "black")
      .text("FIN");

    drawerGroup
      .append("text")
      .attr("x", gridWidth * 8)
      .attr("y", gridHeight * 9 - margin)
      .attr("text-anchor", "middle")
      .attr("font-size", "9px")
      .attr("fill", "black")
      .text("FOUT");

    if (!isDocked) {
      if (configValue) {
        drawerGroup
          .append("line")
          .attr("x1", gridWidth * 9 + margin * 2)
          .attr("y1", gridHeight * 8 + margin * 3)
          .attr("x2", gridWidth * 11)
          .attr("y2", gridHeight * 6)
          .attr("stroke", "red")
          .attr("stroke-width", 2);
      } else {
        drawerGroup
          .append("line")
          .attr("x1", gridWidth * 9 + margin * 2)
          .attr("y1", gridHeight * 4 - margin * 2)
          .attr("x2", gridWidth * 11)
          .attr("y2", gridHeight * 6)
          .attr("stroke", "red")
          .attr("stroke-width", 2);
      }
    }
  },
};
