import Handsontable from "handsontable";
import { TableRendererDFT } from "../types";

export default () => {
  Handsontable.renderers.registerRenderer(
    TableRendererDFT.readOnlyRenderer,
    (
      instance: Handsontable.Core,
      TD: HTMLTableCellElement,
      row: number,
      column: number,
      prop: string | number,
      value: any,
      cellProperties: Handsontable.CellProperties
    ): void => {
      Handsontable.renderers.TextRenderer(
        instance,
        TD,
        row,
        column,
        prop,
        value,
        cellProperties
      );
      TD.style.background = "#C97064";
      TD.style.color = "black";
    }
  );
};
