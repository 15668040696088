import { ClassicPreset } from "rete";
import ITDAControl from "./ITDAControl";
import { ITDASignalType } from "../common/types";

import * as ITDAControlRenderer from "./renderers";
import ITDAControlSignal from "./ITDAControlSignal";

export default class ITDAControlDynamicSignal extends ITDAControl {
  private node: ClassicPreset.Node;
  private type!: ITDASignalType;
  private signals: ITDAControlSignal[] = [];
  constructor(parent: ClassicPreset.Node) {
    super();
    this.node = parent;
    this.render = ITDAControlRenderer.ITDAControlSignal.render;
  }

  public setType(type: ITDASignalType) {
    this.type = type;
  }

  public getType(): ITDASignalType {
    return this.type;
  }

  public newSignal(name: string, direction: string) {
    this.signals.push(new ITDAControlSignal(name, direction));
  }

  public getSignals(): ITDAControlSignal[] {
    return this.signals;
  }
}
