/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";

export default class PowerSequenceLabelRefChecker extends ItemChecker {
  constructor(item) {
    super(item);
    this.addChecker(
      item.getFidItemLabel(),
      this.checkItemLabel,
      ErrorType.SEQUENCE
    );
    //this.addChecker(item.getFidLabels(), this.checkLabels, ErrorType.NAME)
  }
  checkItemLabel(item) {
    const label = item.getItemLabel();
    if (!label) {
      this.newError(`Sequence Label must be selected`, item);
    }
  }
  checkLabels() {
    // TBD
  }
}
