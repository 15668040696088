<template>
  <v-footer color="lightgray" width="100%" class="s-header">
    <v-system-bar color="white" window
      ><v-card>
        <HeaderSystemDomain
          :key="`${store.getters['component/GET_HEADER_SYSTEM_DOMAIN_KEY']}`"
          :keyValue="`${store.getters['component/GET_HEADER_SYSTEM_DOMAIN_KEY']}`"
          class="header-item" />
      </v-card>
      <HeaderSystemTasks class="header-item" />
    </v-system-bar>
  </v-footer>
</template>
<script setup lang="ts">
import HeaderSystemDomain from "./HeaderSystemDomain.vue";
import HeaderSystemTasks from "./HeaderSystemTasks.vue";
import { useStore } from "vuex";
import { useTheme } from "vuetify";
const store = useStore();
const theme = useTheme();
</script>

<style lang="sass" scoped>
.v-footer
  min-width: 400px
  z-index: 1999 !important
  display: flex
  flex-wrap: wrap
  //height:  2.3125rem !important

.header-item
  flex: 1 1 40%
  //height: 2.3125rem !important


.s-header
    z-index: 1009
    justify-content: flex-start
    display: inline-flex !important
    user-select: none

.v-system-bar
  top: 0 !important
  overflow: hidden
  height: inherit !important
  //height: 37px !important
  border: 1.5px solid lightgray
  display: flex
  flex-wrap: wrap
  font-size: .875rem
  font-family: Roboto
  font-weight: 400
  left: 0 !important
  justify-content: space-between
  background-color: unset
  width: 100% !important
  filter: drop-shadow(-1px 6px 3px rgba(0, 0, 0, 0.01))

.v-card
  left: 0 !important
  border-radius: unset

.v-card--variant-elevated
  box-shadow: unset
</style>
