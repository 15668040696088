import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;
    const innerStroke = 2;
    const radius = width / 16;

    g.append("line")
      .attr("x1", (centerX / 4) * 2.5)
      .attr("y1", (centerY / 4) * 2.5)
      .attr("x2", (centerX / 4) * 7)
      .attr("y2", (centerY / 4) * 2.5)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 4.5)
      .attr("y1", (centerY / 4) * 3.5)
      .attr("x2", (centerX / 4) * 6)
      .attr("y2", (centerY / 4) * 3.5)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 4.5)
      .attr("y1", (centerY / 4) * 5.5)
      .attr("x2", (centerX / 4) * 6)
      .attr("y2", (centerY / 4) * 5.5)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 4.5)
      .attr("y1", (centerY / 8) * 7 - 1)
      .attr("x2", (centerX / 4) * 4.5)
      .attr("y2", (centerY / 8) * 11 + 1)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 6)
      .attr("y1", (centerY / 8) * 5)
      .attr("x2", (centerX / 4) * 6)
      .attr("y2", (centerY / 8) * 7 + 1)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 6)
      .attr("y1", (centerY / 8) * 11 - 1)
      .attr("x2", (centerX / 4) * 6)
      .attr("y2", (centerY / 8) * 13)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 1.5)
      .attr("y1", (centerY / 4) * 4.5)
      .attr("x2", (centerX / 4) * 2 + 1)
      .attr("y2", (centerY / 4) * 4.5)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    //triangle
    g.append("polygon")
      .attr(
        "points",
        `${centerX / 2 - 10},${centerY + 5} ${centerX / 2 - 15},${centerY} ${
          centerX / 2 - 15
        },${centerY + 10}`
      )
      .attr("fill", "black")
      .attr("transform", `translate(${(centerX / 4) * 1.5}, 0)`);

    g.append("circle")
      .attr("cx", (centerX / 4) * 3)
      .attr("cy", (centerY / 4) * 4.5)
      .attr("r", radius)
      .attr("fill", "black")
      .attr("stroke", "black")
      .attr("stroke-width", 0);

    g.append("line")
      .attr("x1", (centerX / 4) * 3.5)
      .attr("y1", (centerY / 8) * 7 - 1)
      .attr("x2", (centerX / 4) * 3.5)
      .attr("y2", (centerY / 8) * 11 + 1)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 1.5)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("PSW");

    g.append("text")
      .attr("x", (centerX / 4) * 0.9)
      .attr("y", (centerY / 4) * 4.65)
      .attr("text-anchor", "middle")
      .attr("font-size", `5px`)
      .text("PSW");

    g.append("text")
      .attr("x", (centerX / 4) * 2)
      .attr("y", (centerY / 4) * 2.65)
      .attr("text-anchor", "middle")
      .attr("font-size", `5px`)
      .text("Vdd");
  },
};
