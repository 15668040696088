import { ClassicPreset } from "rete";
import ITDAControl from "./ITDAControl";
import { ITDAControlSDC as ITDAControlSDCRenderer } from "./renderers";

export default class ITDAControlSDC extends ITDAControl {
  private node: ClassicPreset.Node;
  private showSDC = false;
  private sdcName = "";
  private backgroundColor = "transparent";

  constructor(parent: ClassicPreset.Node) {
    super();
    this.node = parent;
    this.render = ITDAControlSDCRenderer.render;
  }

  public setShowSDC(showSDC: boolean) {
    this.showSDC = showSDC;
    if (!showSDC) this.sdcName = "";
  }

  public getShowSDC() {
    return this.showSDC;
  }

  public setSdcName(name: string) {
    this.sdcName = name;
    if (!this.showSDC) this.sdcName = "";
  }

  public getSdcName() {
    return this.sdcName;
  }

  public setBackgroundColor(color: string) {
    if (color) this.backgroundColor = color;
  }

  public getBackgroundColor() {
    return this.backgroundColor;
  }
}
