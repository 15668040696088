/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";

export default class ClockDividerChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
    //this.addChecker(item.getFidMaxNum(), this.checkMaxNum, ErrorType.NAME)
    //this.addChecker(item.getFidECC(), this.checkECC, ErrorType.NAME)
    //this.addChecker(item.getFidClkEn(), this.checkClkEn, ErrorType.NAME)
    this.addChecker(
      item.getFidClkEnValue(),
      this.checkClkEnValue,
      ErrorType.CONFIG
    );
    this.addChecker(
      item.getFidMaxDivRatioWidth(),
      this.checkMaxDivRatioWidth,
      ErrorType.CONFIG
    );
    //this.addChecker(item.getFidDivRatioInitValue(), this.checkDivRatioInitValue, ErrorType.NAME)
    //this.addChecker(item.getFidSDCPath(), this.checkSDCPath, ErrorType.NAME)
  }
  checkName() {
    // TBD
  }
  checkMaxNum() {
    // TBD
  }
  checkECC() {
    // TBD
  }
  checkClkEn() {
    // TBD
  }
  checkClkEnValue(item) {
    const divRatio = item.getMaxDivRatioWidth();
    const clkEn = item.getClkEnValue();
    if (clkEn >= Math.pow(2, divRatio) || clkEn < 0) {
      this.newError(
        `Please check the clock enable value range(0~${
          Math.pow(2, divRatio) - 1
        })`
      );
    }
  }
  checkMaxDivRatioWidth(item) {
    const divRatio = item.getMaxDivRatioWidth();
    if (divRatio > 16 || divRatio < 1) {
      this.newError(`Please check the DIV ratio range(1~16)`);
    }
  }
  checkDivRatioInitValue() {
    // TBD
  }
  checkSDCPath() {
    // TBD
  }
}
