import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;
    const innerStroke = 2;

    g.append("line")
      .attr("x1", (centerX / 4) * 1.8)
      .attr("y1", (centerY / 4) * 3)
      .attr("x2", (centerX / 4) * 2.5 + 1)
      .attr("y2", (centerY / 4) * 3)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 2.5)
      .attr("y1", (centerY / 4) * 3)
      .attr("x2", (centerX / 4) * 2.5)
      .attr("y2", (centerY / 4) * 2)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 2.5 - 1)
      .attr("y1", (centerY / 4) * 2)
      .attr("x2", (centerX / 4) * 4.5 + 1)
      .attr("y2", (centerY / 4) * 2)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 4.5)
      .attr("y1", (centerY / 4) * 2)
      .attr("x2", (centerX / 4) * 4.5)
      .attr("y2", (centerY / 4) * 3)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 4.5 - 1)
      .attr("y1", (centerY / 4) * 3)
      .attr("x2", (centerX / 4) * 8 - 1)
      .attr("y2", (centerY / 4) * 3)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 1.8)
      .attr("y1", (centerY / 4) * 7)
      .attr("x2", (centerX / 4) * 4.85)
      .attr("y2", (centerY / 4) * 7)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 4.85)
      .attr("y1", (centerY / 4) * 6 - 1)
      .attr("x2", (centerX / 4) * 4.85)
      .attr("y2", (centerY / 4) * 7 + 1)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 4.85)
      .attr("y1", (centerY / 4) * 6)
      .attr("x2", (centerX / 4) * 6.85 + 1)
      .attr("y2", (centerY / 4) * 6)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 6.85)
      .attr("y1", (centerY / 4) * 6)
      .attr("x2", (centerX / 4) * 6.85)
      .attr("y2", (centerY / 4) * 7 + 1)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    g.append("line")
      .attr("x1", (centerX / 4) * 6.85)
      .attr("y1", (centerY / 4) * 7)
      .attr("x2", (centerX / 4) * 8 - 1)
      .attr("y2", (centerY / 4) * 7)
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke);

    // CCMD Line 1
    g.append("path")
      .attr(
        "d",
        `M ${(centerX / 4) * 1.8},${(centerY / 4) * 4} 
         L ${(centerX / 4) * 2.5 + 1},${(centerY / 4) * 4} 
         L ${(centerX / 4) * 3.5},${(centerY / 4) * 5} 
         L ${(centerX / 4) * 8 - 1},${(centerY / 4) * 5}`
      )
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke)
      .attr("fill", "transparent");

    // CCMD Line 2
    g.append("path")
      .attr(
        "d",
        `M ${(centerX / 4) * 1.8},${(centerY / 4) * 5} 
         L ${(centerX / 4) * 2.5 + 1},${(centerY / 4) * 5} 
         L ${(centerX / 4) * 3.5},${(centerY / 4) * 4} 
         L ${(centerX / 4) * 8 - 1},${(centerY / 4) * 4}`
      )
      .attr("stroke", "black")
      .attr("stroke-width", innerStroke)
      .attr("fill", "transparent");

    g.append("text")
      .attr("x", (centerX / 4) * 1.05)
      .attr("y", (centerY / 4) * 3.15)
      .attr("text-anchor", "middle")
      .attr("font-size", `4.5px`)
      .attr("fill", "black")
      .text("CREQ");

    g.append("text")
      .attr("x", (centerX / 4) * 1.05)
      .attr("y", (centerY / 4) * 7.15)
      .attr("text-anchor", "middle")
      .attr("font-size", `4.5px`)
      .attr("fill", "black")
      .text("CACK");

    g.append("text")
      .attr("x", (centerX / 4) * 1.05)
      .attr("y", (centerY / 4) * 4.65)
      .attr("text-anchor", "middle")
      .attr("font-size", `4.5px`)
      .attr("fill", "black")
      .text("CCMD");

    g.append("text")
      .attr("x", centerX)
      .attr("y", (centerY / 4) * 1.5)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("CLINK");
  },
};
