import { createStore, Store, CommitOptions, DispatchOptions } from "vuex";

import component from "./modules/component";
import domain from "./modules/domain";
import item from "./modules/item";
import navigation from "./modules/navigation";
import canvas from "./modules/canvas";
import { RootState } from "./types";

const store: Store<RootState> = createStore<RootState>({
  state(): RootState {
    return {
      version: {
        major: 0,
        minor: 0,
        patch: 0,
      },
      inProgress: false,
      loading: false,
      processes: {},
      routing: {},
      footer: false,

      design: undefined,
      config: undefined,
    };
  },
  mutations: {
    SET_IN_PROGRESS(state: RootState, process: string) {
      console.debug(`START:: ${process}`);
      state.processes[process] = true;
      state.inProgress = true;
      console.time(process);
    },
    SET_DONE(state: RootState, process: string) {
      console.debug(`END:: ${process}`);
      if (state.processes[process]) {
        console.timeEnd(process);
      }
      delete state.processes[process];
      if (!Object.keys(state.processes).length) {
        state.inProgress = false;
      }
    },
    LOAD_START(state: RootState) {
      state.loading = true;
    },
    LOAD_END(state: RootState) {
      state.loading = false;
    },
    ROUTING_START(state: RootState, names: string[]) {
      console.debug("routing start:", names);
      names.forEach((name) => {
        state.routing[name] = true;
        console.time(`routing-${name}`);
      });
      console.time("routing");
    },
    ROUTING_END(state: RootState, name: string) {
      console.debug("routing end:", name);
      if (state.routing[name]) {
        console.timeEnd(`routing-${name}`);
      }
      delete state.routing[name];
      if (!Object.keys(state.routing).length) {
        console.timeEnd("routing");
      }
    },
    SET_FOOTER(state: RootState, footer: boolean) {
      state.footer = footer;
    },

    SET_DESIGN(state: RootState, design: string) {
      state.design = design;
    },
    SET_CONFIG(state: RootState, config: string) {
      state.config = config;
    },
  },
  getters: {
    GET_VERSION(state: RootState) {
      return state.version;
    },
    IS_IN_PROGRESS(state: RootState) {
      return state.inProgress;
    },
    GET_NUM_OF_TASKS(state: RootState) {
      return Object.keys(state.processes).length;
    },
    IS_ROUTED(state: RootState) {
      return !Object.keys(state.routing).length ? true : false;
    },
    GET_FOOTER(state: RootState) {
      return state.footer;
    },
    IS_LOADING(state: RootState) {
      return state.loading;
    },
    // GET_DESIGN_DOMAIN,
    GET_DESIGN(state: RootState) {
      return state.design;
    },
    GET_CONFIG(state: RootState) {
      return state.config;
    },
  },
  actions: {
    // async START_PROCESS(context, process: string, options?: CommitOptions) {
    //   await new Promise<void>((resolve) => {
    //     context.commit("SET_IN_PROGRESS", process, options);
    //     setTimeout(() => resolve(), 0);
    //   });
    // },
    // async END_PROCESS(context, process: string, options?: CommitOptions) {
    //   await new Promise<void>((resolve) => {
    //     context.commit("SET_DONE", process, options);
    //     setTimeout(() => resolve(), 0);
    //   });
    // },
    // async SETUP(context, options?: DispatchOptions) {
    //   await context.dispatch("START_PROCESS", "SETUP");
    //   // ItemHandler.createItem(null, Item.Project)
    //   console.log(options);
    //   await context.dispatch("END_PROCESS", "SETUP");
    // },
  },
  //strict: process.env.NODE_ENV !== 'production',
  modules: {
    component,
    domain,
    item,
    navigation,
    canvas,
  },
});

export default store;

// import { createStore } from "vuex";

// export default createStore({
//   state: {},
//   getters: {},
//   mutations: {},
//   actions: {},
//   modules: {},
// });
