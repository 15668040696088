<template>
  <v-app>
    <header-system />
    <splitpanes horizontal>
      <pane size="100%">
        <splitpanes>
          <pane
            class="navi-left"
            :size="navShow ? '18%' : '0%'"
            min-size="18%"
            max-size="30%">
            <navigation-left />
          </pane>
          <pane :size="navShow ? '88%' : '100%'">
            <splitpanes horizontal>
              <pane :style="styleHeader">
                <HeaderMain />
              </pane>
              <pane size="100%" class="main-page-pane">
                <div class="main-page" :style="styleMainPage">
                  <router-view class="router-view" />
                </div>
                <!-- <router-view class="router-view" /> -->
              </pane>
            </splitpanes>
          </pane>
        </splitpanes>
      </pane>
      <!-- <pane v-if="store.getters['GET_FOOTER']" size="10%">
        <FooterMain />
      </pane> -->
    </splitpanes>
    <FooterSystem />
    <in-progress />
  </v-app>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch, nextTick } from "vue";
import HeaderSystem from "@/components/header/HeaderSystem.vue";
import HeaderMain from "@/components/header/HeaderMain.vue";
import NavigationLeft from "@/components/navigation/NavigationLeft.vue";
import NavigationRight from "@/components/navigation/NavigationRight.vue";
import FooterMain from "@/components/footer/FooterMain.vue";
import FooterSystem from "@/components/footer/FooterSystem.vue";
import InProgress from "@/components/InProgress.vue";
import { Splitpanes, Pane } from "splitpanes";
import { useStore } from "vuex";
import events from "./events";
import utils from "./utils";

const store = useStore();
const navShow = ref(store.getters["navigation/IS_NAV_OPENED"]);

const beforeEnter = (el: HTMLElement) => {
  console.log("beforeEnter:", el);
  el.style.transform = "translateX(100%)"; // 오른쪽에서 시작
};
const enter = (el: HTMLElement, done: any) => {
  console.log("enter:", el, done);
  // el.offsetHeight; // trigger reflow
  // el.style.transition = "transform 0.5s ease";
  el.style.transform = "translateX(0)";
  done();
};
const leave = (el: HTMLElement, done: any) => {
  console.log("leave:", el, done);
  el.style.transition = "transform 0.5s ease";
  el.style.transform = "translateX(100%)"; // 오른쪽으로 사라짐
  done();
};

const styleHeader = computed(() => {
  return {
    // display: navShow.value ? "block" : "none",
    height: navShow.value ? "44px" : "0px",
  };
});

const styleMainPage = computed(() => {
  return {
    height: navShow.value
      ? `calc(100vh - 37px - 44px - 37px)`
      : `calc(100vh - 37px - 37px)`,
  };
});

watch(
  () => store.getters["navigation/IS_NAV_OPENED"],
  (newVal) => {
    navShow.value = newVal;
    if (newVal) {
      const getNavRightComponent =
        store.getters["component/GET_NAV_RIGHT_COMPONENT"];
      store.commit(
        "component/UPDATE_NAV_RIGHT_COMPONENT",
        getNavRightComponent + 1
      );
    }
  }
);

onMounted(() => {
  try {
    store.commit("SET_IN_PROGRESS", "CLI TEST");
    events.setupEvents();
    utils.setShortcutKey();
    utils.blockContextMenu();
  } catch (e) {
    console.error(e);
  } finally {
    store.commit("SET_DONE", "CLI TEST");
  }
});
</script>

<style lang="sass">
@import url(splitpanes/dist/splitpanes.css)
.splitpanes__pane
  display: flex
  color: rgba(255, 255, 255, 0.6)

.splitpanes__splitter
  border: 1px solid lightgray
  z-index: 2000


.split-pane
  -fx-padding: 0

.app-header
  display: flex

.navi-left
  flex-wrap: wrap

.main-page-pane
  width: 100%
  overflow-y: auto
  .main-page
    // height: calc(100vh - 37px - 44px - 37px)
    width: 100%
    overflow-y: auto
    margin: 0 !important
    padding: 0
    display: flex
    flex-grow: 0
    flex-direction: column
    .router-view
      width: 100%

.slide-enter-active
  -moz-transition-duration: 1.3s
  -webkit-transition-duration: 1.3s
  -o-transition-duration: 1.3s
  transition-duration: 1.3s
  -moz-transition-timing-function: ease-in
  -webkit-transition-timing-function: ease-in
  -o-transition-timing-function: ease-in
  transition-timing-function: ease-in
</style>
