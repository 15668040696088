import { Position } from "rete-area-plugin/_types/types";

export class WheelDrag {
  private wheelListener?: WheelListener;
  private startPosition?: Position;
  protected config!: DragConfig;
  protected events!: Events;

  constructor(private intensity: number) {
    return;
  }

  public initialize(element: HTMLElement, config: DragConfig, events: Events) {
    this.config = config;
    this.events = events;
    this.wheelListener = useWheelListener(element, {
      wheel: this.wheel,
    });
    this.startPosition = this.config.getCurrentPosition();
  }

  private wheel = (e: WheelEvent) => {
    if (!this.startPosition) return;
    e.preventDefault();

    const zoom = this.config.getZoom();
    if (!e.ctrlKey) {
      if (e.shiftKey) {
        this.events.translate(
          this.startPosition.x - (e.deltaY * this.intensity) / zoom,
          this.startPosition.y,
          e
        );
      } else {
        this.events.translate(
          this.startPosition.x,
          this.startPosition.y - (e.deltaY * this.intensity) / zoom,
          e
        );
      }
    }
  };

  public destroy() {
    if (this.wheelListener) {
      this.wheelListener.destroy();
    }
  }
}

function useWheelListener(element: HTMLElement, handlers: WheelEventHandlers) {
  const wheel = (event: WheelEvent) => {
    handlers.wheel(event);
  };

  element.addEventListener("wheel", wheel);

  return {
    destroy() {
      element.removeEventListener("wheel", wheel);
    },
  };
}

interface WheelEventHandlers {
  wheel: (event: WheelEvent) => void;
}

interface DragConfig {
  getCurrentPosition: () => Position;
  getZoom: () => number;
}

interface Events {
  start: (e: Event) => void;
  translate: (x: number, y: number, e: Event) => void;
  drag: (e: Event) => void;
}

interface WheelListener {
  destroy: () => void;
}
