import data from "./data";
import { TableSchema, TableCellConfig, TableRendererDFT } from "@/table/types";

const getHeaders = (): Array<string> =>
  data.map((obj: TableSchema) => obj.data);
const getColumns = (): Array<TableSchema> => data;

function getCells(this: TableCellConfig, row: number, col: number) {
  const cellsAttribute: { readOnly?: string; renderer?: TableRendererDFT } = {};

  const readOnlyColNums = [0, 1];
  const writeColNums = [2, 3, 4, 5, 6];

  if (this.hot?.getDataAtCell(row, col) === "TIE") {
    cellsAttribute.renderer = TableRendererDFT.tieRenderer;
  } else {
    if (readOnlyColNums.includes(col)) {
      cellsAttribute.renderer = TableRendererDFT.readOnlyRenderer;
    }

    if (writeColNums.includes(col)) {
      cellsAttribute.renderer = TableRendererDFT.writeCellRenderer;
    }
  }

  return cellsAttribute;
}

export { getHeaders, getColumns, getCells };
