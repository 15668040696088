<template>
  <v-container :key="key" fluid class="item-view-boundary">
    <CanvasEditor v-if="diagram" @mounted="canvasDone" />
    <ItemTable v-else-if="table" @mounted="tableDone" />
    <ItemProperty v-else :item="API.getCurrentItem()!" />
  </v-container>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import ItemProperty from "@/components/item/ItemProperty.vue";
import CanvasEditor from "@/components/canvas/CanvasEditor.vue";
import ItemTable from "@/table/ItemTable.vue";
import {
  onBeforeRouteUpdate,
  RouteLocationNormalized,
  useRoute,
} from "vue-router";
import { useStore } from "vuex";
import API from "@/api/internal";

const store = useStore();
const diagram = ref(false);
const table = ref(false);
const key = ref("0");

onBeforeRouteUpdate(
  (to: RouteLocationNormalized, from: RouteLocationNormalized, next) => {
    console.debug("onBeforeRouteUpdate", to, from);
    if (to.query) {
      key.value = to.query.id as string;
      diagram.value = JSON.parse(to.query.diagram as string);
      table.value = JSON.parse(to.query.table as string);
    }
    next();
  }
);

const canvasDone = () => {
  store.commit("ROUTING_END", "diagram");
};

const tableDone = () => {
  store.commit("ROUTING_END", "table");
};

onMounted(() => {
  const route = useRoute();
  const query = route.query;
  if (query) {
    key.value = query.id as string;
    diagram.value = JSON.parse(query.diagram as string);
    table.value = JSON.parse(query.table as string);
  }
});
</script>

<style lang="sass" scoped>
.item-view-boundary
    flex-grow: 100
    height: inherit
    width: 100%
</style>
