import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;

    // Draw head (circle)
    g.append("circle")
      .attr("cx", centerX)
      .attr("cy", (centerY / 4) * 3.7)
      .attr("r", centerX / 4)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    g.append("path")
      .attr(
        "d",
        `M${(centerX / 4) * 2},${(centerY / 4) * 5.5} A15,10 0 0,1 ${
          (centerX / 4) * 6
        },${(centerY / 4) * 5.5}`
      )
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("transform", `translate(${0}, ${centerY / 4})`)
      .attr("fill", color);

    g.append("line")
      .attr("x1", (centerX / 4) * 2)
      .attr("y1", (centerY / 4) * 6.5)
      .attr("x2", (centerX / 4) * 2)
      .attr("y2", (centerY / 4) * 7 + 1)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("line")
      .attr("x1", (centerX / 4) * 6)
      .attr("y1", (centerY / 4) * 6.5)
      .attr("x2", (centerX / 4) * 6)
      .attr("y2", (centerY / 4) * 7 + 1)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("line")
      .attr("x1", (centerX / 4) * 2)
      .attr("y1", (centerY / 4) * 7)
      .attr("x2", (centerX / 4) * 6)
      .attr("y2", (centerY / 4) * 7)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH);

    g.append("text")
      .attr("x", centerX)
      .attr("y", height / 5)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("USERDEFIN");
  },
};
