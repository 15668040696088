import Canvas from "@/canvas";
import { Context, Event } from "./types";
import ITDACanvas from "@/canvas/ITDACanvas";

export default class EventHandler {
  static events: { [key in string]: Event } = {};

  static register(id: string) {
    Canvas.getPlugins(id).forEach((o: ITDACanvas) => {
      o.emit = EventHandler.emit;
    });
  }

  static addEvent(key: string, event: Event) {
    EventHandler.events[key] = event;
  }

  static clear() {
    EventHandler.events = {};
  }

  static emit(context: Context): Promise<boolean> {
    const event = EventHandler.events[context.type];
    if (event) {
      return event(context);
    } else {
      return new Promise<boolean>((resolve) => resolve(false));
    }
  }
}
