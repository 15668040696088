/**
 *  Generated by Modeler
 *  timestamp: 2024-08-27 10:28:21.894782
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemHandler from "../ItemHandler";
import { ItemType } from "ITDAModelTypes";
import SDCHandler from "../SDCHandler";

export default class SDCPhantomEvent extends ItemEvent {
  constructor(item) {
    super(item);
    // this.addPropertyListener(item.getFidPhantom(), this.lstn_phantom)
    this.addPropertyListener(item.getFidClockPoint(), this.lstn_clockPoint);
    // this.addPropertyListener(item.getFidPorts(), this.lstn_ports)
    // this.addPropertyListener(item.getFidPath(), this.lstn_path)
    // this.addPropertyListener(item.getFidOriginItem(), this.lstn_originItem)
  }
  // lstn_phantom(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
  // lstn_ClockPoint(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
  // lstn_ports(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
  // lstn_path(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
  // lstn_originItem(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }

  lstn_clockPoint(item) {
    if (!item.getClockPoint()) {
      item.setClockGroup(null);
    }
  }

  postProcess(item) {
    const originItem = item.getOriginItem();
    if (originItem) {
      SDCHandler.setSDCPath(item, originItem);

      const nodeMetaView = item.getNodeMetaView();
      item.setNodeType(originItem.getNodeType());
      nodeMetaView.setX(originItem.getNodeMetaView().getX());
      nodeMetaView.setY(originItem.getNodeMetaView().getY());
      if (originItem.getItemType() === ItemType.ClockIP) {
        return;
      }
      return originItem.getEvent().setupPorts(item);
    }
  }
}
