/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemType from "../ItemType";

export default class PowerSequenceLabelEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), () => {});
    this.addPropertyListener(item.getFidLabelRefs(), this.lstn_labelRefs);
    this.addPropertyListener(item.getFidColor(), () => {});
  }

  lstn_labelRefs(item, newVal, oldVal) {
    oldVal
      .filter((labelRef) => {
        return newVal.findIndex((o) => o.getId() === labelRef.getId()) < 0;
      })
      .map((o) => {
        if (o.getItemLabel() === item) {
          o.setItemLabel();
        }
      });
  }

  postProcess(item) {
    return this.setupPorts(item);
  }

  setupPorts(item) {
    return item.newOutput({
      key: `SEQREF_${ItemType.Output.getKey().toUpperCase()}`,
      socket: "SEQREF",
      position: "RIGHT",
    }).promise;
  }
}
