/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import PowerPMCEvent from '@/model/src/events/PowerPMCEvent'
import PowerPMCChecker from '@/model/src/checkers/PowerPMCChecker'
export default class PowerPMC extends Item {
    FID_POWERPMC_NAME = 0
    FID_POWERPMC_JTAG = 1
    FID_POWERPMC_NUMOFROMSIZE = 2
    FID_POWERPMC_NUMOFRAMSIZE = 3
    FID_POWERPMC_LAST = 3

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerPMC"}))
        
        this.setEvent(new PowerPMCEvent(this))
        this.setChecker(new PowerPMCChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "Power Management Cluster"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "PMC_NONAME"
    }
    if (this.properties.jtag === undefined) {
        this.properties.jtag = true
    }
    if (this.properties.NumOfROMSize === undefined) {
        this.properties.NumOfROMSize = "8KB"
    }
    if (this.properties.NumOfRAMSize === undefined) {
        this.properties.NumOfRAMSize = "8KB"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerPMC"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "PMC_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "jtag": {"type": "boolean", "label": "Insert JTAG Module", "hidden": true, "readOnly": true, "default": true, "category": "IP", "multiple": false}, "NumOfROMSize": {"type": "enum", "label": "PMR ROM Size", "targets": ["2KB", "4KB", "8KB"], "multiple": false, "default": "8KB", "category": "IP", "hidden": false, "readOnly": false}, "NumOfRAMSize": {"type": "enum", "label": "PMR RAM Size", "targets": ["2KB", "4KB", "8KB"], "multiple": false, "default": "8KB", "category": "IP", "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "PMC_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "jtag": {"type": "boolean", "label": "Insert JTAG Module", "hidden": true, "readOnly": true, "default": true, "category": "IP", "multiple": false}, "NumOfROMSize": {"type": "enum", "label": "PMR ROM Size", "targets": ["2KB", "4KB", "8KB"], "multiple": false, "default": "8KB", "category": "IP", "hidden": false, "readOnly": false}, "NumOfRAMSize": {"type": "enum", "label": "PMR RAM Size", "targets": ["2KB", "4KB", "8KB"], "multiple": false, "default": "8KB", "category": "IP", "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ConfigurationFolder", "auto": true, "category": "IP"}, {"name": "PowerSequenceConfigurationFolder", "auto": true, "category": "IP"}, {"name": "SFRBlock", "auto": true, "category": "IP"}, {"name": "PowerPMR", "auto": true, "category": "IP"}, {"name": "PowerDiagramFolder", "auto": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMC_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERPMC_NAME
    }
    

    setJtag(jtag, event=true) {
        if ((jtag !== null) && (jtag !== undefined)) {
            if (![Boolean].includes(jtag.constructor)) {
                throw `jtag(${jtag}) must be [Boolean] type`
            }
        }
        const newVal = (jtag === null || jtag === undefined) ? undefined : jtag
        const oldVal = this.properties.jtag
        this.properties.jtag = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMC_JTAG, newVal, oldVal)
        }
    }
    getJtag(pure = false) {
        return this.properties.jtag
        
    }
    getFidJtag() {
        return this.FID_POWERPMC_JTAG
    }
    

    setNumOfROMSize(NumOfROMSize, event=true) {
        if ((NumOfROMSize !== null) && (NumOfROMSize !== undefined)) {
            if (![String].includes(NumOfROMSize.constructor)) {
                throw `NumOfROMSize(${NumOfROMSize}) must be [String] type`
            }
        }
        const newVal = (NumOfROMSize === null || NumOfROMSize === undefined) ? undefined : NumOfROMSize
        const oldVal = this.properties.NumOfROMSize
        this.properties.NumOfROMSize = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMC_NUMOFROMSIZE, newVal, oldVal)
        }
    }
    getNumOfROMSize(pure = false) {
        return this.properties.NumOfROMSize
        
    }
    getFidNumOfROMSize() {
        return this.FID_POWERPMC_NUMOFROMSIZE
    }
    

    setNumOfRAMSize(NumOfRAMSize, event=true) {
        if ((NumOfRAMSize !== null) && (NumOfRAMSize !== undefined)) {
            if (![String].includes(NumOfRAMSize.constructor)) {
                throw `NumOfRAMSize(${NumOfRAMSize}) must be [String] type`
            }
        }
        const newVal = (NumOfRAMSize === null || NumOfRAMSize === undefined) ? undefined : NumOfRAMSize
        const oldVal = this.properties.NumOfRAMSize
        this.properties.NumOfRAMSize = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMC_NUMOFRAMSIZE, newVal, oldVal)
        }
    }
    getNumOfRAMSize(pure = false) {
        return this.properties.NumOfRAMSize
        
    }
    getFidNumOfRAMSize() {
        return this.FID_POWERPMC_NUMOFRAMSIZE
    }
    

    getFidLast() {
        return this.FID_POWERPMC_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERPMC_NAME:
                return "name"

            case this.FID_POWERPMC_JTAG:
                return "jtag"

            case this.FID_POWERPMC_NUMOFROMSIZE:
                return "NumOfROMSize"

            case this.FID_POWERPMC_NUMOFRAMSIZE:
                return "NumOfRAMSize"

            default:
                return ""
        }
    }


    newConfigurationFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "ConfigurationFolder", properties, title, domain)
    }
    remConfigurationFolder(obj) {
        return this.removeChild(obj)
    }
    getConfigurationFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "ConfigurationFolder")
            return res
        })
    }
    newPowerSequenceConfigurationFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceConfigurationFolder", properties, title, domain)
    }
    remPowerSequenceConfigurationFolder(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceConfigurationFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "PowerSequenceConfigurationFolder")
            return res
        })
    }
    newSFRBlock(properties={}, title = "", domain = "") {
        return this.createChild(this, "SFRBlock", properties, title, domain)
    }
    remSFRBlock(obj) {
        return this.removeChild(obj)
    }
    getSFRBlock() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "SFRBlock")
            return res
        })
    }
    newPowerPMR(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMR", properties, title, domain)
    }
    remPowerPMR(obj) {
        return this.removeChild(obj)
    }
    getPowerPMR() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "PowerPMR")
            return res
        })
    }
    newPowerDiagramFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerDiagramFolder", properties, title, domain)
    }
    remPowerDiagramFolder(obj) {
        return this.removeChild(obj)
    }
    getPowerDiagramFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "PowerDiagramFolder")
            return res
        })
    }
}