<template>
  <div class="desc" :style="getDescStyle" v-html="getDescription()"></div>
</template>

<script setup lang="ts">
import { computed, defineProps, onMounted, onUpdated, ref, watch } from "vue";
import Utils from "@/utils";
import Canvas from "@/canvas";
const props = defineProps<{
  data: any;
  emit?: (event: string, ...args: any[]) => void;
  width?: number;
  nodeId?: string;
}>();
const width = ref<number>(props.data.node.width);
const nodeId = ref<string>(props.data.node.id);
const textWidth = ref<number | null>(null);
const zoomLevel = ref<number>(1);
const updateZoomLevel = () => {
  zoomLevel.value = Canvas.getAreaPlugin(Canvas.getCurrentID()).getZoom();
};
const calculateTextWidth = () => {
  const textElement = document.querySelector(`.text-${props.data.node.id}`);
  if (textElement) {
    textWidth.value = textElement.getBoundingClientRect().width;
  }
};

const getDescStyle = computed(() => {
  if (textWidth.value && textWidth.value > width.value) {
    return {
      left: `${textWidth.value / zoomLevel.value + width.value / 4 + 13}px`,
    };
  } else {
    return {
      left: `${width.value + 13}px`,
    };
  }
});

const getDescription = () => {
  return Utils.getDescription(
    props.data.repo.getCurrentID(),
    props.data.node.id
  );
};

watch([() => props.width, () => props.nodeId], () => {
  calculateTextWidth();
  updateZoomLevel();
});

onMounted(() => {
  calculateTextWidth();
  updateZoomLevel();
  if (props.width) width.value = props.width;
  if (props.nodeId) nodeId.value = props.nodeId;
});

onUpdated(() => {
  calculateTextWidth();
  updateZoomLevel();
});
</script>

<style lang="sass" scoped>
.desc
  top: 50%
  position: absolute
  font-size: small
  white-space: nowrap
  transform: translateY(-50%)
  pointer-events: none
</style>
