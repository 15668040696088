import API from "@/api/internal";
import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodePower from "./ITDANodePower";

export default class ITDAAPM extends ITDANodePower {
  constructor(name = "") {
    super(name, NodeType.ITDAAPM);
    this.render = ITDANodeRenderer.ITDAAPM.render;
  }

  set height(data: number) {
    super.height = data;
  }

  get height() {
    const nodeStroke = this._nodeStroke;
    const adjustHeight = this.adjustNodeToGrid(super.height);
    if (this.isDocked()) return super.height;
    const item = API.getItem(this.getItemID());
    const children = [
      ...(item?.getPowerAPMPWRDOWNs() || []),
      ...(item?.getPowerAPMPWRUPs() || []),
    ];

    return children.length > 2
      ? children.length * 30 + nodeStroke
      : adjustHeight + nodeStroke;
  }
}
