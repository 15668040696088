import API from "@/api";
import { ExternalSpecPLL } from "ITDAModelTypes";
import { ItemType } from "ITDAModelTypes";
import { Item, Property } from "ITDAModelTypes";
import { isDisabled } from "./ItemProperty";

export const isDisabledTextField = (
  item: Item,
  key: string,
  prop: Property
): boolean => {
  const disabled = isDisabled(item, key, prop);
  switch (item.getItemType()) {
    case ItemType.Label:
    case ItemType.LabelRef:
      return item.getType() === "internal" ? true : false || disabled;
    case ItemType.SFRField:
      if (key === "initValue") {
        const pll = getRefPLLItem(item);
        if (pll) {
          const fieldSpec = getPLLFieldSpec(pll, item);
          return fieldSpec && fieldSpec.config ? false : disabled;
        } else {
          return true;
        }
      } else {
        return true;
      }
    case ItemType.PowerSequenceDiagram:
      if (item.getParent().getTitle() === "PMR") {
        return true;
      }
      return false;
    case ItemType.SDCPhantom:
      if (item.getOriginItem() && key === "path") {
        return checkPathIsAvailable(item) ? false : true;
      }
      return false;
    default:
      return false || disabled;
  }
};

const getRefPLLItem = (field: Item): Item | undefined => {
  const refItem = field.getParent().getItemRef();
  return refItem && refItem.getItemType() === ItemType.ClockPLL
    ? refItem
    : undefined;
};

const getPLLFieldSpec = (pll: Item, field: Item): any => {
  if (!pll) {
    return;
  }
  const pllSpec = API.getExternalPLLSpec().find(
    (spec: ExternalSpecPLL) => spec.name === pll.getParent().getPlltype()
  );
  if (pllSpec) {
    const sfrSpec = pllSpec.sfrs.find(
      (obj) => obj.name === field.getParent().getType()
    );
    return sfrSpec?.fields.find((obj) => {
      return (
        obj.name.replace("SFR_", "") === field.getName().replace("SFR_", "")
      );
    });
  }
};

const checkPathIsAvailable = (item: Item) => {
  switch (item.getOriginItem().getItemType()) {
    case ItemType.ClockPLLCtrl:
      return true;
    case ItemType.ClockExternalCLKSource:
      return true;
    case ItemType.LabelRef:
      if (item.getOriginItem().getType() === "external") {
        return true;
      } else {
        return false;
      }
    default:
      return false;
  }
};
