/**
 *  Generated by Modeler
 *  timestamp: 2024-09-03 13:51:33.402050
 */
//import ErrorType from '@/model/src/ErrorType'
import ItemChecker from '@/model/src/ItemChecker'

export default class NodeChecker extends ItemChecker {
    constructor(item) {
        super(item)
        //this.addChecker(item.getFidPhantom(), this.checkPhantom, ErrorType.NAME)
        //this.addChecker(item.getFidBuild(), this.checkBuild, ErrorType.NAME)
    }
    checkPhantom() {
        // TBD
    }
    checkBuild() {
        // TBD
    }
}