/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x800",
    "size": "0x200",
    "alignSize": "0x8",
    "sfrs": [
        {
            "name": "CTRL",
            "alignOffset": "0x20",
            "description": "INTR Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_DATA0",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA0"
                },
                {
                    "name": "SFR_DATA1",
                    "index": 1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA1"
                },
                {
                    "name": "SFR_DATA2",
                    "index": 2,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA2"
                },
                {
                    "name": "SFR_DATA3",
                    "index": 3,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA3"
                },
                {
                    "name": "SFR_DATA4",
                    "index": 4,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA4"
                },
                {
                    "name": "SFR_DATA5",
                    "index": 5,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA5"
                },
                {
                    "name": "SFR_DATA6",
                    "index": 6,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA6"
                },
                {
                    "name": "SFR_DATA7",
                    "index": 7,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA7"
                },
                {
                    "name": "SFR_DATA8",
                    "index": 8,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA8"
                },
                {
                    "name": "SFR_DATA9",
                    "index": 9,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA9"
                },
                {
                    "name": "SFR_DATA10",
                    "index": 10,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA10"
                },
                {
                    "name": "SFR_DATA11",
                    "index": 11,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA11"
                },
                {
                    "name": "SFR_DATA12",
                    "index": 12,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA12"
                },
                {
                    "name": "SFR_DATA13",
                    "index": 13,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA13"
                },
                {
                    "name": "SFR_DATA14",
                    "index": 14,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA14"
                },
                {
                    "name": "SFR_DATA15",
                    "index": 15,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Trigger Register for {{name}}\nCorresponding Interrupt Request is presented from this Field",
                    "initValue": "0x0",
                    "nc": "CTRL__SFR_DATA15"
                }
            ]
        },
        {
            "name": "STATUS",
            "alignOffset": "0x24",
            "description": "INTR Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_DATA0",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA0",
                    "floating": true
                },
                {
                    "name": "SFR_DATA1",
                    "index": 1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA1",
                    "floating": true
                },
                {
                    "name": "SFR_DATA2",
                    "index": 2,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA2",
                    "floating": true
                },
                {
                    "name": "SFR_DATA3",
                    "index": 3,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA3",
                    "floating": true
                },
                {
                    "name": "SFR_DATA4",
                    "index": 4,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA4",
                    "floating": true
                },
                {
                    "name": "SFR_DATA5",
                    "index": 5,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA5",
                    "floating": true
                },
                {
                    "name": "SFR_DATA6",
                    "index": 6,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA6",
                    "floating": true
                },
                {
                    "name": "SFR_DATA7",
                    "index": 7,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA7",
                    "floating": true
                },
                {
                    "name": "SFR_DATA8",
                    "index": 8,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA8",
                    "floating": true
                },
                {
                    "name": "SFR_DATA9",
                    "index": 9,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA9",
                    "floating": true
                },
                {
                    "name": "SFR_DATA10",
                    "index": 10,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA10",
                    "floating": true
                },
                {
                    "name": "SFR_DATA11",
                    "index": 11,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA11",
                    "floating": true
                },
                {
                    "name": "SFR_DATA12",
                    "index": 12,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA12",
                    "floating": true
                },
                {
                    "name": "SFR_DATA13",
                    "index": 13,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA13",
                    "floating": true
                },
                {
                    "name": "SFR_DATA14",
                    "index": 14,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA14",
                    "floating": true
                },
                {
                    "name": "SFR_DATA15",
                    "index": 15,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Status Inform Register for {{name}}",
                    "initValue": "0x0",
                    "nc": "STATUS__SFR_DATA15",
                    "floating": true
                }
            ]
        }
    ]
}