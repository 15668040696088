/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";

export default class PowerPMDInstanceChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
    this.addChecker(item.getFidModule(), this.checkModule, ErrorType.INSTANCE);
  }
  checkName() {
    // TBD
  }
  checkModule(item) {
    const module = item.getModule();
    if (!module) {
      this.newError(`module must be selected`, item);
    }
  }
}
