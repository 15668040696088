import { ClassicPreset } from "rete";
import {
  ITDAPortPosition,
  ITDASocket,
  ITDASocketType,
  ITDAPort,
} from "../common";
import ITDAControl from "./ITDAControl";

export class ITDAInput extends ClassicPreset.Input<ITDASocket> {
  private position: ITDAPortPosition;
  private itemID!: string;
  constructor(
    socket: ITDASocket,
    label: string,
    position: ITDAPortPosition = ITDAPortPosition.LEFT,
    multipleConnections?: boolean
  ) {
    super(socket, label, multipleConnections);
    this.position = position;
  }

  public setPosition(position: ITDAPortPosition): void {
    this.position = position;
  }

  public getPosition(): ITDAPortPosition {
    return this.position;
  }

  public setItemID(id: string): void {
    this.itemID = id;
  }

  public getItemID(): string {
    return this.itemID;
  }
}

export class ITDAOutput extends ClassicPreset.Output<ITDASocket> {
  private position: ITDAPortPosition;
  private itemID!: string;
  private maxNum?: number;
  constructor(
    socket: ITDASocket,
    label: string,
    position: ITDAPortPosition = ITDAPortPosition.RIGHT,
    multipleConnections?: boolean,
    maxNum?: number
  ) {
    super(socket, label, multipleConnections);
    this.position = position;
    this.maxNum = maxNum;
  }

  public setPosition(position: ITDAPortPosition): void {
    this.position = position;
  }

  public getPosition(): ITDAPortPosition {
    return this.position;
  }

  public setItemID(id: string): void {
    this.itemID = id;
  }

  public getItemID(): string {
    return this.itemID;
  }

  public getMaxNum(): number | undefined {
    return this.maxNum;
  }
}

export default class ITDAControlPort extends ITDAControl {
  private node: ClassicPreset.Node;
  constructor(parent: ClassicPreset.Node) {
    super();
    this.node = parent;
  }

  public newInputPort(
    label: string,
    type: ITDASocketType,
    position: ITDAPortPosition,
    hidden = false,
    multipleConnections?: boolean
  ): ITDAInput {
    const socket = new ITDASocket(type);
    const port = new ITDAInput(socket, label, position, multipleConnections);
    port.showControl = !hidden;
    this.node.addInput(label, port);
    return port;
  }

  public getInputPort(label: string): ITDAPort {
    return this.node.inputs[label] as ITDAPort;
  }

  public getInputPorts(): ITDAPort[] {
    return Object.values(this.node.inputs) as ITDAPort[];
  }

  public remInputPort(label: string) {
    this.node.removeInput(label);
  }

  public remInputPorts() {
    this.node.inputs = {};
  }

  public newOutputPort(
    label: string,
    type: ITDASocketType,
    position: ITDAPortPosition,
    multipleConnections?: boolean,
    maxNum?: number
  ): ITDAOutput {
    const socket = new ITDASocket(type);
    // const label = `out${Object.keys(this.node.outputs).length}`;
    const port = new ITDAOutput(
      socket,
      label,
      position,
      multipleConnections,
      maxNum
    );
    this.node.addOutput(label, port);
    return port;
  }

  public getOutputPort(label: string): ITDAPort {
    return this.node.outputs[label] as ITDAPort;
  }

  public getOutputPorts(): ITDAPort[] {
    return Object.values(this.node.outputs) as ITDAPort[];
  }

  public remOutputPort(label: string) {
    this.node.removeOutput(label);
  }

  public remOutputPorts() {
    this.node.outputs = {};
  }

  public getAllPorts(): ITDAPort[] {
    let res: ITDAPort[] = [];
    res = res.concat(Object.values(this.node.inputs) as ITDAPort[]);
    res = res.concat(Object.values(this.node.outputs) as ITDAPort[]);
    return res;
  }

  public getTopPorts(): ITDAPort[] {
    return this.getAllPorts().filter(
      (port: ITDAPort) => port.getPosition() === ITDAPortPosition.TOP
    );
  }

  public getRightPorts(): ITDAPort[] {
    return this.getAllPorts().filter(
      (port: ITDAPort) => port.getPosition() === ITDAPortPosition.RIGHT
    );
  }

  public getBottomPorts(): ITDAPort[] {
    return this.getAllPorts().filter(
      (port: ITDAPort) => port.getPosition() === ITDAPortPosition.BOTTOM
    );
  }

  public getLeftPorts(): ITDAPort[] {
    return this.getAllPorts().filter(
      (port: ITDAPort) => port.getPosition() === ITDAPortPosition.LEFT
    );
  }
}
