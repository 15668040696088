import { Context } from "../types";
import Canvas from "@/canvas";
import API from "@/api/internal";

export const noderesize = async (context: Context): Promise<boolean> => {
  const editor = Canvas.getEditor();
  const node = editor.getNode(context.options.id);

  const item = API.getItem(node?.getItemID());
  if (item) {
    item.setWidth(context.options.size.width);
    item.setHeight(context.options.size.height);
  }

  return true;
};
