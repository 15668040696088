/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import ProjectEvent from '@/model/src/events/ProjectEvent'
import ProjectChecker from '@/model/src/checkers/ProjectChecker'
export default class Project extends Item {
    FID_PROJECT_NAME = 0
    FID_PROJECT_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"Project"}))
        
        this.setEvent(new ProjectEvent(this))
        this.setChecker(new ProjectChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "Project"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "PRJ_NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "Project"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "PRJ_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "PRJ_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ClockCMUFolder", "auto": true, "category": "IP"}, {"name": "PowerPMDFolder", "auto": true, "category": "IP"}, {"name": "PowerPMCFolder", "auto": true, "category": "IP"}, {"name": "WrapperDFTFolder", "auto": true, "category": "IP"}, {"name": "DUTModuleFolder", "auto": true, "category": "IP"}, {"name": "Voltage", "auto": true, "category": "IP"}, {"name": "SDCModuleFolder", "auto": true, "category": "IP"}, {"name": "UPFModuleFolder", "auto": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_PROJECT_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_PROJECT_NAME
    }
    

    getFidLast() {
        return this.FID_PROJECT_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_PROJECT_NAME:
                return "name"

            default:
                return ""
        }
    }


    newClockCMUFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockCMUFolder", properties, title, domain)
    }
    remClockCMUFolder(obj) {
        return this.removeChild(obj)
    }
    getClockCMUFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "ClockCMUFolder")
            return res
        })
    }
    newPowerPMDFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMDFolder", properties, title, domain)
    }
    remPowerPMDFolder(obj) {
        return this.removeChild(obj)
    }
    getPowerPMDFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "PowerPMDFolder")
            return res
        })
    }
    newPowerPMCFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMCFolder", properties, title, domain)
    }
    remPowerPMCFolder(obj) {
        return this.removeChild(obj)
    }
    getPowerPMCFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "PowerPMCFolder")
            return res
        })
    }
    newWrapperDFTFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "WrapperDFTFolder", properties, title, domain)
    }
    remWrapperDFTFolder(obj) {
        return this.removeChild(obj)
    }
    getWrapperDFTFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "WrapperDFTFolder")
            return res
        })
    }
    newDUTModuleFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "DUTModuleFolder", properties, title, domain)
    }
    remDUTModuleFolder(obj) {
        return this.removeChild(obj)
    }
    getDUTModuleFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "DUTModuleFolder")
            return res
        })
    }
    newVoltage(properties={}, title = "", domain = "") {
        return this.createChild(this, "Voltage", properties, title, domain)
    }
    remVoltage(obj) {
        return this.removeChild(obj)
    }
    getVoltage() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "Voltage")
            return res
        })
    }
    newSDCModuleFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "SDCModuleFolder", properties, title, domain)
    }
    remSDCModuleFolder(obj) {
        return this.removeChild(obj)
    }
    getSDCModuleFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "SDCModuleFolder")
            return res
        })
    }
    newUPFModuleFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFModuleFolder", properties, title, domain)
    }
    remUPFModuleFolder(obj) {
        return this.removeChild(obj)
    }
    getUPFModuleFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "UPFModuleFolder")
            return res
        })
    }
}