/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0xC00",
    "size": "0x200",
    "alignSize": "0x4",
    "sfrs": [
        {
            "name": "TIMER_CTRL",
            "alignOffset": "0x20",
            "description": "Timer Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_EXPIRE_VALUE",
                    "index": 0,
                    "size": 30,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Timer Expire Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_EXPIRE_VALUE"
                },
                {
                    "name": "SFR_EXPIRE",
                    "index": 30,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Timer Expire Indication for {{name}}\nThis Field becomes 1, when Timer Expires\nThis Field becomes 0, when SFR_ENABLE is set to 0",
                    "initValue": "0x0",
                    "nc": "SFR_EXPIRE"
                },
                {
                    "name": "SFR_ENABLE",
                    "index": 31,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Timer Enable for {{name}}\nWhen this Field set to 1, Timer starts running from 0x0 to SFR_EXPIRE_VALUE \nWhen Timer value reaches to SFR_EXPIRE_VALUE, SFR_EXPIRE becomes 1\nWhen this Field set to 0, Timer value is reset to 0x0 and SFR_EXPIRE becomes 0",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE"
                }
            ]
        }
    ]
}