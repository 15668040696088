/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import InstanceHandler from "../InstanceHandler";
import ItemType from "../ItemType";

export default class DUTInstanceEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
    this.addPropertyListener(item.getFidUpper(), () => {});
    this.addPropertyListener(item.getFidModule(), this.lstn_module);
  }

  lstn_name(item, newVal) {
    item.setTitle(newVal);
  }
  async lstn_module(item, newVal, oldVal) {
    InstanceHandler.removeAllPorts(item);
    // await InstanceHandler.removePhantoms(item.getDUTPhantoms());
    const newModule = newVal;
    const oldModule = oldVal;
    if (newModule) {
      switch (newModule.getItemType()) {
        case ItemType.ClockCMU:
          InstanceHandler.createSFRIFPorts(item, newModule);
          InstanceHandler.createCLKPorts(item, newModule);
          InstanceHandler.createCLINKPorts(item, newModule);
          break;
        case ItemType.PowerPMC:
          InstanceHandler.createPLINKPorts(item, newModule);
          break;
        default:
          break;
      }
    }
  }

  async postProcess(item) {
    switch (item.getType()) {
      case "PMIF":
        return this.setupPorts(item);
      default:
        return;
    }
  }

  setupPorts(item) {
    return [
      item.newInput({
        key: `PLINK_${ItemType.Input.getKey().toUpperCase()}`,
        socket: "PLINK",
      }).promise,
    ];
  }
}
