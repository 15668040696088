import API from "@/api/internal";
import { Domain, Item, ItemType } from "ITDAModelTypes";
import ITDANode from "../../ITDANode";
import { NMDividerConfigurationReturnType } from "../../types";

export const getConfiguration = (
  node: ITDANode
): number | undefined | NMDividerConfigurationReturnType => {
  let item = API.getItem(node.getItemID());
  const isClock = API.getCurrentDomain() === Domain.Clock.getKey();
  const isSDC = API.getCurrentDomain() === Domain.SDC.getKey();
  if (
    !item ||
    !(isClock || isSDC) ||
    item.getItemType() === ItemType.SDCInstance ||
    item.getItemType() === ItemType.SDCPhantomGroup
  )
    return 0;

  if (item.getItemType() === ItemType.SDCPhantom) {
    item = item.getOriginItem() as Item;
  }
  const cmu = item?.getParent(ItemType.ClockCMU);
  const selectedFreq = cmu?.getConfigurationFolder().getSelectedConfiguration();

  switch (item.getItemType()) {
    case ItemType.ClockGate:
      return selectedFreq ? 1 : 0;

    case ItemType.ClockDivider: {
      if (!selectedFreq) return undefined;
      const freqElement = selectedFreq
        .getConfigurationElements()
        .find((elem: Item) => {
          return elem.getItemRef() === item;
        });
      if (freqElement) {
        return +freqElement.getValue() + 1;
      }
      return 1;
    }

    case ItemType.ClockNMDivider: {
      if (!selectedFreq) return { numerator: "N", denominator: "M" };
      const freqElements = selectedFreq
        .getConfigurationElements()
        .filter((elem: Item) => {
          return elem.getItemRef() === item;
        });
      if (!freqElements.length) return { numerator: "1", denominator: "16" };
      return freqElements.reduce((acc: Item, elem: Item) => {
        if (elem.getProp() === "numerator") {
          acc[elem.getProp()] = +elem.getValue() + 1 || "1";
          if (!("denominator" in acc)) {
            acc["denominator"] = "16";
          }
        } else {
          acc[elem.getProp()] = getDenomValue(elem.getValue()) || "16";
          if (!("numerator" in acc)) {
            acc["numerator"] = "1";
          }
        }
        return acc;
      }, {});
    }

    case ItemType.ClockMultiplexer: {
      if (!selectedFreq) return 0;
      const freqElement = selectedFreq
        .getConfigurationElements()
        .find((elem: Item) => {
          return elem.getItemRef() === item;
        });
      if (freqElement) return +freqElement.getValue();
      return 0;
    }

    case ItemType.ClockPLLCtrl:
    case ItemType.ClockRefCLKMultiplexer: {
      if (!selectedFreq) return 0;
      const freqElement = selectedFreq
        .getConfigurationElements()
        .find((elem: Item) => {
          return elem.getItemRef() === item && elem.getProp() === "selection";
        });
      if (freqElement) return +freqElement.getValue() === 0 ? 0 : 1;
      return 0;
    }

    default:
      return 0;
  }
};

const getDenomValue = (value: string) => {
  switch (Number(value)) {
    case 0:
      return 16;
    case 1:
      return 32;
    case 2:
      return 64;
    case 3:
      return 128;
    case 4:
      return 256;
    default:
      return 0;
  }
};
