/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import { Domain } from '@/model/types'
import UPFModuleFolderEvent from '@/model/src/events/UPFModuleFolderEvent'
import UPFModuleFolderChecker from '@/model/src/checkers/UPFModuleFolderChecker'
export default class UPFModuleFolder extends Item {
    FID_UPFMODULEFOLDER_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"UPFModuleFolder"}))
        
        this.setEvent(new UPFModuleFolderEvent(this))
        this.setChecker(new UPFModuleFolderChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "UPF"
        }
    }

    getDomain() {
        return Domain.UPF
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "UPFModuleFolder"
    }

    getSchema() {
        return {}
    }

    static getSchema() {
        return {}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "UPFModule", "auto": false, "searchable": false, "title": "UPF List", "headers": [{"title": "Name", "align": "start", "filterable": true, "value": "name", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    getFidLast() {
        return this.FID_UPFMODULEFOLDER_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            default:
                return ""
        }
    }


    newUPFModule(properties={}, title = "", domain = "") {
        return this.createChild(this, "UPFModule", properties, title, domain)
    }
    remUPFModule(obj) {
        return this.removeChild(obj)
    }
    getUPFModules() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "UPFModule")
            return res
        })
    }
}