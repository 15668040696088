/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x0004",
    "size": "0x4",
    "alignSize": "0x4",
    "sfrs": [
        {
            "name": "CTRL_APM_IN",
            "alignOffset": "0x0",
            "description": "Power Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_APM_IN",
                    "index": -1,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PWRDOWN Signal Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_APM_IN"
                }
            ]
        },
        {
            "name": "CTRL_APM_ENABLE",
            "alignOffset": "0x4",
            "description": "Power Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_APM_ENABLE",
                    "index": -1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PWRDOWN Signal Enable for {{name}}\n0 = Make PWR_REQ to Low When PWRDOWN Signal is Valid, is Disabled\n1 = Make PWR_REQ to Low When PWRDOWN Signal is Valid, is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_APM_ENABLE"
                }
            ]
        },
        {
            "name": "CTRL_APM_DIR",
            "alignOffset": "0x8",
            "description": "Power Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_APM_DIR",
                    "index": -1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "PWRDOWN Signal Valid Direction for {{name}}\n0 = PWRDOWN Signal is Valid at Low\n1 = PWRDOWN Signal is Valid at High",
                    "initValue": "0x0",
                    "nc": "SFR_APM_DIR"
                }
            ]
        }
    ]
}