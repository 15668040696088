/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemHandler from "../ItemHandler";
import ItemType from "../ItemType";

export default class ClockDividerEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), () => {});
    this.addPropertyListener(item.getFidECC(), () => {});
    this.addPropertyListener(item.getFidClkEn(), () => {});
    this.addPropertyListener(
      item.getFidMaxDivRatioWidth(),
      this.lstn_max_div_ratio_width
    );
    this.addPropertyListener(
      item.getFidDivRatioInitValue(),
      this.lstn_divRatioInitValue
    );
  }

  lstn_max_div_ratio_width(item) {
    ItemHandler.refreshSFRInfo(item);
  }

  lstn_divRatioInitValue(item) {
    ItemHandler.refreshSFRInfo(item);
  }

  postProcess(item) {
    super.postProcess(item);
    return this.setupPorts(item);
  }

  setupPorts(item) {
    return [
      item.newInput({
        key: `CLK_${ItemType.Input.getKey().toUpperCase()}`,
        socket: "CLK",
      }).promise,
      item.newOutput({
        key: `CLK_${ItemType.Output.getKey().toUpperCase()}`,
        socket: "CLK",
      }).promise,
    ];
  }
}
