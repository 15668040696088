import { ITDANode } from "@/canvas/nodes";
import { ItemType, Item } from "ITDAModelTypes";
import { InputSpec, OutputSpec } from "@/canvas/nodes/types";
import API from "@/api/internal";
import { ITDASocketType } from "@/canvas/common";

export const createNodePorts = (node: ITDANode, item: Item): Promise<void> => {
  const inputSpecs = getInputSpec(item);
  const outputSpecs = getOutputSpec(item);
  return node.build(inputSpecs, outputSpecs);
};

const getInputSpec = (item: Item): InputSpec[] => {
  const res: InputSpec[] = [];
  const targets =
    item.getItemType() === ItemType.ClockIP
      ? item.getClockIPInputs()
      : item.getInputs();

  targets.forEach((port: Item) => {
    res.push({
      name: port.getName(),
      key: port.getKey(),
      itemID: port.getId(),
      position: port.getPosition(),
      socket: port.getSocket(),
      multiple: port.getMultiple(),
      hidden: port.getHidden(),
    });
  });

  return res;
};

const getOutputSpec = (item: Item): OutputSpec[] => {
  const res: OutputSpec[] = [];
  const targets = item.getOutputs ? item.getOutputs() : [];
  targets.forEach((port: Item) => {
    res.push({
      name: port.getName(),
      key: port.getKey(),
      itemID: port.getId(),
      position: port.getPosition(),
      socket: port.getSocket(),
      multiple: port.getMultiple(),
    });
    if (
      item.getItemType() === ItemType.DUTInstance &&
      port.getSocket() === ITDASocketType.CLK.getKey()
    ) {
      const extLabel = getExtLabel(item.getModule(), port.getName());
      res[res.length - 1].maxNum = extLabel ? extLabel.getNumChild() : 1;
    }
  });

  return res;
};

const getExtLabel = (cmu: Item, name: string) => {
  let extLabel;
  for (const diagram of cmu.getClockDiagramFolder().getClockDiagrams()) {
    const labels = diagram
      .getLabels()
      .filter((item: Item) => API.getTitle(item) === name);
    if (labels.length) {
      extLabel = labels[0];
      break;
    }
  }
  return extLabel;
};
