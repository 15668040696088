/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x2000",
    "size": "0x400",
    "alignSize": "0x8",
    "sfrs": [
        {
            "name": "CTRL_QCH_EXT",
            "alignOffset": "0x4",
            "description": "Clock Component Extension Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_ENABLE_PWRDOWN",
                    "index": 16,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_PWRDOWN"
                },
                {
                    "name": "SFR_OVERRIDED_PWRDOWN",
                    "index": 24,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_OVERRIDED_PWRDOWN"
                }
            ]
        }
    ]
}