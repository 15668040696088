import { Context } from "../types";
import Canvas from "@/canvas";

export const nodedragged = async (context: Context): Promise<boolean> => {
  const area = Canvas.getAreaPlugin();
  const node = context.options.node;
  if (area.canAlignConnections(node.id)) {
    area.alignNodeToGrid(node.id);
  } else {
    area.alignNodeToGrid(node.id);
  }
  return true;
};
