import * as d3 from "d3";

export default {
  render: (elem: HTMLElement, width: number, height: number, color: string) => {
    const STROKE_WIDTH = 1.5;
    const rectW = width - STROKE_WIDTH * 2;
    const rectH = height - STROKE_WIDTH * 2;

    const svg = d3
      .select(elem)
      .append("svg")
      .attr("width", width)
      .attr("height", height);

    const g = svg.append("g");

    g.append("rect")
      .attr("x", STROKE_WIDTH)
      .attr("y", STROKE_WIDTH)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("width", rectW)
      .attr("height", rectH)
      .attr("stroke", "black")
      .attr("stroke-width", STROKE_WIDTH)
      .attr("fill", color);

    const centerX = width / 2;
    const centerY = height / 2;
    const innerStroke = 2;

    // const pointSpace = width / 16;
    const pointSpace = 5;

    const points = [
      { x: centerX, y: centerY / 2 + pointSpace },
      { x: centerX + pointSpace * 2, y: centerY / 2 + pointSpace },
      { x: centerX + 1, y: centerY },
      { x: centerX + pointSpace * 2, y: centerY },
      { x: centerX - pointSpace * 1.6, y: centerY * 1.5 + 5 },
      { x: centerX - pointSpace, y: centerY + pointSpace * 1.6 },
      { x: centerX - pointSpace * 2.6, y: centerY + pointSpace * 1.6 },
      { x: centerX - pointSpace * 1.6, y: centerY / 2 + 5 },
      { x: centerX, y: centerY / 2 + pointSpace },
    ];

    const lineGenerator = d3
      .line<{ x: number; y: number }>()
      .x((d) => d.x)
      .y((d) => d.y)
      .curve(d3.curveLinear);

    if (points && points.length > 0) {
      const pathData = lineGenerator(points);

      if (pathData) {
        g.append("path")
          .attr("d", pathData)
          .attr("fill", "none")
          .attr("stroke", "black")
          .attr("stroke-width", innerStroke);
      } else {
        console.error("No pathData");
      }
    } else {
      console.error("No points");
    }

    g.append("text")
      .attr("x", centerX)
      .attr("y", 15)
      .attr("text-anchor", "middle")
      .attr("font-size", `10px`)
      .attr("fill", "black")
      .text("APM");

    g.append("text")
      .attr("x", centerX * 1.5)
      .attr("y", (centerY / 4) * 6)
      .attr("text-anchor", "middle")
      .attr("font-size", `5px`)
      .attr("font-weight", "bold")
      .attr("fill", "black")
      .text("Auto Power");

    g.append("text")
      .attr("x", centerX * 1.5)
      .attr("y", (centerY / 4) * 6 + 5)
      .attr("text-anchor", "middle")
      .attr("font-size", `5px`)
      .attr("fill", "black")
      .attr("font-weight", "bold")
      .text("Management");
  },
};
