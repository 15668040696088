/**
 *  Generated by Modeler
 *  timestamp: 2023-07-25 16:58:29.538289
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class UPFDiagramEvent extends ItemEvent {
  constructor(item) {
    super(item);
  }
}
