import { NodeType } from "@/canvas/types";
import { Context } from "../types";
import Canvas from "@/canvas";
import API from "@/api/internal";
import utils from "../utils";

export const nodetranslated = async (context: Context): Promise<boolean> => {
  const editor = Canvas.getEditor();
  const node = editor.getNode(context.options.id);

  const item = API.getItem(node.getItemID());
  if (item) {
    const nodeMetaView = item.getNodeMetaView();
    nodeMetaView.setX(context.options.position.x);
    nodeMetaView.setY(context.options.position.y);

    switch (node.getType()) {
      case NodeType.ITDASEQSTART:
        utils.translateChildren(item);
        break;
      case NodeType.ITDASEQWRITE:
      case NodeType.ITDASEQREADWAIT:
      case NodeType.ITDASEQWAIT:
      case NodeType.ITDASEQIF:
      case NodeType.ITDASEQGOTO:
      case NodeType.ITDASEQCALL: {
        const posX = context.options.position.x;
        const posY = context.options.position.y;
        utils.translateChildren(item);
        await utils.translateUpSequenceGroup(item, posX, posY);
        await utils.translateDownSequenceGroup(item, posX, posY);
        break;
      }
      default:
        break;
    }
  }

  return true;
};
