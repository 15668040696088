/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x1800",
    "size": "0x800",
    "alignSize": "0x8",
    "sfrs": [
        {
            "name": "CTRL_GATE_EXT",
            "alignOffset": "0x4",
            "description": "Clock Component Extension Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_SHORTSTOP_CYCLE",
                    "index": 0,
                    "size": 4,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Define How many cycles of Gate Clock output is gated at SHORTSTOP\n(This Field is only valid when SFR_ENABLE_SHORTSTOP = 1)",
                    "initValue": "0x0",
                    "nc": "SFR_SHORTSTOP_CYCLE"
                },
                {
                    "name": "SFR_ENABLE_SHORTSTOP",
                    "index": 18,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Gate Enable Automatic change Control for {{name}}\n0 = Gate Enable Automatic change to 0 at SHORTSTOP is Disabled\n1 = Gate Enable Automatic change to 0 at SHORTSTOP is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_SHORTSTOP"
                },
                {
                    "name": "SFR_ENABLE_EWAKEUP",
                    "index": 20,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "ACG_ACTIVE Automatic change Control for {{name}}\n0 = ACG_ACTIVE Control by EWAKEUP is Disabled\n1 = ACG_ACTIVE Control by EWAKEUP is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_ENABLE_EWAKEUP"
                },
                {
                    "name": "SFR_OVERRIDED_SHORTSTOP",
                    "index": 26,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Gate Enable Override Indication for {{name}}\n0 = Gate Enable is Controlled by SFR_ENABLE\n1 = Gate Enable is Overrided by SHORTSTOP",
                    "initValue": "0x0",
                    "nc": "SFR_OVERRIDED_SHORTSTOP"
                },
                {
                    "name": "SFR_OVERRIDED_CUSTOM",
                    "index": 27,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Gate Enable Override Indication for {{name}}\n0 = Gate Enable is Controlled by SFR_ENABLE\n1 = Gate Enable is Overrided by CUSTOM",
                    "initValue": "0x0",
                    "nc": "SFR_OVERRIDED_CUSTOM"
                },
                {
                    "name": "SFR_OVERRIDED_EWAKEUP",
                    "index": 28,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "ACG_ACTIVE by EWAKEUP Indication for {{name}}\n0 = ACG_ACTIVE by EWAKEUP is Low\n1 = ACG_ACTIVE by EWAKEUP is High",
                    "initValue": "0x0",
                    "nc": "SFR_OVERRIDED_EWAKEUP"
                }
            ]
        }
    ]
}