/**
 *  Generated by Modeler
 *  timestamp: 2023-01-18 10:23:06.343589
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class ConnectionEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidSourceOutput(), this.lstn_sourceOutput);
    this.addPropertyListener(item.getFidTargetInput(), this.lstn_targetInput);
  }
  lstn_sourceOutput(item, newVal) {
    newVal?.setConnections([...newVal.getConnections(), item]);
  }
  lstn_targetInput(item, newVal) {
    newVal?.setConnections([...newVal.getConnections(), item]);
  }
}
