import * as canvas from "./canvas";
import * as title from "./title";
import * as desc from "./desc";
import * as windows from "./windows";

export default {
  ...canvas,
  ...title,
  ...desc,
  ...windows,
};
