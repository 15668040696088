export default class ErrorType {
  static NAME = new ErrorType(0, "NAME", "Name has a problem.");
  static CONNECTION = new ErrorType(
    0,
    "CONNECTION",
    "Input/Output has to be connected."
  );
  static VALUE = new ErrorType(0, "VALUE", "Value has a problem.");
  static EMPTY = new ErrorType(0, "EMPTY", "Data is empty.");

  static PLL = new ErrorType(0, "PLL", "PLL Data has a problem.");
  static CONFIG = new ErrorType(1, "CONFIG", "Configuration Error.");
  static INSTANCE = new ErrorType(1, "INSTANCE", "Instance Error.");
  static CLOCK_DIAGRAM = new ErrorType(
    1,
    "CLOCK_DIAGRAM",
    "Clock Diagram Error."
  );
  static POWER_DIAGRAM = new ErrorType(
    1,
    "POWER_DIAGRAM",
    "Power Diagram Error."
  );

  static SEQUENCE = new ErrorType(
    1,
    "SEQUENCE",
    "Power Control Sequence has a problem."
  );
  static SEQUENCE_ROM = new ErrorType(
    1,
    "SEQUENCE_ROM",
    "ROM Sequence has a problem."
  );
  static SEQUENCE_RAM = new ErrorType(
    1,
    "SEQUENCE_RAM",
    "RAM Sequence has a problem."
  );

  constructor(level, name, desc) {
    this.level = level;
    this.name = name;
    this.desc = desc;
  }

  getLevel() {
    return this.level;
  }

  getName() {
    return this.name;
  }

  setDescription(desc) {
    this.desc = desc;
  }

  getDescription() {
    return this.desc;
  }
}
