/**
 *  Generated by Modeler
 *  timestamp: 2023-07-25 16:58:29.540289
 */
//import ErrorType from '@/model/ErrorType'
import ItemChecker from "@/model/src/ItemChecker";

export default class UPFDiagramChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
    //this.addChecker(item.getFidType(), this.checkType, ErrorType.NAME)
    //this.addChecker(item.getFidCanvasData(), this.checkCanvasData, ErrorType.NAME)
  }
  checkName() {
    // TBD
  }
  checkType() {
    // TBD
  }
  checkCanvasData() {
    // TBD
  }
}
