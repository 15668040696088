/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x200",
    "size": "0x8",
    "alignSize": "0x8",
    "sfrs": [
        {
            "name": "OM_STATUS",
            "alignOffset": "0x0",
            "description": "OM Value for {{name}}",
            "fields": [
                {
                    "name": "SFR_OM",
                    "index": 0,
                    "size": 3,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "OM Value for {{name}}",
                    "initValue": "0x0",
                    "nc": "SFR_OM"
                }
            ]
        },
        {
            "name": "OM_DECODE",
            "alignOffset": "0x4",
            "description": "OM Value Decoding for {{name}}",
            "fields": [
                {
                    "name": "SFR_OM_000",
                    "index": 0,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "OM Value Decoding for {{name}}\nThis Field is High when OM value = 000",
                    "initValue": "0x0",
                    "nc": "SFR_OM_000"
                },
                {
                    "name": "SFR_OM_001",
                    "index": 1,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "OM Value Decoding for {{name}}\nThis Field is High when OM value = 001",
                    "initValue": "0x0",
                    "nc": "SFR_OM_001"
                },
                {
                    "name": "SFR_OM_010",
                    "index": 2,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "OM Value Decoding for {{name}}\nThis Field is High when OM value = 010",
                    "initValue": "0x0",
                    "nc": "SFR_OM_010"
                },
                {
                    "name": "SFR_OM_011",
                    "index": 3,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "OM Value Decoding for {{name}}\nThis Field is High when OM value = 011",
                    "initValue": "0x0",
                    "nc": "SFR_OM_011"
                },
                {
                    "name": "SFR_OM_100",
                    "index": 4,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "OM Value Decoding for {{name}}\nThis Field is High when OM value = 100",
                    "initValue": "0x0",
                    "nc": "SFR_OM_100"
                },
                {
                    "name": "SFR_OM_101",
                    "index": 5,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "OM Value Decoding for {{name}}\nThis Field is High when OM value = 101",
                    "initValue": "0x0",
                    "nc": "SFR_OM_101"
                },
                {
                    "name": "SFR_OM_110",
                    "index": 6,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "OM Value Decoding for {{name}}\nThis Field is High when OM value = 110",
                    "initValue": "0x0",
                    "nc": "SFR_OM_110"
                },
                {
                    "name": "SFR_OM_111",
                    "index": 7,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "OM Value Decoding for {{name}}\nThis Field is High when OM value = 111",
                    "initValue": "0x0",
                    "nc": "SFR_OM_111"
                }
            ]
        }
    ]
}