/**
 *  Generated by Modeler
 *  timestamp: 2024-09-04 14:00:26.407911
 */
//import ErrorType from '@/model/src/ErrorType'
import ItemChecker from '@/model/src/ItemChecker'

export default class UPFPhantomGroundChecker extends ItemChecker {
    constructor(item) {
        super(item)
        //this.addChecker(item.getFidOriginItem(), this.checkOriginItem, ErrorType.NAME)
        //this.addChecker(item.getFidPowerSource(), this.checkPowerSource, ErrorType.NAME)
        //this.addChecker(item.getFidMappedModule(), this.checkMappedModule, ErrorType.NAME)
        //this.addChecker(item.getFidMappedPower(), this.checkMappedPower, ErrorType.NAME)
    }
    checkOriginItem() {
        // TBD
    }
    checkPowerSource() {
        // TBD
    }
    checkMappedModule() {
        // TBD
    }
    checkMappedPower() {
        // TBD
    }
}