import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodePower from "./ITDANodePower";

export default class ITDAPMRCTRL extends ITDANodePower {
  constructor(name = "") {
    super(name, NodeType.ITDAPMRCTRL);
    this.render = ITDANodeRenderer.ITDAPMRCTRL.render;
  }
}
