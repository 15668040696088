import configuration from "./configuration";
import schema from "./schema";
import item from "./item";
import * as utils from "./utils";

export default {
  ...configuration,
  ...schema,
  ...item,
  ...utils,
};
