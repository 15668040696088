/**
 *  Generated by Modeler
 *  timestamp: 2023-04-05 20:24:58.494168
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class UPFElementEvent extends ItemEvent {
  constructor(item) {
    super(item);
  }
}
