import ITDACanvas from "../ITDACanvas";
import ITDAEditor from "../ITDAEditor";
import {
  ITDAAreaPlugin,
  ITDAConnectionReRoutePlugin,
  ITDADataFlowPlugin,
  ITDADockPlugin,
  ITDAScopesPlugin,
} from "../plugins";
import { Repository } from "../store";

const repo = Repository.getInstance();
export default {
  getCurrentID: (): string => {
    return repo.getCurrentID();
  },
  getPlugins: (id: string): ITDACanvas[] => {
    return repo.getPlugins(id);
  },
  getEditor: (id?: string): ITDAEditor => {
    return repo.getEditor(id ? id : repo.getCurrentID());
  },
  getAreaPlugin: (id?: string): ITDAAreaPlugin => {
    return repo.getAreaPlugin(id ? id : repo.getCurrentID());
  },
  getScopesPlugin: (id?: string): ITDAScopesPlugin => {
    return repo.getScopesPlugin(id ? id : repo.getCurrentID());
  },
  getDockPlugin: (id?: string): ITDADockPlugin => {
    return repo.getDockPlugin(id ? id : repo.getCurrentID());
  },
  getDataFlowPlugin: (id?: string): ITDADataFlowPlugin => {
    return repo.getDataFlowPlugin(id ? id : repo.getCurrentID());
  },
  getConnectionReRoutePlugin: (id?: string): ITDAConnectionReRoutePlugin => {
    return repo.getConnectionReRoutePlugin(id ? id : repo.getCurrentID());
  },
};
