import { reactive } from "vue";
import Factory from "../gen/Factory";
import ItemType from "./ItemType";

export default class ItemFactory {
  static getConstructor(type) {
    return Factory.getConstructor(type);
  }
  static getSFRItemTarget(type) {
    return Factory.getSFRItemTarget(type);
  }

  static getSFRItemTargets() {
    return Factory.getSFRItemTargets();
  }

  static createItemInstance(type, obj, pllSpec = [], hmSpec = []) {
    const item = Factory.createItemInstance(type, obj);
    if (item) {
      switch (type) {
        case ItemType.ClockPLLCtrl:
          item._schema.plltype.targets = pllSpec.map((spec) => spec.name);
          break;
        case ItemType.UPFHardMacro:
          if (hmSpec) {
            item._schema = item.getSchema();
            hmSpec.forEach((spec) => item._schema.type.targets.push(spec.name));
          }
          break;
        default:
          break;
      }
    }
    return reactive(item);
  }
}
