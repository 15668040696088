import ItemEvent from "@/model/src/ItemEvent";
import Domain from "../Domain";
import ItemHandler from "../ItemHandler";
import ItemType from "../ItemType";
import NodeType from "../NodeType";

export default class ProjectEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
  }

  setup(item, val) {
    item
      .getClockCMUFolder()
      .getClockCMUs()
      .forEach((cmu) => {
        if (cmu.getClockGate()) {
          ItemHandler.removeItem(cmu.getClockGate());
        }
        if (cmu.getClockQChannelManager()) {
          ItemHandler.removeItem(cmu.getClockQChannelManager());
        }
      });
    if (val) {
      item
        .getClockCMUFolder()
        .getClockCMUs()
        .forEach((cmu) => {
          ItemHandler.createItem(cmu, ItemType.ClockGate);
          ItemHandler.createItem(cmu, ItemType.ClockQChannelManager);
        });
    }
    item
      .getClockCMUFolder()
      .getClockCMUs()
      .reduce((acc, cur) => {
        acc = acc.concat(cur.getClockDiagramFolder().getClockDiagrams());
        acc = acc.concat(cur.getClockIPDiagram());
        return acc;
      }, [])
      .reduce((acc, cur) => {
        acc = acc.concat(
          cur.getChildren().filter((nodeItem) => nodeItem.isNodeType())
        );
        return acc;
      }, [])
      .forEach((nodeItem) => nodeItem.getEvent().refreshCLKCOMPSFR(nodeItem));

    item
      .getClockCMUFolder()
      .getClockCMUs()
      .forEach((cmu) => {
        let sfrBlock = cmu.getSFRBlock();
        sfrBlock.setSorted(false);
        sfrBlock.setSorted(true);
      });
  }

  lstn_name(item, newVal) {
    item.setTitle(newVal);
  }
}
