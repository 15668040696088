<template>
  <ItemPropertyLabel :props="props" :isTitle="false" />
  <v-autocomplete
    v-model="model"
    autocomplete="nope"
    class="autocomplete"
    :disabled="
      Utils.isDisabledAutocomplete(props.item, props.propKey, props.prop)
    "
    :items="Utils.getTargets(props.prop)"
    :multiple="Utils.isMultiple(props.prop)"
    :closable-chips="true"
    chips
    color="#1e41cd"
    :menu-props="{
      contentClass: 'autocomplete-selection',
    }"
    :error-messages="props.errorMessages"
    :hide-details="!props.errorMessages"
    variant="outlined"
    @update:model-value="
      props.itemRef
        ? props.itemRef.event(props.item, props.itemRef, $event)
        : Utils.update(props.item, props.propKey, String, $event)
    ">
    <template v-if="!Utils.isMultiple(props.prop)" v-slot:chip="data">
      <v-chip
        v-bind="data.props"
        closable
        :item="data.item"
        :text="data.item.value"></v-chip>
    </template>
    <template v-slot:selection="{ props, item }">
      <v-list-item
        v-bind="props"
        :item="item"
        :title="item.value"></v-list-item>
    </template>
  </v-autocomplete>
  <div v-if="props.children && Utils.showChildren(props.item)">
    <v-divider class="autocomplete__expanded-divider"></v-divider>
    <v-row
      v-for="(prop, key) in props.children"
      :key="key"
      class="property__row">
      <v-col class="property__col" overflow="hidden">
        <ItemPropertyIntegerField
          v-if="Utils.isIntegerType(Object.values(prop)[key])"
          v-bind="getProps(Object.keys(prop)[key], Object.values(prop)[key])"
          :expanded="true" />
      </v-col>
    </v-row>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, reactive } from "vue";
import API from "@/api/internal";
import Utils from "@/components/item/utils";
import { ItemPropsType, Property, ItemType } from "ITDAModelTypes";
import ItemPropertyLabel from "./ItemPropertyLabel.vue";
import ItemPropertyIntegerField from "./ItemPropertyIntegerField.vue";
const props = defineProps<ItemPropsType>();
const value = props.itemRef
  ? props.itemRef.model
  : Utils.getValue(props.item, props.propKey);
const getProps = (key: string, prop: Property): ItemPropsType => {
  const res = {
    item: props.item,
    propKey: key.toString(),
    prop: prop,
    errorMessages: API.getErrorMessages(props.item, key),
  };
  if (prop.children) {
    Object.assign(res, { children: prop.children });
  }
  return res;
};
const model = Array.isArray(value) ? reactive(value) : ref(value);
</script>

<style lang="sass" scoped>
.autocomplete
  margin-bottom: 0
.autocomplete__expanded-divider
  margin: 0.5rem 0

.v-list-item
.v-list-item-title
.v-combobox
  min-height: 10px

.v-autocomplete *
  --v-input-control-height: 38px
  --v-field-input-padding-top: 7px
  --v-field-input-padding-bottom: 7px
  --v-field-padding-start: 10px
  --v-field-padding-end: 10px
  --v-field-autocomplete-selection-gap: 100px
</style>
