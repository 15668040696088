/**
 *  Generated by Modeler
 *  timestamp: 2022-08-22 12:24:20.652766
 */
//import ErrorType from '@/model/ErrorType'
import ItemChecker from "@/model/src/ItemChecker";

export default class SFRBlockChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME)
  }
  checkName() {
    // TBD
  }
}
