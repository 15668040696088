/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0xE0",
    "size": "0x20",
    "alignSize": "0x4",
    "sfrs": [
        {
            "name": "CTRL_HANDSHAKE",
            "alignOffset": "0x0",
            "description": "Power Component Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_REQ",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "HANDSHAKE Control Signal for {{name}}\n0 = Set REQ to 0\n1 = Set REQ to 1",
                    "initValue": "0x0",
                    "nc": "SFR_REQ"
                },
                {
                    "name": "SFR_ACK",
                    "index": 16,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "HANDSHAKE Control Signal for {{name}}\n0 = ACK is Low\n1 = ACK is High",
                    "initValue": "0x0",
                    "nc": "SFR_ACK"
                }
            ]
        }
    ]
}