/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import ClockDividerEvent from '@/model/src/events/ClockDividerEvent'
import ClockDividerChecker from '@/model/src/checkers/ClockDividerChecker'
import ITDA_CMU_DIVIDER_PT from '@/model/gen/sfrSpec/ITDA_CMU_DIVIDER_PT'
export default class ClockDivider extends Node {
    FID_CLOCKDIVIDER_NAME = 100
    FID_CLOCKDIVIDER_MAXNUM = 101
    FID_CLOCKDIVIDER_ECC = 102
    FID_CLOCKDIVIDER_CLKEN = 103
    FID_CLOCKDIVIDER_CLKENVALUE = 104
    FID_CLOCKDIVIDER_MAXDIVRATIOWIDTH = 105
    FID_CLOCKDIVIDER_DIVRATIOINITVALUE = 106
    FID_CLOCKDIVIDER_SDCPATH = 107
    FID_CLOCKDIVIDER_LAST = 107

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockDivider"}))
        
        this.setEvent(new ClockDividerEvent(this))
        this.setChecker(new ClockDividerChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.maxNum === undefined) {
        this.properties.maxNum = 128
    }
    if (this.properties.ECC === undefined) {
        this.properties.ECC = false
    }
    if (this.properties.clkEn === undefined) {
        this.properties.clkEn = false
    }
    if (this.properties.clkEnValue === undefined) {
        this.properties.clkEnValue = 0
    }
    if (this.properties.maxDivRatioWidth === undefined) {
        this.properties.maxDivRatioWidth = 4
    }
    if (this.properties.divRatioInitValue === undefined) {
        this.properties.divRatioInitValue = 0
    }
    if (this.properties.SDCPath === undefined) {
        this.properties.SDCPath = "/{{CMU}}/{{NODE}}/{{NODE}}__CLKCOMP_DIVIDER/{{NODE}}__CLKCOMP_DIVIDER__DESKEWER__CLKNAND3_OUT/$POINT$ITDA_GENERIC_CLKNAND3$Y"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockDivider"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 128, "category": "IP", "multiple": false, "readOnly": false}, "ECC": {"type": "boolean", "label": "External Clock Path", "desc": "Control External Clock", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "clkEn": {"type": "boolean", "label": "Use CLKEN", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "clkEnValue": {"type": "integer", "label": "CLKEN Value", "desc": "it must be less than div ratio", "hidden": false, "default": 0, "category": "IP", "multiple": false, "readOnly": false}, "maxDivRatioWidth": {"type": "integer", "label": "DIVRATIO Width", "desc": "maximum div ratio width", "default": 4, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "divRatioInitValue": {"type": "integer", "label": "DIVRATIO Reset Value", "desc": "div ratio reset value", "default": 0, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "/{{CMU}}/{{NODE}}/{{NODE}}__CLKCOMP_DIVIDER/{{NODE}}__CLKCOMP_DIVIDER__DESKEWER__CLKNAND3_OUT/$POINT$ITDA_GENERIC_CLKNAND3$Y", "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 128, "category": "IP", "multiple": false, "readOnly": false}, "ECC": {"type": "boolean", "label": "External Clock Path", "desc": "Control External Clock", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "clkEn": {"type": "boolean", "label": "Use CLKEN", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "clkEnValue": {"type": "integer", "label": "CLKEN Value", "desc": "it must be less than div ratio", "hidden": false, "default": 0, "category": "IP", "multiple": false, "readOnly": false}, "maxDivRatioWidth": {"type": "integer", "label": "DIVRATIO Width", "desc": "maximum div ratio width", "default": 4, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "divRatioInitValue": {"type": "integer", "label": "DIVRATIO Reset Value", "desc": "div ratio reset value", "default": 0, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "/{{CMU}}/{{NODE}}/{{NODE}}__CLKCOMP_DIVIDER/{{NODE}}__CLKCOMP_DIVIDER__DESKEWER__CLKNAND3_OUT/$POINT$ITDA_GENERIC_CLKNAND3$Y", "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ClockDividerEXT", "auto": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_CMU_DIVIDER_PT
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_CMU_DIVIDER_PT
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIVIDER_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKDIVIDER_NAME
    }
    

    setMaxNum(maxNum, event=true) {
        if ((maxNum !== null) && (maxNum !== undefined)) {
            if (![Number].includes(maxNum.constructor)) {
                throw `maxNum(${maxNum}) must be [Number] type`
            }
        }
        const newVal = (maxNum === null || maxNum === undefined) ? undefined : maxNum
        const oldVal = this.properties.maxNum
        this.properties.maxNum = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIVIDER_MAXNUM, newVal, oldVal)
        }
    }
    getMaxNum(pure = false) {
        return this.properties.maxNum
        
    }
    getFidMaxNum() {
        return this.FID_CLOCKDIVIDER_MAXNUM
    }
    

    setECC(ECC, event=true) {
        if ((ECC !== null) && (ECC !== undefined)) {
            if (![Boolean].includes(ECC.constructor)) {
                throw `ECC(${ECC}) must be [Boolean] type`
            }
        }
        const newVal = (ECC === null || ECC === undefined) ? undefined : ECC
        const oldVal = this.properties.ECC
        this.properties.ECC = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIVIDER_ECC, newVal, oldVal)
        }
    }
    getECC(pure = false) {
        return this.properties.ECC
        
    }
    getFidECC() {
        return this.FID_CLOCKDIVIDER_ECC
    }
    

    setClkEn(clkEn, event=true) {
        if ((clkEn !== null) && (clkEn !== undefined)) {
            if (![Boolean].includes(clkEn.constructor)) {
                throw `clkEn(${clkEn}) must be [Boolean] type`
            }
        }
        const newVal = (clkEn === null || clkEn === undefined) ? undefined : clkEn
        const oldVal = this.properties.clkEn
        this.properties.clkEn = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIVIDER_CLKEN, newVal, oldVal)
        }
    }
    getClkEn(pure = false) {
        return this.properties.clkEn
        
    }
    getFidClkEn() {
        return this.FID_CLOCKDIVIDER_CLKEN
    }
    

    setClkEnValue(clkEnValue, event=true) {
        if ((clkEnValue !== null) && (clkEnValue !== undefined)) {
            if (![Number].includes(clkEnValue.constructor)) {
                throw `clkEnValue(${clkEnValue}) must be [Number] type`
            }
        }
        const newVal = (clkEnValue === null || clkEnValue === undefined) ? undefined : clkEnValue
        const oldVal = this.properties.clkEnValue
        this.properties.clkEnValue = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIVIDER_CLKENVALUE, newVal, oldVal)
        }
    }
    getClkEnValue(pure = false) {
        return this.properties.clkEnValue
        
    }
    getFidClkEnValue() {
        return this.FID_CLOCKDIVIDER_CLKENVALUE
    }
    

    setMaxDivRatioWidth(maxDivRatioWidth, event=true) {
        if ((maxDivRatioWidth !== null) && (maxDivRatioWidth !== undefined)) {
            if (![Number].includes(maxDivRatioWidth.constructor)) {
                throw `maxDivRatioWidth(${maxDivRatioWidth}) must be [Number] type`
            }
        }
        const newVal = (maxDivRatioWidth === null || maxDivRatioWidth === undefined) ? undefined : maxDivRatioWidth
        const oldVal = this.properties.maxDivRatioWidth
        this.properties.maxDivRatioWidth = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIVIDER_MAXDIVRATIOWIDTH, newVal, oldVal)
        }
    }
    getMaxDivRatioWidth(pure = false) {
        return this.properties.maxDivRatioWidth
        
    }
    getFidMaxDivRatioWidth() {
        return this.FID_CLOCKDIVIDER_MAXDIVRATIOWIDTH
    }
    

    setDivRatioInitValue(divRatioInitValue, event=true) {
        if ((divRatioInitValue !== null) && (divRatioInitValue !== undefined)) {
            if (![Number].includes(divRatioInitValue.constructor)) {
                throw `divRatioInitValue(${divRatioInitValue}) must be [Number] type`
            }
        }
        const newVal = (divRatioInitValue === null || divRatioInitValue === undefined) ? undefined : divRatioInitValue
        const oldVal = this.properties.divRatioInitValue
        this.properties.divRatioInitValue = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIVIDER_DIVRATIOINITVALUE, newVal, oldVal)
        }
    }
    getDivRatioInitValue(pure = false) {
        return this.properties.divRatioInitValue
        
    }
    getFidDivRatioInitValue() {
        return this.FID_CLOCKDIVIDER_DIVRATIOINITVALUE
    }
    

    setSDCPath(SDCPath, event=true) {
        if ((SDCPath !== null) && (SDCPath !== undefined)) {
            if (![String].includes(SDCPath.constructor)) {
                throw `SDCPath(${SDCPath}) must be [String] type`
            }
        }
        const newVal = (SDCPath === null || SDCPath === undefined) ? undefined : SDCPath
        const oldVal = this.properties.SDCPath
        this.properties.SDCPath = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIVIDER_SDCPATH, newVal, oldVal)
        }
    }
    getSDCPath(pure = false) {
        return this.properties.SDCPath
        
    }
    getFidSDCPath() {
        return this.FID_CLOCKDIVIDER_SDCPATH
    }
    

    getFidLast() {
        return this.FID_CLOCKDIVIDER_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKDIVIDER_NAME:
                return "name"

            case this.FID_CLOCKDIVIDER_MAXNUM:
                return "maxNum"

            case this.FID_CLOCKDIVIDER_ECC:
                return "ECC"

            case this.FID_CLOCKDIVIDER_CLKEN:
                return "clkEn"

            case this.FID_CLOCKDIVIDER_CLKENVALUE:
                return "clkEnValue"

            case this.FID_CLOCKDIVIDER_MAXDIVRATIOWIDTH:
                return "maxDivRatioWidth"

            case this.FID_CLOCKDIVIDER_DIVRATIOINITVALUE:
                return "divRatioInitValue"

            case this.FID_CLOCKDIVIDER_SDCPATH:
                return "SDCPath"

            default:
                return ""
        }
    }


    newClockDividerEXT(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockDividerEXT", properties, title, domain)
    }
    remClockDividerEXT(obj) {
        return this.removeChild(obj)
    }
    getClockDividerEXT() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "ClockDividerEXT")
            return res
        })
    }
}