/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerPMRLATCHEvent from '@/model/src/events/PowerPMRLATCHEvent'
import PowerPMRLATCHChecker from '@/model/src/checkers/PowerPMRLATCHChecker'
import ITDA_DUT_PMS_PMC_SFR_PSM_LATCH from '@/model/gen/sfrSpec/ITDA_DUT_PMS_PMC_SFR_PSM_LATCH'
export default class PowerPMRLATCH extends Node {
    FID_POWERPMRLATCH_NAME = 100
    FID_POWERPMRLATCH_MAXNUM = 101
    FID_POWERPMRLATCH_LAST = 101

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerPMRLATCH"}))
        
        this.setEvent(new PowerPMRLATCHEvent(this))
        this.setChecker(new PowerPMRLATCHChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "PowerPMRLATCH"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "LATCH_NONAME"
    }
    if (this.properties.maxNum === undefined) {
        this.properties.maxNum = 8
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerPMRLATCH"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "LATCH_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 8, "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "LATCH_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 8, "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_DUT_PMS_PMC_SFR_PSM_LATCH
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_DUT_PMS_PMC_SFR_PSM_LATCH
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRLATCH_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERPMRLATCH_NAME
    }
    

    setMaxNum(maxNum, event=true) {
        if ((maxNum !== null) && (maxNum !== undefined)) {
            if (![Number].includes(maxNum.constructor)) {
                throw `maxNum(${maxNum}) must be [Number] type`
            }
        }
        const newVal = (maxNum === null || maxNum === undefined) ? undefined : maxNum
        const oldVal = this.properties.maxNum
        this.properties.maxNum = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRLATCH_MAXNUM, newVal, oldVal)
        }
    }
    getMaxNum(pure = false) {
        return this.properties.maxNum
        
    }
    getFidMaxNum() {
        return this.FID_POWERPMRLATCH_MAXNUM
    }
    

    getFidLast() {
        return this.FID_POWERPMRLATCH_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERPMRLATCH_NAME:
                return "name"

            case this.FID_POWERPMRLATCH_MAXNUM:
                return "maxNum"

            default:
                return ""
        }
    }


}