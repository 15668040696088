export default class EventType {
    static PROP = new EventType(0, "prop")
    static ADD = new EventType(0, "array")
    static REM = new EventType(1, "array")
    static CLR = new EventType(2, "array")

    constructor(index, name) {
        this.index = index
        this.name = name
    }

    getIndex() {
        return this.index
    }
    getName() {
        return this.name
    }
}