/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import ClockFreqmonEvent from '@/model/src/events/ClockFreqmonEvent'
import ClockFreqmonChecker from '@/model/src/checkers/ClockFreqmonChecker'
import ITDA_CMU_FREQMON_PT from '@/model/gen/sfrSpec/ITDA_CMU_FREQMON_PT'
export default class ClockFreqmon extends Node {
    FID_CLOCKFREQMON_NAME = 100
    FID_CLOCKFREQMON_SDCPATH = 101
    FID_CLOCKFREQMON_LAST = 101

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockFreqmon"}))
        
        this.setEvent(new ClockFreqmonEvent(this))
        this.setChecker(new ClockFreqmonChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.SDCPath === undefined) {
        this.properties.SDCPath = ""
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockFreqmon"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "", "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "SDCPath": {"type": "string", "hidden": true, "default": "", "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ClockFreqmonEXT", "auto": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_CMU_FREQMON_PT
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_CMU_FREQMON_PT
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKFREQMON_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKFREQMON_NAME
    }
    

    setSDCPath(SDCPath, event=true) {
        if ((SDCPath !== null) && (SDCPath !== undefined)) {
            if (![String].includes(SDCPath.constructor)) {
                throw `SDCPath(${SDCPath}) must be [String] type`
            }
        }
        const newVal = (SDCPath === null || SDCPath === undefined) ? undefined : SDCPath
        const oldVal = this.properties.SDCPath
        this.properties.SDCPath = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKFREQMON_SDCPATH, newVal, oldVal)
        }
    }
    getSDCPath(pure = false) {
        return this.properties.SDCPath
        
    }
    getFidSDCPath() {
        return this.FID_CLOCKFREQMON_SDCPATH
    }
    

    getFidLast() {
        return this.FID_CLOCKFREQMON_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKFREQMON_NAME:
                return "name"

            case this.FID_CLOCKFREQMON_SDCPATH:
                return "SDCPath"

            default:
                return ""
        }
    }


    newClockFreqmonEXT(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockFreqmonEXT", properties, title, domain)
    }
    remClockFreqmonEXT(obj) {
        return this.removeChild(obj)
    }
    getClockFreqmonEXT() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "ClockFreqmonEXT")
            return res
        })
    }
}