/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import Node from './Node'
import PowerPMRCTRLEvent from '@/model/src/events/PowerPMRCTRLEvent'
import PowerPMRCTRLChecker from '@/model/src/checkers/PowerPMRCTRLChecker'
import ITDA_DUT_PMS_PMC_SFR_PSM_CTRL from '@/model/gen/sfrSpec/ITDA_DUT_PMS_PMC_SFR_PSM_CTRL'
export default class PowerPMRCTRL extends Node {
    FID_POWERPMRCTRL_NAME = 100
    FID_POWERPMRCTRL_ISCLDBOOT = 101
    FID_POWERPMRCTRL_MAXNUM = 102
    FID_POWERPMRCTRL_LAST = 102

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerPMRCTRL"}))
        
        this.setEvent(new PowerPMRCTRLEvent(this))
        this.setChecker(new PowerPMRCTRLChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "PowerPMRCTRL"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "PMRCTRL_NONAME"
    }
    if (this.properties.isCLDBoot === undefined) {
        this.properties.isCLDBoot = false
    }
    if (this.properties.maxNum === undefined) {
        this.properties.maxNum = 16
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerPMRCTRL"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "PMRCTRL_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "isCLDBoot": {"type": "boolean", "label": "Only Reset at CLDBOOT_RESETn", "desc": "DATA0~15 fields are only Only Reset at CLDBOOT_RESETn", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 16, "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "PMRCTRL_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "isCLDBoot": {"type": "boolean", "label": "Only Reset at CLDBOOT_RESETn", "desc": "DATA0~15 fields are only Only Reset at CLDBOOT_RESETn", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "maxNum": {"type": "integer", "hidden": true, "default": 16, "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_DUT_PMS_PMC_SFR_PSM_CTRL
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_DUT_PMS_PMC_SFR_PSM_CTRL
        }
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRCTRL_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERPMRCTRL_NAME
    }
    

    setIsCLDBoot(isCLDBoot, event=true) {
        if ((isCLDBoot !== null) && (isCLDBoot !== undefined)) {
            if (![Boolean].includes(isCLDBoot.constructor)) {
                throw `isCLDBoot(${isCLDBoot}) must be [Boolean] type`
            }
        }
        const newVal = (isCLDBoot === null || isCLDBoot === undefined) ? undefined : isCLDBoot
        const oldVal = this.properties.isCLDBoot
        this.properties.isCLDBoot = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRCTRL_ISCLDBOOT, newVal, oldVal)
        }
    }
    getIsCLDBoot(pure = false) {
        return this.properties.isCLDBoot
        
    }
    getFidIsCLDBoot() {
        return this.FID_POWERPMRCTRL_ISCLDBOOT
    }
    

    setMaxNum(maxNum, event=true) {
        if ((maxNum !== null) && (maxNum !== undefined)) {
            if (![Number].includes(maxNum.constructor)) {
                throw `maxNum(${maxNum}) must be [Number] type`
            }
        }
        const newVal = (maxNum === null || maxNum === undefined) ? undefined : maxNum
        const oldVal = this.properties.maxNum
        this.properties.maxNum = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRCTRL_MAXNUM, newVal, oldVal)
        }
    }
    getMaxNum(pure = false) {
        return this.properties.maxNum
        
    }
    getFidMaxNum() {
        return this.FID_POWERPMRCTRL_MAXNUM
    }
    

    getFidLast() {
        return this.FID_POWERPMRCTRL_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERPMRCTRL_NAME:
                return "name"

            case this.FID_POWERPMRCTRL_ISCLDBOOT:
                return "isCLDBoot"

            case this.FID_POWERPMRCTRL_MAXNUM:
                return "maxNum"

            default:
                return ""
        }
    }


}