/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import SFRFieldEvent from '@/model/src/events/SFRFieldEvent'
import SFRFieldChecker from '@/model/src/checkers/SFRFieldChecker'
export default class SFRField extends Item {
    FID_SFRFIELD_NAME = 0
    FID_SFRFIELD_INDEX = 1
    FID_SFRFIELD_SIZE = 2
    FID_SFRFIELD_ACCESS = 3
    FID_SFRFIELD_DIRECTWRITE = 4
    FID_SFRFIELD_NAMERESET = 5
    FID_SFRFIELD_INITVALUE = 6
    FID_SFRFIELD_DESCRIPTION = 7
    FID_SFRFIELD_ITEMREF = 8
    FID_SFRFIELD_LAST = 8

    constructor(obj) {
        super(Object.assign(obj,{itemType:"SFRField"}))
        
        this.setEvent(new SFRFieldEvent(this))
        this.setChecker(new SFRFieldChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    if (this.properties.index === undefined) {
        this.properties.index = 0
    }
    if (this.properties.size === undefined) {
        this.properties.size = 1
    }
    if (this.properties.access === undefined) {
        this.properties.access = "RW"
    }
    if (this.properties.directWrite === undefined) {
        this.properties.directWrite = false
    }
    if (this.properties.nameReset === undefined) {
        this.properties.nameReset = ""
    }
    if (this.properties.initValue === undefined) {
        this.properties.initValue = "0x0"
    }
    if (this.properties.description === undefined) {
        this.properties.description = ""
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "SFRField"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "index": {"type": "integer", "default": 0, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "size": {"type": "integer", "default": 1, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "access": {"type": "enum", "targets": ["RO", "RW", "WO"], "multiple": false, "default": "RW", "category": "IP", "hidden": false, "readOnly": false}, "directWrite": {"type": "boolean", "label": "DIRECTWRITE", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "nameReset": {"type": "string", "default": "", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "initValue": {"type": "string", "default": "0x0", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "description": {"type": "textarea", "label": "Description", "readOnly": true, "default": "", "category": "IP", "multiple": false, "hidden": false}, "itemRef": {"type": "item", "label": "item reference", "desc": "item reference", "hidden": true, "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "index": {"type": "integer", "default": 0, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "size": {"type": "integer", "default": 1, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "access": {"type": "enum", "targets": ["RO", "RW", "WO"], "multiple": false, "default": "RW", "category": "IP", "hidden": false, "readOnly": false}, "directWrite": {"type": "boolean", "label": "DIRECTWRITE", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "nameReset": {"type": "string", "default": "", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "initValue": {"type": "string", "default": "0x0", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "description": {"type": "textarea", "label": "Description", "readOnly": true, "default": "", "category": "IP", "multiple": false, "hidden": false}, "itemRef": {"type": "item", "label": "item reference", "desc": "item reference", "hidden": true, "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFRFIELD_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_SFRFIELD_NAME
    }
    

    setIndex(index, event=true) {
        if ((index !== null) && (index !== undefined)) {
            if (![Number].includes(index.constructor)) {
                throw `index(${index}) must be [Number] type`
            }
        }
        const newVal = (index === null || index === undefined) ? undefined : index
        const oldVal = this.properties.index
        this.properties.index = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFRFIELD_INDEX, newVal, oldVal)
        }
    }
    getIndex(pure = false) {
        return this.properties.index
        
    }
    getFidIndex() {
        return this.FID_SFRFIELD_INDEX
    }
    

    setSize(size, event=true) {
        if ((size !== null) && (size !== undefined)) {
            if (![Number].includes(size.constructor)) {
                throw `size(${size}) must be [Number] type`
            }
        }
        const newVal = (size === null || size === undefined) ? undefined : size
        const oldVal = this.properties.size
        this.properties.size = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFRFIELD_SIZE, newVal, oldVal)
        }
    }
    getSize(pure = false) {
        return this.properties.size
        
    }
    getFidSize() {
        return this.FID_SFRFIELD_SIZE
    }
    

    setAccess(access, event=true) {
        if ((access !== null) && (access !== undefined)) {
            if (![String].includes(access.constructor)) {
                throw `access(${access}) must be [String] type`
            }
        }
        const newVal = (access === null || access === undefined) ? undefined : access
        const oldVal = this.properties.access
        this.properties.access = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFRFIELD_ACCESS, newVal, oldVal)
        }
    }
    getAccess(pure = false) {
        return this.properties.access
        
    }
    getFidAccess() {
        return this.FID_SFRFIELD_ACCESS
    }
    

    setDirectWrite(directWrite, event=true) {
        if ((directWrite !== null) && (directWrite !== undefined)) {
            if (![Boolean].includes(directWrite.constructor)) {
                throw `directWrite(${directWrite}) must be [Boolean] type`
            }
        }
        const newVal = (directWrite === null || directWrite === undefined) ? undefined : directWrite
        const oldVal = this.properties.directWrite
        this.properties.directWrite = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFRFIELD_DIRECTWRITE, newVal, oldVal)
        }
    }
    getDirectWrite(pure = false) {
        return this.properties.directWrite
        
    }
    getFidDirectWrite() {
        return this.FID_SFRFIELD_DIRECTWRITE
    }
    

    setNameReset(nameReset, event=true) {
        if ((nameReset !== null) && (nameReset !== undefined)) {
            if (![String].includes(nameReset.constructor)) {
                throw `nameReset(${nameReset}) must be [String] type`
            }
        }
        const newVal = (nameReset === null || nameReset === undefined) ? undefined : nameReset
        const oldVal = this.properties.nameReset
        this.properties.nameReset = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFRFIELD_NAMERESET, newVal, oldVal)
        }
    }
    getNameReset(pure = false) {
        return this.properties.nameReset
        
    }
    getFidNameReset() {
        return this.FID_SFRFIELD_NAMERESET
    }
    

    setInitValue(initValue, event=true) {
        if ((initValue !== null) && (initValue !== undefined)) {
            if (![String].includes(initValue.constructor)) {
                throw `initValue(${initValue}) must be [String] type`
            }
        }
        const newVal = (initValue === null || initValue === undefined) ? undefined : initValue
        const oldVal = this.properties.initValue
        this.properties.initValue = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFRFIELD_INITVALUE, newVal, oldVal)
        }
    }
    getInitValue(pure = false) {
        return this.properties.initValue
        
    }
    getFidInitValue() {
        return this.FID_SFRFIELD_INITVALUE
    }
    

    setDescription(description, event=true) {
        if ((description !== null) && (description !== undefined)) {
            if (![String].includes(description.constructor)) {
                throw `description(${description}) must be [String] type`
            }
        }
        const newVal = (description === null || description === undefined) ? undefined : description
        const oldVal = this.properties.description
        this.properties.description = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFRFIELD_DESCRIPTION, newVal, oldVal)
        }
    }
    getDescription(pure = false) {
        return this.properties.description
        
    }
    getFidDescription() {
        return this.FID_SFRFIELD_DESCRIPTION
    }
    

    setItemRef(itemRef, event=true) {
        if ((itemRef !== null) && (itemRef !== undefined)) {
            if (!(itemRef instanceof Item) && (itemRef.constructor !== String)) {
                throw `itemRef(${itemRef}) must be Item or String type`
            }
        }
        const newVal = (itemRef === null || itemRef === undefined) ? undefined : (itemRef.constructor === String) ? itemRef : itemRef.getId()
        const oldVal = this.properties.itemRef
        this.properties.itemRef = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_SFRFIELD_ITEMREF, this.getRefItem(newVal), this.getRefItem(oldVal))
        }
    }
    getItemRef(pure = false) {
        return pure ? this.properties.itemRef : this.getRefItem(this.properties.itemRef)
        
    }
    getFidItemRef() {
        return this.FID_SFRFIELD_ITEMREF
    }
    

    getFidLast() {
        return this.FID_SFRFIELD_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_SFRFIELD_NAME:
                return "name"

            case this.FID_SFRFIELD_INDEX:
                return "index"

            case this.FID_SFRFIELD_SIZE:
                return "size"

            case this.FID_SFRFIELD_ACCESS:
                return "access"

            case this.FID_SFRFIELD_DIRECTWRITE:
                return "directWrite"

            case this.FID_SFRFIELD_NAMERESET:
                return "nameReset"

            case this.FID_SFRFIELD_INITVALUE:
                return "initValue"

            case this.FID_SFRFIELD_DESCRIPTION:
                return "description"

            case this.FID_SFRFIELD_ITEMREF:
                return "itemRef"

            default:
                return ""
        }
    }


}