/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
//import ErrorType from '@/model/src/ErrorType'
import ItemChecker from '@/model/src/ItemChecker'

export default class ClockSIGNALChecker extends ItemChecker {
    constructor(item) {
        super(item)
        //this.addChecker(item.getFidSignalName(), this.checkSignalName, ErrorType.NAME)
        //this.addChecker(item.getFidSignalReset(), this.checkSignalReset, ErrorType.NAME)
        //this.addChecker(item.getFidType(), this.checkType, ErrorType.NAME)
    }
    checkSignalName() {
        // TBD
    }
    checkSignalReset() {
        // TBD
    }
    checkType() {
        // TBD
    }
}