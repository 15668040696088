/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemType from "../ItemType";
import ItemHandler from "../ItemHandler";
import Repository from "@/model/store";

export default class ClockQChannelManagerEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
    this.addPropertyListener(
      item.getFidPowerdownStep(),
      this.lstn_powerdownStep
    );
    this.addPropertyListener(item.getFidLast() + 1, this.lstn_update_name);
  }

  lstn_name(item, newVal) {
    item.setTitle(newVal);
  }
  lstn_powerdownStep(item, newVal) {
    const ext = item.getClockQChannelManagerEXT();
    ext.setPowerDownMode(newVal ? true : false);
  }

  lstn_update_name(item, newVal) {
    item.setName(`SFRIF_QCH_${newVal}`);
  }

  postProcess(item) {
    super.postProcess(item);
    return this.setupPorts(item);
  }

  setupPorts(item) {
    const repo = Repository.getInstance();
    if (repo.getAcgMode()) {
      return item.getParent().getItemType() === ItemType.ClockCMU
        ? []
        : item.newInput({
            key: `QCH_${ItemType.Input.getKey().toUpperCase()}`,
            socket: "QCH",
            multiple: true,
          }).promise;
    }
  }
}
