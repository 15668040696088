import { NodeType } from "../types";
import ITDANode from "./ITDANode";
import * as ITDANodeRenderer from "./renderers";

export default class ITDASDCCLOCKGROUP extends ITDANode {
  constructor(name = "") {
    super(name, NodeType.ITDASDCCLOCKGROUP);
    this.render = ITDANodeRenderer.ITDASDCCLOCKGROUP.render;
  }
}
