/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import Utils from "./utils";
import ItemEvent from "@/model/src/ItemEvent";

export default class PowerAPMPWRUPEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
  }

  lstn_name(item) {
    Utils.updateAPMChildren(item.getParent(), item);
  }
}
