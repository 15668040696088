import { DataflowEngine } from "rete-engine";
import { ITDACommon, ITDACanvasType } from "./types";
import ITDACanvas from "../ITDACanvas";

export default class ITDADataFlowPlugin extends ITDACanvas {
  constructor(id: string) {
    super(id, ITDACanvasType.DataFlow);
    this.res = new DataflowEngine<ITDACommon.Schemes>();
  }

  getInstance(): DataflowEngine<ITDACommon.Schemes> {
    return this.res as DataflowEngine<ITDACommon.Schemes>;
  }

  async process() {
    this.getInstance().reset();
    const editor = this.repo.getEditor(this.getID());
    const fetchPromises = editor
      .getNodes()
      .map((n) => this.getInstance().fetch(n.id));
    await Promise.all(fetchPromises);
  }
}
