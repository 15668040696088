/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import PowerPMRCoreEvent from '@/model/src/events/PowerPMRCoreEvent'
import PowerPMRCoreChecker from '@/model/src/checkers/PowerPMRCoreChecker'
export default class PowerPMRCore extends Item {
    FID_POWERPMRCORE_NAME = 0
    FID_POWERPMRCORE_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerPMRCore"}))
        
        this.setEvent(new PowerPMRCoreEvent(this))
        this.setChecker(new PowerPMRCoreChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "PowerPMRCore"
        }
    if (this.properties.name === undefined) {
        this.properties.name = ""
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerPMRCore"
    }

    getSchema() {
        return {"name": {"type": "string", "readOnly": true, "default": "", "category": "IP", "multiple": false, "hidden": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "readOnly": true, "default": "", "category": "IP", "multiple": false, "hidden": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "PowerPMRCoreMode", "auto": true, "category": "IP"}, {"name": "PowerPMRCoreNMI", "auto": true, "category": "IP"}, {"name": "PowerPMRCorePCU", "auto": true, "category": "IP"}, {"name": "PowerPMRCoreReset", "auto": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMRCORE_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERPMRCORE_NAME
    }
    

    getFidLast() {
        return this.FID_POWERPMRCORE_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERPMRCORE_NAME:
                return "name"

            default:
                return ""
        }
    }


    newPowerPMRCoreMode(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMRCoreMode", properties, title, domain)
    }
    remPowerPMRCoreMode(obj) {
        return this.removeChild(obj)
    }
    getPowerPMRCoreMode() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "PowerPMRCoreMode")
            return res
        })
    }
    newPowerPMRCoreNMI(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMRCoreNMI", properties, title, domain)
    }
    remPowerPMRCoreNMI(obj) {
        return this.removeChild(obj)
    }
    getPowerPMRCoreNMI() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "PowerPMRCoreNMI")
            return res
        })
    }
    newPowerPMRCorePCU(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMRCorePCU", properties, title, domain)
    }
    remPowerPMRCorePCU(obj) {
        return this.removeChild(obj)
    }
    getPowerPMRCorePCU() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "PowerPMRCorePCU")
            return res
        })
    }
    newPowerPMRCoreReset(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMRCoreReset", properties, title, domain)
    }
    remPowerPMRCoreReset(obj) {
        return this.removeChild(obj)
    }
    getPowerPMRCoreReset() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "PowerPMRCoreReset")
            return res
        })
    }
}