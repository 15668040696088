/**
 *  Generated by Modeler
 *  timestamp: 2023-01-18 10:23:06.348588
 */
//import ErrorType from '@/model/ErrorType'
import ItemChecker from "@/model/src/ItemChecker";

export default class ConnectionChecker extends ItemChecker {
  constructor(item) {
    super(item);
    //this.addChecker(item.getFidSource(), this.checkSource, ErrorType.NAME)
    //this.addChecker(item.getFidSourceNode(), this.checkSourceNode, ErrorType.NAME)
    //this.addChecker(item.getFidTarget(), this.checkTarget, ErrorType.NAME)
    //this.addChecker(item.getFidTargetNode(), this.checkTargetNode, ErrorType.NAME)
  }
  checkSource() {
    // TBD
  }
  checkSourceNode() {
    // TBD
  }
  checkTarget() {
    // TBD
  }
  checkTargetNode() {
    // TBD
  }
}
