/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";
import ItemType from "../ItemType";

export default class PowerSequenceLabelChecker extends ItemChecker {
  constructor(item) {
    super(item);
    this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME);
    //this.addChecker(item.getFidColor(), this.checkColor, ErrorType.NAME)
    //this.addChecker(item.getFidLabelRefs(), this.checkLabelRefs, ErrorType.NAME)
  }
  checkName(item) {
    if (item.getParent().getType() === "RAM") {
      const seqConfiguration = item.getParent(
        ItemType.PowerSequenceConfiguration
      );
      if (seqConfiguration) {
        if (seqConfiguration.getTitle() === "PMR") {
          const labels = [];
          seqConfiguration.getPowerSequenceDiagrams().forEach((diagram) => {
            labels.push(...diagram.getPowerSequenceLabels());
          });
          labels.forEach((label) => {
            if (label !== item && label.getName() === item.getName()) {
              this.newError(
                `Sequence Label Name (${label.getName()}) is duplicated`,
                label
              );
            }
          });
        } else {
          item
            .getParent(ItemType.PowerSequenceDiagram)
            .getPowerSequenceLabels()
            .forEach((label) => {
              if (label !== item && label.getName() === item.getName()) {
                this.newError(
                  `Sequence Label Name (${label.getName()}) is duplicated`,
                  label
                );
              }
            });
        }
      }
    } else {
      item
        .getParent(ItemType.PowerSequenceDiagram)
        .getPowerSequenceLabels()
        .filter(
          (seqLabel) =>
            seqLabel !== item && seqLabel.getName() === item.getName()
        )
        .forEach((seqLabel) =>
          this.newError(
            `Sequence Label Name (${seqLabel.getName()}) is duplicated`,
            seqLabel
          )
        );
    }
  }
  checkColor() {
    // TBD
  }
  checkLabelRefs() {
    // TBD
  }
}
