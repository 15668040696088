/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import ItemHandler from "../ItemHandler";

export default class PowerPMRCTRLEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), () => {});
    this.addPropertyListener(item.getFidIsCLDBoot(), this.lstn_is_cldboot);
  }

  lstn_is_cldboot(item) {
    ItemHandler.refreshSFRInfo(item);
  }
}
