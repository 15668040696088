import ItemType from "./ItemType";
import ItemHandler from "./ItemHandler";
import Repository from "../store";

export default class SFRHandler {
  constructor(meta) {
    this.meta = meta;
    this.initialize();
  }

  initialize() {
    this.startOffset = this.meta.startOffset;
    this.alignSize = this.meta.alignSize;
    this.size = this.meta.size;
    this.offsetToItemID = {};
  }

  setOffsetMap(offset, id) {
    this.offsetToItemID[offset] = id;
  }

  getOffsetById(id) {
    for (let [key, value] of Object.entries(this.offsetToItemID)) {
      if (id === value) {
        return key;
      }
    }
  }

  deleteOffsetMap(offset) {
    offset = parseInt(offset);
    var align = parseInt(this.alignSize);
    delete this.offsetToItemID[Math.floor(offset / align) * align];
  }

  getNextOffset() {
    var startOffset = parseInt(this.startOffset);
    var align = parseInt(this.alignSize);
    var size = parseInt(this.size);
    for (var i = startOffset; i < startOffset + size; i += align) {
      if (!this.offsetToItemID[i]) {
        return i;
      }
    }
  }

  static getSfrHandlerMap(sfrBlock) {
    return Repository.getInstance().getSFR().getSFRHandlerMap(sfrBlock);
  }

  static createSFR(sfrBlock, nodeItem, specType, sfrSpec = null) {
    const sfrHandler = this._getSFRHandler(sfrBlock, nodeItem);
    const res = [];
    if (sfrSpec === null) {
      sfrSpec = nodeItem.getSFRSpec(specType);
    }
    var baseOffset = sfrHandler.getNextOffset();
    var offset = baseOffset;
    for (const spec of sfrSpec.sfrs) {
      const sfr = ItemHandler.createSyncItem(
        sfrBlock,
        ItemType.SFR,
        {
          type: spec.name,
          name: `${spec.name}__${nodeItem.getName()}`,
          itemRef: nodeItem.getId(),
          description: spec.description,
        },
        `${spec.name}__${nodeItem.getName()}`,
        nodeItem.domain
      );

      if (spec.alignOffset) {
        sfr.setOffset(
          `0x${(offset + parseInt(spec.alignOffset)).toString(16)}`,
          false
        );
      } else {
        sfr.setOffset(`0x${offset.toString(16)}`, false);
        // offset += context.getters.GET_OFFSET_ALIGN_SIZE
        offset += 4;
      }

      for (const fieldSpec of spec.fields) {
        if (fieldSpec.index < 0) {
          break;
        }
        this.createSFRField(sfr, fieldSpec);
      }
      sfr.getEvent().sortSFRFields(sfr);
      res.push(sfr);
    }
    sfrHandler.setOffsetMap(baseOffset, nodeItem.getId());
    sfrBlock.getEvent().lstn_sorted(sfrBlock, true);
    return res;
  }

  static _getSFRHandler(sfrBlock, nodeItem) {
    const sfrHandlerMap = Repository.getInstance()
      .getSFR()
      .getSFRHandlerMap(sfrBlock);
    var parent = null;
    var res = null;
    switch (nodeItem.getItemType()) {
      case ItemType.ClockFreqmonEXT:
      case ItemType.ClockPLL:
      case ItemType.ClockPLLCtrlEXT:
      case ItemType.ClockRefCLKMultiplexerEXT:
      case ItemType.ClockMultiplexerEXT:
      case ItemType.ClockDividerEXT:
      case ItemType.ClockNMDividerEXT:
      case ItemType.ClockGateEXT:
      case ItemType.ClockQChannelManagerEXT:
        parent = nodeItem.getParent();
        res = sfrHandlerMap.get(
          `${parent.getId()}/${nodeItem.getItemType().getKey()}`
        );
        res.startOffset = sfrHandlerMap
          .get(parent.constructor.getClassName())
          .getOffsetById(parent.getId());
        return res;

      case ItemType.PowerPMRINTRGenBit:
      case ItemType.PowerPMRINTREvent:
        parent = nodeItem.getParent().getParent();
        res = sfrHandlerMap.get(
          `${parent.getId()}/${nodeItem.getItemType().getKey()}`
        );
        res.startOffset = sfrHandlerMap
          .get(parent.constructor.getClassName())
          .getOffsetById(parent.getId());
        return res;
      case ItemType.PowerPMRINTRSFRATOM:
      case ItemType.PowerPMRINTREXTATOM:
      case ItemType.PowerPMRTIMER:
      case ItemType.PowerPMRPMDCTRL:
        parent = nodeItem.getParent();
        res = sfrHandlerMap.get(
          `${parent.getId()}/${nodeItem.getItemType().getKey()}`
        );
        res.startOffset = sfrHandlerMap
          .get(parent.constructor.getClassName())
          .getOffsetById(parent.getId());
        return res;
      default:
        return sfrHandlerMap.get(nodeItem.constructor.getClassName());
    }
  }

  static _getOffset(sfrBlock, nodeItem) {
    // const sfrHandlerMap = store.getters['sfr/GET_SFR_HANDLER_MAP'].get(sfrBlock)
    const sfrHandlerMap = Repository.getInstance()
      .getSFR()
      .getSFRHandlerMap(sfrBlock);
    var sfrHandler = null;
    switch (nodeItem.getItemType()) {
      case ItemType.ClockFreqmon:
      case ItemType.ClockPLLCtrl:
      case ItemType.ClockRefCLKMultiplexer:
      case ItemType.ClockMultiplexer:
      case ItemType.ClockDivider:
      case ItemType.ClockNMDivider:
      case ItemType.ClockGate:
      case ItemType.ClockQChannelManager:
      case ItemType.PowerPMRINTRSFR:
      case ItemType.PowerPMRINTREXT:
      case ItemType.PowerPMRTIMERGRP:
      case ItemType.PowerPMRPMDGRP:
        sfrHandler = sfrHandlerMap.get(nodeItem.constructor.getClassName());
        return sfrHandler.getOffsetById(nodeItem.getId());
      case ItemType.ClockFreqmonEXT:
      case ItemType.ClockPLL:
      case ItemType.ClockPLLCtrlEXT:
      case ItemType.ClockRefCLKMultiplexerEXT:
      case ItemType.ClockMultiplexerEXT:
      case ItemType.ClockDividerEXT:
      case ItemType.ClockGateEXT:
      case ItemType.ClockQChannelManagerEXT:
      case ItemType.PowerPMRINTRSFRATOM:
      case ItemType.PowerPMRINTREXTATOM:
      case ItemType.PowerPMRTIMER:
      case ItemType.PowerPMRPMDCTRL:
        var parent = nodeItem.getParent();
        sfrHandler = sfrHandlerMap.get(
          `${parent.getId()}/${nodeItem.getItemType().getKey()}`
        );
        return sfrHandler.getOffsetById(nodeItem.getId());
      default:
        return;
    }
  }

  static createSFRField(sfr, fieldSpec) {
    const sfrField = ItemHandler.createSyncItem(
      sfr,
      ItemType.SFRField,
      {
        name: fieldSpec.name ? fieldSpec.name : "",
        index: fieldSpec.index ? fieldSpec.index : 0,
        size: fieldSpec.size ? fieldSpec.size : 1,
        access: fieldSpec.access ? fieldSpec.access : "RW",
        directWrite: fieldSpec.directWrite ? fieldSpec.directWrite : false,
        nameReset: fieldSpec.nameReset ? fieldSpec.nameReset : "",
        initValue: fieldSpec.initValue ? fieldSpec.initValue : "0x0",
        description: fieldSpec.description ? fieldSpec.description : "",
      },
      "",
      sfr.domain
    );
    sfrField.getEvent().updateFieldLabel(sfrField);
    return sfrField;
  }

  static removeSFR(sfrBlock, nodeItem) {
    if (sfrBlock) {
      const sfrHandler = this._getSFRHandler(sfrBlock, nodeItem);
      const offset = this._getOffset(sfrBlock, nodeItem);
      const mappers = nodeItem.getMappers();
      for (const mapper of mappers) {
        const source = mapper.getSource();
        // MapperFactory.removeMapper(mapper)
        if (source && source.getItemType() === ItemType.SFR) {
          sfrHandler.deleteOffsetMap(offset ? offset : source.getOffset());
          for (const sfrField of source.getSFRFields()) {
            ItemHandler.removeSyncItem(sfrField);
          }
          ItemHandler.removeSyncItem(source);
        }
      }
      if (offset) {
        sfrHandler.deleteOffsetMap(offset);
      }
      sfrBlock.getEvent().lstn_sorted(sfrBlock, true);
    }
  }
}
