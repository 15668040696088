<template>
  <div class="canvas-header-container">
    <div v-if="showHeader" class="canvas-header">
      <div class="canvas-header-right">
        <label class="custom-checkbox">
          <input type="checkbox" :checked="showGrid" @change="toggleShowGrid" />
          <span class="checkbox-box"></span>
          Show Grid
        </label>
        <div class="custom-line">
          <label class="line-label">
            <input
              type="radio"
              value="line"
              :checked="selectedPathStyle === 'line'"
              @change="updatePathStyle('line')" />
            <span></span>
            Straight
          </label>
          <label class="curve-label">
            <input
              type="radio"
              value="curve"
              :checked="selectedPathStyle === 'curve'"
              @change="updatePathStyle('curve')" />
            <span></span>
            Curve
          </label>
        </div>
        <div class="split-line"></div>
        <!-- <v-autocomplete
          v-model="socketState.selectedTypes"
          :items="socketState.socketTypes"
          label="Socket Types"
          multiple
          chips
          persistent-hint
          class="custom-select">
        </v-autocomplete> -->
      </div>
    </div>
    <div v-else :style="closedHeaderStyle()"></div>
    <button class="toggle-button" @click="showHeader = !showHeader">
      <svg width="30" height="30" viewBox="0 0 30 30">
        <circle
          cx="15"
          cy="15"
          r="14"
          fill="white"
          stroke="gray"
          stroke-width="1.5" />
        <path
          v-if="showHeader"
          d="M9 6L15 12L9 18"
          stroke="gray"
          stroke-width="3"
          fill="none"
          transform="translate(4, 3)" />
        <path
          v-else
          d="M15 6L9 12L15 18"
          stroke="gray"
          stroke-width="3"
          fill="none"
          transform="translate(4, 3)" />
      </svg>
    </button>
  </div>
</template>

<script setup lang="ts">
import { defineProps, ref, reactive, watch, computed } from "vue";
import { useStore } from "vuex";
import Canvas from "@/canvas";
import API from "@/api";

const item = API.getCurrentItem();
const store = useStore();
const props = defineProps<{ itemId: string }>();

const socketTypesMap: { [key: string]: string[] } = {
  ClockDiagram: ["CLK"],
  ClockIPDiagram: ["CLK", "QCH"],
  PowerDiagram: ["PWRCTRL"],
  PowerSequenceDiagram: ["SEQREF", "SEQ"],
  DUTModuleDiagram: ["PLINK", "CLINK", "CLK", "SFRIF"],
  SDCDiagram: ["CLK"],
  UPFDiagram: ["CLK"],
};

const socketState = reactive({
  selectedTypes: socketTypesMap[item?.itemType] || [],
  socketTypes: socketTypesMap[item?.itemType] || [],
});

const showGrid = ref<boolean>(true);
const selectedPathStyle = computed(() =>
  store.getters["canvas/GET_PATH_STYLE"](props.itemId)
);
const showHeader = ref<boolean>(true);

const eventGrid = (value: boolean) => {
  const area = Canvas.getAreaPlugin();
  area.updateGrid(value);
};

const toggleShowGrid = () => {
  showGrid.value = !showGrid.value;
  eventGrid(showGrid.value);
};

const updatePathStyle = (newStyle: "line" | "curve") => {
  if (selectedPathStyle.value !== newStyle) {
    store.commit("canvas/SET_PATH_STYLE", {
      canvasId: props.itemId,
      style: newStyle,
    });
    const reroutePlugin = Canvas.getConnectionReRoutePlugin();
    reroutePlugin.updateConnectionPathStyle(newStyle);
  }
};

const closedHeaderStyle = () => ({
  position: "relative",
  display: "block",
  width: "50px",
  height: "50px",
  border: "1.5px solid #CCCCCC",
  borderRadius: "5px",
  background: "white",
  backgroundColor: "white",
  boxSizing: "border-box",
  filter: "drop-shadow(-1px 6px 3px rgba(0, 0, 0, 0.15))",
});

watch(
  () => socketState.selectedTypes,
  (newSelectedTypes) => {
    Canvas.getEditor().filterConnections(newSelectedTypes);
  }
);
</script>

<style lang="sass" scoped>
// $primary-color: #1e41cd
$primary-color: #1e41cd

.canvas-header-container
  position: absolute
  // min-width: 30% !important
  display: inline-flex
  justify-content: flex-end
  align-items: center
  pointer-events: auto
  // width: inherit
  user-select: none
  z-index: 1
  height: 50px
  //opacity: 0.9
  top: 10px
  right: 10px

.canvas-header
  position: relative
  display: flex
  height: 50px
  justify-content: flex-end
  border: 1.5px solid #CCCCCC
  border-radius: 5px
  padding: 1rem 1rem 1rem 0.5rem
  background-color: white
  box-sizing: border-box
  filter: drop-shadow(-1px 6px 3px rgba(0, 0, 0, 0.15))

.split-line
  height: 50px
  width: 1px
  background-color: #cccccc
  margin: 0 0 0 0.15rem

.toggle-button
  background: transparent
  border: none
  margin: 0.5rem 0.6rem 0 0
  transition: background-color 0.3s, color 0.3s
  &:hover
    color: $primary-color !important
  position: absolute

.canvas-header-right
  display: flex
  justify-content: center
  align-items: center
  padding: 0rem 2rem 0 0rem
  font-size: 16px
  gap: 0.5rem

.custom-checkbox
  display: inline-flex
  align-items: center
  cursor: pointer
  color: black !important
  border: 1.5px solid lightgray
  border-radius: 5px
  padding: 0.2rem 0.7rem 0.2rem 0.7rem
  width: inherit
  input[type="checkbox"],
  input[type="radio"]
    display: none

  .checkbox-box
    width: 20px
    height: 20px
    margin-right: 8px
    border: 1.5px solid gray
    border-radius: 3px
    background-color: #fff
    display: inline-block
    box-sizing: border-box

  input[type="checkbox"]:checked + .checkbox-box,
  input[type="radio"]:checked + .checkbox-box
    background-color: $primary-color
    display: flex
    justify-content: center
    align-items: center
    color: #fff
    content: "✓"

  input[type="checkbox"]:checked + .checkbox-box::after,
  input[type="radio"]:checked + .checkbox-box::after
    content: "✓"
    font-size: 16px
    color: #fff

.custom-line
  border: 1.5px solid lightgray
  border-radius: 5px !important
  padding: 0.2rem 0.7rem 0.2rem 0.7rem
  .line-label
    margin-right: 0.8rem

  input[type="radio"]
    display: none

  span
    display: inline-block
    width: 20px
    height: 20px
    margin-right: 0.2rem
    top: 5px
    border:1.52px solid gray
    border-radius: 50%
    box-sizing: border-box
    position: relative
    transition: background-color 0.3s, border-color 0.3s

  input[type="radio"]:checked + span
    background-color: $primary-color
    border-color: $primary-color

    &::after
      content: ""
      position: absolute
      top: 52%
      left: 50%
      width: 11px
      height: 11px
      background-color: #fff
      border-radius: 50%
      transform: translate(-50%, -50%)
</style>
