/**
 *  Generated by Modeler
 *  timestamp: 2024-06-13 10:49:37.617464
 */
import ItemEvent from '@/model/src/ItemEvent'

export default class WrapperDFTFolderEvent extends ItemEvent {
    constructor(item) {
        super(item)
    }
}