/**
 *  Generated by Modeler
 *  timestamp: 2022-12-22 10:29:56.252901
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class ConfigurationElementEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidValue(), this.lstn_config_value);
  }
  lstn_config_value(item, newVal, oldVal) {
    console.debug(item, newVal, oldVal);
  }
}
