<template>
  <div
    class="title-desc"
    :style="style"
    @pointerdown.stop=""
    v-html="getNodeTitle()"></div>
</template>

<script setup lang="ts">
import { computed, defineProps } from "vue";
import Utils from "@/utils";
import { ITDANodeSequence, ITDASDCCLOCKGROUP } from "@/canvas/nodes";
import Canvas from "@/canvas";

const props = defineProps<{
  data: any;
}>();

const getNodeTitle = () => {
  if (props.data.node instanceof ITDANodeSequence) {
    return "";
  } else if (props.data.node instanceof ITDASDCCLOCKGROUP) {
    return "";
  } else {
    return Utils.getNodeTitle(
      props.data.repo.getCurrentID(),
      props.data.node.id
    );
  }
};

const gridSpace = Canvas.getEditor(Canvas.getCurrentID()).getGridSpace();

const style = computed(() => {
  const baseGridSpace = 10;
  const baseFontSize = 0.7815;

  const computedFontSize = (gridSpace / baseGridSpace) * baseFontSize;

  return {
    width: `${props.data.node.width}px`,
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    fontSize: `${computedFontSize}rem`,
  };
});
</script>

<style lang="sass" scoped>
.title-desc
  width: fit-content
</style>
