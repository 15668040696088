<template>
  <v-chip-group class="flex" model-value>
    <v-chip
      v-for="(target, index) in props?.itemRef?.targets"
      :key="index"
      :closable="target.closable"
      :disabled="target.disabled"
      :variant="
        props.itemRef
          ? props.itemRef.variant
            ? Utils.getVariant(
                RefItemElementType.CHIP,
                VariantLevel[props.itemRef.variant]
              )
            : variantDefault
          : variantDefault
      "
      label
      class="bg-white v-chip-border"
      @click="
        !target.disabled
          ? props.itemRef
            ? props.itemRef.event(props.item, props.itemRef, target.key)
            : () => {
                return;
              }
          : () => {
              return;
            }
      ">
      <v-icon
        v-if="props.itemRef?.icon"
        style="width: 32px; height: 32px"
        size="x-large"
        >{{ props.itemRef?.icon }}</v-icon
      >
      {{ target.key }}</v-chip
    ></v-chip-group
  >
</template>
<script setup lang="ts">
import { defineProps } from "vue";
import {
  RefItemElementType,
  VariantLevel,
  ItemPropsType,
} from "ITDAModelTypes";
import Utils from "@/components/item/utils";

const props = defineProps<ItemPropsType>();
const variantDefault = "outlined";
</script>
<style lang="sass" scoped>
.v-chip.v-chip--label
  width: 100% !important
</style>
