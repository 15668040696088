import ItemHandler from "./ItemHandler";
import ItemType from "./ItemType";

export default class SequenceHandler {
  static async createSequenceLabel(item) {
    const position = {
      x: item.getNodeMetaView().getX(),
      y: item.getNodeMetaView().getY(),
    };
    const { item: label, promise } = ItemHandler.createItem(
      item.getParent(),
      ItemType.PowerSequenceLabel
    );
    await promise;

    await ItemHandler.createItem(item.getParent(), ItemType.Connection, {
      sourceOutput: label.getOutputs()[0].getId(),
      targetInput: item
        .getInputs()
        .filter((input) => input.getPosition() === "LEFT")[0]
        .getId(),
    }).promise;
  }

  static removeSequenceLabel(item) {
    const label = item
      .getInputs()
      .filter((input) => input.getPosition() === "LEFT")[0]
      .getConnections()[0]
      .getSourceOutput()
      .getParent();
    ItemHandler.removeItem(label);
  }

  static async createSequenceBack(item) {
    const position = {
      x: item.getNodeMetaView().getX(),
      y: item.getNodeMetaView().getY(),
    };
    const { item: back, promise: backPromise } = ItemHandler.createItem(
      item.getParent(),
      ItemType.PowerSequenceBack
    );
    await backPromise;

    const outputKey = `SEQ_${ItemType.Output.getKey().toUpperCase()}`;
    const inputKey = `SEQ_${ItemType.Input.getKey().toUpperCase()}`;
    const { item: conn, promise: connPromise } = await ItemHandler.createItem(
      item.getParent(),
      ItemType.Connection,
      {
        sourceOutput: item
          .getOutputs()
          .filter((output) => output.getKey() === outputKey)[0]
          .getId(),
        targetInput: back
          .getInputs()
          .filter((input) => input.getKey() === inputKey)[0]
          .getId(),
      }
    );
    await connPromise;
  }

  static removeSequenceBack(item) {
    const outputKey = `SEQ_${ItemType.Output.getKey().toUpperCase()}`;
    item.getOutputs().forEach((output) => {
      if (output.getKey() === outputKey) {
        output.getConnections().filter((conn) => {
          if (
            conn.getTargetInput().getParent().getItemType() ===
            ItemType.PowerSequenceBack
          ) {
            ItemHandler.removeItem(conn.getTargetInput().getParent());
          }
        });
      }
    });
  }

  static async createSequenceLabelRef(item) {
    const output = item
      .getOutputs()
      .find((output) => output.getSocket() === "SEQREF");
    if (output && output.getConnections().length) {
      return;
    }
    const { item: labelRef, promise: labelRefPromise } =
      await ItemHandler.createItem(
        item.getParent(),
        ItemType.PowerSequenceLabelRef
      );
    await labelRefPromise;

    const position = {
      x: item.getNodeMetaView().getX(),
      y: item.getNodeMetaView().getY(),
    };

    const inputKey = `SEQREF_${ItemType.Input.getKey().toUpperCase()}`;
    const { item: connection, promise: connPromise } =
      await ItemHandler.createItem(item.getParent(), ItemType.Connection, {
        sourceOutput: item.getOutputs()[0].getId(),
        targetInput: labelRef
          .getInputs()
          .filter((input) => input.getKey() === inputKey)[0]
          .getId(),
      });
    await connPromise;
  }
}
