import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodePower from "./ITDANodePower";

export default class ITDAPCH extends ITDANodePower {
  constructor(name = "") {
    super(name, NodeType.ITDAPCH);
    this.render = ITDANodeRenderer.ITDAPCH.render;
  }

  build() {
    const res = super.build();

    const ctrl = this.getCtrlSignal();
    ctrl.newSignal("PREQ", "out");
    ctrl.newSignal("PSTATE", "out");
    ctrl.newSignal("PACCEPT", "in");
    ctrl.newSignal("PDENY", "in");
    ctrl.newSignal("PACTIVE", "in");

    return res;
  }
}
