<template>
  <ItemPropertyLabel :props="props" :isTitle="false" />
  <v-text-field
    :id="props.propKey"
    ref="input"
    v-model="model"
    color="1e41cd"
    variant="outlined"
    :disabled="Utils.isDisabledTextField(props.item, props.propKey, props.prop)"
    :rules="combinedRules"
    :error-messages="props.errorMessages"
    :hide-details="!inputError && !checkError"
    autocomplete="nope"
    @change="
      inputError
        ? undefined
        : props.itemRef
        ? props.itemRef.event(props.item, props.itemRef, $event.target.value)
        : Utils.change(props.item, props.propKey, String, $event)
    " />
</template>

<script setup lang="ts">
import API from "@/api/internal";
import { computed, defineProps, nextTick, onUpdated, ref, watch } from "vue";
import Utils from "@/components/item/utils";
import { ItemPropsType } from "ITDAModelTypes";
import ItemPropertyLabel from "./ItemPropertyLabel.vue";

const props = defineProps<ItemPropsType>();
const value = props.itemRef
  ? props.itemRef.model
  : Utils.getValue(props.item, props.propKey);
const model = ref(value);
const input = ref<any>(null);
const inputError = ref(false);
const checkError = ref(false);

const rules = API.getConfigurationRules(props.item, props.propKey);
const itemRules = API.getItemRules(props.item, props.propKey);

const combinedRules = computed(() => {
  return [...rules, ...itemRules];
});
watch(model, async () => {
  await nextTick();
  const err = await input.value.validate();
  inputError.value = err.length > 0;
});

watch(
  () => props.item,
  (newItem) => {
    model.value = Utils.getValue(newItem, props.propKey);
  }
);

onUpdated(() => {
  checkError.value = !!props.errorMessages;
});
</script>

<style lang="sass">
.v-text-field
  padding-top: 0

.v-text-field *
  --v-input-control-height: 38px
  --v-field-input-padding-top: 7px
  --v-field-input-padding-bottom: 7px
  --v-field-padding-start: 10px
  --v-field-padding-end: 10px
  font-size: 0.9rem
</style>
