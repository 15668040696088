import {
  ConnectionPlugin,
  getSourceTarget,
  SocketData,
  Context,
} from "rete-connection-plugin";
import { ITDACommon, AreaExtra, ITDACanvasType } from "./types";
import ITDACanvas from "../ITDACanvas";
import { ITDAConnection, ITDAConnectionFlow } from "../common";
import { ITDAInput, ITDAOutput } from "../controls/ITDAControlPort";

export default class ITDAConnectionPlugin extends ITDACanvas {
  constructor(id: string) {
    super(id, ITDACanvasType.Connection);
    this.res = new ConnectionPlugin<ITDACommon.Schemes, AreaExtra>();

    this.res.addPreset(
      (data: SocketData) =>
        new ITDAConnectionFlow<ITDACommon.Schemes, []>(data, {
          canMakeConnection: (from: SocketData, to: SocketData): boolean => {
            const [source, target] = getSourceTarget(from, to) || [null, null];
            if (source && target) {
              const editor = this.repo.getEditor(this.id);
              const sourceNode = editor.getNode(source.nodeId);
              const targetNode = editor.getNode(target.nodeId);
              const output = sourceNode.outputs[source.key] as ITDAOutput;
              const input = targetNode.inputs[target.key] as ITDAInput;
              if (output && input) {
                const driver = output.socket as ITDACommon.ITDASocket;
                const driven = input.socket as ITDACommon.ITDASocket;
                if (output.getMaxNum()) {
                  const maxNum = output.getMaxNum() as number;
                  const curNum = editor
                    .getConnections()
                    .filter(
                      (conn: any) => conn.sourceOutput === source.key
                    ).length;
                  if (curNum >= maxNum) {
                    return false;
                  }
                }
                return driver.isCompatibleWith(driven);
              }
            }
            return false;
          },
          makeConnection: <K extends any[]>(
            from: SocketData,
            to: SocketData,
            context: Context<ITDACommon.Schemes, K>
          ) => {
            const [source, target] = getSourceTarget(from, to) || [null, null];
            if (source && target) {
              context.editor.addConnection(
                new ITDAConnection(
                  context.editor.getNode(source.nodeId),
                  source.key,
                  context.editor.getNode(target.nodeId),
                  target.key
                )
              );
              return true;
            }
          },
        })
    );
  }

  event() {
    this.getInstance().addPipe(async (context) => {
      const editor = this.repo
        .getEditor(this.repo.getCurrentID())
        .getInstance();

      if (context.type === "connectionpick") {
        const res = await this.emit({
          type: context.type,
          options: {
            node: editor.getNode(context.data.socket.nodeId),
            socket: context.data.socket,
          },
        });
        return res ? context : undefined;
      }

      return context;
    });
  }

  getInstance(): ConnectionPlugin<ITDACommon.Schemes, AreaExtra> {
    return this.res as ConnectionPlugin<ITDACommon.Schemes, AreaExtra>;
  }
}
