import { Item, ItemType } from "../types";
import Repository from "../store";
import { GarbageLevel, GarbageType } from "../store/types";
import LabelType from "../src/LabelType";

export const checkGarbageParent = (item: Item): GarbageLevel | undefined => {
  const parent = Repository.getInstance().getItemByID(item.pid);
  if (!parent) {
    return Repository.getInstance().addGarbage(item, GarbageType.PARENT);
  }
};

export const checkGarbageNode = (item: Item): GarbageLevel | undefined => {
  if (item.isNodeType()) {
    if (!item.getNodeID()) {
      return Repository.getInstance().addGarbage(item, GarbageType.NODE);
    }
  }
};

export const checkGarbageItemRef = (item: Item): GarbageLevel | undefined => {
  let res;
  switch (item.getItemType()) {
    case ItemType.ConfigurationElement:
      if (!item.getItemRef()) {
        res = Repository.getInstance().addGarbage(item, GarbageType.CONFIG);
      }
      break;
    case ItemType.Label:
    case ItemType.PowerSequenceLabel: {
      const labelRefs: Item[] = [];
      item.getLabelRefs().forEach((labelRef: Item) => {
        if (labelRef) {
          labelRefs.push(labelRef);
        } else {
          res = Repository.getInstance().addGarbage(item, GarbageType.LABEL);
        }
      });
      item.setLabelRefs(labelRefs, false);
      break;
    }
    case ItemType.LabelRef:
      if (
        !item.getItemLabel() &&
        item.getType() === LabelType.INTERNAL.getKey()
      ) {
        res = Repository.getInstance().addGarbage(item, GarbageType.LABEL);
        item.setItemLabel(undefined, false);
      }
      break;
    case ItemType.PowerSequenceLabelRef:
      if (!item.getItemLabel()) {
        res = Repository.getInstance().addGarbage(item, GarbageType.LABEL);
        item.setItemLabel(undefined, false);
      }
      break;
    case ItemType.SFR:
      if (!item.getItemRef()) {
        res = Repository.getInstance().addGarbage(item, GarbageType.SFR);
      }
      break;
    case ItemType.SFRField: {
      const sfr = item.getParent();
      const refItem = sfr?.getItemRef();
      if (refItem && refItem.getItemType() === ItemType.PowerPMRINTREvent) {
        if (!item.getItemRef()) {
          res = Repository.getInstance().addGarbage(item, GarbageType.SFR);
        }
      }
      break;
    }
    case ItemType.SDC:
      if (!item.getItemRef()) {
        res = Repository.getInstance().addGarbage(item, GarbageType.SDC);
      }
      break;
    default:
      break;
  }
  return res;
};

export const checkGarbageConnection = (
  item: Item
): GarbageLevel | undefined => {
  let res;
  switch (item.getItemType()) {
    case ItemType.Connection:
      if (!item.getSourceOutput() || !item.getTargetInput()) {
        res = Repository.getInstance().addGarbage(item, GarbageType.CONN);
      }
      break;
    case ItemType.Input:
    case ItemType.Output: {
      const conns: Item[] = [];
      item.getConnections().forEach((conn: Item) => {
        if (conn) {
          conns.push(conn);
        } else {
          res = Repository.getInstance().addGarbage(item, GarbageType.PORT);
        }
      });
      item.setConnections(conns, false);
      break;
    }
    default:
      break;
  }
  return res;
};
