import {
  ConfigurationMapType,
  ConfigurationBase,
  ConfigurationFreqeuncy,
} from "@/model/types";

export default class ConfigurationRepository {
  private static instance: ConfigurationRepository;

  private configurationMap: ConfigurationMapType;

  private constructor() {
    this.configurationMap = {};
  }

  public static getInstance(): ConfigurationRepository {
    if (!ConfigurationRepository.instance) {
      ConfigurationRepository.instance = new ConfigurationRepository();
    }
    return ConfigurationRepository.instance;
  }

  public clear(): void {
    this.configurationMap = {};
  }

  public setConfigurationBase(baseObj: ConfigurationBase): void {
    this.configurationMap[baseObj.itemID] = {
      itemID: baseObj.itemID,
      nodeType: baseObj.nodeType,
      sdcID: baseObj.sdcID,
    };
  }

  public setConfigurationFrequency(
    itemID: string,
    freqObj: ConfigurationFreqeuncy
  ) {
    if (!this.configurationMap[itemID].config) {
      this.configurationMap[itemID].config = [];
    }
    this.configurationMap[itemID].config?.push(freqObj);
  }

  public getConfigurationMap(): ConfigurationMapType {
    return this.configurationMap;
  }
}
