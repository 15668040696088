/**
 *  Generated by Modeler
 *  timestamp: 2023-03-19 12:05:43.425172
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class UPFMapperPowerSourceEvent extends ItemEvent {
  constructor(item) {
    super(item);
  }
}
