/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import PowerSequenceConfigurationEvent from '@/model/src/events/PowerSequenceConfigurationEvent'
import PowerSequenceConfigurationChecker from '@/model/src/checkers/PowerSequenceConfigurationChecker'
export default class PowerSequenceConfiguration extends Item {
    FID_POWERSEQUENCECONFIGURATION_TYPE = 0
    FID_POWERSEQUENCECONFIGURATION_LAST = 0

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerSequenceConfiguration"}))
        
        this.setEvent(new PowerSequenceConfigurationEvent(this))
        this.setChecker(new PowerSequenceConfigurationChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerSequenceConfiguration"
    }

    getSchema() {
        return {"type": {"type": "enum", "targets": ["PMR", "PMD"], "readOnly": true, "multiple": false, "category": "IP", "hidden": false}}
    }

    static getSchema() {
        return {"type": {"type": "enum", "targets": ["PMR", "PMD"], "readOnly": true, "multiple": false, "category": "IP", "hidden": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "PowerSequenceDiagram", "auto": false, "searchable": false, "title": "Sequence Diagram List", "headers": [{"title": "Sequence Diagram Name", "align": "start", "filterable": true, "width": "50%", "minWidth": "300px", "value": "name", "sortable": true}, {"title": "copy", "align": "start", "width": "50%", "minWidth": "300px", "filterable": true, "value": "copied", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setType(type, event=true) {
        if ((type !== null) && (type !== undefined)) {
            if (![String].includes(type.constructor)) {
                throw `type(${type}) must be [String] type`
            }
        }
        const newVal = (type === null || type === undefined) ? undefined : type
        const oldVal = this.properties.type
        this.properties.type = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERSEQUENCECONFIGURATION_TYPE, newVal, oldVal)
        }
    }
    getType(pure = false) {
        return this.properties.type
        
    }
    getFidType() {
        return this.FID_POWERSEQUENCECONFIGURATION_TYPE
    }
    

    getFidLast() {
        return this.FID_POWERSEQUENCECONFIGURATION_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERSEQUENCECONFIGURATION_TYPE:
                return "type"

            default:
                return ""
        }
    }


    newPowerSequenceDiagram(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerSequenceDiagram", properties, title, domain)
    }
    remPowerSequenceDiagram(obj) {
        return this.removeChild(obj)
    }
    getPowerSequenceDiagrams() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "PowerSequenceDiagram")
            return res
        })
    }
}