/**
 *  Generated by Modeler
 *  timestamp: 2023-11-13 15:18:10.045078
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class UPFMapperGroundEvent extends ItemEvent {
  constructor(item) {
    super(item);
    // this.addPropertyListener(item.getFidGround(), this.lstn_ground)
    // this.addPropertyListener(item.getFidMappedModuleGround(), this.lstn_mappedModuleGround)
    // this.addPropertyListener(item.getFidNodeId(), this.lstn_nodeId)
  }
  // lstn_ground(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
  // lstn_mappedModuleGround(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
  // lstn_nodeId(item, newVal, oldVal) {
  //     console.debug(item, newVal, oldVal)
  // }
}
