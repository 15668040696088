/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x0000",
    "size": "0x800",
    "alignSize": "0x20",
    "sfrs": [
        {
            "name": "CTRL_PLL_LOCKTIME",
            "alignOffset": "0x8",
            "description": "",
            "fields": [
                {
                    "name": "SFR_LOCKTIME",
                    "index": 0,
                    "size": 20,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0xFFF",
                    "nc": "SFR_LOCKTIME"
                },
                {
                    "name": "SFR_LOCKCNT_ENABLE",
                    "index": 30,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_LOCKCNT_ENABLE"
                },
                {
                    "name": "SFR_LOCKED",
                    "index": 31,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "",
                    "initValue": "0x0",
                    "nc": "SFR_LOCKED"
                }
            ]
        }
    ]
}