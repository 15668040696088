import * as ITDAControl from "../controls";
import * as ITDANodeRenderer from "./renderers";
import { NodeType } from "../types";
import ITDANodeClock from "./ITDANodeClock";

export default class ITDAREFCLKMUX extends ITDANodeClock {
  constructor(name = "") {
    super(name, NodeType.ITDAREFCLKMUX);
    this.render = ITDANodeRenderer.ITDAREFCLKMUX.render;
  }

  set height(data: number) {
    super.height = this.adjustNodeToGrid(data);
  }

  get height() {
    const ctrl = this.getCtrlPort();
    return this.isDocked()
      ? this.adjustNodeToGrid(super.height) * 2 -
          this.repo.getEditor(this.repo.getCurrentID()).getGridStroke() / 2
      : Math.max(ctrl.getInputPorts().length, ctrl.getOutputPorts().length) *
          this.adjustNodeToGrid(super.height) -
          this.repo.getEditor(this.repo.getCurrentID()).getGridStroke() / 2;
  }

  async data(
    inputs: Record<`CLK_INPUT_${number}`, any>
  ): Promise<Record<`CLK_OUTPUT`, any>> {
    const freq = this.controls.freq as ITDAControl.ITDAControlFrequency;
    let frequency = this.getFrequencyInitValue();
    if (Object.keys(inputs).length !== 0) {
      const inputFreq = inputs;
      const configElements = this.getConfigurationElements();
      const selectInput = configElements.length
        ? (("CLK_INPUT_" + configElements[0].getValue()) as keyof typeof inputs)
        : ("CLK_INPUT_0" as keyof typeof inputs);
      frequency = inputFreq[selectInput][0];
    }
    if (frequency) {
      freq.setFrequency(frequency);
      this.update();
    }
    return { CLK_OUTPUT: freq.getFrequency() };
  }
}
