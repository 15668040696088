/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import PowerPMDEvent from '@/model/src/events/PowerPMDEvent'
import PowerPMDChecker from '@/model/src/checkers/PowerPMDChecker'
export default class PowerPMD extends Item {
    FID_POWERPMD_NAME = 0
    FID_POWERPMD_PMIFINPMC = 1
    FID_POWERPMD_LAST = 1

    constructor(obj) {
        super(Object.assign(obj,{itemType:"PowerPMD"}))
        
        this.setEvent(new PowerPMDEvent(this))
        this.setChecker(new PowerPMDChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = "PowerPMD"
        }
    if (this.properties.name === undefined) {
        this.properties.name = "PMD_NONAME"
    }
    if (this.properties.PMIFInPMC === undefined) {
        this.properties.PMIFInPMC = true
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "PowerPMD"
    }

    getSchema() {
        return {"name": {"type": "string", "default": "PMD_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "PMIFInPMC": {"type": "boolean", "label": "PMIF in PMC", "default": true, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    static getSchema() {
        return {"name": {"type": "string", "default": "PMD_NONAME", "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "PMIFInPMC": {"type": "boolean", "label": "PMIF in PMC", "default": true, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ConfigurationFolder", "auto": true, "category": "IP"}, {"name": "SFRBlock", "auto": true, "category": "IP"}, {"name": "PowerDiagramFolder", "auto": true, "category": "IP"}, {"name": "PowerPMDCTRLSPT", "auto": true, "category": "IP"}]]
    }

    hasSFRSpec() {
        return false
    }

    static hasSFRSpec() {
        return false
    }

    static getSFRMeta() {
        return
    }
    getSFRSpec() {
        return
    }

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMD_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_POWERPMD_NAME
    }
    

    setPMIFInPMC(PMIFInPMC, event=true) {
        if ((PMIFInPMC !== null) && (PMIFInPMC !== undefined)) {
            if (![Boolean].includes(PMIFInPMC.constructor)) {
                throw `PMIFInPMC(${PMIFInPMC}) must be [Boolean] type`
            }
        }
        const newVal = (PMIFInPMC === null || PMIFInPMC === undefined) ? undefined : PMIFInPMC
        const oldVal = this.properties.PMIFInPMC
        this.properties.PMIFInPMC = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_POWERPMD_PMIFINPMC, newVal, oldVal)
        }
    }
    getPMIFInPMC(pure = false) {
        return this.properties.PMIFInPMC
        
    }
    getFidPMIFInPMC() {
        return this.FID_POWERPMD_PMIFINPMC
    }
    

    getFidLast() {
        return this.FID_POWERPMD_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_POWERPMD_NAME:
                return "name"

            case this.FID_POWERPMD_PMIFINPMC:
                return "PMIFInPMC"

            default:
                return ""
        }
    }


    newConfigurationFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "ConfigurationFolder", properties, title, domain)
    }
    remConfigurationFolder(obj) {
        return this.removeChild(obj)
    }
    getConfigurationFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "ConfigurationFolder")
            return res
        })
    }
    newSFRBlock(properties={}, title = "", domain = "") {
        return this.createChild(this, "SFRBlock", properties, title, domain)
    }
    remSFRBlock(obj) {
        return this.removeChild(obj)
    }
    getSFRBlock() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "SFRBlock")
            return res
        })
    }
    newPowerDiagramFolder(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerDiagramFolder", properties, title, domain)
    }
    remPowerDiagramFolder(obj) {
        return this.removeChild(obj)
    }
    getPowerDiagramFolder() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "PowerDiagramFolder")
            return res
        })
    }
    newPowerPMDCTRLSPT(properties={}, title = "", domain = "") {
        return this.createChild(this, "PowerPMDCTRLSPT", properties, title, domain)
    }
    remPowerPMDCTRLSPT(obj) {
        return this.removeChild(obj)
    }
    getPowerPMDCTRLSPT() {
        return this.getChildren().find((item) => {
            let res = (item.itemType === "PowerPMDCTRLSPT")
            return res
        })
    }
}