/**
 *  Generated by Modeler
 *  timestamp: 2022-12-22 12:18:37.190826
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class ConfigurationFolderEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(
      item.getFidSelectedConfiguration(),
      this.lstn_selectedConfiguration
    );
  }
  lstn_selectedConfiguration(item, newVal, oldVal) {
    console.debug(item, newVal, oldVal);
    if (oldVal) {
      oldVal.setSelected(false);
    }
    if (newVal) {
      newVal.setSelected(true);
    }
  }
}
