import { Item } from "ITDAModelTypes";
import Canvas from "@/canvas";
import { Repository } from "@/canvas/store";
import Utils from "@/components/navigation/utils";

const moveToTarget = async (source: Item, target: Item) => {
  const repo = Repository.getInstance();
  let editor = repo.getEditor(repo.getCurrentID());

  const inSameDiagram =
    source.getParent().getId() === target.getParent().getId();

  if (inSameDiagram) {
    const sourceNode = editor.getNode(source.getNodeID());
    sourceNode.selected = false;
    sourceNode.update();
  } else {
    await Utils.changePage(target.getParent());
  }

  editor = repo.getEditor(repo.getCurrentID());

  const metaView = target.getNodeMetaView();
  const area = Canvas.getAreaPlugin();
  area
    .getArea()
    .translate(
      (-metaView.getX() + 100) * area.getArea().transform.k,
      (-metaView.getY() + 100) * area.getArea().transform.k
    );

  const targetNode = editor.getNode(target.getNodeID());
  targetNode.selected = true;
  targetNode.update();

  await area.emit({
    type: "nodepicked",
    options: { node: editor.getNode(target.getNodeID()) },
  });
};

export default {
  moveToTarget: moveToTarget,
};
