import { NodeType } from "../types";
import * as ITDANodeRenderer from "./renderers";
import ITDANodePower from "./ITDANodePower";

export default class ITDAINFORM extends ITDANodePower {
  constructor(name = "") {
    super(name, NodeType.ITDAINFORM);
    this.render = ITDANodeRenderer.ITDAINFORM.render;
  }
}
