/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:18.560520
 */
import Item from '@/model/src/Item'
import ClockDividerEXTEvent from '@/model/src/events/ClockDividerEXTEvent'
import ClockDividerEXTChecker from '@/model/src/checkers/ClockDividerEXTChecker'
import ITDA_CMU_DIVIDER_EXT_PT from '@/model/gen/sfrSpec/ITDA_CMU_DIVIDER_EXT_PT'
export default class ClockDividerEXT extends Item {
    FID_CLOCKDIVIDEREXT_POWERDOWNMODE = 0
    FID_CLOCKDIVIDEREXT_THROTTLEMODE = 1
    FID_CLOCKDIVIDEREXT_CUSTOMMODE = 2
    FID_CLOCKDIVIDEREXT_NAME = 3
    FID_CLOCKDIVIDEREXT_LAST = 3

    constructor(obj) {
        super(Object.assign(obj,{itemType:"ClockDividerEXT"}))
        
        this.setEvent(new ClockDividerEXTEvent(this))
        this.setChecker(new ClockDividerEXTChecker(this))
        this._schema = (obj._schema) ? obj._schema : this.getSchema()
        
        if (this.title === undefined) {
            this.title = ""
        }
    if (this.properties.powerDownMode === undefined) {
        this.properties.powerDownMode = false
    }
    if (this.properties.throttleMode === undefined) {
        this.properties.throttleMode = false
    }
    if (this.properties.customMode === undefined) {
        this.properties.customMode = false
    }
    if (this.properties.name === undefined) {
        this.properties.name = "NONAME"
    }
    }

    getDomain() {
        return ""
    }

    isNodeType() {
        return super.isNodeType() || false
    }

    isConnectionType() {
        return super.isConnectionType() || false
    }

    static getClassName() {
        return "ClockDividerEXT"
    }

    getSchema() {
        return {"powerDownMode": {"type": "boolean", "label": "Use PWRDOWN", "desc": "Use Power Down", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "throttleMode": {"type": "boolean", "label": "Use THROTTLE", "desc": "Use Throttle", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "customMode": {"type": "boolean", "label": "Use CUSTOM", "desc": "Use Custom Ctrl", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "name": {"type": "string", "hidden": true, "default": "NONAME", "category": "IP", "multiple": false, "readOnly": false}}
    }

    static getSchema() {
        return {"powerDownMode": {"type": "boolean", "label": "Use PWRDOWN", "desc": "Use Power Down", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "throttleMode": {"type": "boolean", "label": "Use THROTTLE", "desc": "Use Throttle", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "customMode": {"type": "boolean", "label": "Use CUSTOM", "desc": "Use Custom Ctrl", "default": false, "category": "IP", "multiple": false, "hidden": false, "readOnly": false}, "name": {"type": "string", "hidden": true, "default": "NONAME", "category": "IP", "multiple": false, "readOnly": false}}
    }

    getChildrenSpec() {
        return [...super.getChildrenSpec(), ...[{"name": "ClockSIGNAL", "interface": "ThrottleSignal", "condition": {"type": "ThrottleSignal"}, "properties": {"type": "ThrottleSignal"}, "auto": false, "searchable": false, "title": "THROTTLE Signal List", "headers": [{"title": "Name", "value": "SignalName", "align": "start", "sortable": true}], "category": "IP"}]]
    }

    hasSFRSpec() {
        return true
    }

    static hasSFRSpec() {
        return true
    }

    static getSFRMeta() {
        return ITDA_CMU_DIVIDER_EXT_PT
    }
    getSFRSpec(type) {
        if (type === "FULL") {
            return ITDA_CMU_DIVIDER_EXT_PT
        }
    }

    setPowerDownMode(powerDownMode, event=true) {
        if ((powerDownMode !== null) && (powerDownMode !== undefined)) {
            if (![Boolean].includes(powerDownMode.constructor)) {
                throw `powerDownMode(${powerDownMode}) must be [Boolean] type`
            }
        }
        const newVal = (powerDownMode === null || powerDownMode === undefined) ? undefined : powerDownMode
        const oldVal = this.properties.powerDownMode
        this.properties.powerDownMode = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIVIDEREXT_POWERDOWNMODE, newVal, oldVal)
        }
    }
    getPowerDownMode(pure = false) {
        return this.properties.powerDownMode
        
    }
    getFidPowerDownMode() {
        return this.FID_CLOCKDIVIDEREXT_POWERDOWNMODE
    }
    

    setThrottleMode(throttleMode, event=true) {
        if ((throttleMode !== null) && (throttleMode !== undefined)) {
            if (![Boolean].includes(throttleMode.constructor)) {
                throw `throttleMode(${throttleMode}) must be [Boolean] type`
            }
        }
        const newVal = (throttleMode === null || throttleMode === undefined) ? undefined : throttleMode
        const oldVal = this.properties.throttleMode
        this.properties.throttleMode = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIVIDEREXT_THROTTLEMODE, newVal, oldVal)
        }
    }
    getThrottleMode(pure = false) {
        return this.properties.throttleMode
        
    }
    getFidThrottleMode() {
        return this.FID_CLOCKDIVIDEREXT_THROTTLEMODE
    }
    

    setCustomMode(customMode, event=true) {
        if ((customMode !== null) && (customMode !== undefined)) {
            if (![Boolean].includes(customMode.constructor)) {
                throw `customMode(${customMode}) must be [Boolean] type`
            }
        }
        const newVal = (customMode === null || customMode === undefined) ? undefined : customMode
        const oldVal = this.properties.customMode
        this.properties.customMode = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIVIDEREXT_CUSTOMMODE, newVal, oldVal)
        }
    }
    getCustomMode(pure = false) {
        return this.properties.customMode
        
    }
    getFidCustomMode() {
        return this.FID_CLOCKDIVIDEREXT_CUSTOMMODE
    }
    

    setName(name, event=true) {
        if ((name !== null) && (name !== undefined)) {
            if (![String].includes(name.constructor)) {
                throw `name(${name}) must be [String] type`
            }
        }
        const newVal = (name === null || name === undefined) ? undefined : name
        const oldVal = this.properties.name
        this.properties.name = newVal
        if (this.getEvent() && event) {
            return this.getEvent().propertyChanged(this.FID_CLOCKDIVIDEREXT_NAME, newVal, oldVal)
        }
    }
    getName(pure = false) {
        return this.properties.name
        
    }
    getFidName() {
        return this.FID_CLOCKDIVIDEREXT_NAME
    }
    

    getFidLast() {
        return this.FID_CLOCKDIVIDEREXT_LAST
    }

    getFidKey(fid) {
        switch(fid) {

            case this.FID_CLOCKDIVIDEREXT_POWERDOWNMODE:
                return "powerDownMode"

            case this.FID_CLOCKDIVIDEREXT_THROTTLEMODE:
                return "throttleMode"

            case this.FID_CLOCKDIVIDEREXT_CUSTOMMODE:
                return "customMode"

            case this.FID_CLOCKDIVIDEREXT_NAME:
                return "name"

            default:
                return ""
        }
    }


    newThrottleSignal(properties={}, title = "", domain = "") {
        return this.createChild(this, "ClockSIGNAL", properties, title, domain)
    }
    remThrottleSignal(obj) {
        return this.removeChild(obj)
    }
    getThrottleSignals() {
        return this.getChildren().filter((item) => {
            let res = (item.itemType === "ClockSIGNAL")
            res &= (item.properties.type === "ThrottleSignal")
            return res
        })
    }
}