/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";
import InstanceHandler from "../InstanceHandler";
import ItemType from "../ItemType";

export default class UPFPowerDomainInstanceEvent extends ItemEvent {
  constructor(item) {
    super(item);
    this.addPropertyListener(item.getFidName(), this.lstn_name);
    this.addPropertyListener(item.getFidModule(), this.lstn_module);
  }

  lstn_name(item, newVal) {
    item.setTitle(newVal);
  }

  async lstn_module(item, newVal, oldVal) {
    await InstanceHandler.removeUPFPhantoms(item);
    const newModule = newVal;
    const oldModule = oldVal;
    if (newModule) {
      const diagram = newModule.getUPFDiagram();
      const powerDomain = diagram.getUPFPowerDomain();
      for (const cItem of powerDomain.getUPFPowerSources()) {
        await InstanceHandler.createUPFPhantom(
          item,
          cItem,
          ItemType.UPFPhantomPowerSource
        );
      }
      for (const cItem of powerDomain.getUPFGrounds()) {
        await InstanceHandler.createUPFPhantom(
          item,
          cItem,
          ItemType.UPFPhantomGround
        );
      }
    } else if (oldModule) {
      console.log(oldModule);
    }
  }
}
