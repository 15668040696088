/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.608027
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";

export default class ClockQChannelInfoChecker extends ItemChecker {
  constructor(item) {
    super(item);
    this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME);
    //this.addChecker(item.getFidControlledClocks(), this.checkControlledClocks, ErrorType.NAME)
  }
  checkName(item) {
    const IPItem = item.getParent();
    const idx = IPItem.getClockQChannelInfos().findIndex(
      (obj) => obj.getId() === item.getId()
    );
    IPItem.getClockQChannelInfos().forEach((qchInfo) => {
      if (qchInfo !== item) {
        if (qchInfo.getName() === item.getName()) {
          this.newError(
            `QChannel(${idx}) name(${item.getName()}) is duplicated`,
            qchInfo
          );
        }
      }
    });
  }
  checkControlledClocks() {
    // TBD
  }
}
