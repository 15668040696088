export const setShortcutKey = () => {
  window.addEventListener("keydown", shortcutKeyDownHandler.bind(this));
};

export const blockContextMenu = () => {
  window.addEventListener("contextmenu", function (this, e) {
    contextMenuHandler(e);
  });
};

const shortcutKeyDownHandler = (e: KeyboardEvent) => {
  if (e.code === "F12") {
    e.preventDefault();
  }
};

const contextMenuHandler = (e: MouseEvent) => {
  e.preventDefault();
};
