/**
 *  Generated by Modeler
 *  timestamp: 2022-08-22 12:24:20.652766
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";

export default class SFRChecker extends ItemChecker {
  constructor(item) {
    super(item);
    this.addChecker(item.getFidName(), this.checkName, ErrorType.NAME);
    //this.addChecker(item.getFidOffset(), this.checkOffset, ErrorType.NAME)
  }
  checkName(item) {
    for (const cItem of item.getParent().getChildren()) {
      if (cItem === item) {
        continue;
      }
      if (cItem.getName() === item.getName()) {
        this.newError(`${item.getName()} is duplicated`, cItem);
      }
    }
  }
  checkOffset() {
    // TBD
  }
}
