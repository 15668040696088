import {
  Item,
  VariantLevel,
  RefItemElementType,
  ItemType,
  ChildSpec,
} from "ITDAModelTypes";
import * as ItemProperty from "./ItemProperty";
import * as ItemPropertyAutocomplete from "./ItemPropertyAutocomplete";
import * as ItemPropertyCheckbox from "./ItemPropertyCheckbox";
import * as ItemPropertyIntegerField from "./ItemPropertyIntegerField";
import ItemPropertyItemField from "./ItemPropertyItemField";
import ItemPropertyTable from "./ItemPropertyTable";
import * as ItemPropertyTextarea from "./ItemPropertyTextarea";
import * as ItemPropertyTextField from "./ItemPropertyTextField";
import ItemTitle from "./ItemTitle";

import API from "@/api/internal";
import COMP from "@/components/index";

export default {
  ...ItemProperty,
  ...ItemPropertyAutocomplete,
  ...ItemPropertyCheckbox,
  ...ItemPropertyIntegerField,
  ...ItemPropertyItemField,
  ...ItemPropertyTable,
  ...ItemPropertyTextarea,
  ...ItemPropertyTextField,
  ...ItemTitle,
  getVariant: (type: RefItemElementType, level?: VariantLevel) => {
    switch (type) {
      case RefItemElementType.STRING:
      case RefItemElementType.INTEGER:
      case RefItemElementType.ENUM:
      case RefItemElementType.TEXTAREA:
        if (level === VariantLevel.LEVEL0) return "outlined";
        else if (level === VariantLevel.LEVEL1) return "underlined";
        else if (level === VariantLevel.LEVEL2) return "solo";
        else return;
      case RefItemElementType.BOOLEAN:
        return;
      case RefItemElementType.CHIP:
        if (level === VariantLevel.LEVEL0) return "outlined";
        else if (level === VariantLevel.LEVEL1) return "elevated";
        else if (level === VariantLevel.LEVEL2) return "text";
        else return;
      default:
        return;
    }
  },
  update: (item: Item, key: string, type: any, data: string | unknown) => {
    API.setItemValue(item, key, data ? type(data) : data);
    if (item.getItemType() === ItemType.ClockMultiplexer) {
      COMP.updateComponent("NAV_RIGHT_CONFIGURATION");
    }
  },
  change: (item: Item, key: string, type: any, e: Event) => {
    const target = e.target as HTMLInputElement;
    if (type === Boolean) {
      API.setItemValue(item, key, type(target.checked));
    } else {
      API.setItemValue(
        item,
        key,
        target.value ? type(target.value) : target.value
      );
    }
  },
  showChildren: (item: Item) => {
    switch (item.getItemType()) {
      case ItemType.ClockDivider:
        return item.getClkEn() ? true : false;
      case ItemType.Label:
        return item.getType() === "external";
      case ItemType.SDCPhantom:
        return item.getClockPoint() ? true : false;
      default:
        return false;
    }
  },
  getSpec: (item: Item, propKey: string): ChildSpec[] | undefined => {
    switch (item.getItemType()) {
      case ItemType.ClockMultiplexerEXT:
      case ItemType.ClockDividerEXT:
        if (propKey === "throttleMode") {
          return item
            .getChildrenSpec()
            .filter(
              (spec: ChildSpec) =>
                spec.name === ItemType.ClockSIGNAL.getKey() &&
                spec.interface === "ThrottleSignal"
            );
        }
        return;
      case ItemType.ClockGateEXT:
        if (propKey === "shortStopMode") {
          return item
            .getChildrenSpec()
            .filter(
              (spec: ChildSpec) =>
                spec.name === ItemType.ClockSIGNAL.getKey() &&
                spec.interface === "ShortStopSignal"
            );
        }
        if (propKey === "ewakeupMode") {
          return item
            .getChildrenSpec()
            .filter(
              (spec: ChildSpec) =>
                spec.name === ItemType.ClockSIGNAL.getKey() &&
                spec.interface === "EwakeupSignal"
            );
        }
        return;
      case ItemType.UPFGround:
      case ItemType.UPFPowerSource:
        if (propKey === "isNeedElements") {
          return item
            .getChildrenSpec()
            .filter(
              (spec: ChildSpec) => spec.name === ItemType.UPFElement.getKey()
            );
        }
        return;
      case ItemType.UPFIsolationCell:
      case ItemType.UPFLevelShifter:
      case ItemType.UPFEnableLevelShifter:
        if (propKey === "isNeedElements") {
          return item
            .getChildrenSpec()
            .filter(
              (spec: ChildSpec) => spec.name === ItemType.UPFElement.getKey()
            );
        }
        return;
      default:
        return;
    }
  },
};
