/**
 *  Generated by Modeler
 *  timestamp: 2024-09-06 06:46:39.606972
 */
import ItemEvent from "@/model/src/ItemEvent";

export default class PowerSequenceConfigurationFolderEvent extends ItemEvent {
  constructor(item) {
    super(item);
  }

  postProcess(item) {
    item.setTitle("Power Control Sequence", false);
    const PMRConfig = item.newPowerSequenceConfiguration({
      type: "PMR",
    }).item;
    PMRConfig.newPowerSequenceDiagram({
      name: "ROM",
      type: "ROM",
    });
    PMRConfig.newPowerSequenceDiagram({
      name: "RAM",
      type: "RAM",
    });

    item.newPowerSequenceConfiguration({
      type: "PMD",
    }).item;
  }
}
