/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x1000",
    "size": "0x2000",
    "alignSize": "0x20",
    "sfrs": [
        {
            "name": "INTR_ENABLE",
            "alignOffset": "0x0",
            "description": "Interrupt Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_INTR_ENABLE",
                    "index": -1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Request Enable for {{name}}\n0 = Interrupt Request is Disabled\n1 = Interrupt Request is Enabled",
                    "initValue": "0x0",
                    "nc": "SFR_INTR_ENABLE"
                }
            ]
        },
        {
            "name": "INTR_CLEAR",
            "alignOffset": "0x4",
            "description": "Interrupt Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_INTR_CLEAR",
                    "index": -1,
                    "size": 1,
                    "access": "WO",
                    "formula": "",
                    "directWrite": true,
                    "nameReset": "",
                    "description": "Interrupt Request Clear for {{name}}\nWhen this Field is set to 1, Corresponding Interrupt Request is Cleared\nThis Field automatically go back to 0",
                    "initValue": "0x0",
                    "nc": "SFR_INTR_CLEAR"
                }
            ]
        },
        {
            "name": "INTR_PEND",
            "alignOffset": "0x8",
            "description": "Interrupt Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_INTR_PEND",
                    "index": -1,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Request Pending Indication for {{name}}\n0 = Interrupt Request is Not Pending\n1 = Interrupt Request is Pending",
                    "initValue": "0x0",
                    "nc": "SFR_INTR_PEND"
                }
            ]
        },
        {
            "name": "INTR_BUSY",
            "alignOffset": "0xC",
            "description": "Interrupt Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_INTR_BUSY",
                    "index": -1,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "Interrupt Request Busy Indication for {{name}}\n0 = Interrupt Request is Not Presented\n1 = Interrupt Request is in Handling",
                    "initValue": "0x0",
                    "nc": "SFR_INTR_BUSY"
                }
            ]
        }
    ]
}