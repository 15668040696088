import axios from "axios";
import { host, protocol } from "./config";
import { DB } from "ITDAModelTypes";

export const load = async (
  name: string,
  type: "design" | "config"
): Promise<DB | undefined> => {
  try {
    const res = await axios.post(`${protocol}://${host}/io/load`, null, {
      params: {
        name: name,
        type: type,
      },
    });
    return res && res.status === 200 ? res.data : undefined;
  } catch (err) {
    console.log(err);
    return undefined;
  }
};
