/**
 *  Generated by Modeler
 *  timestamp: 2023-03-19 12:05:43.427172
 */
import ErrorType from "@/model/src/ErrorType";
import ItemChecker from "@/model/src/ItemChecker";
import ItemHandler from "../ItemHandler";
import ItemType from "../ItemType";

export default class UPFMapperPowerSourceChecker extends ItemChecker {
  constructor(item) {
    super(item);
    this.addChecker(
      item.getFidPowerSource(),
      this.checkPowerSource,
      ErrorType.POWER_DIAGRAM
    );
  }
  checkPowerSource(item) {
    const ps = item.getPowerSource();
    if (!ps) {
      if (item.getParent(ItemType.UPFGround)) {
        this.newError(`Ground must be selected.`, item);
      } else {
        this.newError(`Power must be selected.`, item);
      }
    }
  }
}
