/**
 *  Generated by Modeler
 *  timestamp: 2024-12-03 07:51:19.589270
 */
export default {
    "startOffset": "0x240",
    "size": "0xC",
    "alignSize": "0xC",
    "sfrs": [
        {
            "name": "NMI_IN",
            "alignOffset": "0x0",
            "description": "NMI Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_SW_RESET",
                    "index": 0,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}\nThis Field is driven from RESET_CTRL__SFR_SW_RESET",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_SW_RESET"
                },
                {
                    "name": "SFR_NMI1",
                    "index": 1,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI1"
                },
                {
                    "name": "SFR_NMI2",
                    "index": 2,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI2"
                },
                {
                    "name": "SFR_NMI3",
                    "index": 3,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI3"
                },
                {
                    "name": "SFR_NMI4",
                    "index": 4,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI4"
                },
                {
                    "name": "SFR_NMI5",
                    "index": 5,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI5"
                },
                {
                    "name": "SFR_NMI6",
                    "index": 6,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI6"
                },
                {
                    "name": "SFR_NMI7",
                    "index": 7,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI7"
                },
                {
                    "name": "SFR_NMI8",
                    "index": 8,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI8"
                },
                {
                    "name": "SFR_NMI9",
                    "index": 9,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI9"
                },
                {
                    "name": "SFR_NMI10",
                    "index": 10,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI10"
                },
                {
                    "name": "SFR_NMI11",
                    "index": 11,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI11"
                },
                {
                    "name": "SFR_NMI12",
                    "index": 12,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI12"
                },
                {
                    "name": "SFR_NMI13",
                    "index": 13,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI13"
                },
                {
                    "name": "SFR_NMI14",
                    "index": 14,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI14"
                },
                {
                    "name": "SFR_NMI15",
                    "index": 15,
                    "size": 1,
                    "access": "RO",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Source for {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_IN__SFR_NMI15"
                }
            ]
        },
        {
            "name": "NMI_ENABLE",
            "alignOffset": "0x4",
            "description": "NMI Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_SW_RESET",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_SW_RESET"
                },
                {
                    "name": "SFR_NMI1",
                    "index": 1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI1"
                },
                {
                    "name": "SFR_NMI2",
                    "index": 2,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI2"
                },
                {
                    "name": "SFR_NMI3",
                    "index": 3,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI3"
                },
                {
                    "name": "SFR_NMI4",
                    "index": 4,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI4"
                },
                {
                    "name": "SFR_NMI5",
                    "index": 5,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI5"
                },
                {
                    "name": "SFR_NMI6",
                    "index": 6,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI6"
                },
                {
                    "name": "SFR_NMI7",
                    "index": 7,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI7"
                },
                {
                    "name": "SFR_NMI8",
                    "index": 8,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI8"
                },
                {
                    "name": "SFR_NMI9",
                    "index": 9,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI9"
                },
                {
                    "name": "SFR_NMI10",
                    "index": 10,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI10"
                },
                {
                    "name": "SFR_NMI11",
                    "index": 11,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI11"
                },
                {
                    "name": "SFR_NMI12",
                    "index": 12,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI12"
                },
                {
                    "name": "SFR_NMI13",
                    "index": 13,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI13"
                },
                {
                    "name": "SFR_NMI14",
                    "index": 14,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI14"
                },
                {
                    "name": "SFR_NMI15",
                    "index": 15,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "",
                    "description": "NMI Enable for {{name}}\n0 = NMI is Disabled\n1 = NMI is Enabled",
                    "initValue": "0x0",
                    "nc": "NMI_ENABLE__SFR_NMI15"
                }
            ]
        },
        {
            "name": "NMI_STATUS",
            "alignOffset": "0x8",
            "description": "NMI Control for {{name}}",
            "fields": [
                {
                    "name": "SFR_SW_RESET",
                    "index": 0,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_SW_RESET",
                    "floating": true
                },
                {
                    "name": "SFR_NMI1",
                    "index": 1,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI1",
                    "floating": true
                },
                {
                    "name": "SFR_NMI2",
                    "index": 2,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI2",
                    "floating": true
                },
                {
                    "name": "SFR_NMI3",
                    "index": 3,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI3",
                    "floating": true
                },
                {
                    "name": "SFR_NMI4",
                    "index": 4,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI4",
                    "floating": true
                },
                {
                    "name": "SFR_NMI5",
                    "index": 5,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI5",
                    "floating": true
                },
                {
                    "name": "SFR_NMI6",
                    "index": 6,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI6",
                    "floating": true
                },
                {
                    "name": "SFR_NMI7",
                    "index": 7,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI7",
                    "floating": true
                },
                {
                    "name": "SFR_NMI8",
                    "index": 8,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI8",
                    "floating": true
                },
                {
                    "name": "SFR_NMI9",
                    "index": 9,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI9",
                    "floating": true
                },
                {
                    "name": "SFR_NMI10",
                    "index": 10,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI10",
                    "floating": true
                },
                {
                    "name": "SFR_NMI11",
                    "index": 11,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI11",
                    "floating": true
                },
                {
                    "name": "SFR_NMI12",
                    "index": 12,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI12",
                    "floating": true
                },
                {
                    "name": "SFR_NMI13",
                    "index": 13,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI13",
                    "floating": true
                },
                {
                    "name": "SFR_NMI14",
                    "index": 14,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI14",
                    "floating": true
                },
                {
                    "name": "SFR_NMI15",
                    "index": 15,
                    "size": 1,
                    "access": "RW",
                    "formula": "",
                    "directWrite": false,
                    "nameReset": "CLDBOOT_RESETn",
                    "description": "NMI Status for {{name}}\nThis Field can indicate which NMI causes the Latest Soft Reset\nThis Field is only reset at Cold Booting of {{name}}",
                    "initValue": "0x0",
                    "nc": "NMI_STATUS__SFR_NMI15",
                    "floating": true
                }
            ]
        }
    ]
}